// Lib
import React, { useMemo } from 'react';
import { AppBar, Toolbar, IconButton, Button } from '@mui/material';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import MenuIcon from '@mui/icons-material/Menu';
import classnames from 'classnames';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';

// Components
import mainLogo from '../../../assets/img/mainLogo.png';
import SideMenu from '../../SideMenu';
import { ReactComponent as GileadLogo } from '../../../assets/svg/gileadLogo.svg';
import kiteLogo from '../../../assets/img/kiteLogo.png';
import MobileSearch from '../../Search/MobileSearch';

// Hooks
import useSideMenu from '../../../hooks/useSideMenu';
import useAuth from '../../../pages/Auth/hooks/useAuth';
import useSearch from '../../../hooks/useSearch';
import useCart from '../../../pages/Cart/hooks/useCart';
import useUI from '../../../hooks/useUI';

// constants
import {
  DESKTOP_HEADER_LINKS,
  MOBILE_HEADER_LINKS,
} from '../../../constants/header';
import { SSO_LOGIN_PAGE, HOME_PAGE } from '../../../constants/urls';

// Utils
import { getCurrentYear } from '../../../utils/date';

const useStyles = makeStyles((theme) => ({
  root: ({ onlyContent }) => ({
    backgroundColor: 'white',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    display: 'flex',
    flexDirection: 'row',
    height: 80,
    justifyContent: onlyContent ? 'start' : 'center',
    boxShadow: '0px 5px 17px rgba(48, 85, 107, 0.2)',
    ...(onlyContent && {
      boxShadow: 'none',
      alignItems: 'center',
    }),
    [theme.breakpoints.down('lg')]: {
      height: onlyContent ? 0 : 70,
    },
    [theme.breakpoints.down('md')]: {
      height: 54,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        height: onlyContent ? 0 : 70,
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        height: 54,
      }),
    },
  }),
  logo: {
    display: 'flex',
    height: 60,
    width: 60,
    [theme.breakpoints.down('lg')]: {
      height: 48,
      width: 48,
    },
    [theme.breakpoints.down('md')]: {
      height: 40,
      width: 40,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        height: 48,
        width: 48,
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        height: 40,
        width: 40,
      }),
    },
  },
  toolbar: ({ isAuthPage, onlyLogoLink }) => ({
    display: 'flex',
    justifyContent: isAuthPage || onlyLogoLink ? 'center' : 'space-between',
    minHeight: '20px',
    width: 1180,
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
      }),
    },
  }),
  linkContainer: ({ isKiteUser }) => ({
    display: 'flex',
    fontWeight: isKiteUser ? 400 : 600,
    justifyContent: 'space-between',
    marginLeft: 'auto',
    width: 777,
    [theme.breakpoints.down('lg')]: {
      marginLeft: 0,
      flexDirection: 'column',
      paddingTop: theme.spacing(3.5),
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(1),
      paddingTop: theme.spacing(2),
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        marginLeft: 0,
        flexDirection: 'column',
        paddingTop: theme.spacing(3.5),
        width: '100%',
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        paddingLeft: theme.spacing(1),
        paddingTop: theme.spacing(2),
      }),
    },
  }),
  link: {
    alignItems: 'center',
    display: 'flex',
    color: 'black',
    textDecoration: 'none',
    [theme.breakpoints.down('lg')]: {
      paddingBottom: theme.spacing(1.5),
      paddingTop: theme.spacing(1.5),
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(1.1),
      paddingTop: theme.spacing(1.1),
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        paddingBottom: theme.spacing(1.5),
        paddingTop: theme.spacing(1.5),
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        paddingBottom: theme.spacing(1.1),
        paddingTop: theme.spacing(1.1),
      }),
    },
  },
  icon: {
    marginRight: 8,
  },
  logoutButton: ({ isKiteUser }) => ({
    color: 'black !important',
    fontWeight: isKiteUser ? 400 : 600,
    textTransform: 'initial',
    marginLeft: 'auto',
    '&:hover': {
      backgroundColor: 'initial',
    },
    [theme.breakpoints.down('lg')]: {
      padding: 0,
      marginBottom: theme.spacing(3),
      marginLeft: `-${theme.spacing(0.5)}`,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        fontSize: '14px !important',
        padding: 0,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(3),
        marginLeft: `-${theme.spacing(0.5)}`,
      }),
    },
  }),
  activeLink: ({ isKiteUser }) => ({
    color: isKiteUser ? theme.palette.primary2 : theme.palette.primary1,
    '& > svg path': {
      stroke: isKiteUser ? theme.palette.primary2 : theme.palette.primary1,
      '&#stroke-none': {
        stroke: 'none',
      },
      '&#fill-only': {
        fill: isKiteUser ? theme.palette.primary2 : theme.palette.primary1,
        stroke: 'none',
      },
    },
  }),
  rights: {
    color: '#C6CAC6',
    fontSize: 14,
    '& div': {
      display: 'inline',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      '& div': {
        display: 'block',
      },
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 12,
        marginBottom: theme.spacing(1),
        '& div': {
          display: 'block',
        },
      }),
    },
  },
  spacer: {
    padding: theme.spacing(1.8),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0.9),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        padding: theme.spacing(0.9),
      }),
    },
  },
  menuButton: {
    padding: 2,
  },
  additionalTitle: {
    alignItems: 'center',
    display: 'flex',
    color: 'black',
    fontWeight: 700,
    fontSize: 36,
    maxWidth: 1180,
    paddingLeft: 26,
    paddingRight: 26,
    width: '100%',
    margin: '50px auto 0',
  },
  searchIcon: {
    boxShadow: '0px 5px 17px rgba(44, 112, 172, 0.2)',
    height: 36,
    marginLeft: theme.spacing(1.5),
    width: 36,
  },
  mainLogo: ({ isActiveSearch }) => ({
    display: isActiveSearch ? 'none' : 'initial',
  }),
}));

const PAGES_WITH_SEARCH = [
  '/materials',
  '/address-book',
  '/order-history',
  '/order-history/recurring',
  '/support/FAQ',
  '/cart/shipping-address',
  '/kits',
];

const Header = ({
  isKiteUser = true,
  isAuthPage,
  isLoggedIn,
  onlyLogoLink,
  searchResults,
  onChangeSearch,
  onlyContent,
  title,
}) => {
  const { isDesktopMode, isTabletMode, isMobileMode } = useUI();
  const { pathname } = useLocation();
  const {
    isActive: isActiveSearch,
    onToggleSearchInput,
    onChange,
    searchValue,
    onClearSearch,
  } = useSearch();
  const classes = useStyles({
    isKiteUser,
    isAuthPage,
    onlyContent,
    onlyLogoLink,
    isActiveSearch,
  });
  const { totalCountInCart } = useCart();

  const { active, toggleSideMenu, onCloseSideMenu } = useSideMenu();
  const { onLogout } = useAuth();

  const HEADER_LINKS = useMemo(
    () => (isDesktopMode ? DESKTOP_HEADER_LINKS : MOBILE_HEADER_LINKS),
    [isDesktopMode],
  );

  const isSsoLogoutPage = useMemo(
    () => pathname.includes('sso/logout'),
    [pathname],
  );

  const logoPath = useMemo(() => {
    if (isSsoLogoutPage) {
      return '/';
    }

    if (isAuthPage) {
      return '/auth';
    }

    return !isLoggedIn ? SSO_LOGIN_PAGE : HOME_PAGE;
  }, [isSsoLogoutPage, isAuthPage, isLoggedIn]);

  const links = useMemo(
    () => (
      <div className={classes.linkContainer}>
        {HEADER_LINKS.map(({ path, title, icon: Icon, id }, index) => [
          !isDesktopMode && index === 3 && (
            <div className={classes.spacer} key={`${title}_${index}`} />
          ),
          <NavLink
            className={({ isActive }) =>
              isActive
                ? classnames(classes.activeLink, classes.link)
                : classes.link
            }
            onClick={isTabletMode && onCloseSideMenu} // to close sidebar on click to the active menu item
            key={id}
            to={path}
            state={{
              navigateFrom: pathname,
            }}
            id={id}>
            <Icon className={classes.icon} />
            {title === 'Cart'
              ? totalCountInCart
                ? `${title}(${totalCountInCart})`
                : `${title}`
              : title}
          </NavLink>,
        ])}
      </div>
    ),
    [
      classes.linkContainer,
      classes.spacer,
      classes.icon,
      classes.activeLink,
      classes.link,
      HEADER_LINKS,
      isDesktopMode,
      isTabletMode,
      onCloseSideMenu,
      pathname,
      totalCountInCart,
    ],
  );

  return (
    <AppBar className={classes.root} position="sticky">
      {onlyContent ? (
        <>
          {isDesktopMode && (
            <div className={classes.additionalTitle}>{title}</div>
          )}
          {isMobileMode && (
            <IconButton
              className={classes.searchIcon}
              id="search-button"
              edge="start"
              aria-label="search"
              onClick={onToggleSearchInput}>
              <SearchIcon />
            </IconButton>
          )}
        </>
      ) : (
        <>
          <Toolbar className={classes.toolbar}>
            <Link className={classes.mainLogo} id="logo-link" to={logoPath}>
              <img
                src={mainLogo}
                alt="GMOP logo"
                className={classes.logo}
                data-testid="header-logo"
              />
            </Link>
            {isDesktopMode && !isAuthPage && !onlyLogoLink && (
              <>
                {links}
                <Button
                  id="logout-btn"
                  onClick={onLogout}
                  className={classes.logoutButton}>
                  Log Out
                </Button>
              </>
            )}
            {!isActiveSearch && (
              <Box display="flex" gap={3}>
                {isMobileMode && PAGES_WITH_SEARCH.includes(pathname) && (
                  <IconButton
                    id="search-button"
                    edge="start"
                    aria-label="search"
                    onClick={onToggleSearchInput}>
                    <SearchIcon />
                  </IconButton>
                )}
                {!isDesktopMode && !isAuthPage && !onlyLogoLink && (
                  <IconButton
                    className={classes.menuButton}
                    edge="start"
                    aria-label="menu"
                    onClick={toggleSideMenu(true)}>
                    <MenuIcon />
                  </IconButton>
                )}
              </Box>
            )}
          </Toolbar>
          <SideMenu active={active} toggleSideMenu={toggleSideMenu}>
            <Box
              height="100%"
              display="flex"
              flexDirection="column"
              justifyContent="space-between">
              <div>
                {isKiteUser ? (
                  <img
                    alt="logo"
                    width={121}
                    height={36}
                    src={kiteLogo}
                    data-testid="kite-logo"
                  />
                ) : (
                  <GileadLogo />
                )}
                {links}
              </div>
              <Box>
                <Button
                  id="logout-btn"
                  onClick={onLogout}
                  className={classes.logoutButton}>
                  Log Out
                </Button>
                <div className={classes.rights}>
                  <div>{`${getCurrentYear()} Gilead Sciences, Inc. `}</div>
                  <div>All Rights Reserved.</div>
                </div>
              </Box>
            </Box>
          </SideMenu>
        </>
      )}
      <MobileSearch
        options={searchResults}
        withMenu={
          pathname.includes('address-book') ||
          pathname.includes('cart/shipping-address')
        }
        value={searchValue}
        onChange={onChangeSearch || onChange}
        isActive={isActiveSearch}
        onToggleSearchInput={onToggleSearchInput}
        onClearSearch={onClearSearch}
      />
    </AppBar>
  );
};

export default Header;
