// Lib
import React, { Fragment, useCallback } from 'react';
import {
  TableCell,
  TableRow as MuiTableRow,
  Collapse,
  Table,
  TableBody,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

// Components
import CollapsedTableRow from '../CollapsedTableRow';

const useStyles = makeStyles((theme) => ({
  collapsedRow: ({ withChildren }) => ({
    boxShadow: withChildren
      ? '0px 0px 15px rgba(48, 85, 107, 0.14) !important'
      : '0px 5px 15px rgba(44, 112, 172, 0.2)',
    ...(withChildren && {
      borderRadius: '10px 10px 0px 0px !important',
      backgroundColor: theme.palette.lightGrey,
      '& td': {
        '&:first-child': {
          borderTopLeftRadius: 10,
        },
        '&:last-child': {
          borderTopRightRadius: 10,
        },
      },
    }),
  }),
  collapsedTable: {
    width: '100%',
    '& tbody tr': {
      alignItems: 'center',
      display: 'flex',
      boxShadow: '0px 0px 1px rgba(48, 85, 107, 0.2)',
      '&:last-child': {
        borderRadius: '0px 0px 10px 10px',
        boxShadow: '0px 5px 17px rgba(48, 85, 107, 0.2)',
      },
      '& td': {
        alignItems: 'center',
        display: 'flex',
      },
    },
  },
}));

const TableRow = ({
  columns,
  useCellsReducers = () => ({ cells: {} }),
  item,
  styles,
  collapsibleTable = false,
  withChildren = false,
  onToggleChildren = () => {},
  style,
  itemHeight,
}) => {
  const classes = useStyles({ withChildren });

  const onToggleHeight = useCallback(() => {
    onToggleChildren(item?.children?.length + 1 || 1);
  }, [item, onToggleChildren]);

  const { cells } = useCellsReducers({
    order: item,
    onToggleChildren: onToggleHeight,
    withChildren,
  });

  return (
    <Fragment key={item.id}>
      <MuiTableRow
        style={style}
        className={classnames(styles?.row, classes.collapsedRow)}>
        {columns?.map((column) => (
          <TableCell key={`${item.id}-${column.id}`} className={styles?.cell}>
            {cells[column.id]}
          </TableCell>
        ))}
      </MuiTableRow>

      {collapsibleTable && (
        <MuiTableRow
          style={{
            ...style,
            top: style.top + itemHeight,
            height: withChildren ? style.height : 0,
          }}>
          <TableCell
            style={{ padding: 0, borderBottom: 0, width: '90%' }}
            colSpan={9}>
            <Collapse in={withChildren} timeout={100} unmountOnExit>
              <Table className={classes.collapsedTable}>
                <TableBody>
                  {item.children?.map((childOrder) => {
                    let mergedOrder = {
                      ...item,
                      ...childOrder,
                      status: childOrder.status || item.status,
                    };
                    delete mergedOrder.children;
                    // Need to delete this property in child of distro for proper
                    // address count calculation and displaying right order qty values
                    delete mergedOrder.addresses;

                    return (
                      <CollapsedTableRow
                        key={mergedOrder.id}
                        useCellsReducers={useCellsReducers}
                        mergedOrder={mergedOrder}
                        item={item}
                        columns={columns}
                        styles={styles}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </MuiTableRow>
      )}
    </Fragment>
  );
};

export default TableRow;
