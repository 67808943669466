// Libs
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useSupportPageTabs = () => {
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState(pathname.includes('FAQ') ? 1 : 0);

  useEffect(() => {
    if (pathname.includes('FAQ')) {
      setActiveTab(1);
    } else {
      setActiveTab(0);
    }
  }, [pathname]);

  return {
    activeTab,
    setActiveTab,
  };
};

export default useSupportPageTabs;
