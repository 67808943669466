// Lib
import React, { useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

// Components
import Dropzone from '../../../DropZone';
import Button from '../../../Button';
import Feather from '../../../Feather';
import Error from '../Error';

// Constants
import { selectAddressImportFile } from '../../../../constants/addressBook';

// Hooks
import useUser from '../../../../hooks/useUser';
import Progress from './Progress';

const useStyles = makeStyles((theme) => ({
  message: {
    fontSize: 28,
    fontWeight: 700,
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 16,
      }),
    },
  },
  text: {
    color: theme.palette.grey2,
    fontSize: 18,
    margin: 0,
  },
  content: ({ fileName }) => ({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingTop: 50,
    paddingBottom: fileName ? 0 : 40,
    [theme.breakpoints.down('lg')]: {
      paddingTop: ({ fileName }) => (fileName ? 12 : 20),
      paddingBottom: ({ fileName }) => (fileName ? 32 : 80),
    },
    [theme.breakpoints.down('md')]: {
      height: 'calc(79vh - 110px)',
      paddingTop: ({ fileName }) => (fileName ? 50 : 80),
      justifyContent: 'initial',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        paddingTop: ({ fileName }) => (fileName ? 12 : 20),
        paddingBottom: ({ fileName }) => (fileName ? 32 : 80),
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        height: '70vh',
        paddingTop: 0,
        paddingBottom: theme.spacing(2),
        justifyContent: 'center',
      }),
    },
  }),
  button: ({ isKiteUser }) => ({
    backgroundColor: theme.palette.lightGrey,
    color: isKiteUser ? theme.palette.primary1 : theme.palette.primary2,
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
    height: 44,
    width: 110,
    padding: '8px 24px',
    '&:hover': {
      backgroundColor: `${theme.palette.lightGrey} !important`,
    },
  }),
  fileName: {
    marginBottom: theme.spacing(2.5),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(3.5),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
      }),
    },
  },
  inputButton: ({ isKiteUser }) => ({
    color: 'transparent',
    width: 110,
    height: 44,
    '&::before': {
      height: 44,
      background: theme.palette.lightGrey,
      borderRadius: 20,
      color: isKiteUser ? theme.palette.primary1 : theme.palette.primary2,
      content: '"Replace"',
      display: 'inline-block',
      fontSize: 16,
      fontWeight: 700,
      padding: '12px 24px',
      outline: 'none',
      whiteSpace: 'nowrap',
      '-webkit-user-select': 'none',
      cursor: 'pointer',
    },
    '&::-webkit-file-upload-button': {
      display: 'none',
    },
  }),
  book: ({ fileName }) => ({
    pointerEvents: fileName ? 'none' : 'initial',
  }),
}));

const FileSelection = ({
  importedAddresses,
  onDrop,
  incorrectFormatMessage,
  uploadingProgress,
  onCancelUploading,
  withSpinner,
}) => {
  const { isKiteUser } = useUser();
  const fileName = importedAddresses.file?.name;
  const classes = useStyles({ isKiteUser, fileName });

  const renderContent = useCallback(() => {
    if (uploadingProgress && withSpinner) {
      return (
        <Progress
          value={uploadingProgress}
          onCancelUploading={onCancelUploading}
        />
      );
    }

    return (
      <Dropzone
        file={importedAddresses.file}
        mult
        onDrop={onDrop}
        accept={{
          'text/csv': ['.csv'],
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
            '.xlsx',
          ],
        }}>
        <div className={classes.content}>
          <Feather
            className={classes.book}
            nativeColor
            type={fileName ? 'emptyBook' : 'book'}
          />
          {fileName ? (
            <Typography className={classes.fileName} variant="h3">
              {fileName}
            </Typography>
          ) : (
            <p className={classes.message}>{selectAddressImportFile}</p>
          )}
          {fileName ? (
            <Button
              className={classes.button}
              data-testid="import-addresses-replace-button">
              Replace
            </Button>
          ) : (
            <p className={classes.text}>Or Drag And Drop It Here</p>
          )}
        </div>
      </Dropzone>
    );
  }, [
    uploadingProgress,
    classes,
    fileName,
    importedAddresses.file,
    onCancelUploading,
    onDrop,
    withSpinner,
  ]);

  return (
    <>
      {incorrectFormatMessage && (
        <Error fontSize={18} error={incorrectFormatMessage} />
      )}
      {renderContent()}
    </>
  );
};

export default FileSelection;
