// Utils
import isProductWithdrawn from './isProductWithdrawn';
import isProductExpired from './isProductExpired';
import isProductOutOfStock from './isProductOutOfStock';

const isProductAvailable = (product) => {
  return (
    !isProductExpired(product) &&
    !isProductWithdrawn(product) &&
    !isProductOutOfStock(product) &&
    product?.is_available_for_user &&
    !product?.offer_inactive
  );
};

export default isProductAvailable;
