// Libs
import React, { useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

// Components
import Button from '../../Button';

// Hooks
import useAddToCartNotification from '../../../pages/Cart/hooks/useAddToCartNotification';

// Constants
import { ADD_ALL_TO_CART_LABEL } from '../../../constants/products';

// Actions
import { AddAllToCart } from '../../../actions/cart';

const useStyles = makeStyles(() => ({
  addAllToCartButton: {},
}));

const AddAllToCartButton = ({ loading, className }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { openModalWindow } = useAddToCartNotification();

  const addAllCachedProductsInCart = useCallback(() => {
    dispatch(AddAllToCart({ openModalWindow }));
  }, [dispatch, openModalWindow]);

  return (
    <Button
      loading={loading}
      id="add-all-to-cart"
      onClick={addAllCachedProductsInCart}
      className={classnames(classes.addAllToCartButton, className)}>
      {ADD_ALL_TO_CART_LABEL}
    </Button>
  );
};

export default AddAllToCartButton;
