// Lib
import { createAction } from '@reduxjs/toolkit';

export const AddToCart = createAction('ADD_TO_CART');
export const AddAllToCart = createAction('ADD_ALL_TO_CART');
export const AddToStateCart = createAction('ADD_TO_STATE_CART');
export const AddOne = createAction('ADD_ONE');
export const RemoveOne = createAction('REMOVE_ONE');
export const DeleteProduct = createAction('DELETE_PRODUCT');
export const DeleteProductInState = createAction('DELETE_PRODUCT_IN_STATE');
export const DeleteUnavailableProducts = createAction(
  'DELETE_UNAVAILABLE_PRODUCTS',
);
export const DeleteAllProducts = createAction('DELETE_ALL_PRODUCTS');
export const DeleteAllProductsInState = createAction(
  'DELETE_ALL_PRODUCTS_IN_STATE',
);
export const SetCount = createAction('SET_COUNT');
export const GetCartProducts = createAction('GET_CART_PRODUCTS');
export const SetCartProducts = createAction('SET_CART_PRODUCTS');
export const SetOverLimitRequest = createAction('SET_OVER_LIMIT_REQUEST');
export const SetMinLengthOverLimitRequest = createAction(
  'SET_MIN_LENGTH_OVER_LIMIT_REQUEST',
);
export const SaveOverLimitRequest = createAction('SAVE_OVER_LIMIT_REQUEST');
export const SaveShippingRequest = createAction('SAVE_SHIPPING_REQUEST');
export const SetShippingDetails = createAction('SET_SHIPPING_DETAILS');
export const SetOverLimitProducts = createAction('SET_OVER_LIMIT_PRODUCTS');
export const GetShippingMethods = createAction('GET_SHIPPING_METHODS');
export const GetShippingNotice = createAction('GET_SHIPPING_NOTICE');
export const PlaceOrder = createAction('PLACE_ORDER');
export const SetPlacedOrder = createAction('SET_PLACED_ORDER');
export const ClearForOrder = createAction('CLEAR_FOR_ORDER');
export const ClearCart = createAction('CLEAR_CART');

export const AddShippingAddress = createAction('ADD_SHIPPING_ADDRESS');
export const SetShippingAddresses = createAction('SET_SHIPPING_ADDRESSES');
export const DeleteShippingAddress = createAction('DELETE_SHIPPING_ADDRESS');
export const ClearShippingAddresses = createAction('CLEAR_SHIPPING_ADDRESSES');
export const ClearShippingDetails = createAction('CLEAR_SHIPPING_DETAILS');
export const ClearShippingDetailsComment = createAction(
  'CLEAR_SHIPPING_DETAILS_COMMENT',
);
export const ClearOrderInfo = createAction('CLEAR_ORDER_INFO');
export const SetProductsForRate = createAction('SET_PRODUCTS_FOR_RATE');
export const UpdateProductRate = createAction('UPDATE_PRODUCT_RATE');

export const GetSaveForLaterProducts = createAction(
  'GET_SAVE_FOR_LATER_PRODUCTS',
);
export const SetSaveForLaterProducts = createAction(
  'SET_SAVE_FOR_LATER_PRODUCTS',
);
export const SetUnavailableLaterProducts = createAction(
  'SET_UNAVAILABLE_LATER_PRODUCTS',
);
export const UpdateSaveForLaterProducts = createAction(
  'UPDATE_SAVE_FOR_LATER_PRODUCTS',
);
export const AddSaveForLaterProduct = createAction(
  'ADD_SAVE_FOR_LATER_PRODUCT',
);
export const DeleteSaveForLaterProduct = createAction(
  'DELETE_SAVE_FOR_LATER_PRODUCT',
);
export const DeleteAllSaveForLaterProducts = createAction(
  'DELETE_ALL_SAVE_FOR_LATER_PRODUCTS',
);
export const PutSaveForLaterProductInCart = createAction(
  'PUT_SAVE_FOR_LATER_PRODUCT_IN_CART',
);
export const DeleteUnavailableLaterProducts = createAction(
  'DELETE_UNAVAILABLE_LATER_PRODUCTS',
);

export const SetCachedCountInCart = createAction('SET_CACHED_COUNT_IN_CART');

export const DeleteAllCachedCountInCart = createAction(
  'DELETE_ALL_CACHED_COUNT_IN_CART',
);

export const ClearEvent = createAction('CLEAR_EVENT');
