// Libs
import { put, takeEvery, call } from 'redux-saga/effects';

// Api
import http from '../../../api/http';

// Actions
import {
  GetContacts,
  SetContacts,
  GetAlgoliaAppInfo,
  SetAlgoliaAppInfo,
} from '../../../actions/support';
import { SetLoading } from '../../../actions/loading';

// Constants
import { SUPPORT_URLS } from '../../../constants/urls';

export function* getContactsSaga() {
  try {
    yield put(SetLoading(true));
    const response = yield call(http.get, SUPPORT_URLS.getContacts);
    yield put(SetContacts(response.data.contact_information));
    yield put(SetLoading(false));
  } catch (e) {
    console.log(e);
  }
}

export function* getAlgoliaAppInfoSaga() {
  try {
    yield put(SetLoading(true));
    const response = yield call(http.get, SUPPORT_URLS.getAlgoliaAppInfo);
    yield put(SetAlgoliaAppInfo(response.data.support_information));
    yield put(SetLoading(false));
  } catch (e) {
    console.log(e);
  }
}

function* supportSaga() {
  yield takeEvery(GetContacts, getContactsSaga);
  yield takeEvery(GetAlgoliaAppInfo, getAlgoliaAppInfoSaga);
}

export default supportSaga;
