// Lib
import React, { useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
// Components
import VirtualGrid from './VirtualGrid';
import VirtualList from './VirtualList';
import OuterElementType from '../VirtualizedList/OuterElementType';
import AddAllToCartButton from './AddAllToCartButton';

// Hooks
import useUI from '../../hooks/useUI';

const useStyles = makeStyles((theme) => ({
  addAllToCartButtonContainer: {
    pointerEvents: 'none',
    position: 'sticky',
    bottom: 85,
    right: 0,
    display: 'flex',
    justifyContent: 'end',
    [theme.breakpoints.down('lg')]: {
      bottom: theme.spacing(2),
    },
  },
  addAllToCartButton: {
    paddingInline: '20px',
    pointerEvents: 'auto',
  },
}));

const Products = ({ products, view = 'list', updateShownProducts }) => {
  const classes = useStyles();
  const isGridView = useMemo(() => view === 'grid', [view]);
  const { isMobileMode } = useUI();
  const { forOrderCached } = useSelector((state) => state.cart);
  const { pathname } = useLocation();

  const isMaterialsPage = pathname === '/materials';

  const cachedProductsQty = useMemo(() => {
    return Object.values(forOrderCached).filter((qty) => qty > 0).length;
  }, [forOrderCached]);

  return (
    <div style={{ position: 'relative' }}>
      {isGridView ? (
        <VirtualGrid
          view={view}
          products={products}
          OuterElementType={OuterElementType}
          updateShownProducts={updateShownProducts}
        />
      ) : (
        <VirtualList
          products={products}
          updateShownProducts={updateShownProducts}
          OuterElementType={OuterElementType}
        />
      )}
      {isMaterialsPage && !isMobileMode && cachedProductsQty > 1 && (
        <div className={classes.addAllToCartButtonContainer}>
          <AddAllToCartButton className={classes.addAllToCartButton} />
        </div>
      )}
    </div>
  );
};

export default Products;
