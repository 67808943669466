// Lib
import React from 'react';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

// Components
import Button from '../../../Button';
import Feather from '../../../Feather';
import Track from '../../../OrderHistory/Buttons/Track';

// Hooks
import useUser from '../../../../hooks/useUser';

const useStyles = makeStyles((theme) => ({
  root: ({ multipleMode }) => ({
    alignItems: 'baseline',
    borderRadius: 20,
    boxShadow: '0px 5px 17px rgba(48, 85, 107, 0.2)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: multipleMode ? 'initial' : 'space-between',
    height: 190,
    padding: theme.spacing(2),
    width: 267,
    [theme.breakpoints.down('lg')]: {
      borderRadius: 10,
      height: 202,
      padding: theme.spacing(1.5),
      width: 224,
    },
    [theme.breakpoints.down('md')]: {
      height: 200,
      marginRight: theme.spacing(2),
      width: 140,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        borderRadius: 10,
        height: 202,
        padding: theme.spacing(1.5),
        width: 224,
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        height: 200,
        marginRight: theme.spacing(2),
        width: 140,
      }),
    },
  }),
  title: {
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 16,
    fontWeight: ({ isKiteUser }) => (isKiteUser ? 700 : 600),
    lineHeight: '20px',
    letterSpacing: '0.1px',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      lineHeight: '16px',
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 12,
        lineHeight: '16px',
      }),
    },
  },
  button: {
    fontSize: '14px',
    padding: 3,
  },
  deleteButton: {
    backgroundColor: theme.palette.lightGrey,
    borderRadius: 4,
    marginBottom: theme.spacing(2),
    maxHeight: 24,
    minWidth: 24,
    padding: 4,
    '&:hover': {
      backgroundColor: `${theme.palette.lightGrey} !important`,
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2.5),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        marginBottom: theme.spacing(2.5),
      }),
    },
  },
  container: {
    width: 267,
    [theme.breakpoints.down('lg')]: {
      width: 224,
    },
    [theme.breakpoints.down('md')]: {
      width: 135,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        width: 224,
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        width: 135,
      }),
    },
  },
}));

const ShippingCard = ({
  title,
  className,
  children,
  onChange,
  multipleMode = false,
  isOrderPlaced = false,
  onDeleteAddress = () => {},
  trackingLink,
  withControls = true,
  dataTestId,
  dataTestIdForCard = '',
}) => {
  const { isKiteUser } = useUser();
  const classes = useStyles({ multipleMode, isKiteUser, isOrderPlaced });

  return (
    <>
      {!isOrderPlaced ? (
        <div
          data-testid={dataTestIdForCard}
          className={classnames(classes.root, className)}>
          {multipleMode && withControls && (
            <Button
              variant="text"
              onClick={onDeleteAddress}
              className={classes.deleteButton}
              data-testid="delete-address-button">
              <Feather type="delete" />
            </Button>
          )}
          <div style={{ width: '100%' }}>
            <div className={classes.title} data-testid={dataTestId}>
              {title}
            </div>
            {children}
          </div>
          {!multipleMode && withControls && (
            <Button
              onClick={onChange}
              variant="text"
              className={classes.button}
              data-testid="change-button">
              Change <Feather printType="stroke" type="arrowRight" />
            </Button>
          )}
        </div>
      ) : (
        <div className={classnames(classes.container, className)}>
          <div className={classes.title} data-testid={dataTestId}>
            {title}
          </div>
          {children}
          {trackingLink && (
            <Track trackingLink={trackingLink} styles={{ paddingLeft: 0 }} />
          )}
        </div>
      )}
    </>
  );
};

export default ShippingCard;
