import React from 'react';
import useValidateProducts from './useValidateProducts';
import EmptyResultPage from './EmptyResultPage';
import StickyButtons from '../../components/Button/StickyButtons';
import ResultList from './ResultList';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import Spinner from '../../components/Spinner';

export default function ResultPage() {
  const { state, isLoading } = useValidateProducts();

  const navigate = useNavigate();
  function handleReject() {
    navigate('/');
  }
  function handleAccept() {
    navigate('/validate');
  }
  if (isLoading) {
    return <Spinner />;
  }

  if (!state?.all || !state) {
    return (
      <>
        <EmptyResultPage />
        <StickyButtons
          onReject={handleReject}
          onAccept={handleAccept}
          submitText={'Next'}
        />
      </>
    );
  }

  return (
    <Box width={'100%'} pb={12}>
      {!!state.available?.product_id && (
        <ResultList validate={state.available} type={'available'} />
      )}
      {!!state.expired?.product_id && (
        <ResultList
          validate={state.expired}
          type={'expired'}
          message={state.configurableMessage?.expired_product_message}
        />
      )}
      {!!state.withdraw?.product_id && (
        <ResultList
          validate={state.withdraw}
          type={'withdraw'}
          message={state.configurableMessage?.withdrawn_product_message}
        />
      )}
      {!!state.outOfStock?.product_id && (
        <ResultList validate={state.outOfStock} type={'outOfStock'} />
      )}

      <StickyButtons
        onReject={handleReject}
        onAccept={handleAccept}
        submitText={'Next'}
      />
    </Box>
  );
}
