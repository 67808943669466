// Lib
import React, { useCallback, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Checkbox, Stack, Tooltip, Typography } from '@mui/material';
import classNames from 'classnames';

// Components
import Button from '../../Button';
import Feather from '../../Feather';
import AddressCardHeader from './AddressCardHeader/AddressCardHeader';
import { ReactComponent as PinImg } from '../../../assets/svg/pin.svg';
import { ReactComponent as PinSmallImg } from '../../../assets/svg/pinSmall.svg';

// Hooks
import useAddress from '../../../pages/AddressBook/hooks/useAddress';
import useUser from '../../../hooks/useUser';

// Constants
import { ADDRESS_CARD_HEIGHTS } from '../../../styles/constants';
import useUI from '../../../hooks/useUI';

const useStyles = makeStyles((theme) => ({
  root: ({ height, checkedAsShippingAddress }) => {
    return {
      backgroundColor: checkedAsShippingAddress ? '#F4F4F4' : 'white',
      borderRadius: 20,
      boxShadow: '0px 5px 15px rgba(44, 112, 172, 0.2)',
      height,
      padding: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        borderRadius: 10,
        padding: theme.spacing(1.5),
      },
      [theme.breakpoints.downLandscape('md')]: {
        ...(theme.isMobileDevice && {
          borderRadius: 10,
          padding: theme.spacing(1.5),
        }),
      },
    };
  },
  container: {
    height: '100%',
    overflow: 'hidden',
  },
  buttons: ({ checkedAsShippingAddress }) => ({
    display: 'flex',
    gap: theme.spacing(1),
    width: '100%',
    '& button': {
      backgroundColor: checkedAsShippingAddress ? 'white' : '#F4F4F4',
      borderRadius: 4,
      maxHeight: 24,
      minWidth: 24,
      padding: 4,
      '&:hover': {
        backgroundColor: checkedAsShippingAddress ? 'white' : '#F4F4F4',
      },
    },
  }),
  fullAddress: ({ isKiteUser, isListView }) => ({
    color: '#54565B',
    display: '-webkit-box',
    '-webkit-line-clamp': isListView ? 2 : 3,
    '-webkit-box-orient': 'vertical',
    letterSpacing: isKiteUser ? '0.1px' : 'initial',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 14,
    lineHeight: isKiteUser ? '20px' : '16px',
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      '-webkit-line-clamp': isListView ? 2 : 5,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        '-webkit-line-clamp': isListView ? 2 : 3,
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 12,
      }),
    },
  }),
  checkbox: {
    '& svg': {
      height: 22,
      width: 22,
    },
  },
  popper: ({ isKiteUser }) => ({
    '& > div': {
      color: '#54565B',
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: '0.1px',
      maxWidth: isKiteUser ? 408 : 384,
      textAlign: 'center',
    },
    [theme.breakpoints.down('md')]: {
      '& > div': {
        fontSize: 12,
        maxWidth: 206,
      },
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        '& > div': {
          fontSize: 12,
          maxWidth: 206,
        },
      }),
    },
  }),
  warning: {
    cursor: 'pointer',
  },
  pinImg: ({ isKiteUser }) => ({
    '& path': {
      stroke: isKiteUser ? theme.palette.primary1 : theme.palette.primary2,
    },
  }),
  mainPinImg: { '& path': { stroke: '#FFFFFF' } },
  mainAddress: {
    marginLeft: 'auto',
  },
  checkedMainAddress: {
    backgroundColor: `${theme.palette.primary1} !important`,
  },
  cartMainAddress: {
    color: theme.palette.primary1,
    '& path': {
      stroke: theme.palette.primary1,
    },
  },
}));

const Card = ({ address, isListView, isAddressBookPage }) => {
  const { isTabletMode, isMobileMode, isDesktopMode } = useUI();
  const {
    onDeleteAddress,
    onMakeMainAddress,
    onEditAddress,
    checkedAsShippingAddress,
    onToggleCheckbox,
    withSelectionCheckbox,
  } = useAddress(address);
  const fullAddress = address.fullAddress || address.full_address;
  const { isKiteUser } = useUser();

  const height = useMemo(() => {
    if (isDesktopMode) {
      if (isListView) {
        return isAddressBookPage
          ? ADDRESS_CARD_HEIGHTS.desktop.addressBookPage.listView
          : ADDRESS_CARD_HEIGHTS.desktop.cartPage.listView;
      }
      if (!isListView) {
        return isAddressBookPage
          ? ADDRESS_CARD_HEIGHTS.desktop.addressBookPage.gridView
          : ADDRESS_CARD_HEIGHTS.desktop.cartPage.gridView;
      }
    }
    if (isTabletMode && !isMobileMode) {
      if (isListView) {
        return isAddressBookPage
          ? ADDRESS_CARD_HEIGHTS.tablet.addressBookPage.listView
          : ADDRESS_CARD_HEIGHTS.tablet.cartPage.listView;
      }
      if (!isListView) {
        return isAddressBookPage
          ? ADDRESS_CARD_HEIGHTS.tablet.addressBookPage.gridView
          : ADDRESS_CARD_HEIGHTS.tablet.cartPage.gridView;
      }
    }
    if (isMobileMode) {
      if (isListView) {
        return isAddressBookPage
          ? ADDRESS_CARD_HEIGHTS.mobile.addressBookPage.listView
          : ADDRESS_CARD_HEIGHTS.mobile.cartPage.listView;
      }
      if (!isListView) {
        return isAddressBookPage
          ? ADDRESS_CARD_HEIGHTS.mobile.addressBookPage.gridView
          : ADDRESS_CARD_HEIGHTS.mobile.cartPage.gridView;
      }
    }
  }, [
    isDesktopMode,
    isTabletMode,
    isMobileMode,
    isListView,
    isAddressBookPage,
  ]);

  const classes = useStyles({
    height,
    checkedAsShippingAddress,
    isKiteUser,
    isAddressBookPage,
    isListView,
  });

  const renderCheckbox = useCallback(() => {
    if (!withSelectionCheckbox) {
      return (
        <Tooltip
          enterTouchDelay={0}
          classes={{
            popper: classes.popper,
          }}
          disableFocusListener
          title="Please Select Either Domestic Or International Addresses.">
          <Feather className={classes.warning} type="warning" />
        </Tooltip>
      );
    }
    return (
      <Checkbox
        className={classes.checkbox}
        onClick={onToggleCheckbox}
        checked={checkedAsShippingAddress}
        data-testid="address-card-checkbox"
      />
    );
  }, [
    withSelectionCheckbox,
    classes,
    onToggleCheckbox,
    checkedAsShippingAddress,
  ]);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Box
          display="flex"
          justifyContent={
            !isAddressBookPage && !address.is_main ? 'end' : 'space-between'
          }>
          {isAddressBookPage && (
            <div className={classes.buttons}>
              <Button onClick={onEditAddress} variant="text">
                <Feather type="edit" />
              </Button>
              <Button variant="text" onClick={onDeleteAddress}>
                <Feather type="delete" />
              </Button>
              <Button
                variant="text"
                onClick={onMakeMainAddress}
                className={
                  address.is_main
                    ? classNames(
                        classes.mainAddress,
                        classes.checkedMainAddress,
                      )
                    : classes.mainAddress
                }>
                <PinImg
                  className={
                    address.is_main ? classes.mainPinImg : classes.pinImg
                  }
                />
                {isListView && (
                  <Typography
                    ml={0.5}
                    variant="textSm"
                    color={address.is_main && '#FFFFFF'}>
                    {address.is_main
                      ? 'Default Address'
                      : 'Make Default Address'}
                  </Typography>
                )}
              </Button>
            </div>
          )}
          {!isAddressBookPage && address.is_main && (
            <Stack
              direction="row"
              alignItems="center"
              columnGap={0.5}
              className={classes.cartMainAddress}>
              <PinSmallImg />
              <Typography variant="textSm">Default Address</Typography>
            </Stack>
          )}
          {!isAddressBookPage && renderCheckbox()}
        </Box>
        <AddressCardHeader
          address={address}
          isListView
          isAddressBookPage={isAddressBookPage}
        />
        {fullAddress && (
          <div
            className={classes.fullAddress}
            data-testid="address-card-full-address">
            {fullAddress}
          </div>
        )}
      </div>
    </div>
  );
};

export default Card;
