// Lib
import React from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    color: ({ isKiteUser }) =>
      isKiteUser ? theme.palette.primary1 : theme.palette.pressed,
    fontWeight: ({ isKiteUser }) => (isKiteUser ? '400' : '600'),
    textDecoration: 'none',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    padding: ({ padding }) => (padding ? padding : 'initial'),
  },
}));

const DetailsButton = ({ to, padding, ...props }) => {
  const classes = useStyles({ padding });

  return (
    <Button
      className={classes.button}
      component={Link}
      to={to}
      endIcon={<ChevronRightIcon />}
      {...props}>
      Details
    </Button>
  );
};

export default DetailsButton;
