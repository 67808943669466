// Fonts
import ProximaNovaRegular from '../assets/fonts/ProximaNova-Regular.ttf';
import ProximaNovaRegularWoff from '../assets/fonts/ProximaNova-Regular.woff';
import ProximaNovaSemiBold from '../assets/fonts/ProximaNova-Semibold.ttf';
import ProximaNovaSemiBoldWoff from '../assets/fonts/ProximaNova-Semibold.woff';
import ProximaNovaBold from '../assets/fonts/ProximaNova-Bold.ttf';
import ProximaNovaBoldWoff from '../assets/fonts/ProximaNova-Bold.woff';

import HelveticaRegular from '../assets/fonts/Helvetica.ttf';
import HelveticaRegularWoff from '../assets/fonts/Helvetica.woff';
import HelveticaBold from '../assets/fonts/Helvetica-Bold.ttf';
import HelveticaBoldWoff from '../assets/fonts/Helvetica-Bold.woff';

// Constants
import USER_TYPE from '../constants/user';

const createFonts = (userType) =>
  userType === USER_TYPE.gilead
    ? `
    @font-face {
        font-family: 'Proxima Nova';
        src: url(${ProximaNovaRegular}) format('trueType');
        font-weight: 400;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Proxima Nova';
        src: url(${ProximaNovaRegularWoff}) format('woff');
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: 'Proxima Nova';
        src: url(${ProximaNovaSemiBold}) format('trueType');
        font-weight: 600;
        font-style: normal;
    }
        
    @font-face {
        font-family: 'Proxima Nova';
        src: url(${ProximaNovaSemiBoldWoff}) format('woff');
        font-weight: 600;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Proxima Nova';
        src: url(${ProximaNovaBold}) format('trueType');
        font-weight: 700;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Proxima Nova';
        src: url(${ProximaNovaBoldWoff}) format('woff');
        font-weight: 700;
        font-style: normal;
    }`
    : `
    @font-face {
        font-family: 'Helvetica';
        src: url(${HelveticaRegular}) format('trueType');
        font-weight: 400;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Helvetica';
        src: url(${HelveticaRegularWoff}) format('woff');
        font-weight: 400;
        font-style: normal;
    }
        
    @font-face {
        font-family: 'Helvetica';
        src: url(${HelveticaBold}) format('trueType');
        font-weight: 700;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Helvetica';
        src: url(${HelveticaBoldWoff}) format('woff');
        font-weight: 700;
        font-style: normal;
    }
`;

export default createFonts;
