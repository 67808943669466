// Lib
import React, { useMemo } from 'react';
import { Button } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
  button: ({ open }) => ({
    backgroundColor: !open ? '#F4F4F4' : 'white',
    borderRadius: 4,
    height: 24,
    minWidth: 20,
    padding: 0,
    '& svg': {
      height: 24,
      width: 24,
    },
  }),
}));

const ToggleButton = ({
  onToggle,
  open,
  UpElement,
  DownElement,
  className,
}) => {
  const classes = useStyles({ open });

  const Up = useMemo(
    () => (UpElement ? UpElement : <ArrowDropUpIcon color="#54565B" />),
    [UpElement],
  );
  const Down = useMemo(
    () => (DownElement ? DownElement : <ArrowDropDownIcon color="#54565B" />),
    [DownElement],
  );

  return (
    <Button
      id="toggle-button"
      onClick={onToggle}
      className={classnames(className, classes.button)}>
      {open ? Up : Down}
    </Button>
  );
};

export default ToggleButton;
