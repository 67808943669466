// Libs
import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import useUser from '../../hooks/useUser';

const CountButtonLabel = ({ className, styles, variant, children }) => {
  const { isKiteUser } = useUser();

  const defaultVariant = useMemo(() => {
    return isKiteUser ? 'textSm' : 'textSmSemibold';
  }, [isKiteUser]);

  return (
    <Typography
      id="additionalInfoContainer"
      variant={variant || defaultVariant}
      color={'error'}
      className={className}
      style={styles}>
      {children}
    </Typography>
  );
};

export default CountButtonLabel;
