// Lib
import * as yup from 'yup';

// Constants
import { REQUIRED_FIELD, ZIP_ERROR } from '../constants/errors';

export const overLimitValidationSchema = (minLengthOverLimitRequest) =>
  yup.object().shape({
    request: yup
      .string()
      .required('This field is required')
      .min(
        minLengthOverLimitRequest,
        `Request must be at least ${minLengthOverLimitRequest} characters`,
      )
      //.max(255, 'Request must be at most 255 characters')
      .test('test', 'Validation failure message', function (value) {
        const modifiedValue = value.toLowerCase().trim();
        if (['na', 'n/a'].includes(modifiedValue)) {
          return this.createError({
            message: 'Avoid n/a or na.',
          });
        }
        return true;
      })
      .trim(),
  });

export const newAddressSchema = yup.object().shape({
  addressType: yup.string().required(REQUIRED_FIELD).trim(),
  company: yup.string().trim(),
  firstName: yup.string().trim(),
  lastName: yup.string().required(REQUIRED_FIELD).trim(),
  phone: yup
    .string()
    .required(REQUIRED_FIELD)
    .transform((value) => value.replace(/\-/g, ''))
    .min(10, 'Invalid Phone Format')
    .trim(),
  address1: yup.string().required(REQUIRED_FIELD).trim(),
  address2: yup.string().trim(),
  country: yup.string().required(REQUIRED_FIELD),
  state: yup.string().when('country', (country, schema) => {
    if (country === 'United States') {
      return yup.string().required(REQUIRED_FIELD);
    }
    return schema;
  }),
  city: yup.string().required(REQUIRED_FIELD).trim(),
  zip: yup
    .string()
    .required(REQUIRED_FIELD)
    .matches(/^[0-9a-zA-Zа-яА-Я -]+$/, ZIP_ERROR)
    .trim(),
});

export const editApproveQtySchema = yup.object().shape({
  orderQty: yup
    .number()
    .min(1, 'Min 1')
    .typeError('Only digits')
    .integer('Only integer'),
});

export const approveResponseSchema = (requiredField) =>
  yup.object().shape({
    approveResponse: requiredField
      ? yup.string().required(REQUIRED_FIELD).trim()
      : yup.string().trim(),
  });

export const scheduleDetailsSchema = yup.object().shape({
  monthNumberForWeekly: yup
    .number()
    .min(1, 'Min 1')
    .typeError('Only digits')
    .integer('Only integer'),
  monthNumberForMonthDay: yup
    .number()
    .min(1, 'Min 1')
    .typeError('Only digits')
    .integer('Only integer'),
  monthNumberForWeekDay: yup
    .number()
    .min(1, 'Min 1')
    .typeError('Only digits')
    .integer('Only integer'),
});
