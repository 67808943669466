// Lib
import React, { useMemo } from 'react';
import { makeStyles } from '@mui/styles';

// Components
import QtyInput from '../../../components/Approvals/OrderQty/QtyInput';
import ActionButtons from '../../../components/Approvals/ProductsList/ActionButtons';

// Hooks
import useProduct from './useProduct';

const useStyles = makeStyles(() => ({
  description: {
    wordBreak: 'break-word',
    display: '-webkit-box',
    margin: 0,
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  status: {
    color: '#929A92',
    fontWeight: 600,
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
}));

const useProductsCellReducers = ({ product, addressesCount, isMethodPage }) => {
  const classes = useStyles();
  const {
    onChangeRequestedQty,
    onOpenConfirmModal,
    addAction,
    canApproveProduct,
    isRejected,
    setOrderQtyError,
    isAlreadyApproved,
  } = useProduct(product.product_id);

  const cells = useMemo(
    () => ({
      productDescription: (
        <div className={classes.description}>{product.product_description}</div>
      ),
      stockNumber: product.product_id,
      manager: product.product_managers?.join(', ') || '',
      unitOfMeasure: product.unit_measure,
      perPackQty: product.prepack_quantity,
      orderQty:
        canApproveProduct &&
        !isRejected &&
        !isAlreadyApproved &&
        !isMethodPage ? (
          <QtyInput
            setOrderQtyError={setOrderQtyError}
            orderLimit={product.qty_ordered}
            onChange={onChangeRequestedQty}
            value={product.qty_approved}
          />
        ) : (
          product.qty_approved
        ),
      totalQty: product.qty_approved * addressesCount,
      maxOrderQty: product.order_limit,
      monthlyLimit: product.monthly_limit,
      annualLimit: product.annual_limit,
      action:
        canApproveProduct && !isAlreadyApproved ? (
          <ActionButtons
            status={product.action}
            onAccept={addAction}
            onReject={onOpenConfirmModal}
          />
        ) : (
          <div className={classes.status}>
            {product.status ? product.status.toLowerCase() : 'Pending Approval'}
          </div>
        ),
    }),
    [
      onChangeRequestedQty,
      onOpenConfirmModal,
      addressesCount,
      canApproveProduct,
      isRejected,
      addAction,
      setOrderQtyError,
      isAlreadyApproved,
      isMethodPage,
      classes,
      product,
    ],
  );

  return { cells };
};

export default useProductsCellReducers;
