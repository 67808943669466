// Libs
import { call, put, takeEvery } from 'redux-saga/effects';

// Api
import http from '../../../api/http';

// Actions
import { SetLoading } from '../../../actions/loading';
import {
  Validate,
  SetValidate,
  SetConfigurableMessage,
  GetConfigurableMessage,
} from '../../../actions/validate';

import { SetApiError } from '../../../actions/apiError';

// Constants
import { SUPPORT_URLS, VALIDATE_URLS } from '../../../constants/urls';
import { COMMON_API_ERROR } from '../../../constants/errors';

export function* getValidatedProductsSaga({ payload: productId }) {
  try {
    yield put(SetValidate([]));
    yield put(SetLoading(true));
    const response = yield call(
      http.get,
      `${VALIDATE_URLS.validate(productId)}`,
    );
    yield put(SetValidate(response.data));
  } catch (error) {
    yield put(
      SetApiError({
        api: 'getValidatedProducts',
        error: COMMON_API_ERROR,
      }),
    );
  } finally {
    yield put(SetLoading(false));
  }
}

export function* getConfigurableMessageSaga() {
  try {
    yield put(SetLoading(true));
    const response = yield call(http.get, SUPPORT_URLS.getShippingNotice);
    yield put(SetConfigurableMessage(response.data));
  } catch (error) {
    yield put(
      SetApiError({
        api: 'getConfigurableMessage',
        error: COMMON_API_ERROR,
      }),
    );
  } finally {
    yield put(SetLoading(false));
  }
}

function* validateProductsSaga() {
  yield takeEvery(Validate, getValidatedProductsSaga);
  yield takeEvery(GetConfigurableMessage, getConfigurableMessageSaga);
}
export default validateProductsSaga;
