import multiSearch from '../../../utils/multiSearch';

export const includeSearchValue = (orderObject, searchValue) => {
  if (!searchValue) {
    return true;
  }

  const searchValues = searchValue.split(' ');

  const searchTargets = [
    orderObject?.address?.full_address,
    orderObject?.address?.address_fields.company,
    orderObject?.address?.address_fields.first_name,
    orderObject?.address?.address_fields.last_name,
    orderObject.tracking_link,
    orderObject.order_sn,
  ];

  return (
    searchTargets.some((target) => multiSearch(target, searchValues)) ||
    orderObject?.order_items?.some((item) =>
      multiSearch(item.product_description, searchValues),
    )
  );
};

export const matchAddressFields = (addressObject, searchValue) => {
  if (!searchValue) {
    return true;
  }
  const { address, full_address } = addressObject;

  return (
    full_address?.toLowerCase().includes(searchValue.toLowerCase()) ||
    address?.company?.toLowerCase().includes(searchValue.toLowerCase()) ||
    address?.first_name?.toLowerCase().includes(searchValue.toLowerCase()) ||
    address?.last_name?.toLowerCase().includes(searchValue.toLowerCase())
  );
};
