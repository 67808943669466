// Libs
import React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { makeStyles } from '@mui/styles';

// Hooks
import useUser from '../../../hooks/useUser';
import useUI from '../../../hooks/useUI';

const resolveSubtabColor = (theme, isKiteUser, isActive) => {
  if (!isActive) {
    return theme.palette.grey2;
  } else {
    return isKiteUser ? theme.palette.primary2 : theme.palette.primary1;
  }
};

const useStyles = makeStyles((theme) => ({
  tabs: {
    overflowX: 'auto',
    '-ms-overflow-style': 'none' /* IE and Edge */,
    scrollbarWidth: 'none' /* Firefox */,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  tab: ({ isKiteUser, isActive }) => ({
    color: resolveSubtabColor(theme, isKiteUser, isActive),
    backgroundColor: isActive ? theme.palette.lightGrey : 'transparent',
    ...theme.typography.textSm,
    [theme.breakpoints.down('lg')]: {
      '&:focus': {
        backgroundColor: ({ isActive }) => isActive && theme.palette.lightGrey,
      },
      '&:hover': {
        backgroundColor: ({ isActive }) => !isActive && 'inherit',
      },
    },
  }),
}));

const SubnavigationTab = ({ item, isActive, setActiveItem }) => {
  const { isDesktopMode } = useUI();
  const { isKiteUser } = useUser();
  const classes = useStyles({ isKiteUser, isActive });
  return (
    <Chip
      size={isDesktopMode ? 'small' : 'medium'}
      label={item.label}
      onClick={() =>
        isActive ? setActiveItem(null) : setActiveItem(item.label)
      }
      className={classes.tab}
    />
  );
};

const SubnavigationBar = (props) => {
  const classes = useStyles();
  const { items, activeItem, setActiveItem } = props;
  const { isTabletMode } = useUI();

  return (
    <Stack
      direction="row"
      spacing={isTabletMode ? 1.5 : 2.5}
      className={classes.tabs}>
      {items.map((item) => (
        <SubnavigationTab
          key={item.key}
          item={item}
          isActive={item.label === activeItem}
          setActiveItem={setActiveItem}
        />
      ))}
    </Stack>
  );
};

export default SubnavigationBar;
