/* eslint-disable react/display-name */
// Lib
import React, { useMemo, forwardRef, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Select as MuiSelect } from '@mui/material';
import { makeStyles } from '@mui/styles';

// Components
import { ReactComponent as Arrow } from '../../../assets/svg/arrow.svg';

// Styles
import { colors } from '../../../styles/palette';
import Feather from '../../Feather';

const useStyles = makeStyles((theme) => ({
  disabled: {
    '&.MuiSvgIcon-root.MuiSelect-icon': {
      stroke: `${theme.palette.grey1} !important`,
      color: `${theme.palette.grey1} !important`,
    },
  },
  paper: ({ classic, width }) => ({
    width: width || 'initial',
    backgroundColor: classic ? 'none' : theme.palette.lightGrey,
    borderRadius: 12,
    border: classic ? '1px solid #929A92' : 'none',
    maxHeight: '30vh',
    marginTop: 15,
    '& ul': {
      paddingBottom: 4,
      paddingTop: 4,
    },
    '& li': {
      whiteSpace: 'normal',
      height: 'auto',
    },
  }),
  error: {
    color: colors.gilead.primary1,
    fontSize: 14,
  },
  selectPlaceholder: {
    color: theme.palette.grey1,
  },
  removeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: theme.spacing(3.5),
    top: theme.spacing(1.5),
    zIndex: 1,
    fill: theme.palette.darkGrey,
  },
}));

const Select = forwardRef(
  (
    {
      width,
      items = [],
      onChange = () => {},
      className,
      label = '',
      required = false,
      classic = false,
      initialValue = '',
      error,
      disabled = false,
      placeholder = '',
      withRemoveIcon = false,
      name: selectName,
      ...props
    },
    ref,
  ) => {
    const classes = useStyles({ classic, error, width });
    const [value, setValue] = useState(initialValue);

    useEffect(() => setValue(initialValue), [initialValue]);

    const handleChange = (event) => {
      setValue(event.target.value);
    };

    const id = useMemo(() => {
      let id = '';
      if (error) {
        id = 'select-error';
      }
      if (classic) {
        return `classic-select ${id}`.trim();
      }
      return `select ${id}`.trim();
    }, [classic, error]);

    return (
      <Box sx={{ width }} className={className}>
        {label && (
          <Box style={{ fontWeight: 600 }} mb={1}>
            {`${label} `}
            {required ? (
              <span style={{ color: colors.gilead.primary1 }}>*</span>
            ) : (
              ''
            )}
          </Box>
        )}
        <FormControl fullWidth id={id} {...props}>
          <MuiSelect
            classes={{ disabled: classes.disabled }}
            disabled={disabled}
            ref={ref}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            IconComponent={classic ? undefined : Arrow}
            id="simple-select"
            value={value}
            onChange={handleChange}
            MenuProps={{ classes: { paper: classes.paper } }}
            renderValue={(selected) =>
              selected ? (
                <span>{selected}</span>
              ) : (
                <>
                  <span className={classes.selectPlaceholder}>
                    {placeholder}
                  </span>
                  {required && !label && (
                    <span
                      style={{
                        marginLeft: '4px',
                        color: colors.gilead.primary1,
                      }}>
                      *
                    </span>
                  )}
                </>
              )
            }>
            {items?.map((item) => {
              const { title, label, name } = item;
              return (
                <MenuItem
                  onClick={onChange(title || item, selectName)}
                  key={title || name}
                  value={label || name}>
                  {label || name}
                </MenuItem>
              );
            })}
          </MuiSelect>
          {withRemoveIcon && (
            <Feather
              className={classes.removeIcon}
              type="close"
              onClick={onChange({ name: '' })}
            />
          )}
        </FormControl>
        {error && <div className={classes.error}>{error}</div>}
      </Box>
    );
  },
);

export default Select;
