// Lib
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Collapse, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

// Components
import Reorder from '../../../Button/ReorderButton/ReorderButton';
import Feather from '../../../Feather';
import InfoCard from '../InfoCard';
import IconButton from '../../../../components/Button/IconButton/IconButton';

// Hooks
import useUI from '../../../../hooks/useUI';
import useAddToKits from '../../../../pages/Kits/hooks/useAddToKits';

const OrderSection = ({ orderDetails, onReorder, isRecurring, canReorder }) => {
  const { isMobileMode } = useUI();
  const [isOrderDetailsOpen, setIsOrderDetailsOpen] = useState(false);
  const { onAddToMyKits } = useAddToKits(orderDetails?.id);

  return (
    <>
      {!isMobileMode ? (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            {orderDetails?.address && (
              <IconButton
                disabled={!orderDetails.can_be_added_to_kit}
                onClick={onAddToMyKits}
                startIcon={<AddIcon />}>
                Add To My Kits
              </IconButton>
            )}
          </Box>
          {orderDetails?.address && (
            <Reorder
              order={orderDetails}
              onClick={onReorder}
              inTable={false}
              disabled={!canReorder}
            />
          )}
        </Box>
      ) : (
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          {orderDetails.address && (
            <Reorder
              order={orderDetails}
              onClick={onReorder}
              inTable={false}
              disabled={!canReorder}
            />
          )}
          <Box
            display="flex"
            flexDirection="column"
            mt={{ xs: 1 }}
            mb={{ xs: 3.5 }}>
            <Box
              display={'flex'}
              alignContent={'center'}
              justifyContent={'space-between'}
              mb={isOrderDetailsOpen ? 2 : 0}>
              <Box display={'flex'} alignContent={'center'}>
                <Typography
                  style={{
                    fontWeight: '700',
                    fontSize: '16px',
                    lineHeight: '24px',
                    marginTop: '5px',
                  }}>
                  Order info
                </Typography>
                <Feather
                  id="collapsed-icon"
                  colorType="text.primary"
                  style={{ marginTop: '2px' }}
                  type={
                    isOrderDetailsOpen ? 'collapseClosed' : 'collapseOpened'
                  }
                  onClick={() =>
                    setIsOrderDetailsOpen(!isOrderDetailsOpen)
                  }></Feather>
              </Box>
              <IconButton
                disabled={!orderDetails.can_be_added_to_kit}
                onClick={() => onAddToMyKits(orderDetails.id)}
                startIcon={<AddIcon />}>
                Add To My Kits
              </IconButton>
            </Box>
            {!isRecurring && (
              <Collapse in={isOrderDetailsOpen}>
                <InfoCard orderDetails={orderDetails} />
              </Collapse>
            )}
          </Box>
        </Box>
      )}
      {!isMobileMode && !isRecurring && (
        <InfoCard orderDetails={orderDetails} />
      )}
    </>
  );
};

export default OrderSection;
