// Lib
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { isMobile } from 'react-device-detect';

// Styles
import breakpoints from '../styles/breakpoints';

const useUI = () => {
  const theme = useTheme();
  const deltaViewportScreen = +localStorage.getItem('deltaViewportScreen') || 0;

  const isDesktopLandscape = useMediaQuery(
    `(min-height: ${
      breakpoints.values.lg - deltaViewportScreen
    }px) and (orientation: landscape)`,
  );
  const isTabletLandscape = useMediaQuery(
    `(max-height: ${
      breakpoints.values.lg - deltaViewportScreen - 0.05
    }px) and (orientation: landscape)`,
  );
  const isMobileLandscape = useMediaQuery(
    `(max-height: ${
      breakpoints.values.md - deltaViewportScreen - 0.05
    }px) and (orientation: landscape)`,
  );
  const isUpMdLandscape = useMediaQuery(
    `(min-height: ${
      breakpoints.values.md - deltaViewportScreen
    }px) and (orientation: landscape)`,
  );
  const isSmallMobileLandscape = useMediaQuery(
    `(max-height: ${
      breakpoints.values.sm - deltaViewportScreen - 0.05
    }px) and (orientation: landscape)`,
  );

  const isLandscapeOrientation = useMediaQuery(
    `screen and (orientation: landscape)`,
  );

  const isDesktopMode = useMediaQuery(theme.breakpoints.up('lg'));
  const isTabletMode = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobileMode = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallMobileMode = useMediaQuery(theme.breakpoints.down('sm'));
  const isUpMdMode = useMediaQuery(theme.breakpoints.up('md'));

  return {
    isDesktopMode:
      isLandscapeOrientation && isMobile ? isDesktopLandscape : isDesktopMode,
    isTabletMode:
      isLandscapeOrientation && isMobile ? isTabletLandscape : isTabletMode,
    isMobileMode:
      isLandscapeOrientation && isMobile ? isMobileLandscape : isMobileMode,
    isSmallMobileMode:
      isLandscapeOrientation && isMobile
        ? isSmallMobileLandscape
        : isSmallMobileMode,
    isUpMdMode:
      isLandscapeOrientation && isMobile ? isUpMdLandscape : isUpMdMode,
    isMobileDevice: isMobile,
    isMobileLandscapeMode: isMobile && isLandscapeOrientation,
  };
};

export default useUI;
