import { useState } from 'react';
import { GetConfigurableMessage, Validate } from '../../actions/validate';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function useQrReader() {
  const navigate = useNavigate();
  const [isQrOpen, setIsQrOpen] = useState(false);
  const [qrError, setQrError] = useState(null);
  const dispatch = useDispatch();
  const handleScan = (data) => {
    if (data) {
      dispatch(Validate(data));
      dispatch(GetConfigurableMessage());
      setIsQrOpen(false);
      navigate('./results');
    }
  };

  const handleError = (err) => {
    console.error(err);
    setQrError('Failed to read QR code. Please try again.');
  };

  const toggleQrOpen = () => {
    setIsQrOpen(!isQrOpen);
  };

  return { isQrOpen, qrError, handleScan, handleError, toggleQrOpen };
}
