// Lib
import React, { useEffect, useMemo } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Components
import Layout from '../../../components/Layout';
import OrderQtyApprovals from '../../../components/Approvals/OrderQty';
import ShippingMethodApproval from '../../../components/Approvals/ShippingMethod';
import Spinner from '../../../components/Spinner';

// Actions
import {
  GetApprovalOrder,
  SetApprovalOrderId,
} from '../../../actions/approvals';
import { SetPrevPath } from '../../../actions/location';

// Hooks
import useUser from '../../../hooks/useUser';
import Error from '../../../components/Error';

// Constants
import { SSO_LOGIN_PAGE, HOME_PAGE } from '../../../constants/urls';

const Order = ({ isShippingMethod = false }) => {
  const navigate = useNavigate();
  const { isLoading, apiError } = useSelector((state) => ({
    isLoading: state.isLoading,
    apiError: state.apiError?.['getApprovalOrder'],
  }));
  const dispatch = useDispatch();
  const { id } = useParams();
  const { isLoggedIn } = useUser();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(SetPrevPath(pathname));
      return navigate(SSO_LOGIN_PAGE);
    }
    if (!id) {
      return navigate(HOME_PAGE);
    }
    dispatch(SetApprovalOrderId(id));
    dispatch(GetApprovalOrder(id));
  }, [navigate, dispatch, id, isLoggedIn, pathname]);

  const content = useMemo(() => {
    if (isLoading) {
      return <Spinner />;
    }
    if (apiError) {
      return <Error message={apiError} />;
    }
    if (!isShippingMethod) {
      return <OrderQtyApprovals />;
    }
    return <ShippingMethodApproval />;
  }, [isLoading, isShippingMethod, apiError]);

  return <Layout>{content}</Layout>;
};

export default Order;
