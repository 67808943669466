// Lib
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

// Components
import Button from '../../Button';
import Feather from '../../Feather';

// Actions
import { GetFDMDocument } from '../../../actions/products';

const FdmButton = ({ className, product, styles }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const { fdm_link, product_id: id } = product || {};

  const onClick = useCallback(
    () => dispatch(GetFDMDocument({ id, setLoading })),
    [dispatch, id],
  );

  return (
    <>
      {fdm_link && (
        <Button
          loadingIndicator="Loading…"
          loading={loading}
          styles={{ fontSize: 12, ...styles }}
          onClick={onClick}
          id="fdm-button"
          className={className}
          color="secondary">
          {!loading && (
            <Feather
              printType="stroke"
              style={{ marginRight: 8 }}
              type="arrowDown"
            />
          )}
          FDM
        </Button>
      )}
    </>
  );
};

export default FdmButton;
