// Lib
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Utils
import { sortingRecurringItems } from '../../../constants/orderHistory';

// Hooks
import useUI from '../../../hooks/useUI';
import { matchAddressFields } from '../utils/filterFunctions';

// Selectors
import { productsObjSelector } from '../../../store/selectors';

const useOrdersHistoryFilters = () => {
  const { searchValue, orders, recurringOrders, productsObj } = useSelector(
    (state) => ({
      searchValue: state.search,
      recurringOrders: state.orders.recurring,
      productsObj: productsObjSelector(state),
    }),
  );

  const [filteredOrders, setFilteredOrders] = useState(null);
  const { isMobileMode } = useUI();
  const [sorting, setSorting] = useState(sortingRecurringItems[0]);
  const [order, setOrder] = useState('desc');

  const applyFilters = useCallback(() => {
    let filteredOrders;

    if (!recurringOrders?.length) {
      return;
    }

    filteredOrders = [...recurringOrders].sort((a, b) => {
      return order === 'asc'
        ? new Date(a.next_run) - new Date(b.next_run)
        : new Date(b.next_run) - new Date(a.next_run);
    });

    filteredOrders = filteredOrders.filter((order) => {
      if (
        order.order_items.some((orderItem) => {
          const product = productsObj[orderItem.product];
          if (!product) {
            return;
          }
          const { product_id, product_description } = product;
          return (
            product_id.toLowerCase().includes(searchValue.toLowerCase()) ||
            product_description
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          );
        })
      ) {
        return true;
      }

      return order.addresses.some((address) =>
        matchAddressFields(address, searchValue),
      );
    });

    return filteredOrders;
  }, [recurringOrders, order, searchValue, productsObj]);

  const changeOrder = () =>
    setOrder((prevOrder) => (prevOrder === 'desc' ? 'asc' : 'desc'));

  const onChangeSorting = useCallback(
    (title) => () => {
      setSorting(
        sortingRecurringItems.find((item) => item.title === title) ||
          sortingRecurringItems[0],
      );
    },
    [],
  );

  const onChangeMobileSorting = (title) => {
    setSorting(
      sortingRecurringItems.find((item) => item.title === title) ||
        sortingRecurringItems[0],
    );
  };

  const applyMobileFilters = () => {
    setFilteredOrders(applyFilters());
  };

  useEffect(() => {
    if (isMobileMode) {
      return;
    }

    if (!recurringOrders) {
      return;
    }

    setFilteredOrders(applyFilters());
  }, [
    sorting,
    order,
    searchValue,
    setFilteredOrders,
    isMobileMode,
    recurringOrders,
    orders,
  ]);

  useEffect(() => {
    if (isMobileMode) {
      applyMobileFilters();
    }
  }, [isMobileMode, searchValue, recurringOrders]);

  return {
    sorting,
    order,
    onChangeSorting,
    changeOrder,
    filteredOrders,
    onChangeMobileSorting,
    applyMobileFilters,
    setFilteredOrders,
    sortingItems: sortingRecurringItems,
  };
};

export default useOrdersHistoryFilters;
