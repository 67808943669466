// Lib
import { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

// Api
import http from '../../../api/http';

// Constants
import { BASE_URL, SSO_LOGIN_PAGE } from '../../../constants/urls';
import { COMMON_API_ERROR } from '../../../constants/errors';

const useSkipFromEmail = () => {
  const pathname = useLocation();
  const navigate = useNavigate();
  const { orderId } = useParams();
  const { search } = pathname;
  const [loading, setLoading] = useState(true);
  const [recurringPath, setRecurringPath] = useState('');
  const [message, setMessage] = useState('');
  const [nextShipping, setNextShipping] = useState('');
  const [responseType, setResponseType] = useState('');
  const [isLaunched, setIsLaunched] = useState(false);

  useEffect(() => {
    if (search) {
      setRecurringPath(search);
      navigate(`/order-history/recurring/${orderId}/skip_from_email`);
    }
  }, [search, navigate, orderId]);

  useEffect(() => {
    if (!search && !recurringPath) {
      navigate(SSO_LOGIN_PAGE);
    }
  }, [search, recurringPath, navigate]);

  const skipFromEmail = useCallback(async () => {
    if (!recurringPath) {
      return;
    }

    try {
      const result = await http.get(
        `${BASE_URL}/api/v1/recurring_orders/${orderId}/skip_from_email/${recurringPath}`,
      );

      const { next_run } = result.data;
      // get date in 00 hours, 00 minutes, 00 seconds
      const dateIn00 = parseISO(next_run);

      setResponseType('success');
      setMessage('Your upcoming recurring order is skipped now.');
      setNextShipping(format(dateIn00, 'MMMM do'));
    } catch (e) {
      setResponseType('error');
      if (e.response.status === 400) {
        setMessage(e.response.data.detail);
      } else {
        setMessage(COMMON_API_ERROR);
      }
    } finally {
      setLoading(false);
    }
  }, [orderId, recurringPath]);

  useEffect(() => {
    if (recurringPath && !search && !isLaunched) {
      skipFromEmail();
      setIsLaunched(true);
    }
  }, [recurringPath, search, isLaunched]);

  return { loading, message, nextShipping, responseType };
};

export default useSkipFromEmail;
