// Lib
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

// Components
import SavedInfoCard from '../../Cards/SavedInfoCard';
import RequestCard from './RequestCard';

// Hooks
import useRequest from '../../../pages/Cart/hooks/useRequest';

const Request = ({ id, isOverLimitRequest }) => {
  const {
    onSubmit,
    onChange,
    loading,
    minLengthOverLimitRequest,
    isRequestSaved,
    savedRequest,
    apiError,
  } = useRequest(id, isOverLimitRequest);
  const { pathname } = useLocation();

  const buttonTitle = useMemo(() => {
    if (isOverLimitRequest && pathname === '/cart') {
      return 'Close';
    }

    return 'Close';
  }, [isOverLimitRequest, pathname]);

  return (
    <>
      {isRequestSaved ? (
        <SavedInfoCard
          btnTitle={buttonTitle}
          message={
            isOverLimitRequest
              ? 'Your extension request has been successfully saved'
              : 'Your shipping request has been successfully saved'
          }
        />
      ) : (
        <RequestCard
          isOverLimitRequest={isOverLimitRequest}
          onChange={onChange}
          loading={loading}
          minLengthOverLimitRequest={minLengthOverLimitRequest}
          onSubmit={onSubmit}
          savedRequest={savedRequest}
          apiError={apiError}
        />
      )}
    </>
  );
};

export default Request;
