// Lib
import { createTheme as create } from '@mui/material/styles';
import { isMobile, isTablet } from 'react-device-detect';

import breakpoints from './breakpoints';
import createTypography from './typography';
import createPalette from './palette';
import createComponents from './components';

const theme = (userType) => ({
  breakpoints,
  palette: createPalette(userType),
  typography: createTypography(userType),
  components: createComponents(userType),
  isMobileDevice: isMobile,
  isTablet,
});

const createTheme = (userType) => create(theme(userType));

export default createTheme;
