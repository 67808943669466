// Lib
import React from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';

import { Typography } from '@mui/material';
// Components
import Button from '../../../components/Button';
import Layout from '../../../components/Layout';

// URLS
import { BASE_URL, SSO_LOGIN_PAGE } from '../../../constants/urls';

const useStyles = makeStyles((theme) => ({
  layout: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    minHeight: 'calc(100vh - 80px)',
    [theme.breakpoints.down('lg')]: {
      minHeight: 'calc(100vh - 70px)',
    },
    [theme.breakpoints.down('md')]: {
      minHeight: 'calc(100vh - 54px)',
    },
  },
  text: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(3),
      textAlign: 'center',
      width: 210,
    },
  },
  button: {
    padding: '10px 16px',
    maxHeight: 44,
    width: 130,
  },
}));

const Logout = () => {
  const classes = useStyles();

  const onClick = () => {
    window.location.href = `${BASE_URL}${SSO_LOGIN_PAGE}`;
  };

  return (
    <Layout hideHeaderLinks={true} className={{ layout: classes.layout }}>
      <Box display="flex" alignItems="center" flexDirection="column">
        <Typography className={classes.text} variant="h1">
          You have logged out of GMOP.
        </Typography>
        <Button id="sign-back" onClick={onClick} className={classes.button}>
          Sign back
        </Button>
      </Box>
    </Layout>
  );
};

export default Logout;
