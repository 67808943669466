// Lib
import React, { useCallback, useEffect } from 'react';
import {
  Route,
  Routes as Switch,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Components
import CartAndForLater from './CartAndForLater';
import AddressBook from '../AddressBook';
import FooterButtons from '../../components/Cart/ShippingDetails/FooterButtons';
import ShippingDetails from './ShippingDetails';
import ReviewOrder from './ReviewOrder';

// Hooks
import useOverLimitProducts from './hooks/useOverLimitProducts';
import useProductsForRemoving from './hooks/useProductsForRemoving';
import useCartSorting from '../../hooks/useCartSorting';

// Actions
import {
  ClearOrderInfo,
  ClearShippingDetails,
  ClearShippingDetailsComment,
} from '../../actions/cart';

const Cart = ({ userType }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useOverLimitProducts();
  const onClickBack = useCallback(
    (pathname = '') =>
      navigate('/cart', {
        state: {
          navigateFrom: pathname,
        },
      }),
    [navigate],
  );

  const { unavailableProducts } = useProductsForRemoving();

  const onClickToShippingDetails = useCallback(() => {
    navigate('/cart/shipping-details');
    window.scroll(0, 0);
  }, [navigate]);

  const isShippingAddressSelected = useSelector(
    (state) => !!state.cart.shippingAddresses.length,
  );

  useEffect(() => {
    return () => dispatch(ClearOrderInfo());
  }, [dispatch]);

  const { state, pathname } = useLocation();

  useEffect(() => {
    if (pathname === '/cart') {
      if (
        state?.navigateFrom === '/cart/shipping-address' ||
        state?.navigateFrom === '/cart/shipping-details' ||
        state?.navigateFrom === '/cart/review-order'
      ) {
        dispatch(ClearOrderInfo());
      }
    }

    if (pathname === '/cart/shipping-address') {
      if (
        state?.navigateFrom === '/cart/shipping-details' ||
        state?.navigateFrom === '/cart/review-order'
      ) {
        dispatch(ClearShippingDetails());
      }
    }

    if (pathname === '/cart/shipping-details') {
      if (state?.navigateFrom === '/cart/review-order') {
        dispatch(ClearShippingDetailsComment());
      }
    }
  }, [state, pathname, dispatch]);

  useCartSorting();

  return (
    <Switch>
      <Route
        path=""
        element={<CartAndForLater unavailableProducts={unavailableProducts} />}
      />
      <Route
        path="shipping-address"
        element={
          <AddressBook isAddressBookPage={false} userType={userType}>
            <FooterButtons
              nextButtonDisabled={!isShippingAddressSelected}
              onClickBack={onClickBack}
              onClickNext={onClickToShippingDetails}
              backTitle="Total Items"
            />
          </AddressBook>
        }
      />
      <Route path="shipping-details" element={<ShippingDetails />} />
      <Route path="review-order" element={<ReviewOrder />} />
    </Switch>
  );
};

export default Cart;
