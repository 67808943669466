// Lib
import { createAction } from '@reduxjs/toolkit';

export const UserLogIn = createAction('USER_LOG_IN');
export const UserSsoLogIn = createAction('USER_SSO_LOG_IN');
export const UserLogOut = createAction('USER_LOG_OUT');
export const GetUser = createAction('GET_USER');
export const SetUser = createAction('SET_USER');
export const SaveCatalogView = createAction('SAVE_CATALOG_VIEW');
export const ClearUser = createAction('CLEAR_USER');
