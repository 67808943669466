// Libs
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from 'react';
import { FixedSizeGrid as Grid } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

// Components
import AddressCard from '../Card';
import TotalQtyLabel from '../../../components/Labels/TotalQtyLabel';

// Constants
import { ADDRESS_CARD_HEIGHTS } from '../../../styles/constants';

// Hooks
import useUI from '../../../hooks/useUI';

const CELL_GAP = 20;
const CELL_GAP_MOBILE = 16;

const Cell = ({ columnIndex, rowIndex, style, data }) => {
  const {
    addressBook,
    isMobileMode,
    columnCount,
    isListView,
    isAddressBookPage,
  } = data;
  const index = rowIndex * columnCount + columnIndex;
  const address = addressBook[index];

  const left =
    Number(style?.left) +
    columnIndex * (isMobileMode ? CELL_GAP_MOBILE : CELL_GAP);

  const styles = {
    ...style,
    left: columnIndex === 0 ? style.left : left,
    pointerEvents: 'auto',
  };

  if (!address) {
    return;
  }

  return (
    <div style={styles}>
      <AddressCard
        isListView={isListView}
        key={`${address.title}_${index}`}
        address={address}
        isAddressBookPage={isAddressBookPage}
      />
    </div>
  );
};

const List = ({
  isListView,
  OuterElementType,
  addressBook,
  updateShownAddresses,
  isAddressBookPage,
}) => {
  const { isTabletMode, isMobileMode, isDesktopMode } = useUI();
  const gridContainerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(window.innerWidth);
  const desktopHeight = useMemo(() => {
    if (isListView) {
      return isAddressBookPage
        ? ADDRESS_CARD_HEIGHTS.desktop.addressBookPage.listView
        : ADDRESS_CARD_HEIGHTS.desktop.cartPage.listView;
    }
    if (!isListView) {
      return isAddressBookPage
        ? ADDRESS_CARD_HEIGHTS.desktop.addressBookPage.gridView
        : ADDRESS_CARD_HEIGHTS.desktop.cartPage.gridView;
    }
  }, [isListView, isAddressBookPage]);
  const tabletHeight = useMemo(() => {
    if (isListView) {
      return isAddressBookPage
        ? ADDRESS_CARD_HEIGHTS.tablet.addressBookPage.listView
        : ADDRESS_CARD_HEIGHTS.tablet.cartPage.listView;
    }
    if (!isListView) {
      return isAddressBookPage
        ? ADDRESS_CARD_HEIGHTS.tablet.addressBookPage.gridView
        : ADDRESS_CARD_HEIGHTS.tablet.cartPage.gridView;
    }
  }, [isListView, isAddressBookPage]);
  const mobileHeight = useMemo(() => {
    if (isListView) {
      return isAddressBookPage
        ? ADDRESS_CARD_HEIGHTS.mobile.addressBookPage.listView
        : ADDRESS_CARD_HEIGHTS.mobile.cartPage.listView;
    }
    if (!isListView) {
      return isAddressBookPage
        ? ADDRESS_CARD_HEIGHTS.mobile.addressBookPage.gridView
        : ADDRESS_CARD_HEIGHTS.mobile.cartPage.gridView;
    }
  }, [isListView, isAddressBookPage]);

  const columnCount = useMemo(() => {
    let count = isListView ? 0 : 1;
    if (isDesktopMode && !isTabletMode) {
      return count + 3;
    }
    if (isTabletMode && !isMobileMode) {
      return count + 2;
    }
    if (isMobileMode) {
      return count + 1;
    }
  }, [isDesktopMode, isTabletMode, isMobileMode, isListView]);

  const onResize = useCallback(() => {
    setContainerWidth(gridContainerRef?.current?.clientWidth);
  }, [gridContainerRef.current]);

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [gridContainerRef.current, onResize]);

  const columnWidth = useMemo(() => {
    let factor = isListView ? 0 : 1;
    const gridWidth = containerWidth;
    let delta = CELL_GAP * (2 + factor);
    if (isTabletMode) {
      delta = CELL_GAP_MOBILE * (factor + 1);
    }
    if (isMobileMode) {
      delta = CELL_GAP_MOBILE * factor;
    }
    return (gridWidth - delta) / columnCount;
  }, [isMobileMode, isTabletMode, columnCount, containerWidth, isListView]);

  const rowHeight = useMemo(() => {
    if (isDesktopMode) {
      return desktopHeight + CELL_GAP;
    }
    if (isTabletMode && !isMobileMode) {
      return tabletHeight + CELL_GAP;
    }
    if (isMobileMode) {
      return mobileHeight + CELL_GAP_MOBILE;
    }
  }, [
    isDesktopMode,
    isTabletMode,
    isMobileMode,
    desktopHeight,
    tabletHeight,
    mobileHeight,
  ]);

  return (
    <div ref={gridContainerRef}>
      <InfiniteLoader
        isItemLoaded={(index) => {
          return addressBook.shown[index] !== undefined;
        }}
        itemCount={addressBook.all.length}
        loadMoreItems={updateShownAddresses}
        treshold={3}>
        {({ onItemsRendered, ref }) => {
          const newItemsRendered = (gridData) => {
            const useOverscanForLoading = true;
            const {
              visibleRowStartIndex,
              visibleRowStopIndex,
              visibleColumnStopIndex,
              overscanRowStartIndex,
              overscanRowStopIndex,
              overscanColumnStopIndex,
            } = gridData;

            const endCol =
              (useOverscanForLoading || true
                ? overscanColumnStopIndex
                : visibleColumnStopIndex) + 1;

            const startRow =
              useOverscanForLoading || true
                ? overscanRowStartIndex
                : visibleRowStartIndex;
            const endRow =
              useOverscanForLoading || true
                ? overscanRowStopIndex
                : visibleRowStopIndex;

            const visibleStartIndex =
              startRow * (endCol + (columnCount === 3 ? 0 : 1));
            const visibleStopIndex =
              endRow * (endCol + (columnCount === 3 ? 0 : 1));

            onItemsRendered({
              visibleStartIndex,
              visibleStopIndex,
            });
          };

          return (
            <>
              <TotalQtyLabel
                qty={addressBook.afterFilters.length}
                type={'address'}
                ending={'es'}
                styles={{ marginBottom: '16px' }}
              />
              <Grid
                columnCount={columnCount}
                columnWidth={columnWidth}
                height={window.innerHeight}
                rowCount={Math.ceil(addressBook.shown.length / columnCount)}
                rowHeight={rowHeight}
                width={window.innerWidth}
                outerElementType={
                  process.env.NODE_ENV !== 'test' && OuterElementType
                }
                itemData={{
                  addressBook: addressBook.shown,
                  isMobileMode,
                  columnCount,
                  isListView,
                  isAddressBookPage,
                }}
                onItemsRendered={newItemsRendered}
                ref={ref}>
                {Cell}
              </Grid>
            </>
          );
        }}
      </InfiniteLoader>
    </div>
  );
};

export default List;
