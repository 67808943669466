// Lib
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    borderRadius: 8,
    color: '#C4C4C4',
    cursor: 'pointer',
    fontSize: 34,
    position: 'absolute',
    right: theme.spacing(2.5),
    top: theme.spacing(2.5),
    zIndex: 1,
    '&:hover': {
      backgroundColor: theme.palette.lightGrey,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
      right: theme.spacing(1.5),
      top: theme.spacing(1.5),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 24,
        right: theme.spacing(1.5),
        top: theme.spacing(1.5),
      }),
    },
  },
}));

const CloseButton = ({ onClose, className, ...props }) => {
  const classes = useStyles();

  return (
    <CloseIcon
      {...props}
      onClick={onClose}
      className={classnames(classes.closeIcon, className)}
    />
  );
};

export default CloseButton;
