// Components
import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

// Components
import Button from '../../Button';

// Hooks
import use2Modal from '../../../hooks/use2Modal';
import Feather from '../../Feather';
import useUser from '../../../hooks/useUser';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 620,
    height: 378,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 44,
    paddingBottom: 29,
    [theme.breakpoints.down('lg')]: {
      width: 580,
    },
    [theme.breakpoints.down('md')]: {
      width: 236,
      height: 274,
      paddingTop: 15,
      paddingBottom: 0,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        width: 580,
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        width: 236,
        height: 274,
        paddingTop: 15,
        paddingBottom: 0,
      }),
    },
  },
  message: {
    textAlign: 'center',
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(3.125),
    width: 365,
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(3.75),
      marginTop: theme.spacing(3.125),
      width: 208,
      lineHeight: '20px',
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        marginBottom: theme.spacing(3.75),
        marginTop: theme.spacing(3.125),
        width: 208,
        lineHeight: '20px',
      }),
    },
  },
  button: {
    width: 140,
    [theme.breakpoints.down('md')]: {
      width: 236,
      height: 44,
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        width: 236,
        height: 44,
      }),
    },
  },
  icon: {
    alignItems: 'center',
    background: '#F4F4F4',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    height: 120,
    width: 120,
    '& svg': {
      cursor: 'initial',
    },
  },
}));

const SavedInfoCard = ({ message, btnTitle }) => {
  const classes = useStyles();
  const { onClose } = use2Modal();
  const { isKiteUser } = useUser();

  return (
    <div className={classes.root}>
      <div className={classes.icon}>
        <Feather nativeColor={!isKiteUser} printType="stroke" type="success" />
      </div>
      <Typography className={classes.message} variant="h2">
        {message}
      </Typography>
      <Button className={classes.button} onClick={onClose}>
        {btnTitle}
      </Button>
    </div>
  );
};

export default SavedInfoCard;
