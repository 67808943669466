// Lib
import React, { useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { makeStyles } from '@mui/styles';

// Components
import OrderCard from '../../Cards/OrderCard';

// Hooks
import useReorder from '../../../../pages/OrderHistory/hooks/useReorder';

const useStyles = makeStyles((theme) => ({
  childCard: {
    borderRadius: 0,
    marginBottom: 0,
    marginTop: 0,
    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        marginBottom: 0,
      }),
    },
  },
  lastChildCard: {
    borderRadius: '0px 0px 20px 20px',
    marginBottom: theme.spacing(2),
  },
}));

const Order = ({ order, toggleHeight = () => {}, withChildren = false }) => {
  const { onReorder } = useReorder();
  const classes = useStyles();

  const onToggleHeight = useCallback(() => {
    toggleHeight(order?.children?.length + 1 || 1);
  }, [order, toggleHeight]);

  const isDistro = useMemo(
    () => !!order?.addresses?.length,
    [order?.addresses?.length],
  );
  return (
    <>
      <OrderCard
        key={order.id}
        order={order}
        onReorder={onReorder}
        withChildren={withChildren}
        onToggleChildren={onToggleHeight}
        isDistro={isDistro}
      />
      {isDistro &&
        withChildren &&
        order.children.map((childOrder, index) => {
          let mergedOrder = {
            ...order,
            ...childOrder,
            status: childOrder.status || order.status,
          };
          delete mergedOrder.children;
          // Need to delete this property in child of distro for proper
          // address count calculation and displaying right order qty values
          delete mergedOrder.addresses;

          return (
            <OrderCard
              className={classnames(
                classes.childCard,
                index === order.children.length - 1 && classes.lastChildCard,
              )}
              key={mergedOrder.id}
              order={mergedOrder}
              onReorder={onReorder}
            />
          );
        })}
    </>
  );
};

export default Order;
