// Libs
import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';

// Components
import Spinner from '../../../components/Spinner';
import Table from '../../../components/Table';
import Search from '../../../components/Search';
import Feather from '../../../components/Feather';
import IconButton from '../../../components/Button/IconButton/IconButton';
import EmptyKitsScreen from '../../../components/Kits/EmptyKitsScreen/EmptyKitsScreen';
import TableRow from '../../../components/Kits/TableRow/TableRow';
import TotalQtyLabel from '../../../components/Labels/TotalQtyLabel';

// Hooks
import useKits from '../hooks/useKits';
import useKitActions from '../hooks/useKitActions';
import useSearch from '../../../hooks/useSearch';
import useUI from '../../../hooks/useUI';
import useKitsCellsReducers from '../hooks/useKitsCellsReducers';

//Constants
import { kitsTableColumns } from '../../../constants/kits';
import useUser from '../../../hooks/useUser';
import KitAdaptiveCard from '../../../components/Kits/KitAdaptiveCard/KitAdaptiveCard';
import Error from '../../../components/Error';

const useTableStyles = makeStyles((theme) => ({
  table: {
    position: 'relative',
    borderCollapse: 'separate',
    width: '100%',
    overflow: 'visible',
    borderSpacing: `0 ${theme.spacing(2)}`,
    '& thead tr': {
      marginTop: 0,
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: '0px 5px 15px rgba(44, 112, 172, 0.2)',
    borderRadius: '10px',
    marginTop: 16,
    '&:nth-child(1)': {
      marginTop: 0,
    },
    '& td, & th': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  cell: {
    fontFamily: ({ isKiteUser }) => (isKiteUser ? 'Helvetica' : 'Proxima Nova'),
    fontWeight: ({ isKiteUser }) => (isKiteUser ? 400 : 600),
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    borderBottom: 'none',
    letterSpacing: '0.1px',
    color: '#54565B',
    paddingBlock: theme.spacing(0.5),
    height: '56px',
    '&:nth-child(1)': {
      width: '40%',
    },
    '&:nth-child(2)': {
      width: '11%',
      justifyContent: 'flex-end',
    },
    '&:nth-child(3)': {
      width: '18%',
      justifyContent: 'flex-end',
    },
    '&:nth-child(4)': {
      width: '24%',
      justifyContent: 'center',
      '& button': {
        width: 89,
        height: 36,
      },
    },
    '&:nth-child(5)': {
      textAlign: 'right',
    },
  },
  headerCell: {
    color: ({ isKiteUser }) =>
      isKiteUser ? theme.palette.darkGrey : theme.palette.black,
    fontWeight: ({ isKiteUser }) => (isKiteUser ? 700 : 600),
  },
}));

const useStyles = makeStyles((theme) => ({
  bar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    zIndex: 100,
    position: 'sticky',
    padding: '40px 26px 10px',
    marginInline: '-26px',
    backgroundColor: 'white',
    top: '80px',
    [theme.breakpoints.down('lg')]: {
      top: '70px',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down('md')]: {
      top: '54px',
      padding: '20px 12px 10px',
      marginInline: '-12px',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        top: '70px',
        justifyContent: 'space-between',
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        top: '54px',
        padding: '20px 12px 10px',
        marginInline: '-12px',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        marginBottom: theme.spacing(1),
      }),
    },
  },
}));

const Kits = () => {
  const { isKiteUser } = useUser();
  const { isTabletMode, isMobileMode } = useUI();
  const { isLoading, kits, filteredKits, apiError } = useKits();
  const { onDeleteAllClick } = useKitActions();
  const { onChange, onClearSearch } = useSearch();
  const tableClasses = useTableStyles({ isKiteUser });
  const classes = useStyles({ isTabletMode, isMobileMode });

  if (isLoading) {
    return <Spinner />;
  }

  if (apiError) {
    return <Error message={apiError} />;
  }

  return (
    <>
      {kits.data !== null && kits?.data?.length > 0 ? (
        <>
          <div className={classes.bar}>
            {isTabletMode && (
              <Typography variant={isMobileMode ? 'h1' : 'h2'}>
                My Kits
              </Typography>
            )}
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={isMobileMode ? 'start' : 'end'}>
              {!isMobileMode && (
                <Search
                  onChange={onChange}
                  onClearSearch={onClearSearch}></Search>
              )}
              <IconButton
                data-testid={'delete-kits-button'}
                onClick={onDeleteAllClick}
                startIcon={<Feather type={'delete'} />}
                styles={{
                  margin: isMobileMode ? '20px 10px 0 0' : '32px 10px 0 0',
                }}>
                Delete All
              </IconButton>
            </Box>
          </div>
          <TotalQtyLabel qty={filteredKits.length} type={'kit'} />
          {isTabletMode ? (
            <Box mt={1}>
              {filteredKits.map((kit) => (
                <KitAdaptiveCard key={kit.id} kit={kit} />
              ))}
            </Box>
          ) : (
            filteredKits.length > 0 && (
              <Table
                data={filteredKits}
                useCellsReducers={useKitsCellsReducers}
                columns={kitsTableColumns}
                styles={tableClasses}
                TableRow={TableRow}
              />
            )
          )}
        </>
      ) : (
        <>
          {isTabletMode && (
            <Typography
              variant={isMobileMode ? 'h1' : 'h2'}
              mt={isMobileMode ? '20px' : '40px'}>
              My Kits
            </Typography>
          )}
          <EmptyKitsScreen />
        </>
      )}
    </>
  );
};

export default Kits;
