import { USER_VIEW } from '../constants/user';
import { PI_PRODUCT_SUBTYPES } from '../constants/cart';

export const getLimits = (limits) => {
  const annualLimit = limits?.find(({ limit_type }) => limit_type === 'Annual');
  const monthLimit = limits?.find(({ limit_type }) => limit_type === 'Monthly');

  return { annualLimit, monthLimit };
};

export const isOverLimit = (product, nums, addressesCount, userView = '') => {
  const requestedQty = nums * addressesCount;

  const { annualLimit, monthLimit } = getLimits(product?.limits);

  const monthRestriction = monthLimit?.restriction;
  const monthBalance = monthLimit?.balance;
  const annualRestriction = annualLimit?.restriction;
  const annualBalance = annualLimit?.balance;

  const isCorporateUser = userView === USER_VIEW.gileadCorporateUserPortal;
  const isPIProductSubtype = PI_PRODUCT_SUBTYPES.includes(product.subtype);
  const isNotCorporateUserOrIsPIProduct =
    !isCorporateUser || isPIProductSubtype;

  let isMonthOverLimit = false;

  if (monthRestriction) {
    let monthRest = monthRestriction - monthBalance;
    monthRest = monthRest > 0 ? monthRest : 0;
    isMonthOverLimit = monthRest >= 0 && requestedQty > monthRest;
  }

  let isAnnualOverLimit = false;

  if (annualRestriction) {
    let annualRest = annualRestriction - annualBalance;
    annualRest = annualRest > 0 ? annualRest : 0;
    isAnnualOverLimit = annualRest >= 0 && requestedQty > annualRest;
  }

  if (
    (product?.order_limit !== null &&
      (product.order_limit > 0 ||
        userView === USER_VIEW.gileadFieldRepPortal) &&
      isNotCorporateUserOrIsPIProduct &&
      requestedQty > product.order_limit) ||
    (isNotCorporateUserOrIsPIProduct && isMonthOverLimit) ||
    (isNotCorporateUserOrIsPIProduct && isAnnualOverLimit)
  ) {
    return true;
  }

  return false;
};

export const getProductsOverLimit = (
  cartProducts,
  addressesCount,
  productsObj,
  userView = '',
) => {
  const productsOverLimit = [];

  const count = addressesCount === 0 ? 1 : addressesCount;

  cartProducts.forEach(({ product, nums }) => {
    if (nums > productsObj?.[product]?.product_available_qty) {
      return;
    }
    if (isOverLimit(productsObj[product], nums, count, userView)) {
      productsOverLimit.push(product);
    }
  });

  return productsOverLimit;
};
