// lib
import { fork } from 'redux-saga/effects';

// Sagas
import authSaga from '../pages/Auth/sagas';
import productsSaga from '../pages/Materials/saga';
import validateProductsSaga from '../pages/Validate/saga';
import cartSaga from '../pages/Cart/saga';
import addressBook from '../pages/AddressBook/saga';
import ordersSaga from '../pages/OrderHistory/saga';
import approvalsSaga from '../pages/Approvals/saga';
import supportSaga from '../pages/Support/saga';
import kitsSaga from '../pages/Kits/saga';
import rating from './rating';
import favoriteSaga from './favorite';

function* mainSaga() {
  yield fork(authSaga);
  yield fork(productsSaga);
  yield fork(validateProductsSaga);
  yield fork(cartSaga);
  yield fork(addressBook);
  yield fork(ordersSaga);
  yield fork(approvalsSaga);
  yield fork(supportSaga);
  yield fork(kitsSaga);
  yield fork(rating);
  yield fork(favoriteSaga);
}

export default mainSaga;
