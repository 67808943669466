// Lib
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import {
  SetImportedAddressFile,
  UploadImportedAddressFile,
  SaveImportedAddresses,
  SetUploadingProgressValue,
} from '../../../actions/addressBook';
import { CloseModal, OpenModal } from '../../../actions/modal';

// Hooks
import useModal from '../../../hooks/useModal';

// Assets
import csvTemplate from '../../../assets/csv/addresses.csv';
import { wrongFormat } from '../../../constants/addressBook';
import { ClearApiError } from '../../../actions/apiError';

const useImportAddresses = () => {
  const dispatch = useDispatch();
  const { importedAddresses, apiError } = useSelector((state) => ({
    importedAddresses: state.addressBook.addressBook.import,
    apiError: state.apiError?.['saveImportedAddresses'],
  }));
  const [step, setStep] = useState(1);
  const [incorrectFormatMessage, setIncorrectFormatMessage] = useState('');
  const [uploading, setUploading] = useState(false);
  const [withSpinner, setWithSpinner] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [editableAddress, setEditableAddress] = useState(null);
  const { onClose } = useModal();
  const refController = useRef(null);

  useEffect(() => {
    return () => {
      dispatch(SetImportedAddressFile(null));
      dispatch(SetUploadingProgressValue(0));
    };
  }, [dispatch]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setIncorrectFormatMessage('');
      if (
        !acceptedFiles[0]?.name.endsWith('.xlsx') &&
        !acceptedFiles[0]?.name.endsWith('.csv')
      ) {
        setIncorrectFormatMessage(wrongFormat);
        return dispatch(SetImportedAddressFile(null));
      }
      dispatch(SetImportedAddressFile(acceptedFiles[0]));
    },
    [dispatch],
  );

  const onChangeFile = useCallback(
    ({ target }) => {
      setIncorrectFormatMessage('');
      const file = target.files[0];
      if (!file?.type.includes('csv')) {
        setIncorrectFormatMessage(wrongFormat);
        return dispatch(SetImportedAddressFile(null));
      }
      dispatch(SetImportedAddressFile(file));
    },
    [dispatch],
  );

  const changeStep = useCallback(() => {
    setIncorrectFormatMessage('');
    refController.current = new AbortController();
    if (step === 1) {
      dispatch(
        UploadImportedAddressFile({
          file: importedAddresses.file,
          setUploading,
          setStep,
          signal: refController?.current?.signal,
          setApiError: setIncorrectFormatMessage,
          setWithSpinner,
        }),
      );
    }
    if (step === 2 && !!importedAddresses?.addresses?.length) {
      dispatch(ClearApiError());
      dispatch(
        SaveImportedAddresses({ setIsLoading: setUploading, setIsSaved }),
      );
    }
    if (step === 2 && !importedAddresses?.addresses?.length) {
      onClose();
    }
  }, [
    setIncorrectFormatMessage,
    dispatch,
    setStep,
    importedAddresses,
    setUploading,
    step,
    onClose,
    refController,
  ]);

  const downloadTemplate = useCallback(() => {
    const element = document.createElement('a');
    element.href = csvTemplate;
    element.download = 'addresses-template.csv';

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }, []);

  useEffect(() => {
    if (!isSaved) {
      return;
    }
    if (isSaved) {
      dispatch(
        OpenModal({
          Component: () => (
            <Typography color="#54565B" variant="h3">
              {`New Address${
                importedAddresses.addresses.length > 1 ? 'es' : ''
              } Ha${
                importedAddresses.addresses.length > 1 ? 've' : 's'
              } Been Successfully Imported.`}
            </Typography>
          ),
          closeIcon: false,
          props: {
            isFullScreen: false,
            addressSavedCard: true,
            paddingContent: {
              xl: '20px',
              lg: '20px',
              md: '20px',
            },
          },
          styles: {
            width: 'fit-content',
          },
        }),
      );
      setTimeout(() => dispatch(CloseModal()), 2000);
    }
  }, [isSaved, dispatch, importedAddresses?.addresses]);

  const onCancelUploading = useCallback(() => {
    setWithSpinner(false);
    refController?.current?.abort();
    dispatch(SetUploadingProgressValue(0));
  }, [dispatch, refController]);

  return {
    step,
    onDrop,
    importedAddresses,
    changeStep,
    uploading,
    onChangeFile,
    incorrectFormatMessage,
    setEditableAddress,
    editableAddress,
    downloadTemplate,
    onCancelUploading,
    withSpinner,
    apiError,
  };
};

export default useImportAddresses;
