// Lib
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';

// Components
import ConfirmCard from '../../../components/Cards/ConfirmCard';
import AddressForm from '../../../components/AddressBook/Form/AddressForm';

// Actions
import { CloseModal, OpenModal } from '../../../actions/modal';
import {
  DeleteAddress,
  MakeMainAddress,
  SetValidatedAddress,
} from '../../../actions/addressBook';
import {
  AddShippingAddress,
  DeleteShippingAddress,
} from '../../../actions/cart';

// Hooks
import useUI from '../../../hooks/useUI';

const useStyles = makeStyles((theme) => ({
  modal: {
    width: 'min(70vw, 611px) !important',
    minHeight: 'initial',
    [theme.breakpoints?.down('md')]: {
      borderRadius: 10,
      width: '85vw !important',
    },
  },
}));

const useAddress = (address) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isMobileMode, isTabletMode } = useUI();
  const { checkedAsShippingAddress, shippingAddresses } = useSelector(
    (state) => ({
      shippingAddresses: state?.cart?.shippingAddresses,
      checkedAsShippingAddress: !!state?.cart?.shippingAddresses?.find(
        (item) => item?.id === address?.id,
      ),
    }),
  );

  const withSelectionCheckbox = useMemo(() => {
    if (shippingAddresses?.length === 0) {
      return true;
    }
    const firstCheckedCountry = shippingAddresses?.[0].country;

    if (
      shippingAddresses?.length > 0 &&
      firstCheckedCountry === 'United States'
    ) {
      return address.country === firstCheckedCountry;
    }

    if (
      shippingAddresses?.length > 0 &&
      firstCheckedCountry !== 'United States'
    ) {
      return address.country !== 'United States';
    }
  }, [shippingAddresses, address]);

  const onDelete = useCallback(() => {
    dispatch(DeleteAddress(address?.id));
    dispatch(CloseModal());
  }, [dispatch, address?.id]);

  const onMakeMainAddress = () => {
    dispatch(
      MakeMainAddress({
        id: address.id,
        isMain: address.is_main ? false : true,
      }),
    );
  };

  const onDeleteAddress = useCallback(() => {
    dispatch(SetValidatedAddress({}));
    dispatch(
      OpenModal({
        Component: () => (
          <ConfirmCard isAcceptBtnMain={false} onConfirm={onDelete} />
        ),
        props: {
          paddingContent: {
            xl: '40px 40px 30px',
            lg: '40px 40px 30px',
            md: '30px 16px 20px',
          },
        },
        className: classes.modal,
        styles: {
          width: isMobileMode
            ? '268px !important'
            : isTabletMode
            ? '541px !important'
            : 611,
          minHeight: isMobileMode && 'auto',
        },
      }),
    );
  }, [classes.modal, dispatch, onDelete, isMobileMode, isTabletMode]);

  const onToggleCheckbox = useCallback(
    ({ target }) => {
      if (target.checked) {
        dispatch(AddShippingAddress(address));
      } else {
        dispatch(DeleteShippingAddress(address));
      }
    },
    [dispatch, address],
  );

  const onEditAddress = useCallback(() => {
    dispatch(SetValidatedAddress({}));
    dispatch(
      OpenModal({
        id: 'address_saved',
        Component: () => <AddressForm address={address} editMode />,
        props: {
          isFullScreen: isMobileMode,
          title: 'Edit Shipping Address',
          closeButtonTop: {
            xl: 0,
            lg: 0,
            md: 16,
          },
        },
        styles: {
          width: 'min(85vw, 700px)',
        },
      }),
    );
  }, [dispatch, isMobileMode, address]);

  return {
    onDeleteAddress,
    onEditAddress,
    onDelete,
    onMakeMainAddress,
    onToggleCheckbox,
    checkedAsShippingAddress,
    withSelectionCheckbox,
  };
};

export default useAddress;
