// Lib
import React, { useMemo } from 'react';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

// Components
import ListCard from './Card/ListCard';

// Hooks
import useUI from '../../hooks/useUI';

const ITEM_SIZE_DESKTOP_LIST = 295;
const ITEM_SIZE_TABLET_LIST = 263;
const ITEM_SIZE_MOBILE_LIST = 152;

const Row = ({ index, style, data }) => {
  const { products } = data || {};
  const product = products?.all?.obj?.[products.shown?.[index]];

  const styles = {
    ...style,
    pointerEvents: 'auto',
  };

  return (
    <div style={styles}>
      <ListCard key={`${product?.product_id}_${index}`} product={product} />
    </div>
  );
};

const VirtualList = ({ products, updateShownProducts, OuterElementType }) => {
  const { isDesktopMode, isTabletMode, isMobileMode } = useUI();
  const itemSize = useMemo(() => {
    if (isDesktopMode) {
      return ITEM_SIZE_DESKTOP_LIST;
    }
    if (isTabletMode && !isMobileMode) {
      return ITEM_SIZE_TABLET_LIST;
    }
    if (isMobileMode) {
      return ITEM_SIZE_MOBILE_LIST;
    }
    return ITEM_SIZE_DESKTOP_LIST;
  }, [isDesktopMode, isTabletMode, isMobileMode]);

  return (
    <InfiniteLoader
      isItemLoaded={(index) => {
        return products.shown[index] !== undefined;
      }}
      itemCount={products?.all?.ids?.length || 0}
      loadMoreItems={updateShownProducts}
      threshold={12}>
      {({ onItemsRendered, ref }) => (
        <List
          height={window.innerHeight}
          outerElementType={OuterElementType}
          itemCount={products.shown.length}
          itemSize={itemSize}
          onItemsRendered={onItemsRendered}
          ref={ref}
          width={window.innerWidth}
          itemData={{
            products,
          }}>
          {Row}
        </List>
      )}
    </InfiniteLoader>
  );
};

export default VirtualList;
