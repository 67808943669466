// Libs
import React from 'react';

// Components
import GridContent from './GridContent';
import ListContent from './ListContent';

const OrderProductCardContent = ({
  product,
  order,
  orderItem,
  listView = false,
  isRecurring = false,
  addressesCount = 1,
}) => {
  return listView ? (
    <ListContent
      product={product}
      order={order}
      orderItem={orderItem}
      isRecurring={isRecurring}
      addressesCount={addressesCount}
    />
  ) : (
    <GridContent
      product={product}
      order={order}
      orderItem={orderItem}
      isRecurring={isRecurring}
      addressesCount={addressesCount}
    />
  );
};

export default OrderProductCardContent;
