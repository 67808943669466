// Lib
import React from 'react';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

// Hooks
import useUser from '../../../hooks/useUser';

// Styles
import { colors } from '../../../styles/palette';

const useStyles = makeStyles((theme) => ({
  countDescription: ({ view, isKiteUser }) => ({
    color: theme.palette.darkGrey,
    display: 'flex',
    flexDirection: 'column',
    gap: view === 'grid' ? 6 : 3,
    fontSize: 14,
    fontWeight: isKiteUser ? 700 : 600,
    [theme.breakpoints.down('lg')]: {
      width: 195,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        width: 195,
      }),
    },
  }),
  countDescriptionValue: ({ isKiteUser }) => ({
    fontWeight: isKiteUser ? 400 : 600,
  }),
  stockItem: ({ isKiteUser, unavailableLabel, view }) => {
    let color;
    if (unavailableLabel) {
      color = colors.gilead.primary1;
    } else {
      color = isKiteUser ? theme.palette.primary1 : theme.palette.primary2;
    }
    return {
      color,
      fontSize: 16,
      fontWeight: 600,
      marginLeft: view === 'grid' ? `${theme.spacing(4)} !important` : 0,
      [theme.breakpoints.down('xl')]: {
        marginLeft: view === 'grid' ? `${theme.spacing(1.5)} !important` : 0,
      },
      [theme.breakpoints.down('lg')]: {
        marginLeft: `0 !important`,
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 10,
      },
      [theme.breakpoints.downLandscape('lg')]: {
        ...(theme.isMobileDevice && {
          marginLeft: `0 !important`,
        }),
      },
      [theme.breakpoints.downLandscape('md')]: {
        ...(theme.isMobileDevice && {
          fontSize: 12,
        }),
      },
      [theme.breakpoints.downLandscape('sm')]: {
        ...(theme.isMobileDevice && {
          fontSize: 10,
        }),
      },
    };
  },
}));

const StockItem = ({
  availableQuantity,
  view,
  unavailableLabel = '',
  className,
}) => {
  const { isKiteUser } = useUser();
  const classes = useStyles({ isKiteUser, unavailableLabel, view });

  return (
    <div className={classnames(className, classes.stockItem)}>
      {!unavailableLabel ? (
        <>
          In Stock: <span>{availableQuantity}</span>
        </>
      ) : (
        unavailableLabel
      )}
    </div>
  );
};

const CountDescription = ({
  perUnitQty,
  maxAllowedQty,
  availableQuantity,
  view,
  unavailableLabel,
}) => {
  const { isKiteUser } = useUser();
  const classes = useStyles({ view, isKiteUser });

  return (
    <div className={classes.countDescription}>
      {perUnitQty && (
        <div>
          Per Unit Qty:
          <span className={classes.countDescriptionValue}> {perUnitQty}</span>
        </div>
      )}
      {maxAllowedQty !== null && (
        <div>
          Max. Allowed Qty:
          <span className={classes.countDescriptionValue}>
            {' '}
            {maxAllowedQty}
          </span>
        </div>
      )}
      <StockItem
        availableQuantity={availableQuantity}
        unavailableLabel={unavailableLabel}
      />
    </div>
  );
};

export default CountDescription;

export { StockItem };
