// Lib
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isValid, isPast } from 'date-fns';

// Hooks
import useRequest from './useRequest';
import {
  SHIPPING_SCHEDULES,
  SHIPPING_SCHEDULES_ONLY_FEDEXGROUND,
  SHIPPING_SCHEDULE_WITH_DATES,
} from '../../../constants/cart';

// Utils
import isDisabledSavingRecurringSchedule from '../../../utils/isDisabledSavingRecurringSchedule';
import { isPastDate } from '../../../utils/date';

const useShippingDetails = () => {
  const navigate = useNavigate();
  const { openRequestCard, isRequestRequired } = useRequest();
  const { shippingDetails } = useSelector((state) => ({
    shippingDetails: state.cart.shippingDetails,
  }));
  const [monthError, setMonthError] = useState('');

  const onClickBack = useCallback(
    (pathname = '') => {
      navigate('/cart/shipping-address', {
        state: {
          navigateFrom: pathname,
        },
      });
    },
    [navigate],
  );

  const onClickNext = useCallback(() => {
    if (isRequestRequired) {
      openRequestCard();
    } else {
      navigate('/cart/review-order');
      window.scroll(0, 0);
    }
  }, [navigate, isRequestRequired, openRequestCard]);

  const isNextButtonDisable = useMemo(() => {
    if (
      shippingDetails.schedule === SHIPPING_SCHEDULES[4].value ||
      shippingDetails.schedule === SHIPPING_SCHEDULES[5].value
    ) {
      return (
        isDisabledSavingRecurringSchedule(shippingDetails, monthError) ||
        !shippingDetails?.methods.length
      );
    }

    if (SHIPPING_SCHEDULE_WITH_DATES.includes(shippingDetails?.schedule)) {
      const date = new Date(shippingDetails.date);

      if (
        shippingDetails?.methods.length &&
        shippingDetails?.method?.id &&
        shippingDetails?.date &&
        isValid(date)
      ) {
        // Do Not Ship Until Date option
        if (shippingDetails?.schedule === SHIPPING_SCHEDULES[3].value) {
          return isPast(date);
        } else {
          return isPastDate(date);
        }
      }
      return true;
    }

    return (
      !shippingDetails?.method?.id ||
      SHIPPING_SCHEDULES_ONLY_FEDEXGROUND.includes(shippingDetails?.schedule)
    );
  }, [shippingDetails, monthError]);

  return {
    onClickBack,
    onClickNext,
    isNextButtonDisable,
    isRequestRequired,
    setMonthError,
  };
};

export default useShippingDetails;
