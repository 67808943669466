// Lib
import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, FormControlLabel, Radio, RadioGroup } from '@mui/material';

// Components
import Button from '../../../Button';

// Hooks
import useAddressFormat from '../../../../pages/AddressBook/hooks/useAddressFormat';

// Styles
import { colors } from '../../../../styles/palette';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }),
    },
  },
  title: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(3.75),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        marginBottom: theme.spacing(3.75),
      }),
    },
  },
  text: {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '24px',
    letterSpacing: '0.1px',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      paddingLeft: 33,
      fontSize: 14,
      lineHeight: '20px',
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        paddingLeft: 33,
        fontSize: 14,
        lineHeight: '20px',
      }),
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(9),
    '& button': {
      width: 138,
    },
    '& button:nth-child(1)': {
      color: theme.palette.primary1,
    },
    [theme.breakpoints.down('lg')]: {
      '& button:nth-child(2)': {
        width: 200,
      },
    },
    [theme.breakpoints.down('md')]: {
      alignItems: 'end',
      flex: 1,
      '& button': {
        height: 44,
      },
      '& button:nth-child(1)': {
        paddingLeft: 0,
        width: 92,
      },
      '& button:nth-child(2)': {
        width: 204,
      },
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        '& button:nth-child(2)': {
          width: 200,
        },
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        alignItems: 'end',
        flex: 1,
        '& button': {
          height: 44,
        },
        '& button:nth-child(1)': {
          paddingLeft: 0,
          width: 92,
        },
        '& button:nth-child(2)': {
          width: 204,
        },
      }),
    },
  },
  radioGroup: {
    width: '75%',
  },
  formControlLabel: {
    color: theme.palette.text.secondary,
    '& .MuiFormControlLabel-label': {
      fontSize: 18,
    },
    '& .MuiRadio-root': {
      paddingTop: 0,
      paddingBottom: 0,
      alignSelf: 'baseline',
      '&:not(.Mui-checked)': { color: theme.palette.grey1 },
    },
    '&:nth-child(even):not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('md')]: {
      '& .MuiFormControlLabel-label': {
        fontSize: 16,
      },
      '& > span:nth-child(1)': {
        position: 'relative',
        bottom: 30,
      },
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        '& .MuiFormControlLabel-label': {
          fontSize: 16,
        },
        '& > span:nth-child(1)': {
          position: 'relative',
          bottom: 30,
        },
      }),
    },
  },
}));

const AddressFormat = ({
  afterValidation: {
    original_address: originalAddress,
    validated_address: validatedAddress,
  },
  formData,
  editMode,
  onBackToForm,
}) => {
  const classes = useStyles();
  const { onSave, onChangeFormat, selectedAddress, apiError } =
    useAddressFormat(formData, editMode);

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h2">
        Select Address Format
      </Typography>
      <RadioGroup
        className={classes.radioGroup}
        defaultValue={selectedAddress}
        row={false}
        aria-labelledby="address-format-radio-buttons-group-label"
        name="address-format-radio-buttons-group">
        <div className={classes.text}>Entered Address</div>
        <FormControlLabel
          className={classes.formControlLabel}
          value="original_address"
          control={<Radio onChange={onChangeFormat} />}
          label={originalAddress}
        />
        {validatedAddress && (
          <>
            <div className={classes.text}>Validated Address</div>
            <FormControlLabel
              className={classes.formControlLabel}
              value="validated_address"
              control={<Radio onChange={onChangeFormat} />}
              label={validatedAddress}
            />
          </>
        )}
      </RadioGroup>
      {apiError && <p style={{ color: colors.gilead.primary1 }}>{apiError}</p>}
      <div className={classes.buttonContainer}>
        <Button
          style={{ backgroundColor: 'transparent' }}
          onClick={onBackToForm}>
          Back
        </Button>
        <Button onClick={onSave}>Save</Button>
      </div>
    </div>
  );
};

export default AddressFormat;
