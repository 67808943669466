// Lib
import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';

// Components
import FooterButtons from '../../Modal/FooterButtons';

// Hooks
import useModal from '../../../hooks/useModal';
import use2Modal from '../../../hooks/use2Modal';

// Styles
import { colors } from '../../../styles/palette';
import { ClearApiError } from '../../../actions/apiError';

const useStyles = makeStyles((theme) => ({
  message: {
    fontSize: 18,
    lineHeight: '22px',
    letterSpacing: '0.2px',
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      lineHeight: '18px',
      letterSpacing: '0.1px',
      marginTop: theme.spacing(1),
    },
  },
  buttons: {
    paddingBottom: 0,
    '& #reject-button': {
      width: 81,
    },
    '& #accept-button': {
      minWidth: 50,
      width: 50,
    },
    [theme.breakpoints.down('lg')]: {
      paddingRight: 30,
      '& #reject-button': {
        width: 116,
      },
      '& #accept-button': {
        width: 100,
      },
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
      columnGap: 8,
      paddingRight: 0,
      paddingLeft: 0,
      '& #reject-button': {
        height: 44,
      },
    },
  },
}));

const ConfirmCard = ({
  modal2Mode,
  onConfirm,
  title = 'Do You Want To Delete?',
  message = 'Deleting Will Remove This Address From The Address Book.',
  isAcceptBtnMain = true,
  loadingBtn = false,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { onClose } = useModal();
  const { onClose: on2Close } = use2Modal();
  const { apiError } = useSelector((state) => ({
    apiError:
      state.apiError['delete-recurring-address'] ||
      state.apiError['cancel-recurring-order'] ||
      state.apiError['deleteKit'] ||
      state.apiError['deleteAllKits'],
  }));

  useEffect(() => {
    return () => dispatch(ClearApiError());
  }, [dispatch]);

  return (
    <>
      <Typography variant="h2">{title}</Typography>
      <div className={classes.message}>{message}</div>
      {apiError && (
        <div style={{ color: colors.gilead.primary1, marginTop: 16 }}>
          {apiError}
        </div>
      )}
      <FooterButtons
        isLoading={loadingBtn}
        className={classes.buttons}
        isAcceptBtnMain={isAcceptBtnMain}
        onAccept={onConfirm}
        onReject={modal2Mode ? on2Close : onClose}
        acceptTitle="Yes"
        rejectTitle="No"
      />
    </>
  );
};

export default ConfirmCard;
