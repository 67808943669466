// Lib
import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { Close2Modal, Open2Modal, Clean2Modal } from '../actions/modal2';

const use2Modal = () => {
  const dispatch = useDispatch();
  const { open, id, Component, styles, closeIcon, props } = useSelector(
    (state) => state.modal2,
  );

  const onOpen = useCallback(
    (data) => () => dispatch(Open2Modal(data)),
    [dispatch],
  );
  const onClose = useCallback(() => dispatch(Close2Modal()), [dispatch]);

  const onClosed = useCallback(() => dispatch(Clean2Modal()), [dispatch]);

  return {
    open,
    onClose,
    onClosed,
    onOpen,
    id,
    Component,
    styles,
    closeIcon,
    props,
  };
};

export default use2Modal;
