// Lib
import React from 'react';
import { Modal, Box, IconButton } from '@mui/material';

// Components
import Search from './index';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';

const useStyles = makeStyles(() => ({
  backdrop: {
    backgroundColor: 'rgba(48, 85, 107, 0.2)',
  },
  searchButton: {
    backgroundColor: 'white',
    padding: 5,
  },
  searchInput: {
    '& .MuiOutlinedInput-root': {
      padding: '8px 12px !important',
    },
  },
}));

const MobileSearch = ({
  isActive,
  onToggleSearchInput,
  onChange,
  value,
  onClearSearch,
  withMenu = false,
  options,
}) => {
  const classes = useStyles();

  return (
    <Modal
      open={isActive}
      onClose={onToggleSearchInput}
      BackdropProps={{ classes: { root: classes.backdrop } }}>
      <Box
        display="flex"
        pl={1.5}
        pr={1.5}
        pt={1}
        justifyContent="space-between">
        <Search
          withMenu={withMenu}
          options={options}
          value={value}
          autoFocus={isActive}
          onChange={onChange}
          className={classes.searchInput}
          withIcon={false}
          onClearSearch={onClearSearch}
        />
        <IconButton
          className={classes.searchButton}
          id="search-button"
          edge="start"
          aria-label="search"
          onClick={onToggleSearchInput}>
          <SearchIcon />
        </IconButton>
      </Box>
    </Modal>
  );
};

export default MobileSearch;
