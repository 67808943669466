// Libs
import { useCallback, useState, useMemo, useEffect } from 'react';

const useCountButton = (product, count, onSetCount, limitValue) => {
  const [isFocused, setIsFocused] = useState(false);
  const [localValue, setLocalValue] = useState(count);

  useEffect(() => {
    setLocalValue(count);
  }, [count]);

  const onChange = useCallback(
    ({ value }) => {
      const availableCount = product?.product_available_qty;

      const pattern = /^\d+\.?\d*$/;
      const isNumber = pattern.test(value);

      if (isNumber) {
        setLocalValue(
          +value > availableCount && limitValue ? availableCount : +value,
        );
      }
      if (!value) {
        setLocalValue('');
      }
    },
    [product, setLocalValue, limitValue],
  );

  const onFocus = useCallback(() => {
    setLocalValue(count);
    setIsFocused(true);
  }, [setIsFocused, count]);

  const onBlur = useCallback(() => {
    if (localValue) {
      onSetCount(product, localValue);
    } else {
      onSetCount(product, 0);
    }
    setIsFocused(false);
  }, [localValue, onSetCount, product]);

  const value = useMemo(() => {
    if (isFocused) {
      return localValue;
    }
    return count;
  }, [count, isFocused, localValue]);

  return {
    onChange,
    onFocus,
    onBlur,
    value,
  };
};

export default useCountButton;
