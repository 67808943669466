// Lib
import React, { useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Box } from '@mui/material';

// Components
import Feather from '../../../components/Feather';
import Button from '../../../components/Button';

// Actions
import { SetShippingDetails } from '../../../actions/cart';
import { Close2Modal, Open2Modal } from '../../../actions/modal2';

// Hooks
import useUI from '../../../hooks/useUI';

// Constants
import {
  DAYS_RESTRICTION_EXPLANATION,
  DAYS_RESTRICTION_MESSAGE,
} from '../../../constants/common';

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: {
      alignItems: 'initial',
      marginBottom: theme.spacing(0.5),
    },
    [theme.breakpoints.downLandscape('md')]: {
      alignItems: 'initial',
      marginBottom: theme.spacing(0.5),
    },
  },
  icon: {
    marginRight: 8,
    '& path': {
      stroke: '#1E1E1E',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 4,
    },
    [theme.breakpoints.downLandscape('md')]: {
      marginTop: 4,
    },
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: 'auto',
    marginRight: '10px',
    width: 138,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.downLandscape('md')]: {
      width: '100%',
    },
  },
  monthTitle: {
    [theme.breakpoints.down('lg')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      width: '80%',
    },
    [theme.breakpoints.downLandscape('md')]: {
      fontSize: 14,
      width: '80%',
    },
  },
  monthMessage: {
    fontSize: 18,
    lineHeight: '22px',
    width: '65%',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      lineHeight: '16px',
      paddingLeft: theme.spacing(2.5),
      width: '100%',
    },
    [theme.breakpoints.downLandscape('md')]: {
      fontSize: 12,
      lineHeight: '16px',
      paddingLeft: theme.spacing(2.5),
      width: '100%',
    },
  },
}));

const useScheduleDetails = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { shippingDetails, holidays } = useSelector((state) => ({
    shippingDetails: state.cart.shippingDetails,
    holidays: state.holidays,
  }));
  const { isMobileMode } = useUI();

  const onChangeDate = useCallback(
    (newDate) => {
      dispatch(
        SetShippingDetails({
          type: 'date',
          value: newDate,
        }),
      );
    },
    [dispatch],
  );

  const onChangeMultipleSelect = useCallback(
    () =>
      ({ target }) => {
        const { value } = target;
        const checkedValues =
          typeof value === 'string' ? value.split(',') : value;

        dispatch(
          SetShippingDetails({
            type: 'weekly',
            value: {
              days: shippingDetails.weekly.days.map((item) =>
                checkedValues.includes(item.title)
                  ? { ...item, checked: true }
                  : { ...item, checked: false },
              ),
              monthNumber: shippingDetails.weekly.monthNumber,
            },
          }),
        );
      },
    [dispatch, shippingDetails.weekly],
  );

  const onChangeSelect = useCallback(
    (value, name) => () => {
      if (name === 'monthDay') {
        if (+value >= 29 && +value <= 31) {
          dispatch(
            Open2Modal({
              id: 'fever-than-31',
              Component: () => (
                <>
                  <Box className={classes.titleContainer}>
                    <Feather
                      className={classes.icon}
                      type="error"
                      printType="stroke"
                    />
                    <Typography className={classes.monthTitle} variant="h3">
                      {DAYS_RESTRICTION_MESSAGE(`${value} Days`)}
                    </Typography>
                  </Box>
                  <Typography className={classes.monthMessage}>
                    {DAYS_RESTRICTION_EXPLANATION('Day')}
                  </Typography>
                  <Button
                    className={classes.button}
                    onClick={() => dispatch(Close2Modal())}>
                    Close
                  </Button>
                </>
              ),
              props: {
                paddingContent: {
                  xl: '30px 30px 30px 40px',
                  lg: '30px 30px 30px 40px',
                  md: '30px 16px 20px',
                },
              },
              closeIcon: true,
              styles: {
                minHeight: 'initial',
                width: isMobileMode ? 268 : 555,
              },
            }),
          );
        }
        dispatch(
          SetShippingDetails({
            type: 'monthly',
            value: {
              ...shippingDetails.monthly,
              monthDay: {
                ...shippingDetails.monthly.monthDay,
                dayNumber: value,
              },
            },
          }),
        );
      }

      if (name === 'weekDayNumber') {
        dispatch(
          SetShippingDetails({
            type: 'monthly',
            value: {
              ...shippingDetails.monthly,
              weekDay: {
                ...shippingDetails.monthly.weekDay,
                dayNumber: value,
              },
            },
          }),
        );
        if (value === 'Fifth' && shippingDetails.monthly.weekDay.weekDay) {
          dispatch(
            Open2Modal({
              id: 'every-5-days',
              Component: () => (
                <>
                  <Box className={classes.titleContainer}>
                    <Feather
                      className={classes.icon}
                      type="error"
                      printType="stroke"
                    />
                    <Typography className={classes.monthTitle} variant="h3">
                      {DAYS_RESTRICTION_MESSAGE(
                        `5 ${shippingDetails.monthly.weekDay.weekDay}`,
                      )}
                    </Typography>
                  </Box>
                  <Typography className={classes.monthMessage}>
                    {DAYS_RESTRICTION_EXPLANATION(
                      shippingDetails.monthly.weekDay.weekDay,
                    )}
                  </Typography>
                  <Button
                    className={classes.button}
                    onClick={() => dispatch(Close2Modal())}>
                    Close
                  </Button>
                </>
              ),
              props: {
                paddingContent: {
                  xl: '30px 30px 30px 40px',
                  lg: '30px 30px 30px 40px',
                  md: '30px 16px 20px',
                },
              },
              closeIcon: true,
              styles: {
                minHeight: 'initial',
                width: isMobileMode ? 268 : 555,
              },
            }),
          );
        }
      }
      if (name === 'weekDayTitle') {
        dispatch(
          SetShippingDetails({
            type: 'monthly',
            value: {
              ...shippingDetails.monthly,
              weekDay: {
                ...shippingDetails.monthly.weekDay,
                weekDay: value,
              },
            },
          }),
        );
        if (shippingDetails.monthly.weekDay.dayNumber === 'Fifth') {
          dispatch(
            Open2Modal({
              id: 'every-5-days',
              Component: () => (
                <>
                  <Box className={classes.titleContainer}>
                    <Feather
                      className={classes.icon}
                      type="error"
                      printType="stroke"
                    />
                    <Typography className={classes.monthTitle} variant="h3">
                      {DAYS_RESTRICTION_MESSAGE(`5 ${value}`)}
                    </Typography>
                  </Box>
                  <Typography className={classes.monthMessage}>
                    {DAYS_RESTRICTION_EXPLANATION(value)}
                  </Typography>
                  <Button
                    className={classes.button}
                    onClick={() => dispatch(Close2Modal())}>
                    Close
                  </Button>
                </>
              ),
              props: {
                paddingContent: {
                  xl: '30px 30px 30px 40px',
                  lg: '30px 30px 30px 40px',
                  md: '30px 16px 20px',
                },
              },
              closeIcon: true,
              styles: {
                minHeight: 'initial',
                width: isMobileMode ? 268 : 555,
              },
            }),
          );
        }
      }
    },
    [dispatch, shippingDetails, classes, isMobileMode],
  );

  const onChangeMonthCount = useCallback(
    (onChangeField) =>
      ({ value, name }) => {
        onChangeField(value);
        if (name === 'monthNumberForWeekly') {
          dispatch(
            SetShippingDetails({
              type: 'weekly',
              value: { ...shippingDetails.weekly, monthNumber: value },
            }),
          );
        }
        if (name === 'monthNumberForMonthDay') {
          dispatch(
            SetShippingDetails({
              type: 'monthly',
              value: {
                ...shippingDetails.monthly,
                monthDay: {
                  ...shippingDetails.monthly.monthDay,
                  monthNumber: value,
                },
              },
            }),
          );
        }
        if (name === 'monthNumberForWeekDay') {
          dispatch(
            SetShippingDetails({
              type: 'monthly',
              value: {
                ...shippingDetails.monthly,
                weekDay: {
                  ...shippingDetails.monthly.weekDay,
                  monthNumber: value,
                },
              },
            }),
          );
        }
      },
    [dispatch, shippingDetails],
  );

  const onChangeMonthlyOption = useCallback(
    (keyNumber) => () => {
      const options = Object.keys(shippingDetails.monthly);
      const uncheckedType = keyNumber === 0 ? options[1] : options[0];

      dispatch(
        SetShippingDetails({
          type: 'monthly',
          value: {
            ...shippingDetails.monthly,
            [uncheckedType]: {
              ...shippingDetails.monthly[uncheckedType],
              checked: false,
            },
            [options[keyNumber]]: {
              ...shippingDetails.monthly[options[keyNumber]],
              checked: true,
            },
          },
        }),
      );
    },
    [dispatch, shippingDetails.monthly],
  );

  return {
    onChangeDate,
    onChangeSelect,
    onChangeMultipleSelect,
    onChangeMonthlyOption,
    onChangeMonthCount,
    shippingDetails,
    holidays,
  };
};

export default useScheduleDetails;
