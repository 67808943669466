// Lib
import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

// Componenents
import { StockItem } from '../Card/CountDescription';

// Hooks
import useUser from '../../../hooks/useUser';

// Utils
import getRemainingQuantity from '../../../utils/getRemainigQuantity';
import { getLimits } from '../../../utils/overLimit';
import isProductExpired from '../../../utils/isProductExpired';
import isProductWithdrawn from '../../../utils/isProductWithdrawn';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  detailTitle: {
    color: theme.palette.darkGrey,
    fontWeight: 600,
    marginBottom: 6,
    '& span': {
      fontWeight: 400,
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: 3,
    },
  },
  expired: {
    color: theme.palette.error.main,
  },
  quantityContainer: {
    display: 'flex',
    gap: 90,
    [theme.breakpoints.down('lg')]: {
      gap: 50,
    },
    [theme.breakpoints.down('md')]: {
      gap: 40,
      marginBottom: theme.spacing(1),
    },
  },
  stockItem: {
    fontSize: 18,
    fontWeight: 700,
    marginBottom: 3,
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
      marginBottom: 0,
    },
  },
}));

const ProductInfo = ({ product, isMobileMode = false }) => {
  const { isKiteUser } = useUser();
  const classes = useStyles({ isKiteUser });
  const {
    audience,
    product_id: stockNumber,
    brand_category: brandCategory,
    pi_required: piRequired,
    pi_associated: piAssociated,
    withdrawal_effective_date: withdrawalDate,
    expiration_date: expirationDate,
    unit_measure: unitMeasure,
    prepack_quantity: perUnitQty,
    owner: department,
    leave_behind: leaveBehind,
    size_vers: sizeVersion,
    public_external_notes: publicExternalNotes,
  } = product || {};
  const isExpired = isProductExpired(product);
  const isWithdraw = isProductWithdrawn(product);
  return (
    <Box flex={1}>
      {isMobileMode && (
        <div data-testid="stock-number" className={classes.detailTitle}>
          Stock Number: <span>{stockNumber}</span>
        </div>
      )}
      <div data-testid="brand-category" className={classes.detailTitle}>
        Brand Category: <span>{brandCategory}</span>
      </div>
      <div data-testid="therapeutic-area" className={classes.detailTitle}>
        Therapeutic Area: <span>{department}</span>
      </div>
      <div data-testid="PI-required" className={classes.detailTitle}>
        PI or IF Required: <span>{piRequired ? 'Yes' : 'No'}</span>
      </div>
      {piRequired && (
        <div data-testid="PI-associated" className={classes.detailTitle}>
          PI or IF Associated:{' '}
          <span>{piAssociated.map((pi) => pi).join('| ')}</span>
        </div>
      )}
      <div data-testid="leave-behind" className={classes.detailTitle}>
        Leave Behind: <span>{leaveBehind ? 'Yes' : 'No'}</span>
      </div>
      <div data-testid="audience" className={classes.detailTitle}>
        Audience: <span>{audience}</span>
      </div>
      <div data-testid="unit-of-measure" className={classes.detailTitle}>
        Unit Of Measure: <span>{unitMeasure}</span>
      </div>
      <div data-testid="per-unit-quantity" className={classes.detailTitle}>
        Per Unit Quantity: <span>{perUnitQty}</span>
      </div>
      {sizeVersion && (
        <div data-testid="size-version" className={classes.detailTitle}>
          Size version: <span>{sizeVersion}</span>
        </div>
      )}
      <div data-testid="notes" className={classes.detailTitle}>
        Public/External Notes:{' '}
        <span>{publicExternalNotes ? publicExternalNotes : '-'}</span>
      </div>
      {isExpired && (
        <div data-testid="expiration-date" className={classes.expired}>
          Expiration Date:{' '}
          <span className={classes.expired}>
            {format(new Date(expirationDate), 'MM/dd/yyyy')}
          </span>
        </div>
      )}
      {isWithdraw && (
        <div data-testid="withdrawal-date" className={classes.expired}>
          Withdrawn:{' '}
          <span className={classes.expired}>
            {format(new Date(withdrawalDate), 'MM/dd/yyyy')}
          </span>
        </div>
      )}
    </Box>
  );
};

export default ProductInfo;

const QuantityInfo = ({ product, unavailableLabel }) => {
  const classes = useStyles();
  const {
    product_available_qty: availableQuantity,
    limits,
    order_limit: maxOrderQuantity,
  } = product || {};

  const { monthLimit, annualLimit } = useMemo(
    () => getLimits(limits),
    [limits],
  );

  const monthRemainingQuantity = useMemo(
    () => getRemainingQuantity(monthLimit),
    [monthLimit],
  );
  const annualRemainingQuantity = useMemo(
    () => getRemainingQuantity(annualLimit),
    [annualLimit],
  );

  return (
    <div data-testid="quantity-info" className={classes.quantityContainer}>
      <div>
        <StockItem
          className={classes.stockItem}
          availableQuantity={availableQuantity}
          unavailableLabel={unavailableLabel}
        />
        {monthLimit?.restriction && monthLimit?.restriction >= 0 && (
          <div className={classes.detailTitle}>
            Monthly Limit: <span>{monthLimit?.restriction}</span>
          </div>
        )}
        {annualLimit?.restriction && annualLimit?.restriction >= 0 && (
          <div className={classes.detailTitle}>
            Annual Limit: <span>{annualLimit?.restriction}</span>
          </div>
        )}
      </div>
      <div>
        {maxOrderQuantity !== null && (
          <div className={classes.detailTitle}>
            Max Order Quantity: <span>{maxOrderQuantity}</span>
          </div>
        )}
        {monthRemainingQuantity !== null && monthRemainingQuantity >= 0 && (
          <div className={classes.detailTitle}>
            Remaining Limit In Month: <span>{monthRemainingQuantity}</span>
          </div>
        )}
        {annualRemainingQuantity !== null && annualRemainingQuantity >= 0 && (
          <div className={classes.detailTitle}>
            Remaining Limit In Year: <span>{annualRemainingQuantity}</span>
          </div>
        )}
      </div>
    </div>
  );
};
export { QuantityInfo };
