// Libs
import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';

// Utils
import { getAddressText } from '../../../utils/address';

const AddressesTruncatedLabel = ({ order, stringsQty = 1, className }) => {
  const addressText = getAddressText(order);
  const isDistro = !!order?.addresses?.length && order?.addresses?.length > 1;

  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

  return (
    <ResponsiveEllipsis
      text={`${addressText}${
        isDistro ? `+${order?.addresses?.length - 1}` : ''
      }`}
      maxLine={stringsQty}
      ellipsis={isDistro ? `...+${order?.addresses?.length - 1}` : '...'}
      basedOn="letters"
      className={className}
    />
  );
};

export default AddressesTruncatedLabel;
