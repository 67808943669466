// Lib
import React, { useCallback } from 'react';
import { Box, Rating as MuiRating } from '@mui/material';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

// Styles
import { colors } from '../../styles/palette';
import classnames from 'classnames';

// Hooks
import useUser from '../../hooks/useUser';
import useRating from '../../hooks/useRating';

const useStyles = makeStyles((theme) => ({
  myRate: ({ isKiteUser }) => ({
    fontSize: 14,
    fontWeight: isKiteUser ? '700' : '600',
    '& > legend': {
      fontSize: 14,
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 16,
      '& > legend': {
        fontSize: 16,
      },
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      fontWeight: '400',
      '& > legend': {
        fontSize: 14,
      },
    },
  }),
}));

const MyRating = ({
  product,
  className,
  withTitle = true,
  updateProductRating,
}) => {
  const { isKiteUser } = useUser();
  const classes = useStyles({ isKiteUser });
  const { myRate, canRate, setRate } = useRating(product);

  const onChange = useCallback(
    (event, newValue) => {
      const updateRate = updateProductRating
        ? updateProductRating(product.product_id)
        : setRate;
      !!newValue && updateRate(newValue);
    },
    [setRate, updateProductRating, product.product_id],
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      width="max-content"
      className={classnames(classes.myRate, className)}>
      {withTitle ? 'My Rating' : ''}
      <MuiRating
        disabled={!canRate}
        id="rateable"
        name="simple-controlled"
        value={myRate}
        onChange={onChange}
      />
      <Typography color={colors.grey2} component="legend">
        {`${myRate}/5`}
      </Typography>
    </Box>
  );
};

export default MyRating;
