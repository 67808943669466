// Constants
import { IGNORED_PRESCRIBING_INFO_VALUES } from '../constants/products';

const separatePrescribedProducts = (allProductsIds = [], productsObj = {}) => {
  let productIds = [];
  let prescribedProductIds = [];

  const isProductPrescribed = (productId) => {
    return IGNORED_PRESCRIBING_INFO_VALUES.includes(
      productsObj?.[productId]?.prescribing_information_format,
    );
  };

  allProductsIds?.forEach((id) => {
    (isProductPrescribed(id) ? prescribedProductIds : productIds).push(id);
  });

  return { productIds, prescribedProductIds };
};

export { separatePrescribedProducts };
