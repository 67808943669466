// Lib
import React from 'react';
import { Navigate, Route, Routes as Switch } from 'react-router-dom';

// Components
import Order from './Order';
import ApproveAll from './ApproveAll';

// Hooks
import useUser from '../../hooks/useUser';

const Approvals = () => {
  const { isLoggedIn } = useUser();

  return (
    <Switch>
      <Route path="/approve-all" element={<ApproveAll />} />
      <Route
        path="*"
        element={<Navigate to={isLoggedIn ? '/materials' : '/auth'} replace />}
      />
      <Route path="/order-qty/:id" element={<Order />} />
      <Route
        path="/shipping-method/:id"
        element={<Order isShippingMethod={true} />}
      />
    </Switch>
  );
};

export default Approvals;
