// Components
import React from 'react';

// Styles
import { colors } from '../../../styles/palette';

const ErrorMessage = ({ message }) => (
  <p style={{ color: colors.gilead.primary1 }}>{message}</p>
);

export default ErrorMessage;
