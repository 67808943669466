// Lib
import React, { useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

// Components
import CartProductQtyButtons from './CartProductQtyButtons/CartProductQtyButtons';
import KitsProductQtyButtons from '../Kits/KitsProductQtyButtons/KitsProductQtyButtons';

// Hooks
import useUser from '../../hooks/useUser';
import useUI from '../../hooks/useUI';

// Utils
import getRemainingQuantity from '../../utils/getRemainigQuantity';
import { getLimits } from '../../utils/overLimit';
import { getAddressesCount } from '../../pages/OrderHistory/utils/getQuantity';

// Styles
import { colors } from '../../styles/palette';

const useStyles = makeStyles((theme) => ({
  container: ({ orderedProduct }) => ({
    display: 'flex',
    '& > div:nth-child(1)': {
      flexBasis: orderedProduct ? '20%' : '27%',
    },
    '& > div:nth-child(2)': {
      flexBasis: orderedProduct ? '19%' : '26%',
    },
    '& > div:nth-child(3)': {
      flexBasis: orderedProduct ? '24%' : '18%',
    },
    '& > div:last-child': {
      alignItems: 'end',
      flexBasis: orderedProduct ? '24%' : '29%',
      paddingTop: theme.spacing(2),
    },
    [theme.breakpoints.down('lg')]: {
      '& > div:nth-child(1)': {
        alignItems: 'start !important',
        flexBasis: '60%',
      },
      '& > div:nth-child(2)': {
        alignItems: 'start !important',
        flexBasis: '40%',
      },
      '& > div:last-child': {
        paddingTop: 0,
      },
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        '& > div:nth-child(1)': {
          alignItems: 'start !important',
          flexBasis: '60%',
        },
        '& > div:nth-child(2)': {
          alignItems: 'start !important',
          flexBasis: '40%',
        },
        '& > div:last-child': {
          paddingTop: 0,
        },
      }),
    },
  }),
  details: {
    color: theme.palette.darkGrey,
    display: 'flex',
    flexBasis: '30%',
    flexDirection: 'column',
    gap: theme.spacing(2),
    fontSize: 14,
    fontWeight: 600,
    [theme.breakpoints.down('lg')]: {
      gap: 3,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        gap: 3,
      }),
    },
  },
  detailsItem: ({ isKiteUser }) => ({
    '& > span:first-child': {
      fontWeight: isKiteUser ? '700' : '600',
      display: 'block',
      [theme.breakpoints.down('lg')]: {
        paddingRight: theme.spacing(0.5),
        display: 'initial',
      },
      [theme.breakpoints.downLandscape('lg')]: {
        ...(theme.isMobileDevice && {
          paddingRight: theme.spacing(0.5),
          display: 'initial',
        }),
      },
    },
    '& > span:nth-child(2)': {
      fontWeight: isKiteUser ? '400' : '600',
    },
  }),
  inlineItem: {
    '& > *': {
      display: 'inline',
    },
  },
  detailsItemColored: ({ isKiteUser }) => ({
    color: isKiteUser ? theme.palette.primary1 : theme.palette.primary2,
    fontWeight: 'bold',
    position: 'relative',
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        marginTop: theme.spacing(4),
      }),
    },
  }),
  orderedProduct: {
    [theme.breakpoints.down('lg')]: {
      marginTop: ({ placedOrder }) => placedOrder && 'auto',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        marginTop: ({ placedOrder }) => placedOrder && 'auto',
      }),
    },
  },
  approvalInfo: {
    color: theme.palette.grey2,
    fontSize: 14,
    fontWeight: 400,
    position: 'absolute',
    left: 0,
    top: theme.spacing(3),
    width: 'max-content',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
  totalItems: () => ({
    color: theme.palette.red,
  }),
}));

const DetailsInfo = ({
  product,
  cartProps,
  orderedProduct,
  pendingApproval,
  kit,
  nums,
  isOverLimit,
  placedOrder,
  isUnavailableForOrder,
  unavailableLabel = false,
  shippingAddresses = 1,
}) => {
  const { isTabletMode, isMobileMode } = useUI();
  const { isKiteUser } = useUser();
  const classes = useStyles({
    orderedProduct,
    isKiteUser,
    placedOrder: !!placedOrder?.id,
  });

  const addressesCount = placedOrder ? getAddressesCount(placedOrder) : 1;

  const {
    brand_category,
    product_id: stockNumber,
    pi_required,
    unit_measure,
    product_available_qty,
    prepack_quantity,
    order_limit,
    limits,
  } = product || {};

  const { monthLimit } = useMemo(() => getLimits(limits), [limits]);

  const remainingMonthQuantity = useMemo(
    () => getRemainingQuantity(monthLimit),
    [monthLimit],
  );

  if (!isTabletMode) {
    return (
      <div className={classes.container}>
        <div className={classes.details}>
          <div
            className={classes.detailsItem}
            data-testid="product-card-stock-number">
            <span>Stock Number:</span>
            <span>{stockNumber}</span>
          </div>
          <div
            className={classes.detailsItem}
            data-testid="product-card-brand-category">
            <span>Brand Category:</span>
            <span>{brand_category}</span>
          </div>
        </div>

        <div className={classes.details}>
          <div
            className={classes.detailsItem}
            data-testid="product-card-unit-measure">
            <span>Unit Measure:</span>
            <span>{unit_measure}</span>
          </div>
          <div
            className={classes.detailsItem}
            data-testid="product-card-pi-required">
            <span>PI Required:</span>
            <span>{pi_required ? 'Yes' : 'No'}</span>
          </div>
        </div>

        <div className={classes.details}>
          <div className={classes.detailsItem}>
            <div
              className={classes.detailsItem}
              data-testid="product-card-per-unit-qty">
              <span style={{ display: 'inline' }}>Per Unit Qty: </span>
              <span style={{ display: 'inline' }}>{prepack_quantity}</span>
            </div>
            <div
              className={classes.detailsItem}
              data-testid="product-card-max-allowed-qty"
              style={{
                opacity: order_limit !== null ? 'initial' : 0,
                whiteSpace: order_limit === null ? 'unset' : 'nowrap',
              }}>
              <span style={{ display: 'inline' }}>Max. Allowed Qty: </span>
              <span style={{ display: 'inline' }}>{order_limit}</span>
            </div>
          </div>
          <div>
            {!isUnavailableForOrder ? (
              <div
                className={classes.detailsItem}
                data-testid="product-card-available-qty">
                <span style={{ display: 'inline' }}>In Stock: </span>
                <span style={{ display: 'inline' }}>
                  {product_available_qty}
                </span>
              </div>
            ) : (
              <span style={{ color: colors.gilead.primary1, fontWeight: 700 }}>
                {unavailableLabel}
              </span>
            )}
            <div
              className={classes.detailsItem}
              data-testid="product-card-left-in-month">
              {remainingMonthQuantity !== null &&
                remainingMonthQuantity >= 0 && (
                  <span>{`${remainingMonthQuantity} Left In Month`}</span>
                )}
            </div>
          </div>
        </div>
        {orderedProduct && (
          <div className={classes.details}>
            <div className={classes.detailsItemColored}>
              <div data-testid="product-card-order-qty">{`Order Qty${
                pendingApproval ? ' (Requested)' : ''
              }: ${orderedProduct?.qty_ordered * addressesCount}`}</div>
              <div
                className={classes.totalItems}
                data-testid="product-card-total-ordered">{`Total Ordered: ${
                orderedProduct?.qty_ordered * prepack_quantity * addressesCount
              }`}</div>
            </div>
          </div>
        )}
        <div className={classes.details}>
          {!orderedProduct &&
            !placedOrder?.id &&
            kit &&
            !isUnavailableForOrder && (
              <KitsProductQtyButtons
                product={product}
                isMobileMode={isMobileMode}
                nums={nums}
                detailsMode={false}
                listView={true}
                className={classes.qtyButtons}
              />
            )}
          {!orderedProduct && !placedOrder?.id && !kit && (
            <CartProductQtyButtons
              isOverLimit={isOverLimit}
              shippingAddresses={shippingAddresses}
              product={product}
              onAdd={cartProps.addOne(product)}
              onRemove={cartProps.removeOne(product)}
              onSetCount={cartProps.setCount}
              count={cartProps.count}
              isMobileMode={isMobileMode}
              detailsMode={false}
              className={classes.qtyButtons}
            />
          )}
        </div>
      </div>
    );
  }
  if (isTabletMode && !isMobileMode) {
    return (
      <div className={classes.container}>
        <div className={classes.details}>
          <div
            className={classes.detailsItem}
            data-testid="product-card-stock-number">
            <span>Stock Number:</span>
            <span>{stockNumber}</span>
          </div>
          <div
            className={classes.detailsItem}
            data-testid="product-card-brand-category">
            <span>Brand Category:</span>
            <span>{brand_category}</span>
          </div>
          <div
            className={classes.detailsItem}
            data-testid="product-card-unit-measure">
            <span>Unit Measure:</span>
            <span>{unit_measure}</span>
          </div>
          <div
            className={classes.detailsItem}
            data-testid="product-card-pi-required">
            <span>PI Required:</span>
            <span>{pi_required ? 'Yes' : 'No'}</span>
          </div>
          {orderedProduct && (
            <div
              className={classnames(
                classes.detailsItem,
                classes.detailsItemColored,
              )}>
              <span data-testid="product-card-order-qty">{`Order Qty: ${
                orderedProduct?.qty_ordered * addressesCount
              }`}</span>
              {pendingApproval && (
                <div className={classes.approvalInfo}>Pending Approval</div>
              )}
            </div>
          )}
        </div>
        <div className={classes.details}>
          <div style={{ flexDirection: 'column', display: 'flex' }}>
            <div
              className={classes.detailsItem}
              data-testid="product-card-per-unit-qty">
              <span>Per Unit Qty: </span>
              <span>{prepack_quantity}</span>
            </div>
            <div
              className={classes.detailsItem}
              data-testid="product-card-max-allowed-qty"
              style={{
                display: order_limit !== null ? 'initial' : 'none',
              }}>
              <span>Max. Allowed Qty: </span>
              <span>{order_limit}</span>
            </div>
          </div>
          <div style={{ flexDirection: 'column', display: 'flex' }}>
            {!isUnavailableForOrder ? (
              <div
                className={classes.detailsItem}
                data-testid="product-card-available-qty">
                <span>In Stock: </span>
                <span>{product_available_qty}</span>
              </div>
            ) : (
              <span style={{ color: colors.gilead.primary1, fontWeight: 700 }}>
                {unavailableLabel}
              </span>
            )}
            {remainingMonthQuantity !== null && remainingMonthQuantity >= 0 && (
              <span>{`${remainingMonthQuantity} Left In Month`}</span>
            )}
          </div>
          {orderedProduct && (
            <div
              className={classnames(
                classes.detailsItem,
                classes.detailsItemColored,
                classes.orderedProduct,
              )}>
              <span
                className={classes.totalItems}
                data-testid="product-card-total-ordered">{`Total Ordered: ${
                orderedProduct?.qty_ordered * prepack_quantity
              }`}</span>
            </div>
          )}
        </div>
      </div>
    );
  }
  if (isMobileMode) {
    return <></>;
  }
};

export default DetailsInfo;
