// Lib
import { useSelector } from 'react-redux';

// Constants
import USER_TYPE from '../constants/user';
import {
  USER_VIEW_WITHOUT_ALL_LIMITS,
  USER_VIEW_WITHOUT_QUANTITY_LIMIT,
} from '../constants/cart';

const useUser = () => {
  const {
    userType,
    isKiteUser,
    isLoggedIn,
    prevPath,
    isGridView,
    email,
    userView,
  } = useSelector((state) => ({
    userType: [USER_TYPE.gilead, USER_TYPE.kite].includes(state?.user?.company)
      ? state.user.company
      : USER_TYPE.gilead,
    isKiteUser: state?.user?.company === 'kite',
    isLoggedIn: !!state?.user?.email,
    prevPath: state?.location?.prevPath,
    isGridView: state?.user?.catalogView === 'grid',
    email: state?.user?.email,
    firstName: state.user?.firstName,
    userView: state?.user?.userView,
  }));

  return {
    userType: userType ? userType : USER_TYPE.gilead,
    isKiteUser,
    isLoggedIn,
    prevPath,
    isGridView,
    email,
    userWithoutAllLimits: USER_VIEW_WITHOUT_ALL_LIMITS.includes(userView),
    userWithoutQuantityLimit:
      USER_VIEW_WITHOUT_QUANTITY_LIMIT.includes(userView),
    userView,
  };
};

export default useUser;
