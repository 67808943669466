import { OTHER_FILTER_TYPE } from '../constants/products';

const isMatchFilter = (product, filters, name) => {
  let isMatch;
  const isChecked = !!filters?.[name].find((item) => item.checked);
  if (isChecked) {
    isMatch = !!filters[name].find((item) => {
      if (item.checked && item.title === OTHER_FILTER_TYPE) {
        return !product[name] || product[name] === 'Other';
      }
      if (item.checked && item.title !== OTHER_FILTER_TYPE) {
        return item.title === product[name];
      }
    });
  } else {
    isMatch = true;
  }

  return isMatch;
};

export default isMatchFilter;
