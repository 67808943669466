// Lib
import React, { useMemo } from 'react';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';

// Components
import EditRecurrenceButton from '../../../components/OrderHistory/Buttons/EditRecurrenceButton';
import SkipButton from '../../../components/OrderHistory/Buttons/SkipButton';
import DetailsButton from '../../../components/OrderHistory/Buttons/DetailsButton';
import AddressesTruncatedLabel from '../../../components/OrderHistory/Labels/AddressesTruncatedLabel';

// Hooks
import useRecurringOrder from './useRecurringOrder';

// Utils
import { getOrderQty, getTotalOrderedQty } from '../utils/getQuantity';
import { getFormattedNextRunDate } from '../../../utils/date';

// Selectors
import { productsObjSelector } from '../../../store/selectors';

const useStyles = makeStyles((theme) => ({
  cancelButton: {
    color: ({ isKiteUser }) =>
      isKiteUser ? theme.palette.primary1 : theme.palette.pressed,
    fontWeight: ({ isKiteUser }) => (isKiteUser ? '400' : '600'),
    textDecoration: 'none',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    padding: 0,
    backgroundColor: 'transparent !important',

    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  address: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  totalItems: {
    color: theme.palette.red,
  },
}));

const useRecurringOrderCellReducer = ({ order }) => {
  const productsObj = useSelector((state) => productsObjSelector(state));
  const classes = useStyles();
  const { onEditRecurrence, onCancel, onSkip, loadingSkip } =
    useRecurringOrder(order);

  const cells = useMemo(
    () => ({
      nextShipping: getFormattedNextRunDate(order?.next_run),
      shippingAddress: (
        <AddressesTruncatedLabel
          order={order}
          stringsQty={2}
          className={classes.address}
        />
      ),
      orderQty: getOrderQty(order),
      totalOrdered: (
        <span className={classes.totalItems}>
          {getTotalOrderedQty(order, productsObj)}
        </span>
      ),
      editRecurrence: <EditRecurrenceButton onClick={onEditRecurrence} />,
      cancel: (
        <Button
          onClick={onCancel}
          className={classes.cancelButton}
          data-testid="cancel-recurrence-button">
          Cancel
        </Button>
      ),
      skip: (
        <SkipButton
          loading={loadingSkip}
          onClick={onSkip}
          data-testid="skip-recurrence-button"
        />
      ),
      details: (
        <DetailsButton
          to={`${order.id}`}
          data-testid="recurrence-details-button"
        />
      ),
    }),
    [
      order,
      productsObj,
      onEditRecurrence,
      onCancel,
      loadingSkip,
      onSkip,
      classes,
    ],
  );

  return { cells };
};

export default useRecurringOrderCellReducer;
