// Lib
import React from 'react';
import { makeStyles } from '@mui/styles';

// Components
import FdmButton from '../Card/FdmButton';

// Hooks
import useProductDetailsQtyButtons from '../../../hooks/useProductDetailsQtyButtons';

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    display: 'flex',
    gap: theme.spacing(3.75),
    marginTop: theme.spacing(3),
    width: '80%',
    maxWidth: 360,
    '& #fdm-button': {
      fontSize: 14,
      width: 130,
    },
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      maxWidth: 'initial',
      width: '100%',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        flexDirection: 'row',
        justifyContent: 'space-between',
        maxWidth: 'initial',
        width: '100%',
      }),
    },
    '& .MuiCardActions-root': {
      flexDirection: 'row',
      '& #count-button': {
        width: 130,
      },
      '& button': {
        width: 130,
      },
      [theme.breakpoints.down('lg')]: {
        gap: theme.spacing(2.5),
        '& #count-button': {
          width: 200,
        },
        '& button': {
          width: 200,
        },
      },
      [theme.breakpoints.down('md')]: {
        gap: theme.spacing(2),
        '& #count-button': {
          width: 140,
        },
        '& button': {
          width: 140,
        },
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3.375),
    },
  },
  fdmButton: {
    height: 36,
    [theme.breakpoints.down('lg')]: {
      height: 44,
    },
  },
}));

const ProductDetailsButtons = ({
  product,
  isMobileMode,
  cartProps,
  detailsMode = false,
  hideQtyButtons = false,
}) => {
  const classes = useStyles();
  const { productQtyButtons } = useProductDetailsQtyButtons(
    product,
    cartProps,
    isMobileMode,
    detailsMode,
  );

  return (
    <div className={classes.buttonsContainer}>
      {!hideQtyButtons && productQtyButtons}
      {!isMobileMode && (
        <FdmButton className={classes.fdmButton} product={product} />
      )}
    </div>
  );
};

export default ProductDetailsButtons;
