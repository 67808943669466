// Libs
import { put, takeEvery, delay } from 'redux-saga/effects';

// Api
import http from '../../../api/http';

// Actions
import {
  UserLogIn,
  SetUser,
  GetUser,
  UserLogOut,
  ClearUser,
  UserSsoLogIn,
} from '../../../actions/user';
import {
  ClearSsoData,
  ClearTokenData,
  SetAuthData,
} from '../../../actions/auth';
import { SetApiError } from '../../../actions/apiError';
import { SetLoading } from '../../../actions/loading';

// URLS
import { BASE_URL } from '../../../constants/urls';
import { AUTH_URLS, USER_URLS } from '../../../constants/urls';

// history
import browserHistory from '../../../browserHistory';

// Store
import { store } from '../../../store';

// Constants
import { COMMON_API_ERROR } from '../../../constants/errors';

export function* userLogOutSaga() {
  yield put(ClearTokenData());
  const { auth } = store.getState();

  if (auth.sso) {
    browserHistory?.push('/auth/sso/logout');
  } else {
    browserHistory?.push('/auth');
  }

  yield delay(10);

  // Clear user type
  yield put(ClearUser());

  yield put(ClearSsoData());
}

export function* getUserSaga() {
  const userData = yield http.get(USER_URLS.getUser);
  const settings = userData?.data?.profile_settings;

  const user = {
    firstName: userData?.data?.prereg_user_first_name,
    lastName: userData?.data?.prereg_user_last_name,
    email: userData?.data?.prereg_user_email,
    userView: userData?.data?.prereg_user_view,
    accessGroup: userData?.data?.access_group,
    company: userData?.data?.company.toLowerCase(),
    catalogView: settings?.view?.toLowerCase() || 'list',
  };
  yield put(SetUser(user));
}

export function* userLogInSaga({ payload }) {
  try {
    const { username, password } = payload;
    yield put(ClearTokenData());
    yield put(SetLoading(true));

    const authorizationHeader = `Basic ${btoa(`${username}:${password}`)}`;
    const authData = yield http.post(AUTH_URLS.obtainToken, null, {
      baseURL: `${BASE_URL}/`,
      headers: {
        Authorization: authorizationHeader,
      },
    });
    yield put(SetAuthData(authData?.data));
    yield getUserSaga();

    const state = store.getState();
    const prevPath = state.location.prevPath;

    if (prevPath) {
      browserHistory.push(prevPath);
      return;
    }
    browserHistory.push('/materials');
  } catch (e) {
    const error = e?.response?.data;
    yield put(
      SetApiError({
        api: 'logIn',
        error: error?.detail || error?.[0] || COMMON_API_ERROR,
      }),
    );
  } finally {
    yield put(SetLoading(false));
  }
}

export function* userSsoLogInSaga() {
  try {
    yield getUserSaga();

    const state = store.getState();
    const prevPath = state?.location?.prevPath;

    if (prevPath) {
      browserHistory.push(prevPath);
      return;
    }
    browserHistory.push('/materials');
  } catch (e) {
    yield put(
      SetApiError({
        api: 'logIn',
        error: COMMON_API_ERROR,
      }),
    );
  } finally {
    yield put(SetLoading(false));
  }
}

function* authSaga() {
  yield takeEvery(UserLogIn, userLogInSaga);
  yield takeEvery(UserSsoLogIn, userSsoLogInSaga);
  yield takeEvery(GetUser, getUserSaga);
  yield takeEvery(UserLogOut, userLogOutSaga);
}

export default authSaga;
