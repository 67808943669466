// Lib
import React from 'react';
import { makeStyles } from '@mui/styles';

import packageFile from '../../../package.json';

const useStyles = makeStyles((theme) => ({
  root: {
    fontWeight: 'bold',
    padding: theme.spacing(2),
  },
}));

const Version = () => {
  const classes = useStyles();

  return (
    <div
      className={
        classes.root
      }>{`Frontend version: ${packageFile.version}`}</div>
  );
};

export default Version;
