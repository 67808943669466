// Libs
import { takeEvery, put, call } from 'redux-saga/effects';

// Api
import http from '../api/http';

// Actions
import { SetFavoriteValue, UpdateFavoriteValue } from '../actions/products';
import { SetApiError } from '../actions/apiError';

// Constants
import { PRODUCTS_URLS } from '../constants/urls';
import { COMMON_API_ERROR } from '../constants/errors';

export function* setFavoriteValueSaga({ payload }) {
  const { productId, favoriteValue } = payload;
  try {
    yield put(UpdateFavoriteValue({ productId, favoriteValue }));

    if (favoriteValue === true) {
      yield call(http.put, PRODUCTS_URLS.updateProductFavoriteValue(productId));
    } else {
      yield call(
        http.delete,
        PRODUCTS_URLS.updateProductFavoriteValue(productId),
      );
    }
  } catch (e) {
    yield put(
      SetApiError({
        api: 'setFavoriteValue',
        error: COMMON_API_ERROR,
      }),
    );
    yield put(
      UpdateFavoriteValue({ productId, favoriteValue: !favoriteValue }),
    );
  }
}

function* favoriteSaga() {
  yield takeEvery(SetFavoriteValue, setFavoriteValueSaga);
}

export default favoriteSaga;
