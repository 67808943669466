// Lib
import React, { useMemo } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@mui/styles';

// Components
import { ReactComponent as Arrow } from '../../../assets/svg/arrow.svg';

// Styles
import { colors } from '../../../styles/palette';

const MenuProps = (classic) => ({
  PaperProps: {
    style: {
      borderRadius: 12,
      backgroundColor: classic ? 'none' : colors.lightGrey,
      border: classic ? '1px solid #929A92' : 'none',
      marginTop: 15,
      '& ul': {
        paddingBottom: 4,
        paddingTop: 4,
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  menuItem: {
    height: 29,
    paddingBottom: 0,
    paddingTop: 0,
  },
  checkbox: {
    '& svg': {
      borderRadius: 4,
      height: 20,
      width: 20,
    },
  },
}));

const MultipleSelect = ({
  items = [],
  name,
  label,
  width,
  onChangeFilter = () => {},
  className,
  required,
  classic = false,
  labelColor,
}) => {
  const checkedValues = useMemo(
    () => items.filter((item) => item.checked).map((item) => item.title),
    [items],
  );
  const classes = useStyles();

  const renderValue = (selected) => {
    const selectedCount = selected?.length;

    if (selectedCount === 0 && label) {
      return (
        <>
          <span style={labelColor && { color: labelColor }}>{label}</span>
          {required && (
            <span
              style={{
                marginLeft: '4px',
                color: colors.gilead.primary1,
              }}>
              *
            </span>
          )}
        </>
      );
    }

    if (selectedCount > 1) {
      let shortestItem;
      if (selected.includes('HBV')) {
        shortestItem = 'HBV';
      } else {
        shortestItem = selected.sort((a, b) =>
          a.length < b.length ? -1 : 1,
        )[0];
      }
      return `${shortestItem} +${selectedCount - 1}`;
    } else {
      return selected?.[0];
    }
  };

  return (
    <FormControl
      className={className}
      sx={{ minWidth: 96, width }}
      id={classic ? 'classic-select' : 'select'}>
      <Select
        disabled={!items.length}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        IconComponent={classic ? undefined : Arrow}
        id="multiple-select"
        multiple
        value={checkedValues}
        onChange={onChangeFilter(name)}
        renderValue={renderValue}
        MenuProps={MenuProps(classic)}>
        {items?.map(({ title }, index) => (
          <MenuItem
            className={classes.menuItem}
            key={`${title}_${index}`}
            value={title}>
            <Checkbox
              className={classes.checkbox}
              checked={checkedValues?.includes(title)}
            />
            <ListItemText primary={title} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultipleSelect;
