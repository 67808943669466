// Lib
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';

// Components
import EditQtyCard from '../../../components/Approvals/OrderQty/EditQtyCard';
import ConfirmCard from '../../../components/Cards/ConfirmCard';

// Hooks
import useModal from '../../../hooks/useModal';
import useUser from '../../../hooks/useUser';

// Actions
import { OpenModal } from '../../../actions/modal';
import {
  AddAction,
  EditRequestedQty,
  SetOrderQtyError,
} from '../../../actions/approvals';

const useStyles = makeStyles((theme) => ({
  modal: {
    width: 'min(70vw, 611px) !important',
    minHeight: 'initial',
    [theme.breakpoints?.down('md')]: {
      borderRadius: 10,
      width: '85vw !important',
    },
  },
}));

const useProduct = (id, qtyApproved) => {
  const dispatch = useDispatch();
  const { onClose } = useModal();
  const classes = useStyles();
  const { product } = useSelector((state) => ({
    product: state.approvals?.approvals?.products_limits?.[id],
  }));
  const { email } = useUser();

  const isRejected = useMemo(() => product?.action === 'reject', [product]);

  const canApproveProduct = useMemo(
    () =>
      !!product?.approvers?.find(
        (approver) => approver?.approver_email === email,
      ),
    [email, product],
  );

  const onChangeRequestedQty = useCallback(
    (value) => {
      dispatch(EditRequestedQty({ id, value }));
    },
    [id, dispatch],
  );

  const onSaveQty = useCallback(
    (value) => {
      const newQty = typeof value === 'number' ? String(value) : value;
      dispatch(EditRequestedQty({ id, value: newQty?.trim() }));
      onClose();
    },
    [id, onClose, dispatch],
  );

  const addAction = useCallback(
    (action) => () => {
      dispatch(AddAction({ id, action }));
    },
    [id, dispatch],
  );

  const onReject = useCallback(() => {
    dispatch(AddAction({ id, action: 'reject' }));
    onClose();
  }, [dispatch, id, onClose]);

  const onOpenConfirmModal = useCallback(() => {
    dispatch(
      OpenModal({
        Component: () => (
          <ConfirmCard
            isAcceptBtnMain={false}
            title="Do You Want To Reject?"
            message="Rejecting Will Remove This Item From The Order."
            onConfirm={onReject}
          />
        ),
        className: classes.modal,
      }),
    );
  }, [dispatch, onReject, classes]);

  const setOrderQtyError = useCallback(
    (error) => dispatch(SetOrderQtyError(error)),
    [dispatch],
  );

  const onOpenQtyModal = useCallback(() => {
    dispatch(
      OpenModal({
        Component: () => (
          <EditQtyCard
            setOrderQtyError={setOrderQtyError}
            orderLimit={product?.qty_ordered}
            onSave={onSaveQty}
            qtyApproved={qtyApproved}
            onChangeRequestedQty={onChangeRequestedQty}
            onClose={onClose}
          />
        ),
        props: {
          title: '',
          paddingContent: {
            lg: '32px 40px 16px',
            md: '20px 18px 20px',
          },
        },
        styles: {
          width: 'min(85vw, 435px)',
        },
      }),
    );
  }, [
    dispatch,
    onChangeRequestedQty,
    onClose,
    onSaveQty,
    qtyApproved,
    setOrderQtyError,
    product,
  ]);

  const isAlreadyApproved = useMemo(() => !!product?.status, [product]);

  return {
    onChangeRequestedQty,
    onOpenQtyModal,
    onOpenConfirmModal,
    onSaveQty,
    addAction,
    canApproveProduct,
    isRejected,
    setOrderQtyError,
    isAlreadyApproved,
  };
};

export default useProduct;
