// Libs
import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

// Components
import Button from '../../../Button';
import DetailsButton from '../../Buttons/DetailsButton';
import EditRecurrenceButton from '../../Buttons/EditRecurrenceButton';
import SkipButton from '../../Buttons/SkipButton';
import AddressesTruncatedLabel from '../../Labels/AddressesTruncatedLabel';

// Hooks
import useUser from '../../../../hooks/useUser';
import useUI from '../../../../hooks/useUI';
import useRecurringOrder from '../../../../pages/OrderHistory/hooks/useRecurringOrder';

// Utils
import {
  getOrderQty,
  getTotalOrderedQty,
} from '../../../../pages/OrderHistory/utils/getQuantity';
import { getFormattedNextRunDate } from '../../../../utils/date';
import getGridPropValues from '../../../../utils/getGridPropValues';

// Selectors
import { productsObjSelector } from '../../../../store/selectors';

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: 'white',
    boxShadow: '0px 5px 15px rgba(44, 112, 172, 0.2)',
    borderRadius: '20px',
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginLeft: 0,
    width: '100%',
    '&:first-child': {
      marginTop: theme.spacing(2),
    },
    height: 167,
    [theme.breakpoints.down('md')]: {
      borderRadius: 10,
      height: 147,
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        borderRadius: 10,
        height: 147,
        marginBottom: theme.spacing(2),
      }),
    },
  },
  headerText: ({ isKiteUser }) => ({
    color: isKiteUser ? theme.palette.darkGrey : theme.palette.black,
    fontWeight: isKiteUser ? 700 : 600,
    fontSize: '14px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
      paddingRight: theme.spacing(0.5),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: '12px',
        paddingRight: theme.spacing(0.5),
      }),
    },
  }),
  text: ({ isKiteUser }) => ({
    color: theme.palette.darkGrey,
    fontWeight: isKiteUser ? 400 : 600,
    fontSize: '14px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: '12px',
      }),
    },
  }),
  skipButton: {
    backgroundColor: '#F4F4F4',
    color: ({ isKiteUser }) =>
      isKiteUser ? theme.palette.primary1 : theme.palette.pressed,
    fontWeight: ({ isKiteUser }) => (isKiteUser ? '400' : '600'),
    textDecoration: 'none',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
  cancel: {
    [theme.breakpoints.down('lg')]: {
      fontSize: 14,
    },
    [theme.breakpoints.down('md')]: {
      order: 1,
      width: 100,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        fontSize: 14,
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        order: 1,
        width: 100,
      }),
    },
  },
  buttonsContainer: {
    paddingRight: theme.spacing(2.5),
    [theme.breakpoints.down('md')]: {
      order: 4,
      paddingRight: '10px',
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        order: 4,
        paddingRight: '10px',
      }),
    },
  },
  totalItems: {
    color: () => theme.palette.red,
  },
}));

const RecurringOrderCard = ({ order, className }) => {
  const productsObj = useSelector((state) => productsObjSelector(state));
  const { isKiteUser } = useUser();
  const classes = useStyles({
    isKiteUser,
  });
  const { isMobileMode, isTabletMode } = useUI();
  const { onCancel, onSkip, onEditRecurrence, loadingSkip } =
    useRecurringOrder(order);
  const gridPropValues = getGridPropValues(isTabletMode, isMobileMode);

  return (
    <Grid
      container
      spacing={gridPropValues({
        lgSize: 2.5,
        mdSize: '10px',
      })}
      className={classnames(className, classes.card)}>
      <Grid
        item
        flexBasis={gridPropValues({
          lgSize: 5.8,
          mdSize: 12,
          unit: '%',
        })}>
        <Grid container alignContent={isMobileMode ? 'center' : 'initial'} item>
          <Typography fontSize={{ md: 18 }} variant="h3">
            {`Next Shipping:  ${getFormattedNextRunDate(order?.next_run)}`}
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.detailsBtnContainer}>
          <DetailsButton to={`${order.id}`} padding={0} />
        </Grid>
      </Grid>
      <Grid
        item
        flexBasis={gridPropValues({
          lgSize: 2.5,
          unit: '%',
        })}
        // Fixed width to properly align total ordered label in mobile mode
        // without changing grid structure
        width={isMobileMode ? '158px' : 'init'}>
        <Box display="flex" flexDirection={!isMobileMode ? 'column' : 'row'}>
          <Typography className={classes.headerText}>Order Qty:</Typography>
          <Typography className={classes.text}>{getOrderQty(order)}</Typography>
        </Box>
      </Grid>
      <Grid
        alignItems="center"
        justifyContent={isMobileMode ? 'start' : 'end'}
        item
        flexBasis={gridPropValues({
          lgSize: 3.7,
          mdSize: 12,
          unit: '%',
        })}
        container
        className={classes.buttonsContainer}
        display="flex">
        <Button className={classes.cancel} variant="text" onClick={onCancel}>
          Cancel
        </Button>
        <SkipButton onClick={onSkip} loading={loadingSkip} />
      </Grid>
      {!isMobileMode && (
        <Grid
          item
          flexBasis={gridPropValues({
            lgSize: 5.8,
            mdSize: 12,
            unit: '%',
          })}>
          <Box display={'flex'} flexDirection={'column'}>
            <Typography className={classes.headerText}>
              Shipping Address:
            </Typography>
            <AddressesTruncatedLabel
              stringsQty={2}
              order={order}
              className={classes.text}
            />
          </Box>
        </Grid>
      )}
      <Grid
        item
        flexBasis={gridPropValues({
          lgSize: 2.5,
          unit: '%',
        })}>
        <Box display="flex" flexDirection={!isMobileMode ? 'column' : 'row'}>
          <Typography
            className={classnames(classes.headerText, classes.totalItems)}>
            Total Ordered:
          </Typography>
          <Typography className={classnames(classes.text, classes.totalItems)}>
            {getTotalOrderedQty(order, productsObj)}
          </Typography>
        </Box>
      </Grid>
      {!isMobileMode && (
        <Grid
          item
          flexBasis={gridPropValues({
            lgSize: 3.7,
            mdSize: 6,
            unit: '%',
          })}
          container
          pr={2.5}
          alignContent={'end'}
          justifyContent={'right'}>
          <EditRecurrenceButton onClick={onEditRecurrence} />
        </Grid>
      )}
    </Grid>
  );
};

export default RecurringOrderCard;
