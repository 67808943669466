const getTotalQty = (kit, productsObj) => {
  const totalQty = kit.products.reduce(
    (totalQty, { product, nums }) =>
      totalQty + nums * productsObj?.[product]?.prepack_quantity,
    0,
  );
  if (isNaN(totalQty)) {
    return '';
  }
  return totalQty;
};

export { getTotalQty };
