// Libs
import React from 'react';
import { Typography } from '@mui/material';

// Hooks
import useUser from '../../hooks/useUser';
import capitalize from '../../utils/capitalize';

/* type can be kit / order / material / question depends on page */

const TotalQtyLabel = ({ qty, type, ending, className, styles }) => {
  const { isKiteUser } = useUser();
  return (
    <Typography
      as="div"
      variant={isKiteUser ? 'textMd' : 'textMdSemibold'}
      color={'darkGrey'}
      className={className}
      style={styles}>
      {qty > 0
        ? `Total: ${qty} ${capitalize(type)}(${ending || 's'})`
        : 'No Matches'}
    </Typography>
  );
};

export default TotalQtyLabel;
