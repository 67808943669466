// Lib
import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, Box } from '@mui/material';

// Components
import FiltersBox from './FiltersBox';
import Search from '../../Search';
import SideMenu from '../../SideMenu';
import Feather from '../../Feather';
import Breadcrumbs from '../../Breadcrumbs';

// Hooks
import useSideMenu from '../../../hooks/useSideMenu';
import useSearch from '../../../hooks/useSearch';
import useUI from '../../../hooks/useUI';
import ViewButtons from './ViewButtons';
import MobileFilters from './MobileFilters';

// Styles
import { colors } from '../../../styles/palette';

// Constants
import { SHIPPING_ADDRESS_BREADCRUMBS } from '../../../constants/cart';

const useStyles = makeStyles((theme) => ({
  bar: ({ onlyContent }) => ({
    backgroundColor: 'white',
    margin: '0 auto',
    maxWidth: 1180,
    position: 'sticky',
    padding: `40px 26px 12px`,
    top: 50,
    zIndex: 100,
    [theme.breakpoints.down('lg')]: {
      top: onlyContent ? 0 : 40,
    },
    [theme.breakpoints.down('md')]: {
      top: 50,
      padding: `${theme.spacing(1)} ${theme.spacing(1.5)} 12px`,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        top: onlyContent ? 0 : 40,
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        top: 50,
        padding: `${theme.spacing(1)} ${theme.spacing(1.5)} 12px`,
      }),
    },
  }),
  titleContainer: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(2),
      }),
    },
  },
  title: {
    [theme.breakpoints.down('md')]: {
      fontSize: 22,
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 22,
      }),
    },
  },
  filtersBox: {
    display: 'flex',
    gap: theme.spacing(4),
    width: '100%',
    [theme.breakpoints.down('xl')]: {
      gap: theme.spacing(2),
    },
    [theme.breakpoints.downLandscape('xl')]: {
      ...(theme.isMobileDevice && {
        gap: theme.spacing(2),
      }),
    },
  },
  button: {
    backgroundColor: '#F4F4F4',
    fontSize: 14,
  },
  search: {
    [theme.breakpoints.down('xl')]: {
      width: 300,
    },
    [theme.breakpoints.downLandscape('xl')]: {
      ...(theme.isMobileDevice && {
        width: 300,
      }),
    },
  },
  mobileBox: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      '& > div:nth-child(1)': {
        order: 1,
      },
      '& > div:nth-child(2)': {
        order: 0,
      },
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        '& > div:nth-child(1)': {
          order: 1,
        },
        '& > div:nth-child(2)': {
          order: 0,
        },
      }),
    },
  },
  searchResult: {
    color: colors.darkGrey,
    fontWeight: 600,
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 14,
        marginTop: theme.spacing(2),
      }),
    },
  },
  noMatches: {
    marginTop: theme.spacing(2),
    fontSize: 18,
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 16,
      }),
    },
  },
}));

const FiltersBar = ({
  filters,
  isListView,
  onChangeView,
  onAddAddress,
  onChangeSorting,
  importAddresses,
  onApplyMobileFilters,
  searchResults,
  isLoading,
  isAddressBookPage = true,
  onlyContent = false,
}) => {
  const classes = useStyles({ onlyContent });
  const { isDesktopMode, isTabletMode, isMobileMode } = useUI();
  const { active, toggleSideMenu, onCloseSideMenu } = useSideMenu();
  const { onChange, searchValue, onClearSearch } = useSearch();

  return (
    <>
      {!isLoading && (
        <>
          <div className={classes.bar}>
            {(!isAddressBookPage
              ? isDesktopMode || isTabletMode
              : isTabletMode) && (
              <Box
                className={classes.titleContainer}
                display="flex"
                justifyContent="space-between"
                alignItems="center">
                {isDesktopMode && !isAddressBookPage ? (
                  !onlyContent && (
                    <Breadcrumbs items={SHIPPING_ADDRESS_BREADCRUMBS} />
                  )
                ) : (
                  <Typography className={classes.title} variant="h2">
                    {!isAddressBookPage && !onlyContent
                      ? 'Shipping Address'
                      : 'Address Book'}
                  </Typography>
                )}
                {isMobileMode ? (
                  <Feather
                    colorType="primary1"
                    type="filters"
                    onClick={toggleSideMenu(true)}
                  />
                ) : (
                  !onlyContent && (
                    <Search
                      loadingDelay={200}
                      value={searchValue}
                      onChange={onChange}
                      onClearSearch={onClearSearch}
                      withMenu
                      options={searchResults}
                    />
                  )
                )}
              </Box>
            )}
            {isDesktopMode ? (
              <>
                <Box width="100%" display="flex">
                  <ViewButtons
                    isListView={isListView}
                    onChangeView={onChangeView}
                    isMobileMode={isMobileMode}
                  />
                  <FiltersBox
                    isAddressBookPage={isAddressBookPage}
                    filters={filters}
                    isMobileMode={isMobileMode}
                    isDesktopMode={isDesktopMode}
                    onAddAddress={onAddAddress}
                    onChangeSorting={onChangeSorting}
                    importAddresses={importAddresses}
                  />
                  {onlyContent && (
                    <Search
                      loadingDelay={200}
                      value={searchValue}
                      onChange={onChange}
                      onClearSearch={onClearSearch}
                      withMenu
                      options={searchResults}
                    />
                  )}
                  {!isTabletMode && isAddressBookPage && (
                    <Search
                      loadingDelay={100}
                      className={classes.search}
                      value={searchValue}
                      onChange={onChange}
                      onClearSearch={onClearSearch}
                      withMenu
                      options={searchResults}
                    />
                  )}
                </Box>
              </>
            ) : (
              <div className={classes.mobileBox}>
                <ViewButtons
                  isListView={isListView}
                  onChangeView={onChangeView}
                  isMobileMode={isMobileMode}
                />
                {!(isMobileMode && onlyContent) && (
                  <FiltersBox
                    isAddressBookPage={isAddressBookPage}
                    filters={filters}
                    isMobileMode={isMobileMode}
                    isDesktopMode={isDesktopMode}
                    onAddAddress={onAddAddress}
                    onChangeSorting={onChangeSorting}
                    importAddresses={importAddresses}
                  />
                )}
                {!isMobileMode && isTabletMode && onlyContent && (
                  <Search
                    loadingDelay={200}
                    value={searchValue}
                    onChange={onChange}
                    onClearSearch={onClearSearch}
                    withMenu
                    options={searchResults}
                  />
                )}
              </div>
            )}
          </div>
          <SideMenu
            title="Filters"
            styles={{
              width: '100%',
              maxWidth: 'initial',
              '& > div:nth-child(2)': {
                padding: '20px 12px',
              },
            }}
            active={active}
            toggleSideMenu={toggleSideMenu}>
            <MobileFilters
              filters={filters}
              onApplyMobileFilters={onApplyMobileFilters}
              onCloseSideMenu={onCloseSideMenu}
            />
          </SideMenu>
        </>
      )}
    </>
  );
};

export default FiltersBar;
