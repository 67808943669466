// Lib
import { createReducer } from '@reduxjs/toolkit';

// Actions
import { ClearPrevPath, SetPrevPath } from '../actions/location';

export const initialState = {
  prevPath: '',
};

export default createReducer(initialState, {
  [SetPrevPath]: (state, { payload }) => ({
    prevPath: payload,
  }),
  [ClearPrevPath]: () => initialState,
});
