// Libs
import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useSelector } from 'react-redux';

// Components
import IconButton from '../../../components/Button/IconButton/IconButton';
import ReorderButton from '../../../components/Button/ReorderButton/ReorderButton';
import Feather from '../../../components/Feather';

// Hooks
import useKitActions from './useKitActions';
import useUser from '../../../hooks/useUser';

// Utils
import { getTotalQty } from '../utils/calculations';

// Selectors
import { productsObjSelector } from '../../../store/selectors';

const useStyles = makeStyles((theme) => ({
  kitName: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
  },
}));

const useKitsCellsReducers = ({ kit }) => {
  const { onReorderClick, onDeleteClick, onViewDetailsClick, canReorder } =
    useKitActions(kit);
  const { isKiteUser } = useUser();
  const classes = useStyles();
  const productsObj = useSelector((state) => productsObjSelector(state));

  const cells = useMemo(
    () => ({
      kitNickname: (
        <Typography
          data-testid={'kit-name'}
          variant={isKiteUser ? 'textSm' : 'textSmSemibold'}
          className={classes.kitName}
          title={kit.name}>
          {kit.name}
        </Typography>
      ),
      totalQnty: getTotalQty(kit, productsObj),
      viewDetailsButton: (
        <IconButton
          data-testid={'kit-details-button'}
          onClick={() => onViewDetailsClick(kit.id)}
          endIcon={<ChevronRightIcon style={{ marginLeft: '-5px' }} />}
          styles={{ padding: 0 }}>
          View Details
        </IconButton>
      ),
      reorderButton: (
        <ReorderButton
          disabled={!canReorder}
          kit={kit}
          onClick={onReorderClick}
          inTable={true}
        />
      ),
      deleteButton: (
        <Feather
          data-testid="kit-remove-button"
          type="close"
          colorType="grey2"
          style={{ width: '22px', height: '22px', marginTop: '5px' }}
          onClick={() => onDeleteClick(kit.id)}
        />
      ),
    }),
    [
      kit,
      onDeleteClick,
      onReorderClick,
      onViewDetailsClick,
      productsObj,
      canReorder,
      isKiteUser,
      classes,
    ],
  );

  return {
    cells,
  };
};

export default useKitsCellsReducers;
