// Lib
import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

// Components
import Image from '../../../Image';
import MyRating from '../../../Rating/MyRating';

// Hooks
import useUI from '../../../../hooks/useUI';

// Styles
import { colors } from '../../../../styles/palette';

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: '1px solid #c6cac640',
    display: 'flex',
    gap: theme.spacing(3),
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: {
      gap: theme.spacing(1),
    },
  },
  description: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      marginBottom: theme.spacing(0.5),
    },
  },
  rating: {
    color: '#929A92',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1.5),
      '& #rateable': {
        marginLeft: 0,
      },
    },
  },
}));

const ProductRatingCard = ({
  product,
  updateProductRating = () => {},
  notRatedProducts = [],
}) => {
  const classes = useStyles();
  const { isMobileMode } = useUI();
  const { image_link: image, product_description: description } = product || {};

  return (
    <div className={classes.root}>
      <Image
        alt="productImage"
        src={image}
        imageContainer={{ desktop: [110], tablet: [110], mobile: [48] }}
      />
      <div>
        <Typography variant="h3" className={classes.description}>
          {description}
        </Typography>
        <MyRating
          updateProductRating={updateProductRating}
          withTitle={!isMobileMode}
          className={classes.rating}
          product={product}
        />
        {notRatedProducts[product.product_id] && (
          <div
            style={{
              color: colors.gilead.primary1,
              marginBottom: 8,
              marginTop: 8,
              fontSize: 12,
            }}>
            {notRatedProducts[product.product_id]}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductRatingCard;
