// Lib
import { createReducer } from '@reduxjs/toolkit';

// Actions
import { Open2Modal, Close2Modal, Clean2Modal } from '../actions/modal2';
import { UserLogOut } from '../actions/user';

export const initialState = {
  id: '',
  open: false,
  Component: null,
  styles: {},
  closeIcon: true,
  props: null,
};

export default createReducer(initialState, {
  [Open2Modal]: (state, { payload }) => {
    return { ...state, ...payload, open: true };
  },
  [Close2Modal]: (state) => ({ ...state, open: false }),
  [Clean2Modal]: () => initialState,
  [UserLogOut]: () => initialState,
});
