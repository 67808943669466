// Libs
import React from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Components
import Reorder from '../../../components/Button/ReorderButton/ReorderButton';
import Feather from '../../Feather';
import IconButton from '../../Button/IconButton/IconButton';

// Hooks
import useUser from '../../../hooks/useUser';
import useUI from '../../../hooks/useUI';
import useKitActions from '../../../pages/Kits/hooks/useKitActions';

// Utils
import { getTotalQty } from '../../../pages/Kits/utils/calculations';

// Selectors
import { productsObjSelector } from '../../../store/selectors';

const useStyles = makeStyles((theme) => ({
  card: ({ isMobileMode }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: isMobileMode ? 'column' : 'row',
    height: isMobileMode ? '130px' : '107px',
    padding: isMobileMode ? '10px' : '20px',
    backgroundColor: 'white',
    boxShadow: '0px 5px 15px rgba(44, 112, 172, 0.2)',
    borderRadius: '20px',
    marginBottom: theme.spacing(3),
    '& > *': {
      flexGrow: !isMobileMode && 1,
    },
    [theme.breakpoints.down('md')]: {
      borderRadius: 10,
      marginBottom: theme.spacing(1.5),
    },
  }),
  kitName: ({ isMobileMode }) => ({
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    color: !isMobileMode && theme.palette.darkGrey,
  }),
  kitDetailsLink: ({ isKiteUser }) => ({
    color: isKiteUser ? theme.palette.primary1 : theme.palette.pressed,
    textDecoration: 'none',
    maxHeight: '40px',
    paddingRight: theme.spacing(3),
  }),
  mobileTotalQty: {
    flexGrow: 1,
    paddingTop: theme.spacing(0.75),
    color: theme.palette.darkGrey,
  },
  tabletTotalQtyValue: {
    color: theme.palette.darkGrey,
  },
}));

const KitAdaptiveCard = ({ kit }) => {
  const { isKiteUser } = useUser();
  const productsObj = useSelector((state) => productsObjSelector(state));
  const { onReorderClick, onDeleteClick, onViewDetailsClick, canReorder } =
    useKitActions(kit);

  const { isMobileMode } = useUI();

  const classes = useStyles({
    isKiteUser,
    isMobileMode,
  });

  const closeButton = (
    <Feather
      data-testid="kit-remove-button"
      type="close"
      colorType="grey2"
      style={{
        width: '22px',
        height: '22px',
        position: 'absolute',
        top: '10px',
        right: '10px',
      }}
      onClick={() => onDeleteClick(kit.id)}
    />
  );

  return (
    <div className={classes.card} data-testid={'kit-card'}>
      {isMobileMode ? (
        <>
          <Typography
            data-testid={'kit-name-link-button'}
            variant="textMdSemibold"
            className={classnames(classes.kitDetailsLink, classes.kitName)}
            title={kit.name}
            component={Link}
            to={`${kit.id}`}>
            {kit.name}
          </Typography>
          <Typography
            data-testid={'total-qty'}
            className={classes.mobileTotalQty}
            variant="textSmSemibold">
            Total Qty: {getTotalQty(kit, productsObj)}
          </Typography>
          <Reorder
            disabled={!canReorder}
            kit={kit}
            onClick={onReorderClick}
            styles={{ maxHeight: '34px', width: 276, alignSelf: 'center' }}
            inTable
          />
        </>
      ) : (
        <>
          <Box display="flex" flexDirection="column" flexBasis="300px">
            <Typography
              data-testid={'kit-name'}
              variant="h3"
              className={classes.kitName}
              title={kit.name}>
              {kit.name}
            </Typography>
            <IconButton
              data-testid={'kit-details-button'}
              onClick={() => onViewDetailsClick(kit?.id)}
              endIcon={<ChevronRightIcon style={{ marginLeft: '-5px' }} />}
              styles={{ padding: 0, alignSelf: 'start' }}>
              Details
            </IconButton>
          </Box>
          <Box display="flex" justifyContent="center">
            <div data-testid={'total-qty'}>
              <Typography variant="h4">Total Qty:</Typography>
              <Typography
                className={classes.tabletTotalQtyValue}
                variant="textSmSemibold">
                {getTotalQty(kit, productsObj)}
              </Typography>
            </div>
          </Box>

          <Box display="flex" justifyContent="center">
            <Reorder
              disabled={!canReorder}
              kit={kit}
              onClick={onReorderClick}
              styles={{
                width: '119px',
                height: '36px',
              }}
              inTable
            />
          </Box>
        </>
      )}
      {closeButton}
    </div>
  );
};

export default KitAdaptiveCard;
