// Libs
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

// Actions
import {
  DeleteKit,
  DeleteKitInDetailsMode,
  DeleteAllKits,
} from '../../../actions/kits';
import { OpenModal } from '../../../actions/modal';
import { Open2Modal } from '../../../actions/modal2';

// Components
import ConfirmCard from '../../../components/Cards/ConfirmCard';

// Hooks
import useUI from '../../../hooks/useUI';

const useDeleteKits = () => {
  const dispatch = useDispatch();
  const { isTabletMode, isMobileMode } = useUI();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const confirmDeleteKit = (kitId) => dispatch(DeleteKit(kitId));
  const confirmDeleteKitInDetailsMode = (kitId) =>
    dispatch(DeleteKitInDetailsMode(kitId));

  const confirmDeleteAllKits = () => dispatch(DeleteAllKits());

  const showDeleteConfirmation = ({ message, onConfirm }) => {
    dispatch(
      OpenModal({
        Component: ConfirmCard,
        props: {
          message,
          onConfirm,
          isAcceptBtnMain: false,
          paddingContent: {
            xl: '40px 40px 30px',
            lg: '40px 40px 30px',
            md: '30px 16px 20px',
          },
        },
        styles: {
          width: isMobileMode ? 268 : isTabletMode ? 541 : 611,
          minHeight: isMobileMode ? 192 : 216,
        },
      }),
    );
  };

  const showDeleteConfirmation2 = ({ message, onConfirm }) => {
    dispatch(
      Open2Modal({
        Component: ConfirmCard,
        props: {
          modal2Mode: true,
          message,
          onConfirm,
          isAcceptBtnMain: false,
          paddingContent: {
            xl: '40px 40px 30px',
            lg: '40px 40px 30px',
            md: '30px 16px 20px',
          },
        },
        styles: {
          width: isMobileMode ? 268 : isTabletMode ? 541 : 611,
          height: isMobileMode ? 192 : 216,
          minHeight: 192,
        },
      }),
    );
  };

  const onDeleteMyKit = (kitId) =>
    showDeleteConfirmation({
      message: 'Deleting Will Remove This Kit From Your Kits.',
      onConfirm: () => {
        confirmDeleteKit(kitId);
        if (pathname !== '/kits') {
          navigate('/kits');
        }
      },
    });

  const onDeleteMyKitInDetailsMode = (kitId) =>
    showDeleteConfirmation2({
      message: 'Deleting Will Remove This Kit From Your Kits.',
      onConfirm: () => {
        confirmDeleteKitInDetailsMode(kitId);
        if (pathname !== '/kits') {
          navigate('/kits');
        }
      },
    });

  const onDeleteAllKits = () =>
    showDeleteConfirmation({
      message: 'Deleting Will Remove All Your Kits.',
      onConfirm: confirmDeleteAllKits,
    });

  return { onDeleteMyKit, onDeleteMyKitInDetailsMode, onDeleteAllKits };
};

export default useDeleteKits;
