// Lib
import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useForm, Controller } from 'react-hook-form';

// Components
import Input from '../../../components/Field/Input';
import Layout from '../../../components/Layout';
import Button from '../../../components/Button';
import Feather from '../../../components/Feather';

// Hooks
import useAuth from '../hooks/useAuth';

// Validation
import { usernameValidation, passwordValidation } from '../validationRules';

const useStyles = makeStyles((theme) => ({
  layout: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    minHeight: 'calc(100vh - 80px)',
    padding: `0 ${theme.spacing(1.5)}`,
    [theme.breakpoints.down('lg')]: {
      height: 'calc(100vh - 70px)',
    },
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 54px)',
    },
  },
  root: {
    maxWidth: 400,
    width: '100%',
  },
  input: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2),
    },
  },
  button: {
    margin: `${theme.spacing(5)} auto 0`,
    width: 130,
    minHeight: 44,
    [theme.breakpoints.down('lg')]: {
      width: 180,
    },
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(4),
    },
  },
  header: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
  error: {
    marginTop: theme.spacing(3),
    color: theme.palette.primary1,
    fontSize: 14,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
      fontSize: 12,
    },
  },
}));

const Login = () => {
  const classes = useStyles();
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      username: '',
      password: '',
    },
  });
  const { changeType, type, onSubmit, onChange, apiError, isLoading } =
    useAuth();

  return (
    <Layout className={{ layout: classes.layout }}>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
        <Typography className={classes.header} align="center" variant="h1">
          Log In
        </Typography>
        <Controller
          name="username"
          rules={usernameValidation}
          control={control}
          render={({ field }) => (
            <Input
              id="username"
              label="Username"
              className={classes.input}
              {...field}
              onChange={onChange(field.onChange)}
            />
          )}
        />
        <Controller
          name="password"
          rules={passwordValidation}
          control={control}
          render={({ field }) => (
            <Input
              id="password"
              label="Password"
              type={type}
              sx={{
                'input::-ms-reveal': {
                  display: 'none',
                },
              }}
              InputProps={{
                classes: { root: classes.root },
                endAdornment: (
                  <Feather
                    nativeColor
                    onClick={changeType}
                    type={type === 'password' ? 'closedEye' : 'openedEye'}
                  />
                ),
              }}
              {...field}
              onChange={onChange(field.onChange)}
            />
          )}
        />
        {apiError && (
          <div className={classes.error} data-testid="login-error-message">
            {apiError}
          </div>
        )}
        <Button
          type="submit"
          loadingIndicator="Loading…"
          loading={isLoading}
          id="log-in-button"
          disabled={!isValid}
          onClick={handleSubmit(onSubmit)}
          className={classes.button}>
          Log In
        </Button>
      </form>
    </Layout>
  );
};

export default Login;
