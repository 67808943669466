import React from 'react';
import useUI from '../../hooks/useUI';
import ValidateDesktop from './ValidateDesktop';
import ValidateMobile from './ValidateMobile';
import { Route, Routes } from 'react-router-dom';
import ResultPage from './ResultPage';

const Validate = () => {
  const { isMobileMode } = useUI();
  const Component = isMobileMode ? ValidateMobile : ValidateDesktop;
  return (
    <Routes>
      <Route exact path="" element={<Component />} />
      <Route path={'/results'} element={<ResultPage />} />
    </Routes>
  );
};
export default Validate;
