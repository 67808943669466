// Lib
import React, { useCallback } from 'react';
import { Button } from '@mui/material';
import Link from '@mui/material/Link';
import WestIcon from '@mui/icons-material/West';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  button: {
    fontWeight: ({ isKiteUser }) => (isKiteUser ? '400' : '600'),
    fontSize: '16px',
    lineHeight: '20px',
    color: `${theme.palette.primary1} !important`,
  },
}));

const KitsButton = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  const onClick = useCallback(() => {
    return navigate('/kits');
  }, [navigate]);

  return (
    <Button
      component={Link}
      width={'fit-content'}
      className={classes.button}
      startIcon={<WestIcon />}
      onClick={onClick}>
      My Kits
    </Button>
  );
};

export default KitsButton;
