// Lib
import React, { useCallback, useEffect } from 'react';
import { Typography, Divider, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Components
import Layout from '../../../components/Layout';
import EmptyCart from '../../../components/Cart/EmptyCart';
import CartBar from '../../../components/Cart/ShippingDetails/CartBar';
import CartCard from '../../../components/Cart/ShippingDetails/CartCard';
import Spinner from '../../../components/Spinner';
import FooterButtons from '../../../components/Cart/ShippingDetails/FooterButtons';
import RatingCard from '../../../components/Cart/RatingCard';
import GridCard from '../../../components/Products/Card/GridCard';
import IconButton from '../../../components/Button/IconButton/IconButton';
import Feather from '../../../components/Feather';
import Error from '../../../components/Error';

// Hooks
import useCart from '../hooks/useCart';
import useRequest from '../hooks/useRequest';
import useSaveForLater from '../hooks/useSaveForLater';
import useRatingBeforeNextStep from '../hooks/useRatingBeforeNextStep';
import useUI from '../../../hooks/useUI';
import useOverLimitProduct from '../hooks/useOverLimitProduct';

// Actions
import { Open2Modal } from '../../../actions/modal2';
import {
  ClearShippingAddresses,
  ClearShippingDetails,
  GetSaveForLaterProducts,
} from '../../../actions/cart';

// Utils
import { isObjEmpty } from '../../../utils/object';

// Styles
import { colors } from '../../../styles/palette';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 232px)',
    '& #next-button': {
      [theme.breakpoints.down('lg')]: {
        width: 110,
      },
      [theme.breakpoints.down('md')]: {
        width: 200,
      },
    },
  },
  order: {
    alignItems: 'center',
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
  },
  saveForLaterContainer: {
    marginTop: '40px',
    [theme.breakpoints.down('lg')]: {
      marginTop: '46px',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '30px',
    },
  },
  saveForLaterProducts: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  laterMessage: {
    alignItems: 'center',
    alignSelf: 'center',
    display: 'flex',
    flex: 1,
    minHeight: 200,
  },
  layout: {
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down('xl')]: {
      paddingTop: theme.spacing(0),
    },
  },
  divider: {
    borderColor: colors.lightGrey,
    borderBottomWidth: 'medium',
    paddingTop: theme.spacing(6),
    width: '100vw',
  },
}));

const CartAndForLater = ({ unavailableProducts = [] }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const apiError = useSelector((state) => state.apiError?.['getCartProducts']);
  const classes = useStyles();
  const {
    isEmptyCart,
    cart,
    totalCountInCart,
    loading,
    productsObj,
    someProductIsMoreThanInStock,
  } = useCart();
  const { productsForRate, onSaveRating, updateProductRating } =
    useRatingBeforeNextStep();
  const {
    isRequestRequired,
    openRequestCard,
    savedRequest,
    onClearOverLimitInDB,
    onSaveOverLimitInDB,
  } = useRequest(null, true);
  const { isSomeProductOverLimit } = useOverLimitProduct();
  const { isTabletMode, isMobileMode } = useUI();
  const { deleteAllFromLater } = useSaveForLater();

  useEffect(() => {
    dispatch(ClearShippingDetails());
    dispatch(ClearShippingAddresses());
    dispatch(GetSaveForLaterProducts());
  }, [dispatch]);

  const renderCartBar = useCallback(
    () => !loading && <CartBar countInCart={totalCountInCart} />,
    [loading, totalCountInCart],
  );

  const goToMaterials = useCallback(() => navigate('/materials'), [navigate]);

  const onNext = useCallback(() => {
    if (isRequestRequired && !cart?.overLimitRequest) {
      openRequestCard();
    } else if (productsForRate.length) {
      dispatch(
        Open2Modal({
          Component: () => (
            <RatingCard
              onSave={onSaveRating}
              updateProductRating={updateProductRating}
            />
          ),
          styles: {
            width: 'min(85vw, 700px)',
          },
          props: {
            paddingContent: {
              xl: '40px 40px 20px',
              lg: '40px 40px 20px',
              md: '40px 12px 20px',
            },
          },
        }),
      );
    } else {
      navigate('/cart/shipping-address');
    }
  }, [
    isRequestRequired,
    dispatch,
    cart,
    navigate,
    productsForRate,
    updateProductRating,
    onSaveRating,
    openRequestCard,
  ]);

  useEffect(() => {
    if (
      !someProductIsMoreThanInStock &&
      isSomeProductOverLimit &&
      !isObjEmpty(productsObj) &&
      !savedRequest &&
      !cart?.shippingAddresses?.length
    ) {
      openRequestCard();
    }
  }, [
    someProductIsMoreThanInStock,
    openRequestCard,
    isSomeProductOverLimit,
    savedRequest,
    productsObj,
    cart?.shippingAddresses?.length,
  ]);

  const renderContent = () => {
    if (loading) {
      return <Spinner />;
    }
    if (apiError) {
      return <Error message={apiError} />;
    }
    return (
      <div className={classes.root}>
        <div className={classes.order}>
          {isEmptyCart ? (
            <EmptyCart />
          ) : (
            <>
              {cart.forOrder?.map(({ product }) => (
                <CartCard
                  key={product}
                  product={productsObj[product]}
                  onClearOverLimitInDB={onClearOverLimitInDB}
                  onSaveOverLimitInDB={onSaveOverLimitInDB}
                />
              ))}
            </>
          )}
          {!!totalCountInCart && (
            <FooterButtons
              nextTitle="Checkout"
              backTitle="Continue Shopping"
              nextButtonDisabled={
                !!unavailableProducts.length || someProductIsMoreThanInStock
              }
              onClickBack={goToMaterials}
              onClickNext={onNext}
            />
          )}
          <Divider className={classes.divider} />
        </div>
        {cart.forLater?.length > 0 ? (
          <div className={classes.saveForLaterContainer}>
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              mb={isMobileMode ? '16px' : '20px'}>
              <Typography variant={isMobileMode ? 'h2' : 'h3'}>
                Saved For Later
              </Typography>
              <IconButton
                startIcon={<Feather type="delete" />}
                onClick={deleteAllFromLater}>
                Remove All
              </IconButton>
            </Box>
            <div className={classes.saveForLaterProducts}>
              {cart.forLater.map((item) => (
                <GridCard
                  key={item.product}
                  product={productsObj[item.product]}
                  isTabletMode={isTabletMode}
                  isMobileMode={isMobileMode}
                  saveForLater={true}
                  nums={item.nums}
                  imageContainer={{
                    desktop: ['100%', 250],
                    tablet: ['100%', 200],
                    mobile: ['100%', 138],
                  }}
                />
              ))}
            </div>
          </div>
        ) : (
          <div className={classes.laterMessage}>
            <Typography color="#929A92" variant="h2" textAlign="center">
              There are no saved for later items.
            </Typography>
          </div>
        )}
      </div>
    );
  };

  return (
    <Layout
      className={{ layout: classes.layout }}
      renderComponent={renderCartBar}>
      {renderContent()}
    </Layout>
  );
};

export default CartAndForLater;
