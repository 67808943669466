// Lib
import React, { useMemo } from 'react';
import { Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

// Components
import OrderInfo from './OrderInfo';
import Button from '../../Button';
import ProductsList from '../ProductsList';
import Feather from '../../Feather';
import ApproveResponse from './ApproveResponse';
import Spinner from '../../Spinner';
import TitleContainer from '../TitleContainer';
import ApprovalInfo from '../ApprovalInfo';

// Hooks
import useUser from '../../../hooks/useUser';
import useApprovals from '../../../pages/Approvals/hooks/useApprovals';
import useQtyApprovalInfo from '../../../pages/Approvals/hooks/useQtyApprovalInfo';

// Styles
import { colors } from '../../../styles/palette';
import ErrorMessage from '../../Error/ErrorMessage';

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        marginBottom: theme.spacing(1),
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        marginBottom: theme.spacing(2),
      }),
    },
  },
  h1: {
    fontSize: 28,
    [theme.breakpoints.down('md')]: {
      fontSize: 22,
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 22,
      }),
    },
  },
  h2: {
    [theme.breakpoints.down('lg')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        fontSize: 18,
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 16,
      }),
    },
  },
  approveAllButton: ({ hasAllApproves }) => {
    const color = hasAllApproves
      ? colors.gilead.primary2
      : colors.gilead.primary1;

    return {
      color: `${color} !important`,
      fontSize: 14,
      '& svg path': {
        stroke: color,
      },
      padding: 0,
      [theme.breakpoints.down('md')]: {
        fontSize: 10,
        padding: 4,
        '& svg': {
          height: 10,
          width: 10,
        },
      },
      [theme.breakpoints.downLandscape('md')]: {
        ...(theme.isMobileDevice && {
          fontSize: 10,
          padding: 4,
          '& svg': {
            height: 10,
            width: 10,
          },
        }),
      },
    };
  },
  submitButton: {
    marginLeft: 'auto',
    marginTop: theme.spacing(3.5),
    minWidth: 146,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: 44,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
}));

const OrderQty = () => {
  const { isKiteUser, email } = useUser();
  const {
    approvals,
    fullName,
    onApproveAll,
    isResponseRequired,
    isSubmitDisabled,
    onChangeResponse,
    onSubmit,
    isSaving,
    allProductsAlreadyApproved,
    comment,
    hasAllApproves,
    apiError,
  } = useApprovals();

  const classes = useStyles({
    isKiteUser,
    hasAllApproves,
  });

  const { order, approvals: approvalsData } = approvals;

  const { status, approvals: qtyApprovals } = useQtyApprovalInfo(
    approvalsData?.products_limits || {},
    email,
  );

  const addressesCount = useMemo(
    () => order?.addresses.length,
    [order?.addresses],
  );

  return (
    <>
      <TitleContainer
        isApproved={allProductsAlreadyApproved}
        fullName={fullName}
      />

      <OrderInfo
        isApproved={allProductsAlreadyApproved}
        addressesCount={addressesCount}
        order={order}
      />

      <Box className={classes.titleContainer}>
        <Typography className={classes.h2} variant="h2">
          Products
        </Typography>
        {!allProductsAlreadyApproved && (
          <Button
            className={classes.approveAllButton}
            variant="text"
            onClick={onApproveAll}>
            Approve All <Feather printType="stroke" type="arrowRight" />
          </Button>
        )}
      </Box>
      <ProductsList addressesCount={addressesCount} approvals={approvals} />

      <ApproveResponse
        isApproved={allProductsAlreadyApproved}
        isRequired={isResponseRequired}
        onChangeResponse={onChangeResponse}
        response={comment || approvals.approveResponse}
      />

      {apiError && <ErrorMessage message={apiError} />}
      {!allProductsAlreadyApproved && (
        <Button
          loading={isSaving}
          loadingIndicator={<Spinner size={20} height={24} />}
          onClick={onSubmit}
          disabled={isSubmitDisabled}
          className={classes.submitButton}>
          Submit
        </Button>
      )}
      {allProductsAlreadyApproved && (
        <ApprovalInfo approvals={qtyApprovals} title={`${status} by`} />
      )}
    </>
  );
};

export default OrderQty;
