// Libs
import React from 'react';
import { LoadingButton } from '@mui/lab';
import { makeStyles } from '@mui/styles';

// Components
import Spinner from '../../Spinner';

const useStyles = makeStyles((theme) => ({
  button: {
    color: ({ isKiteUser }) =>
      isKiteUser ? theme.palette.primary1 : theme.palette.pressed,
    fontWeight: ({ isKiteUser }) => (isKiteUser ? '400' : '600'),
    textDecoration: 'none',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    height: 36,
    width: 89,
    '&, &:hover': {
      backgroundColor: '#F4F4F4 !important',
      borderRadius: '20px',
    },
    [theme.breakpoints.down('md')]: {
      order: 0,
      width: 130,
      height: 34,
    },
  },
}));

const SkipButton = ({
  onClick = () => {},
  loading = false,
  disabled = false,
  ...props
}) => {
  const classes = useStyles();

  return (
    <LoadingButton
      disabled={disabled}
      loading={loading}
      loadingIndicator={<Spinner height="initial" size={14} />}
      onClick={onClick}
      className={classes.button}
      {...props}>
      Skip
    </LoadingButton>
  );
};

export default SkipButton;
