export const emptyAddressBook =
  'There are currently no addresses in your address book';

export const selectAddressImportFile = 'Select A CSV/XLSX File To Upload';

export const wrongFormat = 'Wrong format. Please select .csv or .xlsx file';

export const validationFailed = 'Validation failed.';

export const emptyImport = 'There are no addresses to import.';

export const invalidAddressFormat =
  'Invalid address format. Please download and use the address template provided on this page.';
