// Lib
import React from 'react';
import { TextareaAutosize, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

// Hooks
import useUI from '../../../../hooks/useUI';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Controller, useForm } from 'react-hook-form';

// Validation Schema
import { approveResponseSchema } from '../../../../validationSchema';

// Colors
import { colors } from '../../../../styles/palette';

const useStyles = makeStyles((theme) => ({
  responseInfo: {
    color: theme.palette.darkGrey,
    fontSize: 14,
    marginTop: theme.spacing(0.5),
    wordBreak: 'break-word',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 12,
      }),
    },
  },
  textArea: ({ isKiteUser }) => ({
    border: '1px solid #C6CAC6',
    borderRadius: 10,
    fontFamily: isKiteUser
      ? 'Helvetica, sans-serif'
      : 'Proxima Nova, sans-serif',
    fontSize: 16,
    padding: theme.spacing(1),
    width: '100% !important',
    outline: 0,
    resize: 'none',
    '&::placeholder': {
      color: '#C6CAC6',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 14,
      }),
    },
  }),
  error: {
    color: colors.gilead.primary1,
    fontSize: 14,
  },
}));

const ApproveResponse = ({
  isRequired = false,
  onChangeResponse = () => {},
  isApproved,
  response,
}) => {
  const classes = useStyles();
  const { isMobileMode } = useUI();

  const {
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(approveResponseSchema(isRequired)),
    defaultValues: {
      approveResponse: '',
    },
  });

  if (isApproved && !response) {
    return;
  }

  return (
    <>
      <Typography
        component="div"
        mt={{ md: isApproved ? 1 : 2, xs: isApproved ? 2.5 : 3.5 }}
        variant="textMdBold">
        Your response to the requester{' '}
        {isRequired && !isApproved ? (
          <span style={{ color: colors.gilead.primary1 }}>*</span>
        ) : (
          ''
        )}
      </Typography>
      <p className={classes.responseInfo}>
        {response && isApproved
          ? response
          : 'This message will be sent to the order originator.'}
      </p>
      {!isApproved && (
        <>
          <Controller
            name="approveResponse"
            control={control}
            render={({ field }) => (
              <TextareaAutosize
                {...field}
                placeholder="Type a message"
                className={classes.textArea}
                minRows={isMobileMode ? 7 : 3.5}
                aria-label="empty textarea"
                onChange={onChangeResponse(field.onChange)}
              />
            )}
          />
          {isRequired && errors?.approveResponse?.message && !isApproved && (
            <span className={classes.error}>
              {errors?.approveResponse?.message}
            </span>
          )}
        </>
      )}
    </>
  );
};

export default ApproveResponse;
