// Lib
import React from 'react';
import { makeStyles } from '@mui/styles';
import { isMobile } from 'react-device-detect';
import { Typography } from '@mui/material';

// Components
import ProgressSpinner from '../../../../Spinner/ProgressSpinner';
import Button from '../../../../Button';

// Hooks
import useUser from '../../../../../hooks/useUser';

const useStyles = makeStyles((theme) => ({
  root: ({ isMobile }) => ({
    border: !isMobile ? '1px dashed #C6CAC6' : 'none',
    borderRadius: 6,
    padding: 20,
    marginBottom: 10,
    marginTop: theme.spacing(3),
    textAlign: 'center',
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      padding: 0,
      marginTop: 0,
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(3),
    },
  }),
  title: {
    [theme.breakpoints.down('md')]: {
      fontSize: '16px !important',
    },
  },
  button: ({ isKiteUser }) => ({
    backgroundColor: theme.palette.lightGrey,
    color: isKiteUser ? theme.palette.primary1 : theme.palette.primary2,
    display: 'initial',
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(3.5),
    maxHeight: 44,
    padding: '8px 32px',
    '&:hover': {
      backgroundColor: `${theme.palette.lightGrey} !important`,
    },
  }),
  spinner: {
    marginTop: theme.spacing(1),
  },
}));

const Progress = ({ onCancelUploading = () => {}, value }) => {
  const { isKiteUser } = useUser();
  const classes = useStyles({ isMobile, isKiteUser });

  return (
    <div className={classes.root}>
      <ProgressSpinner className={classes.spinner} value={value} />
      <Typography variant="h2" className={classes.title}>
        Uploading file...
      </Typography>
      <Button onClick={onCancelUploading} className={classes.button}>
        Cancel
      </Button>
    </div>
  );
};

export default Progress;
