// Libs
import React, { useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

// Components
import Image from '../../../Image';

// Hooks
import useUser from '../../../../hooks/useUser';

// Actions
import { ClearReorder } from '../../../../actions/orders';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 430,
    width: 571,
    [theme.breakpoints.down('md')]: {
      minHeight: 300,
      width: 350,
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        minHeight: 300,
        width: 350,
      }),
    },
    [theme.breakpoints.down('sm')]: {
      width: '75vw',
    },
  },
  description: {
    wordBreak: 'break-word',
  },
  dialogButton: {
    paddingInline: theme.spacing(4),
  },
  cancelButton: {
    '&, &:hover': {
      border: 'none',
      backgroundColor: 'transparent',
    },
  },
  yesButton: {
    width: 110,
    [theme.breakpoints.down('md')]: {
      height: 44,
      width: 144,
    },
  },
}));

const OrderConfirmation = ({
  unavailableProducts,
  header,
  description,
  cancelAction,
  confirmAction,
}) => {
  const { isKiteUser } = useUser();
  const classes = useStyles({ isKiteUser });
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(ClearReorder());
    };
  }, [dispatch]);

  return (
    <Box className={classes.root}>
      <Typography variant="h2" mb={2}>
        {header}
      </Typography>
      <Typography mb={4}>{description}</Typography>
      <Box style={{ height: '50%', overflowY: 'auto' }} mb={3}>
        {unavailableProducts.map((product) => (
          <Box key={product.product_id} display={'flex'} gap={2} mb={2}>
            <Image
              src={product.image_link}
              imageContainer={{ desktop: [198], tablet: [198], mobile: [80] }}
            />
            <Typography className={classes.description} variant="h3">
              {product.product_description}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box display={'flex'} gap={2} justifyContent={'right'}>
        <Button
          variant="outlined"
          className={classnames(classes.dialogButton, classes.cancelButton)}
          onClick={cancelAction}>
          No
        </Button>
        <Button
          variant="contained"
          className={classnames(classes.dialogButton, classes.yesButton)}
          onClick={confirmAction}>
          Yes
        </Button>
      </Box>
    </Box>
  );
};

export default OrderConfirmation;
