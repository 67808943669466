// Lib
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Components
import Spinner from '../../../components/Spinner';
import Error from '../../../components/Error';

// Hooks
import useQueryParams from '../../../hooks/useQueryParams';

// Actions
import { SetAuthData } from '../../../actions/auth';
import { UserSsoLogIn } from '../../../actions/user';

// URLS
import { BASE_URL } from '../../../constants/urls';

// Hooks
import useAuth from '../hooks/useAuth';

const SsoResponse = () => {
  const authData = useQueryParams();
  const dispatch = useDispatch();
  const { accessToken, apiError } = useAuth();

  useEffect(() => {
    if (authData?.auth_token && !accessToken) {
      dispatch(
        SetAuthData({
          ...authData,
          access_token: authData.auth_token,
          sso: true,
        }),
      );
      dispatch(UserSsoLogIn());
    }
    if (!authData?.auth_token) {
      window.location.href = `${BASE_URL}/auth/sso/signin`;
    }
  }, [dispatch, authData, accessToken]);

  if (apiError) {
    return <Error message={apiError} />;
  }

  return (
    <div>
      <Spinner />
    </div>
  );
};

export default SsoResponse;
