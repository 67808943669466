// Libs
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

// Components
import Layout from '../../components/Layout';
import Kits from './Kits/Kits';
import KitDetails from './KitDetails/KitDetails';
import KitMaterials from '../../components/Kits/KitMaterials/KitMaterials';

const useStyles = makeStyles(() => ({
  layout: {
    paddingTop: 0,
  },
}));

const MyKits = () => {
  const classes = useStyles();
  return (
    <Routes>
      <Route
        path={'/'}
        exact
        element={
          <Layout className={{ layout: classes.layout }}>
            <Kits />
          </Layout>
        }
      />
      <Route
        path={'/:kitId'}
        exact
        element={
          <Layout className={{ layout: classes.layout }}>
            <KitDetails />
          </Layout>
        }
      />
      <Route path={'/:kitId/materials'} exact element={<KitMaterials />} />
    </Routes>
  );
};

export default MyKits;
