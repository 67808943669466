// Lib
import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Components
import ListButton from '../../../Button/ListButton';
import GridButton from '../../../Button/GridButton';
import ListCard from '../../../Products/Card/ListCard';
import OrderProductDetails from '../../Modals/OrderProductDetails/OrderProductDetails';
import OrderProductCardContent from '../../Cards/OrderProductCardContent';
import GridCard from '../../../Products/Card/GridCard';

// Hooks
import useUI from '../../../../hooks/useUI';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import PlusButton from '../../Buttons/PlusButton';
import CloseComponent from '../../Cards/OrderProductCardContent/CloseComponent';

const useStyles = makeStyles((theme) => ({
  productsLayout: ({ listView, isMobileMode }) =>
    listView
      ? {
          display: 'flex',
          flexDirection: 'column',
          gridTemplateColumns: 'initial',
          gap: theme.spacing(2),
        }
      : {
          display: 'grid',
          gridTemplateColumns: isMobileMode ? '1fr 1fr' : '1fr 1fr 1fr',
          gap: theme.spacing(2),
        },
  gridCard: ({ isRecurring }) => ({
    [theme.breakpoints.down('lg')]: {
      height: '410px !important',
    },
    [theme.breakpoints.down('md')]: {
      height: isRecurring ? '270px !important' : '250px !important',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        height: '410px !important',
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        height: isRecurring ? '270px !important' : '250px !important',
      }),
    },
  }),
}));

const OrderedProducts = ({
  orderProducts,
  setListView = () => {},
  listView = true,
  orderDetails,
  isRecurring = false,
  addressesCount = 1,
}) => {
  const navigate = useNavigate();
  const { isTabletMode, isMobileMode } = useUI();
  const classes = useStyles({ listView, isMobileMode, isRecurring });
  const theme = useTheme();

  const getOrderItem = (productId) => {
    return orderDetails.order_items.find((item) => item.product === productId);
  };

  return (
    <Box mb={4}>
      <Box
        mt={{ lg: 4, md: isRecurring ? 2 : 4, xs: 4 }}
        display={'flex'}
        justifyContent={'space-between'}>
        <Typography fontSize={{ lg: 28, md: 18, xs: 16 }} variant="h2" mb={2}>
          Products{' '}
          {isRecurring && (
            <PlusButton
              onClick={() =>
                navigate(
                  `/order-history/recurring/${orderDetails.id}/materials`,
                )
              }
              style={{ marginLeft: 16 }}
            />
          )}
        </Typography>
        <Box display={'flex'} gap={2}>
          <ListButton
            isActive={listView}
            onClick={() => setListView(true)}></ListButton>
          <GridButton
            isActive={!listView}
            onClick={() => setListView(false)}></GridButton>
        </Box>
      </Box>
      {orderProducts?.ids?.length > 0 && (
        <Box
          data-testid={`ordered-products-${listView ? 'list' : 'grid'}`}
          className={classes.productsLayout}>
          {orderProducts?.ids?.map((productId) => {
            const product = orderProducts.products?.[productId];

            return listView ? (
              <ListCard
                key={productId}
                isTabletMode={isTabletMode}
                product={product}
                cardModalComponent={OrderProductDetails}
                modalProps={{
                  addressesCount: addressesCount,
                  order: orderDetails,
                  orderItem: getOrderItem(productId),
                  isRecurring,
                }}
                cardContent={
                  <OrderProductCardContent
                    addressesCount={addressesCount}
                    isRecurring={isRecurring}
                    product={product}
                    order={orderDetails}
                    listView
                    orderItem={getOrderItem(productId)}
                  />
                }
                imageContainer={{
                  desktop: [230],
                  tablet: [245, 263],
                  mobile: [136, 136],
                }}
                customCardSize={{
                  height: 230,
                  [theme.breakpoints.down('lg')]: {
                    height: 263,
                  },
                }}
              />
            ) : (
              <GridCard
                CloseComponent={() =>
                  isRecurring && (
                    <CloseComponent
                      orderItem={getOrderItem(productId)}
                      product={product}
                      order={orderDetails}
                    />
                  )
                }
                className={classes.gridCard}
                key={productId}
                isTabletMode={isTabletMode}
                product={product}
                cardModalComponent={OrderProductDetails}
                modalProps={{
                  addressesCount: addressesCount,
                  order: orderDetails,
                  orderItem: getOrderItem(productId),
                  isRecurring,
                }}
                cardContent={
                  <OrderProductCardContent
                    addressesCount={addressesCount}
                    isRecurring={isRecurring}
                    product={product}
                    order={orderDetails}
                    orderItem={getOrderItem(productId)}
                  />
                }
                imageContainer={{
                  desktop: ['100%', 250],
                  tablet: ['100%', 200],
                  mobile: ['100%', 138],
                }}
              />
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default OrderedProducts;
