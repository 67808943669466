import { store } from '../../../store';

export const modifyOrders = (orders = []) => {
  const productsObj = { ...store.getState().products?.all?.obj };

  const modifiedOrders = orders?.map((order) => {
    const order_items = order.order_items.map((orderItem) => {
      const { product } = orderItem;
      productsObj[product.product_id] = product || {};

      return {
        ...orderItem,
        product: product.product_id,
      };
    });

    return {
      ...order,
      order_items,
    };
  });

  return { productsObj, modifiedOrders };
};
