// Lib
import React, { useMemo, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux';

// Components
import ShippingCard from '../ShippingCard';
import EditRequestButton from '../../../Button/EditRequestButton';
import Select from '../../../Field/Select';
import CartCard from '../../ShippingDetails/CartCard';
import ShippingAddresses from './ShippingAddresses';
import IconButton from '../../../Button/IconButton/IconButton';
import Spinner from '../../../Spinner';
import Feather from '../../../Feather';

// hooks
import useUI from '../../../../hooks/useUI';
import useRequest from '../../../../pages/Cart/hooks/useRequest';
import useUser from '../../../../hooks/useUser';
import useShippingAddresses from '../../../../pages/Cart/hooks/useShippingAddresses';
import useAddToKits from '../../../../pages/Kits/hooks/useAddToKits';
import useOverLimitProduct from '../../../../pages/Cart/hooks/useOverLimitProduct';

// Utils
import { isObjEmpty } from '../../../../utils/object';

// Constants
import { ORDER_INSTRUCTIONS } from '../../../../constants/cart';

// Selectors
import { productsObjSelector } from '../../../../store/selectors';

// Styles
import { colors } from '../../../../styles/palette';

const useStyles = makeStyles((theme) => ({
  shippingInfo: ({ isDistroOrder, placedOrder }) => {
    let flexWrap = 'noWrap';
    if (isDistroOrder || placedOrder) {
      flexWrap = 'wrap';
    }

    return {
      display: 'flex',
      flexWrap,
      rowGap: isDistroOrder ? theme.spacing(1.25) : theme.spacing(3.75),
      marginBottom: theme.spacing(3.5),
      marginTop: placedOrder ? '22px' : theme.spacing(3.5),
      ...(!isDistroOrder && {
        '& > div': {
          marginRight: theme.spacing(1.5),
        },
      }),
      '& > div:nth-child(1)': {
        [theme.breakpoints.down('md')]: {
          marginRight: ({ isDistroOrder, placedOrder }) =>
            !isDistroOrder &&
            (placedOrder ? theme.spacing(3.75) : theme.spacing(0.5)),
        },
        '& > div:nth-child(2)': {
          [theme.breakpoints.down('md')]: {
            marginRight: ({ isDistroOrder, placedOrder }) =>
              !isDistroOrder && placedOrder && 0,
          },
        },
      },
      [theme.breakpoints.down('lg')]: {
        marginTop: ({ placedOrder }) => (placedOrder ? theme.spacing(2.5) : 0),
        marginBottom: ({ placedOrder }) => placedOrder && 0,
      },
    };
  },
  shippingCard: {
    height: ({ isDistroOrder, placedOrder }) =>
      isDistroOrder && !placedOrder && 136,
    [theme.breakpoints.down('lg')]: {
      height: ({ isDistroOrder, placedOrder }) =>
        isDistroOrder && !placedOrder && 156,
    },
    [theme.breakpoints.down('md')]: {
      width: ({ isDistroOrder }) => isDistroOrder && 296,
      height: ({ isDistroOrder, placedOrder }) =>
        isDistroOrder ? (placedOrder ? 'auto' : 118) : !placedOrder && 190,
      paddingTop: ({ placedOrder }) => !placedOrder && theme.spacing(2),
      marginRight: ({ isDistroOrder, placedOrder }) =>
        !isDistroOrder && !placedOrder && 0,
    },
  },
  shippingTitle: {
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: {
      marginBottom: ({ placedOrder }) =>
        placedOrder ? theme.spacing(0.75) : theme.spacing(1.5),
    },
  },
  instructionsTitle: {
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(0.5),
    },
  },
  editRequestButton: {
    marginTop: theme.spacing(1),
    padding: 3,
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(0.75),
    },
  },
  errorContainer: {
    display: 'flex',
    marginTop: theme.spacing(0.5),
  },
  error: {
    fontSize: 14,
    color: colors.gilead.primary1,
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
  instructions: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  instructionsPlacedOrder: {
    [theme.breakpoints.down('md')]: {
      marginTop: ({ isDistroOrder }) =>
        isDistroOrder ? theme.spacing(2.5) : 0,
    },
  },
  select: ({ isKiteUser }) => ({
    width: 564,
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
    '& .MuiFormControl-root#select': {
      '& > div': {
        borderRadius: '10px !important',
        color: theme.palette.black,
        fontWeight: 400,

        '& .MuiSelect-select > span': {
          fontSize: 16,
          fontWeight: 400,
          fontFamily: isKiteUser
            ? 'Helvetica, sans-serif'
            : 'Proxima Nova, sans-serif',
          [theme.breakpoints.down('md')]: {
            fontSize: 14,
          },
        },
      },
      '& fieldset': {
        border: `1px solid ${theme.palette.grey1}`,
        backgroundColor: 'initial',
      },
    },
  }),
  fullAddress: ({ placedOrder }) => ({
    color: placedOrder ? theme.palette.darkGrey : 'initial',
    display: '-webkit-box',
    '-webkit-line-clamp': 4,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 14,
    lineHeight: '16px',
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('lg')]: {
      '-webkit-line-clamp': 5,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  }),
  titleContainer: {
    marginTop: theme.spacing(3.75),
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    },
  },
  orderId: {
    color: theme.palette.grey2,
    fontSize: 18,
    fontWeight: 700,
    '& ~ button': {
      [theme.breakpoints.down('lg')]: {
        justifyContent: 'flex-start',
        marginTop: theme.spacing(0.5),
      },
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(0.25),
      },
    },
  },
  placedTitle: {
    fontSize: 28,
    fontWeight: 700,
    marginBottom: '20px',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(3.75),
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 22,
      marginTop: 0,
    },
  },
  shippingComment: {
    maxWidth: 235,
    [theme.breakpoints.down('md')]: {
      maxWidth: 'none',
    },
  },
  kitsButton: {
    width: 'fit-content',
    fontSize: 14,
    padding: 4,
  },
  productsTitle: {
    marginBottom: theme.spacing(2.5),
    [theme.breakpoints.down('lg')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1.5),
      fontSize: 14,
      alignSelf: 'center',
    },
  },
  approvalInfo: {
    color: theme.palette.grey2,
    fontSize: 14,
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
  cartCard: {
    '& .MuiCardContent-root': {
      [theme.breakpoints.down('md')]: {
        gap: theme.spacing(0.25),
      },
      '& > div:nth-child(2)': {
        [theme.breakpoints.down('md')]: {
          marginTop: theme.spacing(0.25),
        },
      },
    },
  },
}));

const OrderInfo = ({
  shippingDetails,
  goToSippingDetails = () => {},
  cart,
  onChangeInstructions = () => {},
  placedOrder = {},
  withOverLimitRequest,
  disabledCountBtn,
  isAvailableMethod = true,
  moreThanInStock,
}) => {
  const { productsObj, isLoading } = useSelector((state) => ({
    productsObj: productsObjSelector(state),
    isLoading: state.isLoading,
  }));
  const { isDistroOrder, shippingAddresses, goToAddresses, onDeleteAddress } =
    useShippingAddresses();
  const { isKiteUser, userWithoutAllLimits } = useUser();
  const classes = useStyles({
    isDistroOrder,
    placedOrder: placedOrder.id,
    isKiteUser,
  });
  const { isMobileMode, isDesktopMode } = useUI();
  const { isSomeProductOverLimit } = useOverLimitProduct();
  const {
    openRequestCard: openOverLimitCard,
    savedRequest,
    onClearOverLimitInDB,
    onSaveOverLimitInDB,
  } = useRequest(null, true);
  const { openRequestCard: openShippingRequestCard } = useRequest();
  const { onAddToMyKits } = useAddToKits(placedOrder.id);

  const isRecurringOrder = useMemo(() => !placedOrder.order_sn, [placedOrder]);

  useEffect(() => {
    if (
      isSomeProductOverLimit &&
      !moreThanInStock &&
      !isObjEmpty(productsObj) &&
      !savedRequest
    ) {
      openOverLimitCard();
    }
  }, [
    isSomeProductOverLimit,
    savedRequest,
    moreThanInStock,
    productsObj,
    openOverLimitCard,
  ]);

  if (isLoading) {
    return <Spinner height="calc(100vh - 350px)" />;
  }

  return (
    <>
      {isDesktopMode && !placedOrder.id && (
        <Typography variant="h2" data-testid="review-order-page-header">
          Order Info
        </Typography>
      )}
      {placedOrder.id && (
        <>
          <div
            className={classes.placedTitle}
            data-testid="placed-order-page-header">
            Placed Order Info
          </div>
          {!isRecurringOrder && (
            <>
              <div
                className={classes.orderId}
                data-testid="placed-order-id-label">{`Order ID: #${
                placedOrder.order_sn || placedOrder.id
              }`}</div>
              {!isDistroOrder && (
                <IconButton
                  disabled={!placedOrder.can_be_added_to_kit}
                  onClick={onAddToMyKits}
                  startIcon={<AddIcon />}
                  data-testid="add-to-kits-button">
                  Add To My Kits
                </IconButton>
              )}
            </>
          )}
        </>
      )}
      <div className={classes.shippingInfo}>
        <ShippingAddresses
          className={classes.shippingTitle}
          isOrderPlaced={!!placedOrder?.id}
          isDistroOrder={isDistroOrder}
          isReviewOrderPage={true}
          shippingAddresses={shippingAddresses}
          goToAddresses={goToAddresses}
          onDeleteAddress={onDeleteAddress}
        />
        <div>
          <Typography
            className={classes.shippingTitle}
            variant="h3"
            data-testid="shipping-method-header">
            Shipping Method
          </Typography>
          <ShippingCard
            className={classes.shippingCard}
            isOrderPlaced={!!placedOrder.id}
            onChange={goToSippingDetails}
            title={shippingDetails.method?.name}
            dataTestId="shipping-method-title">
            {shippingDetails.method?.escalation &&
              !placedOrder?.id &&
              isAvailableMethod &&
              !userWithoutAllLimits && (
                <EditRequestButton
                  className={classes.editRequestButton}
                  onClick={openShippingRequestCard}
                  title={
                    isMobileMode && !isDistroOrder
                      ? 'Edit Request'
                      : 'Edit Shipping Request'
                  }
                />
              )}
            {!placedOrder?.id && !isAvailableMethod && (
              <div className={classes.errorContainer}>
                <div>
                  <Feather
                    style={{ marginRight: 5, marginTop: 3 }}
                    nativeColor
                    type="error"></Feather>
                </div>
                <div className={classes.error}>
                  This method is unavailable. Please change shipping method.
                </div>
              </div>
            )}
          </ShippingCard>
          {placedOrder?.additional_info_shipment && (
            <div
              className={classes.approvalInfo}
              data-testid="order-request-status-label">
              Pending Approval
            </div>
          )}
        </div>
        {placedOrder.id && placedOrder.shipping_comments && (
          <div className={classes.instructionsPlacedOrder}>
            <Typography
              className={classes.instructionsTitle}
              variant="h3"
              data-testid="special-instructions-header">
              Special Instructions
            </Typography>
            <div
              className={classes.shippingComment}
              data-testid="special-instructions-text">
              {placedOrder.shipping_comments}
            </div>
          </div>
        )}
      </div>
      {!placedOrder.id && (
        <>
          <div
            className={classes.instructions}
            data-testid="special-instructions-header">
            Special Instructions
          </div>
          <Select
            initialValue={
              shippingDetails.comment || ORDER_INSTRUCTIONS[0].title
            }
            placeholder="Select"
            className={classes.select}
            items={ORDER_INSTRUCTIONS}
            onChange={onChangeInstructions}
            data-testid="special-instructions-dropdown"
          />
        </>
      )}
      <Box
        className={classes.titleContainer}
        display="flex"
        justifyContent="space-between">
        <Typography
          className={classes.productsTitle}
          variant={placedOrder.id ? 'h3' : 'h2'}>
          Products
        </Typography>
        {withOverLimitRequest && !placedOrder.id && savedRequest && (
          <EditRequestButton
            onClick={openOverLimitCard}
            title="Edit Qty Request"
          />
        )}
      </Box>
      {!!placedOrder.id ? (
        <div className={classes.cartCard}>
          {placedOrder.order_items?.map(({ product }) => (
            <CartCard
              addressesCount={shippingAddresses.length}
              reviewOrderPage
              placedOrder={placedOrder}
              key={product.product_id}
              product={productsObj[product?.product_id || product]}
              disabledCountBtn={disabledCountBtn}
            />
          ))}
        </div>
      ) : (
        <div className={classes.cartCard}>
          {cart.forOrder?.map(({ product }) => (
            <CartCard
              addressesCount={shippingAddresses.length}
              reviewOrderPage
              placedOrder={placedOrder}
              key={product}
              product={cart.productsObj[product]}
              disabledCountBtn={disabledCountBtn}
              onClearOverLimitInDB={onClearOverLimitInDB}
              onSaveOverLimitInDB={onSaveOverLimitInDB}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default OrderInfo;
