// Libs
import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

// Components
import Layout from '../../../components/Layout';
import Spinner from '../../../components/Spinner';
import Feather from '../../../components/Feather';

// Hooks
import useUnauthorizedApprove from '../hooks/useUnauthorizedApprove';

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    height: 'calc(100vh - 250px)',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0 auto',
    width: 510,
    [theme.breakpoints.down('lg')]: {
      width: 530,
    },
    [theme.breakpoints.down('md')]: {
      width: 230,
    },
  },
  icon: ({ isSuccessResponse }) => ({
    alignItems: 'center',
    background: '#F4F4F4',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    height: 120,
    width: 120,
    '& svg': {
      cursor: 'initial',
      ...(!isSuccessResponse && {
        height: 70,
        width: 70,
      }),
    },
  }),
}));

const ApproveAll = () => {
  const { loading, message, responseType, status } = useUnauthorizedApprove();
  const classes = useStyles({ isSuccessResponse: responseType === 'success' });

  return (
    <Layout hideHeaderLinks={true}>
      <div className={classes.container}>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div className={classes.icon}>
              <Feather nativeColor printType="stroke" type={responseType} />
            </div>
            {message && (
              <Typography textAlign="center" mt={3} variant="h2">
                {status === 'already_approved'
                  ? 'No changes can be made'
                  : message}
              </Typography>
            )}
            {status === 'already_approved' && (
              <span style={{ textAlign: 'center' }}>{message}</span>
            )}
          </>
        )}
      </div>
    </Layout>
  );
};

export default ApproveAll;
