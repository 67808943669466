// Lib
import React from 'react';
import { makeStyles } from '@mui/styles';

// Components
import Header from '../../../components/Cart/Header';
import Layout from '../../../components/Layout';
import DetailsForm from '../../../components/Cart/ShippingDetails/DetailsForm';
import FooterButtons from '../../../components/Cart/ShippingDetails/FooterButtons';
import IconButton from '../../../components/Button/IconButton/IconButton';
import Feather from '../../../components/Feather';

// Hooks
import useShippingDetails from '../hooks/useShippingDetails';
import useOrderProcessingInfo from '../hooks/useOrderProcessingInfo';

const useStyles = makeStyles((theme) => ({
  layout: {
    padding: `${theme.spacing(3)} 26px ${theme.spacing(14)}`,
    [theme.breakpoints.down('lg')]: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: 'calc(100vh - 70px - 90px)', // minus header and title
      padding: `0 26px ${theme.spacing(5)}`,
    },
    [theme.breakpoints.down('md')]: {
      minHeight: 'calc(100vh - 54px - 56px)', // minus header and title
      padding: `0 ${theme.spacing(1.5)} ${theme.spacing(1.5)}`,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        padding: `0 26px ${theme.spacing(5)}`,
      }),
    },
  },
  orderInfoButton: {
    display: 'none',
    width: 'fit-content',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      display: 'inline-flex',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        display: 'inline-flex',
      }),
    },
  },
}));

const ShippingDetails = () => {
  const classes = useStyles();

  const { onClickBack, onClickNext, isNextButtonDisable, setMonthError } =
    useShippingDetails();
  const { openOrderProcessingWindow } = useOrderProcessingInfo();

  return (
    <Layout className={{ layout: classes.layout }} renderComponent={Header}>
      <IconButton
        data-testid="order-processing-button"
        onClick={openOrderProcessingWindow()}
        classname={classes.orderInfoButton}
        endIcon={<Feather nativeColor type="arrowRight" />}>
        See Order Processing Information
      </IconButton>

      <DetailsForm setMonthError={setMonthError} />
      <FooterButtons
        nextButtonDisabled={isNextButtonDisable}
        onClickNext={onClickNext}
        onClickBack={onClickBack}
        backTitle="Shipping Address"
      />
    </Layout>
  );
};

export default ShippingDetails;
