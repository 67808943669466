import React from 'react';
import { Modal, IconButton } from '@mui/material';
import { QrReader } from 'react-qr-reader';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';

const QrReaderWindow = ({
  isQrOpen,
  handleError,
  handleScan,
  toggleQrOpen,
}) => {
  const classes = useStyles();

  return (
    <Modal open={isQrOpen} onClose={toggleQrOpen}>
      <div className={classes.root}>
        <IconButton className={classes.closeButton} onClick={toggleQrOpen}>
          <CloseIcon />
        </IconButton>
        <QrReader
          className={classes.qrReaderContainer}
          onResult={(result, error) => {
            if (!!result) {
              handleScan(result);
            }
            if (!!error) {
              handleError(error);
            }
          }}
          constraints={{
            facingMode: 'environment',
            aspectRatio: { ideal: 1 },
          }}
          containerStyle={{ width: '100%', height: '100vh' }}
          ViewFinder={ViewFinder}
        />
      </div>
    </Modal>
  );
};

export default QrReaderWindow;

const ViewFinder = () => {
  const classes = useStyles();
  return (
    <>
      <svg
        width="50px"
        viewBox="0 0 100 100"
        style={{
          top: 0,
          left: 0,
          zIndex: 1,
          position: 'absolute',
          borderTop: '60px solid rgba(0, 0, 0, 0.7)',
          borderBottom: '60px solid rgba(0, 0, 0, 0.7)',
          borderLeft: '40px solid rgba(0, 0, 0, 0.7)',
          borderRight: '40px solid rgba(0, 0, 0, 0.7)',
          width: '100%',
          height: '100%',
          MozBoxSizing: 'border-box',
          WebkitBoxSizing: 'border-box',
          boxSizing: 'border-box',
        }}
      />
      <p className={classes.resultText}>
        Align the QR code within the frame to scan
      </p>
    </>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  qrReaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    boxSizing: 'border-box',
  },
  resultText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    bottom: 0,
    fontSize: 16,
    fontWeight: 600,
    color: '#fff',
    zIndex: 999,
  },
  closeButton: {
    position: 'absolute',
    top: 16,
    right: 24,
    zIndex: 999,
    color: '#fff',
  },
}));
