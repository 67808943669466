// Libs
import { useDispatch } from 'react-redux';

// Actions
import { EditKitName, CleanKitActionErrorMessage } from '../../../actions/kits';
import { OpenModal, CloseModal } from '../../../actions/modal';

// Components
import KitNameModal from '../../../components/Kits/Modal/KitNameModal/KitNameModal';

// Hooks
import useUI from '../../../hooks/useUI';

const useEditKit = () => {
  const dispatch = useDispatch();
  const { isTabletMode, isMobileMode } = useUI();

  const onClickSave = (kitId, name) => {
    dispatch(EditKitName({ kitId, name }));
  };

  const onClickCancel = () => {
    dispatch(CloseModal());
  };

  const cleanUp = () => {
    dispatch(CleanKitActionErrorMessage());
  };

  const onEditKitName = (kitId, oldName) => {
    dispatch(
      OpenModal({
        Component: KitNameModal,
        props: {
          entityId: kitId,
          header: 'Edit kit nickname',
          oldName,
          onClickSave,
          onClickCancel,
          cleanUp,
          paddingContent: {
            xl: '40px 40px 30px',
            lg: '40px 40px 30px',
            md: '24px 16px 24px',
          },
        },
        styles: {
          width: isMobileMode ? 268 : isTabletMode ? 660 : 700,
          height: isMobileMode ? 263 : 272,
        },
      }),
    );
  };

  return { onEditKitName };
};

export default useEditKit;
