// Lib
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// Actions
import {
  ClearShippingAddresses,
  SetShippingAddresses,
} from '../../../actions/cart';
import { UpdateRecurringOrder } from '../../../actions/orders';

const useRecurringAddressBook = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const [loading, setLoading] = useState(false);
  const { updatedAddresses, order } = useSelector((state) => ({
    updatedAddresses: state.cart.shippingAddresses,
    order: state.orders.currentOrder,
  }));

  useEffect(() => {
    if (!order) {
      return navigate(-1);
    }

    dispatch(
      SetShippingAddresses(
        order.addresses.map(({ address }) => ({
          ...address,
          id: address.original_id,
        })),
      ),
    );

    return () => dispatch(ClearShippingAddresses());
  }, [navigate, dispatch, order]);

  const onSave = useCallback(() => {
    const newOrder = { ...order };
    newOrder.shipment_method = newOrder.shipment_method.id;
    newOrder.addresses = updatedAddresses.map((address) => {
      if (address.original_id) {
        return { ...address, id: address.original_id };
      } else {
        return address;
      }
    });
    let schedule = { ...order.schedule };
    if (!schedule.days_week) {
      schedule.days_week = [];
    }
    newOrder.schedule = schedule;
    delete newOrder.user;

    dispatch(
      UpdateRecurringOrder({
        newOrder,
        setLoading,
        backPath: `/order-history/recurring/${orderId}`,
        state: {
          updateOrderDetails: true,
        },
      }),
    );
  }, [dispatch, updatedAddresses, order, orderId]);

  const onCancel = useCallback(() => {
    navigate(`/order-history/recurring/${orderId}`, {
      state: {
        updateOrderDetails: true,
      },
    });
  }, [navigate, orderId]);

  return { onCancel, onSave, loading };
};

export default useRecurringAddressBook;
