export function isProductExpiredValidate(product) {
  return product?.doc_status?.toLowerCase() === 'expired';
}
export function isProductWithdrawnValidate(product) {
  return product?.doc_status?.toLowerCase() === 'withdrawn';
}
export function isProductOutOfStockValidate(product) {
  return product?.doc_status?.toLowerCase() === 'out_of_stock';
}
export function isProductAvailableValidate(product) {
  return (
    !isProductWithdrawnValidate(product) && !isProductExpiredValidate(product)
  );
}
