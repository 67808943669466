// Lib
import React, { useCallback } from 'react';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';

// Components
import Button from '../../Button';
import Select from '../../Field/Select';
import SortButton from '../../Button/SortButton';
import Feather from '../../Feather';
import capitalize from '../../../utils/capitalize';

const useStyles = makeStyles((theme) => ({
  filtersBox: {
    display: 'flex',
    gap: theme.spacing(4),
    width: '100%',
    [theme.breakpoints.down('xl')]: {
      gap: theme.spacing(2),
    },
    [theme.breakpoints.down('lg')]: {
      gap: theme.spacing(0),
      justifyContent: 'space-between',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        gap: theme.spacing(0),
        justifyContent: 'space-between',
      }),
    },
  },
  button: {
    backgroundColor: '#F4F4F4',
    fontSize: 14,
    '&:hover': {
      backgroundColor: '#F4F4F4 !important',
    },
  },
  plusIcon: {
    fontSize: 24,
    marginRight: theme.spacing(0.5),
  },
  iconButton: {
    backgroundColor: '#F4F4F4',
    borderRadius: 4,
    minWidth: 24,
    padding: 2,
    '&:hover': {
      backgroundColor: '#F4F4F4 !important',
    },
  },
}));

const FiltersBox = ({
  filters,
  isMobileMode = false,
  onAddAddress,
  onChangeSorting,
  isAddressBookPage = true,
  importAddresses,
}) => {
  const classes = useStyles();

  const { pathname } = useLocation();
  const isCartPage = pathname === '/cart/shipping-address';

  const renderBox = useCallback(() => {
    if (isMobileMode && isCartPage) {
      return (
        <Button
          onClick={onAddAddress}
          variant="text"
          className={classes.iconButton}
          data-testid={'add-address-button'}>
          <Feather type="plus" />
        </Button>
      );
    }
    if (isMobileMode && !isAddressBookPage) {
      return <></>;
    }
    if (isMobileMode && isAddressBookPage) {
      return (
        <Box display="flex" gap={2}>
          <Button
            onClick={onAddAddress}
            variant="text"
            className={classes.iconButton}
            data-testid={'add-address-button'}>
            <Feather type="plus" />
          </Button>
          <Button
            variant="text"
            className={classes.iconButton}
            onClick={importAddresses}
            data-testid="import-address-button">
            <Feather strokeWidth="2px" type="arrowDown" printType="stroke" />
          </Button>
        </Box>
      );
    }

    return (
      <>
        <Box display="flex" gap={4} width={isCartPage ? '100%' : 'auto'}>
          {(isAddressBookPage || isCartPage) && (
            <Button
              styles={{
                order: isCartPage ? 1 : 0,
                marginLeft: isCartPage ? 'auto' : 0,
              }}
              onClick={onAddAddress}
              className={classes.button}
              variant="text"
              data-testid="add-address-button">
              <AddIcon className={classes.plusIcon} />
              <span>Add New Address</span>
            </Button>
          )}
          <Box display="flex" gap={1} alignItems="center">
            <Select
              width={164}
              onChange={onChangeSorting}
              initialValue={`Sort By ${capitalize(
                filters?.sorting?.orderBy,
                true,
              )}`}
              items={filters?.sorting?.items}
            />
            <SortButton
              onClick={onChangeSorting(null, filters?.sorting?.order)}
              order={filters?.sorting?.order}
            />
          </Box>
        </Box>
        {isAddressBookPage && (
          <Button
            variant="text"
            styles={{ fontSize: 14 }}
            onClick={importAddresses}
            data-testid="import-address-button">
            <Feather
              strokeWidth="2px"
              type="arrowDown"
              printType="stroke"
              style={{ marginRight: 8 }}
            />
            Import Addresses
          </Button>
        )}
      </>
    );
  }, [
    onAddAddress,
    isAddressBookPage,
    isMobileMode,
    filters,
    onChangeSorting,
    classes,
    importAddresses,
    isCartPage,
  ]);

  return <Box className={classes.filtersBox}>{renderBox()}</Box>;
};

export default FiltersBox;
