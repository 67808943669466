// Libs
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { makeStyles } from '@mui/styles';
import { yupResolver } from '@hookform/resolvers/yup';

// Components
import Input from '../../../../../Field/Input';
import MultipleSelect from '../../../../../Field/MultipleSelect';

// Validation Schema
import { scheduleDetailsSchema } from '../../../../../../validationSchema';
import Disclaimer from '../../../OrderProcessingInfo/Disclaimer';

const useStyles = makeStyles((theme) => ({
  input: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 45,
    '& #input-error': {
      fontSize: 12,
      marginTop: 0,
      position: 'absolute',
      width: 'max-content',
    },
  },
  select: {
    margin: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      margin: 6,
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        margin: 6,
      }),
    },

    '& .MuiFormControl-root#select': {
      '& > div': {
        borderRadius: '10px !important',
        color: theme.palette.black,
        fontWeight: 400,

        '& .MuiSelect-select > span': {
          fontSize: 16,
          fontWeight: 400,
          [theme.breakpoints.down('md')]: {
            fontSize: 14,
          },
          [theme.breakpoints.downLandscape('md')]: {
            ...(theme.isMobileDevice && {
              fontSize: 14,
            }),
          },
        },
      },
      '& fieldset': {
        border: `1px solid ${theme.palette.grey1}`,
        backgroundColor: 'initial',
      },
    },
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      fontSize: 14,
      letterSpacing: '0.1px',
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        flexWrap: 'wrap',
        fontSize: 14,
        letterSpacing: '0.1px',
      }),
    },
  },
  multipleSelect: {
    margin: theme.spacing(1),
    '&#select .MuiInputBase-formControl': {
      '& #multiple-select > span': {
        fontSize: 16,
        color: theme.palette.grey1,
        fontWeight: 400,
        [theme.breakpoints.down('md')]: {
          fontSize: 14,
        },
        [theme.breakpoints.downLandscape('md')]: {
          ...(theme.isMobileDevice && {
            fontSize: 14,
          }),
        },
      },
      borderRadius: '10px !important',
      color: theme.palette.black,
      fontWeight: 400,

      '& fieldset': {
        border: `1px solid ${theme.palette.grey1}`,
        backgroundColor: 'initial',
      },
    },
  },
  disclaimer: {
    left: 380,
    top: -12,
    [theme.breakpoints.down('lg')]: {
      top: '50%',
      transform: 'translateY(-50%)',
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
      marginLeft: -6,
      position: 'initial',
      transform: 'none',
      width: 'min-content',
    },
  },
}));

const Weekly = ({
  weekly,
  onChangeMonthCount = () => {},
  onChangeMultipleSelect = () => {},
  setMonthError = () => {},
  disclaimer,
  openOrderProcessingWindow,
}) => {
  const classes = useStyles();

  const {
    control,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(scheduleDetailsSchema),
    defaultValues: {
      monthNumberForWeekly: weekly?.monthNumber,
    },
  });

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    if (errors.monthNumberForWeekly) {
      setMonthError(errors.monthNumberForWeekly?.message);
    } else {
      setMonthError('');
    }
  }, [setMonthError, errors.monthNumberForWeekly]);

  return (
    // <div style={{ position: 'relative', display: 'flex', flexWrap: 'wrap' }}>
    <div className={classes.container}>
      Every
      <MultipleSelect
        labelColor="#C6CAC6"
        classic
        name="weekly"
        onChangeFilter={onChangeMultipleSelect}
        width={132}
        className={classes.multipleSelect}
        label="Select"
        required
        items={weekly?.days}
      />
      Of Every
      <Controller
        name="monthNumberForWeekly"
        control={control}
        render={({ field }) => {
          return (
            <Input
              {...field}
              maxLength={2}
              name="monthNumberForWeekly"
              onChange={onChangeMonthCount(field.onChange)}
              className={classes.input}
              value={weekly?.monthNumber}
              error={errors?.monthNumberForWeekly?.message}
            />
          );
        }}
      />
      <span style={{ marginRight: 16 }}>Week(s)</span>
      {disclaimer && (
        <Disclaimer
          onClick={openOrderProcessingWindow}
          className={classes.disclaimer}
          disclaimer={disclaimer}
        />
      )}
    </div>
  );
};

export default Weekly;
