// Lib
import React, { useState, forwardRef, useCallback, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import { Snackbar as MuiSnackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  message: {
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
}));

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Snackbar = ({ severity = 'error', message, onCloseCallback }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (message) {
      setOpen(true);
    }
  }, [message]);

  const onClose = useCallback(
    (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      onCloseCallback && onCloseCallback();
      setOpen(false);
    },
    [setOpen, onCloseCallback],
  );

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <MuiSnackbar open={open} autoHideDuration={3000} onClose={onClose}>
        <Alert
          className={classes.message}
          onClose={onClose}
          severity={severity}
          sx={{ width: '100%' }}>
          {message}
        </Alert>
      </MuiSnackbar>
    </Stack>
  );
};

export default Snackbar;
