// Lib
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import Request from '../../../components/Cart/Request';

// Actions
import {
  SaveOverLimitRequest,
  SaveShippingRequest,
} from '../../../actions/cart';
import { Open2Modal } from '../../../actions/modal2';

// Hooks
import useUser from '../../../hooks/useUser';

const useRequest = (id, isOverLimitRequest) => {
  const {
    minLengthOverLimitRequest,
    overLimitRequest,
    cart,
    shippingRequest,
    apiError,
    shippingDetails,
  } = useSelector((state) => ({
    minLengthOverLimitRequest: state?.cart?.minLengthOverLimitRequest,
    overLimitRequest: state?.cart?.overLimitRequest,
    shippingRequest: state?.cart?.shippingDetails?.shippingRequest,
    shippingDetails: state?.cart?.shippingDetails,
    cart: state?.cart,
    apiError: state.apiError?.['saveOverLimitRequest'],
  }));
  const [isOverLimitInDB, setIsOverLimitInDB] = useState(false);

  const [loading, setLoading] = useState(false);
  const [isRequestSaved, setIsRequestSaved] = useState(false);

  const dispatch = useDispatch();
  const { userWithoutAllLimits, userWithoutQuantityLimit } = useUser();

  const openRequestCard = useCallback(() => {
    if (userWithoutAllLimits) {
      return;
    }
    if (userWithoutQuantityLimit && isOverLimitRequest) {
      return;
    }
    dispatch(
      Open2Modal({
        id: 'over-limit',
        Component: () => (
          <Request id={id} isOverLimitRequest={isOverLimitRequest} />
        ),
        closeIcon: true,
        styles: {
          minHeight: 'initial',
          width: 'auto',
        },
        props: {
          paddingContent: {
            xl: '40px',
            lg: '40px',
            md: '40px 16px 30px',
          },
        },
      }),
    );
  }, [
    dispatch,
    id,
    isOverLimitRequest,
    userWithoutAllLimits,
    userWithoutQuantityLimit,
  ]);

  const onSubmit = useCallback(
    (data) => {
      if (isOverLimitRequest) {
        dispatch(
          SaveOverLimitRequest({ ...data, setLoading, setIsRequestSaved }),
        );
      } else {
        dispatch(
          SaveShippingRequest({ ...data, setLoading, setIsRequestSaved }),
        );
      }
    },
    [dispatch, isOverLimitRequest],
  );

  const onChange = useCallback(
    (onChangeField) => (e) => {
      onChangeField(e);
    },
    [],
  );

  const isRequestRequired = useMemo(() => {
    if (userWithoutAllLimits) {
      return false;
    }

    if (userWithoutQuantityLimit && isOverLimitRequest) {
      return false;
    }

    if (!isOverLimitRequest) {
      return (
        !!shippingDetails?.method?.escalation &&
        !shippingDetails?.shippingRequest
      );
    }

    return !!cart?.productsOverLimit?.length;
  }, [
    cart,
    userWithoutAllLimits,
    userWithoutQuantityLimit,
    isOverLimitRequest,
    shippingDetails,
  ]);

  const onClearOverLimitInDB = useCallback(
    (product) => {
      if (cart.productsOverLimit.length > 1) {
        return;
      }
      if (
        overLimitRequest &&
        cart.productsOverLimit.find((id) => id === product.product_id)
      ) {
        setIsOverLimitInDB(false);
        dispatch(SaveOverLimitRequest({ request: '', onlyDB: true }));
      }
    },
    [overLimitRequest, cart?.productsOverLimit, dispatch],
  );

  const onSaveOverLimitInDB = useCallback(() => {
    if (cart.productsOverLimit.length > 1) {
      return;
    }
    if (overLimitRequest && !isOverLimitInDB) {
      setIsOverLimitInDB(true);
      dispatch(
        SaveOverLimitRequest({ request: overLimitRequest, onlyDB: true }),
      );
    }
  }, [overLimitRequest, cart?.productsOverLimit, isOverLimitInDB, dispatch]);

  return {
    loading,
    onChange,
    onSubmit,
    minLengthOverLimitRequest,
    isRequestSaved,
    openRequestCard,
    savedRequest: isOverLimitRequest ? overLimitRequest : shippingRequest,
    isRequestRequired,
    cart,
    apiError,
    onClearOverLimitInDB,
    onSaveOverLimitInDB,
  };
};

export default useRequest;
