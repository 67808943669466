// Lib
import React from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

// Components
import GridButton from '../Button/GridButton';
import ListButton from '../Button/ListButton';
import Switch from '../Button/Switch';
import Search from '../Search';
import Select from '../Field/Select';
import MultipleSelect from '../Field/MultipleSelect';
import SortButton from '../Button/SortButton';
import RemoveFilters from './RemoveFilters';
import SideMenu from '../SideMenu';
import Feather from '../Feather';
import MobileFilterBar from './MobileFilterBar';

// Hooks
import useSideMenu from '../../hooks/useSideMenu';
import useCatalogView from '../../pages/Materials/hooks/useCatalogView';
import useSearch from '../../hooks/useSearch';
import useUI from '../../hooks/useUI';
import capitalize from '../../utils/capitalize';

const useStyles = makeStyles((theme) => ({
  bar: ({ onlyContent }) => ({
    backgroundColor: 'white',
    margin: '0 auto',
    maxWidth: 1180,
    position: 'sticky',
    padding: `40px 26px 12px`,
    top: 50,
    zIndex: 100,
    [theme.breakpoints.down('lg')]: {
      top: onlyContent ? 0 : 40,
    },
    [theme.breakpoints.down('md')]: {
      top: 50,
      padding: `${theme.spacing(1)} ${theme.spacing(1.5)} 12px`,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        top: onlyContent ? 0 : 40,
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        top: 50,
        padding: `${theme.spacing(1)} ${theme.spacing(1.5)} 12px`,
      }),
    },
  }),
  switcher: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      fontSize: 22,
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(1.5),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 22,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1.5),
      }),
    },
  },
  switch: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginLeft: -4,
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        marginLeft: -4,
      }),
    },
  },
  filtersBox: {
    display: 'flex',
    marginBottom: theme.spacing(2.5),
    width: '100%',
    '& > div': {
      margin: theme.spacing(1),
    },
    '& > div:nth-child(1)': {
      order: 0,
    },
    '& > div:nth-child(2)': {
      order: 1,
    },
    '& > div:nth-child(3)': {
      order: 2,
    },
    '& > div:nth-child(4)': {
      order: 3,
    },
    '& > div:nth-child(5)': {
      order: 4,
    },
    '& > div:nth-child(6)': {
      order: 5,
    },
    [theme.breakpoints.down('xl')]: {
      flexWrap: 'wrap',
      maxWidth: 830,
      '& > div': {
        margin: 5,
      },
      '& > div:nth-child(6)': {
        order: 4,
      },
      '& > div:nth-child(5)': {
        order: 5,
      },
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: 650,
      '& > div:nth-child(6)': {
        order: 3,
      },
      '& > div:nth-child(4)': {
        order: 4,
      },
    },
    [theme.breakpoints.downLandscape('xl')]: {
      ...(theme.isMobileDevice && {
        flexWrap: 'wrap',
        maxWidth: 830,
        '& > div': {
          margin: 5,
        },
        '& > div:nth-child(6)': {
          order: 4,
        },
        '& > div:nth-child(5)': {
          order: 5,
        },
      }),
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        maxWidth: 650,
        '& > div:nth-child(6)': {
          order: 3,
        },
        '& > div:nth-child(4)': {
          order: 4,
        },
      }),
    },
  },
}));

const FiltersBar = ({
  onChangeFilter,
  onMultipleSelectChange,
  applyMobileFilters,
  onRemoveFilter,
  filters,
  onClearAllFilters,
  onChangeSorting,
  onlyContent,
}) => {
  const classes = useStyles({ onlyContent });
  const { active, toggleSideMenu, onCloseSideMenu } = useSideMenu();
  const { catalogView, changeView } = useCatalogView();
  const { onChange, searchValue, onClearSearch } = useSearch();
  const { isUpMdMode, isMobileMode, isTabletMode } = useUI();

  const materialsSwitchButtons = (
    <>
      <Switch
        name="favorites"
        label="Show Favorites"
        onChange={onChangeFilter}
        checked={filters.favorites}
      />
      <Switch
        name="frequentlyOrdered"
        className={classes.switch}
        label="Frequently Ordered"
        onChange={onChangeFilter}
        checked={filters.frequentlyOrdered}
      />
    </>
  );

  return (
    <>
      {filters.loaded && (
        <>
          <div className={classes.bar}>
            {isTabletMode && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="baseline">
                <Typography className={classes.title} variant="h2">
                  Materials
                </Typography>

                {isMobileMode ? (
                  <Feather
                    colorType="primary1"
                    type="filters"
                    onClick={toggleSideMenu(true)}
                  />
                ) : (
                  <Search
                    value={searchValue}
                    onChange={onChange}
                    onClearSearch={onClearSearch}
                  />
                )}
              </Box>
            )}
            {isUpMdMode ? (
              <>
                <Box width="100%" display="flex" justifyContent="space-between">
                  <Box display="flex" alignItems="center" mb={2}>
                    <ListButton
                      onClick={changeView('list')}
                      isActive={catalogView === 'list'}
                      styles={{ marginRight: 16 }}
                    />
                    <GridButton
                      onClick={changeView('grid')}
                      isActive={catalogView === 'grid'}
                      styles={{ marginRight: 38 }}
                    />
                    {materialsSwitchButtons}
                  </Box>
                  {!isTabletMode && (
                    <Search
                      value={searchValue}
                      onChange={onChange}
                      onClearSearch={onClearSearch}
                    />
                  )}
                </Box>
                <Box className={classes.filtersBox}>
                  <MultipleSelect
                    name="brand_category"
                    label="All Brands"
                    width={116}
                    items={filters.brand_category}
                    onChangeFilter={onMultipleSelectChange}
                  />
                  <MultipleSelect
                    name="owner"
                    label="All Therapeutic Areas"
                    items={filters.owner}
                    onChangeFilter={onMultipleSelectChange}
                  />
                  <MultipleSelect
                    name="audience"
                    label="All Audience"
                    items={filters.audience}
                    onChangeFilter={onMultipleSelectChange}
                  />
                  <MultipleSelect
                    name="available"
                    label="Available Products"
                    items={filters.available}
                    onChangeFilter={onMultipleSelectChange}
                  />
                  {!!filters.indication.length && (
                    <MultipleSelect
                      name="indication"
                      label="All Indications"
                      items={filters.indication}
                      onChangeFilter={onMultipleSelectChange}
                    />
                  )}
                  <Box display="flex" alignItems="center" gap={1}>
                    <Select
                      width={130}
                      onChange={onChangeSorting}
                      initialValue={`Sort By ${capitalize(
                        filters?.sorting?.orderBy,
                      )}`}
                      items={filters?.sorting?.items}
                    />
                    <SortButton
                      onClick={onChangeSorting(null, filters?.sorting?.order)}
                      order={filters?.sorting?.order}
                    />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  flexWrap="wrap"
                  gap="10px">
                  <RemoveFilters
                    filters={filters}
                    onRemoveFilter={onRemoveFilter}
                    onClearAllFilters={onClearAllFilters}
                  />
                </Box>
              </>
            ) : (
              <Box display="flex" justifyContent="space-between">
                <Switch
                  name="favorites"
                  className={classes.switch}
                  label="Show Favorites"
                  onChange={onChangeFilter}
                  checked={filters.favorites}
                />
                <Box display="flex" alignItems="center">
                  <ListButton
                    onClick={changeView('list')}
                    isActive={catalogView === 'list'}
                    styles={{ marginRight: 16 }}
                  />
                  <GridButton
                    onClick={changeView('grid')}
                    isActive={catalogView === 'grid'}
                  />
                </Box>
              </Box>
            )}
          </div>
          <SideMenu
            title="Filters"
            styles={{
              width: '100%',
              maxWidth: 'initial',
              '& > div:nth-child(2)': {
                padding: '20px 12px',
              },
            }}
            active={active}
            toggleSideMenu={toggleSideMenu}>
            <MobileFilterBar
              onChangeFilter={onChangeFilter}
              onCloseSideMenu={onCloseSideMenu}
              applyMobileFilters={applyMobileFilters}
              filters={filters}
              onClearAllFilters={onClearAllFilters}
            />
          </SideMenu>
        </>
      )}
    </>
  );
};

export default FiltersBar;
