// Lib
import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import useUser from '../../hooks/useUser';

const useStyles = makeStyles((theme) => ({
  title: {
    '& span': {
      fontWeight: 600,
    },
  },
  list: {
    padding: 0,
    marginLeft: `-${theme.spacing(1.5)}`,
    marginRight: `-${theme.spacing(1.5)}`,
  },
  listItem: {
    padding: `${theme.spacing(0.6)} ${theme.spacing(1)}`,
    borderBottom: `1px solid ${theme.palette.lightGrey}`,
    borderTop: `1px solid ${theme.palette.lightGrey}`,
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  collapsedList: {
    alignItems: 'center',
    backgroundColor: 'rgba(48, 85, 107, 0.03)',
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1.5),
    padding: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
  },
  button: {
    background: 'white',
    border: `1px solid rgba(198, 202, 198, 0.3)`,
    borderRadius: 10,
    fontSize: 12,
    flexGrow: 0,
    '&:hover': {
      background: 'white',
    },
  },
  activeButton: ({ isKiteUser }) => ({
    background: 'rgba(48, 85, 107, 0.1)',
    border: '1px solid rgba(198, 202, 198, 0.2)',
    color: isKiteUser ? theme.palette.primary1 : theme.palette.primary2,
    '&:focus': {
      background: 'rgba(48, 85, 107, 0.1)',
    },
  }),
  icon: {
    color: theme.palette.grey2,
  },
  circle: {
    backgroundColor: theme.palette.primary1,
    borderRadius: '50%',
    display: 'inline-block',
    height: 6,
    marginLeft: 3,
    width: 6,
  },
}));

const NestedList = ({
  title,
  items,
  name,
  onChange = () => {},
  withChanges,
  orderBy,
  sortButton,
}) => {
  const { isKiteUser } = useUser();
  const classes = useStyles({ isKiteUser });
  const [open, setOpen] = useState(false);

  const onClick = () => {
    setOpen(!open);
  };

  return (
    <List
      className={classes.list}
      component="nav"
      aria-labelledby="nested-list-subheader">
      <ListItemButton className={classes.listItem} onClick={onClick}>
        <ListItemText className={classes.title}>
          {title} {withChanges && !open && <div className={classes.circle} />}
        </ListItemText>
        {open ? (
          <ExpandLess className={classes.icon} />
        ) : (
          <ExpandMore className={classes.icon} />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List className={classes.collapsedList} component="div" disablePadding>
          {items?.map(({ title, checked }) => (
            <ListItemButton
              onClick={
                sortButton ? onChange(title) : onChange(name, title, checked)
              }
              key={title}
              className={classnames(
                classes.button,
                (checked || title?.toLowerCase() === orderBy?.toLowerCase()) &&
                  classes.activeButton,
              )}>
              {title}
            </ListItemButton>
          ))}
          {sortButton && sortButton}
        </List>
      </Collapse>
    </List>
  );
};

export default NestedList;
