// Lib
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import classnames from 'classnames';
import { useDebounce } from 'use-debounce';
import { Autocomplete } from '@mui/material';

// Components
import Input from '../Field/Input';
import Feather from '../Feather';

// Actions
import { ToClearSearchLocalValue } from '../../actions/support';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 350,
    [theme.breakpoints.down('sm')]: {
      width: '77vw',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white',
      borderRadius: 20,
      boxShadow: '0px 5px 17px rgba(48, 85, 107, 0.2)',
      padding: '5px 12px !important',
    },
    '& fieldset': {
      border: 'none',
    },
  },
  searchIcon: {
    color: theme.palette.grey2,
    marginRight: theme.spacing(0.5),
  },
  progress: {
    alignItems: 'center',
    display: 'flex',
    height: 27,
    marginRight: theme.spacing(1),
    '& span': {
      height: '18px !important',
      width: '18px !important',
    },
  },
  paper: ({ showMenu }) => ({
    display: showMenu ? 'block' : 'none',
    borderRadius: 20,
    marginTop: theme.spacing(1),
  }),
  listbox: {
    '& .MuiAutocomplete-option': {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '20px',
      letterSpacing: '0.1px',
    },
  },
  matchText: {
    color: '##929A92',
    fontSize: 12,
    padding: theme.spacing(2),
  },
}));

const SearchInput = ({
  classes,
  className,
  value,
  ref,
  withIcon,
  loading,
  onClear,
  onChangeValue,
  autoFocus,
  ...props
}) => (
  <Input
    {...props}
    id="search-field"
    ref={ref}
    autoFocus={autoFocus}
    onChange={onChangeValue}
    className={classnames(classes.root, className)}
    placeholder="Search"
    value={value}
    InputProps={{
      ...props.InputProps,
      startAdornment:
        withIcon &&
        (loading ? (
          <div className={classes.progress}>
            <CircularProgress />
          </div>
        ) : (
          <SearchIcon className={classes.searchIcon} />
        )),
      endAdornment: value && (
        <Feather onClick={onClear} type="clear" nativeColor />
      ),
    }}
  />
);

// eslint-disable-next-line react/display-name
const Search = forwardRef(
  (
    {
      loadingDelay = 500,
      className,
      withIcon = true,
      onChange = () => {},
      autoFocus = false,
      onClearSearch,
      withMenu = false,
      options,
    },
    ref,
  ) => {
    const { search } = useSelector((store) => store);
    const [value, setValue] = useState(search);
    const [loading, setLoading] = useState(false);
    const [debouncedValue] = useDebounce(value, loadingDelay * 0.8);
    const { toClearFAQSearch } = useSelector((state) => state.support);
    const dispatch = useDispatch();

    const showMenu = useMemo(() => {
      return withMenu && options.length > 0;
    }, [withMenu, options]);

    const classes = useStyles({ showMenu });

    useEffect(() => {
      onChange(debouncedValue);
      setTimeout(() => setLoading(false), loadingDelay);
    }, [loadingDelay, debouncedValue, onChange]);

    const onChangeValue = useCallback(
      ({ value }) => {
        setLoading(true);
        setValue(value);
      },
      [setValue],
    );

    useEffect(() => {
      if (!search) {
        setValue('');
      }
    }, [search]);

    const onClear = useCallback(() => {
      onClearSearch(setValue);
    }, [onClearSearch, setValue]);

    useEffect(() => {
      if (toClearFAQSearch) {
        onClear();
        dispatch(ToClearSearchLocalValue(false));
      }
    }, [toClearFAQSearch, onClear, dispatch]);

    // for autocomplete
    const modifiedOptions = useMemo(
      () => (showMenu ? [{ length: options?.length }, ...options] : []),
      [options, showMenu],
    );

    return (
      <>
        {withMenu ? (
          <Autocomplete
            filterOptions={(options) => options}
            disablePortal
            classes={{
              paper: classes.paper,
              listbox: classes.listbox,
            }}
            inputValue={value}
            getOptionLabel={(option) => {
              return option.last_name || '';
            }}
            clearIcon={false}
            renderOption={(props, option) => {
              if (option.length) {
                return (
                  <li
                    key={`${option.length} matches`}
                    className={classes.matchText}>
                    {`${option.length} Top Matches`}
                  </li>
                );
              }
              if (option.length === 0) {
                return (
                  <li key="No matches" className={classes.matchText}>
                    No Items Found
                  </li>
                );
              }
              let value = `${option.first_name} ${option.last_name}`;
              if (option.company) {
                value = `${value}, ${option.company}`;
              }
              return (
                <li
                  {...props}
                  key={option.id}
                  style={{ borderBottom: '1px solid #F4F4F4' }}
                  onClick={(e) => {
                    props.onClick(e);
                    setValue(value);
                  }}>
                  {value}
                </li>
              );
            }}
            id="autocomplete-input"
            options={modifiedOptions}
            renderInput={(params) => {
              return (
                <SearchInput
                  {...params}
                  value={value}
                  autoFocus={autoFocus}
                  onChangeValue={onChangeValue}
                  className={className}
                  classes={classes}
                  loading={loading}
                  onClear={onClear}
                  ref={ref}
                  withIcon={withIcon}
                />
              );
            }}
          />
        ) : (
          <SearchInput
            value={value}
            autoFocus={autoFocus}
            onChangeValue={onChangeValue}
            className={className}
            classes={classes}
            loading={loading}
            onClear={onClear}
            ref={ref}
            withIcon={withIcon}
          />
        )}
      </>
    );
  },
);

export default Search;
