// Lib
import React from 'react';
import classnames from 'classnames';
import { makeStyles } from '@mui/styles';

// Components
import Button from '../index';
import Feather from '../../Feather';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 4,
    height: 24,
    minWidth: 24,
    width: 24,
    padding: 0,
  },
}));

const FlagButton = ({ active, onClick, className }) => {
  const classes = useStyles();

  return (
    <Button
      className={classnames(classes.button, className)}
      onClick={onClick}
      color="secondary">
      {active ? (
        <Feather type="filledFlag" />
      ) : (
        <Feather printType="stroke" type="flag" />
      )}
    </Button>
  );
};

export default FlagButton;
