// Lib
import React, { useMemo } from 'react';

// Components
import WatermarkImg from '../components/Products/Card/WatermarkImg';

// Utils
import isProductExpired from '../utils/isProductExpired';
import isProductWithdrawn from '../utils/isProductWithdrawn';
import isProductOutOfStock from '../utils/isProductOutOfStock';
import {
  isProductExpiredValidate,
  isProductOutOfStockValidate,
  isProductWithdrawnValidate,
} from '../pages/Validate/utils';

const useUnavailableForOrderProduct = (
  product,
  isGridView = false,
  isModal = false,
  isValidate = false,
) => {
  const isExpired = useMemo(() => {
    return isValidate
      ? isProductExpiredValidate(product)
      : isProductExpired(product);
  }, [product, isValidate]);

  const isWithdrawn = useMemo(() => {
    return isValidate
      ? isProductWithdrawnValidate(product)
      : isProductWithdrawn(product);
  }, [product, isValidate]);

  const isOutOfStock = useMemo(() => {
    return isValidate
      ? isProductOutOfStockValidate(product)
      : isProductOutOfStock(product);
  }, [product, isValidate]);

  const isUnavailable = useMemo(() => {
    return isValidate
      ? false
      : !product?.is_available_for_user || product?.offer_inactive;
  }, [product, isValidate]);

  const unavailableWatermark = useMemo(() => {
    if (isExpired) {
      return <WatermarkImg isGrid={isGridView} isModal={isModal} isExpired />;
    }
    if (isWithdrawn) {
      return <WatermarkImg isGrid={isGridView} isModal={isModal} isWithdrawn />;
    }
    if (isUnavailable) {
      return (
        <WatermarkImg isGrid={isGridView} isModal={isModal} isUnavailable />
      );
    }
    if (isOutOfStock) {
      return (
        <WatermarkImg isGrid={isGridView} isModal={isModal} isOutOfStock />
      );
    }
    return <></>;
  }, [
    isOutOfStock,
    isWithdrawn,
    isExpired,
    isGridView,
    isUnavailable,
    isModal,
  ]);

  const unavailableLabel = useMemo(() => {
    if (isExpired) {
      return 'Expired';
    }
    if (isWithdrawn) {
      return 'Withdrawn';
    }
    if (isUnavailable) {
      return 'Unavailable';
    }
    if (isOutOfStock) {
      return 'Out Of Stock';
    }
    return '';
  }, [isOutOfStock, isWithdrawn, isExpired, isUnavailable]);

  return {
    isUnavailableForOrder:
      isWithdrawn || isExpired || isOutOfStock || isUnavailable,
    unavailableWatermark,
    unavailableLabel,
  };
};

export default useUnavailableForOrderProduct;
