// Lib
import React from 'react';
import { Typography } from '@mui/material';
import { format } from 'date-fns';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  p: {
    marginTop: 0,
    '&::first-letter': {
      textTransform: 'uppercase',
    },
    '&:not(:last-child)': {
      marginBottom: 0,
    },
  },
}));

const ApprovalInfo = ({ approvals, title }) => {
  const classes = useStyles();

  if (!approvals || approvals.some((approve) => !approve.approvalDate)) {
    return;
  }

  return (
    <>
      <Typography
        component="div"
        mt={{ md: 2.5, xs: 2 }}
        mb={1}
        variant="textMdBold">
        {title}
      </Typography>
      {approvals.map((approve) => (
        <p key={approve.approverId} className={classes.p}>{`${
          approve.approverFullName
        } ${approve.approverEmail} on ${format(
          new Date(approve.approvalDate),
          'yyyy-MM-dd',
        )} at ${format(new Date(approve.approvalDate), 'HH:mm:ss')}`}</p>
      ))}
    </>
  );
};

export default ApprovalInfo;
