// Lib
import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

// Components
import Address from './Address';

// Hooks
import useUI from '../../../../hooks/useUI';

// Constants
import { emptyImport } from '../../../../constants/addressBook';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(5),
    marginBottom: theme.spacing(2.5),
    marginTop: theme.spacing(3.5),
    '& > h3': {
      flexBasis: 'calc(50% - 20px)',
    },
  },
  emptyImport: {
    fontSize: 18,
    color: theme.palette.darkGrey,
    marginTop: theme.spacing(3.75),
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 16,
      }),
    },
  },
}));

const Addresses = ({ addresses, setEditableAddress = () => {} }) => {
  const classes = useStyles();
  const { isTabletMode } = useUI();

  return (
    <div className={classes.root} data-testid="addresses-validation-block">
      {addresses.length ? (
        <>
          {!isTabletMode && (
            <div className={classes.headerContainer}>
              <Typography variant="h3">Entered Address</Typography>
              <Typography variant="h3">Validated Address</Typography>
            </div>
          )}
          {addresses.map((address) => (
            <Address
              key={address?.id}
              address={address}
              isTabletMode={isTabletMode}
              setEditableAddress={setEditableAddress}
            />
          ))}
        </>
      ) : (
        <div className={classes.emptyImport}>{emptyImport}</div>
      )}
    </div>
  );
};

export default Addresses;
