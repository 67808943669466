// Lib
import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

// Components
import Slider from '../../../../Slider';
import ShippingCard from '../../ShippingCard';
import PlusButton from '../../../../OrderHistory/Buttons/PlusButton';
import AddressCardHeader from '../../../../AddressBook/Card/AddressCardHeader/AddressCardHeader';

const useStyles = makeStyles((theme) => ({
  addressSection: ({ isDistroOrder }) => ({
    width: isDistroOrder ? '100%' : 'initial',
  }),
  shippingTitle: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
    },
  },
  shippingCard: {
    width: '100% !important',
    '& > div:nth-child(1)': {
      marginBottom: ({ isOrderPlaced }) => isOrderPlaced && theme.spacing(1),
      [theme.breakpoints.down('md')]: {
        marginBottom: ({ isOrderPlaced }) =>
          isOrderPlaced && theme.spacing(0.5),
      },
      [theme.breakpoints.downLandscape('md')]: {
        ...(theme.isMobileDevice && {
          marginBottom: ({ isOrderPlaced }) =>
            isOrderPlaced && theme.spacing(0.5),
        }),
      },
    },
    '& > div:nth-child(2)': {
      '& > div:nth-child(1)': {
        marginBottom: ({ isOrderPlaced }) =>
          !isOrderPlaced && theme.spacing(1.25),
        [theme.breakpoints.down('md')]: {
          marginBottom: ({ isOrderPlaced }) =>
            !isOrderPlaced && theme.spacing(0.75),
        },
        [theme.breakpoints.downLandscape('md')]: {
          ...(theme.isMobileDevice && {
            marginBottom: ({ isOrderPlaced }) =>
              !isOrderPlaced && theme.spacing(0.75),
          }),
        },
      },
    },
  },
  singleShippingCard: {
    marginRight: theme.spacing(2.5),
    [theme.breakpoints.down('md')]: {
      height: ({ isReviewOrderPage, isOrderPlaced }) =>
        isReviewOrderPage && !isOrderPlaced && 190,
      marginRight: theme.spacing(1.5),
      paddingTop: ({ isReviewOrderPage, isOrderPlaced }) =>
        isReviewOrderPage && !isOrderPlaced && theme.spacing(2),
    },
    '& > div:nth-child(1)': {
      marginBottom: ({ isOrderPlaced }) => isOrderPlaced && theme.spacing(1),
      [theme.breakpoints.down('md')]: {
        marginBottom: ({ isOrderPlaced }) =>
          isOrderPlaced && theme.spacing(0.5),
      },
      [theme.breakpoints.downLandscape('md')]: {
        ...(theme.isMobileDevice && {
          marginBottom: ({ isOrderPlaced }) =>
            isOrderPlaced && theme.spacing(0.5),
        }),
      },
      '& > div:nth-child(1)': {
        marginBottom: ({ isOrderPlaced }) =>
          !isOrderPlaced && theme.spacing(1.25),
        [theme.breakpoints.down('lg')]: {
          marginBottom: ({ isOrderPlaced }) =>
            !isOrderPlaced && theme.spacing(1),
        },
        [theme.breakpoints.down('md')]: {
          marginBottom: ({ isOrderPlaced }) =>
            !isOrderPlaced && theme.spacing(0.75),
        },
        [theme.breakpoints.downLandscape('lg')]: {
          ...(theme.isMobileDevice && {
            marginBottom: ({ isOrderPlaced }) =>
              !isOrderPlaced && theme.spacing(1),
          }),
        },
        [theme.breakpoints.downLandscape('md')]: {
          ...(theme.isMobileDevice && {
            marginBottom: ({ isOrderPlaced }) =>
              !isOrderPlaced && theme.spacing(0.75),
          }),
        },
      },
    },
  },
  fullAddress: {
    display: '-webkit-box',
    fontSize: 14,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    color: theme.palette.darkGrey,
    [theme.breakpoints.down('lg')]: {
      '-webkit-line-clamp': 3,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      '-webkit-line-clamp': 4,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        '-webkit-line-clamp': 4,
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 12,
        '-webkit-line-clamp': 6,
      }),
    },
  },
}));

const ShippingAddresses = ({
  shippingAddresses,
  isOrderPlaced = false,
  goToAddresses = () => {},
  onDeleteAddress = () => {},
  isOrderDetailsPage = false,
  isRecurringOrderPage = false,
  isReviewOrderPage = false,
  trackingLink = '',
  orderId,
  className,
}) => {
  const navigate = useNavigate();
  const isDistroOrder = useMemo(
    () => shippingAddresses?.length > 1,
    [shippingAddresses],
  );
  const classes = useStyles({
    isDistroOrder,
    isReviewOrderPage,
    isOrderPlaced,
  });

  const title = useMemo(() => {
    const title = `Shipping Addresses (${shippingAddresses?.length})`;
    if (isDistroOrder && !isRecurringOrderPage) {
      return title;
    }
    if (isDistroOrder && isRecurringOrderPage) {
      return (
        <>
          {title}
          <PlusButton
            onClick={() =>
              navigate(`/order-history/recurring/${orderId}/address-book`)
            }
            style={{ marginLeft: 16 }}
          />
        </>
      );
    }
  }, [
    navigate,
    orderId,
    isDistroOrder,
    shippingAddresses,
    isRecurringOrderPage,
  ]);

  return (
    <div className={classes.addressSection}>
      {!isDistroOrder && !(isOrderDetailsPage || isRecurringOrderPage) && (
        <Typography
          className={classNames(classes.shippingTitle, className)}
          variant="h3"
          data-testid="shipping-address-header">
          Shipping Address
        </Typography>
      )}
      {!isDistroOrder && (isOrderDetailsPage || isRecurringOrderPage) && (
        <Typography
          className={classes.shippingTitle}
          variant="h3"
          data-testid="shipping-address-header">
          {`Shipping Addresses (${shippingAddresses.length})`}
          {isRecurringOrderPage && (
            <PlusButton
              onClick={() =>
                navigate(`/order-history/recurring/${orderId}/address-book`)
              }
              style={{ marginLeft: 16 }}
            />
          )}
        </Typography>
      )}
      {isDistroOrder ? (
        <Slider title={title}>
          {shippingAddresses.map((address) => {
            const fullAddress = address?.fullAddress || address?.full_address;
            const addressId =
              address?.id ||
              address?.address?.id ||
              address?.address_fields?.id;

            return (
              <ShippingCard
                dataTestIdForCard={`shipping-address-${addressId}`}
                trackingLink={trackingLink}
                multipleMode
                onDeleteAddress={onDeleteAddress(addressId)}
                isOrderPlaced={isOrderPlaced || isOrderDetailsPage}
                className={classes.shippingCard}
                key={addressId}
                onChange={goToAddresses}
                title={<AddressCardHeader address={address} />}
                dataTestId="shipping-address-title">
                {fullAddress && (
                  <div
                    className={classes.fullAddress}
                    data-testid="shipping-address-text">
                    {fullAddress}
                  </div>
                )}
              </ShippingCard>
            );
          })}
        </Slider>
      ) : (
        <ShippingCard
          withControls={!isRecurringOrderPage}
          trackingLink={trackingLink}
          isOrderPlaced={isOrderPlaced || isOrderDetailsPage}
          className={classes.singleShippingCard}
          onChange={goToAddresses}
          title={<AddressCardHeader address={shippingAddresses?.[0]} />}
          dataTestId="shipping-address-title">
          {shippingAddresses?.[0]?.full_address && (
            <div
              className={classes.fullAddress}
              data-testid="shipping-address-text">
              {shippingAddresses?.[0]?.full_address}
            </div>
          )}
        </ShippingCard>
      )}
    </div>
  );
};

export default ShippingAddresses;
