// Lib
import React, { useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

// Components
import Button from '../../Button';
import Feather from '../../Feather';
import FooterButtons from '../../Modal/FooterButtons';
import Addresses from './Addresses';
import FileSelection from './FileSelection';
import Form from '../Form';

// Hooks
import useImportAddresses from '../../../pages/AddressBook/hooks/useImportAddresses';
import useModal from '../../../hooks/useModal';
import useAddressForm from '../../../pages/AddressBook/hooks/useAddressForm';
import useImportAddress from '../../../pages/AddressBook/hooks/useImportAddress';
import ErrorMessage from '../../Error/ErrorMessage';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: ({ firstStep }) => (firstStep ? 530 : 815),
    minHeight: ({ firstStep }) => (firstStep ? 'initial' : 'auto'),
    [theme.breakpoints.down('lg')]: {
      width: ({ firstStep }) => (firstStep ? 530 : 600),
    },
    [theme.breakpoints.down('md')]: {
      height: '100%',
      width: 'initial !important',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        height: '100%',
        width: 'initial !important',
      }),
      ...(theme.isTablet && {
        width: ({ firstStep }) => {
          return firstStep ? 530 : 600;
        },
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        width: 'initial !important',
      }),
    },
  },
  stepInfo: {
    fontSize: 18,
    color: theme.palette.darkGrey,
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: ({ incorrectFormatMessage }) =>
      incorrectFormatMessage && theme.spacing(3.625),
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  downloadButton: {
    fontSize: 14,
    padding: '2px 10px',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3),
      paddingLeft: 0,
      paddingRight: 0,
      width: 145,
    },
  },
  footerButtons: {
    paddingBottom: 0,
    [theme.breakpoints.down('lg')]: {
      paddingBottom: ({ firstStep }) => (!firstStep ? '30px' : 'initial'),
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: '16px !important',
    },
  },
}));

const ImportAddresses = () => {
  const { states, countries } = useAddressForm();
  const {
    onDrop,
    importedAddresses,
    step,
    changeStep,
    onChangeFile,
    uploading,
    withSpinner,
    incorrectFormatMessage,
    setEditableAddress,
    editableAddress,
    downloadTemplate,
    onCancelUploading,
    apiError,
  } = useImportAddresses();
  const { onChangeSelect, onSaveAddress, onChangeInput, onCloseAddressForm } =
    useImportAddress(editableAddress, setEditableAddress);

  const classes = useStyles({ firstStep: step === 1, incorrectFormatMessage });
  const { onClose } = useModal();

  const acceptBtnTitle = useMemo(() => {
    if (step === 1) {
      return 'Next';
    }
    if (step === 2 && !!importedAddresses.addresses.length) {
      return 'Save';
    }
    if (step === 2 && !importedAddresses.addresses.length) {
      return 'Close';
    }
  }, [importedAddresses, step]);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div
          className={classes.stepInfo}
          data-testid="import-addresses-step-label">{`Step ${step}/2`}</div>
        {step === 1 && (
          <Button
            className={classes.downloadButton}
            variant="text"
            onClick={downloadTemplate}
            data-testid="download-template-button">
            <Feather
              printType="stroke"
              type="arrowDown"
              style={{ marginRight: 8 }}
            />
            Download Template
          </Button>
        )}
      </div>
      {step === 1 ? (
        <>
          <FileSelection
            withSpinner={withSpinner}
            onCancelUploading={onCancelUploading}
            uploadingProgress={importedAddresses.uploadingProgress}
            importedAddresses={importedAddresses}
            onDrop={onDrop}
            onChangeFile={onChangeFile}
            incorrectFormatMessage={incorrectFormatMessage}
          />
        </>
      ) : editableAddress ? (
        <>
          <Typography variant="h3" mb={2} mt={2}>
            Edit The Shipping Address
          </Typography>
          <Form
            onChangeInput={onChangeInput}
            apiErrors={editableAddress.GMOP_errors_description}
            uspsError={
              editableAddress?.USPS_return_text ||
              editableAddress?.USPS_error_description
            }
            address={editableAddress.original_fields}
            states={states}
            countries={countries}
            onChangeSelect={onChangeSelect}
            onSubmit={onSaveAddress}>
            <FooterButtons
              acceptBtnType="submit"
              acceptTitle="Save"
              rejectTitle="Cancel"
              onReject={onCloseAddressForm}
            />
          </Form>
        </>
      ) : (
        <Addresses
          addresses={importedAddresses.addresses}
          setEditableAddress={setEditableAddress}
        />
      )}
      {apiError && <ErrorMessage message={apiError} />}
      {!editableAddress &&
        (importedAddresses.file || step === 2) &&
        !withSpinner && (
          <FooterButtons
            withPrevButton={step === 1 || !!importedAddresses.addresses.length}
            onReject={onClose}
            rejectTitle="Cancel"
            acceptTitle={acceptBtnTitle}
            onAccept={changeStep}
            isLoading={uploading}
            className={classes.footerButtons}
          />
        )}
    </div>
  );
};

export default ImportAddresses;
