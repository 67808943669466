import { ORDER_APPROVAL_STATUSES } from '../../../constants/approvals';

const getAddressesCount = (order) =>
  order.addresses ? order.addresses.length : 1;

const getOrderedQty = (item) => {
  const { APPROVED, REJECTED } = ORDER_APPROVAL_STATUSES;

  if ([APPROVED, REJECTED].includes(item.status)) {
    return item.qty_approved;
  } else {
    return item.qty_ordered;
  }
};

const getPrepackQty = (item, productsObj) =>
  (productsObj && productsObj[item.product]?.prepack_quantity) || 1;

const getOrderQty = (order) => {
  return (
    getAddressesCount(order) *
    order.order_items.reduce((acc, item) => acc + getOrderedQty(item), 0)
  );
};

const getTotalOrderedQty = (order, productsObj) => {
  const unavailableProduct = order.order_items.find(
    (item) => productsObj[item.product]?.prepack_quantity === 0,
  );

  if (unavailableProduct) {
    return 'N/A';
  }

  return (
    getAddressesCount(order) *
    order.order_items.reduce(
      (acc, item) =>
        acc + getOrderedQty(item) * getPrepackQty(item, productsObj),
      0,
    )
  );
};

const getProductTotalItemsOrdered = (
  product,
  orderItem,
  addressesCount = 1,
) => {
  return (
    getOrderedQty(orderItem) * (product?.prepack_quantity || 1) * addressesCount
  );
};

export {
  getOrderQty,
  getTotalOrderedQty,
  getAddressesCount,
  getOrderedQty,
  getProductTotalItemsOrdered,
};
