// Libs
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

// Components
import CartProductQtyButtons from '../components/Cart/CartProductQtyButtons/CartProductQtyButtons';
import MaterialsProductQtyButtons from '../components/Products/Card/MaterialsProductQtyButtons';
import KitsProductQtyButtons from '../components/Kits/KitsProductQtyButtons/KitsProductQtyButtons';
import LaterProductQtyButtons from '../components/Cart/LaterProductQtyButtons/LaterProductQtyButtons';

// Hooks
import useCart from '../pages/Cart/hooks/useCart';
import { isOverLimit } from '../utils/overLimit';
import useRequest from '../pages/Cart/hooks/useRequest';

const useProductDetailsQtyButtons = (product, cartProps, isMobileMode) => {
  const { id: modalId, props: modalProps } = useSelector(
    (state) => state.modal,
  );
  const productId = product?.product_id;
  const availableQty = product?.product_available_qty;
  const { count, addOne, removeOne, setCount } = useCart(productId);
  const { onClearOverLimitInDB, onSaveOverLimitInDB } = useRequest(null, true);

  const removeOneProduct = useCallback(
    (product) => () => {
      if (!isOverLimit(product, count - 1, 1)) {
        onClearOverLimitInDB(product);
      }
      removeOne(product)();
    },
    [removeOne, count],
  );

  const addOneProduct = useCallback(
    (product) => () => {
      if (isOverLimit(product, count + 1, 1)) {
        onSaveOverLimitInDB(product);
      }
      addOne(product)();
    },
    [addOne, count],
  );

  const productQtyButtons = useMemo(() => {
    switch (modalId) {
      case 'materials-product-details':
        return availableQty > 0 ? (
          <MaterialsProductQtyButtons
            detailsMode={true}
            product={product}
            {...cartProps}
          />
        ) : (
          <></>
        );
      case 'cart-product-details':
        return (
          <CartProductQtyButtons
            product={product}
            onAdd={addOneProduct(product)}
            onRemove={removeOneProduct(product)}
            onSetCount={setCount}
            count={count}
            detailsMode={true}
            {...cartProps}
          />
        );
      case 'kits-product-details':
        return (
          <KitsProductQtyButtons
            detailsMode={true}
            product={product}
            {...cartProps}
          />
        );
      case 'later-product-details':
        return (
          <LaterProductQtyButtons
            detailsMode={true}
            product={product}
            isMobileMode={isMobileMode}
            nums={modalProps?.nums}
          />
        );
      case 'recurring-product-details':
        return;
      case 'placed-order-details':
      default:
        return null;
    }
  }, [
    modalId,
    availableQty,
    product,
    cartProps,
    addOneProduct,
    removeOneProduct,
    setCount,
    count,
    isMobileMode,
    modalProps?.nums,
  ]);

  return {
    productQtyButtons,
  };
};

export default useProductDetailsQtyButtons;
