// Lib
import React, { useMemo } from 'react';
import { makeStyles } from '@mui/styles';

// Components
import AddressTitle from '../../Labels/AddressTitle/AddressTitle';

// Utils
import {
  getFullName,
  getCompany,
  getAddressType,
} from '../../../../utils/address';

const useStyles = makeStyles((theme) => ({
  fullName: ({ isAddressBookPage }) => ({
    marginTop: isAddressBookPage ? theme.spacing(2) : 0,
  }),
  company: {},
  addressType: ({ isListView, isAddressBookPage }) => ({
    marginTop: theme.spacing(1.5),
    fontSize: '14px',
    lineHeight: '16px',
    [theme.breakpoints.down('lg')]: {
      '-webkit-line-clamp': isListView ? 1 : isAddressBookPage ? 2 : 1,
    },
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(0.5),
      fontSize: 12,
      lineHeight: '16px',
    },
  }),
}));

const AddressCardHeader = ({ address, isListView, isAddressBookPage }) => {
  const fullName = useMemo(() => getFullName(address), [address]);
  const company = useMemo(() => getCompany(address), [address]);
  const addressType = useMemo(() => getAddressType(address), [address]);

  const classes = useStyles({ isListView, isAddressBookPage });

  return (
    <>
      {fullName && (
        <AddressTitle
          className={classes.fullName}
          title={fullName}
          data-testid="address-card-full-name"
        />
      )}
      {company && (
        <AddressTitle
          className={classes.company}
          title={company}
          data-testid="address-card-company"
        />
      )}
      {addressType && (
        <AddressTitle
          className={classes.addressType}
          title={<>&ldquo;{addressType}&rdquo;</>}
          data-testid="address-card-address-type"
        />
      )}
    </>
  );
};

export default AddressCardHeader;
