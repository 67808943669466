// Lib
import { createReducer } from '@reduxjs/toolkit';

// Actions
import {
  ApplyFilters,
  ResetProductsPage,
  UpdateProductsPage,
  SetProducts,
  SetFrequentlyOrderedProducts,
  UpdateProductViaSocket,
  SetProductsSortedByOrder,
  UpdateArrayProductsViaSocket,
} from '../actions/products';
import { SetRating } from '../actions/rating';
import { UserLogOut } from '../actions/user';
import { UpdateFavoriteValue } from '../actions/products';

const PRODUCT_COUNT_ON_PAGE = 15;

export const initialState = {
  page: 1,
  all: {
    ids: null,
    obj: {},
  },
  afterFilters: [],
  shown: [],
  frequentlyOrdered: [],
  sortedByOrders: null,
};

export default createReducer(initialState, {
  [SetProducts]: (state, { payload }) => {
    return {
      ...state,
      all: payload,
    };
  },

  [SetFrequentlyOrderedProducts]: (state, { payload }) => ({
    ...state,
    frequentlyOrdered: payload || [],
  }),
  [SetProductsSortedByOrder]: (state, { payload }) => ({
    ...state,
    sortedByOrders: payload || null,
  }),
  [UpdateProductsPage]: (state) => {
    const newPage = state.page + 1;
    const newShownProducts = state?.afterFilters?.slice(
      0,
      newPage * PRODUCT_COUNT_ON_PAGE,
    );
    const newProductsCount = newShownProducts?.length;

    if (
      state?.afterFilters?.length >= newProductsCount &&
      state?.shown?.length < newProductsCount
    ) {
      return {
        ...state,
        page: newPage,
        shown: newShownProducts,
      };
    }
    return state;
  },
  [ResetProductsPage]: (state) => ({
    ...state,
    page: 1,
    shown: state.afterFilters.slice(0, PRODUCT_COUNT_ON_PAGE),
  }),
  [ApplyFilters]: (state, { payload }) => ({
    ...state,
    page: 1,
    afterFilters: payload,
    shown: payload.slice(0, PRODUCT_COUNT_ON_PAGE),
  }),
  [SetRating]: (state, { payload: { id, rate, family_rate } }) => ({
    ...state,
    all: {
      ...state.all,
      obj: {
        ...state.all.obj,
        [id]: { ...state.all.obj[id], user_rate: rate, family_rate },
      },
    },
  }),
  [UpdateFavoriteValue]: (state, { payload }) => ({
    ...state,
    all: {
      ...state.all,
      obj: {
        ...state.all.obj,
        [payload.productId]: {
          ...state.all.obj[payload.productId],
          is_favourite: payload.favoriteValue,
        },
      },
    },
  }),
  [UpdateProductViaSocket]: (state, { payload }) => {
    const isProductInState = !!state?.all?.obj?.[payload.product_id];
    const productIds = state?.all?.ids ? state?.all?.ids : [];

    return {
      ...state,
      all: {
        ...state.all,
        obj: {
          ...state.all.obj,
          [payload?.product_id]: {
            ...state?.all?.obj?.[payload?.product_id],
            ...payload,
          },
        },
        ids: isProductInState
          ? productIds
          : [...productIds, payload?.product_id],
      },
    };
  },
  [UpdateArrayProductsViaSocket]: (state, { payload }) => {
    let updatedState = { ...state };
    const productIds = state?.all?.ids ? state?.all?.ids : [];

    payload.forEach((product) => {
      const isProductInState = !!state?.all?.obj?.[product.product_id];
      updatedState = {
        ...updatedState,
        all: {
          ...updatedState.all,
          obj: {
            ...updatedState.all.obj,
            [product?.product_id]: {
              ...updatedState?.all?.obj?.[product?.product_id],
              ...product,
            },
          },
          ids: isProductInState
            ? productIds
            : [...productIds, product?.product_id],
        },
      };
    });

    return updatedState;
  },
  [UserLogOut]: () => initialState,
});
