// Lib
import React from 'react';
import Drawer from '@mui/material/Drawer';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

// Components
import CloseButton from '../Button/CloseButton';

const useStyles = makeStyles((theme) => ({
  root: ({ styles }) => ({
    minWidth: 268,
    width: '71vw',
    maxWidth: 550,
    ...styles,
  }),
  content: {
    height: '100%',
    padding: `${theme.spacing(8)} ${theme.spacing(4)} ${theme.spacing(4)}`,
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(5)} ${theme.spacing(1.5)} ${theme.spacing(2)}`,
    },
  },
  modal: {
    backgroundColor: 'rgba(48, 85, 107, 0.2)',
  },
  closeButton: {
    [theme.breakpoints.down('md')]: {
      right: theme.spacing(2.5),
      top: theme.spacing(2.5),
    },
  },
}));

const SideMenu = ({ children, active, toggleSideMenu, styles, title }) => {
  const classes = useStyles({ styles });

  return (
    <Drawer
      sx={{ minWidth: 268 }}
      anchor="left"
      open={active}
      onClose={toggleSideMenu(false)}
      PaperProps={{ classes: { root: classes.root } }}
      ModalProps={{ BackdropProps: { classes: { root: classes.modal } } }}>
      <CloseButton
        className={classes.closeButton}
        onClose={toggleSideMenu(false)}
      />
      <div className={classes.content}>
        {title && (
          <Typography mb={3} variant="h1">
            {title}
          </Typography>
        )}
        {children}
      </div>
    </Drawer>
  );
};

export default SideMenu;
