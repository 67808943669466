// Lib
import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

// Components
import AddressFormat from '../../../components/AddressBook/Card/AddressFormat';
import ErrorAddress from '../../../components/AddressBook/Card/ErrorAddress';
import AddressForm from '../../../components/AddressBook/Form/AddressForm';

// Hooks
import useModal from '../../../hooks/useModal';
import useUI from '../../../hooks/useUI';

// Actions
import {
  SaveAddress,
  SetValidatedAddress,
  ValidateAddress,
} from '../../../actions/addressBook';
import { CloseModal, OpenModal } from '../../../actions/modal';

// Utils
import { objectKeysToSnake } from '../../../utils/camelToSnake';
import addShippingAddressInCart from '../utils/addShippingAddressInCart';

const useAddressForm = (editMode = false, id) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [formData, setFormData] = React.useState({});
  const { isMobileMode, isTabletMode } = useUI();

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { addressBook, apiError, shippingAddresses } = useSelector((state) => ({
    addressBook: state.addressBook.addressBook,
    apiError:
      state.apiError?.['validateAddress'] || state.apiError?.['saveAddress'],
    shippingAddresses: state.cart.shippingAddresses,
  }));
  const { onClose } = useModal();

  useEffect(() => {
    if (!isSaved) {
      return;
    }
    if (isSaved) {
      dispatch(
        OpenModal({
          Component: () => (
            <Typography variant="h3" color="#54565B">
              {`${
                editMode ? 'The Address' : 'New Address'
              } Has Been Successfully ${editMode ? 'Edited.' : 'Added.'}`}
            </Typography>
          ),
          closeIcon: false,
          props: {
            isFullScreen: false,
            addressSavedCard: true,
            paddingContent: {
              xl: '20px',
              lg: '20px',
              md: '20px',
            },
          },
        }),
      );
      setTimeout(() => dispatch(CloseModal()), 2000);
    }
  }, [isSaved, dispatch, editMode]);

  const clearApiError = useCallback(() => {
    if (addressBook?.afterValidation?.GMOP_errors_description) {
      setIsValidated(false);
      dispatch(
        SetValidatedAddress({
          ...addressBook.afterValidation,
          GMOP_errors_description: '',
        }),
      );
    }
  }, [addressBook, dispatch]);

  const onChangeSelect = useCallback(
    (onChange, setValue) => (item) => () => {
      clearApiError();
      onChange(item.name);
      setValue && setValue('state', '');
    },
    [clearApiError],
  );

  const onChangeInput = useCallback(
    (onChange, nativeEvent = false) =>
      (e) => {
        let value;
        if (nativeEvent) {
          value = e.target.value;
        } else {
          value = e.value;
        }
        onChange(value);
      },
    [],
  );

  const onSubmit = useCallback(
    (data) => {
      const address = objectKeysToSnake({
        ...data,
        phone: data?.phone?.replace(/\-/g, ''),
      });
      setFormData(address);
      if (address?.country === 'United States') {
        dispatch(
          ValidateAddress({ formData: address, setIsLoading, setIsValidated }),
        );
      } else {
        const addShippingAddress = addShippingAddressInCart(
          pathname,
          shippingAddresses,
          data?.country,
        );
        dispatch(
          SaveAddress({
            formData: {
              ...address,
              id,
            },
            setIsLoading,
            setIsSaved,
            editMode,
            addShippingAddress,
          }),
        );
      }
    },
    [
      dispatch,
      setIsLoading,
      setIsValidated,
      editMode,
      id,
      pathname,
      shippingAddresses,
    ],
  );

  const onBackToForm = useCallback(() => {
    dispatch(
      OpenModal({
        id: 'address_saved',
        Component: () => (
          <AddressForm
            address={
              editMode ? { ...formData, id } : addressBook.enteredAddress
            }
            editMode={editMode}
          />
        ),
        closeIcon: true,
        props: {
          isFullScreen: isMobileMode,
          title: `${editMode ? 'Edit' : 'Add New'} Shipping Address`,
        },
        styles: {
          width: 'min(85vw, 700px)',
        },
      }),
    );
  }, [dispatch, addressBook, editMode, id, isMobileMode, formData]);

  useEffect(() => {
    if (!isValidated) {
      return;
    }
    if (isValidated) {
      if (
        (!addressBook.afterValidation.validated ||
          addressBook.afterValidation.USPS_error_description) &&
        !addressBook?.afterValidation?.GMOP_errors_description
      ) {
        dispatch(
          OpenModal({
            Component: () => (
              <ErrorAddress
                originalAddress={addressBook?.afterValidation?.original_address}
                onBackToForm={onBackToForm}
                editMode={editMode}
                id={id}
                enteredAddress={
                  editMode
                    ? { ...addressBook.enteredAddress, id }
                    : addressBook.enteredAddress
                }
              />
            ),
            props: {
              title: '',
              isFullScreen: isMobileMode,
              paddingContent: {
                xl: '40px 40px 30px',
                lg: '40px 28px 40px',
                md: '20px 12px 16px',
              },
            },
            closeIcon: true,
            styles: {
              width: isTabletMode ? 660 : 700,
            },
          }),
        );
      } else if (
        !addressBook.afterValidation.USPS_error_description &&
        !addressBook?.afterValidation?.GMOP_errors_description
      ) {
        dispatch(
          OpenModal({
            Component: () => (
              <AddressFormat
                afterValidation={addressBook.afterValidation}
                formData={editMode ? { ...formData, id } : formData}
                editMode={editMode}
                onBackToForm={onBackToForm}
              />
            ),
            props: {
              title: '',
              isFullScreen: isMobileMode,
              paddingContent: {
                xl: '40px 40px 30px',
                lg: '40px 28px 30px',
                md: '20px 12px 16px',
              },
            },
            closeIcon: true,
            styles: {
              width: isTabletMode ? 660 : 700,
            },
          }),
        );
      }
    }
  }, [
    editMode,
    id,
    isValidated,
    dispatch,
    addressBook,
    formData,
    onBackToForm,
    isTabletMode,
    isMobileMode,
  ]);

  return {
    onChangeSelect,
    onChangeInput,
    onClose,
    onSubmit,
    states: addressBook.usaStates,
    countries: addressBook.countries,
    isLoading,
    setIsLoading,
    setIsValidated,
    apiErrors: addressBook?.afterValidation?.GMOP_errors_description,
    uspsError: addressBook?.afterValidation?.USPS_error_description,
    clearApiError,
    onBackToForm,
    apiError,
  };
};

export default useAddressForm;
