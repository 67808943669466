// Libs
import { put, takeEvery, all, call } from 'redux-saga/effects';

// Api
import http from '../../../api/http';

// Actions
import {
  SaveAddress,
  GetAddressBook,
  SetAddressBook,
  DeleteAddress,
  ValidateAddress,
  SetValidatedAddress,
  AddAddress,
  EditAddress,
  SetEnteredAddress,
  SetAllCountries,
  SetUSAStates,
  SaveAddressBookFilters,
  ChangAddressBookFilter,
  UploadImportedAddressFile,
  SetImportedAddresses,
  SetImportedAddress,
  SaveImportedAddresses,
  SetUploadingProgressValue,
  EditImportedAddress,
  DeleteAddressInState,
  MakeMainAddress,
} from '../../../actions/addressBook';
import { AddShippingAddress } from '../../../actions/cart';
import { SetLoading } from '../../../actions/loading';
import { ClearApiError, SetApiError } from '../../../actions/apiError';

// Sagas
import handleApiError from '../../../sagas/handleApiError';

// Constants
import { ADDRESS_BOOK_URLS, USER_URLS } from '../../../constants/urls';
import { ADDRESS_EXISTS, COMMON_API_ERROR } from '../../../constants/errors';

// Store
import { store } from '../../../store';

// Utils
import { changeAddress1AndAddress2 } from '../../../utils/address';

// Constants
import { invalidAddressFormat } from '../../../constants/addressBook';

export function* saveAddressSaga({
  payload: { formData, setIsLoading, setIsSaved, editMode, addShippingAddress },
}) {
  try {
    setIsLoading(true);
    if (editMode) {
      const response = yield http.put(
        ADDRESS_BOOK_URLS.editAddress(formData.id),
        formData,
      );
      yield put(EditAddress(response?.data));
    } else {
      const response = yield http.post(ADDRESS_BOOK_URLS.saveAddress, formData);
      yield put(AddAddress(response?.data));
      if (addShippingAddress) {
        yield put(AddShippingAddress(response?.data));
      }
    }
    yield put(SetValidatedAddress({}));
    setIsSaved(true);
  } catch (e) {
    setIsLoading(false);
    if (
      e?.response?.data?.non_field_errors?.[0]?.includes(
        'must make a unique set',
      )
    ) {
      yield put(
        SetApiError({
          api: 'saveAddress',
          error: ADDRESS_EXISTS,
        }),
      );
    }
  } finally {
    setIsLoading(false);
  }
}

export function* saveImportedAddressesSaga({
  payload: { setIsLoading, setIsSaved },
}) {
  try {
    const storeAddresses =
      store.getState().addressBook.addressBook.import.addresses;

    setIsLoading(true);
    const addresses = storeAddresses.map((address) => {
      if (address.checked === 'validated') {
        return {
          id: address.id,
          ...address.original_fields,
          ...address.validated_fields,
        };
      }
      return {
        id: address.id,
        ...address.original_fields,
      };
    });

    const response = yield http.post(
      ADDRESS_BOOK_URLS.saveAddresses,
      addresses,
    );

    yield all(response?.data?.map((address) => put(AddAddress(address))));
    setIsSaved(true);
  } catch (e) {
    if (e?.response?.data?.validation_errors) {
      e?.response?.data?.validation_errors?.map((errorItem) => {
        let errorData;
        const [id, error] = Object.entries(errorItem)[0];

        if (typeof error === 'string' && error?.includes('Already exist')) {
          errorData = { addressExists: ADDRESS_EXISTS };
        }

        store.dispatch(
          EditImportedAddress({
            data: errorData || error,
            id: +id,
            type: 'apiError',
          }),
        );
      });
    } else {
      yield put(
        SetApiError({
          api: 'saveImportedAddresses',
          error: COMMON_API_ERROR,
        }),
      );
    }
  } finally {
    setIsLoading(false);
  }
}

export function* validateAddressSaga({
  payload: { formData, setIsLoading, setIsValidated, id, importFlow = false },
}) {
  try {
    yield put(ClearApiError());
    setIsLoading(true);
    const validationResult = yield http.post(
      ADDRESS_BOOK_URLS.validateAddress,
      formData,
    );
    const address = changeAddress1AndAddress2(validationResult?.data);
    if (!importFlow) {
      yield put(SetValidatedAddress(validationResult?.data));
      yield put(SetEnteredAddress(formData));
      setIsValidated(true);
    } else {
      let modifiedAddress = { ...address };
      if (
        !address?.validated_address ||
        (address?.validated_address && address?.USPS_return_text)
      ) {
        modifiedAddress = { ...modifiedAddress, id, checked: 'original' };
      } else {
        modifiedAddress = { ...modifiedAddress, id, checked: 'validated' };
      }
      yield put(SetImportedAddress({ data: modifiedAddress, id }));
    }
  } catch (e) {
    yield put(
      SetApiError({
        api: 'validateAddress',
        error: COMMON_API_ERROR,
      }),
    );
  } finally {
    setIsLoading(false);
  }
}

export function* createFiltersSaga(filters) {
  try {
    yield http.post(USER_URLS.saveSettings, {
      view: 'List',
      address_book_filter: filters,
    });
  } catch (e) {
    console.log(e?.response?.data?.detail || e.message);
  }
}

function* getFiltersSaga() {
  try {
    const userData = yield http.get(USER_URLS.getUser);
    const storeFilters = store.getState().addressBook.filters;
    const settings = userData?.data?.profile_settings;
    const filtersDB = settings?.address_book_filter;

    if (!filtersDB && !settings?.products_filter) {
      yield createFiltersSaga(storeFilters);
    } else {
      yield put(
        ChangAddressBookFilter({
          sorting: { ...storeFilters.sorting, ...filtersDB.sorting },
          view: filtersDB.view,
        }),
      );
    }
  } catch (e) {
    console.log(e);
  }
}

export function* getAddressBookSaga() {
  try {
    yield put(SetLoading(true));
    const [addressBook, countries, usaStates] = yield all([
      call(http.get, ADDRESS_BOOK_URLS.getAddressBook),
      call(http.get, ADDRESS_BOOK_URLS.getCountries),
      call(http.get, ADDRESS_BOOK_URLS.getStates),
      getFiltersSaga(),
    ]);

    yield put(SetAddressBook(addressBook?.data));
    yield put(
      SetAllCountries(
        countries?.data?.map((name) => ({
          name,
        })),
      ),
    );
    if (usaStates?.data) {
      yield put(
        SetUSAStates(
          Object.entries(usaStates?.data)?.map(([name, isoCode]) => ({
            name,
            isoCode,
          })),
        ),
      );
    }
  } catch (e) {
    yield call(handleApiError, e, 'getAddressBook');
  } finally {
    yield put(SetLoading(false));
  }
}

export function* deleteAddressSaga({ payload: id }) {
  try {
    yield http.delete(ADDRESS_BOOK_URLS.deleteAddress(id));
    yield put(DeleteAddressInState(id));
  } catch (e) {
    yield put(
      SetApiError({
        api: 'deleteAddress',
        error: COMMON_API_ERROR,
      }),
    );
  }
}

export function* makeMainAddressSaga({ payload: { id, isMain } }) {
  try {
    yield http.post(ADDRESS_BOOK_URLS.makeMainAddress(id), {
      is_main: isMain,
    });
    yield getAddressBookSaga();
  } catch (e) {
    yield put(
      SetApiError({
        api: 'makeMainAddress',
        error: COMMON_API_ERROR,
      }),
    );
  }
}

export function* saveAddressBookFiltersSaga({ payload }) {
  try {
    yield http.put(ADDRESS_BOOK_URLS.saveFilters, payload);
  } catch (e) {
    console.log(e);
  }
}

export function* uploadImportedAddressFileSaga({
  payload: { file, setUploading, setStep, signal, setApiError, setWithSpinner },
}) {
  let timer;
  try {
    timer = setTimeout(() => setWithSpinner(true), 500);
    setUploading(true);
    const addresses = yield http.post(
      ADDRESS_BOOK_URLS.uploadCSVFile,
      { file },
      {
        signal,
        onUploadProgress: function (progressEvent) {
          const progressValue = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          store.dispatch(SetUploadingProgressValue(progressValue));
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    clearTimeout(timer);

    const modifiedAddresses = addresses?.data?.map((address, index) => {
      const modifiedAddress = changeAddress1AndAddress2(address);
      const { validated_address, USPS_return_text } = modifiedAddress;

      if (!validated_address || (validated_address && USPS_return_text)) {
        return { ...modifiedAddress, id: index, checked: 'original' };
      }
      return { ...modifiedAddress, id: index, checked: 'validated' };
    });
    yield put(SetImportedAddresses(modifiedAddresses));
    setStep(2);
  } catch (e) {
    yield put(SetUploadingProgressValue(0));
    if (e?.response?.status === 406) {
      setApiError(invalidAddressFormat);
    } else if (e.message !== 'canceled') {
      setApiError(COMMON_API_ERROR);
    }
  } finally {
    clearTimeout(timer);
    setWithSpinner(false);
    setUploading(false);
  }
}

function* addressBookSaga() {
  yield takeEvery(ValidateAddress, validateAddressSaga);
  yield takeEvery(SaveAddress, saveAddressSaga);
  yield takeEvery(SaveImportedAddresses, saveImportedAddressesSaga);
  yield takeEvery(GetAddressBook, getAddressBookSaga);
  yield takeEvery(DeleteAddress, deleteAddressSaga);
  yield takeEvery(MakeMainAddress, makeMainAddressSaga);
  yield takeEvery(SaveAddressBookFilters, saveAddressBookFiltersSaga);
  yield takeEvery(UploadImportedAddressFile, uploadImportedAddressFileSaga);
}

export default addressBookSaga;
