// Lib
import React from 'react';
import { makeStyles } from '@mui/styles';

// Components
import Button from '../../Button';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
  buttons: ({ isAcceptBtnMain }) => ({
    backgroundColor: 'white',
    bottom: 0,
    display: 'flex',
    gap: theme.spacing(4),
    justifyContent: 'flex-end',
    left: 0,
    marginTop: 'auto',
    padding: '24px 0 16px 12px',
    position: 'sticky',
    width: '100%',
    zIndex: 1,
    '& button': {
      maxHeight: 44,
    },
    '& #reject-button': {
      color: isAcceptBtnMain ? theme.palette.primary1 : 'white',
    },
    '& .Mui-disabled#accept-button': {
      color: '#929A92 !important',
    },
    '& #accept-button': {
      color: !isAcceptBtnMain ? theme.palette.primary1 : 'white',
      width: 110,
    },
    [theme.breakpoints.down('lg')]: {
      gap: theme.spacing(2),
      '& #accept-button': {
        width: 200,
      },
    },
    [theme.breakpoints.downLandscape('sm')]: {
      ...(theme.isMobileDevice && {
        paddingBottom: theme.spacing(1.5),
        paddingTop: theme.spacing(1.5),
      }),
    },
  }),
}));

const FooterButtons = ({
  withPrevButton = true,
  rejectTitle,
  onReject,
  onAccept = () => {},
  acceptTitle,
  isLoading,
  acceptBtnType,
  isAcceptBtnMain = true,
  className,
  disabledAcceptBtn = false,
}) => {
  const classes = useStyles({ isAcceptBtnMain });

  return (
    <div className={classnames(classes.buttons, className)}>
      {withPrevButton && (
        <Button
          id="reject-button"
          variant={isAcceptBtnMain ? 'text' : 'contained'}
          onClick={onReject}>
          {rejectTitle}
        </Button>
      )}
      <Button
        variant={!isAcceptBtnMain ? 'text' : 'contained'}
        id="accept-button"
        loadingIndicator="Loading…"
        onClick={onAccept}
        loading={isLoading}
        type={acceptBtnType}
        disabled={disabledAcceptBtn}>
        {acceptTitle}
      </Button>
    </div>
  );
};

export default FooterButtons;
