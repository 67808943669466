// Lib
import React from 'react';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

// Components
import Feather from '../../../../Feather';
import IconButton from '../../../../Button/IconButton/IconButton';

// Hooks
import useUI from '../../../../../hooks/useUI';

const useStyles = makeStyles((theme) => ({
  disclaimer: {
    alignItems: 'center',
    color: '#54565B',
    display: 'flex',
    fontSize: 14,
    left: 155,
    lineHeight: '16px',
    position: 'absolute',
    top: 2,
    width: 510,
    [theme.breakpoints.down('lg')]: {
      left: 148,
      top: 8,
    },
  },
  warningIcon: {
    height: 20,
    width: 20,
    '& path': {
      stroke: theme.palette.primary2,
    },
  },
}));

const Disclaimer = ({ disclaimer, className, onClick }) => {
  const { isDesktopMode } = useUI();
  const classes = useStyles();

  return (
    <span
      data-testid="disclaimer-container"
      className={classnames(classes.disclaimer, className)}>
      {isDesktopMode ? (
        <>
          <Feather
            data-testid="disclaimer-icon"
            style={{
              cursor: 'initial',
              marginRight: 6,
              width: 20,
              height: 20,
              minWidth: 20,
            }}
            nativeColor
            type="warning"
          />
          <span data-testid="disclaimer-message">{disclaimer}</span>
        </>
      ) : (
        <IconButton
          data-testid="disclaimer-button"
          startIcon={
            <Feather
              className={classes.warningIcon}
              nativeColor
              type="warning"
            />
          }
          onClick={onClick}>
          Info
        </IconButton>
      )}
    </span>
  );
};

export default Disclaimer;
