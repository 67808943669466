// Lib
import React from 'react';
import { makeStyles } from '@mui/styles';

// Components
import Order from './Order';
import TableRow from './TableRow';
import RecurringOrderCard from '../Cards/RecurringOrderCard';
import VirtualizedTable from '../../VirtualizedTable';
import VirtualizedList from '../../VirtualizedList';
import OuterElementType from '../../VirtualizedList/OuterElementType';

// Hooks
import useUI from '../../../hooks/useUI';
import useRecurringOrderCellReducer from '../../../pages/OrderHistory/hooks/useRecurringOrderCellReducer';
import useOrderCellReducer from '../../../pages/OrderHistory/hooks/useOrderCellReducer';

// Constants
import {
  ordersHistoryColumns,
  recurringOrdersColumns,
} from '../../../constants/orderHistory';

const commonStyles = {
  row: {
    alignItems: 'center',
    boxShadow: '0px 5px 15px rgba(44, 112, 172, 0.2)',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    height: '56px',
    '& td, & th': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  addressCell: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

const useOrdersHistoryStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: 'separate',
    width: '100%',
    maxWidth: 1180,
    overflow: 'visible',
    '& thead tr': {
      marginBottom: theme.spacing(2),
      marginTop: `${theme.spacing(2)} !important`,
      '& th:nth-child(3)': {
        minWidth: 'initial',
      },
    },
  },
  cell: {
    fontFamily: ({ isKiteUser }) => (isKiteUser ? 'Helvetica' : 'Proxima Nova'),
    fontWeight: ({ isKiteUser }) => (isKiteUser ? 400 : 600),
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    borderBottom: 'none',
    letterSpacing: '0.1px',
    color: '#54565B',
    paddingBlock: theme.spacing(0.5),
    height: '56px',
    '&:nth-child(1)': {
      width: '17%',
      paddingRight: 0,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '&:nth-child(2)': {
      width: '8%',
    },
    '&:nth-child(3)': {
      paddingLeft: theme.spacing(1),
      width: '8%',
      minWidth: 'fit-content',
    },
    '&:nth-child(4)': {
      justifyContent: 'center',
      paddingLeft: theme.spacing(1),
      whiteSpace: 'nowrap',
      width: '11%',
    },
    '&:nth-child(5)': {
      wordBreak: 'break-word',
      width: '24%',
    },
    '&:nth-child(6)': {
      justifyContent: 'flex-end',
      textAlign: 'right',
      width: '5%',
    },
    '&:nth-child(7)': {
      justifyContent: 'flex-end',
      textAlign: 'right',
      width: '7%',
    },
    '&:nth-child(8)': {
      width: '14%',
    },
    '&:nth-child(9)': {
      paddingLeft: 0,
      justifyContent: 'flex-end',
      width: '9%',
      '& #reorder': {
        height: 36,
        width: 89,
      },
    },
  },
  headerCell: {
    color: ({ isKiteUser }) =>
      isKiteUser ? theme.palette.darkGrey : theme.palette.black,
    fontWeight: ({ isKiteUser }) => (isKiteUser ? 700 : 600),
    '&:nth-child(7)': {
      color: theme.palette.red,
    },
  },
  ...commonStyles,
}));

const useRecurringOrdersStyles = makeStyles((theme) => ({
  tableFont: {
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    borderBottom: 'none',
    fontWeight: 600,
    letterSpacing: '0.1px',
    marginTop: theme.spacing(2),
  },
  table: {
    borderCollapse: 'separate',
    borderSpacing: `0 ${theme.spacing(2.5)}`,
    overflow: 'visible',
    margin: '0 auto',
    width: '100%',
  },
  cell: {
    fontFamily: ({ isKiteUser }) => (isKiteUser ? 'Helvetica' : 'Proxima Nova'),
    fontWeight: ({ isKiteUser }) => (isKiteUser ? 400 : 600),
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    borderBottom: 'none',
    letterSpacing: '0.1px',
    color: '#54565B',
    paddingBlock: theme.spacing(0.5),
    '&:nth-child(1)': {
      width: '12%',
    },
    '&:nth-child(2)': {
      width: '29%',
    },
    '&:nth-child(3)': {
      justifyContent: 'flex-end',
      textAlign: 'right',
      width: '6%',
    },
    '&:nth-child(4)': {
      justifyContent: 'flex-end',
      textAlign: 'right',
      width: '7%',
    },
    '&:nth-child(5)': {
      width: '14%',
    },
    '&:nth-child(6)': {
      width: '7%',
    },
    '&:nth-child(7)': {
      width: '11%',
    },
    '&:nth-child(8)': {
      paddingLeft: 0,
      width: '8%',
    },
  },
  headerCell: {
    color: ({ isKiteUser }) =>
      isKiteUser ? theme.palette.darkGrey : theme.palette.black,
    fontWeight: ({ isKiteUser }) => (isKiteUser ? 700 : 600),
    '&:nth-child(4)': {
      color: theme.palette.red,
    },
  },
  ...commonStyles,
}));

const OrderList = ({ orders, isRecurring, isKiteUser }) => {
  const { isDesktopMode } = useUI();
  const recurringOrdersClasses = useRecurringOrdersStyles({ isKiteUser });
  const ordersHistoryClasses = useOrdersHistoryStyles({ isKiteUser });

  if (!orders?.length) {
    return <></>;
  }

  return isDesktopMode ? (
    <VirtualizedTable
      styles={isRecurring ? recurringOrdersClasses : ordersHistoryClasses}
      columns={isRecurring ? recurringOrdersColumns : ordersHistoryColumns}
      items={orders}
      collapsibleTable={!isRecurring}
      TableRow={TableRow}
      useCellsReducers={
        isRecurring ? useRecurringOrderCellReducer : useOrderCellReducer
      }
      rowHeight={72}
      rowGap={16}
    />
  ) : (
    <VirtualizedList
      itemPropName="order"
      OuterElementType={OuterElementType}
      RowComponent={isRecurring ? RecurringOrderCard : Order}
      items={orders}
      itemCount={orders.length}
      rowHeight={
        isRecurring
          ? { tablet: 187, landscape: 140, mobile: 162 }
          : { tablet: 188, landscape: 166, mobile: 196 }
      }
      rowGap={{ tablet: 20, mobile: 16 }}
    />
  );
};

export default OrderList;
