// Lib
import React, { useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';

// Components
import CountButton from '../../Button/CountButton';
import Button from '../../Button';
import CountButtonLabel from '../../Labels/CountButtonLabel';

// Constants
import { MORE_THAN_IN_STOCK_MESSAGE } from '../../../constants/products';

// Hooks
import useUI from '../../../hooks/useUI';
import useUser from '../../../hooks/useUser';

const useStyles = makeStyles((theme) => ({
  container: ({ detailsMode, listView }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: detailsMode || !listView ? 'row' : 'column',
    alignItems: 'center',
    gap: 10,
    padding: 0,
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
      justifyContent: !detailsMode && !listView ? 'center' : 'unset',
      width: '100%',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        flexDirection: 'row',
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        marginRight: 0,
        justifyContent: !detailsMode && !listView ? 'center' : 'unset',
        width: '100%',
      }),
    },
  }),
  countButton: ({ detailsMode, listView }) =>
    listView
      ? {
          width: 130,
          height: 36,
          [theme.breakpoints.down('lg')]: {
            width: detailsMode ? 200 : 96,
            height: detailsMode ? 44 : 34,
          },
          [theme.breakpoints.down('md')]: {
            width: detailsMode ? 140 : 96,
            height: detailsMode ? 44 : 34,
          },
          [theme.breakpoints.downLandscape('lg')]: {
            ...(theme.isMobileDevice && {
              width: detailsMode ? 200 : 96,
              height: detailsMode ? 44 : 34,
            }),
          },
          [theme.breakpoints.downLandscape('md')]: {
            ...(theme.isMobileDevice && {
              width: detailsMode ? 140 : 96,
              height: detailsMode ? 44 : 34,
            }),
          },
        }
      : {
          width: detailsMode ? 130 : '50%',
          maxWidth: 130,
          height: 36,
          [theme.breakpoints.down('lg')]: {
            minWidth: detailsMode ? 200 : 96,
            height: detailsMode ? 44 : 34,
          },
          [theme.breakpoints.down('md')]: {
            minWidth: detailsMode ? 140 : 96,
            height: detailsMode ? 44 : 34,
          },
          [theme.breakpoints.downLandscape('lg')]: {
            ...(theme.isMobileDevice && {
              minWidth: detailsMode ? 200 : 96,
              height: detailsMode ? 44 : 34,
            }),
          },
          [theme.breakpoints.downLandscape('md')]: {
            ...(theme.isMobileDevice && {
              minWidth: detailsMode ? 140 : 96,
              height: detailsMode ? 44 : 34,
            }),
          },
        },
  qtyButtonLabel: ({ detailsMode }) => ({
    height: detailsMode ? 'initial' : 36,
    width: detailsMode && 130,
    [theme.breakpoints.down('lg')]: {
      height: 'initial',
      width: detailsMode && 'auto',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        height: 'initial',
        width: detailsMode && 'auto',
      }),
    },
  }),
  addToCartButton: ({ detailsMode, listView, isRecurringMaterials }) => {
    if (listView) {
      let width = 96;
      if (detailsMode) {
        width = 200;
      }
      if (isRecurringMaterials) {
        width = 105;
      }
      return {
        width: 130,
        height: 36,
        padding: theme.spacing(1),
        fontSize: 14,
        [theme.breakpoints.down('lg')]: {
          width,
          height: detailsMode ? 44 : 34,
        },
        [theme.breakpoints.down('md')]: {
          width: detailsMode ? 140 : '100%',
          height: 32,
        },
        [theme.breakpoints.downLandscape('lg')]: {
          ...(theme.isMobileDevice && {
            width,
            height: detailsMode ? 44 : 34,
          }),
        },
        [theme.breakpoints.downLandscape('md')]: {
          ...(theme.isMobileDevice && {
            width: detailsMode ? 140 : '100%',
            height: 32,
          }),
        },
      };
    }
    return {
      width: detailsMode ? 130 : '50%',
      maxWidth: detailsMode ? 'unset' : 130,
      height: 36,
      padding: theme.spacing(1),
      fontSize: 14,
      [theme.breakpoints.down('lg')]: {
        minWidth: detailsMode ? 200 : 96,
        height: detailsMode ? 44 : 34,
        fontSize: detailsMode && 16,
      },
      [theme.breakpoints.down('md')]: {
        width: detailsMode ? 140 : 124,
        minWidth: detailsMode ? 140 : 'unset',
        maxWidth: detailsMode ? 'unset' : 124,
        height: 32,
      },
      [theme.breakpoints.downLandscape('lg')]: {
        ...(theme.isMobileDevice && {
          minWidth: detailsMode ? 200 : 96,
          height: detailsMode ? 44 : 34,
          fontSize: detailsMode && 16,
        }),
      },
      [theme.breakpoints.downLandscape('md')]: {
        ...(theme.isMobileDevice && {
          width: detailsMode ? 140 : 124,
          minWidth: detailsMode ? 140 : 'unset',
          maxWidth: detailsMode ? 'unset' : 124,
          height: 32,
        }),
      },
    };
  },
}));

const MaterialsProductQtyButtons = ({
  product,
  addInCart,
  count,
  addOne,
  removeOne,
  setCount,
  isProductInCart,
  listView,
  detailsMode, // in products modal details
  className,
  loading = false,
}) => {
  const { pathname } = useLocation();
  const { isMobileMode, isTabletMode, isDesktopMode } = useUI();
  const { isKiteUser } = useUser();

  const { product_available_qty: availableQuantity } = product || {};
  const isRecurringMaterials = useMemo(() => {
    if (
      pathname.includes('/order-history/recurring/') &&
      pathname.includes('/materials')
    ) {
      return true;
    }
  }, [pathname]);

  const classes = useStyles({ detailsMode, listView, isRecurringMaterials });

  const actionButtonTitle = useMemo(() => {
    const instance = isRecurringMaterials ? 'Order' : 'Cart';

    return !isProductInCart ? `Add To ${instance}` : `In ${instance}`;
  }, [isProductInCart, isRecurringMaterials]);

  const labelVariant = useMemo(() => {
    let variant = isMobileMode ? 'textLg' : 'textSm';
    if (!isKiteUser) {
      variant += 'Semibold';
    }
    return variant;
  }, [isKiteUser, isMobileMode]);

  const isMoreThanInStock = useMemo(
    () => count > availableQuantity,
    [availableQuantity, count],
  );

  const isAddToCartButtonDisabled = useMemo(() => {
    // If product already in cart
    // If qty is set in 0 and nothing to add in cart
    // In mobile mode qty buttons hidden, so default value is 1, not 0 and
    // we don't block button if we are not inside modal
    return (
      isProductInCart ||
      (!isMobileMode && !detailsMode && count === 0) ||
      (detailsMode && count === 0)
    );
  }, [count, detailsMode, isMobileMode, isProductInCart]);

  const isQtyLabelShown = useMemo(() => {
    return isMoreThanInStock && !(isMobileMode && !detailsMode);
  }, [detailsMode, isMobileMode, isMoreThanInStock]);

  return (
    <div className={classnames(classes.container, className)}>
      {(detailsMode || (!detailsMode && !isMobileMode)) && (
        <CountButton
          product={product}
          count={count}
          onAdd={addOne(product)}
          onRemove={removeOne(product)}
          onSetCount={setCount}
          error={isMoreThanInStock}
          className={classes.countButton}
        />
      )}
      {isQtyLabelShown ? (
        <CountButtonLabel
          className={classes.qtyButtonLabel}
          variant={labelVariant}>
          {(isTabletMode || (isDesktopMode && !listView)) && !detailsMode ? (
            <div>
              More Than <br /> In Stock
            </div>
          ) : (
            MORE_THAN_IN_STOCK_MESSAGE
          )}
        </CountButtonLabel>
      ) : (
        <Button
          loading={loading}
          id="add-to-cart"
          onClick={addInCart(product)}
          disabled={isAddToCartButtonDisabled}
          className={classes.addToCartButton}>
          {actionButtonTitle}
        </Button>
      )}
    </div>
  );
};

export default MaterialsProductQtyButtons;
