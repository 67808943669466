// Lib
import React, {
  createContext,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { areEqual, VariableSizeList as List } from 'react-window';
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow as MuiTableRow,
} from '@mui/material';
import classnames from 'classnames';

// Components
import OuterElementType from '../VirtualizedList/OuterElementType';

const VirtualizedTableContext = createContext();

const Row = memo(({ data, index, style }) => {
  const {
    TableRow,
    items,
    useCellsReducers,
    collapsibleTable,
    showChildren,
    toggleHeight,
    columns,
    styles,
    itemHeight,
  } = data;
  const item = items[index];

  const mergedStyles = {
    ...style,
    height: itemHeight,
    pointerEvents: 'auto',
  };

  return (
    <TableRow
      style={mergedStyles}
      collapsibleTable={collapsibleTable}
      styles={styles}
      key={item?.id}
      columns={columns}
      useCellsReducers={useCellsReducers}
      item={item}
      withChildren={showChildren?.[index]}
      onToggleChildren={toggleHeight(index)}
      itemHeight={itemHeight}
    />
  );
}, areEqual);

const InnerElementType = forwardRef(({ children, ...rest }, ref) => (
  <VirtualizedTableContext.Consumer>
    {({ styles, columns, topBodyPosition }) => {
      return (
        <MuiTable className={styles.table} ref={ref} {...rest}>
          <TableHead>
            <MuiTableRow className={styles.row}>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  className={classnames(styles.cell, styles.headerCell)}>
                  {column.label}
                </TableCell>
              ))}
            </MuiTableRow>
          </TableHead>
          <TableBody
            style={{
              position: 'absolute',
              width: '100%',
              top: topBodyPosition,
            }}>
            {children}
          </TableBody>
        </MuiTable>
      );
    }}
  </VirtualizedTableContext.Consumer>
));

const VirtualizedTable = ({
  useCellsReducers,
  TableRow,
  collapsibleTable = false,
  items,
  styles,
  columns,
  rowHeight,
  rowGap,
}) => {
  const listRef = useRef();

  const createRowHeights = useCallback(
    () =>
      new Array(items.length).fill(true).reduce((acc, item, i) => {
        if (listRef.current) {
          listRef.current.resetAfterIndex(i);
        }
        acc[i] = rowHeight;
        return acc;
      }, {}),
    [rowHeight, listRef, items],
  );

  const [rowSizes, setRowSizes] = useState(createRowHeights);
  const [showChildren, setShowChildren] = useState({});

  useEffect(() => {
    setRowSizes(createRowHeights());
    setShowChildren({});
  }, [setShowChildren, createRowHeights, items?.length]);

  const toggleHeight = useCallback(
    (i) => (childItemsCount) => {
      if (listRef.current) {
        listRef.current.resetAfterIndex(i);
      }
      setShowChildren((prevState) => ({
        ...prevState,
        [i]: !prevState[i],
      }));

      setRowSizes((prevState) => ({
        ...prevState,
        [i]:
          prevState[i] === rowHeight
            ? rowHeight * childItemsCount - rowGap * (childItemsCount - 1)
            : rowHeight,
      }));
    },
    [listRef, rowHeight, setShowChildren, rowGap],
  );

  const getSize = useCallback(
    (i) => {
      return rowSizes[i];
    },
    [rowSizes],
  );

  if (!rowSizes[items.length - 1]) {
    return;
  }

  return (
    <VirtualizedTableContext.Provider
      value={{
        styles,
        columns,
        TableRow,
        topBodyPosition: rowHeight + rowGap,
      }}>
      <List
        itemCount={items.length}
        outerElementType={OuterElementType}
        innerElementType={InnerElementType}
        height={window.innerHeight}
        width={window.innerWidth}
        itemSize={getSize}
        ref={listRef}
        itemData={{
          itemHeight: rowHeight - rowGap,
          TableRow,
          items,
          useCellsReducers,
          collapsibleTable,
          styles,
          columns,
          showChildren,
          toggleHeight,
        }}>
        {Row}
      </List>
    </VirtualizedTableContext.Provider>
  );
};

export default VirtualizedTable;
