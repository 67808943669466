// Libs
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

// Actions
import { ReorderKit, SetReorderKitConfirmation } from '../../../actions/kits';

// Utils
import isProductAvailable from '../../../utils/isProductAvailable';

// Selectors
import { productsObjSelector } from '../../../store/selectors';

const useReorderKit = (kit) => {
  const { productsObj } = useSelector((state) => ({
    productsObj: productsObjSelector(state),
  }));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const navigateToCart = () => {
    navigate('/cart', {
      state: {
        navigateFrom: pathname,
      },
    });
  };

  const cancelReorder = () => dispatch(SetReorderKitConfirmation(false));

  const confirmReorder = () => dispatch(SetReorderKitConfirmation(true));

  const onReorderKit = () => {
    dispatch(
      ReorderKit({ kit, cancelReorder, confirmReorder, navigateToCart }),
    );
  };

  const canReorder = useMemo(
    () =>
      !!kit?.products?.find(({ product }) =>
        isProductAvailable(productsObj[product]),
      ),
    [kit, productsObj],
  );

  return {
    onReorderKit,
    canReorder,
  };
};

export default useReorderKit;
