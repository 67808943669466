// Lib
import { createReducer } from '@reduxjs/toolkit';

// Actions
import { SetSearchActive } from '../actions/isSearchActive';

export const initialState = false;

export default createReducer(initialState, {
  [SetSearchActive]: (state, { payload }) => payload,
});
