// Lib
import { createAction } from '@reduxjs/toolkit';

export const GetProducts = createAction('GET_PRODUCTS');
export const SetProducts = createAction('SET_PRODUCTS');
export const SetFrequentlyOrderedProducts = createAction(
  'SET_FREQUENTLY_ORDERED_PRODUCTS',
);
export const SetProductsSortedByOrder = createAction(
  'SET_PRODUCTS_SORTED_BY_ORDER',
);
export const UpdateProductsPage = createAction('UPDATE_PRODUCTS_PAGE');
export const ResetProductsPage = createAction('RESET_PRODUCTS_PAGE');
export const ApplyFilters = createAction('APPLY_FILTERS');
export const GetFDMDocument = createAction('GET_FDM_DOCUMENT');
export const SetFavoriteValue = createAction('SET_FAVORITE_VALUE');
export const UpdateFavoriteValue = createAction('UPDATE_FAVORITE_VALUE');
export const UpdateProductViaSocket = createAction('UPDATE_PRODUCT_VIA_SOCKET');
export const UpdateArrayProductsViaSocket = createAction(
  'UPDATE_ARRAY_PRODUCTS_VIA_SOCKET',
);
