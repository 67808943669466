// Lib
import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { SaveCatalogView, SetUser } from '../../../actions/user';
import { ResetProductsPage } from '../../../actions/products';

const useCatalogView = () => {
  const dispatch = useDispatch();
  const { catalogView } = useSelector((state) => ({
    catalogView: state.user.catalogView,
  }));

  const changeView = useCallback(
    (newView) => () => {
      if (catalogView !== newView) {
        window?.scroll(0, 0);
        dispatch(ResetProductsPage());
        dispatch(SetUser({ catalogView: newView }));
        dispatch(SaveCatalogView(newView));
      }
    },
    [dispatch, catalogView],
  );

  return { changeView, catalogView };
};

export default useCatalogView;
