// Lib
import React, { useEffect, useState, useCallback } from 'react';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';

// Components
import Input from '../../../../Field/Input';
import Feather from '../../../../Feather';

// Styles
import { colors } from '../../../../../styles/palette';

const useStyles = makeStyles((theme) => ({
  checkBox: {
    marginBottom: theme.spacing(3),
    marginLeft: 0,
    '& .MuiSvgIcon-root': {
      height: 22,
      marginRight: 6,
      width: 22,
    },
    '& .MuiTypography-root': {
      fontSize: 18,
    },
    [theme.breakpoints.down('md')]: {
      '& .MuiTypography-root': {
        fontSize: 16,
      },
    },
  },
  eventContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(8),
    '& > div:nth-child(3)': {
      '& input': {
        opacity: 0,
      },
    },
    '& > .MuiBox-root': {
      '&:nth-child(1)': {
        width: 350,
      },
      '&:nth-child(2)': {
        width: 114,
      },
      '&:nth-child(3)': {
        width: 280,
      },
    },
    [theme.breakpoints.down('lg')]: {
      '& > .MuiBox-root': {
        '&:nth-child(2)': {
          minWidth: 114,
        },
      },
    },
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      marginBottom: theme.spacing(4),
      '& > .MuiBox-root': {
        '&:nth-child(1)': {
          width: '100% !important',
          '& > div:nth-child(2)': {
            maxWidth: 350,
            width: '100%',
          },
        },
        '&:nth-child(2)': {
          width: 76,
          minWidth: 'auto',
        },
        '&:nth-child(3)': {
          width: '100% !important',
        },
      },
    },
  },
  customPlaceholder: ({ isKiteUser }) => ({
    paddingLeft: 6,
    paddingTop: 6,
    position: 'absolute',
    width: '100%',
    color: isKiteUser ? theme.palette.primary1 : theme.palette.primary2,
  }),
  placeholderText: {
    width: '82%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.text.primary,
  },
  input: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 45,
  },
  fileInput: {
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.secondary.main,
      zIndex: '-1',
    },
    '& fieldset': {
      border: 'none',
    },
  },
  errorContainer: {
    display: 'flex',
    position: 'absolute',
  },
  errorIcon: {
    marginTop: 5,
    [theme.breakpoints.down('lg')]: {
      marginTop: 3.5,
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 3,
    },
  },
  error: {
    color: colors.gilead.primary1,
    fontSize: 18,
    [theme.breakpoints.down('lg')]: {
      fontSize: 16,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  },
}));

const Event = ({
  onChangeEvent,
  shippingDetails,
  onRemoveFile,
  onChangeFile,
  incorrectFormatMessage,
  inputFileRef,
}) => {
  const classes = useStyles();
  const [checkBoxChecked, setCheckBoxChecked] = useState(false);

  const { event, schedule } = shippingDetails;

  const isRecurring =
    schedule?.toLowerCase().includes('monthly') ||
    schedule?.toLowerCase().includes('weekly');

  useEffect(() => {
    if (event?.name || event?.boothNumber) {
      setCheckBoxChecked(true);
    }
  }, [event, setCheckBoxChecked]);

  const onChangeCheckBox = useCallback(
    ({ target }) => {
      setCheckBoxChecked(target.checked);
    },
    [setCheckBoxChecked],
  );

  return (
    <>
      <FormControlLabel
        disabled={isRecurring}
        className={classes.checkBox}
        label="Order Is For An Event/Conference"
        control={
          <Checkbox
            checked={checkBoxChecked && !isRecurring}
            onChange={onChangeCheckBox}
          />
        }
      />
      {checkBoxChecked && !isRecurring && (
        <div className={classes.eventContainer}>
          <Input
            value={event.name}
            label="Event Name"
            name="name"
            onChange={onChangeEvent}
            maxLength={256}
          />
          <Input
            value={event.boothNumber}
            label="Booth Number"
            name="boothNumber"
            onChange={onChangeEvent}
            dir="rtl"
            maxLength={10}
          />
          <div>
            <Input
              ref={inputFileRef}
              accept="application/pdf"
              type="file"
              label="Shipping Labels"
              hidden
              onChange={onChangeFile}
              className={event.file.name ? classes.fileInput : ''}
              customPlaceholder={
                event.file.name ? (
                  <Box
                    className={classes.customPlaceholder}
                    display="flex"
                    alignItems="center">
                    <Feather
                      nativeColor
                      type="file"
                      style={{ marginRight: 5 }}
                    />
                    <div className={classes.placeholderText}>
                      {event.file.name}
                    </div>
                    <Feather
                      onClick={onRemoveFile}
                      type="delete"
                      style={{ position: 'absolute', zIndex: 1, right: 5 }}
                    />
                  </Box>
                ) : (
                  <Box
                    className={classes.customPlaceholder}
                    display="flex"
                    alignItems="center">
                    <Feather
                      printType="stroke"
                      type="download"
                      style={{ marginRight: 5 }}
                    />
                    Upload PDF File
                  </Box>
                )
              }
            />
            {incorrectFormatMessage && (
              <div className={classes.errorContainer}>
                <Feather
                  style={{ minWidth: 20, cursor: 'initial' }}
                  nativeColor
                  type="error"
                  className={classes.errorIcon}
                />
                <span className={classes.error}>{incorrectFormatMessage}</span>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Event;
