// Lib
import React from 'react';
import { Button } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  clickable: {
    color: ({ isKiteUser }) =>
      isKiteUser ? theme.palette.primary1 : theme.palette.pressed,
    fontWeight: ({ isKiteUser }) => (isKiteUser ? '400' : '600'),
    textDecoration: 'none',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
}));

const EditRecurrenceButton = ({ onClick = () => {} }) => {
  const classes = useStyles();

  return (
    <Button
      onClick={onClick}
      className={classes.clickable}
      style={{ padding: 0, wordWrap: 'none' }}
      startIcon={<EditOutlinedIcon />}
      data-testid="edit-recurrence-button">
      Edit Recurrence
    </Button>
  );
};

export default EditRecurrenceButton;
