// Lib
import React, { useMemo } from 'react';
import { makeStyles } from '@mui/styles';

// Components
import RemoveFilter from '../../Button/RemoveFilter';
import Feather from '../../Feather';
import Button from '../../Button';

// Hooks
import useUser from '../../../hooks/useUser';
import {
  FILTERS_KEY_MAP,
  OTHER_FILTER_TYPE,
} from '../../../constants/products';

const defaultFilterTitles = [
  'brand_category',
  'owner',
  'audience',
  'available',
  'indication',
];

const useStyles = makeStyles((theme) => ({
  button: ({ isKiteUser }) => ({
    '&:hover': {
      '& svg path': {
        fill: isKiteUser ? '#3C89D0' : '#3C7BA3',
      },
      '& span': {
        color: isKiteUser ? '#3C89D0' : '#3C7BA3',
      },
    },
  }),
}));

const RemoveFilters = ({
  filters,
  onRemoveFilter,
  onClearAllFilters,
  filterTitles,
}) => {
  const { isKiteUser } = useUser();
  const classes = useStyles({ isKiteUser });
  const renderedItems = useMemo(() => {
    const items = [];

    (filterTitles || defaultFilterTitles).forEach((filterTitle) => {
      filters[filterTitle]?.forEach(({ title, checked }) => {
        const modifiedTitle =
          title === OTHER_FILTER_TYPE
            ? `${FILTERS_KEY_MAP[filterTitle]}: ${OTHER_FILTER_TYPE}`
            : title;
        if (checked) {
          items.push(
            <RemoveFilter
              key={modifiedTitle}
              name={filterTitle}
              title={modifiedTitle}
              onClick={onRemoveFilter(filterTitle, title)}
            />,
          );
        }
      });
    });

    if (items.length) {
      items.push(
        <Button
          className={classes.button}
          onClick={onClearAllFilters}
          id="clear-all"
          key="clear-all"
          styles={{ padding: '4px', fontSize: '14px' }}
          variant="text">
          <Feather style={{ marginRight: 4 }} type="close" />
          <span className={classes.clearAll}>Clear All</span>
        </Button>,
      );
    }

    return items;
  }, [filters, onRemoveFilter, onClearAllFilters, filterTitles, classes]);

  return <>{renderedItems}</>;
};

export default RemoveFilters;
