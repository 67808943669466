// Lib
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

// Components
import ConfirmCard from '../../../components/Cards/ConfirmCard';
import EditRecurrenceCard from '../../../components/OrderHistory/Cards/EditRecurrenceCard';

// Actions
import {
  CancelRecurringOrder,
  SkipRecurringOrder,
} from '../../../actions/orders';
import { OpenModal } from '../../../actions/modal';
import { ClearApiError } from '../../../actions/apiError';

// Hook
import useUI from '../../../hooks/useUI';

const useRecurringOrder = (order, orderDetailsPage) => {
  const { isTabletMode, isMobileMode } = useUI();
  const dispatch = useDispatch();
  const [loadingSkip, setLoadingSkip] = useState(false);
  const { orderId } = useParams();

  const onEditRecurrence = useCallback(() => {
    dispatch(
      OpenModal({
        Component: () => (
          <EditRecurrenceCard
            order={order}
            orderDetailsPage={orderDetailsPage}
          />
        ),
        styles: {
          width: isTabletMode ? 660 : 723,
          height: 420,
        },
        props: {
          isFullScreen: isMobileMode,
          paddingContent: {
            xl: '40px 40px 30px',
            lg: '40px 40px 30px',
            md: '20px 12px 16px',
          },
        },
      }),
    );
  }, [dispatch, order, isMobileMode, orderDetailsPage, isTabletMode]);

  const onCancel = useCallback(() => {
    dispatch(ClearApiError());
    dispatch(
      OpenModal({
        Component: () => (
          <ConfirmCard
            title="Do You Want To Cancel?"
            message="It Will Remove The Recurring Order From Order History And Cancel Recurrence."
            isAcceptBtnMain={false}
            onConfirm={() =>
              dispatch(
                CancelRecurringOrder({
                  id: order.id,
                  redirectPath: orderId && '/order-history/recurring',
                  api: 'cancel-recurring-order',
                }),
              )
            }
          />
        ),
        styles: {
          width: isMobileMode ? '85vw' : 'min(70vw, 611px)',
        },
      }),
    );
  }, [dispatch, order, isMobileMode, orderId]);

  const onSkip = useCallback(() => {
    dispatch(
      SkipRecurringOrder({
        id: order.id,
        setLoading: setLoadingSkip,
        orderDetailsPage,
      }),
    );
  }, [dispatch, order, orderDetailsPage]);

  return {
    onEditRecurrence,
    onCancel,
    onSkip,
    loadingSkip,
  };
};

export default useRecurringOrder;
