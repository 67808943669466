const addShippingAddressInCart = (pathname, shippingAddresses, country) => {
  if (!country || pathname !== '/cart/shipping-address') {
    return false;
  }

  if (shippingAddresses?.length === 0) {
    return true;
  }

  const firstCheckedCountry = shippingAddresses?.[0].country;

  if (
    shippingAddresses?.length > 0 &&
    firstCheckedCountry === 'United States'
  ) {
    return country === firstCheckedCountry;
  }

  if (
    shippingAddresses?.length > 0 &&
    firstCheckedCountry !== 'United States'
  ) {
    return country !== 'United States';
  }
};

export default addShippingAddressInCart;
