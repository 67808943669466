// Lib
import React from 'react';
import { useSelector } from 'react-redux';

// Components
import StickyButtons from '../../../components/Button/StickyButtons';

// Pages
import AddressBook from '../../AddressBook';

// Hooks
import useRecurringAddressBook from '../hooks/useRecurringAddressBook';

const RecurringAddressBook = () => {
  const { onCancel, onSave, loading } = useRecurringAddressBook();
  const isSearchActive = useSelector((state) => state.isSearchActive);

  return (
    <AddressBook isAddressBookPage={false} onlyContent>
      {!isSearchActive && (
        <StickyButtons
          onReject={onCancel}
          onAccept={onSave}
          loading={loading}
        />
      )}
    </AddressBook>
  );
};

export default RecurringAddressBook;
