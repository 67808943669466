// Lib
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';

// Components
import StickyButtons from '../../../components/Button/StickyButtons';

// Pages
import Materials from '../../../pages/Materials';

// Hooks
import useKitsProducts from '../../../pages/Kits/hooks/useKitsProducts';
import {
  GetKitDetails,
  SetSelectingKitProductsProcess,
} from '../../../actions/kits';

const useStyles = makeStyles((theme) => ({
  stickyButtons: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      '& button': {
        flexGrow: 0,
        width: 140,
      },
    },
  },
}));

const KitProducts = () => {
  const dispatch = useDispatch();
  const { kitId } = useParams();
  const { submitNewKitProducts, closeMaterialsModal } = useKitsProducts();
  const { isLoading } = useSelector((state) => state);
  const isSearchActive = useSelector((state) => state.isSearchActive);

  useEffect(() => {
    dispatch(SetSelectingKitProductsProcess(true));

    return () => {
      dispatch(SetSelectingKitProductsProcess(false));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(GetKitDetails(kitId));
  }, [dispatch, kitId]);

  const classes = useStyles();

  return (
    <Materials onlyContent>
      {!isSearchActive && (
        <StickyButtons
          onReject={closeMaterialsModal}
          onAccept={submitNewKitProducts}
          loading={isLoading}
          className={classes.stickyButtons}
        />
      )}
    </Materials>
  );
};

export default KitProducts;
