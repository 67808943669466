// Lib
import React from 'react';
import { TableCell, TableRow as MuiTableRow } from '@mui/material';

const TableRow = ({
  useCellsReducers = () => ({ cells: {} }),
  columns,
  item,
  styles,
  addressesCount,
  isMethodPage,
}) => {
  const { cells } = useCellsReducers({
    product: item,
    addressesCount,
    isMethodPage,
  });

  return (
    <MuiTableRow className={styles?.row}>
      {columns?.map((column) => {
        return (
          <TableCell key={`${item?.id}-${column?.id}`} className={styles?.cell}>
            {cells?.[column?.id]}
          </TableCell>
        );
      })}
    </MuiTableRow>
  );
};

export default TableRow;
