// Lib
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { VariableSizeList as List, areEqual } from 'react-window';

// Hooks
import useUI from '../../hooks/useUI';

const Row = memo(({ index, style, data }) => {
  const {
    items,
    RowComponent,
    rowComponentProps,
    itemPropName,
    toggleHeight,
    showChildren,
  } = data || {};
  const item = items?.[index];
  const props = {
    ...rowComponentProps,
    toggleHeight: toggleHeight(index),
    ...{ [itemPropName]: item },
  };

  const styles = {
    ...style,
    pointerEvents: 'auto',
  };

  return (
    <div style={styles}>
      <RowComponent
        key={`${item?.product_id}_${index}`}
        {...props}
        withChildren={showChildren?.[index]}
      />
    </div>
  );
}, areEqual);

const VirtualizedList = ({
  items,
  itemCount,
  OuterElementType,
  RowComponent,
  rowHeight,
  rowComponentProps = {},
  itemPropName,
  rowGap,
}) => {
  const { isTabletMode, isMobileMode } = useUI();
  const listRef = useRef();

  const height = useMemo(() => {
    if (isTabletMode && !isMobileMode) {
      return rowHeight.tablet;
    }
    if (isMobileMode) {
      return rowHeight.mobile;
    }
  }, [rowHeight, isTabletMode, isMobileMode]);

  const createRowHeights = useCallback(
    () =>
      new Array(items.length).fill(true).reduce((acc, item, i) => {
        if (listRef.current) {
          listRef.current.resetAfterIndex(i);
        }
        acc[i] = height;
        return acc;
      }, {}),
    [height, listRef, items],
  );

  const [rowSizes, setRowSizes] = useState(createRowHeights);
  const [showChildren, setShowChildren] = useState({});

  useEffect(() => {
    setRowSizes(createRowHeights());
    setShowChildren({});
  }, [
    setShowChildren,
    createRowHeights,
    isMobileMode,
    isTabletMode,
    itemCount,
  ]);

  const gap = useMemo(() => {
    if (isTabletMode && !isMobileMode) {
      return rowGap.tablet;
    }
    if (isMobileMode) {
      return rowGap.mobile;
    }
  }, [rowGap, isTabletMode, isMobileMode]);

  const toggleHeight = useCallback(
    (i) => (childItemsCount) => {
      if (listRef.current) {
        listRef.current.resetAfterIndex(i);
      }
      setShowChildren((prevState) => ({
        ...prevState,
        [i]: !prevState[i],
      }));

      setRowSizes((prevState) => ({
        ...prevState,
        [i]:
          prevState[i] === height
            ? height * childItemsCount - gap * (childItemsCount - 1)
            : height,
      }));
    },
    [listRef, height, setShowChildren, gap],
  );

  const getSize = useCallback(
    (i) => {
      return rowSizes[i];
    },
    [rowSizes],
  );

  if (!rowSizes[itemCount - 1]) {
    return;
  }

  return (
    <List
      height={window.innerHeight}
      outerElementType={OuterElementType}
      itemCount={itemCount}
      itemSize={getSize}
      ref={listRef}
      width={window.innerWidth}
      itemData={{
        listRef,
        items,
        RowComponent,
        rowComponentProps,
        itemPropName,
        toggleHeight,
        showChildren,
      }}>
      {Row}
    </List>
  );
};

export default VirtualizedList;
