// Lib
import React, { useCallback } from 'react';
import { OpenModal } from '../../../../../actions/modal';
import { useDispatch } from 'react-redux';

// Components
import ConfirmCard from '../../../../Cards/ConfirmCard';

// Actions
import { ClearApiError } from '../../../../../actions/apiError';
import {
  CancelRecurringOrder,
  UpdateRecurringOrder,
  SetRecurringOrderProductQty,
} from '../../../../../actions/orders';

// Hooks
import useUI from '../../../../../hooks/useUI';

const useCountComponent = (order, orderItem, product) => {
  const dispatch = useDispatch();
  const { isMobileMode } = useUI();

  const onAdd = useCallback(() => {
    if (orderItem.qty_ordered + 1 <= product.product_available_qty) {
      const newOrder = { ...order };
      newOrder.order_items = order.order_items.map((item) => {
        if (orderItem.product === item.product) {
          return {
            ...item,
            qty_ordered: item.qty_ordered + 1,
          };
        }
        return item;
      });
      newOrder.shipment_method = newOrder.shipment_method.id;
      newOrder.addresses = newOrder.addresses.map(({ address }) => address);
      delete newOrder.user;

      dispatch(
        UpdateRecurringOrder({
          newOrder,
          updateCurrentOrder: true,
        }),
      );
    }
  }, [dispatch, orderItem, order, product]);

  const showConfirmWindow = useCallback(() => {
    dispatch(
      OpenModal({
        Component: () => (
          <ConfirmCard
            title="Do You Want To Cancel?"
            message="It Will Remove The Recurring Order From Order History And Cancel Recurrence."
            isAcceptBtnMain={false}
            onConfirm={() =>
              dispatch(
                CancelRecurringOrder({
                  id: order.id,
                  redirectPath: '/order-history/recurring',
                  api: 'cancel-recurring-order',
                }),
              )
            }
          />
        ),
        styles: {
          width: isMobileMode ? '85vw' : 'min(70vw, 611px)',
        },
      }),
    );
  }, [dispatch, order?.id, isMobileMode]);

  const onDelete = useCallback(
    (e) => {
      e?.stopPropagation();
      if (order.order_items.length === 1) {
        dispatch(ClearApiError());
        showConfirmWindow();
      } else {
        const newOrder = { ...order };
        newOrder.order_items = order.order_items.filter(
          (item) => orderItem.product !== item.product,
        );
        newOrder.shipment_method = newOrder.shipment_method.id;
        newOrder.addresses = newOrder.addresses.map(({ address }) => address);
        delete newOrder.user;

        dispatch(
          UpdateRecurringOrder({
            newOrder,
            updateCurrentOrder: true,
            productId: orderItem.product,
          }),
        );
      }
    },
    [dispatch, order, orderItem, showConfirmWindow],
  );

  const onRemoveOne = useCallback(
    (e) => {
      if (
        (orderItem.qty_ordered === 1 && order.order_items.length === 1) ||
        orderItem.qty_ordered === 1
      ) {
        onDelete(e);
      } else {
        const newOrder = { ...order };
        newOrder.order_items = order.order_items.map((item) => {
          if (orderItem.product === item.product) {
            return {
              ...item,
              qty_ordered: item.qty_ordered - 1,
            };
          }
          return item;
        });
        newOrder.shipment_method = newOrder.shipment_method.id;
        newOrder.addresses = newOrder.addresses.map(({ address }) => address);
        delete newOrder.user;

        dispatch(
          UpdateRecurringOrder({
            newOrder,
            updateCurrentOrder: true,
          }),
        );
      }
    },
    [dispatch, order, orderItem, onDelete],
  );

  const onSetCount = useCallback(
    (product, newCount) => {
      const count = Math.round(newCount);

      if (count === 0) {
        onDelete();
        return;
      }

      if (count <= product.product_available_qty) {
        const newOrder = { ...order };
        newOrder.order_items = order.order_items.map((item) => {
          if (orderItem.product === item.product) {
            return {
              ...item,
              qty_ordered: count,
            };
          }
          return item;
        });
        newOrder.shipment_method = newOrder.shipment_method.id;
        newOrder.addresses = newOrder.addresses.map(({ address }) => address);
        delete newOrder.user;

        // This is used for setting new product qty before request.
        dispatch(
          SetRecurringOrderProductQty({
            productId: product.product_id,
            newQty: count,
          }),
        );

        dispatch(
          UpdateRecurringOrder({
            newOrder,
            updateCurrentOrder: true,
          }),
        );
      }
    },
    [dispatch, order, orderItem, onDelete],
  );

  const onRemoveProductWithZeroCount = useCallback(() => {
    if (!orderItem.qty_ordered) {
      onDelete();
    }
  }, [orderItem, onDelete]);

  return {
    onAdd,
    onRemoveOne,
    onSetCount,
    onDelete,
    onRemoveProductWithZeroCount,
  };
};

export default useCountComponent;
