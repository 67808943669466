// Libs
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from 'react';
import { FixedSizeGrid as Grid } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

// Components
import GridCard from './Card/GridCard';

// Hooks
import useUI from '../../hooks/useUI';

const ITEM_SIZE_DESKTOP = 495;
const ITEM_SIZE_TABLET = 380;
const ITEM_SIZE_MOBILE = 290;
const CELL_GAP = 20;
const CELL_GAP_MOBILE = 16;

const Cell = ({ columnIndex, rowIndex, style, data }) => {
  const { isMobileMode } = useUI();
  const { products, columnCount } = data || {};
  const index = rowIndex * columnCount + columnIndex;
  const product = products?.all?.obj?.[products.shown?.[index]];

  const left =
    Number(style?.left) +
    columnIndex * (isMobileMode ? CELL_GAP_MOBILE : CELL_GAP);

  const styles = {
    ...style,
    left: columnIndex === 0 ? style.left : left,
    pointerEvents: 'auto',
  };

  if (!product?.product_id) {
    return;
  }

  return (
    <div style={styles}>
      <GridCard
        isGridView
        key={`${product.product_id}_${index}`}
        product={product}
      />
    </div>
  );
};

const VirtualGrid = ({ OuterElementType, products, updateShownProducts }) => {
  const { isDesktopMode, isTabletMode, isMobileMode } = useUI();
  const gridContainerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(window.innerWidth);
  const columnCount = useMemo(() => (isMobileMode ? 2 : 3), [isMobileMode]);

  const onResize = useCallback(() => {
    setContainerWidth(gridContainerRef?.current?.clientWidth);
  }, [gridContainerRef.current]);

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [gridContainerRef.current, onResize]);

  const columnWidth = useMemo(() => {
    const gridWidth = containerWidth;
    let delta = CELL_GAP * 2;
    if (isMobileMode) {
      delta = CELL_GAP_MOBILE;
    }
    return (gridWidth - delta) / columnCount;
  }, [isMobileMode, columnCount, containerWidth]);

  const rowHeight = useMemo(() => {
    if (isDesktopMode) {
      return ITEM_SIZE_DESKTOP + CELL_GAP;
    }
    if (isTabletMode && !isMobileMode) {
      return ITEM_SIZE_TABLET + CELL_GAP;
    }
    if (isMobileMode) {
      return ITEM_SIZE_MOBILE;
    }
  }, [isDesktopMode, isTabletMode, isMobileMode]);

  return (
    <div ref={gridContainerRef}>
      <InfiniteLoader
        isItemLoaded={(index) => {
          return products.shown[index] !== undefined;
        }}
        itemCount={products?.all?.ids?.length || 0}
        loadMoreItems={updateShownProducts}
        treshold={3}>
        {({ onItemsRendered, ref }) => {
          const newItemsRendered = (gridData) => {
            const useOverscanForLoading = true;
            const {
              visibleRowStartIndex,
              visibleRowStopIndex,
              visibleColumnStopIndex,
              overscanRowStartIndex,
              overscanRowStopIndex,
              overscanColumnStopIndex,
            } = gridData;

            const endCol =
              (useOverscanForLoading || true
                ? overscanColumnStopIndex
                : visibleColumnStopIndex) + 1;

            const startRow =
              useOverscanForLoading || true
                ? overscanRowStartIndex
                : visibleRowStartIndex;
            const endRow =
              useOverscanForLoading || true
                ? overscanRowStopIndex
                : visibleRowStopIndex;

            const visibleStartIndex =
              startRow * (endCol + (columnCount === 3 ? 0 : 1));
            const visibleStopIndex =
              endRow * (endCol + (columnCount === 3 ? 0 : 1));

            onItemsRendered({
              visibleStartIndex,
              visibleStopIndex,
            });
          };

          return (
            <Grid
              columnCount={columnCount}
              columnWidth={columnWidth}
              height={window.innerHeight}
              rowCount={Math.ceil(products.shown.length / columnCount)}
              rowHeight={rowHeight}
              width={window.innerWidth}
              outerElementType={OuterElementType}
              itemData={{
                products,
                columnCount,
              }}
              onItemsRendered={newItemsRendered}
              ref={ref}>
              {Cell}
            </Grid>
          );
        }}
      </InfiniteLoader>
    </div>
  );
};

export default VirtualGrid;
