export const PRODUCT_OUT_OF_STACK =
  'This Product Is Out Of Stock Or No Longer Available.';

export const PRODUCTS_OUT_OF_STACK =
  'These Products Are Out Of Stock Or No Longer Available.';

export const DAYS_RESTRICTION_MESSAGE = (text) =>
  `Some Months Have Fewer Than ${text}.`;

export const DAYS_RESTRICTION_EXPLANATION = (text) =>
  `For These Months The Recurrence Will Fall On The Last ${text} Of The Month.`;

export const REORDER_ENTITY_WITHOUT_PRODUCT_MESSAGE = (entity = 'Kit') =>
  `Do You Want To Reorder The ${entity} Without This Product?`;

export const REORDER_ENTITY_WITHOUT_PRODUCTS_MESSAGE = (entity = 'Kit') =>
  `Do You Want To Reorder The ${entity} Without These Products?`;
