export const kitsTableColumns = [
  { id: 'kitNickname', label: 'Kit Nickname' },
  { id: 'totalQnty', label: 'Total Qty' },
  { id: 'viewDetailsButton', label: '' },
  { id: 'reorderButton', label: '' },
  { id: 'deleteButton', label: '' },
];

export const emptyKitsMessage =
  'There are currently no items saved in your kits';

export const emptyKitsButtonTitle = 'Go To Order History';
