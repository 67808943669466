// Libs
import React from 'react';
import { Typography } from '@mui/material';

export const resolveFontVariant = ({ device }) => {
  const { isMobileMode } = device;

  if (!isMobileMode) {
    return 'textSmBold';
  } else {
    return 'textXsSemibold';
  }
};

const OutOfStockLabel = (props) => {
  return (
    <Typography
      variant={resolveFontVariant(props)}
      sx={{ color: '#C5203F' }}
      data-testid="out-of-stock-label">
      {props.label}
    </Typography>
  );
};

export default OutOfStockLabel;
