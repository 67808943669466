export const ADDRESS_CARD_HEIGHTS = {
  desktop: {
    addressBookPage: {
      listView: 180,
      gridView: 196,
    },
    cartPage: {
      listView: 164,
      gridView: 180,
    },
  },
  tablet: {
    addressBookPage: {
      listView: 180,
      gridView: 212,
    },
    cartPage: {
      listView: 164,
      gridView: 176,
    },
  },
  mobile: {
    addressBookPage: {
      listView: 164,
      gridView: 222,
    },
    cartPage: {
      listView: 144,
      gridView: 200,
    },
  },
};
