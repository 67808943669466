// Lib
import { createAction } from '@reduxjs/toolkit';

export const GetApprovalOrder = createAction('GET_APPROVAL_ORDER');
export const SetApprovalOrderId = createAction('SET_APPROVAL_ORDER_ID');
export const SetApprovalOrder = createAction('SET_APPROVAL_ORDER');
export const SetApprovalProducts = createAction('SET_APPROVAL_PRODUCTS');
export const EditRequestedQty = createAction('EDIT_REQUESTED_QTY');
export const AddAction = createAction('ADD_ACTION');
export const SetOrderQtyError = createAction('SET_ORDER_QTY_ERROR');
export const SetApproveResponse = createAction('SET_APPROVE_RESPONSE');
export const SaveApprovals = createAction('SAVE_APPROVALS');
export const SetApprovalShippingMethods = createAction(
  'SET_APPROVAL_SHIPPING_METHODS',
);
export const EditShippingMethod = createAction('EDIT_SHIPPING_METHOD');
