// Libs
import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Card as MuiCard,
  CardMedia,
  CardContent,
  Box,
  Typography,
} from '@mui/material';

// Components
import Image from '../../Image';

// Hooks

import useUnavailableForOrderProduct from '../../../hooks/useUnavailableForOrderProduct';
import useUI from '../../../hooks/useUI';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    borderRadius: 20,
    boxShadow: '0px 5px 15px rgba(44, 112, 172, 0.2)',
    display: 'flex',
    wordBreak: 'break-all',
    minHeight: 275,
    [theme.breakpoints.down('lg')]: {
      minHeight: 243,
    },
    [theme.breakpoints.down('md')]: {
      borderRadius: 10,
      minHeight: 136,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        minHeight: 243,
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        borderRadius: 10,
        minHeight: 136,
      }),
    },
  },
  img: ({ isUnavailableForOrder }) => ({
    ...(isUnavailableForOrder && {
      opacity: 0.65,
    }),
  }),
  detailsBold: {
    fontWeight: 600,
  },
  detailsNormal: {
    fontWeight: 400,
  },
  actionArea: {
    position: 'relative',
    width: 'initial',
  },
  description: {
    cursor: 'pointer',
    fontSize: 18,
    fontWeight: 700,
    maxWidth: '80%',
    [theme.breakpoints.down('lg')]: {
      fontSize: 14,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      '-webkit-line-clamp': 1,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        fontSize: 14,
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 12,
        '-webkit-line-clamp': 1,
      }),
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: '30px 16px 30px 30px !important',
    width: '100%',
    minWidth: 150,
    [theme.breakpoints.down('lg')]: {
      gap: theme.spacing(1),
      padding: '16px 16px 16px 20px !important',
    },
    [theme.breakpoints.down('md')]: {
      padding: '8px 10px !important',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        gap: theme.spacing(1),
        padding: '16px 16px 16px 20px !important',
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        padding: '8px 10px !important',
      }),
    },
  },
  details: {
    color: theme.palette.darkGrey,
    display: 'flex',
    gap: theme.spacing(8),
    fontSize: 14,
    fontWeight: 600,
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
      gap: 3,
      '& div:nth-child(2)': {
        order: 2,
      },
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        flexDirection: 'column',
        gap: 3,
        '& div:nth-child(2)': {
          order: 2,
        },
      }),
    },
  },
  detailsItem: {
    '& > span:first-child': {
      display: 'block',
      [theme.breakpoints.down('lg')]: {
        paddingRight: theme.spacing(0.5),
        display: 'initial',
      },
      [theme.breakpoints.downLandscape('lg')]: {
        ...(theme.isMobileDevice && {
          paddingRight: theme.spacing(0.5),
          display: 'initial',
        }),
      },
    },
  },
}));

const ValidateCard = ({ product, cardContent, imageContainer }) => {
  const { isMobileMode } = useUI();
  const {
    image_link: image,
    product_id: stockNumber,
    product_description: description,
  } = product || {};

  const { isUnavailableForOrder, unavailableWatermark } =
    useUnavailableForOrderProduct(product, false, false, true);
  const classes = useStyles({ isUnavailableForOrder });
  return (
    <MuiCard disabled={!image} className={classes.root}>
      <Box className={classes.actionArea}>
        {unavailableWatermark}
        <Image
          alt="productImage"
          src={image}
          imageContainer={
            imageContainer || { desktop: [275], tablet: [243], mobile: [136] }
          }>
          <CardMedia
            id="image-button"
            className={classes.img}
            component="img"
            image={image}
            alt="productImage"
          />
        </Image>
      </Box>
      {!cardContent ? (
        <CardContent className={classes.content}>
          <div
            style={
              isMobileMode
                ? {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }
                : {}
            }>
            <Box
              alignItems="start"
              display="flex"
              justifyContent="space-between"
              width="100%"
              gap={1}>
              <Typography className={classes.description}>
                {description}
              </Typography>
            </Box>
            <div className={classes.details}>
              <div
                className={classes.detailsItem}
                data-testid="product-card-stock-number">
                <span className={classes.detailsBold}>Stock Number:</span>
                <span className={classes.detailsNormal}>{stockNumber}</span>
              </div>
            </div>
          </div>
        </CardContent>
      ) : (
        cardContent
      )}
    </MuiCard>
  );
};

export default ValidateCard;
