// Lib
import React from 'react';
import { makeStyles } from '@mui/styles';

// Components
import { ReactComponent as Sorter } from '../../../assets/svg/sorter.svg';
import { ReactComponent as TabletSorter } from '../../../assets/svg/tabletSorter.svg';

// Hooks
import useUser from '../../../hooks/useUser';
import useUI from '../../../hooks/useUI';

const useStyles = makeStyles((theme) => ({
  root: ({ order, isKiteUser }) => {
    const isDescOrder = order === 'desc';
    const color = isKiteUser ? theme.palette.primary1 : theme.palette.primary2;
    return {
      cursor: 'pointer',

      '& path:nth-child(2)': {
        fill: isDescOrder ? color : theme.palette.grey2,
      },

      '& path:nth-child(3)': {
        fill: isDescOrder ? theme.palette.grey2 : color,
      },
    };
  },
}));

const SortButton = ({ order = 'asc', onClick }) => {
  const { isKiteUser } = useUser();
  const { isMobileMode, isTabletMode } = useUI();

  const SorterComponent = !isMobileMode && isTabletMode ? TabletSorter : Sorter;

  const classes = useStyles({ order, isKiteUser });

  return (
    <SorterComponent
      onClick={onClick}
      className={classes.root}
      data-testid={'sort-button'}
    />
  );
};

export default SortButton;
