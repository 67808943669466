// Libs
import React, { useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';

// Components
import { Typography } from '@mui/material';

// Actions
import { Open2Modal, Close2Modal } from '../../../actions/modal2';

const useAddToCartNotification = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const timerRef = useRef(null);

  useEffect(() => {
    return () => clearTimeout(timerRef?.current);
  }, []);

  const openModalWindow = useCallback(() => {
    dispatch(
      Open2Modal({
        id: 'added-to-cart',
        Component: () => (
          <Typography variant="h3">Added To Your Cart</Typography>
        ),
        closeIcon: false,
        styles: {
          width: 'max-content',
          minHeight: 'initial',
          color: theme.palette.darkGrey,
        },
        props: {
          transitionTimeout: { enter: 0, exit: 500 },
          paddingContent: {
            xl: '20px',
            lg: '20px',
            md: '20px',
          },
        },
      }),
    );
    timerRef.current = setTimeout(() => dispatch(Close2Modal()), 2500);
  }, [dispatch, theme.palette.darkGrey]);

  return { openModalWindow };
};

export default useAddToCartNotification;
