// Lib
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

// Actions
import {
  AddToCart,
  AddOne,
  RemoveOne,
  SetCount,
  DeleteProduct,
  DeleteAllProducts,
  SetCachedCountInCart,
} from '../../../actions/cart';

// Hooks
import useAddToCartNotification from './useAddToCartNotification';

// Selectors
import { productsObjSelector } from '../../../store/selectors';

const useCart = (id) => {
  // State and callback for 2-nd modal in Details modal window
  const dispatch = useDispatch();
  const { countForAdd } = useSelector((state) => ({
    countForAdd: state.cart?.forOrderCached?.[id] || 0,
  }));
  const [addingProgress, setAddingProgress] = useState(false);

  const { pathname } = useLocation();

  const { openModalWindow } = useAddToCartNotification();

  const isRecurringMaterials = useMemo(() => {
    if (
      pathname.includes('/order-history/recurring/') &&
      pathname.includes('/materials')
    ) {
      return true;
    }
  }, [pathname]);

  const {
    cart,
    modal,
    loading,
    productsObj = {},
    addressesCount,
  } = useSelector((state) => ({
    cart: state.cart,
    modal: state.modal,
    loading: state.isLoading,
    productsObj: productsObjSelector(state),
    addressesCount: state.cart?.shippingAddresses?.length || 1,
  }));

  const totalCountInCart = useMemo(() => {
    return cart?.forOrder?.length || 0;
  }, [cart]);

  const isProductInCart = useMemo(
    () => !!cart?.forOrder?.find(({ product }) => product === id),
    [cart, id],
  );

  const countInCart = useMemo(() => {
    return (
      cart?.forOrder?.find(({ product }) => {
        return product === id;
      })?.nums || 0
    );
  }, [cart, id]);

  const countInOrder = useMemo(() => {
    return countInCart * addressesCount;
  }, [addressesCount, countInCart]);

  const addInCart = useCallback(
    (product) => () => {
      dispatch(
        AddToCart({
          product: product.product_id,
          nums: countForAdd ? countForAdd : 1,
          withoutApi: isRecurringMaterials,
          openModalWindow: !isRecurringMaterials && openModalWindow,
          setAddingProgress,
        }),
      );
    },
    [dispatch, countForAdd, isRecurringMaterials, openModalWindow],
  );

  const addOne = useCallback(
    (product) => {
      const availableCount = product?.product_available_qty;
      if (!isProductInCart) {
        return () => {
          const newCount = countForAdd + 1;
          dispatch(
            SetCachedCountInCart({
              id,
              count: newCount > availableCount ? availableCount : newCount,
            }),
          );
        };
      } else {
        if (availableCount > countInCart) {
          return () => {
            dispatch(
              AddOne({
                product: product.product_id,
                nums: countInCart + 1,
                withoutApi: isRecurringMaterials,
                openModalWindow: !countInCart && openModalWindow,
              }),
            );
          };
        }
      }
    },
    [
      id,
      countForAdd,
      dispatch,
      openModalWindow,
      countInCart,
      isProductInCart,
      isRecurringMaterials,
    ],
  );

  const setCount = useCallback(
    ({ product_id }, newCount) => {
      const count = Math.round(newCount);

      if (!isProductInCart) {
        dispatch(SetCachedCountInCart({ id, count }));
      } else {
        dispatch(
          SetCount({
            product: product_id,
            nums: count,
            withoutApi: isRecurringMaterials,
            openModalWindow: !countInCart && !!count && openModalWindow,
          }),
        );
      }
    },
    [
      id,
      dispatch,
      countInCart,
      openModalWindow,
      isProductInCart,
      isRecurringMaterials,
    ],
  );

  const removeOne = useCallback(
    (product) => {
      if (!isProductInCart) {
        return () => {
          dispatch(
            SetCachedCountInCart({
              id,
              count: countForAdd === 0 ? 0 : countForAdd - 1,
            }),
          );
        };
      }
      if (!!countInCart && isProductInCart) {
        return () => {
          dispatch(
            RemoveOne({
              product: product?.product_id,
              nums: countInCart - 1,
              withoutApi: isRecurringMaterials,
            }),
          );
        };
      }
    },
    [
      dispatch,
      countInCart,
      isProductInCart,
      isRecurringMaterials,
      id,
      countForAdd,
    ],
  );

  const isEmptyCart = useMemo(
    () => cart?.forOrder && !cart?.forOrder?.length,
    [cart],
  );

  const isEmptyForLater = useMemo(() => !cart?.forLater?.length, [cart]);

  const someProductIsMoreThanInStock = useMemo(() => {
    return cart?.forOrder?.some((item) => {
      return productsObj[item.product]?.product_available_qty < item.nums;
    });
  }, [cart?.forOrder, productsObj]);

  const onDeleteProduct = useCallback(() => {
    dispatch(DeleteProduct(id));
  }, [dispatch, id]);

  const onDeleteAllProducts = useCallback(
    () => dispatch(DeleteAllProducts()),
    [dispatch],
  );

  const getProductCount = () => {
    return pathname.includes('review-order') ? countInOrder : countInCart;
  };

  return {
    cart,
    addInCart,
    isEmptyCart,
    isEmptyForLater,
    addOne,
    removeOne,
    setCount,
    count: isProductInCart ? countInCart : countForAdd,
    modal,
    isProductInCart,
    totalCountInCart,
    onDeleteProduct,
    onDeleteAllProducts,
    loading,
    productsObj,
    isProductMoreThanInStock:
      getProductCount() > productsObj[id]?.product_available_qty,
    someProductIsMoreThanInStock,
    addingProgress,
  };
};

export default useCart;
