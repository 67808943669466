// Libs
import { useState, useEffect, useCallback } from 'react';
import {
  useSearchBox,
  useMenu,
  useClearRefinements,
} from 'react-instantsearch-hooks';
import { useDispatch, useSelector } from 'react-redux';

// Hooks
import useUI from '../../../hooks/useUI';

// Actions
import { ToClearSearchLocalValue } from '../../../actions/support';
import { ClearSearchValue } from '../../../actions/search';

const useAlgolia = (onChangeSearchInput) => {
  const dispatch = useDispatch();
  const { isMobileMode } = useUI();
  const searchValue = useSelector((state) => state.search);
  const [oldSubTab, setOldSubTab] = useState(null);
  const [activeSubTab, setActiveSubTab] = useState(null);

  const { refine: refineSearch } = useSearchBox();
  const { refine: refineCategory } = useMenu({
    attribute: 'category',
  });
  const { refine: clearCategory } = useClearRefinements({
    includedAttributes: ['category'],
  });

  const onChangeSearchQuery = useCallback(
    (value) => {
      if (value) {
        setActiveSubTab(null);
      } else {
        setActiveSubTab(oldSubTab);
      }
      onChangeSearchInput(value);
    },
    [oldSubTab, onChangeSearchInput],
  );

  const onChangeCategory = useCallback(
    (value) => {
      if (searchValue) {
        dispatch(ToClearSearchLocalValue(true));
        // In mobile mode Search component is in modal,
        // so side effect inside it is not run while this modal
        // is not displayed, we should manually clear search value
        // to drop search filter and show proper results
        if (isMobileMode) {
          dispatch(ClearSearchValue());
        }
      }
      setActiveSubTab(value);
      setOldSubTab(value);
    },
    [searchValue, isMobileMode, dispatch],
  );

  useEffect(() => {
    refineSearch(searchValue);
    if (activeSubTab) {
      refineCategory(activeSubTab.toLowerCase().split(' ').join('-'));
    } else {
      clearCategory();
    }
  }, [searchValue, activeSubTab, refineSearch, refineCategory, clearCategory]);

  return {
    activeSubTab,
    onChangeSearchQuery,
    onChangeCategory,
  };
};

export default useAlgolia;
