export const EMPTY_CART = 'There are currently no items in your cart.';

export const NO_METHODS =
  'There are no ship methods available for the date selected. Please select a date that is further out or select a different shipping schedule.';

export const INVALID_PLACE_ORDER_DATE =
  'This request has missed the order cut-off date and time to meet the required date. Please return to the shipping details page and select a new schedule.';

export const SHIPPING_ADDRESS_BREADCRUMBS = [
  { to: '/cart', title: 'Total Items' },
  { to: '/cart/shipping-address', title: 'Shipping Address' },
];

export const SHIPPING_DETAILS_BREADCRUMBS = [
  ...SHIPPING_ADDRESS_BREADCRUMBS,
  { to: '/cart/shipping-details', title: 'Shipping Details' },
];

export const REVIEW_ORDER_BREADCRUMBS = [
  ...SHIPPING_DETAILS_BREADCRUMBS,
  { to: '/cart/review-order', title: 'Review Order' },
];

export const CHECKBOXES_WITH_DATE = [
  'Must Arrive By Date',
  'Must Arrive On Date',
  'Do Not Ship Until Date',
];

export const SHIPPING_SCHEDULES = [
  { label: 'Ship Right Now', value: 'Ship Right Now' },
  {
    label: 'Must Arrive By Date',
    value: 'Must Arrive By Date',
    disclaimer:
      'These orders can only ship Ground. Your order will arrive on this date or earlier, however, if your order requires approval your order may be delayed.',
  },
  {
    label: 'Must Arrive On Date',
    value: 'Must Arrive On Date',
  },
  {
    label: 'Do Not Ship Until Date',
    value: 'Do Not Ship Until Date',
    disclaimer:
      'Your order will not be processed and shipped until this date, however, if your order requires approval your order may be delayed.',
  },
  {
    label: 'Ship Weekly',
    value: 'Ship Weekly',
    disclaimer:
      'These orders can only ship Ground. Your order will be processed on the next upcoming business day of the occurrence you select and on-going unless you skip or cancel the recurring order. If you exceed the order quantity your order will require approval and may be delayed.  If the day selected falls on a holiday Lahlouh will process the order on the next business day.',
  },
  {
    label: 'Ship Monthly',
    value: 'Ship Monthly',
    disclaimer:
      'These orders can only ship Ground. Your order will be processed on the next upcoming business day of the occurrence you select and on-going unless you skip or cancel the recurring order. If you exceed the order quantity your order will require approval and may be delayed. If a date selected for each month falls on a weekend or holiday Lahlouh will process the order on the next business day.',
  },
];

export const SHIPPING_SCHEDULES_ONLY_FEDEXGROUND = [
  'Ship Weekly',
  'Ship Monthly',
];

export const SHIPPING_SCHEDULE_WITH_DATES = [
  'Must Arrive By Date',
  'Must Arrive On Date',
  'Do Not Ship Until Date',
];

export const SHIPPING_SCHEDULE_DATES_NEED_CHECK = [
  'Must Arrive By Date',
  'Must Arrive On Date',
];

export const DAY_NUMBERS = [
  { title: 'First', name: 'First', digit: 1 },
  { title: 'Second', name: 'Second', digit: 2 },
  { title: 'Third', name: 'Third', digit: 3 },
  { title: 'Fourth', name: 'Fourth', digit: 4 },
  { title: 'Fifth', name: 'Fifth', digit: 5 },
];

export const WEEK_DAYS = [
  { title: 'Monday', name: 'Monday' },
  { title: 'Tuesday', name: 'Tuesday' },
  { title: 'Wednesday', name: 'Wednesday' },
  { title: 'Thursday', name: 'Thursday' },
  { title: 'Friday', name: 'Friday' },
];

export const ORDER_INSTRUCTIONS = [
  { title: 'None', name: 'None' },
  {
    title: 'Enclosed Are The Materials You Requested. Thank You!',
    name: 'Enclosed Are The Materials You Requested. Thank You!',
  },
  {
    title: 'Please Let Me Know If You Need Additional Materials. Thank You!',
    name: 'Please Let Me Know If You Need Additional Materials. Thank You!',
  },
  {
    title: 'Great Meeting You!',
    name: 'Great Meeting You!',
  },
  {
    title: 'Exhibit Materials. Please Hold For Gilead Sciences.',
    name: 'Exhibit Materials. Please Hold For Gilead Sciences.',
  },
  {
    title: 'No Signature Required.',
    name: 'No Signature Required.',
  },
  {
    title: 'Shipping To Public Storage.',
    name: 'Shipping To Public Storage.',
  },
  {
    title: 'Items Are Needed For An Event/Conference.',
    name: 'Items Are Needed For An Event/Conference.',
  },
];

export const wrongFormat = 'Wrong format. Please select .pdf file.';

export const USER_VIEW_WITHOUT_ALL_LIMITS = ['Gilead Admin Portal View'];
export const USER_VIEW_WITHOUT_QUANTITY_LIMIT = [];

export const PI_PRODUCT_SUBTYPES = [
  'Prescribing Information',
  'Prescribing Information - Full Prescribing Information',
];
