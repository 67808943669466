// Libs
import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';

// Components
import Input from '../../../Field/Input';
import FooterButtons from '../../../Modal/FooterButtons';

// Hooks
import useUI from '../../../../hooks/useUI';

const useStyles = makeStyles((theme) => ({
  input: {
    [theme.breakpoints.down('md')]: {
      '& textarea': {
        fontSize: 14,
        lineHeight: '18px',
      },
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        '& textarea': {
          fontSize: 14,
          lineHeight: '18px',
        },
      }),
    },
  },
  buttons: {
    paddingBottom: 0,
    [theme.breakpoints.down('lg')]: {
      '& #accept-button': {
        width: 110,
      },
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      '& #accept-button': {
        width: 144,
        height: 44,
      },
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        '& #accept-button': {
          width: 110,
        },
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        paddingLeft: 0,
        '& #accept-button': {
          width: 144,
          height: 44,
        },
      }),
    },
  },
}));

const KitNameModal = ({
  header,
  entityId,
  oldName,
  onClickSave,
  onClickCancel,
  cleanUp,
}) => {
  const dispatch = useDispatch();
  const [name, setName] = useState(oldName);
  const { kitActionErrorMessage } = useSelector((state) => state.kits);
  const classes = useStyles();
  const { isMobileMode } = useUI();

  useEffect(() => {
    return cleanUp;
  }, [dispatch, cleanUp]);

  return (
    <>
      <Typography variant="h3" mb={3}>
        {header || 'Add To My Kits'}
      </Typography>
      <Input
        id="kit-name-input"
        className={classes.input}
        onChange={({ value }) => {
          setName(value);
        }}
        value={name}
        maxLength={256}
        label="Kit Nickname"
        error={kitActionErrorMessage}
        required
        multiline={isMobileMode}
        rows={3}
        data-testid="kit-name-modal-input"
      />
      <FooterButtons
        onReject={onClickCancel}
        acceptTitle="Save"
        rejectTitle="Cancel"
        onAccept={() => onClickSave(entityId, name)}
        className={classes.buttons}
      />
    </>
  );
};

export default KitNameModal;
