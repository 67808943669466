// Lib
import React from 'react';
import { makeStyles } from '@mui/styles';

// Components
import Feather from '../../../Feather';

// Styles
import { colors } from '../../../../styles/palette';

const useStyles = makeStyles((theme) => ({
  errorContainer: {
    alignItems: 'baseline',
    display: 'flex',
  },
  error: ({ fontSize }) => ({
    color: colors.gilead.primary1,
    fontSize,
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  }),
}));

const Error = ({ error, fontSize = 16 }) => {
  const classes = useStyles({ fontSize });

  return (
    <div
      className={classes.errorContainer}
      data-testid="address-import-message">
      <Feather
        style={{ minWidth: 20, marginTop: 3, cursor: 'initial' }}
        nativeColor
        type="error"
      />
      <span className={classes.error}>{error}</span>
    </div>
  );
};

export default Error;
