// Lib
import { useMemo } from 'react';

const useMethodApprovalInfo = (data) => {
  const approver = useMemo(
    () =>
      data?.approvers?.find(
        ({ approver_id }) => approver_id === data.aproved_by,
      ) || {},
    [data],
  );

  const status = useMemo(() => {
    if (data.id_approved === data.id_requested) {
      return 'Approved';
    }

    return 'Modified and approved';
  }, [data]);

  return {
    approvalDate: data?.approval_date,
    status,
    approverEmail: approver?.approver_email,
    approverFullName: approver.approver_full_name,
  };
};

export default useMethodApprovalInfo;
