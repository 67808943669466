// Lib
import React, { useMemo } from 'react';
import classnames from 'classnames';
import { makeStyles } from '@mui/styles';

// Components
import CountButton from '../../Button/CountButton';
import CountButtonLabel from '../../Labels/CountButtonLabel';

// Constants
import {
  MORE_THAN_IN_STOCK_MESSAGE,
  OVER_LIMIT_MESSAGE,
} from '../../../constants/products';
import useUI from '../../../hooks/useUI';
import useUser from '../../../hooks/useUser';

const useStyles = makeStyles((theme) => ({
  container: ({ detailsMode }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexDirection: detailsMode ? 'row' : 'column',
    gap: detailsMode ? 10 : 4,
    padding: 0,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      gap: 10,
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        flexDirection: 'row',
        gap: 10,
      }),
    },
  }),
  qtyButtonLabel: ({ isTabletMode, isMobileMode, detailsMode }) => ({
    [theme.breakpoints.down('lg')]: {
      position:
        !isMobileMode && isTabletMode && !detailsMode ? 'absolute' : 'static',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        position:
          !isMobileMode && isTabletMode && !detailsMode ? 'absolute' : 'static',
      }),
    },
  }),
  qtyButtonLabelContainer: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  countButton: ({ detailsMode }) => ({
    width: 130,
    height: 36,
    [theme.breakpoints.down('lg')]: {
      width: detailsMode ? 200 : 130,
      height: detailsMode ? 44 : 34,
    },
    [theme.breakpoints.down('md')]: {
      width: detailsMode ? 140 : 95,
      height: detailsMode ? 44 : 24,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        width: detailsMode ? 200 : 130,
        height: detailsMode ? 44 : 34,
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        width: detailsMode ? 140 : 95,
        height: detailsMode ? 44 : 24,
      }),
    },
  }),
}));

const CartProductQtyButtons = ({
  product,
  onAdd,
  onRemove,
  onSetCount,
  count,
  detailsMode, // in products modal details
  className,
  disabledCountBtn = false,
  isOverLimit = false,
  shippingAddresses = 1,
}) => {
  const { isMobileMode, isTabletMode } = useUI();
  const { isKiteUser, userWithoutAllLimits, userWithoutQuantityLimit } =
    useUser();
  const classes = useStyles({ detailsMode, isMobileMode });
  const { product_available_qty: availableQuantity } = product || {};

  const errorLabel = useMemo(() => {
    if (count * shippingAddresses > availableQuantity) {
      return MORE_THAN_IN_STOCK_MESSAGE;
    } else if (
      isOverLimit &&
      !(userWithoutAllLimits || userWithoutQuantityLimit)
    ) {
      return OVER_LIMIT_MESSAGE;
    } else return null;
  }, [
    count,
    availableQuantity,
    isOverLimit,
    shippingAddresses,
    userWithoutAllLimits,
    userWithoutQuantityLimit,
  ]);

  const labelVariant = useMemo(() => {
    let variant;

    if (detailsMode) {
      variant = isTabletMode ? 'textLg' : 'textSm';
    } else {
      variant = isMobileMode ? 'textXs' : 'textSm';
    }

    if (!isKiteUser) {
      variant += 'Semibold';
    }
    return variant;
  }, [detailsMode, isKiteUser, isMobileMode, isTabletMode]);

  return (
    <div className={classnames(classes.container, className)}>
      <CountButton
        product={product}
        count={count}
        onAdd={onAdd}
        onRemove={onRemove}
        onSetCount={onSetCount}
        error={!!errorLabel}
        limitValue={true}
        className={classes.countButton}
        disabled={disabledCountBtn}
      />
      {errorLabel &&
        (!detailsMode && isTabletMode && !isMobileMode ? (
          <div className={classes.qtyButtonLabelContainer}>
            <CountButtonLabel
              variant={labelVariant}
              className={classes.qtyButtonLabel}>
              {errorLabel}
            </CountButtonLabel>
          </div>
        ) : (
          <CountButtonLabel
            variant={labelVariant}
            className={classes.qtyButtonLabel}>
            {errorLabel}
          </CountButtonLabel>
        ))}
    </div>
  );
};

export default CartProductQtyButtons;
