import React, { useCallback, useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Tooltip, Popper, ClickAwayListener } from '@mui/material';
import { Rating as MuiRating } from '@mui/material';
import Typography from '@mui/material/Typography';

// Components
import Button from '../Button';
import Feather from '../Feather';
import MyRating from './MyRating';

// Constants
import { RATING_DISABLED } from '../../constants/products';

// Hooks
import useRating from '../../hooks/useRating';
import useUI from '../../hooks/useUI';

// Styles
import { colors } from '../../styles/palette';

const useStyles = makeStyles((theme) => ({
  button: {
    padding: theme.spacing(1),
    minWidth: 10,
  },
  count: {
    color: theme.palette.grey2,
    fontSize: 14,
    marginLeft: theme.spacing(0.5),
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      marginLeft: 2,
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 12,
        marginLeft: 2,
      }),
    },
  },
  commonMark: {
    fontSize: 14,
    marginLeft: 6,
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      marginLeft: 3,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 12,
        marginLeft: 3,
      }),
    },
  },
  userIcon: {
    [theme.breakpoints.down('md')]: {
      height: 12,
      width: 12,
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        height: 12,
        width: 12,
      }),
    },
  },
}));

const Rating = ({ className, product, withTooltip = true }) => {
  const { isMobileMode } = useUI();
  const classes = useStyles();
  const { familyRate, canRate } = useRating(product);
  const [isTooltipActive, setIsTooltipActive] = useState(false);

  const onToggleTooltip = useCallback(
    () => setIsTooltipActive((prevState) => !prevState),
    [setIsTooltipActive],
  );

  const onCloseTooltip = () => {
    setIsTooltipActive(false);
  };

  const commonRate = useMemo(() => {
    if (familyRate?.rating) {
      return familyRate?.rating.toFixed(1);
    }
    return 0;
  }, [familyRate]);

  return (
    <Box id="rating" display="flex" alignItems="center">
      <MuiRating
        precision={0.1}
        className={className}
        disabled
        name="simple-controlled"
        value={familyRate?.rating}
      />
      <Typography
        className={classes.commonMark}
        color={colors.grey2}
        component="legend">
        {`${commonRate}/5`}
      </Typography>
      {!isMobileMode && withTooltip && (
        <ClickAwayListener onClickAway={onCloseTooltip}>
          <div>
            <Tooltip
              onClose={onCloseTooltip}
              open={isTooltipActive}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={
                canRate ? <MyRating product={product} /> : RATING_DISABLED
              }>
              <Button
                className={classes.button}
                variant="text"
                onClick={onToggleTooltip}>
                <Feather
                  nativeColor
                  type={isTooltipActive ? 'arrowUpSmall' : 'arrowDownSmall'}
                />
              </Button>
            </Tooltip>
          </div>
        </ClickAwayListener>
      )}
      <Box display="flex" alignItems="center">
        <Feather className={classes.userIcon} nativeColor type="user" />
        <div className={classes.count}>{familyRate?.['users rated']}</div>
      </Box>
    </Box>
  );
};

export default Rating;
