// Lib
import React from 'react';
import FAQCollapsibleCard from '../../../components/Support/FAQCollapsibleCard/FAQCollapsibleCard';
import { useInfiniteHits } from 'react-instantsearch-hooks-web';
import InfiniteScroll from 'react-infinite-scroll-component';
import { makeStyles } from '@mui/styles';

// Components
import TotalQtyLabel from '../../../components/Labels/TotalQtyLabel';

const useStyles = makeStyles((theme) => ({
  kitsQtyLabel: {
    marginBottom: theme.spacing(2),
  },
}));

const FAQPage = ({ isMobileMode }) => {
  const { hits, results, showMore, isLastPage } = useInfiniteHits();

  const classes = useStyles();

  return (
    <>
      <TotalQtyLabel
        qty={results.nbHits}
        type={'item'}
        className={classes.kitsQtyLabel}
      />
      <InfiniteScroll
        dataLength={hits.length}
        next={showMore}
        hasMore={!isLastPage}
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          marginInline: `${isMobileMode ? '-12px' : '-20px'}`,
          marginTop: `${isMobileMode ? '-12px' : '-20px'}`,
          paddingInline: `${isMobileMode ? '12px' : '20px'}`,
          paddingTop: `${isMobileMode ? '12px' : '20px'}`,
          paddingBottom: '50px',
        }}>
        {hits.map((item, index) => (
          <FAQCollapsibleCard key={item.objectID || index} item={item} />
        ))}
      </InfiniteScroll>
    </>
  );
};

export default FAQPage;
