// Lib
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { ClearApiError } from '../actions/apiError';

const API_ERROR_KEYS = [
  'saveCatalogView',
  'getFDMDocument',
  'saveMaterialsFilters',
  'getFrequentlyOrderedProducts',
  'getAvailableProductsSortedByOrder',
  'deleteAddress',
  'saveAddressBookFilters',
  'deleteProduct',
  'deleteAllProducts',
  'addToCart',
  'updateProduct',
  'setFavoriteValue',
  'skipRecurring',
  'removeProductFromKit',
  'updateKitProductQty',
  'reorderKit',
  'submitNewKitProducts',
];

const useApiError = () => {
  const dispatch = useDispatch();
  const apiError = useSelector((state) => state.apiError);
  const error = useMemo(() => {
    const errorKey = API_ERROR_KEYS.find((key) => apiError?.[key]);

    return apiError?.[errorKey];
  }, [apiError]);

  const clearApiError = useCallback(
    () => dispatch(ClearApiError()),
    [dispatch],
  );

  return { apiError: error, clearApiError };
};

export default useApiError;
