// Libs
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Actions
import { Reorder, SetOrderConfirmation } from '../../../actions/orders';

const useReorder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navigateToCart = () => {
    navigate('/cart');
  };

  const cancelReorder = () => dispatch(SetOrderConfirmation(false));

  const confirmReorder = () => dispatch(SetOrderConfirmation(true));

  const onReorder = (order) => {
    dispatch(Reorder({ order, cancelReorder, confirmReorder, navigateToCart }));
  };

  return {
    onReorder,
  };
};

export default useReorder;
