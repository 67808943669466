// Lib
import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: '0px 5px 17px rgba(48, 85, 107, 0.2)',
    borderRadius: 16,
    padding: theme.spacing(2),
    width: 267,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1.5),
      width: 'calc(50% - 8px)',
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        padding: theme.spacing(1.5),
        width: 'calc(50% - 8px)',
      }),
    },
  },
}));

const InfoCard = ({ title, children, dataTestId = '' }) => {
  const classes = useStyles();

  return (
    <div data-testid={dataTestId} className={classes.root}>
      <Typography mb={1} variant="h3" fontSize={{ xs: 14, md: 18 }}>
        {title}
      </Typography>
      {children}
    </div>
  );
};

export default InfoCard;
