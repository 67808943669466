export const AUTH_URLS = {
  obtainToken: '/auth/obtain_token/',
  refreshToken: '/auth/refresh_token/',
  getUser: '/users/details/',
};

export const USER_URLS = {
  getUser: '/users/details/',
  saveSettings: '/users/profile_settings/',
};

export const PRODUCTS_URLS = {
  getProducts: '/products/',
  getFrequentlyOrderedProducts: '/orders/frequently_ordered/',
  getAvailableProductsSortedByOrder:
    '/orders/frequently_ordered/?sort_by_order=true',
  getProductDetails: (productId) => `/products/${productId}/`,
  getFDMDoc: (id) => `/products/fdm_document/${id}/`,
  updateProductFavoriteValue: (productId) => `products/favourite/${productId}/`,
};

export const CART_URLS = {
  getProducts: '/shopping_cart/products/',
  addInCart: (id) => `/shopping_cart/${id}/`,
  updateProduct: (id) => `/shopping_cart/${id}/`,
  deleteProduct: (id) => `/shopping_cart/${id}/`,
  deleteAllProducts: '/shopping_cart/products/',
  saveOverLimitRequest: '/shopping_cart/products/additional_info/',
  getShippingMethods: '/shipment_method/',
  createOrder: '/orders/',
  createRecurringOrder: '/recurring_orders/',
  uploadEventFile: '/orders/upload_shipping_label/',
  getSaveForLaterProducts: 'shopping_cart/products/save_for_later/',
  addProductToSaveForLater: (productId) =>
    `shopping_cart/${productId}/add_to_save_for_later/`,
  deleteAllProductsFromSaveForLater: `shopping_cart/products/save_for_later/`,
  deleteProductFromSaveForLater: (productId) =>
    `shopping_cart/${productId}/add_from_save_for_later_to_cart_or_delete/`,
  putSaveForLaterProductInCart: (productId) =>
    `shopping_cart/${productId}/add_from_save_for_later_to_cart_or_delete/`,
};

export const ADDRESS_BOOK_URLS = {
  saveAddress: '/address/',
  saveAddresses: '/address/multiple_create/',
  getAddressBook: '/address/',
  deleteAddress: (id) => `/address/${id}/`,
  makeMainAddress: (id) => `/address/${id}/set_as_main/`,
  validateAddress: '/address/validate/',
  editAddress: (id) => `/address/${id}/`,
  getCountries: '/address/countries/',
  getStates: '/address/states/',
  saveFilters: '/users/profile_settings/',
  uploadCSVFile: '/address/csv_validate/',
};

export const ORDERS_URLS = {
  getOrders: '/orders/',
  getRecurringOrders: '/recurring_orders/',
  updateRecurringOrder: (orderId) => `recurring_orders/${orderId}/`,
  skipRecurringOrder: (orderId) => `recurring_orders/${orderId}/next_run/`,
  getOrderDetails: (orderId) => `/orders/${orderId}/`,
  getRecurringOrderDetails: (orderId) => `/recurring_orders/${orderId}/`,
  getUnavailableProductsFromOrder: (orderId) =>
    `/orders/${orderId}/get_unavailable_products_from_order/`,
};

export const APPROVALS_URLS = {
  getOrder: (id) => `orders/${id}/get_order_approvals/`,
  saveApprovals: '/orders_approvals/approve/',
};

export const SUPPORT_URLS = {
  getContacts: '/support/contacts/',
  getAlgoliaAppInfo: '/support/support_info/',
  getHolidays: 'support/holidays/',
  getShippingNotice: '/support/additional_configuration',
};

export const KITS_URLS = {
  getMyKits: 'kits/',
  addToMyKits: (orderId) => `/orders/${orderId}/add_to_kit/`,
  deleteKit: (kitId) => `/kits/${kitId}/`,
  deleteAllKits: '/kits/',
  kitDetails: (kitId) => `/kits/${kitId}/`,
  addKitToCart: (kitId) => `/kits/${kitId}/add_to_shop_cart/`,
  editKit: (kitId) => `/kits/${kitId}/`,
  updateKitProductQty: (kitId, productId) =>
    `kits/${kitId}/product/${productId}/`,
  addNewKitProduct: (kitId, productId) => `kits/${kitId}/product/${productId}/`,
  removeProductFromKit: (kitId, productId) =>
    `kits/${kitId}/product/${productId}/`,
};

export const RATING = {
  setRating: (productId) => `products/${productId}/rating/`,
};

export const VALIDATE_URLS = {
  validate: (productId) => `products/validate/${productId}/`,
};

export const SSO_LOGIN_PAGE = '/auth/sso/signin';
export const HOME_PAGE = '/materials';

const URL = document?.getElementById('base-url')?.dataset?.baseUrl;

export const BASE_URL = URL?.includes('http')
  ? URL
  : 'https://gmop-dev.lahlouhonline.com';
