// Lib
import React from 'react';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

// Components
import Button from '../index';
import Feather from '../../Feather';

const useStyles = makeStyles((theme) => ({
  button: {
    fontSize: 14,
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      padding: '2px',
    },
  },
}));

const EditRequestButton = ({ onClick = () => {}, title, className }) => {
  const classes = useStyles();

  return (
    <Button
      onClick={onClick}
      className={classnames(classes.button, className)}
      variant="text">
      <Feather type="edit" style={{ marginRight: 5 }} />
      {title}
    </Button>
  );
};

export default EditRequestButton;
