// Lib
import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

// Styles
import { colors } from '../../../styles/palette';

// Hooks
import useUser from '../../../hooks/useUser';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'inline-flex',
    width: 90,
    height: 90,
  },
  progress: ({ isKiteUser }) => ({
    color: isKiteUser ? colors.kite.primary1 : colors.gilead.primary2,
    '& svg': {
      color: isKiteUser ? colors.kite.primary1 : colors.gilead.primary2,
      '& circle': {
        color: isKiteUser ? colors.kite.primary1 : colors.gilead.primary2,
      },
    },
  }),
  absoluteProgress: {
    color: `${theme.palette.grey1} !important`,
    position: 'absolute',
    zIndex: -1,
    '& svg': {
      color: `${theme.palette.grey1} !important`,
      '& circle': {
        color: `${theme.palette.grey1} !important`,
      },
    },
  },
}));

function ProgressSpinner({ value, className }) {
  const { isKiteUser } = useUser();
  const classes = useStyles({ isKiteUser });

  return (
    <div className={classnames(classes.root, className)}>
      <CircularProgress
        size={90}
        thickness={2}
        className={classes.progress}
        variant="determinate"
        value={value}
      />
      <CircularProgress
        size={90}
        thickness={2}
        className={classnames(classes.progress, classes.absoluteProgress)}
        variant="determinate"
        value={100}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Typography variant="h3" component="div" color="#929A92">{`${Math.round(
          value,
        )}%`}</Typography>
      </Box>
    </div>
  );
}

export default ProgressSpinner;
