// Libs
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { SetCartProducts } from '../actions/cart';

// Selectors
import { productsObjSelector, forOrderSelector } from '../store/selectors';

// Utils
import { isObjEmpty } from '../utils/object';

const sortByOwner =
  (productsObj) =>
  ({ product: productA }, { product: productB }) => {
    const a = productsObj[productA]?.owner?.toLowerCase();
    const b = productsObj[productB]?.owner?.toLowerCase();
    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }
    return 0;
  };

const useCartSorting = () => {
  const dispatch = useDispatch();
  const { productsObj, forOrder } = useSelector((state) => ({
    productsObj: productsObjSelector(state),
    forOrder: forOrderSelector(state),
  }));

  useEffect(() => {
    if (isObjEmpty(productsObj)) {
      return;
    }
    const sortedProducts = [...forOrder].sort(sortByOwner(productsObj));
    dispatch(SetCartProducts(sortedProducts));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forOrder?.length, productsObj]);
};

export default useCartSorting;
