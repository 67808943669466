// Lib
import React from 'react';
import { makeStyles } from '@mui/styles';

// Components
import CountButton from '../../../../Button/CountButton';

// Hooks
import useCountComponent from './useCountComponent';

const useStyles = makeStyles((theme) => ({
  button: {
    '& #count-button': {
      width: 130,
      '& svg': {
        fontSize: 20,
      },
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 'auto',
      '& #count-button': {
        height: 24,
        width: ({ isGridView }) => (isGridView ? 120 : 95),
      },
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        marginTop: 'auto',
        '& #count-button': {
          height: 24,
          width: ({ isGridView }) => (isGridView ? 120 : 95),
        },
      }),
    },
  },
}));

const CountComponent = ({ orderItem, product, order, isGridView = false }) => {
  const classes = useStyles({ isGridView });
  const { onAdd, onRemoveOne, onSetCount, onRemoveProductWithZeroCount } =
    useCountComponent(order, orderItem, product);

  return (
    <CountButton
      className={classes.button}
      product={product}
      count={orderItem?.qty_ordered}
      onAdd={onAdd}
      onSetCount={onSetCount}
      onRemove={onRemoveOne}
      onClickAway={onRemoveProductWithZeroCount}
    />
  );
};

export default CountComponent;
