// Lib
import { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { SetSearchValue, ClearSearchValue } from '../actions/search';
import { SetSearchActive } from '../actions/isSearchActive';

const useSearch = () => {
  const [isActive, setIsActive] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobileMode = useMediaQuery(theme.breakpoints.down('md'));

  const searchValue = useSelector((state) => state.search);

  useEffect(() => {
    if (!isMobileMode) {
      setIsActive(false);
    }
  }, [isMobileMode, setIsActive]);

  useEffect(() => {
    if (isMobileMode) {
      dispatch(SetSearchActive(isActive));
    }
  }, [dispatch, isActive, isMobileMode]);

  const onToggleSearchInput = useCallback(
    () => setIsActive((prevState) => !prevState),
    [setIsActive],
  );

  const onChange = useCallback(
    (value) => dispatch(SetSearchValue(value)),
    [dispatch],
  );

  const onClearSearch = useCallback(
    (clearSearch) => {
      clearSearch && clearSearch('');
      dispatch(ClearSearchValue());
    },
    [dispatch],
  );

  return {
    onToggleSearchInput,
    isActive,
    searchValue,
    onChange,
    onClearSearch,
  };
};

export default useSearch;
