// Lib
import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

// Components
import RemovedProducts from '../../../components/Cart/RemovedProducts';

// Actions
import {
  DeleteUnavailableProducts,
  DeleteUnavailableLaterProducts,
} from '../../../actions/cart';
import { OpenModal } from '../../../actions/modal';

// Utils
import { isObjEmpty } from '../../../utils/object';
import isProductAvailable from '../../../utils/isProductAvailable';

// Selectors
import { productsObjSelector } from '../../../store/selectors';

const useProductsForRemoving = () => {
  const { pathname, state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { productsObj, cart, loading } = useSelector((state) => ({
    productsObj: productsObjSelector(state),
    cart: state.cart,
    loading: state.isLoading,
  }));

  const { unavailableLaterProducts, forOrder } = cart || {};

  const unavailableCartProducts = useMemo(() => {
    if (loading || isObjEmpty(productsObj)) {
      return [];
    }

    return forOrder?.filter(({ product: productId }) => {
      const product = productsObj?.[productId];
      return !isProductAvailable(product);
    });
  }, [forOrder, productsObj, loading]);

  const unavailableProducts = useMemo(() => {
    return pathname === '/cart'
      ? [
          ...unavailableCartProducts,
          ...unavailableLaterProducts.filter(
            (laterItem) =>
              !unavailableCartProducts.some(
                (cartItem) => cartItem.product === laterItem.product,
              ),
          ),
        ]
      : unavailableCartProducts;
  }, [pathname, unavailableCartProducts, unavailableLaterProducts]);

  useEffect(() => {
    if (
      unavailableCartProducts?.length &&
      pathname.includes('/cart') &&
      pathname !== '/cart' &&
      !cart.placedOrder.id &&
      !loading // prevent redirection after placing order before set placed order
    ) {
      return navigate('/cart');
    }

    if (!!unavailableProducts?.length) {
      dispatch(DeleteUnavailableProducts(unavailableCartProducts));
      dispatch(DeleteUnavailableLaterProducts(unavailableLaterProducts));

      if (!state?.navigateFrom.includes('kits')) {
        dispatch(
          OpenModal({
            id: 'removed_products',
            Component: () => <RemovedProducts products={unavailableProducts} />,
            styles: {
              width: 'min(85vw, 600px)',
            },
          }),
        );
      }
    }
  }, [
    navigate,
    dispatch,
    unavailableCartProducts,
    unavailableProducts,
    productsObj,
    pathname,
    loading,
    cart.placedOrder,
    state,
    unavailableLaterProducts,
  ]);

  return { unavailableProducts };
};

export default useProductsForRemoving;
