// Lib
import { createReducer } from '@reduxjs/toolkit';

// Actions
import { SetAuthData, ClearTokenData, ClearSsoData } from '../actions/auth';

import { MOCK_AUTH } from '../mockData/auth';

const isTestEnv = process.env.NODE_ENV === 'test';

export const initialState = isTestEnv
  ? MOCK_AUTH
  : {
      accessToken: null,
      refreshToken: null,
      sso: null,
    };

export default createReducer(initialState, {
  [SetAuthData]: (state, { payload }) => {
    return {
      accessToken: payload.access_token,
      refreshToken: payload.refresh_token,
      sso: !!payload?.sso,
    };
  },
  [ClearTokenData]: (state) => ({ ...initialState, sso: state?.sso }),
  [ClearSsoData]: (state) => ({ ...state, sso: null }),
});
