// Lib
import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch-hooks';

//Components
import Layout from '../../components/Layout';
import PagesSwitcher from '../../components/Support/PagesSwitcher/PagesSwitcher';
import SubnavigationBar from '../../components/Support/SubnavigationBar/SubnavigationBar';
import Search from '../../components/Search';
import SupportPage from './SupportPage/SupportPage';
import FAQPage from './FAQPage/FAQPage';
import Spinner from '../../components/Spinner';

// Hooks
import useSearch from '../../hooks/useSearch';
import useUI from '../../hooks/useUI';
import useAlgolia from './hooks/useAlgolia';
import useSupportPageTabs from './hooks/useSupportPageTabs';

// Utils
import { FAQSubnavigationTabs } from '../../constants/support';
import { GetContacts, GetAlgoliaAppInfo } from '../../actions/support';

const useStyles = makeStyles((theme) => ({
  layout: {
    paddingTop: 0,
  },
  bar: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    zIndex: 100,
    position: 'sticky',
    padding: '40px 26px 10px',
    marginInline: '-26px',
    backgroundColor: 'white',
    top: '80px',
    [theme.breakpoints.down('lg')]: {
      top: '70px',
    },
    [theme.breakpoints.down('md')]: {
      top: '54px',
      padding: '40px 12px 10px',
      marginInline: '-12px',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        top: '70px',
      }),
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        top: '40px',
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        top: '20px',
        padding: '40px 12px 10px',
        marginInline: '-12px',
      }),
    },
  },
  title: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        fontSize: 28,
        marginBottom: theme.spacing(2),
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 22,
        marginBottom: theme.spacing(1),
      }),
    },
  },
  switcherContainer: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(2.5),
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        marginBottom: theme.spacing(2.5),
      }),
    },
  },
}));

const SupportPages = () => {
  const { isMobileMode, isDesktopMode } = useUI();
  const { activeTab, setActiveTab } = useSupportPageTabs();
  const { onChange: onChangeSearchInput, onClearSearch } = useSearch();
  const classes = useStyles({ activeTab });

  const { activeSubTab, onChangeSearchQuery, onChangeCategory } =
    useAlgolia(onChangeSearchInput);

  return (
    <Layout
      className={{ layout: classes.layout }}
      onChangeSearch={onChangeSearchQuery}>
      <div className={classes.bar}>
        {!isDesktopMode && (
          <Typography
            className={classes.title}
            variant={isMobileMode ? 'h1' : 'h2'}>
            Support
          </Typography>
        )}
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          className={activeTab === 1 && classes.switcherContainer}>
          <PagesSwitcher activeTab={activeTab} setActiveTab={setActiveTab} />
          {activeTab === 1 && !isMobileMode && (
            <Search
              onClearSearch={onClearSearch}
              onChange={onChangeSearchQuery}
            />
          )}
        </Box>
        {activeTab === 1 && (
          <SubnavigationBar
            items={FAQSubnavigationTabs}
            activeItem={activeSubTab}
            setActiveItem={onChangeCategory}
          />
        )}
      </div>
      <Routes>
        <Route path="/" element={<SupportPage />}></Route>
        <Route
          path="/FAQ"
          element={<FAQPage isMobileMode={isMobileMode} />}></Route>
      </Routes>
    </Layout>
  );
};

const Support = () => {
  const dispatch = useDispatch();
  const { algoliaAppInfo, contacts } = useSelector((state) => state.support);
  const { isLoading } = useSelector((state) => state);

  const searchClient = algoliasearch(
    algoliaAppInfo?.algolia_app_id,
    algoliaAppInfo?.algolia_search_key,
  );

  useEffect(() => {
    dispatch(GetContacts());
    dispatch(GetAlgoliaAppInfo());
  }, [dispatch]);

  return (
    <>
      {isLoading ? (
        <Layout>
          <Spinner />
        </Layout>
      ) : (
        algoliaAppInfo &&
        contacts && (
          <InstantSearch
            searchClient={searchClient}
            indexName={algoliaAppInfo?.algolia_app_index_name}>
            <SupportPages />
          </InstantSearch>
        )
      )}
    </>
  );
};

export default Support;
