// Lib
import { createReducer } from '@reduxjs/toolkit';

// Actions
import { SetFilters, ChangeFilter, ClearFilters } from '../actions/filters';
import { UserLogOut } from '../actions/user';

const filters = ['brand_category', 'owner', 'audience', 'indication'];

export const initialState = {
  favorites: false,
  frequentlyOrdered: false,
  available: [
    { title: 'All Orderable Materials', checked: false },
    { title: 'All Active Materials', checked: false },
    { title: 'Out Of Stock', checked: false },
    { title: 'Expired In The Last 30 Days', checked: false },
    { title: 'Withdrawn In The Last 30 Days', checked: false },
  ],
  sorting: {
    order: 'desc',
    orderBy: 'date',
    items: [
      { title: 'Date', label: 'Sort By Date' },
      { title: 'Name', label: 'Sort By Name' },
      { title: 'Orders', label: 'Sort By Orders' },
      { title: 'Rating', label: 'Sort By Rating' },
      { title: 'Stock Number', label: 'Sort By Stock Number' },
    ],
  },
  brand_category: [],
  owner: [],
  audience: [],
  indication: [],
  loaded: false,
};

export default createReducer(initialState, {
  [SetFilters]: (state, { payload }) => {
    return {
      ...state,
      ...payload,
      sorting: {
        ...state.sorting,
        orderBy: payload?.sorting?.orderBy || state.sorting.orderBy,
        order: payload?.sorting?.order || state.sorting.order,
      },
    };
  },
  [ChangeFilter]: (state, { payload }) => {
    return {
      ...state,
      ...payload,
    };
  },
  [ClearFilters]: (state) => {
    const newFilters = {
      ...initialState,
      loaded: true,
      sorting: state.sorting,
    };
    filters.forEach((filter) => {
      newFilters[filter] = state[filter].map((item) => ({
        ...item,
        checked: false,
      }));
    });

    return newFilters;
  },
  [UserLogOut]: () => initialState,
});
