// Lib
import React, { useCallback } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Components
import Button from '../Button';
import OrderList from './OrderList';
import TotalQtyLabel from '../../components/Labels/TotalQtyLabel';

// Hooks
import useUser from '../../hooks/useUser';

const useStyles = makeStyles((theme) => ({
  noMatchesLabel: {
    color: theme.palette.black,
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '22px',
  },
  resultsLabel: {
    color: theme.palette.darkGrey,
    fontWeight: ({ isKiteUser }) => (isKiteUser ? 400 : 600),
    fontSize: '16px',
    lineHeight: '20px',
  },
  emptyOrdersContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 'calc(100vh - 344px)',
  },
  emptyOrdersHistory: {
    marginBottom: theme.spacing(5),
    width: 540,
    [theme.breakpoints.down('md')]: {
      width: 290,
    },
  },
  emptyOrdersHistoryButton: {
    width: 146,
    minHeight: 46,
    [theme.breakpoints.down('lg')]: {
      width: 170,
      minHeight: 44,
    },
  },
}));

const OrderHistory = ({ orders, isRecurring = false }) => {
  const { isKiteUser } = useUser();
  const classes = useStyles({
    isKiteUser,
  });
  const search = useSelector((state) => state.search);
  const { general: generalOrders, recurring: recurringOrders } = useSelector(
    (state) => state.orders,
  );
  const navigate = useNavigate();

  const navigateToMaterials = useCallback(
    () => navigate('/materials'),
    [navigate],
  );

  if (
    (isRecurring ? recurringOrders : generalOrders)?.length === 0 &&
    !search
  ) {
    return (
      <div className={classes.emptyOrdersContainer}>
        <Typography
          className={classes.emptyOrdersHistory}
          variant="h1"
          textAlign="center">
          {isRecurring
            ? 'There are currently no recurring orders saved in your order history'
            : 'There are currently no orders saved in your order history'}
        </Typography>
        <Button
          onClick={navigateToMaterials}
          className={classes.emptyOrdersHistoryButton}>
          Go To Materials
        </Button>
      </div>
    );
  }

  return (
    <>
      <TotalQtyLabel
        qty={orders?.length}
        type={'order'}
        styles={{ marginTop: '16px' }}
      />
      <OrderList
        orders={orders}
        isRecurring={isRecurring}
        isKiteUser={isKiteUser}
      />
    </>
  );
};

export default OrderHistory;
