const USER_TYPE = {
  gilead: 'gilead',
  kite: 'kite',
};

export default USER_TYPE;

export const USER_VIEW = {
  gileadCorporateUserPortal: 'Gilead Corporate User Portal',
  gileadFieldRepPortal: 'Gilead Field Rep Portal',
};
