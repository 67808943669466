// Lib
import { createReducer } from '@reduxjs/toolkit';

// Actions
import { OpenModal, CloseModal, ChangeModalProps } from '../actions/modal';
import { UserLogOut } from '../actions/user';

export const initialState = {
  id: '',
  Component: null,
  open: false,
  closeIcon: true,
  children: null,
  props: null,
  className: '',
  styles: {},
};

export default createReducer(initialState, {
  [OpenModal]: (state, { payload }) => {
    return { ...state, ...payload, open: true };
  },
  [ChangeModalProps]: (state, { payload: { type, value } }) => {
    return {
      ...state,
      props: {
        ...state.props,
        [type]: value,
      },
    };
  },
  [CloseModal]: () => initialState,
  [UserLogOut]: () => initialState,
});
