// Lib
import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

// Hooks
import useUI from '../../../hooks/useUI';

const useStyles = makeStyles((theme) => ({
  titleContainer: ({ isApproved }) => ({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: isApproved ? theme.spacing(3.5) : theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      marginBottom: isApproved ? theme.spacing(3) : theme.spacing(1),
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: isApproved ? theme.spacing(3) : 2,
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        marginBottom: isApproved ? theme.spacing(3) : theme.spacing(1),
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        marginBottom: isApproved ? theme.spacing(3) : 2,
        justifyContent: 'flex-start',
      }),
    },
  }),
  h1: {
    fontSize: 36,
    [theme.breakpoints.down('lg')]: {
      fontSize: 28,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 22,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        fontSize: 28,
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 22,
      }),
    },
  },
  h2: {
    [theme.breakpoints.down('lg')]: {
      fontSize: 18,
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        fontSize: 18,
        marginBottom: theme.spacing(1),
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 16,
      }),
    },
  },
  approveText: {
    color: theme.palette.darkGrey,
    fontSize: 18,
    letterSpacing: '0.2px',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.down('lg')]: {
      fontSize: 16,
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 14,
      marginTop: 2,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        fontSize: 16,
        marginTop: theme.spacing(1),
      }),
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        fontSize: 14,
        marginTop: 2,
      }),
    },
  },
  fullName: {
    position: 'relative',
    color: '#929A92',
    [theme.breakpoints.down('lg')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        fontSize: 18,
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 12,
        marginLeft: theme.spacing(1),
      }),
    },
  },
  circle: {
    backgroundColor: '#929A92',
    border: '1px solid #929A92',
    borderRadius: '50%',
    height: 1,
    left: -5,
    position: 'absolute',
    top: '50%',
    width: 1,
  },
}));

const TitleContainer = ({
  fullName,
  isApproved,
  title = 'Approvals',
  message = 'The order quantity needs to be approved.',
}) => {
  const classes = useStyles({ isApproved });
  const { isMobileMode } = useUI();

  return (
    <>
      <div className={classes.titleContainer}>
        <Typography className={classes.h1} variant="h1">
          {title}
        </Typography>
        <Typography className={classes.fullName} variant="h2">
          {isMobileMode && <div className={classes.circle} />}
          {fullName}
        </Typography>
      </div>
      {!isApproved && <p className={classes.approveText}>{message}</p>}
    </>
  );
};

export default TitleContainer;
