// Libs
import React from 'react';
import {
  TableBody,
  TableHead,
  TableCell,
  TableRow as MuiTableRow,
} from '@mui/material';
import MuiTable from '@mui/material/Table';
import classnames from 'classnames';

// Components
import BaseTableRow from './TableRow';

const Table = ({
  data,
  useCellsReducers = () => ({ cells: {} }),
  columns,
  styles = {},
  TableRow = BaseTableRow,
  collapsibleTable = false,
  props = {},
}) => {
  return (
    <MuiTable className={styles.table}>
      <TableHead>
        <MuiTableRow className={styles.row}>
          {columns.map((column) => (
            <TableCell
              key={column.id}
              className={classnames(styles.cell, styles.headerCell)}>
              {column.label}
            </TableCell>
          ))}
        </MuiTableRow>
      </TableHead>
      <TableBody>
        {data?.map((item) => {
          return (
            <TableRow
              collapsibleTable={collapsibleTable}
              styles={styles}
              key={item?.id || item?.product_id}
              columns={columns}
              useCellsReducers={useCellsReducers}
              item={item}
              {...props}
            />
          );
        })}
      </TableBody>
    </MuiTable>
  );
};

export default Table;
