// Lib
import React, { forwardRef } from 'react';
import { LoadingButton } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
  button: ({ styles, variant }) => ({
    display: 'flex',
    alignItems: 'center',
    ...styles,
  }),
}));

const Button = forwardRef(
  ({ variant = 'contained', children, styles, className, ...props }, ref) => {
    const classes = useStyles({ styles, variant });

    return (
      <LoadingButton
        {...props}
        className={classnames(classes.button, className)}
        variant={variant}
        ref={ref}>
        {children}
      </LoadingButton>
    );
  },
);

export default Button;
