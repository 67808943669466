// Lib
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

// Actions
import {
  EditImportedAddress,
  RemoveImportedAddress,
  ValidateAddress,
} from '../../../actions/addressBook';
import { ChangeModalProps } from '../../../actions/modal';

// Utils
import { camelToSnake } from '../../../utils/camelToSnake';

const useImportAddress = (address, setEditableAddress = () => {}) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const onRemoveAddress = useCallback(() => {
    dispatch(RemoveImportedAddress(address?.id));
  }, [dispatch, address?.id]);

  const onCloseAddressForm = useCallback(() => {
    setEditableAddress(null);
    window?.scroll(0, 0);
    dispatch(
      ChangeModalProps({
        type: 'onClose',
        value: null,
      }),
    );
  }, [dispatch, setEditableAddress]);

  const onEditAddress = useCallback(() => {
    setEditableAddress(address);
    dispatch(
      ChangeModalProps({
        type: 'onClose',
        value: onCloseAddressForm,
      }),
    );
  }, [setEditableAddress, address, dispatch, onCloseAddressForm]);

  const onChangeSelect = useCallback(
    (onChange, setValue, field) => (item) => () => {
      let gmopErrors = null;
      if (address?.GMOP_errors_description) {
        gmopErrors = { ...address?.GMOP_errors_description };
        if (field.name === 'country') {
          gmopErrors[field.name] = {};
          gmopErrors.state = {};
        }
        if (field.name === 'state') {
          gmopErrors.state = {};
        }
      }
      setEditableAddress((prevAddress) => ({
        ...prevAddress,
        GMOP_errors_description: gmopErrors,
        apiError: null,
      }));
      onChange(item.name);
      setValue && setValue('state', '');
    },
    [setEditableAddress, address],
  );

  const onSaveAddress = useCallback(
    (data) => {
      const modifiedAddress = {
        address1: data.address1,
        address2: data.address2,
        address_type: data.addressType,
        city: data.city,
        company: data.company,
        country: data.country,
        first_name: data.firstName,
        last_name: data.lastName,
        phone: data.phone,
        state: data.state,
        zip: data.zip,
      };
      if (address.GMOP_errors_description) {
        const gmopErrors = { ...address.GMOP_errors_description };
        dispatch(
          EditImportedAddress({
            id: address.id,
            data: gmopErrors,
            type: 'GMOP_errors_description',
          }),
        );
      }
      if (address.apiError) {
        const apiError = address.apiError;
        dispatch(
          EditImportedAddress({
            id: address.id,
            data: apiError,
            type: 'apiError',
          }),
        );
      }
      dispatch(
        EditImportedAddress({
          id: address.id,
          data: modifiedAddress,
          type: 'original_fields',
          clearApiError: true,
          clearValidatedAddress: true,
        }),
      );
      onCloseAddressForm();
    },
    [address, dispatch, onCloseAddressForm],
  );

  const revalidateAddress = useCallback(() => {
    dispatch(
      ValidateAddress({
        formData: {
          ...address.original_fields,
          phone: address.original_fields?.phone?.replace(/\-/g, ''),
        },
        setIsLoading,
        setIsValidated: () => {},
        id: address.id,
        importFlow: true,
      }),
    );
  }, [dispatch, address]);

  const onChangeAddress = useCallback(
    ({ target }) => {
      dispatch(
        EditImportedAddress({
          id: address.id,
          data: target.name,
          type: 'checked',
        }),
      );
    },
    [dispatch, address],
  );

  const onChangeInput = useCallback(
    (onChange, nativeEvent = false) =>
      (e) => {
        let name, value;
        if (nativeEvent) {
          value = e.target.value;
          name = e.target.name;
        } else {
          value = e.value;
          name = e.name;
        }
        if (address.GMOP_errors_description) {
          const gmopErrors = { ...address.GMOP_errors_description };
          gmopErrors[camelToSnake(name)] = {};
          setEditableAddress((prevAddress) => ({
            ...prevAddress,
            GMOP_errors_description: gmopErrors,
            apiError: null,
          }));
        }
        onChange(value);
      },
    [setEditableAddress, address],
  );

  return {
    onRemoveAddress,
    onEditAddress,
    onCloseAddressForm,
    onChangeSelect,
    onChangeInput,
    onSaveAddress,
    revalidateAddress,
    isLoading,
    onChangeAddress,
  };
};

export default useImportAddress;
