// Lib
import React, { forwardRef } from 'react';
import { TextField, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

// Styles
import { colors } from '../../../styles/palette';

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    position: 'relative',
  },
  error: {
    color: colors.gilead.primary1,
    fontSize: 14,
    marginTop: theme.spacing(0.5),
  },
  input: {
    '& .Mui-disabled fieldset': {
      border: 'none',
    },
  },
}));

const Input = forwardRef(
  (
    {
      withMask = false,
      value,
      label,
      required,
      className,
      error,
      onChange,
      customPlaceholder,
      maxLength,
      name,
      ...props
    },
    ref,
  ) => {
    const classes = useStyles({ error });

    return (
      <Box className={classnames(className, classes.inputContainer)}>
        {label && (
          <Box style={{ fontWeight: 600 }} mb={1}>
            {`${label} `}
            {required ? (
              <span style={{ color: colors.gilead.primary1 }}>*</span>
            ) : (
              ''
            )}
          </Box>
        )}
        {customPlaceholder && customPlaceholder}
        <TextField
          name={name}
          error={!!error}
          className={classes.input}
          ref={ref}
          fullWidth={true}
          value={value}
          inputProps={{ accept: props.accept }}
          onChange={(e) => {
            if (withMask) {
              return onChange(e);
            }
            const { target } = e;
            let value = target?.value;
            const trimmedValue = value?.trimStart();
            if (trimmedValue?.length > maxLength) {
              const firstLetterPosition = value?.indexOf(trimmedValue[0]);
              value = value?.slice(0, maxLength + firstLetterPosition);
            }
            return onChange({ name, value, files: target?.files });
          }}
          {...props}
        />
        {error && (
          <div id="input-error" className={classes.error}>
            {error}
          </div>
        )}
      </Box>
    );
  },
);

export default Input;
