import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: {
      marginTop: 14,
    },
  },
  text: {
    color: '#54565B',
    lineHeight: '20px',
    width: 895,
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: {
      lineHeight: '18px',
      marginBottom: 0,
    },
  },
}));

const OrderProcessingInfo = ({
  classNames,
  header = 'Order Processing Information',
  message = (
    <>
      Orders placed on weekdays (excluding holidays) by{' '}
      <b>12:00&nbsp;PM&nbsp;EST&nbsp;for&nbsp;Gilead</b> and{' '}
      <b>12:00&nbsp;PM&nbsp;PST&nbsp;for&nbsp;Kite</b> will ship the same-day.
      Orders placed after <b>12:00&nbsp;PM&nbsp;EST&nbsp;for&nbsp;Gilead</b> and{' '}
      <b>12:00&nbsp;PM&nbsp;PST&nbsp;for&nbsp;Kite</b> will ship the following
      business day. If you are selecting a ship schedule or specifying a
      specific date, there may be other conditions. Please see your selected
      option for additional information.
    </>
  ),
}) => {
  const classes = useStyles();

  return (
    <>
      <Typography
        data-testid={header.toLowerCase().replace(/\ /g, '-')}
        className={classnames(classes.title, classNames?.title)}
        variant="h3">
        {header}
      </Typography>
      <div
        data-testid={`${header.toLowerCase().replace(/\ /g, '-')}-message`}
        className={classnames(classes.text, classNames?.text)}>
        {message}
      </div>
    </>
  );
};

export default OrderProcessingInfo;
