// Lib
import React from 'react';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

// Components
import Button from '../../../Button';

const useStyles = makeStyles((theme) => ({
  buttons: ({ status }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    '& button': {
      fontSize: 14,
      height: 34,
      minWidth: 10,
      paddingLeft: 4,
      paddingRight: 4,
      '&:nth-child(1)': {
        color:
          status === 'reject' ? theme.palette.primary2 : theme.palette.primary1,
        pointerEvents: status === 'reject' ? 'none' : 'initial',
      },
      '&:nth-child(2)': {
        backgroundColor:
          status === 'approve'
            ? `${theme.palette.primary2} !important`
            : `${theme.palette.primary1} !important`,
        fontSize: 12,
        minWidth: 72,
      },
    },
    [theme.breakpoints.down('md')]: {
      '& button': {
        fontSize: '10px !important',
      },
    },
  }),
}));

const ActionButtons = ({
  className,
  onReject = () => {},
  onAccept = () => {},
  status,
}) => {
  const classes = useStyles({ status });

  return (
    <div className={classnames(classes.buttons, className)}>
      <Button onClick={onReject} variant="text">
        {`Reject${status === 'reject' ? 'ed' : ''}`}
      </Button>
      <Button id="approve-btn" onClick={onAccept('approve')}>{`Approve${
        status === 'approve' ? 'd' : ''
      }`}</Button>
    </div>
  );
};

export default ActionButtons;
