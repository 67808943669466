// Libs
import React from 'react';

// Constants
import { ORDER_APPROVAL_STATUSES } from '../../constants/approvals';

const OrderProductQtyLabels = ({
  orderItem,
  addressesCount,
  isRecurring = false,
  className,
}) => {
  const { status, qty_ordered, qty_approved } = orderItem || {};

  const qtyOrdered = qty_ordered * addressesCount;
  const qtyApproved = qty_approved * addressesCount;

  const { APPROVAL_IS_NOT_REQUIRED, WAITING_FOR_APPROVAL, APPROVED, REJECTED } =
    ORDER_APPROVAL_STATUSES;

  const qtyLabel = (qtyOrdered) => {
    return <div className={className}>Order Qty: {qtyOrdered}</div>;
  };

  const requestedLabel = (qtyOrdered) => {
    return <div className={className}>Order Qty (Requested): {qtyOrdered}</div>;
  };

  const bothLabels = (qtyOrdered, qtyApproved) => {
    return (
      <>
        <div className={className}>Order Qty (Requested): {qtyOrdered}</div>
        <div className={className}>Order Qty (Approved): {qtyApproved}</div>
      </>
    );
  };

  if (isRecurring) return qtyLabel(qtyOrdered);

  switch (status) {
    case APPROVAL_IS_NOT_REQUIRED:
      return qtyLabel(qtyOrdered);
    case WAITING_FOR_APPROVAL:
      return requestedLabel(qtyOrdered);
    case APPROVED:
    case REJECTED:
    default:
      return bothLabels(qtyOrdered, qtyApproved);
  }
};

export default OrderProductQtyLabels;
