// Libs
import { put, call, all } from 'redux-saga/effects';

// Constants
import { CART_URLS } from '../../../constants/urls';

// Actions
import {
  SetSaveForLaterProducts,
  SetUnavailableLaterProducts,
  UpdateSaveForLaterProducts,
} from '../../../actions/cart';
import { SetProducts } from '../../../actions/products';

// Api
import http from '../../../api/http';

// Store
import { store } from '../../../store';

// Utils
import isProductExpired from '../../../utils/isProductExpired';
import isProductWithdrawn from '../../../utils/isProductWithdrawn';

export function* getSaveForLaterProductsSaga() {
  try {
    const response = yield call(http.get, CART_URLS.getSaveForLaterProducts);
    const productsObj = { ...store.getState().products?.all?.obj };

    // Save for later request returns two arrays (products and removed_products)
    // which have different structure and need to be processed differently
    const callback = (item) => {
      productsObj[item.product.product_id] = item.product;
      return {
        product: item.product.product_id,
        nums: item.nums,
      };
    };

    const callbackForUnavailable = (item) => {
      if (!productsObj[item.product_id]) {
        productsObj[item.product_id] = item;
      }
      return {
        product: item.product_id,
      };
    };

    const productItems = [];
    let unavailableItems = [];

    response.data.products.forEach((item) => {
      if (
        !isProductExpired(item.product) &&
        !isProductWithdrawn(item.product) &&
        item.product?.is_available_for_user
      ) {
        productItems.push(callback(item));
      } else {
        unavailableItems.push(callback(item));
      }
    });

    unavailableItems = unavailableItems.concat(
      response.data.removed_products.map(callbackForUnavailable),
    );

    yield put(SetProducts({ obj: productsObj }));
    yield put(SetSaveForLaterProducts(productItems));
    yield put(SetUnavailableLaterProducts(unavailableItems));
  } catch (e) {
    console.log(e);
  }
}

export function* addSaveForLaterProductSaga({ payload }) {
  try {
    const { product, nums } = payload;
    yield call(
      http.post,
      CART_URLS.addProductToSaveForLater(product.product_id),
      { nums },
    );
    yield put(
      UpdateSaveForLaterProducts({
        type: 'add',
        product,
        nums,
      }),
    );
  } catch (e) {
    console.log(e);
  }
}

export function* deleteAllSaveForLaterProductsSaga() {
  try {
    yield call(http.delete, CART_URLS.deleteAllProductsFromSaveForLater);
    yield put(UpdateSaveForLaterProducts({ type: 'deleteAll' }));
  } catch (e) {
    console.log(e);
  }
}

export function* deleteSaveForLaterProductSaga({ payload }) {
  try {
    yield call(http.delete, CART_URLS.deleteProductFromSaveForLater(payload));
    yield put(
      UpdateSaveForLaterProducts({ type: 'delete', productId: payload }),
    );
  } catch (e) {
    console.log(e);
  }
}

export function* deleteUnavaialableLaterProductsSaga({ payload }) {
  try {
    yield all(
      payload.map(({ product }) =>
        call(http.delete, CART_URLS.deleteProductFromSaveForLater(product)),
      ),
    );
  } catch (e) {
    console.log(e);
  }
}

export function* putSaveForLaterProductInCartSaga({ payload }) {
  try {
    const { product, nums } = payload;
    yield call(
      http.post,
      CART_URLS.putSaveForLaterProductInCart(product.product_id),
      { nums },
    );
    yield put(UpdateSaveForLaterProducts({ type: 'putInCart', product, nums }));
  } catch (e) {
    console.log(e);
  }
}
