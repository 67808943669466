// Constants
import { SHIPPING_SCHEDULES } from '../constants/cart';

const isDisabledSavingRecurringSchedule = (shippingDetails, monthError) => {
  if (shippingDetails.schedule === SHIPPING_SCHEDULES[4].value) {
    return !(
      shippingDetails.weekly.days.find(({ checked }) => checked) && !monthError
    );
  }
  if (shippingDetails.schedule === SHIPPING_SCHEDULES[5].value) {
    if (shippingDetails.monthly.monthDay.checked) {
      return !(shippingDetails.monthly.monthDay.dayNumber && !monthError);
    }
    if (shippingDetails.monthly.weekDay.checked) {
      return !(
        shippingDetails.monthly.weekDay.dayNumber &&
        shippingDetails.monthly.weekDay.weekDay &&
        !monthError
      );
    }
  }
};

export default isDisabledSavingRecurringSchedule;
