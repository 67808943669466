// Lib
import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Box } from '@mui/material';

// Components
import OrderInfoItem from '../../OrderQty/OrderInfo/OrderInfoItem';
import Feather from '../../../Feather';
import ActionButtons from '../ActionButtons';

// Hooks
import useUser from '../../../../hooks/useUser';
import useProduct from '../../../../pages/Approvals/hooks/useProduct';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: '0px 5px 17px rgba(48, 85, 107, 0.2)',
    borderRadius: 20,
    margin: 'auto',
    marginBottom: theme.spacing(2.5),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      minHeight: 150,
      borderRadius: 10,
      '& span': {
        lineHeight: '12px !important',
      },
      '& > div:nth-child(1)': {
        paddingTop: theme.spacing(1.375),
        paddingBottom: theme.spacing(1.375),
      },
    },
  },
  buttons: {
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  description: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    '& span': {
      '&:nth-child(2)': {
        paddingLeft: theme.spacing(0.4),
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        wordBreak: 'break-word',
        '-webkit-line-clamp': 1,
        '-webkit-box-orient': 'vertical',
      },
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
      flexWrap: 'wrap',
      '& span': {
        marginBottom: 0,
        '&:nth-child(2)': {
          paddingLeft: ({ isMethodPage }) =>
            isMethodPage ? theme.spacing(0.4) : 0,
        },
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& span': {
        paddingLeft: '0 !important',
      },
    },
  },
  orderInfo: {
    [theme.breakpoints.down('md')]: {
      '& span': {
        marginBottom: 0,
      },
    },
  },
  orderQty: ({ isKiteUser }) => ({
    color: isKiteUser ? theme.palette.primary1 : theme.palette.primary2,
    fontWeight: 700,
    [theme.breakpoints.down('md')]: {
      '& span': {
        marginBottom: 0,
      },
    },
  }),
  editIcon: {
    cursor: 'pointer',
    height: 12,
    width: 12,
    marginLeft: theme.spacing(1),
    marginTop: 6,
    '& path': {
      fill: theme.palette.primary1,
    },
  },
  statusContainer: ({ canApproveProduct }) => ({
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      textAlign: canApproveProduct ? 'initial' : 'end',
    },
  }),
  status: {
    color: '#929A92',
    fontSize: 14,
    fontWeight: 600,
    '&:first-letter': {
      textTransform: 'uppercase',
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 'auto',
      fontSize: 10,
      textAlign: 'end',
    },
  },
  actionButtons: {
    [theme.breakpoints.down('md')]: {
      columnGap: 20,
      justifyContent: 'flex-end',
      '& button': {
        height: 30,
        '&:nth-child(2)': {
          minWidth: 69,
        },
      },
    },
  },
}));

const ProductCard = ({ product, addressesCount, isMethodPage = false }) => {
  const {
    onOpenQtyModal,
    onOpenConfirmModal,
    addAction,
    canApproveProduct,
    isRejected,
    isAlreadyApproved,
  } = useProduct(product.product_id, product.qty_approved);

  const { isKiteUser } = useUser();
  const classes = useStyles({ isKiteUser, canApproveProduct, isMethodPage });
  const {
    product_description,
    product_id,
    product_managers,
    unit_measure,
    prepack_quantity,
    qty_approved,
    order_limit,
    monthly_limit,
    annual_limit,
    action,
    status,
  } = product || {};

  return (
    <Grid container className={classes.root} spacing={1}>
      <Grid
        display="flex"
        alignItems="center"
        item
        pt={isMethodPage && { md: '16px !important' }}
        pb={isMethodPage && { md: 1.5 }}
        xs={isMethodPage ? 12 : 6}
        sm={isMethodPage ? 12 : 8}
        md={isMethodPage ? 12 : 9}
        borderBottom="1px solid #F4F4F4">
        <OrderInfoItem
          className={classes.description}
          title="Product description"
          text={product_description}
        />
      </Grid>
      {!isMethodPage && (
        <Grid
          display={canApproveProduct && !isAlreadyApproved ? 'block' : 'flex'}
          alignItems={
            canApproveProduct && !isAlreadyApproved ? 'initial' : 'center'
          }
          className={classes.statusContainer}
          item
          xs={6}
          sm={4}
          md={3}
          borderBottom="1px solid #F4F4F4">
          {canApproveProduct && !isAlreadyApproved ? (
            <ActionButtons
              className={classes.actionButtons}
              status={action}
              onAccept={addAction}
              onReject={onOpenConfirmModal}
            />
          ) : (
            <div className={classes.status}>
              {status ? status.toLowerCase() : 'Pending Approval'}
            </div>
          )}
        </Grid>
      )}
      <Grid item xs={5} md={5.5} borderBottom="1px solid #F4F4F4">
        <OrderInfoItem
          className={classes.orderInfo}
          title="Product Manager"
          text={product_managers?.join(', ') || ''}
        />
        <OrderInfoItem
          className={classes.orderInfo}
          title="Unit of Measure"
          text={unit_measure}
        />
        <OrderInfoItem
          className={classes.orderInfo}
          title="Per Pack Qty"
          text={prepack_quantity}
        />
      </Grid>
      <Grid item xs={3} md={3.5} borderBottom="1px solid #F4F4F4">
        <Box display="flex">
          <OrderInfoItem
            className={!isMethodPage && classes.orderQty}
            title="Order Qty"
            text={qty_approved}
          />
          {!(isRejected || isAlreadyApproved) &&
            !isMethodPage &&
            canApproveProduct && (
              <Feather
                className={classes.editIcon}
                type="edit"
                onClick={onOpenQtyModal}
              />
            )}
        </Box>
        {addressesCount > 1 && (
          <OrderInfoItem
            className={classes.orderInfo}
            title="Total Qty"
            text={qty_approved * addressesCount}
          />
        )}
        <OrderInfoItem
          className={classes.orderInfo}
          title="Stock number"
          text={product_id}
        />
      </Grid>
      <Grid
        ml={{ md: 0, xs: 1.5 }}
        item
        xs={3}
        md={3}
        borderBottom="1px solid #F4F4F4">
        <OrderInfoItem
          className={classes.orderInfo}
          title="Max order Qty"
          text={order_limit}
        />
        <OrderInfoItem
          className={classes.orderInfo}
          title="Monthly limit"
          text={monthly_limit}
        />
        <OrderInfoItem
          className={classes.orderInfo}
          title="Annual limit"
          text={annual_limit}
        />
      </Grid>
    </Grid>
  );
};

export default ProductCard;
