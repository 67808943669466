// Libs
import { createReducer } from '@reduxjs/toolkit';

// Actions
import {
  SetAddressBook,
  ChangeBookView,
  AddAddress,
  SetValidatedAddress,
  ApplyAddressFilters,
  EditAddress,
  ChangAddressBookFilter,
  UpdateAddressesPage,
  SetSearchResults,
  SetEnteredAddress,
  SetAllCountries,
  SetUSAStates,
  SetImportedAddressFile,
  SetImportedAddresses,
  SetImportedAddress,
  RemoveImportedAddress,
  EditImportedAddress,
  SetUploadingProgressValue,
  DeleteAddressInState,
} from '../actions/addressBook';
import { UserLogOut } from '../actions/user';

const ADDRESS_COUNT_ON_PAGE = 24;

export const initialState = {
  addressBook: {
    page: 1,
    all: [],
    shown: [],
    afterFilters: [],
    afterValidation: {},
    searchResults: [],
    enteredAddress: {},
    countries: [],
    usaStates: [],
    import: {
      file: null,
      addresses: null,
      uploadingProgress: 0,
    },
  },
  filters: {
    view: 'list',
    sorting: {
      order: 'desc',
      orderBy: 'Last name',
      items: [
        {
          title: 'First name',
          label: 'Sort By First Name',
        },
        {
          title: 'Last name',
          label: 'Sort By Last Name',
        },
        {
          title: 'Company',
          label: 'Sort By Company',
        },
      ],
    },
  },
};

export default createReducer(initialState, {
  [SetAddressBook]: (state, { payload }) => ({
    ...state,
    addressBook: {
      ...state.addressBook,
      all: payload,
    },
  }),
  [AddAddress]: (state, { payload }) => ({
    ...state,
    addressBook: {
      ...state.addressBook,
      all: [...state.addressBook.all, payload],
      shown: [...state.addressBook.shown, payload],
    },
  }),
  [EditAddress]: (state, { payload }) => {
    const modifiedAll = state.addressBook.all.map((item) => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });

    const modifiedAfterFilters = state.addressBook.afterFilters.map((item) => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });

    return {
      ...state,
      addressBook: {
        ...state.addressBook,
        all: modifiedAll,
        afterFilters: modifiedAfterFilters,
        shown: modifiedAfterFilters.slice(
          0,
          state.addressBook.page * ADDRESS_COUNT_ON_PAGE,
        ),
      },
    };
  },
  [SetAllCountries]: (state, { payload }) => ({
    ...state,
    addressBook: {
      ...state.addressBook,
      countries: payload,
    },
  }),
  [SetUSAStates]: (state, { payload }) => ({
    ...state,
    addressBook: {
      ...state.addressBook,
      usaStates: payload,
    },
  }),
  [DeleteAddressInState]: (state, { payload }) => ({
    ...state,
    addressBook: {
      ...state.addressBook,
      all: state.addressBook.all.filter(({ id }) => id !== payload),
      shown: state.addressBook.shown.filter(({ id }) => id !== payload),
    },
  }),
  [SetValidatedAddress]: (state, { payload }) => ({
    ...state,
    addressBook: {
      ...state.addressBook,
      afterValidation: payload,
    },
  }),
  [ChangeBookView]: (state, { payload }) => ({
    ...state,
    filters: {
      ...state.filters,
      view: payload,
    },
  }),
  [ApplyAddressFilters]: (state, { payload }) => ({
    ...state,
    addressBook: {
      ...state.addressBook,
      afterFilters: payload,
      shown: payload.slice(0, state.addressBook.page * ADDRESS_COUNT_ON_PAGE),
    },
  }),
  [ChangAddressBookFilter]: (state, { payload }) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        ...payload,
      },
    };
  },
  [SetSearchResults]: (state, { payload }) => {
    return {
      ...state,
      addressBook: {
        ...state.addressBook,
        searchResults: payload,
      },
    };
  },
  [SetEnteredAddress]: (state, { payload }) => {
    return {
      ...state,
      addressBook: {
        ...state.addressBook,
        enteredAddress: payload,
      },
    };
  },
  [SetImportedAddressFile]: (state, { payload }) => {
    return {
      ...state,
      addressBook: {
        ...state.addressBook,
        import: {
          ...state.addressBook.import,
          file: payload,
        },
      },
    };
  },
  [SetImportedAddresses]: (state, { payload }) => {
    return {
      ...state,
      addressBook: {
        ...state.addressBook,
        import: {
          ...state.addressBook.import,
          addresses: payload,
        },
      },
    };
  },
  [SetUploadingProgressValue]: (state, { payload }) => {
    return {
      ...state,
      addressBook: {
        ...state.addressBook,
        import: {
          ...state.addressBook.import,
          uploadingProgress: payload,
        },
      },
    };
  },
  [EditImportedAddress]: (state, { payload }) => {
    const { data, id, type, clearApiError, clearValidatedAddress } = payload;
    return {
      ...state,
      addressBook: {
        ...state.addressBook,
        import: {
          ...state.addressBook.import,
          addresses: state.addressBook.import.addresses.map((address) => {
            if (address.id === id) {
              const modifiedAddress = {
                ...address,
                [type]: data,
              };
              if (clearApiError && modifiedAddress.apiError) {
                modifiedAddress.apiError = '';
              }
              if (clearValidatedAddress) {
                modifiedAddress.validated_address = '';
                modifiedAddress.checked = 'original';
              }
              return modifiedAddress;
            }
            return address;
          }),
        },
      },
    };
  },
  [SetImportedAddress]: (state, { payload }) => {
    const { data, id } = payload;

    return {
      ...state,
      addressBook: {
        ...state.addressBook,
        import: {
          ...state.addressBook.import,
          addresses: state.addressBook.import.addresses.map((address) => {
            if (address.id === id) {
              return data;
            }
            return address;
          }),
        },
      },
    };
  },
  [RemoveImportedAddress]: (state, { payload }) => {
    return {
      ...state,
      addressBook: {
        ...state.addressBook,
        import: {
          ...state.addressBook.import,
          addresses: state.addressBook.import.addresses.filter(
            (address) => address.id !== payload,
          ),
        },
      },
    };
  },
  [UpdateAddressesPage]: (state) => {
    const newPage = state.addressBook.page + 1;
    const newShownAddresses = state?.addressBook?.afterFilters?.slice(
      0,
      newPage * ADDRESS_COUNT_ON_PAGE,
    );
    const newCount = newShownAddresses?.length;

    if (
      state?.addressBook?.afterFilters?.length >= newCount &&
      state?.addressBook?.shown?.length < newCount
    ) {
      return {
        ...state,
        addressBook: {
          ...state.addressBook,
          page: newPage,
          shown: newShownAddresses,
        },
      };
    }
    return state;
  },
  [UserLogOut]: () => initialState,
});
