// Libs
import { takeEvery, put, all, call } from 'redux-saga/effects';

// Api
import http from '../api/http';

// Actions
import {
  SetRating,
  UpdateProductsRating,
  UpdateRating,
} from '../actions/rating';

// URLS
import { RATING } from '../constants/urls';

// Actions
import { Close2Modal } from '../actions/modal2';

export function* updateRatingSaga({ payload: { id, rate, isAlreadyRated } }) {
  try {
    const response = yield http[isAlreadyRated ? 'put' : 'post'](
      RATING.setRating(id),
      { rate },
    );
    yield put(
      SetRating({ id, rate, family_rate: response?.data?.family_rate || {} }),
    );
  } catch (e) {
    console.log(e);
  }
}

export function* updateProductsRatingSaga({
  payload: { products, setLoading },
}) {
  try {
    setLoading(true);
    const response = yield all(
      products.map(({ id, rate }) =>
        call(http.post, RATING.setRating(id), { rate }),
      ),
    );
    yield all(
      products.map(({ id, rate }, index) =>
        put(
          SetRating({
            id,
            rate,
            family_rate: response?.[index]?.data?.family_rate || {},
          }),
        ),
      ),
    );
    yield put(Close2Modal());
  } catch (e) {
    console.log(e);
  } finally {
    setLoading(false);
  }
}

function* ratingSaga() {
  yield takeEvery(UpdateRating, updateRatingSaga);
  yield takeEvery(UpdateProductsRating, updateProductsRatingSaga);
}

export default ratingSaga;
