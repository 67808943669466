// Libs
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Link from '@mui/material/Link';

// Components
import Spinner from '../../../components/Spinner';
import GridCard from '../../../components/Products/Card/GridCard';
import GridButton from '../../../components/Button/GridButton';
import ListButton from '../../../components/Button/ListButton';
import Reorder from '../../../components/Button/ReorderButton/ReorderButton';
import IconButton from '../../../components/Button/IconButton/IconButton';
import CartCard from '../../../components/Cart/ShippingDetails/CartCard';
import Feather from '../../../components/Feather';
import KitsButton from '../../../components/Kits/Buttons/KitsButton';
import Error from '../../../components/Error';

// Hooks
import useUI from '../../../hooks/useUI';
import useKitActions from '../hooks/useKitActions';
import useKitsProducts from '../hooks/useKitsProducts';

// Actions
import {
  GetKitDetails,
  ClearKitDetails,
  SetKitDetails,
} from '../../../actions/kits';

// Selectors
import { productsObjSelector } from '../../../store/selectors';

const useStyles = makeStyles((theme) => ({
  kitDetails: {
    [theme.breakpoints.down('lg')]: {
      minHeight: 'calc(100vh - 70px - 40px)',
      display: 'flex',
      flexDirection: 'column',
    },
    [theme.breakpoints.down('md')]: {
      minHeight: 'calc(100vh - 54px - 20px)',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        minHeight: 'calc(100vh - 70px - 40px)',
        display: 'flex',
        flexDirection: 'column',
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        minHeight: 'calc(100vh - 54px - 20px)',
      }),
    },
  },
  breadCrumbs: {
    color: theme.palette.darkGrey,
    ...theme.typography.h3,
    '& > * > :last-child': {
      color: theme.palette.grey2,
    },
  },
  productsLayout: ({ listView, isMobileMode }) =>
    listView
      ? {
          display: 'flex',
          flexDirection: 'column',
          gridTemplateColumns: 'initial',
          gap: theme.spacing(2),
        }
      : {
          display: 'grid',
          gridTemplateColumns: isMobileMode ? '1fr 1fr' : '1fr 1fr 1fr',
          gap: theme.spacing(2),
        },
  kitReorderButton: {
    marginTop: 0,
    width: '89px',
    height: '36px',
    [theme.breakpoints.down('lg')]: {
      width: '119px',
    },
    [theme.breakpoints.down('md')]: {
      width: 296,
      marginTop: '20px',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        width: '119px',
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        width: 296,
        marginTop: '20px',
      }),
    },
  },
  kitName: {
    wordBreak: 'break-word',
  },
  productsLabel: {
    [theme.breakpoints.down('lg')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        fontSize: 18,
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 16,
      }),
    },
  },
}));

const KitDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isDesktopMode, isTabletMode, isMobileMode } = useUI();
  const { openMaterialsModal, selectingKitProducts } = useKitsProducts();
  const { kitId } = useParams();
  const [listView, setListView] = useState(true);
  const { kits, kit, productsObj, apiError } = useSelector((state) => ({
    kits: state.kits.data,
    kit: state.kits.currentKit,
    productsObj: productsObjSelector(state),
    apiError: state.apiError?.['getKitDetails'],
  }));
  const { onEditKitName, onReorderClick, canReorder } = useKitActions(kit);
  const { isLoading } = useSelector((state) => state);
  const classes = useStyles({ listView, isMobileMode });

  useEffect(() => {
    const localKit = kits?.find((kit) => kit.id === +kitId);

    if (kitId && localKit) {
      dispatch(SetKitDetails(localKit));
    }
    if (kitId && !localKit) {
      dispatch(GetKitDetails(kitId));
    }

    return () => {
      dispatch(ClearKitDetails(kitId));
    };
  }, [dispatch, kitId, selectingKitProducts, kits]);

  const breadcrumbs = [
    <Link
      underline="none"
      key="1"
      color="inherit"
      style={{ cursor: 'pointer' }}
      onClick={() => navigate('..')}>
      My Kits
    </Link>,
    <Link key="2" color="inherit" underline="none">
      Kit Details
    </Link>,
  ];

  if (isLoading) {
    return <Spinner />;
  }

  if (apiError) {
    return <Error message={apiError} />;
  }

  return (
    <>
      {kit && (
        <div className={classes.kitDetails}>
          {isDesktopMode ? (
            <Breadcrumbs
              className={classes.breadCrumbs}
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
              mt={3}>
              {breadcrumbs}
            </Breadcrumbs>
          ) : (
            <Typography
              variant={isMobileMode ? 'h1' : 'h2'}
              mt={isMobileMode ? '20px' : '40px'}>
              Kit Details
            </Typography>
          )}
          <Box
            display={'flex'}
            flexDirection={isMobileMode ? 'column' : 'row'}
            justifyContent={'space-between'}
            mt={4}
            position={'relative'}>
            <div style={{ flexBasis: '75%' }}>
              <Typography variant="h2" className={classes.kitName}>
                {kit?.name}
              </Typography>
              <IconButton
                startIcon={<Feather type={'edit'} />}
                styles={{
                  margin: isMobileMode ? '0 0 0 5px' : '10px 0 0 5px',
                }}
                onClick={() => onEditKitName(kit?.id, kit.name)}
                data-testid={'edit-kit-name-button'}>
                Edit Nickname
              </IconButton>
            </div>
            <Reorder
              disabled={!canReorder}
              kit={kit}
              onClick={onReorderClick}
              inTable={false}
              className={classes.kitReorderButton}
            />
          </Box>

          <Box mt={4} mb={4}>
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              mb={2}
              alignItems={'center'}>
              <Box display={'flex'} alignItems={'center'} gap={2}>
                <Typography className={classes.productsLabel} variant="h2">
                  Products ({kit.products.length})
                </Typography>
                <Feather
                  type="containedRoundedPlus"
                  onClick={() => openMaterialsModal(kit.id)}
                  data-testid={'add-kit-products-button'}
                />
              </Box>
              <Box display={'flex'} gap={2}>
                <ListButton
                  isActive={listView}
                  onClick={() => setListView(true)}></ListButton>
                <GridButton
                  isActive={!listView}
                  onClick={() => setListView(false)}></GridButton>
              </Box>
            </Box>
            {kit?.products.length > 0 && (
              <Box className={classes.productsLayout}>
                {kit?.products.map((item) =>
                  listView ? (
                    <CartCard
                      key={item.product}
                      product={productsObj?.[item.product]}
                      kit={kit}
                      nums={item.nums}
                      kitDetailsPage
                    />
                  ) : (
                    <GridCard
                      key={item.product}
                      product={productsObj?.[item.product]}
                      kit={kit}
                      nums={item.nums}
                      isTabletMode={isTabletMode}
                      imageContainer={{
                        desktop: ['100%', 250],
                        tablet: ['100%', 200],
                        mobile: ['100%', 138],
                      }}
                    />
                  ),
                )}
              </Box>
            )}
          </Box>
          {isTabletMode && (
            <Box
              flexGrow={'1'}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'end'}>
              <KitsButton />
            </Box>
          )}
        </div>
      )}
    </>
  );
};

export default KitDetails;
