// Lib
import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { compareDesc, compareAsc, isAfter } from 'date-fns';

// Actions
import { ApplyFilters, UpdateProductsPage } from '../../../actions/products';
import {
  ChangeFilter,
  ClearFilters,
  SaveFilters,
  SetFilters,
} from '../../../actions/filters';

// Utils
import isMatchFilter from '../../../utils/isMatchFilter';

// Constants
import { SEARCHABLE_FIELDS } from '../../../constants/products';

// Utils
import multiSearch from '../../../utils/multiSearch';
import {
  isExpiredIn30Days,
  isPastDate,
  isWithdrawnIn30Days,
} from '../../../utils/date';
import { separatePrescribedProducts } from '../../../utils/prescribingInfo';
import isProductExpired from '../../../utils/isProductExpired';
import isProductWithdrawn from '../../../utils/isProductWithdrawn';

// Actions
import { SetLoading } from '../../../actions/loading';

const sortingMap = {
  date: 'planned_date_first_use',
  name: 'product_description',
  'stock number': 'product_id',
  orders: 'orders_count',
  rating: 'family_rate.rating',
};

const useFilters = () => {
  const dispatch = useDispatch();
  const [initialFiltersApplied, setInitialFiltersApplied] = useState(false);
  const { products, searchValue, filters, isLoading } = useSelector(
    (state) => ({
      products: state.products,
      searchValue: state.search,
      filters: state.filters,
      isLoading: state.isLoading,
    }),
  );

  const applySearch = useCallback(
    (product) => {
      if (!searchValue) {
        return true;
      }
      if (searchValue) {
        const field = SEARCHABLE_FIELDS.find((field) => {
          return multiSearch(product[field], searchValue.split(' '));
        });
        if (field) {
          return true;
        }
      }
    },
    [searchValue],
  );

  useEffect(() => {
    if (filters.loaded) {
      const changedFilters = { ...filters };
      delete changedFilters.loaded;
      dispatch(SaveFilters(changedFilters));
    }
  }, [dispatch, filters]);

  const applyFilters = useCallback(
    (product) => {
      const checkedAvailableOption = filters.available.find(
        (item) => item.checked,
      );

      const isCheckedAvailable = !!checkedAvailableOption;
      const availableOptionTitle = checkedAvailableOption?.title;
      const isWithdrawnOrExpired =
        isProductWithdrawn(product) || isProductExpired(product);
      const isPastEndDate = product.end_date
        ? isPastDate(new Date(product.end_date))
        : false;

      if (
        (!isCheckedAvailable && product?.offer_inactive) ||
        (isCheckedAvailable &&
          !(
            availableOptionTitle?.includes('Expired') ||
            availableOptionTitle?.includes('Withdrawn')
          ) &&
          product?.offer_inactive) ||
        (isWithdrawnOrExpired ? false : isPastEndDate)
      ) {
        return;
      }

      let isMatchFavourite;
      let isMatchAvailable;
      let isMatchFrequentlyOrdered;
      // Brand
      let isMatchBrand = isMatchFilter(product, filters, 'brand_category');
      // Therapeutic area
      let isMatchTherapeuticArea = isMatchFilter(product, filters, 'owner');
      // Audience
      let isMatchAudience = isMatchFilter(product, filters, 'audience');
      // Indication
      let isMatchIndication = isMatchFilter(product, filters, 'indication');
      // Favorites
      if (filters.favorites) {
        isMatchFavourite = product.is_favourite;
      } else {
        isMatchFavourite = true;
      }
      if (filters.frequentlyOrdered) {
        isMatchFrequentlyOrdered = products.frequentlyOrdered.some(
          (item) => item.product === product.product_id,
        );
      } else {
        isMatchFrequentlyOrdered = true;
      }

      // Available
      if (isCheckedAvailable) {
        for (let i = 0; i < filters.available.length; i++) {
          const { title, checked } = filters.available[i];
          if (!checked) {
            continue;
          }
          // All Orderable Materialss
          if (title.includes('Orderable')) {
            if (product.product_available_qty > 0) {
              isMatchAvailable = true;
            }
          }
          if (isMatchAvailable) {
            break;
          }
          // All Active Materials
          if (title.includes('Active')) {
            if (
              product.product_available_qty > 0 ||
              product.product_available_qty < 1
            ) {
              isMatchAvailable = true;
            }
          }
          if (isMatchAvailable) {
            break;
          }
          // Out of Stock
          if (title.includes('Stock')) {
            if (product.product_available_qty < 1) {
              isMatchAvailable = true;
            }
          }
          if (isMatchAvailable) {
            break;
          }
          // Expired in the last 30 days
          if (title.includes('Expired')) {
            isMatchAvailable = isExpiredIn30Days(product);
          }
          if (isMatchAvailable) {
            break;
          }
          // Withdrawn in the last 30 days
          if (title.includes('Withdrawn')) {
            isMatchAvailable = isWithdrawnIn30Days(product);
          }
          if (isMatchAvailable) {
            break;
          }
        }
      } else {
        isMatchAvailable = true;
      }

      return (
        isMatchFavourite &&
        isMatchBrand &&
        isMatchTherapeuticArea &&
        isMatchAudience &&
        isMatchIndication &&
        isMatchAvailable &&
        isMatchFrequentlyOrdered
      );
    },
    [filters],
  );

  const sortByName = useCallback(
    (order) => (productIdA, productIdB) => {
      const productsObj = products.all.obj;
      const productA = productsObj?.[productIdA];
      const productB = productsObj?.[productIdB];
      const orderBy = filters.sorting.orderBy.toLowerCase();

      const a = productA?.[sortingMap?.[orderBy]]?.toLowerCase();
      const b = productB?.[sortingMap?.[orderBy]]?.toLowerCase();
      if (a > b) {
        return order === 'desc' ? -1 : 1;
      }
      if (b > a) {
        return order === 'desc' ? 1 : -1;
      }
      return 0;
    },
    [filters, products.all.obj],
  );

  const sortByDate = useCallback(
    (order) => (productIdA, productIdB) => {
      const productsObj = products.all.obj;
      const productA = productsObj?.[productIdA];
      const productB = productsObj?.[productIdB];
      const orderBy = filters.sorting.orderBy.toLowerCase();

      let a = productA?.[sortingMap?.[orderBy]];
      let b = productB?.[sortingMap?.[orderBy]];
      let compareResult;

      a = a ? new Date(a) : a;
      b = b ? new Date(b) : b;

      const compareFunc = order === 'desc' ? compareDesc : compareAsc;

      if (a & b) {
        compareResult = compareFunc(a, b);
      } else if (!a && !b) {
        compareResult = 0;
      } else if (!a) {
        compareResult = 1;
      } else if (!b) {
        compareResult = -1;
      }
      // If dates are equal, sort by name
      if (compareResult === 0) {
        const nameA = productA?.product_description?.toLowerCase();
        const nameB = productB?.product_description?.toLowerCase();
        if (nameA > nameB) {
          return order === 'desc' ? -1 : 1;
        }
        if (nameB > nameA) {
          return order === 'desc' ? 1 : -1;
        }
      }

      return compareResult;
    },

    [filters],
  );

  const sortByNumber = useCallback(
    (order) => (productIdA, productIdB) => {
      const productsObj = products.all.obj;
      const productA = productsObj?.[productIdA];
      const productB = productsObj?.[productIdB];
      const orderBy = filters.sorting.orderBy.toLowerCase();

      const keyString = sortingMap?.[orderBy];
      const arrKeys = keyString?.split('.');
      let a = productA?.[arrKeys?.[0]];
      let b = productB?.[arrKeys?.[0]];
      if (arrKeys?.length > 1) {
        a = a?.[arrKeys?.[1]];
        b = b?.[arrKeys?.[1]];
      }

      if (order === 'desc') {
        return a - b;
      } else {
        return b - a;
      }
    },
    [filters],
  );

  const sortByOrders = useCallback(
    (order) => {
      // resolve right callback to return, to get rid of unnecessary
      // order (desc/asc) comparing on each product
      if (order === 'desc') {
        return (firstId, secondId) => {
          const sortedByOrders = products.sortedByOrders;

          const firstValue = sortedByOrders[firstId] || 0;
          const secondValue = sortedByOrders[secondId] || 0;

          return secondValue - firstValue;
        };
      } else {
        return (firstId, secondId) => {
          const sortedByOrders = products.sortedByOrders;

          const firstValue = sortedByOrders[firstId] || 0;
          const secondValue = sortedByOrders[secondId] || 0;
          return firstValue - secondValue;
        };
      }
    },
    [products.sortedByOrders],
  );

  const sortProducts = useCallback(
    (allProductIds) => {
      const order = filters.sorting.order;
      const orderBy = filters.sorting.orderBy.toLowerCase();
      const { productIds, prescribedProductIds } = separatePrescribedProducts(
        allProductIds,
        products.all.obj,
      );

      if (orderBy === 'name') {
        return productIds
          .sort(sortByName(order))
          .concat(prescribedProductIds.sort(sortByName(order)));
      }
      if (orderBy === 'stock number') {
        return productIds
          .sort(sortByName(order))
          .concat(prescribedProductIds.sort(sortByName(order)));
      }
      if (orderBy === 'date') {
        return productIds
          .sort(sortByDate(order))
          .concat(prescribedProductIds.sort(sortByDate(order)));
      }
      if (orderBy === 'orders') {
        return productIds
          .sort(sortByOrders(order))
          .concat(prescribedProductIds.sort(sortByOrders(order)));
      }
      if (orderBy === 'rating') {
        return productIds
          .sort(sortByNumber(order))
          .concat(prescribedProductIds.sort(sortByNumber(order)));
      }
      return productIds;
    },
    [
      filters.sorting.order,
      filters.sorting.orderBy,
      products.all.obj,
      sortByName,
      sortByDate,
      sortByOrders,
      sortByNumber,
    ],
  );

  const applyAllFilters = useCallback(() => {
    const arr = (products.all.ids || [])?.filter((id) => {
      // Apply search value
      const product = (products.all.obj || {})?.[id];
      let isSearchMatch, isFiltersMatch;
      isFiltersMatch = applyFilters(product);
      isSearchMatch = applySearch(product);
      // if start date exists it should be before current date
      const isStartDateValid =
        !product.start_date ||
        isAfter(new Date(), new Date(product.start_date));
      return isSearchMatch && isFiltersMatch && isStartDateValid;
    });
    return sortProducts(arr);
  }, [applyFilters, applySearch, products.all.ids, sortProducts]);

  useEffect(() => {
    if (!initialFiltersApplied) {
      return;
    }
    setTimeout(() => {
      dispatch(ApplyFilters(applyAllFilters()));
      dispatch(SetLoading(false));
    }, 500);
  }, [applyAllFilters, dispatch, initialFiltersApplied]);

  useEffect(() => {
    if (initialFiltersApplied || !products?.all?.ids?.length) {
      return;
    }
    setInitialFiltersApplied(true);
    dispatch(ApplyFilters(applyAllFilters()));
  }, [
    applyAllFilters,
    dispatch,
    initialFiltersApplied,
    products?.all?.ids?.length,
  ]);

  const updateShownProducts = useCallback(async () => {
    dispatch(UpdateProductsPage());
  }, [dispatch]);

  const onChangeFilter = useCallback(
    (newFilter) => {
      dispatch(SetLoading(true));
      window.scroll(0, 0);
      dispatch(ChangeFilter(newFilter));
    },
    [dispatch],
  );

  const onMultipleSelectChange = useCallback(
    (name) => (event) => {
      const {
        target: { value },
      } = event;
      const checkedValues =
        typeof value === 'string' ? value.split(',') : value;

      if (name === 'available') {
        const checkedValue = checkedValues[checkedValues.length - 1];

        onChangeFilter({
          [name]: filters[name].map((item) => {
            return item.title === checkedValue
              ? { ...item, checked: true }
              : { ...item, checked: false };
          }),
        });
      } else {
        onChangeFilter({
          [name]: filters[name].map((item) =>
            checkedValues.includes(item.title)
              ? { ...item, checked: true }
              : { ...item, checked: false },
          ),
        });
      }
    },
    [filters, onChangeFilter],
  );

  const applyMobileFilters = useCallback(
    (filters) => {
      dispatch(SetLoading(true));
      dispatch(SetFilters(filters));
    },
    [dispatch],
  );

  const onRemoveFilter = useCallback(
    (filterTitle, title) => () => {
      onChangeFilter({
        [filterTitle]: filters[filterTitle].map((item) =>
          item.title === title ? { ...item, checked: false } : item,
        ),
      });
    },
    [filters, onChangeFilter],
  );

  const onClearAllFilters = useCallback(() => {
    dispatch(SetLoading(true));
    dispatch(ClearFilters());
  }, [dispatch]);

  const onChangeSorting = useCallback(
    (orderBy, order) => () => {
      dispatch(SetLoading(true));
      const newFilter = { ...filters.sorting };
      if (orderBy) {
        newFilter.orderBy = orderBy.toLowerCase();
      }
      if (order) {
        newFilter.order = order === 'desc' ? 'asc' : 'desc';
      }
      dispatch(ChangeFilter({ sorting: newFilter }));
    },
    [filters, dispatch],
  );

  return {
    products,
    updateShownProducts,
    onChangeFilter,
    filters,
    onMultipleSelectChange,
    applyMobileFilters,
    onRemoveFilter,
    onClearAllFilters,
    isLoading,
    onChangeSorting,
    applyFilters,
    applyAllFilters,
    sortProducts,
    sortByNumber,
    sortByDate,
    sortByName,
  };
};

export default useFilters;
