// Lib
import { createReducer } from '@reduxjs/toolkit';

// Actions
import { SetSearchValue, ClearSearchValue } from '../actions/search';

export const initialState = '';

export default createReducer(initialState, {
  [SetSearchValue]: (state, { payload }) => payload,
  [ClearSearchValue]: () => initialState,
});
