//Libs
import React from 'react';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

// Hooks
import useUser from '../../../hooks/useUser';

const useStyles = makeStyles((theme) => ({
  button: ({ isKiteUser, inTable }) => ({
    color: isKiteUser
      ? inTable
        ? theme.palette.primary1
        : 'white'
      : inTable
      ? theme.palette.pressed
      : 'white',
    fontWeight: isKiteUser ? '400' : '600',
    textDecoration: 'none',
    fontStyle: 'normal',
    fontSize: '14px',
    height: 44,
    lineHeight: '20px',
    letterSpacing: '0.1px',
    '&, &:hover': {
      backgroundColor: inTable ? '#F4F4F4 !important' : theme.palette.primary1,
      borderRadius: '20px',
    },
  }),
}));

const Reorder = ({
  order,
  kit,
  onClick,
  inTable,
  className,
  styles = {},
  disabled = false,
}) => {
  const { isKiteUser } = useUser();
  const classes = useStyles({ isKiteUser, inTable });

  return (
    <Button
      variant="contained"
      disabled={disabled}
      className={classnames(classes.button, className)}
      style={styles}
      onClick={() => onClick(order ? order : kit)}
      id="reorder"
      data-testid={'reorder-button'}>
      Reorder
    </Button>
  );
};

export default Reorder;
