// Libs
import { useDispatch } from 'react-redux';

// Actions
import { SetFavoriteValue } from '../actions/products';

const useFavorites = () => {
  const dispatch = useDispatch();

  const setFavoriteValue = (productId, favoriteValue, fromModal) => {
    dispatch(SetFavoriteValue({ productId, favoriteValue, fromModal }));
  };

  return {
    setFavoriteValue,
  };
};

export default useFavorites;
