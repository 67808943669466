import { format, differenceInDays } from 'date-fns';

export const getCurrentYear = () => new Date().getFullYear();

export const dateMatchForLast30Days = (date) => {
  if (!date) {
    return false;
  }
  const days = differenceInDays(
    new Date(date),
    new Date(format(new Date(), 'MM/dd/yy')),
  );

  return !!(days >= -30 && days <= 0);
};

export const isExpiredIn30Days = (product) => {
  const status = product?.doc_status?.toLowerCase();

  if (
    product?.offer_inactive &&
    status === 'expired' &&
    dateMatchForLast30Days(product?.expiration_date)
  ) {
    return true;
  }
};

export const isWithdrawnIn30Days = (product) => {
  const status = product?.doc_status?.toLowerCase();

  if (
    product?.offer_inactive &&
    status === 'withdrawn' &&
    dateMatchForLast30Days(product?.withdrawal_effective_date)
  ) {
    return true;
  }
};

export const getFormattedNextRunDate = (date) => {
  if (!date) {
    return;
  }

  const [year, month, day] = date.split('-');

  return `${month}/${day}/${year}`;
};

export const isPastDate = (date) => {
  return (
    new Date(format(date, 'yyyy-MM-dd')).getTime() -
      new Date(format(new Date(), 'yyyy-MM-dd')).getTime() <
    0
  );
};
