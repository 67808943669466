// Lib
import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Typography } from '@mui/material';

// Hooks
import useModal from '../../../hooks/useModal';

// Actions
import { SaveAddress } from '../../../actions/addressBook';
import { OpenModal, CloseModal } from '../../../actions/modal';
import { ClearApiError } from '../../../actions/apiError';

// Utils
import addShippingAddressInCart from '../utils/addShippingAddressInCart';

const useAddressFormat = (formData, editMode) => {
  const [isLoading, setIsLoading] = useState(false);
  const { addressBook, apiError, shippingAddresses } = useSelector((state) => ({
    addressBook: state?.addressBook?.addressBook,
    apiError: state.apiError.saveAddress,
    shippingAddresses: state.cart.shippingAddresses,
  }));
  const { pathname } = useLocation();
  const [selectedAddress, setSelectedAddress] = React.useState(
    addressBook.afterValidation.validated_address
      ? 'validated_address'
      : 'original_address',
  );
  const [isSaved, setIsSaved] = useState(false);
  const dispatch = useDispatch();
  const { onClose } = useModal();

  useEffect(() => {
    return () => dispatch(ClearApiError());
  }, [dispatch]);

  const onSaveAddress = useCallback(
    (selectedAddress, setIsLoading) => {
      if (!selectedAddress) {
        return;
      }
      const addressFields = selectedAddress.includes('original')
        ? addressBook.afterValidation.original_fields
        : addressBook.afterValidation.validated_fields;

      const addShippingAddress = addShippingAddressInCart(
        pathname,
        shippingAddresses,
        {
          ...formData,
          ...addressFields,
        }.country,
      );

      dispatch(
        SaveAddress({
          formData: {
            ...formData,
            ...addressFields,
          },
          setIsLoading: (...args) => {
            setTimeout(() => setIsLoading(...args), 500);
          },
          setIsSaved,
          editMode,
          addShippingAddress,
        }),
      );
    },
    [
      editMode,
      dispatch,
      formData,
      addressBook,
      setIsSaved,
      pathname,
      shippingAddresses,
    ],
  );

  const onChangeFormat = useCallback(
    (event) => {
      setSelectedAddress(event.target.value);
    },
    [setSelectedAddress],
  );

  const onSave = useCallback(() => {
    onSaveAddress(selectedAddress, setIsLoading);
  }, [onSaveAddress, selectedAddress]);

  useEffect(() => {
    if (!isSaved) {
      return;
    }
    if (isSaved) {
      dispatch(
        OpenModal({
          Component: () => (
            <Typography variant="h3" color="#54565B">
              {`${
                editMode ? 'The Address' : 'New Address'
              } Has Been Successfully ${editMode ? 'Edited.' : 'Added.'}`}
            </Typography>
          ),
          closeIcon: false,
          props: {
            isFullScreen: false,
            addressSavedCard: true,
            paddingContent: {
              xl: '20px',
              lg: '20px',
              md: '20px',
            },
          },
        }),
      );
      setTimeout(() => dispatch(CloseModal()), 2000);
    }
  }, [isSaved, dispatch, editMode]);

  return {
    onClose,
    isLoading,
    onSave,
    onChangeFormat,
    selectedAddress,
    apiError,
  };
};

export default useAddressFormat;
