// Libs
import React, { useEffect } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { makeStyles } from '@mui/styles';
import { yupResolver } from '@hookform/resolvers/yup';

// Components
import Select from '../../../../../Field/Select';
import Input from '../../../../../Field/Input';

// Constants
import {
  DAY_NUMBERS,
  SHIPPING_SCHEDULES,
  WEEK_DAYS,
} from '../../../../../../constants/cart';

// Hooks
import useUI from '../../../../../../hooks/useUI';

// Validation schema
import { scheduleDetailsSchema } from '../../../../../../validationSchema';
import classnames from 'classnames';
import Disclaimer from '../../../OrderProcessingInfo/Disclaimer';

const useStyles = makeStyles((theme) => ({
  internalRadioGroup: {
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        marginLeft: 0,
      }),
    },
  },
  radioLabel: {
    alignItems: 'center',
    marginRight: 0,
    width: 'fit-content',
    '& .MuiTypography-root': {
      fontSize: 18,
      [theme.breakpoints.down('md')]: {
        fontSize: 16,
      },
      [theme.breakpoints.downLandscape('md')]: {
        ...(theme.isMobileDevice && {
          fontSize: 16,
        }),
      },
    },
    '& .PrivateSwitchBase-root': {
      [theme.breakpoints.down('md')]: {
        marginTop: 2,
      },
      [theme.breakpoints.downLandscape('md')]: {
        ...(theme.isMobileDevice && {
          marginTop: 2,
        }),
      },
    },
    [theme.breakpoints.down('md')]: {
      alignItems: 'start',
      '& .MuiFormControlLabel-label': {
        marginLeft: '-28px',
      },
      '&:not(:last-child)': {
        marginBottom: '14px',
      },
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        alignItems: 'start',
        '& .MuiFormControlLabel-label': {
          marginLeft: '-28px',
        },
        '&:not(:last-child)': {
          marginBottom: '10px',
        },
      }),
    },
  },
  radioItem: {
    color: '#C6CAC6',
    padding: 8,
    '&$checked': { color: theme.palette.primary1 },
    [theme.breakpoints.down('md')]: {
      padding: 5,
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        padding: 5,
      }),
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 60,
    '& input': {
      textAlign: 'end',
    },
    '& #input-error': {
      fontSize: 12,
      marginTop: 0,
      position: 'absolute',
      width: 'max-content',
    },
  },
  select: {
    margin: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      margin: '2px 10px',
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        margin: '2px 10px',
      }),
    },
    '& .MuiFormControl-root#select': {
      '& > div': {
        borderRadius: '10px !important',
        color: theme.palette.black,
        fontWeight: 400,

        '& .MuiSelect-select > span': {
          fontSize: 16,
          fontWeight: 400,
          [theme.breakpoints.down('md')]: {
            fontSize: 14,
          },
          [theme.breakpoints.downLandscape('md')]: {
            ...(theme.isMobileDevice && {
              fontSize: 14,
            }),
          },
        },
      },
      '& fieldset': {
        border: `1px solid ${theme.palette.grey1}`,
        backgroundColor: 'initial',
      },
    },
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 16,
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      fontSize: 14,
      letterSpacing: '0.1px',
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        flexWrap: 'wrap',
        fontSize: 14,
        letterSpacing: '0.1px',
      }),
    },
  },
  margin: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
      }),
    },
  },
  span: {
    [theme.breakpoints.down('md')]: {
      marginLeft: 28,
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        marginLeft: 28,
      }),
    },
  },
  disclaimer: {
    left: 365,
    top: -14,
    width: 530,
    [theme.breakpoints.down('lg')]: {
      top: '50%',
      transform: 'translateY(-50%)',
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
      marginLeft: -6,
      position: 'initial',
      transform: 'none',
      width: 'min-content',
    },
  },
  weekDayDisclaimer: {
    left: 505,
    top: -28,
    width: 410,
    [theme.breakpoints.down('lg')]: {
      top: '50%',
      transform: 'translateY(-50%)',
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
      marginLeft: -6,
      position: 'initial',
      transform: 'none',
      width: 'min-content',
    },
  },
}));

const Monthly = ({
  monthly,
  onChangeMonthlyOption = () => {},
  onChangeSelect = () => {},
  onChangeMonthCount = () => {},
  setMonthError = () => {},
  disclaimer,
  openOrderProcessingWindow,
}) => {
  const classes = useStyles();
  const { isMobileMode } = useUI();

  const optionTitles = Object.keys(monthly);
  const checkedOptionTitle = optionTitles.find((item) => monthly[item].checked);

  const {
    control,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(scheduleDetailsSchema),
    defaultValues: {
      monthNumberForMonthDay: monthly?.monthDay?.monthNumber,
      monthNumberForWeekDay: monthly?.weekDay?.monthNumber,
    },
  });

  const { monthNumberForMonthDay, monthNumberForWeekDay } = errors;

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    if (monthly?.monthDay?.checked && monthNumberForMonthDay) {
      setMonthError(monthNumberForMonthDay?.message);
    } else if (monthly?.weekDay?.checked && monthNumberForWeekDay) {
      setMonthError(monthNumberForWeekDay?.message);
    } else {
      setMonthError('');
    }
  }, [setMonthError, monthNumberForMonthDay, monthNumberForWeekDay, monthly]);

  return (
    <RadioGroup
      className={classes.internalRadioGroup}
      value={checkedOptionTitle}
      row={false}
      aria-labelledby="address-format-radio-buttons-group-label"
      name="shipping-schedule-radio-buttons-group">
      <FormControlLabel
        className={classes.radioLabel}
        value={optionTitles[0]}
        control={
          <Radio
            classes={{
              root: classes.radioItem,
            }}
            onChange={onChangeMonthlyOption(0)}
          />
        }
        label={
          <div className={classes.container}>
            <span className={classes.span}>Day</span>
            <Select
              disabled={checkedOptionTitle !== 'monthDay'}
              initialValue={monthly?.monthDay?.dayNumber}
              classic
              required
              name="monthDay"
              onChange={onChangeSelect}
              width={isMobileMode ? 98 : 105}
              className={classes.select}
              placeholder="Select"
              items={new Array(31).fill(0).map((_, index) => ({
                title: index + 1,
                name: index + 1,
              }))}
            />
            Of Every
            <Controller
              name="monthNumberForMonthDay"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  disabled={checkedOptionTitle !== 'monthDay'}
                  maxLength={2}
                  onChange={onChangeMonthCount(field.onChange)}
                  name="monthNumberForMonthDay"
                  className={classes.input}
                  value={monthly?.monthDay?.monthNumber}
                  error={
                    SHIPPING_SCHEDULES[5].value &&
                    monthly?.monthDay?.checked &&
                    errors?.monthNumberForMonthDay?.message
                  }
                />
              )}
            />
            <span style={{ marginRight: 16 }}>Month(s)</span>
            {disclaimer && checkedOptionTitle === 'monthDay' && (
              <Disclaimer
                onClick={openOrderProcessingWindow}
                className={classes.disclaimer}
                disclaimer={disclaimer}
              />
            )}
          </div>
        }
      />
      <FormControlLabel
        className={classes.radioLabel}
        value={optionTitles[1]}
        control={
          <Radio
            classes={{
              root: classes.radioItem,
            }}
            onChange={onChangeMonthlyOption(1)}
          />
        }
        label={
          <div className={classes.container}>
            <span className={classes.span}>The</span>
            <Select
              disabled={checkedOptionTitle !== 'weekDay'}
              classic
              required
              initialValue={monthly?.weekDay?.dayNumber}
              className={classes.select}
              onChange={onChangeSelect}
              width={isMobileMode ? 98 : 105}
              placeholder="Select"
              items={DAY_NUMBERS}
              name="weekDayNumber"
            />
            <Select
              disabled={checkedOptionTitle !== 'weekDay'}
              initialValue={monthly.weekDay.weekDay}
              classic
              required
              className={classes.select}
              onChange={onChangeSelect}
              width={isMobileMode ? 105 : 130}
              placeholder="Select"
              name="weekDayTitle"
              items={WEEK_DAYS}
            />
            Of Every
            <Controller
              name="monthNumberForWeekDay"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  disabled={checkedOptionTitle !== 'weekDay'}
                  maxLength={2}
                  name="monthNumberForWeekDay"
                  className={classnames(classes.margin, classes.input)}
                  value={monthly?.weekDay?.monthNumber}
                  error={
                    SHIPPING_SCHEDULES[5].value &&
                    monthly?.weekDay?.checked &&
                    errors?.monthNumberForWeekDay?.message
                  }
                  onChange={onChangeMonthCount(field.onChange)}
                />
              )}
            />
            <span style={{ marginRight: 16 }}>Month(s)</span>
            {disclaimer && checkedOptionTitle === 'weekDay' && (
              <Disclaimer
                onClick={openOrderProcessingWindow}
                className={classes.weekDayDisclaimer}
                disclaimer={disclaimer}
              />
            )}
          </div>
        }
      />
    </RadioGroup>
  );
};

export default Monthly;
