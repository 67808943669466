//Libs
import React from 'react';
import { Button, IconButton as MaterialIconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

// Hooks
import useUser from '../../../hooks/useUser';

const useStyles = makeStyles((theme) => ({
  button: {
    color: ({ isKiteUser }) =>
      isKiteUser ? theme.palette.primary1 : theme.palette.pressed,
    fontWeight: ({ isKiteUser }) => (isKiteUser ? '400' : '600'),
    textDecoration: 'none',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    padding: 0,
    backgroundColor: 'transparent !important',

    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
}));

const IconButton = ({
  onClick,
  startIcon,
  endIcon,
  classname,
  styles,
  children,
  ...props
}) => {
  const { isKiteUser } = useUser;
  const classes = useStyles({ isKiteUser });
  return children ? (
    <Button
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      className={classNames(classes.button, classname)}
      style={styles}
      {...props}>
      {children}
    </Button>
  ) : (
    <MaterialIconButton
      onClick={onClick}
      className={classNames(classes.button, classname)}
      style={styles}
      {...props}>
      {startIcon}
    </MaterialIconButton>
  );
};

export default IconButton;
