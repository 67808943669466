// Lib
import React from 'react';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

// Component
import CloseButton from '../../../../Button/CloseButton';

// Hooks
import useCountComponent from '../CountComponent/useCountComponent';

const useStyles = makeStyles((theme) => ({
  button: {
    height: 24,
    top: 12,
    right: 14,
    width: 24,
    [theme.breakpoints.down('md')]: {
      height: 16,
      right: 5,
      top: 8,
      width: 16,
    },
  },
}));

const CloseComponent = ({ orderItem, product, order, className }) => {
  const classes = useStyles();
  const { onDelete } = useCountComponent(order, orderItem, product);

  return (
    <CloseButton
      className={classnames(classes.button, className)}
      onClose={onDelete}
    />
  );
};

export default CloseComponent;
