// Libs
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// Actions
import {
  UpdateKitProductQty,
  AddNewKitProduct,
  RemoveProductFromKit,
  SubmitNewKitProducts,
  UpdateNewKitProductQty,
  UpdateExistingKitProductQty,
  AddExistingKitProduct,
  CleanExistingKitProducts,
  CleanNewKitProducts,
} from '../../../actions/kits';
import { CloseModal } from '../../../actions/modal';

// Hooks
import useDeleteKits from './useDeleteKits';

const useKitsProducts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { kitId } = useParams();
  const {
    currentKit,
    selectingKitProducts,
    newKitProducts,
    existingKitProducts,
  } = useSelector((state) => state.kits);

  const { onDeleteMyKit, onDeleteMyKitInDetailsMode } = useDeleteKits();

  const getNewKitProductQty = (productId) => {
    const inTempBuffer = newKitProducts.find(
      (item) => item.productId === productId,
    );

    if (inTempBuffer) {
      return inTempBuffer.nums;
    } else {
      return null;
    }
  };

  const getExistingKitProductQty = (productId) => {
    const inTempBuffer = existingKitProducts.find(
      (item) => item.productId === productId,
    );

    if (inTempBuffer) {
      return inTempBuffer.nums;
    } else {
      return null;
    }
  };

  const getKitProductQty = (productId) => {
    const inKits = currentKit?.products.find(
      (item) => item.product === productId,
    );

    if (inKits) {
      return inKits.nums;
    } else {
      return null;
    }
  };

  const getBufferProductQty = (productId) => {
    const inNewProducts = getNewKitProductQty(productId);
    const inExistingProducts = getExistingKitProductQty(productId);
    if (inNewProducts) {
      return inNewProducts;
    } else if (inExistingProducts) {
      return inExistingProducts;
    } else {
      return null;
    }
  };

  const getProductQty = (productId) => {
    const inBuffer = getBufferProductQty(productId);
    const inKit = getKitProductQty(productId);

    if (inBuffer) {
      return inBuffer;
    } else if (inKit) {
      return inKit;
    } else {
      return null;
    }
  };

  const updateKitProductQty = (kitId, productId, newQty) => {
    dispatch(UpdateKitProductQty({ kitId, productId, newQty }));
  };

  const addNewKitProduct = (productId, qty) => {
    const inNewProducts = getNewKitProductQty(productId);
    const inExistingProducts = getExistingKitProductQty(productId);
    const inKits = getProductQty(productId);

    if (inNewProducts !== null) {
      dispatch(UpdateNewKitProductQty({ productId, newQty: qty }));
    } else if (inExistingProducts !== null) {
      dispatch(UpdateExistingKitProductQty({ productId, newQty: qty }));
    } else if (inKits) {
      dispatch(AddExistingKitProduct({ productId, qty }));
    } else {
      dispatch(AddNewKitProduct({ productId, qty }));
    }
  };

  const removeProductFromKit = (kitId, productId) => {
    dispatch(RemoveProductFromKit({ kitId, productId }));
  };

  const submitNewKitProducts = () => {
    dispatch(
      SubmitNewKitProducts({
        kitId: currentKit?.id,
        callback: () => navigate(`/kits/${kitId}`),
      }),
    );
  };

  const openMaterialsModal = (kitId) => {
    navigate(`/kits/${kitId}/materials`);
  };

  const closeMaterialsModal = () => {
    dispatch(CleanExistingKitProducts());
    dispatch(CleanNewKitProducts());
    navigate(`/kits/${kitId}`);
  };

  const deleteKit = (detailsMode) => {
    if (detailsMode) {
      onDeleteMyKitInDetailsMode(currentKit.id);
    } else {
      onDeleteMyKit(currentKit.id);
    }
  };

  const removeOneProductFromKit = (count, productId, detailsMode) => {
    if (count === 1) {
      if (currentKit.products.length === 1) {
        deleteKit(detailsMode);
      } else {
        removeProductFromKit(currentKit.id, productId);
        dispatch(CloseModal());
      }
    } else {
      updateKitProductQty(
        currentKit.id,
        productId,
        count === 0 ? 0 : count - 1,
      );
    }
  };

  const setCountForProductInKit = (count, productId, detailsMode) => {
    if (count === 0) {
      if (currentKit.products.length === 1) {
        deleteKit(detailsMode);
      } else {
        removeProductFromKit(currentKit.id, productId);
        dispatch(CloseModal());
      }
    } else {
      updateKitProductQty(currentKit.id, productId, count);
    }
  };

  return {
    currentKit,
    selectingKitProducts,
    updateKitProductQty,
    removeProductFromKit,
    removeOneProductFromKit,
    setCountForProductInKit,
    openMaterialsModal,
    closeMaterialsModal,
    addNewKitProduct,
    getProductQty,
    newKitProducts,
    existingKitProducts,
    submitNewKitProducts,
  };
};

export default useKitsProducts;
