// Lib
import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Switch as MuiSwitch } from '@mui/material';

const Switch = ({ label, className, checked, onChange, name }) => {
  return (
    <FormGroup>
      <FormControlLabel
        className={className}
        control={
          <MuiSwitch
            onChange={() => onChange({ [name]: !checked })}
            checked={checked}
          />
        }
        label={label}
      />
    </FormGroup>
  );
};

export default Switch;
