// Lib
import React, { useCallback, useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, Box } from '@mui/material';
import classnames from 'classnames';
import { format } from 'date-fns';

// Components
import OrderInfoItem from './OrderInfoItem';
import ToggleButton from '../../../Button/ToggleButton';
import Feather from '../../../Feather';

// Hooks
import useUI from '../../../../hooks/useUI';

const useStyles = makeStyles((theme) => ({
  orderInfoTitle: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      marginBottom: ({ open }) => (open ? theme.spacing(2) : theme.spacing(3)),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        marginBottom: ({ open }) =>
          open ? theme.spacing(2) : theme.spacing(3),
      }),
    },
  },
  root: {
    alignItems: 'flex-start',
    boxShadow: '0px 5px 17px rgba(48, 85, 107, 0.2)',
    borderRadius: 10,
    display: 'flex',
    padding: '8px 16px',
    gap: 10,
    marginBottom: '30px',
    minHeight: 90,
  },
  toggleButton: {
    marginLeft: theme.spacing(1.5),
    backgroundColor: 'white',
    '& svg': {
      height: 14,
      width: 14,
      '& path': {
        stroke: theme.palette.darkGrey,
      },
    },
  },
  orderInfo: {
    [theme.breakpoints.down('md')]: {
      display: 'block',
      fontSize: 14,
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        display: 'block',
        fontSize: 14,
        marginBottom: theme.spacing(1),
      }),
    },
  },
  orderInfoId: {
    fontWeight: 700,
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1.5),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        marginBottom: theme.spacing(1.5),
      }),
    },
  },
  lastOrderInfo: {
    marginBottom: theme.spacing(3),
  },
}));

const OrderInfo = ({
  order,
  addressesCount,
  isApproved = false,
  isMethodPage = false,
  onOpenShippingModal,
  className,
}) => {
  const { isDesktopMode } = useUI();
  const [open, setOpen] = useState(false);
  const classes = useStyles({ open });

  const isDistro = useMemo(() => addressesCount > 1, [addressesCount]);

  const {
    order_sn: orderId,
    created: orderDate,
    user: originator,
    addresses,
    shipment_method: method,
    additional_info_limit: comment,
    additional_info_shipment: shippingComment,
  } = order || {};

  const onToggle = useCallback(
    () => setOpen((prevState) => !prevState),
    [setOpen],
  );

  const date = useMemo(() => {
    if (orderDate) {
      return format(new Date(orderDate), 'MM/dd/yyyy');
    }
  }, [orderDate]);

  return (
    <>
      {isDesktopMode ? (
        <>
          <Typography mb={3} variant="h2">
            Order Info
          </Typography>
          <div className={classnames(className, classes.root)}>
            <OrderInfoItem
              width={isDistro ? '10%' : '8%'}
              title="Order ID"
              text={orderId}
            />
            <OrderInfoItem width="8%" title="Order date" text={date} />
            <OrderInfoItem
              width={isDistro ? '17%' : '15%'}
              textColor="#C5203F"
              title="Originator"
              text={originator}
            />
            <OrderInfoItem
              width={isDistro ? '15%' : '25%'}
              title={isDistro ? 'Total orders' : 'Ship-to address'}
              text={isDistro ? addressesCount : addresses?.full_address}
            />
            <OrderInfoItem
              width={isDistro ? '20%' : '16%'}
              title="Shipping method"
              text={method}
              isMethodPage={isMethodPage}
              isApproved={isApproved}
              onOpenShippingModal={onOpenShippingModal}
            />
            <OrderInfoItem
              width="24%"
              title="Comment"
              text={isMethodPage ? shippingComment : comment}
            />
          </div>
        </>
      ) : (
        <>
          <Box display="flex">
            <Typography className={classes.orderInfoTitle} variant="h3">
              Order Info
            </Typography>
            <ToggleButton
              open={open}
              onToggle={onToggle}
              className={classes.toggleButton}
              DownElement={<Feather printType="stroke" type="arrowDownSmall" />}
              UpElement={<Feather printType="stroke" type="arrowUpSmall" />}
            />
          </Box>
          {open && (
            <>
              <OrderInfoItem
                className={classnames(classes.orderInfo, classes.orderInfoId)}
                title="Order ID"
                text={orderId}
              />
              <OrderInfoItem
                className={classes.orderInfo}
                title="Order date"
                text={date}
              />
              <OrderInfoItem
                className={classes.orderInfo}
                textColor="#C5203F"
                title="Originator"
                text={originator}
              />
              {!isDistro && (
                <OrderInfoItem
                  className={classes.orderInfo}
                  title="Ship-to address"
                  text={addresses.full_address}
                />
              )}
              {!isMethodPage && (
                <OrderInfoItem
                  className={classes.orderInfo}
                  title="Shipping method"
                  text={method}
                />
              )}
              <OrderInfoItem
                className={classnames(classes.orderInfo, classes.lastOrderInfo)}
                title="Comment"
                text={comment}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default OrderInfo;
