const getGridPropValues =
  (isTabletMode, isMobileMode) =>
  ({ lgSize, mdSize, unit }) => {
    if (lgSize && isTabletMode && !isMobileMode) {
      return unit ? `${(lgSize * 100) / 12}${unit}` : lgSize;
    }

    if (mdSize && isMobileMode) {
      return unit ? `${(mdSize * 100) / 12}${unit}` : mdSize;
    }

    if (!mdSize && isMobileMode) {
      return 'initial';
    }
  };

export default getGridPropValues;
