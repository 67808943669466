// Lib
import React, { useMemo } from 'react';
import { makeStyles } from '@mui/styles';

// Components
import Table from '../../Table';
import ProductCard from './ProductCard';
import TableRow from './TableRow';

// Hooks
import useUI from '../../../hooks/useUI';
import useProductsCellReducers from '../../../pages/Approvals/hooks/useProductsCellReducers';

// Constants
import { PRODUCTS_COLUMNS } from '../../../constants/approvals';

const useStyles = makeStyles((theme) => ({
  table: ({ isDistro, isMethodPage }) => ({
    borderSpacing: '0 16px',
    borderCollapse: 'separate',
    ...(isMethodPage && {
      '& .MuiTableCell-root': {
        padding: '5px 22px',
      },
    }),
    '& thead tr th': {
      fontWeight: 600,
      '&:nth-child(1)': {
        paddingRight: 0,
        paddingLeft: theme.spacing(3),
        width: isMethodPage ? '12%' : '15%',
      },
      '&:nth-child(2)': {
        paddingRight: 0,
        width: isMethodPage ? '5%' : '10%',
      },
      '&:nth-child(3)': {
        width: isMethodPage ? '11%' : '13%',
      },
      '&:nth-child(4)': {
        width: isMethodPage ? '5%' : '7%',
      },
      '&:nth-child(5)': {
        textAlign: 'right',
        width: isMethodPage ? '8%' : '9%',
      },
      '&:nth-child(6)': {
        textAlign: 'right',
        width: isMethodPage ? '4%' : '6%',
      },
      '&:nth-child(7)': {
        textAlign: 'right',
        width: isDistro ? '5%' : '9%',
      },
      '&:nth-child(8)': {
        textAlign: 'right',
        width: isDistro ? '6%' : '7%',
      },
      '&:nth-child(9)': {
        textAlign: 'right',
        width: '6%',
      },
      '&:nth-child(10)': {
        width: isDistro ? '5%' : '17%',
      },
      ...(isDistro && {
        '&:nth-child(11)': {
          width: '18%',
        },
      }),
    },
  }),
  row: ({ isDistro }) => ({
    boxShadow: '0px 5px 17px rgba(48, 85, 107, 0.2)',
    borderRadius: 10,
    '& th, & td': {
      fontSize: 14,
      paddingBottom: 5,
      paddingTop: 5,
      lineHeight: '20px',
    },
    '& td': {
      '&:nth-child(1)': {
        paddingLeft: theme.spacing(3),
        paddingRight: 0,
      },
      '&:nth-child(2)': {
        paddingRight: 0,
      },
      '&:nth-child(5)': {
        textAlign: 'right',
      },
      '&:nth-child(6)': {
        paddingLeft: 0,
        paddingRight: 12,
        textAlign: 'right',
      },
      '&:nth-child(7)': {
        textAlign: 'right',
      },
      '&:nth-child(8)': {
        textAlign: 'right',
      },
      '&:nth-child(9)': {
        textAlign: 'right',
      },
      ...(isDistro && {
        '&:nth-child(10)': {
          textAlign: 'right',
        },
      }),
    },
  }),
}));

const ProductsList = ({ approvals, addressesCount, isMethodPage = false }) => {
  const classes = useStyles({ isDistro: addressesCount > 1, isMethodPage });
  const { isDesktopMode } = useUI();

  const products = useMemo(() => {
    if (approvals?.approvals) {
      return Object.keys(approvals?.approvals?.products_limits).map(
        (productId) => ({
          ...approvals.approvals?.products_limits[productId],
          product_id: productId,
          ...approvals.approvals?.products_limits[productId].product_details,
        }),
      );
    }
    return [];
  }, [approvals]);

  const productsForApprove = useMemo(
    () =>
      isMethodPage ? products : products.filter((product) => product.approvers),
    [products, isMethodPage],
  );

  return (
    <>
      {isDesktopMode ? (
        <Table
          useCellsReducers={useProductsCellReducers}
          data={productsForApprove}
          TableRow={TableRow}
          columns={PRODUCTS_COLUMNS(addressesCount > 1, isMethodPage)}
          styles={classes}
          props={{ addressesCount, isMethodPage }}
        />
      ) : (
        productsForApprove.map((product) => {
          return (
            <ProductCard
              isMethodPage={isMethodPage}
              addressesCount={addressesCount}
              key={product.product_id}
              product={product}
            />
          );
        })
      )}
    </>
  );
};

export default ProductsList;
