// Lib
import { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { format, isValid, isPast } from 'date-fns';

// Actions
import {
  ClearEvent,
  GetShippingMethods,
  GetShippingNotice,
  SetShippingDetails,
} from '../../../actions/cart';

// Hooks
import useRequest from './useRequest';

// Constants
import {
  SHIPPING_SCHEDULES_ONLY_FEDEXGROUND,
  SHIPPING_SCHEDULE_WITH_DATES,
  SHIPPING_SCHEDULES,
} from '../../../constants/cart';

// Assets
import { wrongFormat } from '../../../constants/cart';

// Utils
import { isPastDate } from '../../../utils/date';

const useDetailsForm = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { openRequestCard } = useRequest();
  const [loadingMethods, setLoadingMethods] = useState(false);
  const [loadingNotice, setLoadingNotice] = useState(false);
  const [incorrectFormatMessage, setIncorrectFormatMessage] = useState('');
  const inputFileRef = useRef(null);
  const { shippingDetails, shippingAddresses, apiError } = useSelector(
    (state) => ({
      shippingDetails: state.cart.shippingDetails,
      shippingAddresses: state.cart.shippingAddresses,
      apiError: state.apiError?.['getShippingMethods'],
    }),
  );
  const onSaveDetails = useCallback(() => {}, []);

  useEffect(() => {
    if (!shippingAddresses.length) {
      return navigate('/cart/shipping-address');
    }
    dispatch(GetShippingNotice({ setLoadingNotice }));
    const scheduleWithDate = SHIPPING_SCHEDULE_WITH_DATES.includes(
      shippingDetails.schedule,
    );
    if (scheduleWithDate && !shippingDetails?.date) {
      return;
    }

    const data = {
      schedule_date_type: shippingDetails.schedule,
      country: shippingAddresses?.[0]?.country,
    };

    if (scheduleWithDate) {
      const date = new Date(shippingDetails?.date);
      if (isValid(date) && !isPastDate(date)) {
        data.schedule_date = format(date, 'yyyy-MM-dd');
      }
      if (
        !isValid(date) ||
        isPastDate(date) ||
        (shippingDetails.schedule === SHIPPING_SCHEDULES[3].value &&
          isPast(date))
      ) {
        dispatch(
          SetShippingDetails({
            type: 'methods',
            value: [],
          }),
        );

        //  Skip api request with invalid date or past date
        return;
      }
    }

    dispatch(
      GetShippingMethods({
        setLoadingMethods,
        data,
      }),
    );
  }, [
    dispatch,
    shippingAddresses,
    shippingDetails?.schedule,
    shippingDetails?.date,
    navigate,
    pathname,
  ]);

  const onChangeDetails = useCallback(
    (type) =>
      ({ target }) => {
        if (
          type === 'schedule' &&
          SHIPPING_SCHEDULES_ONLY_FEDEXGROUND.includes(target.value)
        ) {
          dispatch(
            SetShippingDetails({
              type: 'method',
              value: { name: 'Fed Ex Ground', id: 1 },
            }),
          );
          dispatch(
            SetShippingDetails({
              type: 'methods',
              value: [
                {
                  label: 'Fed Ex Ground',
                  value: 'Fed Ex Ground',
                  name: 'Fed Ex Ground',
                  id: 1,
                },
              ],
            }),
          );
          dispatch(ClearEvent());
        }
        if (SHIPPING_SCHEDULE_WITH_DATES.includes(target.value)) {
          dispatch(SetShippingDetails({ type: 'methods', value: [] }));
        }
        if (type === 'schedule') {
          dispatch(SetShippingDetails({ type, value: target.value }));
        }
        if (type === 'method') {
          const method = shippingDetails.methods.find(
            (method) => method.id === +target.id,
          );
          dispatch(SetShippingDetails({ type, value: method }));
        }
        if (
          type === 'method' &&
          target.name === 'requiredRequest' &&
          !shippingDetails?.shippingRequest
        ) {
          openRequestCard();
        }
      },
    [
      dispatch,
      openRequestCard,
      shippingDetails?.shippingRequest,
      shippingDetails?.methods,
    ],
  );

  const onChangeEvent = useCallback(
    ({ value, name }) => {
      dispatch(
        SetShippingDetails({
          type: 'event',
          value: { ...shippingDetails.event, [name]: value },
        }),
      );
    },
    [dispatch, shippingDetails],
  );

  const onChangeFile = useCallback(
    ({ files }) => {
      setIncorrectFormatMessage('');
      const file = files[0];
      if (!file?.type.includes('pdf')) {
        setIncorrectFormatMessage(wrongFormat);
        return;
      }
      dispatch(
        SetShippingDetails({
          type: 'event',
          value: {
            ...shippingDetails.event,
            file: {
              data: file,
              name: file.name,
            },
          },
        }),
      );
    },
    [dispatch, shippingDetails],
  );

  const onRemoveFile = useCallback(() => {
    const input = inputFileRef?.current?.getElementsByTagName('input')?.[0];
    const dt = new DataTransfer();
    if (input) {
      input.files = dt.files;
    }
    dispatch(
      SetShippingDetails({
        type: 'event',
        value: {
          ...shippingDetails.event,
          file: { dataUrl: '', name: '' },
        },
      }),
    );
  }, [dispatch, inputFileRef, shippingDetails.event]);

  return {
    shippingDetails,
    shippingAddresses,
    inputFileRef,
    loadingMethods,
    loadingNotice,
    onSaveDetails,
    onChangeDetails,
    openRequestCard,
    onChangeEvent,
    onChangeFile,
    incorrectFormatMessage,
    onRemoveFile,
    apiError,
  };
};

export default useDetailsForm;
