const supportTabs = [
  { label: 'Support', path: '/support' },
  { label: 'FAQ', path: '/support/FAQ' },
];

const FAQSubnavigationTabs = [
  { label: 'Materials', key: 'materials' },
  { label: 'Ordering', key: 'ordering' },
  { label: 'Tracking', key: 'tracking' },
  { label: 'Shipping', key: 'shipping' },
  { label: 'Using the app', key: 'using-the-app' },
];

const supportCards = {
  mops: {
    header: 'MOPs',
    text: 'Questions concerning marketing material content or orders',
  },
  brandAppropriatePerson: {
    header: 'Brand-appropriate person within Gilead',
    text: 'Questions concerning collaterial-related topics',
  },
  userGuide: {
    header: 'GMOP User Guide',
    text: 'This guide highlights core functionality and offerings of the site, helps to navigate the site',
  },
};

export { supportTabs, FAQSubnavigationTabs, supportCards };
