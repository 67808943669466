// Libs
import React from 'react';
import { Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

// Constants
import {
  emptyKitsMessage,
  emptyKitsButtonTitle,
} from '../../../constants/kits';

const useStyles = makeStyles((theme) => ({
  emptyKitsContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 'calc(100vh - 289px)',
  },
  emptyKitsBook: {
    marginBottom: theme.spacing(5),
    width: 540,
    [theme.breakpoints.down('md')]: {
      width: 290,
    },
  },
  emptyKitsButton: {
    height: 44,
    width: 176,
    [theme.breakpoints.down('md')]: {
      width: 170,
    },
  },
}));

const EmptyKitsScreen = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  return (
    <div className={classes.emptyKitsContainer}>
      <Typography
        className={classes.emptyKitsBook}
        variant="h1"
        textAlign="center">
        {emptyKitsMessage}
      </Typography>
      <Button
        data-testid="empty-kits-redirect-button"
        variant="contained"
        onClick={() => {
          navigate('/order-history');
        }}
        className={classes.emptyKitsButton}>
        {emptyKitsButtonTitle}
      </Button>
    </div>
  );
};

export default EmptyKitsScreen;
