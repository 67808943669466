// Libs
import { put, all, call, take, delay } from 'redux-saga/effects';

// Actions
import {
  SetReorderProcessing,
  SetOrderConfirmation,
} from '../../../actions/orders';
import { OpenModal, CloseModal } from '../../../actions/modal';
import { AddToCart, SetCount } from '../../../actions/cart';

//Components
import OrderConfirmation from '../../../components/OrderHistory/Modals/OrderConfirmation/OrderConfirmation';

// Store
import { store } from '../../../store';

// Utils
import isProductAvailable from '../../../utils/isProductAvailable';

// Constants
import {
  PRODUCT_OUT_OF_STACK,
  PRODUCTS_OUT_OF_STACK,
  REORDER_ENTITY_WITHOUT_PRODUCT_MESSAGE,
  REORDER_ENTITY_WITHOUT_PRODUCTS_MESSAGE,
} from '../../../constants/common';

function* reorderSaga({ payload }) {
  try {
    const { order, cancelReorder, confirmReorder, navigateToCart } = payload;
    const allProducts = store.getState().products?.all?.obj;

    const unavailableProducts = order.order_items.filter(
      ({ product: productId }) => {
        const product = allProducts[productId];
        return product ? !isProductAvailable(product) : false;
      },
    );

    if (unavailableProducts.length > 0) {
      const confirmation = yield call(
        waitForUserConfirmation,
        unavailableProducts.map((item) => allProducts[item.product]),
        cancelReorder,
        confirmReorder,
      );

      if (!confirmation) {
        yield put(CloseModal());
        return;
      }
    }

    yield put(SetReorderProcessing(true));
    const unavailableProductsIds = unavailableProducts.length
      ? unavailableProducts.map(({ product }) => product)
      : [];
    const newCartItems = yield call(
      getNewCartItems,
      order,
      unavailableProductsIds,
    );

    const cartItems = store.getState().cart?.forOrder || [];

    yield all(
      newCartItems.map((newItem) => {
        const itemAlreadyInCart = cartItems.find(
          (item) => item.product === newItem.product,
        );

        if (itemAlreadyInCart) {
          const nums = itemAlreadyInCart.nums + newItem.nums;
          return put(SetCount({ ...itemAlreadyInCart, nums }));
        } else {
          return put(AddToCart(newItem));
        }
      }),
    );

    yield delay(500);

    yield put(SetReorderProcessing(false));

    yield put(CloseModal());

    yield call(navigateToCart);
  } catch (e) {
    console.log(e);
  }
}

export function* getNewCartItems(order, unavailableProductsIds) {
  const newCartItems = order.order_items
    .filter((item) => !unavailableProductsIds.includes(item.product))
    .map((item) => ({ product: item.product, nums: item.qty_ordered }));

  return newCartItems;
}

export function* waitForUserConfirmation(
  unavailableProducts,
  cancelReorder,
  confirmReorder,
) {
  yield put(
    OpenModal({
      Component: OrderConfirmation,
      props: {
        unavailableProducts,
        header:
          unavailableProducts.length === 1
            ? PRODUCT_OUT_OF_STACK
            : PRODUCTS_OUT_OF_STACK,
        description:
          unavailableProducts.length === 1
            ? REORDER_ENTITY_WITHOUT_PRODUCT_MESSAGE('Order')
            : REORDER_ENTITY_WITHOUT_PRODUCTS_MESSAGE('Order'),
        cancelAction: cancelReorder,
        confirmAction: confirmReorder,
      },
      styles: {
        width: 'min(90vw, 651px)',
      },
    }),
  );

  const { payload: confirmation } = yield take(SetOrderConfirmation);

  return confirmation;
}

export default reorderSaga;
