// Libs
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Selectors
import { productsObjSelector } from '../../../store/selectors';

// Actions
import { GetMyKits } from '../../../actions/kits';

const useKits = () => {
  const dispatch = useDispatch();
  const searchValue = useSelector((state) => state.search);
  const productsStorage = useSelector((state) => productsObjSelector(state));
  const apiError = useSelector((state) => state.apiError?.['getMyKits']);
  const { isLoading, kits } = useSelector((state) => state);

  const filteredKits = useMemo(() => {
    if (searchValue) {
      const queries = searchValue.toLowerCase().split(' ');
      return kits.data?.filter(
        (kit) =>
          queries.every((query) => kit.name.toLowerCase().includes(query)) ||
          kit.products.some((item) => {
            return queries.every(
              (query) =>
                item.product.toLowerCase().includes(query) ||
                productsStorage[item.product].product_description
                  .toLowerCase()
                  .includes(query),
            );
          }),
      );
    } else {
      return kits.data;
    }
  }, [kits.data, productsStorage, searchValue]);

  useEffect(() => {
    dispatch(GetMyKits());
  }, [dispatch]);

  return {
    isLoading,
    kits,
    filteredKits,
    apiError,
  };
};

export default useKits;
