// Utils
import capitalize from './capitalize';

// Constants
import { COMMON_API_ERROR } from '../constants/errors';

export const parseAddressErrors = (errors) => {
  if (errors.addressExists) {
    return errors.addressExists;
  }

  const fields = [
    { name: 'first_name', value: 'First name' },
    { name: 'last_name', value: 'Last name' },
    { name: 'zip', value: 'Zip' },
    { name: 'phone', value: 'Phone' },
    { name: 'address_type', value: 'Address type' },
    { name: 'address1', value: 'Address 1' },
    { name: 'country', value: 'Country' },
    { name: 'state', value: 'State' },
    { name: 'city', value: 'City' },
  ];

  let error = 'Invalid address:';

  fields.map((field) => {
    if (errors[field.name]) {
      error = `${error} ${field.value} (${errors[field.name][0]})`;
    }
  });

  return error;
};

export const getErrorString = (errors) => {
  let string = '';

  if (typeof errors === 'string') {
    return COMMON_API_ERROR;
  }

  if (Array.isArray(errors)) {
    return errors[0];
  }

  Object.entries(errors).map(([key, error]) => {
    if (error?.[0]?.includes('field is required')) {
      if (string && string?.[string?.length - 1] === '.') {
        string = string?.slice(0, string?.length - 1);
      }
      if (string) {
        string += ', ';
      }
      string += `${key?.replace(/_/, ' ')}: ${error?.[0]}`;
      string = capitalize(string);
    } else if (string) {
      string += `, ${error[0]}`;
    } else {
      string = error[0];
    }
  });

  return string ? string : COMMON_API_ERROR;
};
