// Lib
import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

// Components
import Feather from '../../../Feather';
import Button from '../../../Button';
import Spinner from '../../../Spinner';

// Hooks
import useAddressFormat from '../../../../pages/AddressBook/hooks/useAddressFormat';

// Styles
import { colors } from '../../../../styles/palette';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }),
    },
  },
  title: {
    letterSpacing: '-0.4px',
  },
  infoText: {
    color: '#54565B',
    fontSize: 18,
    letterSpacing: '0.2px',
    width: '61%',
    [theme.breakpoints.down('lg')]: {
      width: '65%',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
      width: 250,
      marginTop: theme.spacing(1),
    },
  },
  address: {
    color: '#54565B',
    fontSize: 18,
    letterSpacing: '0.2px',
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  error: {
    fontSize: 18,
    color: colors.gilead.primary1,
    marginBottom: theme.spacing(2.5),
  },
  label: {
    fontSize: 18,
    fontWeight: 700,
    marginTop: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      marginTop: theme.spacing(3.75),
      marginBottom: theme.spacing(1),
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 110,
    '& button': {
      width: 138,
    },
    '& button:nth-child(2)': {
      color: theme.palette.primary1,
    },
    [theme.breakpoints.down('lg')]: {
      alignItems: 'end',
      flex: 1,
      '& button:nth-child(1)': {
        width: 200,
      },
      '& button:nth-child(2)': {
        width: 90,
      },
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        alignItems: 'end',
        flex: 1,
      }),
    },
    [theme.breakpoints.down('md')]: {
      '& button': {
        height: 44,
      },
      '& button:nth-child(1)': {
        width: 204,
      },
      '& button:nth-child(2)': {
        paddingRight: 0,
        width: 92,
      },
    },
  },
}));

const ErrorAddress = ({
  originalAddress,
  onBackToForm,
  editMode,
  enteredAddress,
}) => {
  const classes = useStyles();
  const { onSave, apiError } = useAddressFormat(enteredAddress, editMode);

  return (
    <div className={classes.root}>
      <div className={classes.error}>
        <Feather
          className={classes.title}
          style={{ marginRight: 8 }}
          nativeColor
          type="error"></Feather>
        Error
      </div>
      <Typography variant="h2">
        We Were Unable To Validate This Address.
      </Typography>
      <div className={classes.infoText}>
        Go Back And Change The Address To Revalidate Or Save It As It Is.
      </div>
      <div className={classes.label}>Entered Address</div>
      <div className={classes.address}>{originalAddress}</div>
      {apiError && <p style={{ color: colors.gilead.primary1 }}>{apiError}</p>}
      <div className={classes.buttons}>
        <Button onClick={onBackToForm}>Back</Button>
        <Button
          loadingIndicator={<Spinner size={20} height={24} />}
          variant="text"
          onClick={onSave}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default ErrorAddress;
