// Lib
import React, { useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

// Components
import Breadcrumbs from '../../Breadcrumbs';

// Hooks
import useUI from '../../../hooks/useUI';

// Constants
import {
  REVIEW_ORDER_BREADCRUMBS,
  SHIPPING_DETAILS_BREADCRUMBS,
} from '../../../constants/cart';

const useStyles = makeStyles((theme) => ({
  bar: {
    backgroundColor: 'white',
    margin: '0 auto',
    maxWidth: 1180,
    position: 'sticky',
    padding: `40px 26px 12px`,
    top: 50,
    zIndex: 100,
    [theme.breakpoints.down('lg')]: {
      top: 40,
    },
    [theme.breakpoints.down('md')]: {
      top: 50,
      padding: `${theme.spacing(2.5)} ${theme.spacing(1.5)} 12px`,
    },
  },
  title: {
    [theme.breakpoints.down('md')]: {
      fontSize: 22,
    },
  },
}));

const Header = (props) => {
  const empty = props?.empty || false;
  const classes = useStyles();
  const { isDesktopMode } = useUI();
  const { pathname } = useLocation();

  const items = useMemo(() => {
    if (pathname === '/cart/shipping-details') {
      return SHIPPING_DETAILS_BREADCRUMBS;
    }
    if (pathname === '/cart/review-order') {
      return REVIEW_ORDER_BREADCRUMBS;
    }
  }, [pathname]);

  return (
    <>
      {empty ? (
        <></>
      ) : (
        <div className={classes.bar}>
          {isDesktopMode ? (
            <Breadcrumbs items={items} />
          ) : (
            <Typography className={classes.title} variant="h2">
              {items[items.length - 1].title}
            </Typography>
          )}
        </div>
      )}
    </>
  );
};

export default Header;
