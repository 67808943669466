// Lib
import React from 'react';
import { makeStyles } from '@mui/styles';

// Components
import { ReactComponent as OutOfStock } from '../../../assets/svg/outOfStock.svg';
import { ReactComponent as Expired } from '../../../assets/svg/expired.svg';
import { ReactComponent as Withdrawn } from '../../../assets/svg/withdrawn.svg';
import { ReactComponent as Unavailable } from '../../../assets/svg/unavailable.svg';
import { ReactComponent as OutOfStockKite } from '../../../assets/svg/outOfStockKite.svg';
import { ReactComponent as ExpiredKite } from '../../../assets/svg/expiredKite.svg';
import { ReactComponent as WithdrawnKite } from '../../../assets/svg/withdrawnKite.svg';
import { ReactComponent as UnavailableKite } from '../../../assets/svg/unavailableKite.svg';

// Hooks
import useUser from '../../../hooks/useUser';

const useStyles = makeStyles((theme) => ({
  svg: ({ isGrid, isModal }) => ({
    position: 'absolute',
    zIndex: 1,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.down('lg')]: {
      width: 199,
    },
    [theme.breakpoints.down('md')]: {
      width: isGrid || isModal ? '29vw' : 90,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        width: 199,
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        width: isGrid || isModal ? '29vw' : 90,
      }),
    },
  }),
}));

const WatermarkImg = ({
  isGrid = false,
  isOutOfStock = false,
  isWithdrawn = false,
  isExpired = false,
  isUnavailable = false,
  isModal = false,
}) => {
  const { isKiteUser } = useUser();
  const classes = useStyles({ isGrid, isWithdrawn, isModal });

  if (isOutOfStock) {
    return isKiteUser ? (
      <OutOfStockKite className={classes.svg} />
    ) : (
      <OutOfStock className={classes.svg} />
    );
  }

  if (isExpired) {
    return isKiteUser ? (
      <ExpiredKite className={classes.svg} />
    ) : (
      <Expired className={classes.svg} />
    );
  }

  if (isWithdrawn) {
    return isKiteUser ? (
      <WithdrawnKite className={classes.svg} />
    ) : (
      <Withdrawn className={classes.svg} />
    );
  }
  if (isUnavailable) {
    return isKiteUser ? (
      <UnavailableKite className={classes.svg} />
    ) : (
      <Unavailable className={classes.svg} />
    );
  }
};

export default WatermarkImg;
