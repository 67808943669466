// Libs
import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';

// Components
import SupportCard from '../../../components/Support/SupportCard/SupportCard';

// Utils
import { supportCards } from '../../../constants/support';

const useStyles = makeStyles((theme) => ({
  supportCardsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      gap: theme.spacing(2),
      flexDirection: 'column',
      alignItems: 'stretch',
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        gap: theme.spacing(2),
        flexDirection: 'column',
        alignItems: 'stretch',
      }),
    },
  },
}));

const SupportPage = () => {
  const classes = useStyles();
  const { contacts } = useSelector((state) => state.support);
  return (
    <Box className={classes.supportCardsContainer}>
      <SupportCard
        {...supportCards.mops}
        phoneNumber={contacts.phone_1}
        buttonInfo={{
          type: 'email',
          title: 'Send an Email',
          content: contacts.email_1,
        }}
      />
      <SupportCard
        {...supportCards.brandAppropriatePerson}
        buttonInfo={{
          type: 'pdf',
          title: 'View PDF',
          content: contacts.brand_contact_list,
        }}
      />
      <SupportCard
        {...supportCards.userGuide}
        buttonInfo={{
          type: 'link',
          title: 'View User Guide',
          content: contacts.gmop_training_video,
        }}
        videoButtonInfo={{
          title: 'Training Video',
          content: contacts.gmop_training_video_guide,
        }}
      />
    </Box>
  );
};

export default SupportPage;
