// Libs
import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

// Components
import CountButton from '../../Button/CountButton';
import Button from '../../Button';

// Hooks
import useKitsProducts from '../../../pages/Kits/hooks/useKitsProducts';

const useStyles = makeStyles((theme) => ({
  container: ({ detailsMode, listView }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: detailsMode || !listView ? 'row' : 'column',
    alignItems: 'center',
    gap: 10,
    padding: 0,
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
      justifyContent: !detailsMode && !listView ? 'center' : 'unset',
      width: '100%',
    },
  }),
  countButton: ({ detailsMode, listView }) =>
    listView
      ? {
          width: 130,
          height: 36,
          [theme.breakpoints.down('lg')]: {
            width: detailsMode ? 200 : 96,
            height: detailsMode ? 44 : 34,
          },
          [theme.breakpoints.down('md')]: {
            width: detailsMode ? 140 : 96,
            height: detailsMode ? 44 : 24,
          },
        }
      : {
          width: '50%',
          maxWidth: detailsMode ? 130 : 128,
          height: 36,
          [theme.breakpoints.down('lg')]: {
            minWidth: detailsMode ? 200 : 'initial',
            height: detailsMode ? 44 : 34,
            marginLeft: !detailsMode && 'auto',
            marginRight: !detailsMode && 'auto',
          },
          [theme.breakpoints.down('md')]: {
            minWidth: detailsMode ? 140 : 124,
            height: detailsMode ? 44 : 24,
          },
        },
  addToKitButton: ({ detailsMode, listView }) =>
    listView
      ? {
          width: 130,
          height: 36,
          padding: theme.spacing(1),
          [theme.breakpoints.down('lg')]: {
            width: detailsMode ? 200 : 105,
            height: detailsMode ? 44 : 34,
          },
          [theme.breakpoints.down('md')]: {
            width: detailsMode ? 140 : '100%',
            height: detailsMode ? 44 : 32,
          },
        }
      : {
          width: detailsMode ? 130 : '50%',
          maxWidth: detailsMode ? 'unset' : 130,
          height: 36,
          padding: theme.spacing(1),
          [theme.breakpoints.down('lg')]: {
            minWidth: detailsMode ? 200 : 96,
            height: detailsMode ? 44 : 34,
          },
          [theme.breakpoints.down('md')]: {
            width: detailsMode ? 140 : 124,
            minWidth: detailsMode ? 140 : 'unset',
            maxWidth: detailsMode ? 'unset' : 124,
            height: detailsMode ? 44 : 32,
          },
        },
}));

const KitsProductQtyButtons = ({ nums, ...props }) => {
  const { product, detailsMode, isMobileMode, listView, className } = props;
  const [tempCount, setTempCount] = useState(0);
  const { selectingKitProducts } = useSelector((state) => state.kits);
  const {
    currentKit,
    updateKitProductQty,
    addNewKitProduct,
    getProductQty,
    removeOneProductFromKit,
    setCountForProductInKit,
  } = useKitsProducts();

  const classes = useStyles({ listView, detailsMode });

  const qty = getProductQty(product?.product_id);
  const isProductInKit = !!qty;
  const count = nums ? nums : qty;

  const isAddToKitButtonDisabled = useMemo(() => {
    return isProductInKit || (!isMobileMode && tempCount === 0);
  }, [isMobileMode, isProductInKit, tempCount]);

  const addOneProductToKit = () => {
    updateKitProductQty(currentKit.id, product.product_id, count + 1);
  };

  const removeOneProduct = () => {
    removeOneProductFromKit(count, product.product_id, detailsMode);
  };

  const setCount = (product, count) => {
    setCountForProductInKit(Math.round(count), product.product_id, detailsMode);
  };

  const kitSelectingAddOne = () => {
    if (!count) {
      return () => setTempCount(tempCount + 1);
    }

    return () => addNewKitProduct(product.product_id, count + 1);
  };

  const kitSelectingRemoveOne = () => {
    if (!count) {
      return () => setTempCount(tempCount - 1);
    }

    return () => addNewKitProduct(product.product_id, count - 1);
  };

  const kitSelectingSetCount = () => {
    if (!count) {
      return (product, value) => setTempCount(Math.round(value));
    }

    return (product, value) =>
      addNewKitProduct(product.product_id, Math.round(value));
  };

  return selectingKitProducts ? (
    // buttons in material product card in "modal" while adding products in kit
    <div className={classnames(classes.container, className)}>
      {(detailsMode || (!detailsMode && !isMobileMode)) && (
        <CountButton
          product={product}
          count={count || tempCount}
          onAdd={kitSelectingAddOne()}
          onRemove={kitSelectingRemoveOne()}
          onSetCount={kitSelectingSetCount()}
          limitValue={false}
          className={classes.countButton}
        />
      )}
      <Button
        id="add-to-kits"
        onClick={() =>
          addNewKitProduct(
            product.product_id,
            isMobileMode && !detailsMode ? 1 : count ? count : tempCount,
          )
        }
        disabled={isAddToKitButtonDisabled}
        className={classes.addToKitButton}>
        {isProductInKit ? 'In Kit' : 'Add To Kit'}
      </Button>
    </div>
  ) : (
    // buttons in kit product card, no labels and no button
    <CountButton
      product={product}
      count={count}
      limitValue={false} // user can enter any value
      onAdd={addOneProductToKit}
      onRemove={removeOneProduct}
      onSetCount={setCount}
      className={classes.countButton}
    />
  );
};

export default KitsProductQtyButtons;
