// Libs
import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { Typography } from '@mui/material';

// Components
import Spinner from '../../../components/Spinner';
import OrderHistoryButton from '../../../components/OrderHistory/Buttons/OrderHistoryButton';
import ShippingAddresses from '../../../components/Cart/ReviewOrder/OrderInfo/ShippingAddresses';
import OrderSection from '../../../components/OrderHistory/OrderDetails/OrderSection';
import OrderedProducts from '../../../components/OrderHistory/OrderDetails/OrderedProducts';
import RecurringOrderSection from '../../../components/OrderHistory/OrderDetails/RecurringOrderSection';

// Hooks
import useUser from '../../../hooks/useUser';
import useReorder from '../hooks/useReorder';
import useUI from '../../../hooks/useUI';
import useOrderDetails from '../hooks/useOrderDetails';
import Error from '../../../components/Error';

// Actions
import { DeleteAllCachedCountInCart } from '../../../actions/cart';

const useStyles = makeStyles((theme) => ({
  orderDetails: {
    '& #reorder': {
      width: 110,
    },
    [theme.breakpoints.down('lg')]: {
      minHeight: '89vh',
      display: 'flex',
      flexDirection: 'column',
      '& #reorder': {
        height: 44,
      },
    },
    [theme.breakpoints.down('md')]: {
      '& #reorder': {
        width: '100%',
      },
    },
  },
  breadCrumbs: {
    color: theme.palette.darkGrey,
    ...theme.typography.h3,
    '& > * > :last-child': {
      color: theme.palette.grey2,
    },
  },
}));

const OrderDetails = ({ isRecurring = false }) => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { isKiteUser } = useUser();
  const { isDesktopMode, isMobileMode, isTabletMode } = useUI();

  const {
    isLoading,
    orderDetails,
    orderProductIds,
    listView,
    setListView,
    onDeleteAddress,
    products,
    canReorder,
    apiError,
  } = useOrderDetails(orderId, isRecurring);

  const classes = useStyles({
    isKiteUser,
    listView,
    isDesktopMode,
    isMobileMode,
    isTabletMode,
  });

  const { onReorder } = useReorder();

  const isOrderDetailsPage = useMemo(
    () => !pathname.includes('recurring'),
    [pathname],
  );

  const onNavigate = useCallback(() => {
    if (isRecurring) {
      navigate('/order-history/recurring');
    } else {
      navigate('/order-history');
    }
  }, [navigate, isRecurring]);

  useEffect(() => {
    dispatch(DeleteAllCachedCountInCart());
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  if (apiError) {
    return <Error message={apiError} />;
  }

  return (
    orderDetails && (
      <div className={classes.orderDetails}>
        {isDesktopMode && (
          <Breadcrumbs
            className={classes.breadCrumbs}
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            mt={3}>
            <Link
              underline="none"
              key="1"
              color="inherit"
              style={{ cursor: 'pointer' }}
              onClick={onNavigate}>
              Order History
            </Link>
            <Link key="2" color="inherit" underline="none">
              Order Details
            </Link>
          </Breadcrumbs>
        )}
        <Typography
          variant={isMobileMode ? 'h1' : 'h2'}
          mb={{ lg: 1, md: isRecurring ? 3 : 4, xs: isRecurring ? 2 : 4 }}
          mt={{ lg: 4, md: 2 }}>
          Order {isDesktopMode ? 'Info' : 'Details'}
        </Typography>
        {!isRecurring && (
          <OrderSection
            orderDetails={orderDetails}
            onReorder={onReorder}
            isRecurring={isRecurring}
            canReorder={canReorder}
          />
        )}
        {isRecurring && <RecurringOrderSection orderDetails={orderDetails} />}
        <ShippingAddresses
          onDeleteAddress={onDeleteAddress}
          shippingAddresses={
            orderDetails.address
              ? [orderDetails.address]
              : orderDetails.addresses
          }
          isOrderDetailsPage={isOrderDetailsPage}
          isRecurringOrderPage={isRecurring}
          trackingLink={
            orderDetails.status?.toLowerCase() === 'shipped' &&
            orderDetails.tracking_link
          }
          orderId={orderDetails.id}
        />
        <OrderedProducts
          addressesCount={
            orderDetails.address ? 1 : orderDetails.addresses.length
          }
          orderProducts={{ ids: orderProductIds, products }}
          setListView={setListView}
          listView={listView}
          orderDetails={orderDetails}
          isRecurring={isRecurring}
        />
        {isTabletMode ? (
          <Box
            flexGrow={'1'}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'end'}>
            <OrderHistoryButton />
          </Box>
        ) : (
          <OrderHistoryButton />
        )}
      </div>
    )
  );
};

export default OrderDetails;
