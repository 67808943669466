// Lib
import React from 'react';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';

// Components
import Button from '../../../Button';
import Feather from '../../../Feather';

// Hooks
import useUser from '../../../../hooks/useUser';

const useStyles = makeStyles((theme) => ({
  buttons: ({ withPrevButton }) => {
    return {
      marginTop: theme.spacing(1),
      display: 'flex',
      justifyContent: withPrevButton ? 'space-between' : 'end',
      width: '100%',
      '& #prev-button': {
        color: theme.palette.primary1,
      },
      '& #next-button': {
        minWidth: 110,
        paddingLeft: 20,
        paddingRight: 20,
      },
      [theme.breakpoints.down('lg')]: {
        // move buttons down in tablet mode
        flexGrow: 1,
        alignItems: 'end',
        '& #next-button': {
          width: 200,
        },
      },
      [theme.breakpoints.down('md')]: {
        '& #next-button': {
          height: 44,
        },
        ...(withPrevButton && {
          '& button': {
            '&:nth-child(2)': {
              width: '200px',
            },
            '&:nth-child(1)': {
              paddingLeft: 0,
              paddingRight: 0,
            },
          },
        }),
      },
      [theme.breakpoints.down('sm')]: {
        '& #prev-button': {
          width: 'fit-content !important',
        },
        '& #next-button': {
          width: ({ withPrevButton }) =>
            withPrevButton ? '47% !important' : '100% !important',
        },
      },
      [theme.breakpoints.downLandscape('lg')]: {
        ...(theme.isMobileDevice && {
          flexGrow: 1,
          alignItems: 'end',
          '& #next-button': {
            width: 200,
          },
        }),
      },
      [theme.breakpoints.downLandscape('md')]: {
        ...(theme.isMobileDevice && {
          '& #next-button': {
            height: 44,
          },
          ...(withPrevButton && {
            '& button': {
              '&:nth-child(2)': {
                width: '200px',
              },
              '&:nth-child(1)': {
                paddingLeft: 0,
                paddingRight: 0,
              },
            },
          }),
        }),
      },
    };
  },
}));

const FooterButtons = ({
  onClickBack = () => {},
  onClickNext = () => {},
  backTitle,
  nextTitle = 'Next Step',
  nextButtonDisabled,
  loading,
  withPrevButton = true,
}) => {
  const { isKiteUser } = useUser();
  const classes = useStyles({ withPrevButton });
  const { pathname } = useLocation();

  return (
    <div className={classes.buttons}>
      {withPrevButton && (
        <Button
          id="prev-button"
          onClick={() => onClickBack(pathname)}
          variant="text"
          style={{ paddingLeft: 0 }}>
          <Feather
            nativeColor={!isKiteUser}
            type="backArrow"
            printType="stroke"
          />
          {backTitle}
        </Button>
      )}
      <Button
        loadingIndicator="Loading…"
        loading={loading}
        id="next-button"
        disabled={nextButtonDisabled}
        onClick={onClickNext}>
        {nextTitle}
      </Button>
    </div>
  );
};

export default FooterButtons;
