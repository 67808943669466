// Lib
import React, { useCallback } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { makeStyles } from '@mui/styles';

// Components
import Button from '../../../../Button';
import Feather from '../../../../Feather';
import Error from '../../Error';

// Hooks
import useImportAddress from '../../../../../pages/AddressBook/hooks/useImportAddress';

// Utils
import { fullAddressString } from '../../../../../utils/address';
import { parseAddressErrors } from '../../../../../utils/apiErrors';

// Constants
import { validationFailed } from '../../../../../constants/addressBook';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    columnGap: theme.spacing(5),
    marginBottom: theme.spacing(2),
    '& > div': {
      flexBasis: 'calc(50% - 20px)',
    },
    [theme.breakpoints.down('lg')]: {
      borderBottom: `1px solid ${theme.palette.grey1}`,
      flexDirection: 'column',
      gap: 0,
      justifyContent: 'initial',
      flexBasis: 'initial',
      '& .MuiFormControlLabel-label': {
        fontSize: 14,
        fontWeight: 700,
      },
      '& .MuiRadio-colorPrimary': {
        paddingLeft: 1,
      },
      '& > div': {
        flexBasis: 'initial',
      },
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        borderBottom: `1px solid ${theme.palette.grey1}`,
        flexDirection: 'column',
        gap: 0,
        justifyContent: 'initial',
        flexBasis: 'initial',
        '& .MuiFormControlLabel-label': {
          fontSize: 14,
          fontWeight: 700,
        },
        '& .MuiRadio-colorPrimary': {
          paddingLeft: 1,
        },
        '& > div': {
          flexBasis: 'initial',
        },
      }),
    },
  },
  enteredAddress: {
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'space-between',
  },
  formControlLabel: {
    color: theme.palette.darkGrey,
    [theme.breakpoints.down('lg')]: {
      alignItems: 'start',
      maxHeight: 'fit-content',
      marginLeft: '-2px',
      '& .MuiFormControlLabel-label': {
        paddingTop: '3px',
      },
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        alignItems: 'start',
        maxHeight: 'fit-content',
        marginLeft: '-2px',
        '& .MuiFormControlLabel-label': {
          paddingTop: '3px',
        },
      }),
    },
  },
  formControlRadio: {
    position: 'relative',
    bottom: 9,
    [theme.breakpoints.down('lg')]: {
      paddingBottom: 0,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        paddingBottom: 0,
      }),
    },
  },
  buttons: {
    display: 'flex',
    gap: theme.spacing(1),
    '& button': {
      backgroundColor: '#F4F4F4',
      borderRadius: 4,
      maxHeight: 24,
      minWidth: 24,
      padding: 4,
      '&:hover': {
        backgroundColor: '#F4F4F4',
      },
    },
  },
  validatedAddress: {
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(3),
      marginTop: '18px',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        marginBottom: theme.spacing(3),
        marginTop: '18px',
      }),
    },
  },
  retryButton: {
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 0,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        paddingLeft: 0,
      }),
    },
  },
}));

const Address = ({ address, isTabletMode, setEditableAddress = () => {} }) => {
  const classes = useStyles();

  const {
    onRemoveAddress,
    onEditAddress,
    revalidateAddress,
    isLoading,
    onChangeAddress,
  } = useImportAddress(address, setEditableAddress);

  const renderValidatedAddress = useCallback(() => {
    if (address.validated === undefined) {
      return;
    }
    if (address.USPS_return_text) {
      return (
        <>
          <Error error={address.USPS_return_text} />
          <Button
            className={classes.retryButton}
            style={{ fontSize: 14 }}
            variant="text"
            loadingIndicator="Loading..."
            onClick={revalidateAddress}
            loading={isLoading}>
            Retry
            {!isLoading && <Feather nativeColor type="arrowRight" />}
          </Button>
        </>
      );
    }
    if (!address.validated_address || address.GMOP_errors_description) {
      return (
        <>
          <Error error={validationFailed} />
          <Button
            className={classes.retryButton}
            style={{ fontSize: 14 }}
            variant="text"
            onClick={revalidateAddress}
            loadingIndicator="Loading..."
            loading={isLoading}
            data-testid="import-address-retry-button">
            Retry
            {!isLoading && <Feather nativeColor type="arrowRight" />}
          </Button>
        </>
      );
    }
    return (
      <>
        <FormControlLabel
          className={classes.formControlLabel}
          name="validated"
          value={address.validated_address}
          control={
            <Radio
              className={classes.formControlRadio}
              onChange={onChangeAddress}
              data-testid="validated-address-radio-button"
            />
          }
          label={isTabletMode ? 'Validated Address' : address.validated_address}
        />
        {isTabletMode && <div>{address.validated_address}</div>}
      </>
    );
  }, [
    address.validated,
    address.USPS_return_text,
    address.validated_address,
    address.GMOP_errors_description,
    classes.formControlLabel,
    classes.formControlRadio,
    classes.retryButton,
    onChangeAddress,
    isTabletMode,
    revalidateAddress,
    isLoading,
  ]);

  return (
    <>
      <RadioGroup
        className={classes.root}
        value={address[`${address.checked}_address`]}
        row={false}
        aria-labelledby="address-format-radio-buttons-group-label"
        name="address-format-radio-buttons-group">
        <div className={classes.enteredAddress}>
          <FormControlLabel
            className={classes.formControlLabel}
            name="original"
            value={address.original_address}
            control={
              <Radio
                className={classes.formControlRadio}
                onChange={onChangeAddress}
                data-testid="entered-address-radio-button"
              />
            }
            label={
              isTabletMode
                ? 'Entered Address'
                : fullAddressString(address.original_fields)
            }
          />
          <div className={classes.buttons}>
            <Button
              onClick={onEditAddress}
              variant="text"
              data-testid="entered-address-edit-button">
              <Feather type="edit" />
            </Button>
            <Button
              variant="text"
              onClick={onRemoveAddress}
              data-testid="entered-address-delete-button">
              <Feather type="delete" />
            </Button>
          </div>
        </div>
        {isTabletMode && (
          <div>{fullAddressString(address.original_fields)}</div>
        )}
        <div className={classes.validatedAddress}>
          {renderValidatedAddress()}
        </div>
        {address.apiError && (
          <Error error={parseAddressErrors(address.apiError)} />
        )}
      </RadioGroup>
    </>
  );
};

export default Address;
