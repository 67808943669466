// Lib
import React from 'react';
import { makeStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';

// Components
import sunIcon from '../../../assets/img/sunIcon.png';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(1.25),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      columnGap: theme.spacing(1),
      marginTop: 0,
    },
  },
  text: {
    color: theme.palette.darkGrey,
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  },
  imageContainer: {
    height: 24,
    width: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.lightGrey,
    borderRadius: 4,
    [theme.breakpoints.down('md')]: {
      height: ({ withText }) => (withText ? 24 : 20),
      width: ({ withText }) => (withText ? 24 : 20),
    },
  },
  image: {
    height: 16,
    width: 16,
    [theme.breakpoints.down('md')]: {
      height: ({ withText }) => (withText ? 16 : 14),
      width: ({ withText }) => (withText ? 16 : 14),
    },
  },
  tooltip: {
    color: theme.palette.darkGrey,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px',
    marginTop: '10px !important',
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      lineHeight: '18px',
    },
  },
  arrow: {
    color: '#FFFFFF',
  },
}));

const ReportableIcon = ({ withText = false }) => {
  const classes = useStyles({ withText });

  const sunImage = (
    <div className={classes.imageContainer}>
      <img className={classes.image} src={sunIcon} alt="Sun icon" />
    </div>
  );

  return (
    <>
      {withText ? (
        <div className={classes.container}>
          {sunImage}
          <Typography className={classes.text}>
            Sunshine Act Reportable Item
          </Typography>
        </div>
      ) : (
        <Tooltip
          disableFocusListener
          enterTouchDelay={1}
          leaveTouchDelay={2000}
          title="Sunshine Act Reportable Item."
          arrow
          classes={{
            tooltip: classes.tooltip,
            arrow: classes.arrow,
          }}>
          {sunImage}
        </Tooltip>
      )}
    </>
  );
};

export default ReportableIcon;
