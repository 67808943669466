// Lib
import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const Spinner = ({ size, height = '300px', styles = {} }) => {
  return (
    <Box
      justifyContent="center"
      alignItems="center"
      sx={{ display: 'flex', height, ...styles }}>
      <CircularProgress size={size} />
    </Box>
  );
};

export default Spinner;
