// Lib
import React from 'react';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

// Components
import Button from '../index';

const useStyles = makeStyles((theme) => ({
  root: {
    bottom: theme.spacing(5),
    display: 'flex',
    gap: theme.spacing(2),
    position: 'fixed',
    right: 'calc(50vw - 564px)',
    '& button': {
      width: 110,
    },
    '& #cancel-button': {
      color: theme.palette.primary1,
      background: '#F4F4F4',
    },
    [theme.breakpoints.down('xl')]: {
      right: 26,
    },
    [theme.breakpoints.down('md')]: {
      bottom: 12,
      right: 12,
      left: 12,
      '& button': {
        height: '44px',
        flexGrow: 1,
      },
    },
  },
}));

const StickyButtons = ({
  onAccept = () => {},
  onReject = () => {},
  loading,
  submitText = 'Save',
  className,
}) => {
  const classes = useStyles();

  return (
    <div className={classnames(classes.root, className)}>
      <Button onClick={onReject} id="cancel-button">
        Cancel
      </Button>
      <Button loading={loading} onClick={onAccept} id="save-button">
        {submitText}
      </Button>
    </div>
  );
};

export default StickyButtons;
