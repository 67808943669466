// Lib
import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

// Hook
import useUI from '../../../../../hooks/useUI';
import classnames from 'classnames';
import Feather from '../../../../Feather';

const useStyles = makeStyles((theme) => ({
  root: ({ width }) => ({
    display: 'flex',
    flexDirection: 'column',
    fontSize: 14,
    position: 'relative',
    width,
    [theme.breakpoints.down('lg')]: {
      display: 'block',
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.down('md')]: {
      alignItems: 'start',
      display: 'flex',
      fontSize: 10,
      lineHeight: '12px',
      marginBottom: 6,
    },
  }),
  title: {
    fontWeight: 'bold',
    minWidth: 'max-content',
    marginBottom: theme.spacing(0.5),
  },
  editIcon: {
    cursor: 'pointer',
    height: 12,
    width: 12,
    marginLeft: theme.spacing(1),
    '& path': {
      fill: theme.palette.primary1,
    },
  },
}));

const OrderInfoItem = ({
  title,
  text,
  textColor,
  width = 'initial',
  isMethodPage,
  className,
  isApproved,
  onOpenShippingModal = () => {},
}) => {
  const classes = useStyles({ width });
  const { isDesktopMode } = useUI();

  if (!text) {
    return;
  }

  return (
    <div className={classnames(classes.root, className)}>
      <span className={classes.title}>{`${title}${
        isDesktopMode ? '' : ': '
      }`}</span>
      <Box
        component="span"
        display={isDesktopMode ? 'flex' : 'initial'}
        alignItems="center">
        <span
          style={{
            color: textColor,
            lineHeight: '16px',
            maxWidth: isMethodPage ? '80%' : '100%',
            wordBreak: 'break-word',
          }}>
          {text}
        </span>
        {isMethodPage && !isApproved && (
          <Feather
            onClick={onOpenShippingModal}
            className={classes.editIcon}
            type="edit"
          />
        )}
      </Box>
      {isMethodPage && isApproved && (
        <div style={{ color: '#929A92' }}>Approved</div>
      )}
    </div>
  );
};

export default OrderInfoItem;
