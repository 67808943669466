const values = {
  xs: 0,
  sm: 420,
  md: 768,
  lg: 1024,
  xl: 1280,
};
export default {
  values,
  downLandscape: (heightKey) => {
    const deltaViewportScreen =
      +localStorage.getItem('deltaViewportScreen') || 0;

    return `@media (max-height: ${
      values[heightKey] - deltaViewportScreen - 0.05
    }px) and (orientation: landscape)`;
  },
};
