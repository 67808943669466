// Lib
import React from 'react';
import { TableCell, TableRow as MuiTableRow } from '@mui/material';

const CollapsedTableRow = ({
  useCellsReducers = () => ({ cells: {} }),
  columns,
  mergedOrder,
  item,
  styles,
}) => {
  const { cells } = useCellsReducers({ order: mergedOrder });

  return (
    <MuiTableRow key={mergedOrder.id}>
      {columns?.map((column) => (
        <TableCell key={`${item.id}-${column.id}`} className={styles?.cell}>
          {cells[column.id]}
        </TableCell>
      ))}
    </MuiTableRow>
  );
};

export default CollapsedTableRow;
