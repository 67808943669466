// Lib
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Components
import FooterButtons from '../../../components/Modal/FooterButtons';
import Select from '../../../components/Field/Select';

// Actions
import {
  AddAction,
  SetApproveResponse,
  SaveApprovals,
  EditShippingMethod,
} from '../../../actions/approvals';
import { CloseModal, OpenModal } from '../../../actions/modal';

// Hooks
import useUser from '../../../hooks/useUser';
import useModal from '../../../hooks/useModal';

const useApprovals = (isMethodPage = false) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { approvals, fullName, products, approveResponse, apiError } =
    useSelector((state) => ({
      approvals: state.approvals,
      fullName: `${state.user.firstName} ${state.user.lastName}`,
      products: state.approvals?.approvals?.products_limits,
      approveResponse: state.approvals?.approveResponse,
      apiError: state.apiError?.['saveApprovals'],
    }));

  const [isSaving, setIsSaving] = useState(false);
  const { email } = useUser();
  const { onClose } = useModal();

  const getApprover = useCallback(
    (approvers) =>
      approvers?.find(({ approver_email }) => approver_email === email),
    [email],
  );

  const onApproveAll = useCallback(() => {
    if (!products) {
      return;
    }
    Object.keys(products).map((productId) => {
      if (getApprover(products[productId]?.approvers)) {
        dispatch(AddAction({ id: productId, action: 'approve' }));
      }
    });
  }, [products, dispatch, getApprover]);

  const isResponseRequired = !!useMemo(() => {
    if (!products) {
      return;
    }

    return Object.keys(products).find((productId) => {
      const approver = getApprover(products[productId]?.approvers);
      const product = products[productId];
      if (approver) {
        return (
          product.action === 'reject' ||
          +product.qty_approved !== product.qty_ordered
        );
      }
    });
  }, [products, getApprover]);

  const onChangeResponse = useCallback(
    (onChange) =>
      ({ target }) => {
        dispatch(SetApproveResponse(target.value));
        onChange(target.value);
      },
    [dispatch],
  );

  const hasAllApproves = !useMemo(() => {
    if (!products) {
      return true;
    }
    return Object.keys(products).find((productId) => {
      const approver = getApprover(products[productId]?.approvers);
      if (approver) {
        return products[productId].action !== 'approve';
      }
    });
  }, [products, getApprover]);

  const hasAllActions = useMemo(() => {
    if (!products) {
      return true;
    }

    return Object.values(products).every((productLimitApprove) => {
      const { approvers, approved_by, action } = productLimitApprove;

      // check if logged user is in the list of approvers and
      // if product is already approved or has action
      if (getApprover(approvers)) {
        return approved_by || action;
      } else {
        return true;
      }
    });
  }, [products, getApprover]);

  const isSubmitDisabled = useMemo(() => {
    if ((approvals?.orderQtyError || !hasAllActions) && !isMethodPage) {
      return true;
    }
    if (isResponseRequired && !approveResponse && !isMethodPage) {
      return true;
    }
    if (isMethodPage && !approveResponse) {
      return true;
    }

    return false;
  }, [
    approvals?.orderQtyError,
    hasAllActions,
    isMethodPage,
    isResponseRequired,
    approveResponse,
  ]);

  const onSubmit = useCallback(() => {
    if (!products) {
      return;
    }

    let data;
    if (!isMethodPage) {
      const approvedProductIds = Object.keys(products).filter((productId) =>
        getApprover(products[productId]?.approvers),
      );
      data = {
        approved_items: approvedProductIds.map((productId) => {
          return {
            approved_value: products[productId].qty_approved,
            action: products[productId].action,
            approval_id: getApprover(products[productId]?.approvers)
              .approval_id,
          };
        }),
        comment: approvals?.approveResponse,
        comment_type: 'orderQty',
      };
    } else {
      data = {
        approved_items: [
          {
            approved_value: approvals?.approvals?.shipment_method?.id_approved,
            action: 'approve',
            approval_id: getApprover(
              approvals?.approvals?.shipment_method?.approvers,
            ).approval_id,
          },
        ],
        comment: approvals?.approveResponse,
        comment_type: 'shippingMethod',
      };
    }

    dispatch(SaveApprovals({ setIsSaving, data, id: approvals.orderId }));
  }, [dispatch, isMethodPage, products, getApprover, approvals]);

  const allProductsAlreadyApproved = !useMemo(() => {
    if (!products) {
      return true;
    }
    return Object.keys(products).find((productId) => {
      const approver = getApprover(products[productId]?.approvers);
      if (approver) {
        return !products[productId].status;
      }
    });
  }, [products, getApprover]);

  const onChangeMethod = useCallback(
    (method) => () => {
      dispatch(EditShippingMethod(method));
    },
    [dispatch],
  );

  const onOpenShippingModal = useCallback(() => {
    let newMethod = null;
    const onChangeItem = (method) => () => {
      newMethod = method;
    };

    const onSaveItem = () => {
      dispatch(EditShippingMethod(newMethod));
      dispatch(CloseModal());
    };

    dispatch(
      OpenModal({
        Component: () => (
          <>
            <Typography variant="h3" mb={3}>
              Select shipping method
            </Typography>
            <Select
              onChange={onChangeItem}
              items={approvals?.shippingMethods}
              classic
              label="Shipping method"
              initialValue={approvals.order.shipment_method}
            />
            <FooterButtons
              onReject={onClose}
              acceptTitle="Save"
              rejectTitle="Cancel"
              onAccept={onSaveItem}
            />
          </>
        ),
        styles: {
          width: 'min(85vw, 700px)',
        },
      }),
    );
  }, [dispatch, onClose, approvals]);

  useEffect(() => {
    if (isMethodPage && approvals?.approvals?.shipment_method?.approvers) {
      if (!getApprover(approvals?.approvals?.shipment_method?.approvers)) {
        navigate('/materials');
      }
    }
    if (!isMethodPage && approvals?.approvals?.products_limits) {
      const productIds = Object.keys(approvals?.approvals?.products_limits);

      if (
        !productIds?.find((productId) =>
          getApprover(
            approvals?.approvals?.products_limits?.[productId]?.approvers,
          ),
        )
      ) {
        navigate('/materials');
      }
    }
  }, [approvals?.approvals, email, navigate, isMethodPage, getApprover]);

  const isMethodApproved = useMemo(
    () => !!approvals?.approvals?.shipment_method?.status,
    [approvals?.approvals?.shipment_method],
  );

  const comment = useMemo(() => {
    if (!isMethodPage) {
      return approvals?.order?.approval_comments.find(
        ({ user, comment_type }) =>
          user === email && comment_type === 'orderQty',
      )?.comment;
    } else {
      return approvals?.order?.approval_comments.find(
        ({ user, comment_type }) =>
          user === email && comment_type === 'shippingMethod',
      )?.comment;
    }
  }, [email, approvals?.order?.approval_comments, isMethodPage]);

  return {
    approvals,
    fullName,
    comment,
    isResponseRequired,
    isSubmitDisabled,
    isSaving,
    allProductsAlreadyApproved,
    onApproveAll,
    onChangeResponse,
    onSubmit,
    onOpenShippingModal,
    isMethodApproved,
    onChangeMethod,
    hasAllApproves,
    apiError,
  };
};

export default useApprovals;
