// Lib
import React from 'react';
import Box from '@mui/material/Box';

// Components
import ListButton from '../../Button/ListButton';
import GridButton from '../../Button/GridButton';

const ViewButtons = ({ isListView, onChangeView, isMobileMode }) => {
  return (
    <Box display="flex" alignItems="center">
      <ListButton
        onClick={onChangeView('list')}
        isActive={isListView}
        styles={{ marginRight: 16 }}
      />
      <GridButton
        onClick={onChangeView('grid')}
        isActive={!isListView}
        styles={{ marginRight: isMobileMode ? 0 : 28 }}
      />
    </Box>
  );
};

export default ViewButtons;
