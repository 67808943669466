// Lib
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import AddressForm from '../../../components/AddressBook/Form/AddressForm';
import ImportAddresses from '../../../components/AddressBook/ImportAddresses';

// Actions
import {
  ChangeBookView,
  ApplyAddressFilters,
  ChangAddressBookFilter,
  UpdateAddressesPage,
  SetSearchResults,
  SaveAddressBookFilters,
  SetValidatedAddress,
} from '../../../actions/addressBook';
import { OpenModal } from '../../../actions/modal';

// Utils
import multiSearch from '../../../utils/multiSearch';

// Hooks
import useUI from '../../../hooks/useUI';

const sortingMap = {
  'first name': 'first_name',
  'last name': 'last_name',
  company: 'company',
};

const useAddressBook = () => {
  const { isTabletMode, isMobileMode } = useUI();
  const dispatch = useDispatch();
  const { addressBook, filters, isListView, isLoading, searchValue } =
    useSelector((state) => ({
      addressBook: state.addressBook.addressBook,
      filters: state.addressBook.filters,
      isListView: state.addressBook.filters.view === 'list',
      isLoading: state.isLoading,
      searchValue: state.search,
    }));

  const sortByName = useCallback(
    (order) => (addressA, addressB) => {
      if (addressA.is_main) {
        return -1;
      }
      if (addressB.is_main) {
        return 1;
      }

      const a =
        addressA[
          sortingMap[filters.sorting.orderBy.toLowerCase()]
        ]?.toLowerCase();
      const b =
        addressB[
          sortingMap[filters.sorting.orderBy.toLowerCase()]
        ]?.toLowerCase();
      if (order === 'asc') {
        return !a - !b || a?.localeCompare(b);
      }
      return !b - !a || b.localeCompare(a);
    },
    [filters?.sorting?.orderBy],
  );

  const sortAddresses = useCallback(
    (addresses) => {
      return addresses.sort(sortByName(filters?.sorting?.order));
    },
    [filters?.sorting?.order, sortByName],
  );

  const applySearch = useCallback(
    (product) => {
      if (!searchValue) {
        return true;
      }
      if (searchValue) {
        let string = searchValue;
        if (searchValue.includes(',')) {
          string = string.replace(',', '');
          string = string.replace('«', '');
          string = string.replace('»', '');
        }
        const { last_name, first_name, company } = product || {};
        const text = `${last_name} ${first_name} ${company}`;
        return multiSearch(text, string.split(' '));
      }
    },
    [searchValue],
  );

  const applyAllFilters = useCallback(() => {
    const searchResults = addressBook.all.filter((address) => {
      // Apply search value
      return applySearch(address);
    });
    dispatch(SetSearchResults(searchResults));

    return sortAddresses([...searchResults]);
  }, [dispatch, addressBook, sortAddresses, applySearch]);

  useEffect(() => {
    if (addressBook?.all?.length) {
      dispatch(ApplyAddressFilters(applyAllFilters()));
    }
  }, [dispatch, addressBook?.all, filters, searchValue]);

  const onChangeSorting = useCallback(
    (orderBy, order) => () => {
      const newFilter = { ...filters.sorting };
      if (orderBy) {
        newFilter.orderBy = orderBy;
      }
      if (order) {
        newFilter.order = order === 'desc' ? 'asc' : 'desc';
      }
      dispatch(
        SaveAddressBookFilters({
          address_book_filter: {
            sorting: { order: newFilter.order, orderBy: newFilter.orderBy },
            view: filters.view,
          },
        }),
      );
      dispatch(ChangAddressBookFilter({ sorting: newFilter }));
    },
    [filters, dispatch],
  );

  const onChangeView = useCallback(
    (view) => () => {
      dispatch(ChangeBookView(view));
      dispatch(
        SaveAddressBookFilters({
          address_book_filter: {
            sorting: {
              orderBy: filters.sorting.orderBy,
              order: filters.sorting.order,
            },
            view,
          },
        }),
      );
    },
    [dispatch, filters],
  );

  const onAddAddress = useCallback(() => {
    dispatch(SetValidatedAddress({}));
    dispatch(
      OpenModal({
        id: 'add_address',
        Component: AddressForm,
        props: {
          isFullScreen: isMobileMode,
          title: 'Add New Shipping Address',
          paddingContent: {
            xl: '4px 40px 4px',
            lg: '16px 28px 16px',
            md: '16px 16px 20px',
          },
          closeButtonTop: {
            xl: 0,
            lg: 0,
            md: 16,
          },
        },
        styles: {
          width: isTabletMode ? 660 : 700,
        },
      }),
    );
  }, [dispatch, isMobileMode, isTabletMode]);

  const updateShownAddresses = useCallback(async () => {
    dispatch(UpdateAddressesPage());
  }, [dispatch]);

  const onApplyMobileFilters = useCallback(
    (filters) => {
      dispatch(
        SaveAddressBookFilters({
          address_book_filter: {
            sorting: {
              order: filters.sorting.order,
              orderBy: filters.sorting.orderBy,
            },
            view: filters.view,
          },
        }),
      );
      dispatch(ChangAddressBookFilter(filters));
    },
    [dispatch],
  );

  const importAddresses = useCallback(() => {
    dispatch(
      OpenModal({
        id: 'import_addresses',
        Component: ImportAddresses,
        props: {
          isFullScreen: isMobileMode,
          title: 'Import Addresses',
          paddingContent: {
            xl: '16px 40px 30px',
            lg: '16px 40px',
            md: '16px 12px 20px',
          },
        },
        styles: {
          width: 'fit-content',
          '& h2': {
            fontSize: isMobileMode ? 22 : 28,
          },
          '& #title-container': {
            paddingBottom: 10,
            paddingTop: isMobileMode ? 0 : 16,
          },
        },
      }),
    );
  }, [dispatch, isMobileMode]);

  return {
    addressBook,
    filters,
    isListView,
    onChangeView,
    onAddAddress,
    isLoading,
    onChangeSorting,
    updateShownAddresses,
    onApplyMobileFilters,
    importAddresses,
  };
};

export default useAddressBook;
