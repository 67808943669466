// Lib
import React from 'react';
import { makeStyles } from '@mui/styles';

// Components
import Button from '../../../Button';
import InfoCard from './InfoCard';
import EditRecurrenceButton from '../../Buttons/EditRecurrenceButton';
import Spinner from '../../../Spinner';

// Hooks
import useUser from '../../../../hooks/useUser';
import useRecurringOrder from '../../../../pages/OrderHistory/hooks/useRecurringOrder';

// Utils
import { getFormattedNextRunDate } from '../../../../utils/date';

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2.5),
    '& button:nth-child(1)': {
      height: 34,
      width: 89,
    },
    '& button:nth-child(2)': {
      color: theme.palette.primary1,
      height: 34,
      width: 89,
    },
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(2),
      '& button': {
        fontSize: 14,
      },
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      '& button': {
        height: '44px !important',
        width: '50% !important',
      },
    },
  },
  cards: {
    display: 'flex',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
    [theme.breakpoints.down('sm')]: {
      '& div:nth-child(3)': {
        width: '100%',
      },
    },
  },
}));

const RecurringOrderSection = ({ orderDetails }) => {
  const { isKiteUser } = useUser();
  const classes = useStyles({ isKiteUser });
  const { onCancel, onSkip, onEditRecurrence, loadingSkip } = useRecurringOrder(
    orderDetails,
    true,
  );

  return (
    <>
      <div className={classes.buttons}>
        <Button
          loadingIndicator={<Spinner height="initial" size={14} />}
          loading={loadingSkip}
          onClick={onSkip}>
          Skip
        </Button>
        <Button onClick={onCancel} variant="text">
          Cancel
        </Button>
      </div>
      <div className={classes.cards}>
        {orderDetails?.next_run && (
          <InfoCard dataTestId="next-shipping" title="Next Shipping">
            {getFormattedNextRunDate(orderDetails?.next_run)}
          </InfoCard>
        )}
        <InfoCard dataTestId="shipping-method" title="Shipping Method">
          {orderDetails.shipment_method.name}
        </InfoCard>
        <InfoCard dataTestId="recurrence" title="Recurrence">
          <EditRecurrenceButton onClick={onEditRecurrence} />
        </InfoCard>
      </div>
    </>
  );
};

export default RecurringOrderSection;
