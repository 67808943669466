// Lib
import { createReducer } from '@reduxjs/toolkit';

// Actions
import {
  SetOrders,
  ClearOrders,
  SetOrderDetails,
  ClearOrderDetails,
  SetReorderProcessing,
  ClearReorder,
  SetRecurringOrders,
  UpdateLocalRecurringOrder,
  UpdateCurrentOrder,
  DeleteLocalRecurringOrder,
  RemoveProductFromCurrentProducts,
  UpdateNextShippingDate,
  UpdateOrderViaSocket,
  SetRecurringOrderProductQty,
} from '../actions/orders';

export const initialState = {
  general: null,
  recurring: null,
  currentOrder: null,
  currentOrderProducts: [],
  reorder: {
    reorderProcessing: false,
  },
};

export default createReducer(initialState, {
  [SetOrders]: (state, { payload }) => ({ ...state, general: payload }),
  [SetRecurringOrders]: (state, { payload }) => ({
    ...state,
    recurring: payload,
  }),
  [UpdateLocalRecurringOrder]: (state, { payload }) => {
    return {
      ...state,
      recurring: state.recurring?.map((order) => {
        if (order.id === payload.id) {
          return payload;
        }
        return order;
      }),
    };
  },
  [RemoveProductFromCurrentProducts]: (state, { payload }) => {
    return {
      ...state,
      currentOrderProducts: state.currentOrderProducts.filter(
        (product) => product !== payload,
      ),
    };
  },
  [DeleteLocalRecurringOrder]: (state, { payload }) => ({
    ...state,
    recurring: state.recurring.filter((order) => order.id !== payload),
  }),
  [ClearOrders]: () => initialState,
  [SetOrderDetails]: (
    state,
    { payload: { currentOrder, currentOrderProducts } },
  ) => ({ ...state, currentOrder, currentOrderProducts }),
  [UpdateCurrentOrder]: (state, { payload }) => {
    if (payload.onlyNextRun) {
      return {
        ...state,
        currentOrder: { ...state.currentOrder, next_run: payload.next_run },
      };
    } else {
      return {
        ...state,
        currentOrder: payload,
      };
    }
  },
  [UpdateNextShippingDate]: (state, { payload: { id, next_run } }) => ({
    ...state,
    recurring: state.recurring.map((order) => {
      if (order.id === id) {
        return { ...order, next_run };
      }
      return order;
    }),
  }),
  [ClearOrderDetails]: (state) => ({
    ...state,
    currentOrder: initialState.currentOrder,
    currentOrderProducts: initialState.currentOrderProducts,
  }),
  [SetReorderProcessing]: (state, { payload }) => ({
    ...state,
    reorder: { ...state.reorder, reorderProcessing: payload },
  }),
  [UpdateOrderViaSocket]: (state, { payload }) => ({
    ...state,
    general: state?.general?.length
      ? [
          ...state?.general?.map((order) => {
            if (order.id === payload?.id) {
              return {
                ...order,
                ...payload,
              };
            }

            if (order?.children) {
              const children = [...order.children]?.map((childOrder) => {
                if (childOrder.id === payload?.id) {
                  return {
                    ...childOrder,
                    ...payload,
                  };
                }
                return childOrder;
              });
              return { ...order, children };
            }

            return order;
          }),
        ]
      : state?.general,
    currentOrder:
      state?.currentOrder?.status && state?.currentOrder?.id === payload?.id
        ? { ...state.currentOrder, ...payload }
        : state?.currentOrder,
  }),
  [ClearReorder]: (state) => ({
    ...state,
    reorder: initialState.reorder,
  }),
  [SetRecurringOrderProductQty]: (state, { payload }) => {
    const { productId, newQty } = payload;
    return {
      ...state,
      currentOrder: {
        ...state.currentOrder,
        order_items: state.currentOrder.order_items.map((item) =>
          item.product === productId ? { ...item, qty_ordered: newQty } : item,
        ),
      },
    };
  },
});
