// Lib
import React, { useCallback, useEffect, useMemo } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

//Components
import Layout from '../../components/Layout';
import OrderDetails from './OrderDetails';
import OrderHistoryBar from '../../components/OrderHistory/OrderHistoryBar/OrderHistoryBar';

// Hooks
import useOrdersHistoryFilters from './hooks/useOrdersHistoryFilters';
import useRecurringOrdersHistoryFilters from './hooks/useRecurringOrdersHistoryFilters';

// Pages
import RecurringOrders from './RecurringOrders';
import Orders from './Orders';
import RecurringMaterials from './RecurringMaterials';
import RecurringAddressBook from './RecurringAddressBook';
import SkipFromEmail from './SkipFromEmail';

// Actions
import { UpdateOrderViaSocket } from '../../actions/orders';

// Socket
import { openSocket } from '../../api/socket';

const useStyles = makeStyles((theme) => ({
  layout: ({ withSearchBar }) => ({
    paddingTop: withSearchBar ? 0 : theme.spacing(3),
    minHeight: 'calc(100vh - 305px)',
  }),
  footer: {
    position: 'sticky',
    left: 'unset',
    right: 'unset',
  },
}));

const LayoutWrapper = ({ withSearchBar = true, renderComponent, children }) => {
  const classes = useStyles({ withSearchBar });

  return (
    <Layout
      className={{ ...classes }}
      renderComponent={withSearchBar && renderComponent}>
      {children}
    </Layout>
  );
};

const OrderHistory = ({ userType }) => {
  const { pathname } = useLocation();
  const activeTab = useMemo(() => {
    if (pathname === '/order-history') {
      return 0;
    }
    if (pathname === '/order-history/recurring') {
      return 1;
    }
  }, [pathname]);

  useEffect(() => {
    const client = openSocket('orders/', UpdateOrderViaSocket);

    return () => {
      if (client.readyState === 1) {
        client.closeWithoutReconnect = true;
        client.close();
      }
    };
  }, []);

  const filters = useOrdersHistoryFilters();
  const recurringFilters = useRecurringOrdersHistoryFilters();

  const renderComponent = useCallback(
    () => (
      <OrderHistoryBar
        userType={userType}
        activeTab={activeTab}
        filters={activeTab === 0 ? filters : recurringFilters}
        isRecurring={activeTab === 1}
      />
    ),
    [userType, activeTab, filters, recurringFilters],
  );

  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <LayoutWrapper renderComponent={renderComponent}>
            <Orders
              orders={
                (activeTab === 0 ? filters : recurringFilters).filteredOrders
              }
            />
          </LayoutWrapper>
        }
      />
      <Route
        exact
        path="/:orderId"
        element={
          <LayoutWrapper
            renderComponent={renderComponent}
            withSearchBar={false}>
            <OrderDetails />
          </LayoutWrapper>
        }
      />
      <Route
        exact
        path="/recurring"
        element={
          <LayoutWrapper renderComponent={renderComponent}>
            <RecurringOrders
              orders={
                (activeTab === 0 ? filters : recurringFilters).filteredOrders
              }
            />
          </LayoutWrapper>
        }
      />
      <Route
        path="/recurring/:orderId/skip_from_email"
        element={<SkipFromEmail />}
      />
      <Route
        exact
        path="/recurring/:orderId"
        element={
          <LayoutWrapper
            renderComponent={renderComponent}
            withSearchBar={false}>
            <OrderDetails isRecurring />
          </LayoutWrapper>
        }
      />
      <Route
        exact
        path="/recurring/:orderId/materials"
        element={<RecurringMaterials />}
      />
      <Route
        exact
        path="/recurring/:orderId/address-book"
        element={<RecurringAddressBook />}
      />
    </Routes>
  );
};

export default OrderHistory;
