// Lib
import React, { useMemo, useState, useCallback } from 'react';
import Box from '@mui/material/Box';

// Components
import NestedList from '../../NestedList';
import Button from '../../Button';
import capitalize from '../../../utils/capitalize';

const MobileFilters = ({
  filters: storeFilters,
  onApplyMobileFilters,
  onCloseSideMenu,
}) => {
  const [filters, setFilters] = useState(storeFilters);

  const items = useMemo(() => {
    const options = [];
    filters?.sorting?.items.forEach((item) => {
      options.push({ title: `${capitalize(item.title, true)} (Asc)` });
      options.push({ title: `${capitalize(item.title, true)} (Desc)` });
    });
    return options;
  }, [filters]);

  const onChangeSorting = useCallback(
    (_, orderBy) => () => {
      const newFilter = { ...filters.sorting };
      const isDescOrder = orderBy?.toLowerCase()?.includes('desc');
      newFilter.orderBy = isDescOrder
        ? orderBy.slice(0, orderBy.length - 7)
        : orderBy.slice(0, orderBy.length - 6);
      newFilter.order = isDescOrder ? 'desc' : 'asc';

      setFilters((prevFilters) => ({ ...prevFilters, sorting: newFilter }));
    },
    [filters],
  );

  const onApplyFilters = useCallback(() => {
    onApplyMobileFilters(filters);
    onCloseSideMenu();
  }, [onCloseSideMenu, onApplyMobileFilters, filters]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="calc(100% - 32px)"
      justifyContent="space-between">
      <NestedList
        withChanges={filters?.sorting}
        onChange={onChangeSorting}
        title="Sort By"
        items={items}
        orderBy={`${filters?.sorting?.orderBy} (${filters?.sorting?.order})`}
        order={filters?.sorting?.order}
      />
      <Box
        alignItems="center"
        display="flex"
        justifyContent="end"
        pt={6}
        pb={2}>
        <Button
          id="apply-filters"
          onClick={onApplyFilters}
          styles={{ width: 296, height: 44, maxHeight: 44 }}>
          Apply Filters
        </Button>
      </Box>
    </Box>
  );
};

export default MobileFilters;
