// Lib
import React, { useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

// Components
import TitleContainer from '../TitleContainer';
import useApprovals from '../../../pages/Approvals/hooks/useApprovals';
import OrderInfo from '../OrderQty/OrderInfo';
import ApproveResponse from '../OrderQty/ApproveResponse';
import Button from '../../Button';
import Spinner from '../../Spinner';
import ProductsList from '../ProductsList';
import Select from '../../Field/Select';

// Hooks
import useUI from '../../../hooks/useUI';
import ApprovalInfo from '../ApprovalInfo';
import useMethodApprovalInfo from '../../../pages/Approvals/hooks/useMethodApprovalInfo';
import ErrorMessage from '../../Error/ErrorMessage';

const useStyles = makeStyles((theme) => ({
  h1: {
    fontSize: 28,
    [theme.breakpoints.down('md')]: {
      fontSize: 22,
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 22,
      }),
    },
  },
  h2: {
    [theme.breakpoints.down('lg')]: {
      fontSize: 18,
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        fontSize: 18,
        marginBottom: theme.spacing(1),
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 16,
      }),
    },
  },
  titleSelect: {
    [theme.breakpoints.down('lg')]: {
      fontSize: 16,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        fontSize: 16,
      }),
    },
  },
  approveButton: {
    marginLeft: 'auto',
    marginTop: theme.spacing(3.5),
    minWidth: 146,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      minWidth: '100%',
    },
  },
  orderInfo: {
    marginBottom: theme.spacing(3.5),
  },
  select: {
    marginBottom: theme.spacing(3),
  },
  method: {
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 14,
      }),
    },
  },
  status: {
    color: '#929A92',
    fontSize: 14,
    fontWeight: 600,
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(3.5),
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      marginBottom: theme.spacing(2.5),
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        marginBottom: theme.spacing(3.5),
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 12,
        marginBottom: theme.spacing(2.5),
      }),
    },
  },
}));

const ShippingMethod = () => {
  const classes = useStyles();
  const {
    onChangeMethod,
    approvals,
    fullName,
    onChangeResponse,
    onSubmit,
    isSaving,
    onOpenShippingModal,
    isSubmitDisabled,
    isMethodApproved,
    comment,
    apiError,
  } = useApprovals(true);
  const { isDesktopMode } = useUI();

  const { order, approvals: approvalsData } = approvals;
  const { status, approvalDate, approverEmail, approverFullName } =
    useMethodApprovalInfo(approvalsData?.shipment_method || {});

  const addressesCount = useMemo(
    () => order?.addresses.length,
    [order?.addresses],
  );

  return (
    <>
      <TitleContainer
        isApproved={isMethodApproved}
        fullName={fullName}
        message="Order shipping method is automatically approved after 6 hours if no action is taken."
      />

      <OrderInfo
        isMethodPage
        isApproved={isMethodApproved}
        className={classes.orderInfo}
        onOpenShippingModal={onOpenShippingModal}
        addressesCount={addressesCount}
        order={order}
      />

      {!isDesktopMode && (
        <>
          <Typography mb={0.5} variant="h2" className={classes.titleSelect}>
            Shipping method
          </Typography>
          {!isMethodApproved ? (
            <Select
              onChange={onChangeMethod}
              className={classes.select}
              classic
              initialValue={order?.shipment_method}
              items={approvals.shippingMethods}
            />
          ) : (
            <>
              <div className={classes.method}>
                {approvals?.order?.shipment_method}
              </div>
              <div className={classes.status}>Approved</div>
            </>
          )}
        </>
      )}

      <ApproveResponse
        isApproved={isMethodApproved}
        isRequired
        onChangeResponse={onChangeResponse}
        response={comment || approvals.approveResponse}
      />

      {isMethodApproved && (
        <ApprovalInfo
          approvals={[
            {
              approverId: approverEmail,
              approverFullName: approverFullName,
              approverEmail: approverEmail,
              approvalDate: approvalDate,
            },
          ]}
          title={`${status} by`}
        />
      )}

      {apiError && <ErrorMessage message={apiError} />}
      {!isMethodApproved && (
        <Button
          disabled={isSubmitDisabled}
          loading={isSaving}
          loadingIndicator={<Spinner size={20} height={24} />}
          onClick={onSubmit}
          className={classes.approveButton}>
          Approve
        </Button>
      )}

      <Typography
        mt={{ lg: 3.5, xs: 3 }}
        mb={{ lg: 0.5, md: 1.5, xs: 2 }}
        className={classes.h2}
        variant="h2">
        Products
      </Typography>
      <ProductsList
        isMethodPage
        addressesCount={addressesCount}
        approvals={approvals}
      />
    </>
  );
};

export default ShippingMethod;
