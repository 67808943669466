import { SetConfigurableMessage, SetValidate } from '../actions/validate';
import { createReducer } from '@reduxjs/toolkit';

import {
  isProductExpiredValidate,
  isProductWithdrawnValidate,
  isProductOutOfStockValidate,
  isProductAvailableValidate,
} from '../pages/Validate/utils';

export const initialState = {
  all: {},
  expired: {},
  withdraw: {},
  outOfStock: {},
  available: {},
  unavailable: {},
  configurableMessage: {},
};

export default createReducer(initialState, {
  [SetValidate]: (state, { payload }) => ({
    ...state,
    all:
      Array.isArray(payload) && !payload.length ? null : payload ? payload : {},
    withdraw: isProductWithdrawnValidate(payload) ? payload : {},
    expired: isProductExpiredValidate(payload) ? payload : {},
    outOfStock: isProductOutOfStockValidate(payload) ? payload : {},
    available: isProductAvailableValidate(payload) ? payload : {},
  }),
  [SetConfigurableMessage]: (state, { payload }) => ({
    ...state,
    configurableMessage: payload,
  }),
});
