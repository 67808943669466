// Lib
import React, { useCallback } from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { makeStyles } from '@mui/styles';
import locale from 'date-fns/locale/en-US';
import { format } from 'date-fns';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// Assets
import { ReactComponent as Calendar } from '../../assets/svg/calendar.svg';

// Hooks
import useUser from '../../hooks/useUser';

function customAdapter(options) {
  const adapter = new AdapterDateFns(options);

  const constructDayObject = (day) => ({ charAt: () => day });

  return {
    ...adapter,

    getWeekdays() {
      const customWeekdays = adapter.getWeekdays();

      return customWeekdays.map((day) => constructDayObject(day));
    },
  };
}

const useStyles = makeStyles((theme) => ({
  root: ({
    isKiteUser,
    placeholderGradientPercentage,
    required,
    inSidebarOnMobile,
  }) => ({
    '& .MuiOutlinedInput-root': {
      color: theme.palette.darkGrey,
      padding: `8px 10px !important`,

      '& .MuiButtonBase-root': {
        padding: 4,
      },
      '& .MuiInputAdornment-root': {
        marginLeft: 8,
      },
      '& fieldset': {
        borderColor: theme.palette.grey1,
      },
      '& svg': {
        fill: isKiteUser ? theme.palette.primary1 : theme.palette.primary2,
      },
      '&.Mui-disabled svg': {
        fill: theme.palette.grey2,
      },
      '& input': {
        marginLeft: 0,
        fontSize: 'inherit',
        [theme.breakpoints.down('md')]: {
          fontSize: '14px',
        },
        '&::placeholder': {
          ...(required && {
            opacity: 1,
            background: `-webkit-linear-gradient(left, #AAA 0%, #AAA ${placeholderGradientPercentage}%, red 0%, red 0%)`,
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent',
          }),
          [theme.breakpoints.down('md')]: {
            fontSize: 14,
            background: `-webkit-linear-gradient(left, #AAA 0%, #AAA ${
              placeholderGradientPercentage * 0.87
            }%, red 0%, red 0%)`,
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent',
          },
        },
      },
      ...(inSidebarOnMobile && {
        [theme.breakpoints.down('md')]: {
          padding: `13px 12px !important`,
        },
      }),
    },
    '& .MuiFormHelperText-root': ({
      helperTextPosition,
      inSidebarOnMobile,
    }) => ({
      marginInline: 0,
      position: helperTextPosition,
      top:
        helperTextPosition === 'absolute'
          ? inSidebarOnMobile
            ? '45px'
            : '34px'
          : 'unset',
      overflowX: 'shown',
      width: 'max-content',
      fontSize: '14px',
      [theme.breakpoints.down('md')]: {
        fontSize: '10px',
      },
    }),
  }),
  paper: {
    borderRadius: 12,
    boxShadow: '0px 5px 17px rgba(44, 112, 172, 0.2)',
    marginTop: 15,
  },
  dialog: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(48, 85, 107, 0.2)',
    },
    '& .MuiPaper-root': {
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
      bottom: 0,
      margin: 0,
      position: 'absolute',
    },
    '& .MuiDialogActions-root': {
      display: 'none !important',
    },
    '& .MuiCalendarPicker-root': {
      '& > div': {
        '&:first-child': {
          position: 'relative',
          '& .MuiPickersArrowSwitcher-root': {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          },
          '& > div:first-child': {
            right: '50%',
            transform: 'translate(50%, 0)',
            position: 'absolute',
          },
        },
      },
    },
  },
  calendarIcon: ({ inSidebarOnMobile }) => ({
    zIndex: -1,
    top: '8px',
    position: 'absolute',
    marginRight: '8px',
    width: 18,
    height: 18,
    ...(inSidebarOnMobile && {
      [theme.breakpoints.down('md')]: {
        top: '12px',
      },
    }),
  }),
}));

locale.options.weekStartsOn = 0;

const DatePicker = ({
  date,
  width,
  placeholder,
  onChange = () => {},
  required,
  datesDisabled = [],
  placeholderGradientPercentage = 85,
  helperTextPosition = 'relative' /* relative or absolute to move next elements down (under date picker) or not to */,
  inSidebarOnMobile = false /* is datepicker inside sidebar in mobile mode (then special styling) */,
  ...props
}) => {
  const onErrorText = `Valid format: mm/dd/yyyy`;
  const theme = useTheme();
  const { isKiteUser } = useUser();
  const isMobileMode = useMediaQuery(theme.breakpoints.down('md'));
  const classes = useStyles({
    isKiteUser,
    isMobileMode,
    placeholderGradientPercentage,
    required,
    helperTextPosition,
    inSidebarOnMobile,
  });

  const shouldDisableDate = useCallback(
    (date) => {
      if (datesDisabled && datesDisabled[format(date, 'yyyy-MM-dd')]) {
        return true;
      }
    },
    [datesDisabled],
  );

  return (
    <LocalizationProvider dateAdapter={customAdapter} adapterLocale={locale}>
      <Stack width={width || 130} className={classes.root}>
        <DesktopDatePicker
          shouldDisableDate={shouldDisableDate}
          closeOnSelect={true}
          showToolbar={false}
          showDaysOutsideCurrentMonth
          inputFormat="MM/dd/yyyy"
          value={date || null}
          onChange={onChange}
          renderInput={(params) => (
            <TextField
              {...params}
              helperText={params.error && onErrorText}
              inputProps={{
                ...params.inputProps,
                placeholder,
              }}
            />
          )}
          InputAdornmentProps={{ position: 'start' }}
          PaperProps={{ classes: { root: classes.paper } }}
          DialogProps={{ classes: { root: classes.dialog } }}
          components={{
            OpenPickerIcon: Calendar,
          }}
          InputProps={{
            classes: { root: classes.root },
            startAdornment: <Calendar className={classes.calendarIcon} />,
          }}
          {...props}
        />
      </Stack>
    </LocalizationProvider>
  );
};

export default DatePicker;
