import React from 'react';
import Layout from '../../components/Layout';
import Button from '../../components/Button';
import { Box, TextField, Typography } from '@mui/material';
import useValidateProducts from './useValidateProducts';

const ValidateDesktop = () => {
  const { value, onChangeInput, handleCheck } = useValidateProducts();
  return (
    <Layout title={'Validate'}>
      <Box display={'flex'} width={'100%'} height={'70vh'}>
        <Box
          display={'flex'}
          height={'70vh'}
          margin={'0 auto'}
          rowGap={5}
          flexDirection={'column'}
          alignContent={'center'}
          justifyContent={'center'}>
          <Typography variant={'h1'}>Enter Stock Number</Typography>
          <Box
            display={'flex'}
            flexDirection={'column'}
            width={400}
            rowGap={2}
            justifyContent={'center'}>
            <Typography htmlFor="input" variant={'textMdSemibold'}>
              Stock Number
            </Typography>
            <TextField
              id="input"
              value={value}
              onChange={(e) => onChangeInput(e.target.value)}
              fullWidth
            />
            <Button
              variant={'contained'}
              disabled={!value}
              onClick={handleCheck}>
              Check
            </Button>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default ValidateDesktop;
