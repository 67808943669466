// Lib
import React from 'react';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
  title: {
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0.1px',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      lineHeight: '16px',
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 12,
        lineHeight: '16px',
      }),
    },
  },
}));

const AddressTitle = ({ title, className, ...props }) => {
  const classes = useStyles();
  return (
    <div className={classnames(classes.title, className)} {...props}>
      {title}
    </div>
  );
};

export default AddressTitle;
