// Libs
import { createReducer } from '@reduxjs/toolkit';

// Actions
import { SetApiError, ClearApiError } from '../actions/apiError';

const initialState = {};

export default createReducer(initialState, {
  [SetApiError]: (state, { payload }) => {
    return {
      ...state,
      [payload.api]: payload.error,
      id: payload?.id,
    };
  },
  [ClearApiError]: () => initialState,
});
