// Lib
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// Components
import OrderProcessingInfoCard from '../../../components/Cart/ShippingDetails/OrderProcessingInfo/OrderProcessingInfoCard';
import OrderProcessingInfo from '../../../components/Cart/ShippingDetails/OrderProcessingInfo';

// Actions
import { OpenModal } from '../../../actions/modal';
import useUI from '../../../hooks/useUI';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700,
  },
  text: {
    color: '#1E1E1E',
    width: 'initial',
  },
}));

const useOrderProcessingInfo = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isMobileMode } = useUI();

  const openOrderProcessingWindow = useCallback(
    (header, message) => () => {
      dispatch(
        OpenModal({
          Component: () => (
            <OrderProcessingInfoCard>
              <OrderProcessingInfo
                classNames={classes}
                header={header}
                message={message}
              />
            </OrderProcessingInfoCard>
          ),
          props: {
            paddingContent: {
              xl: '50px 40px 50px',
              lg: '30px 40px',
              md: '20px 16px 16px',
            },
          },
          styles: {
            width: isMobileMode ? '268px' : '541px',
          },
        }),
      );
    },
    [isMobileMode],
  );

  return { openOrderProcessingWindow };
};

export default useOrderProcessingInfo;
