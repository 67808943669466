// Lib
import React from 'react';
import { makeStyles } from '@mui/styles';

// Components
import Image from '../../../Image';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  imageContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    minHeight: 198,
    maxHeight: 198,
    minWidth: 198,
    maxWidth: 198,
    marginRight: theme.spacing(3.5),
    '& img': {
      maxWidth: 198,
      maxHeight: 198,
    },
    [theme.breakpoints.down('md')]: {
      minHeight: 80,
      maxHeight: 80,
      minWidth: 78,
      maxWidth: 78,
      marginRight: theme.spacing(1),
      '& img': {
        maxWidth: 78,
        maxHeight: 80,
      },
    },
  },
  description: {
    fontSize: 18,
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  },
}));

const RemovedProduct = ({ product }) => {
  const classes = useStyles();

  const { image_link: image, product_description: description } = product || {};

  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        <Image
          alt="productImage"
          src={image}
          data-testid="removed-product-image"
        />
      </div>
      <div className={classes.description} data-testid="removed-product-name">
        {description}
      </div>
    </div>
  );
};

export default RemovedProduct;
