// Lib
import React, { useCallback, useEffect, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Components
import Layout from '../../components/Layout';
import Spinner from '../../components/Spinner';
import AddressBookList from '../../components/AddressBook/List';
import FiltersBar from '../../components/AddressBook/Bar/FiltersBar';
import OuterElementType from '../../components/VirtualizedList/OuterElementType';
import Button from '../../components/Button';
import Error from '../../components/Error';

// Hooks
import useAddressBook from './hooks/useAddressBook';

// Actions
import { GetAddressBook } from '../../actions/addressBook';

// Constants
import { emptyAddressBook } from '../../constants/addressBook';
import { ClearShippingAddresses } from '../../actions/cart';

const useStyles = makeStyles((theme) => ({
  layout: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 70px - 158px)', // minus header and bar
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('xl')]: {
      paddingTop: theme.spacing(1),
    },
  },
  emptyBookContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 'calc(100vh - 289px)',
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        height: 'initial',
      }),
    },
  },
  emptyAddressBook: {
    marginBottom: theme.spacing(5),
    width: 540,
    [theme.breakpoints.down('md')]: {
      width: 290,
    },
  },
  emptyAddressBookButton: {
    minHeight: 46,
    [theme.breakpoints.down('lg')]: {
      width: 170,
      minHeight: 44,
    },
  },
}));

const AddressBook = ({
  isAddressBookPage = true,
  children,
  onlyContent = false,
}) => {
  const apiError = useSelector((state) => state.apiError?.['getAddressBook']);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const {
    filters,
    isListView,
    onChangeView,
    onAddAddress,
    onChangeCountry,
    isLoading,
    addressBook,
    onChangeSorting,
    updateShownAddresses,
    onApplyMobileFilters,
    importAddresses,
  } = useAddressBook();

  useEffect(() => {
    if (pathname === '/address-book') {
      dispatch(ClearShippingAddresses());
    }
  }, [dispatch, pathname]);

  const onClickButton = useCallback(() => {
    if (pathname.includes('/order-history/recurring')) {
      return navigate(-1);
    }
    return onAddAddress();
  }, [pathname, onAddAddress, navigate]);

  useEffect(() => {
    dispatch(GetAddressBook());
  }, [dispatch]);

  const content = useMemo(() => {
    if (isLoading) {
      return <Spinner />;
    }
    if (apiError) {
      return <Error message={apiError} />;
    }
    if (addressBook?.all?.length) {
      return (
        <>
          <AddressBookList
            isAddressBookPage={isAddressBookPage}
            isListView={isListView}
            addressBook={addressBook}
            OuterElementType={OuterElementType}
            updateShownAddresses={updateShownAddresses}
          />
          {children}
        </>
      );
    } else {
      return (
        <div className={classes.emptyBookContainer}>
          <Typography
            className={classes.emptyAddressBook}
            variant="h1"
            textAlign="center">
            {emptyAddressBook}
          </Typography>
          {pathname !== '/cart/shipping-address' && (
            <Button
              onClick={onClickButton}
              className={classes.emptyAddressBookButton}>
              {pathname.includes('/order-history/recurring')
                ? 'Go To Order'
                : 'Add New Address'}
            </Button>
          )}
        </div>
      );
    }
  }, [
    isLoading,
    addressBook,
    isListView,
    updateShownAddresses,
    isAddressBookPage,
    children,
    classes,
    apiError,
    onClickButton,
    pathname,
  ]);

  return (
    <Layout
      title="Address book"
      onlyContent={onlyContent}
      searchResults={addressBook.searchResults}
      className={{ layout: classes.layout }}
      renderComponent={() => (
        <FiltersBar
          onlyContent={onlyContent}
          isAddressBookPage={isAddressBookPage}
          searchResults={addressBook.searchResults}
          onChangeSorting={onChangeSorting}
          onChangeCountry={onChangeCountry}
          filters={filters}
          isListView={isListView}
          onChangeView={onChangeView}
          onAddAddress={onAddAddress}
          onApplyMobileFilters={onApplyMobileFilters}
          isLoading={isLoading}
          importAddresses={importAddresses}
        />
      )}>
      {content}
    </Layout>
  );
};

export default AddressBook;
