// Libs
import React from 'react';
import { Tabs, Tab } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

// Hooks
import useUser from '../../../hooks/useUser';

// Utils
import { supportTabs } from '../../../constants/support';

const useStyles = makeStyles((theme) => ({
  tabs: {
    width: '256px',
    height: '44px',
    padding: '4px',
    alignItems: 'center',
    borderRadius: 30,
    boxShadow: '0px 5px 17px rgba(48, 85, 107, 0.2)',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  tab: ({ isKiteUser }) => ({
    width: '120px',
    minHeight: '36px',
    borderRadius: 20,
    fontSize: 14,
    fontWeight: isKiteUser ? 400 : 600,
    textTransform: 'none',
    color: theme.palette.grey2,
    '&.Mui-selected': {
      backgroundColor: theme.palette.lightGrey,
      color: isKiteUser && theme.palette.primary2,
    },
    '&:first-child': {
      marginRight: '8px',
    },
    [theme.breakpoints.down('md')]: {
      width: 'calc(50% - 4px)',
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        width: 'calc(50% - 4px)',
      }),
    },
  }),
}));

const PagesSwitcher = (props) => {
  const { activeTab, setActiveTab } = props;
  const { isKiteUser } = useUser();
  const classes = useStyles({ isKiteUser });

  return (
    <Tabs
      value={activeTab}
      onChange={(event, newValue) => setActiveTab(newValue)}
      className={classes.tabs}>
      {supportTabs.map((tab) => (
        <Tab
          key={tab.path}
          label={tab.label}
          component={NavLink}
          to={tab.path}
          className={classes.tab}
        />
      ))}
    </Tabs>
  );
};

export default PagesSwitcher;
