// Lib
import { useMemo } from 'react';

const useQtyApprovalInfo = (data, email) => {
  const productsForApprove = useMemo(
    () =>
      Object.keys(data).filter(
        (productId) =>
          !!data?.[productId]?.approvers?.find(
            ({ approver_email }) => approver_email === email,
          ),
      ),
    [data, email],
  );

  const isAllProductsRejected = useMemo(
    () =>
      productsForApprove?.filter(
        (productId) => data?.[productId]?.status === 'REJECTED',
      ).length === productsForApprove.length,
    [data, productsForApprove],
  );

  const isApprovedWithChanges = useMemo(
    () =>
      productsForApprove?.find(
        (productId) =>
          data?.[productId]?.qty_ordered !== data?.[productId]?.qty_approved,
      ),
    [data, productsForApprove],
  );

  const status = useMemo(() => {
    if (isAllProductsRejected) {
      return 'Rejected';
    }
    if (isApprovedWithChanges) {
      return 'Modified and approved';
    }
    return 'Approved';
  }, [isApprovedWithChanges, isAllProductsRejected]);

  const approvals = useMemo(() => {
    return Object.values(data).reduce((approvals, productQtyApprove) => {
      const { approved_by, approvers, approval_date } = productQtyApprove;
      const approver = approvers?.find(
        (approver) => approver.approver_id === approved_by,
      );
      const { approver_full_name, approver_email } = approver || {};

      if (
        approved_by &&
        !approvals.some((approve) => approve.approverId === approved_by)
      ) {
        approvals.push({
          approverId: approved_by,
          approverFullName: approver_full_name || '',
          approverEmail: approver_email || '',
          approvalDate: approval_date,
        });
      }

      return approvals;
    }, []);
  }, [data]);

  return {
    status,
    approvals,
  };
};

export default useQtyApprovalInfo;
