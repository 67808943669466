// Lib
import React from 'react';
import { Routes as Switch, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Routes
import { commonRoutes, securedRoutes, authRoutes } from './allRoutes';

const Routes = ({ userType, isLoggedIn }) => {
  const prevPath = useSelector((state) => state?.location?.prevPath);

  return (
    <Switch>
      {commonRoutes.map(({ title, path, page: Page }) => (
        <Route key={title} path={path} element={<Page userType={userType} />} />
      ))}
      {!isLoggedIn &&
        authRoutes.map(({ title, path, page: Page }) => (
          <Route
            key={title}
            path={path}
            element={<Page userType={userType} />}
          />
        ))}
      {isLoggedIn &&
        securedRoutes.map(({ title, path, page: Page }) => (
          <Route
            key={title}
            path={path}
            element={<Page userType={userType} />}
          />
        ))}
      {isLoggedIn && !prevPath && (
        <Route path="*" element={<Navigate to="/materials" replace />} />
      )}
    </Switch>
  );
};

export default Routes;
