import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetConfigurableMessage, Validate } from '../../actions/validate';
import { useNavigate } from 'react-router-dom';
export default function useValidateProducts() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.validate);
  const isLoading = useSelector((state) => state.isLoading);
  const [value, setValue] = useState('');

  function onChangeInput(value) {
    setValue(value);
  }

  function handleCheck() {
    dispatch(Validate(value));
    dispatch(GetConfigurableMessage());
    navigate('./results');
  }

  return {
    state,
    value,
    isLoading,
    onChangeInput,
    handleCheck,
  };
}
