import USER_TYPE from '../constants/user';

const commonColors = {
  grey1: '#C6CAC6',
  grey2: '#929A92',
  lightGrey: '#F4F4F4',
  darkGrey: '#54565B',
  black: '#1E1E1E',
  blue: '#3C587F',
  red: '#C5203F',

  disabled: '#F4F4F4',
};

export const colors = {
  gilead: {
    primary1: '#C5203F',
    primary2: '#30556B',
    primary3: '#929A92',
    hovered: '#D02041',
    pressed: '#30556B',
    blured: '#30556b33',
  },
  kite: {
    primary1: '#2C70AC',
    primary2: '#51438A',
    primary3: '#C5203F',
    hovered: '#3C89D0',
    pressed: '#286CA7',
    disabled: '#F4F4F4',
    blured: '#2c70ac33',
  },
  ...commonColors,
};

const createPalette = (userType) => ({
  ...(userType === USER_TYPE.kite ? colors.kite : colors.gilead),
  ...commonColors,

  text: {
    primary: '#1E1E1E',
    secondary: '#54565B',
  },
  primary: {
    main:
      userType === USER_TYPE.kite
        ? colors.kite.primary1
        : colors.gilead.primary1,
    light: '#54565B',
  },
  secondary: {
    main: '#F4F4F4',
  },
});

export default createPalette;
