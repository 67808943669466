// Lib
import React, { useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns';
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux';

// Components
import OrderIdLink from '../../../components/OrderHistory/Buttons/OrderIdLink';
import Track from '../../../components/OrderHistory/Buttons/Track';
import IconButton from '../../../components/Button/IconButton/IconButton';
import ToggleButton from '../../../components/Button/ToggleButton';
import Reorder from '../../../components/Button/ReorderButton/ReorderButton';
import AddressesTruncatedLabel from '../../../components/OrderHistory/Labels/AddressesTruncatedLabel';

// Hooks
import useReorder from './useReorder';
import useAddToKits from '../../Kits/hooks/useAddToKits';

// Utils
import capitalize from '../../../utils/capitalize';
import { getOrderQty, getTotalOrderedQty } from '../utils/getQuantity';
import isProductAvailable from '../../../utils/isProductAvailable';

// Selectors
import { productsObjSelector } from '../../../store/selectors';

const useStyles = makeStyles((theme) => ({
  addressCell: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  created: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'fit-content',
  },
  totalItems: {
    color: theme.palette.red,
  },
}));

const useOrderCellReducer = ({
  order,
  onToggleChildren = () => {},
  withChildren = false,
}) => {
  const classes = useStyles();
  const { onReorder } = useReorder();
  const isDistro = !!order.addresses && !order.address;
  const { onAddToMyKits } = useAddToKits(order.id);
  const productsObj = useSelector((state) => productsObjSelector(state));

  const canReorder = useMemo(
    () =>
      !!order?.order_items.find(({ product }) =>
        isProductAvailable(productsObj[product]),
      ),
    [order, productsObj],
  );

  const cells = useMemo(
    () => ({
      orderId: <OrderIdLink order={order} />,
      tracking: order?.tracking_link && (
        <Track trackingLink={order.tracking_link} />
      ),
      created: order.created && (
        <div className={classes.created}>
          <div>{format(new Date(order.created), 'M/d/yyyy')}</div>
          <div>{format(new Date(order.created), 'hh:mm a')}</div>
        </div>
      ),
      orderStatus: capitalize(
        order.status === 'VERACORE FAILED' ? 'ORDERED' : order?.status,
        true,
      ),
      shippingAddress: (
        <AddressesTruncatedLabel
          order={order}
          stringsQty={2}
          className={classes.addressCell}
        />
      ),
      orderQty: getOrderQty(order),
      totalOrdered: (
        <span className={classes.totalItems}>
          {getTotalOrderedQty(order, productsObj)}
        </span>
      ),
      addToMyKits: !isDistro ? (
        <IconButton
          disabled={!order.can_be_added_to_kit}
          data-testid={'add-to-kits-button'}
          onClick={onAddToMyKits}
          startIcon={<AddIcon />}>
          Add To My Kits
        </IconButton>
      ) : (
        <></>
      ),
      reorder: !!order?.children?.length ? (
        <ToggleButton open={withChildren} onToggle={onToggleChildren} />
      ) : (
        order.address && (
          <Reorder
            order={order}
            onClick={onReorder}
            inTable={true}
            disabled={!canReorder}
          />
        )
      ),
    }),
    [
      order,
      classes.created,
      classes.addressCell,
      productsObj,
      isDistro,
      withChildren,
      onToggleChildren,
      onReorder,
      onAddToMyKits,
      canReorder,
    ],
  );

  return { cells };
};

export default useOrderCellReducer;
