// Lib
import React, { useMemo } from 'react';
import { makeStyles } from '@mui/styles';

// Components
import Layout from '../../../components/Layout';
import Header from '../../../components/Cart/Header';
import OrderInfo from '../../../components/Cart/ReviewOrder/OrderInfo';
import FooterButtons from '../../../components/Cart/ShippingDetails/FooterButtons';

// Hooks
import useReviewOrder from '../hooks/useReviewOrder';

// Utils
import { getErrorString } from '../../../utils/apiErrors';

// Constants
import { colors } from '../../../styles/palette';

const useStyles = makeStyles((theme) => ({
  layout: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(13),
    minHeight: 'calc(100vh - 240px)',
    [theme.breakpoints.down('lg')]: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: ({ isPlacedOrder }) =>
        isPlacedOrder ? 'calc(100vh - 55px)' : 'calc(100vh - 110px)',
      paddingBottom: theme.spacing(3.75),
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: ({ isPlacedOrder }) =>
        isPlacedOrder ? theme.spacing(2.5) : theme.spacing(2.25),
      paddingBottom: theme.spacing(3.25),
    },
    '& #next-button': {
      [theme.breakpoints.down('md')]: {
        width: ({ isPlacedOrder }) => isPlacedOrder && '296px !important',
      },
    },
  },
  apiError: {
    color: colors.gilead.primary1,
    fontSize: 14,
  },
}));

const ReviewOrder = () => {
  const {
    shippingDetails,
    goToSippingDetails,
    cart,
    placeOrder,
    loading,
    onChangeInstructions,
    placedOrder,
    moreThanInStock,
    apiError,
    withOverLimitRequest,
    disabledCountBtn,
    isAvailableMethod,
  } = useReviewOrder();

  const isPlacedOrder = useMemo(() => !!placedOrder?.id, [placedOrder]);

  const classes = useStyles({ isPlacedOrder });

  return (
    <Layout
      className={{ layout: classes.layout }}
      renderComponent={() => <Header empty={!!placedOrder?.id} />}>
      <OrderInfo
        cart={cart}
        placedOrder={placedOrder}
        shippingDetails={shippingDetails}
        goToSippingDetails={goToSippingDetails}
        onChangeInstructions={onChangeInstructions}
        withOverLimitRequest={withOverLimitRequest}
        disabledCountBtn={disabledCountBtn}
        isAvailableMethod={isAvailableMethod}
        moreThanInStock={moreThanInStock}
      />
      {apiError && (
        <span className={classes.apiError}>{getErrorString(apiError)}</span>
      )}
      <FooterButtons
        withPrevButton={!placedOrder.id}
        loading={loading}
        nextButtonDisabled={moreThanInStock || !isAvailableMethod}
        nextTitle={placedOrder.id ? 'Continue Shopping' : 'Place Order'}
        backTitle="Shipping Details"
        onClickBack={goToSippingDetails}
        onClickNext={placeOrder}
      />
    </Layout>
  );
};

export default ReviewOrder;
