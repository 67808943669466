// Libs
import React, { useState } from 'react';
import { Box, Collapse, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

// Components
import Feather from '../../Feather';

// Hooks
import useUser from '../../../hooks/useUser';
import useUI from '../../../hooks/useUI';

// Styles
import { cardRoundedShadow } from '../../../styles/global';

const useStyles = makeStyles((theme) => ({
  card: ({ isKiteUser }) => ({
    ...cardRoundedShadow,
    borderRadius: '10px',

    '& > *:first-child': {
      padding: '10px 12px',
    },

    '& p': {
      margin: 0,
      padding: '10px 12px',
      ...(isKiteUser ? theme.typography.textSm : theme.typography.textMd),
      color: theme.palette.darkGrey,
    },

    '& [data-testid="collapse-button"]': {
      '& > rect:nth-child(2)': {
        fill: isKiteUser && theme.palette.primary1,
      },
      '& > rect:nth-child(3)': {
        fill: isKiteUser && theme.palette.primary1,
      },
    },
  }),
}));

const FAQCollapsibleCard = (props) => {
  const { item } = props;
  const { isKiteUser } = useUser();
  const classes = useStyles({ isKiteUser });
  const [cardOpen, setCardOpen] = useState(false);
  const { isMobileMode } = useUI();
  return (
    <Box
      data-testid="faq-card"
      display={'flex'}
      flexDirection={'column'}
      className={classes.card}>
      <Box display={'flex'} columnGap={isMobileMode && 1.25}>
        <Box flexGrow={1}>
          <Typography variant={isKiteUser ? 'textSmBold' : 'textMdSemibold'}>
            {item.question}
          </Typography>
        </Box>
        <Box height={'24px'}>
          <Feather
            data-testid="collapse-button"
            type={cardOpen ? 'containedRoundedMinus' : 'containedRoundedPlus'}
            onClick={() => setCardOpen(!cardOpen)}></Feather>
        </Box>
      </Box>{' '}
      <Collapse in={cardOpen}>
        <Box borderTop="1px solid #E6E6E6">
          <p>{item.answer}</p>
        </Box>
      </Collapse>
    </Box>
  );
};

export default FAQCollapsibleCard;
