// Lib
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const useOverLimitProduct = (id) => {
  const { productsOverLimit } = useSelector((state) => ({
    productsOverLimit: state?.cart?.productsOverLimit,
  }));

  const isOverLimitProduct = useMemo(
    () => productsOverLimit?.includes(id),
    [productsOverLimit, id],
  );

  const isSomeProductOverLimit = useMemo(
    () => !!productsOverLimit?.length,
    [productsOverLimit],
  );

  return { isOverLimitProduct, isSomeProductOverLimit };
};

export default useOverLimitProduct;
