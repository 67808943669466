// Lib
import { createReducer } from '@reduxjs/toolkit';

// Actions
import { SetHolidays } from '../actions/holidays';
import { UserLogOut } from '../actions/user';

export const initialState = null;

export default createReducer(initialState, {
  [SetHolidays]: (state, { payload }) => payload,
  [UserLogOut]: () => initialState,
});
