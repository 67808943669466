// Lib
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';

// Components
import ConfirmCard from '../../../components/Cards/ConfirmCard';

// Actions
import {
  ClearOrderDetails,
  GetOrderDetails,
  SetOrderDetails,
  UpdateRecurringOrder,
} from '../../../actions/orders';
import { OpenModal } from '../../../actions/modal';
import { ClearApiError } from '../../../actions/apiError';

// Utils
import isProductAvailable from '../../../utils/isProductAvailable';

// Selectors
import { productsObjSelector } from '../../../store/selectors';

const useStyles = makeStyles((theme) => ({
  modal: {
    width: 'min(70vw, 611px) !important',
    minHeight: 'initial',
    [theme.breakpoints?.down('md')]: {
      borderRadius: 10,
      width: '85vw !important',
    },
  },
}));

const useOrderDetails = (orderId, isRecurring = false) => {
  const { state: locationState } = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {
    currentOrder: orderDetails,
    currentOrderProducts: orderProductIds,
    isLoading,
    orders,
    products,
    recurringOrders,
    apiError,
  } = useSelector((state) => ({
    isLoading: state.isLoading,
    currentOrder: state.orders.currentOrder,
    currentOrderProducts: state.orders.currentOrderProducts,
    orders: state.orders.general,
    products: productsObjSelector(state),
    recurringOrders: state.orders.recurring,
    apiError: state.apiError?.['getOrderDetails'],
  }));
  const [listView, setListView] = useState(true);

  const canReorder = useMemo(
    () =>
      !!orderDetails?.order_items.find(({ product }) =>
        isProductAvailable(products[product]),
      ),
    [orderDetails, products],
  );

  useEffect(() => {
    const order = (isRecurring ? recurringOrders : orders)?.find(
      (order) => order.id === +orderId,
    );

    if (orderId && order && !locationState?.updateOrderDetails) {
      dispatch(
        SetOrderDetails({
          currentOrder: order,
          currentOrderProducts: order.order_items.map(({ product }) => product),
        }),
      );
    }
    if (orderId && (!order || !!locationState?.updateOrderDetails)) {
      dispatch(GetOrderDetails({ orderId, isRecurring }));
    }
    return () => {
      // recurring order need details persist to open materials "modal"
      // general order need to clean details to run request on token expiration
      if (!isRecurring) {
        dispatch(ClearOrderDetails());
      }
    };
  }, [dispatch, orderId, isRecurring, orders, recurringOrders, locationState]);

  const onRemoveAddress = useCallback(
    (addressId) => () => {
      const newOrder = { ...orderDetails };
      newOrder.shipment_method = newOrder.shipment_method.id;
      const updatedAddresses = [];
      orderDetails.addresses.forEach(({ address }) => {
        if (address.id !== addressId) {
          updatedAddresses.push(address);
        }
      });
      newOrder.addresses = updatedAddresses.map((address) => {
        if (address.original_id) {
          return { ...address, id: address.original_id };
        } else {
          return address;
        }
      });
      let schedule = { ...orderDetails.schedule };
      if (!schedule.days_week) {
        schedule.days_week = [];
      }
      newOrder.schedule = schedule;
      delete newOrder.user;

      dispatch(
        UpdateRecurringOrder({
          newOrder,
          setLoading,
          updateCurrentOrder: true,
          api: 'delete-recurring-address',
        }),
      );
    },
    [dispatch, orderDetails, setLoading],
  );

  const onDeleteAddress = useCallback(
    (addressId) => () => {
      dispatch(ClearApiError());
      dispatch(
        OpenModal({
          Component: () => (
            <ConfirmCard
              loadingBtn={loading}
              isAcceptBtnMain={false}
              title="Do You Want To Delete?"
              message="Deleting Will Remove This Address From The Order."
              onConfirm={onRemoveAddress(addressId)}
            />
          ),
          className: classes.modal,
        }),
      );
    },
    [dispatch, onRemoveAddress, loading, classes],
  );

  return {
    orderDetails,
    orderProductIds,
    isLoading,
    listView,
    setListView,
    onDeleteAddress: isRecurring ? onDeleteAddress : undefined,
    products,
    canReorder,
    apiError,
    onRemoveAddress,
  };
};

export default useOrderDetails;
