// Libs
import React from 'react';
import { put, takeEvery } from 'redux-saga/effects';

// Api
import http from '../../../api/http';

// Actions
import {
  GetApprovalOrder,
  SaveApprovals,
  SetApprovalOrder,
  SetApprovalProducts,
  SetApprovalShippingMethods,
} from '../../../actions/approvals';
import { SetLoading } from '../../../actions/loading';
import { Open2Modal } from '../../../actions/modal2';
import { ClearApiError, SetApiError } from '../../../actions/apiError';

// URLS
import { APPROVALS_URLS, CART_URLS, HOME_PAGE } from '../../../constants/urls';

// Browser history
import browserHistory from '../../../browserHistory';

// Components
import SavedInfoCard from '../../../components/Cards/SavedInfoCard';

// Constants
import { COMMON_API_ERROR } from '../../../constants/errors';

export function* getApprovalOrderSaga({ payload: id }) {
  try {
    const isMethodApprove =
      browserHistory?.location?.pathname.includes('shipping-method');
    yield put(SetLoading(true));
    const order = yield http.get(APPROVALS_URLS.getOrder(id));
    yield put(SetApprovalOrder(order?.data?.order));
    const modifiedProductsLimit = {
      ...order?.data?.approvals?.products_limits,
    };

    const country = Array.isArray(order?.data?.order?.addresses)
      ? order?.data?.order?.addresses[0].address_fields?.country
      : order?.data?.order?.addresses?.address_fields?.country;

    if (isMethodApprove) {
      const methods = yield http.post(CART_URLS.getShippingMethods, {
        schedule_date_type: 'Ship Right Now',
        country,
      });
      yield put(SetApprovalShippingMethods(methods.data));
    }

    Object.keys(order?.data?.approvals?.products_limits).map((key) => {
      const orderDetails = order?.data?.approvals?.products_limits[key];
      modifiedProductsLimit[key] = {
        ...modifiedProductsLimit[key],
        qty_approved: orderDetails?.qty_approved
          ? orderDetails?.qty_approved
          : orderDetails?.qty_ordered,
      };
    });

    yield put(
      SetApprovalProducts({
        ...order?.data?.approvals,
        products_limits: modifiedProductsLimit,
      }),
    );
  } catch (e) {
    if (e?.response?.status === 404) {
      browserHistory.push(HOME_PAGE);
    } else {
      yield put(
        SetApiError({
          api: 'getApprovalOrder',
          error: COMMON_API_ERROR,
        }),
      );
    }
  } finally {
    yield put(SetLoading(false));
  }
}

export function* saveApprovalsSaga({ payload: { setIsSaving, data, id } }) {
  try {
    yield put(ClearApiError());
    setIsSaving(true);
    yield http.post(APPROVALS_URLS.saveApprovals, data);
    yield put(
      Open2Modal({
        id: 'approvals-saved',
        Component: () => (
          <SavedInfoCard
            message="Your response has been successfully saved"
            btnTitle="Close"
          />
        ),
        closeIcon: true,
        styles: {
          minHeight: 'initial',
          width: 'auto',
        },
        props: {
          paddingContent: {
            xl: '40px',
            lg: '40px',
            md: '40px 16px 30px',
          },
        },
      }),
    );
    yield getApprovalOrderSaga({ payload: id });
  } catch (e) {
    yield put(
      SetApiError({
        api: 'saveApprovals',
        error: COMMON_API_ERROR,
      }),
    );
  } finally {
    setIsSaving(false);
  }
}

function* approvalsSaga() {
  yield takeEvery(GetApprovalOrder, getApprovalOrderSaga);
  yield takeEvery(SaveApprovals, saveApprovalsSaga);
}

export default approvalsSaga;
