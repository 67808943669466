// Libs
import {
  put,
  takeEvery,
  call,
  delay,
  all,
  take,
  select,
} from 'redux-saga/effects';

// Components
import OrderConfirmation from '../../../components/OrderHistory/Modals/OrderConfirmation/OrderConfirmation';

// Api
import http from '../../../api/http';

// Actions
import {
  GetMyKits,
  SetMyKits,
  AddToMyKits,
  DeleteKit,
  DeleteKitInDetailsMode,
  DeleteAllKits,
  GetKitDetails,
  SetKitDetails,
  EditKitName,
  SetKitActionErrorMessage,
  ReorderKit,
  SetReorderKitConfirmation,
  UpdateKitProductQty,
  SubmitNewKitProducts,
  RemoveProductFromKit,
  SetNewKitName,
  SetKitProductQty,
  SetKitAfterRemoveProduct,
  CleanNewKitProducts,
  CleanExistingKitProducts,
  SetSelectingKitProductsProcess,
} from '../../../actions/kits';
import { SetLoading } from '../../../actions/loading';
import { OpenModal, CloseModal } from '../../../actions/modal';
import { Close2Modal } from '../../../actions/modal2';
import { SetProducts } from '../../../actions/products';

//Sagas
import handleApiError from '../../../sagas/handleApiError';

// Constants
import { KITS_URLS } from '../../../constants/urls';
import {
  PRODUCT_OUT_OF_STACK,
  PRODUCTS_OUT_OF_STACK,
} from '../../../constants/common';
import {
  REORDER_ENTITY_WITHOUT_PRODUCT_MESSAGE,
  REORDER_ENTITY_WITHOUT_PRODUCTS_MESSAGE,
} from '../../../constants/common';

// Store
import { store } from '../../../store';
import { productsObjSelector, kitsSelector } from '../../../store/selectors';

// Utils
import isProductAvailable from '../../../utils/isProductAvailable';
import { ClearApiError } from '../../../actions/apiError';

// TODO: unify method of handling errors
function* handleKitActionError(e) {
  if (e.response) {
    const nonFieldErrors = e.response.data.non_field_errors;
    const nonFieldError = nonFieldErrors ? nonFieldErrors[0] : null;
    yield put(
      SetKitActionErrorMessage(
        nonFieldError || e.response.data.detail || e.message,
      ),
    );
  } else {
    yield put(SetKitActionErrorMessage(e.message));
  }
}

function* getMyKitsSaga() {
  try {
    yield put(SetLoading(true));
    const response = yield call(http.get, KITS_URLS.getMyKits);
    const productsObj = { ...store.getState().products.all.obj };

    const kits = response?.data.map((kit) => {
      const products = kit.products.map((item) => {
        productsObj[item.product.product_id] = item.product;
        return {
          product: item.product.product_id,
          nums: item.nums,
        };
      });

      return {
        ...kit,
        products,
      };
    });

    yield put(SetProducts({ obj: productsObj }));
    yield put(SetMyKits(kits));
  } catch (e) {
    yield call(handleApiError, e, 'getMyKits');
  } finally {
    yield put(SetLoading(false));
  }
}

function* addToMyKitsSaga({ payload }) {
  try {
    const { orderId, name, callback } = payload;

    if (!name) {
      throw new Error('This field is required');
    }

    yield call(http.post, KITS_URLS.addToMyKits(orderId), {
      name,
    });

    yield put(CloseModal());
    yield call(callback);
  } catch (e) {
    yield call(handleKitActionError, e);
  }
}

function* deleteKitSaga({ payload }) {
  try {
    yield put(ClearApiError());
    yield call(http.delete, KITS_URLS.deleteKit(payload));
    yield put(CloseModal());
    yield put(GetMyKits());
  } catch (e) {
    yield call(handleApiError, e, 'deleteKit');
  }
}

function* deleteKitInDetailsModeSaga({ payload }) {
  try {
    yield call(http.delete, KITS_URLS.deleteKit(payload));
    yield put(Close2Modal());
    yield put(CloseModal());
    yield put(GetMyKits());
  } catch (e) {
    console.log(e);
  }
}

function* deleteAllKitsSaga() {
  try {
    yield put(ClearApiError());
    yield call(http.delete, KITS_URLS.deleteAllKits);
    yield put(CloseModal());
    yield put(GetMyKits());
  } catch (e) {
    yield call(handleApiError, e, 'deleteAllKits');
  }
}

function* getKitDetailsSaga({ payload }) {
  try {
    const productsObj = { ...store.getState().products.all.obj };
    yield put(SetLoading(true));
    const response = yield call(http.get, KITS_URLS.kitDetails(payload));

    const products =
      response?.data.products.map((item) => {
        productsObj[item.product.product_id] = item.product;
        return {
          product: item.product.product_id,
          nums: item.nums,
        };
      }) || [];
    yield put(SetProducts({ obj: productsObj }));
    yield put(SetKitDetails({ ...response?.data, products }));
  } catch (e) {
    yield call(handleApiError, e, 'getKitDetails');
  } finally {
    yield put(SetLoading(false));
  }
}

function* editKitNameSaga({ payload }) {
  try {
    const { kitId, name } = payload;

    if (!name) {
      throw new Error('Name is required');
    }

    yield call(http.put, KITS_URLS.editKit(kitId), { name });
    yield put(CloseModal());
    yield put(SetNewKitName(name));
  } catch (e) {
    yield call(handleKitActionError, e);
  }
}

function* reorderKitSaga({ payload }) {
  try {
    const { kit, cancelReorder, confirmReorder, navigateToCart } = payload;
    const allProducts = yield select(productsObjSelector);

    const unavailableProducts = kit.products.filter((item) => {
      const product = allProducts[item.product];
      return product ? !isProductAvailable(product) : false;
    });

    if (unavailableProducts.length > 0) {
      const confirmation = yield call(
        waitForUserConfirmation,
        unavailableProducts.map((item) => allProducts[item.product]),
        cancelReorder,
        confirmReorder,
      );

      if (!confirmation) {
        yield put(CloseModal());
        return;
      }
    }

    yield call(http.post, KITS_URLS.addKitToCart(kit.id));
    yield delay(500);

    yield put(CloseModal());

    yield call(navigateToCart);
  } catch (e) {
    yield call(handleApiError, e, 'reorderKit');
  }
}

function* updateKitProductQtySaga({ payload }) {
  try {
    const { kitId, productId, newQty } = payload;
    yield put(SetKitProductQty({ productId, newQty }));
    yield call(http.put, KITS_URLS.updateKitProductQty(kitId, productId), {
      nums: newQty,
    });
  } catch (e) {
    yield call(handleApiError, e, 'updateKitProductQty');
  }
}

function* submitNewKitProductsSaga({ payload }) {
  const { kitId } = payload;
  try {
    const { newKitProducts, existingKitProducts } = yield select(kitsSelector);

    let newKitProductsRequests = [];
    let existingKitProductsRequests = [];

    newKitProducts.forEach((item) => {
      newKitProductsRequests.push(
        call(http.post, KITS_URLS.addNewKitProduct(kitId, item.productId), {
          nums: item.nums,
        }),
      );
    });

    existingKitProducts.forEach((item) => {
      existingKitProductsRequests.push(
        call(http.put, KITS_URLS.updateKitProductQty(kitId, item.productId), {
          nums: item.nums,
        }),
      );
    });

    yield all(newKitProductsRequests);
    yield all(existingKitProductsRequests);
    yield call(finishAddProductsProcess, payload);
  } catch (e) {
    yield call(handleApiError, e, 'submitNewKitProducts');
  }
}

function* finishAddProductsProcess(payload) {
  const { kitId, callback } = payload;
  yield put(SetSelectingKitProductsProcess(false));
  yield put(CleanNewKitProducts());
  yield put(CleanExistingKitProducts());
  yield put(GetKitDetails(kitId));
  yield call(callback);
}

function* removeProductFromKitSaga({ payload }) {
  try {
    const { kitId, productId } = payload;
    yield put(SetKitAfterRemoveProduct(payload));
    yield call(http.delete, KITS_URLS.removeProductFromKit(kitId, productId));
  } catch (e) {
    yield call(handleApiError, e, 'removeProductFromKit');
  }
}

export function* waitForUserConfirmation(
  unavailableProducts,
  cancelReorder,
  confirmReorder,
) {
  yield put(
    OpenModal({
      Component: OrderConfirmation,
      props: {
        unavailableProducts,
        header:
          unavailableProducts.length === 1
            ? PRODUCT_OUT_OF_STACK
            : PRODUCTS_OUT_OF_STACK,
        description:
          unavailableProducts.length === 1
            ? REORDER_ENTITY_WITHOUT_PRODUCT_MESSAGE()
            : REORDER_ENTITY_WITHOUT_PRODUCTS_MESSAGE(),
        cancelAction: cancelReorder,
        confirmAction: confirmReorder,
      },
      styles: {
        width: 'min-content',
      },
    }),
  );

  const { payload: confirmation } = yield take(SetReorderKitConfirmation);

  return confirmation;
}

function* kitsSaga() {
  yield takeEvery(GetMyKits, getMyKitsSaga);
  yield takeEvery(AddToMyKits, addToMyKitsSaga);
  yield takeEvery(DeleteKit, deleteKitSaga);
  yield takeEvery(DeleteKitInDetailsMode, deleteKitInDetailsModeSaga);
  yield takeEvery(DeleteAllKits, deleteAllKitsSaga);
  yield takeEvery(GetKitDetails, getKitDetailsSaga);
  yield takeEvery(EditKitName, editKitNameSaga);
  yield takeEvery(ReorderKit, reorderKitSaga);
  yield takeEvery(UpdateKitProductQty, updateKitProductQtySaga);
  yield takeEvery(SubmitNewKitProducts, submitNewKitProductsSaga);
  yield takeEvery(RemoveProductFromKit, removeProductFromKitSaga);
}

export default kitsSaga;
export {
  getMyKitsSaga,
  addToMyKitsSaga,
  deleteKitSaga,
  deleteKitInDetailsModeSaga,
  deleteAllKitsSaga,
  getKitDetailsSaga,
  editKitNameSaga,
  reorderKitSaga,
  updateKitProductQtySaga,
  submitNewKitProductsSaga,
  removeProductFromKitSaga,
  finishAddProductsProcess,
  handleKitActionError,
};
