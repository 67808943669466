// Lib
import { useCallback, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';

// Actions
import { UpdateRating } from '../actions/rating';

const useRating = (product) => {
  const dispatch = useDispatch();
  const [newRate, setNewRate] = useState();
  const {
    is_ordered: canRate,
    family_rate: familyRate,
    product_id,
    user_rate: myRate = 0,
  } = product || {};

  const setRate = useCallback(
    (rate) => {
      setNewRate(rate);
      dispatch(
        UpdateRating({
          id: product_id,
          rate,
          isAlreadyRated: !!myRate,
        }),
      );
    },
    [setNewRate, dispatch, product_id, myRate],
  );

  const rate = useMemo(() => newRate || myRate || 0, [newRate, myRate]);

  return {
    canRate,
    myRate: rate,
    setRate,
    familyRate,
  };
};

export default useRating;
