// Lib
import React from 'react';
import { Modal as MuiModal, Box } from '@mui/material';
import classnames from 'classnames';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import Fade from '@mui/material/Fade';

// Components
import CloseButton from '../Button/CloseButton';

const useStyles = makeStyles((theme) => ({
  root: ({ styles, isFullScreen }) => ({
    backgroundColor: 'white',
    borderRadius: 20,
    left: '50%',
    minHeight: 200,
    outline: 0,
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    ...(!isFullScreen && {
      height: 'initial',
      '& #content': {
        minHeight: 'initial',
      },
    }),
    ...styles,
    ...(isFullScreen && {
      borderRadius: 0,
      height: '100vh',
      height: 'calc(var(--vh, 1vh) * 100)',
      width: '100vw',

      '& #content': {
        minHeight: '100vh',
        minHeight: 'calc(var(--vh, 1vh) * 100)',
        minHeight: '-moz-available',
        minHeight: '-webkit-fill-available',
        minHeight: 'fill-available',
      },
    }),
  }),
  addressSavedCard: {
    borderRadius: '10px !important',
    minHeight: 'initial !important',
    textAlign: 'center',
    width: '280px !important',
    [theme.breakpoints?.down('md')]: {
      width: '230px !important',
      padding: 6,
      '& .MuiTypography-root': {
        fontSize: 14,
      },
    },
    [theme.breakpoints?.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        width: '230px !important',
        padding: 6,
        '& .MuiTypography-root': {
          fontSize: 14,
        },
      }),
    },
  },
  contentContainer: ({ isFullScreen }) => ({
    height: '100%',
    overflowY: 'auto',
    maxHeight: isFullScreen ? '100vh' : '90vh',
    maxHeight: isFullScreen
      ? 'calc(var(--vh, 1vh) * 100)'
      : 'calc(var(--vh, 1vh) * 90)',
    position: 'relative',
  }),
  content: ({ padding }) => ({
    display: 'flex',
    flexDirection: 'column',
    // stretch modal content on full height to be able to move buttons down
    height: '100%',
    padding: padding.xl,
    [theme.breakpoints.down('lg')]: {
      padding: ({ padding }) => padding.lg,
    },
    [theme.breakpoints.down('md')]: {
      padding: ({ padding }) => padding.md,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        padding: ({ padding }) => padding.lg,
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        padding: ({ padding }) => padding.md,
      }),
    },
  }),
  backdrop: {
    backgroundColor: theme.palette.blured,
  },
  titleContainer: {
    backgroundColor: 'white',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    position: 'sticky',
    top: 0,
    width: '100%',
    zIndex: 1,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    margin: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: 22,
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 22,
      }),
    },
  },
  closeButton: {
    right: ({ right }) => right.xl,
    top: ({ top }) => top.xl,
    [theme.breakpoints.down('lg')]: {
      right: ({ right }) => right.lg,
      top: ({ top }) => top.lg,
    },
    [theme.breakpoints.down('md')]: {
      right: ({ right }) => right.md,
      top: ({ top }) => top.md,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        right: ({ right }) => right.lg,
        top: ({ top }) => top.lg,
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        right: ({ right }) => right.md,
        top: ({ top }) => top.md,
      }),
    },
  },
}));

const Modal = ({
  children,
  open,
  onClose,
  onClosed,
  closeIcon,
  styles,
  className,
  modalProps,
  ...props
}) => {
  const theme = useTheme();
  const defaultPadding = {
    xl: `${!!modalProps?.title ? '16px' : '40px'} 40px 30px`,
    lg: `${!!modalProps?.title ? '16px' : '40px'} 28px 16px`,
    md: `${!!modalProps?.title ? '16px' : '40px'} 16px 20px`,
  };
  const defaultRight = {
    xl: 0,
    lg: 0,
    md: 0,
  };
  const defaultTop = {
    xl: theme.spacing(1),
    lg: theme.spacing(1),
    md: theme.spacing(1),
  };
  const classes = useStyles({
    styles,
    isFullScreen: modalProps?.isFullScreen,
    padding: modalProps?.paddingContent || defaultPadding,
    right: modalProps?.closeButtonRight || defaultRight,
    top: modalProps?.closeButtonTop || defaultTop,
  });

  //The trick to viewport units on mobile
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });

  return (
    <MuiModal
      onClick={(e) => e.stopPropagation()}
      open={open}
      onClose={onClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      BackdropProps={{
        classes: { root: classes.backdrop },
        timeout: modalProps?.transitionTimeout || { enter: 0, exit: 0 },
      }}
      closeAfterTransition
      {...props}>
      <Fade
        in={open}
        onExited={onClosed}
        timeout={modalProps?.transitionTimeout || { enter: 0, exit: 0 }}>
        <Box
          id="modal-root"
          className={classnames(
            classes.root,
            className,
            modalProps?.addressSavedCard && classes.addressSavedCard,
          )}>
          <div className={classes.contentContainer}>
            <div className={classes.content} id="content">
              {modalProps?.title || modalProps?.titleElement ? (
                <div className={classes.titleContainer} id="title-container">
                  {modalProps.titleElement || (
                    <h2 className={classes.title}>{modalProps?.title}</h2>
                  )}
                  {closeIcon && (
                    <CloseButton
                      className={classes.closeButton}
                      onClose={modalProps?.onClose || onClose}
                    />
                  )}
                </div>
              ) : (
                closeIcon && (
                  <CloseButton onClose={modalProps?.onClose || onClose} />
                )
              )}
              {children}
            </div>
          </div>
        </Box>
      </Fade>
    </MuiModal>
  );
};

export default Modal;
