// Lib
import React, { useCallback, useState } from 'react';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

// Component
import Spinner from '../Spinner';

const fixedContainer = (width, height) => ({
  minWidth: width,
  maxWidth: width,
  minHeight: height ? height : width,
  maxHeight: height ? height : width,
});

const fitImage = (width, height) => ({
  maxWidth: width,
  maxHeight: height ? height : width,
});

const unsetHeightAndWidth = () => ({
  height: 'unset',
  width: 'unset',
});

const useStyles = makeStyles((theme) => ({
  container: ({ imageContainer }) =>
    imageContainer && {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...fixedContainer(...imageContainer.desktop),
      [theme.breakpoints.down('lg')]: {
        ...fixedContainer(...imageContainer.tablet),
      },
      [theme.breakpoints.down('md')]: {
        ...fixedContainer(...imageContainer.mobile),
      },
      [theme.breakpoints.downLandscape('lg')]: {
        ...(theme.isMobileDevice && {
          ...fixedContainer(...imageContainer.tablet),
        }),
      },
      [theme.breakpoints.downLandscape('md')]: {
        ...(theme.isMobileDevice && {
          ...fixedContainer(...imageContainer.mobile),
        }),
      },
      '& img': {
        ...unsetHeightAndWidth(),
        ...fitImage(...imageContainer.desktop),
        [theme.breakpoints.down('lg')]: {
          ...fitImage(...imageContainer.tablet),
        },
        [theme.breakpoints.down('md')]: {
          ...fitImage(...imageContainer.mobile),
        },
        [theme.breakpoints.downLandscape('lg')]: {
          ...(theme.isMobileDevice && {
            ...fitImage(...imageContainer.tablet),
          }),
        },
        [theme.breakpoints.downLandscape('md')]: {
          ...(theme.isMobileDevice && {
            ...fitImage(...imageContainer.mobile),
          }),
        },
      },
    },
  text: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 14,
    fontWeight: 600,
    justifyContent: 'center',
    minHeight: '100%',
    minWidth: '100%',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
}));

const Image = ({ src, alt, imageContainer, children, classname, ...props }) => {
  const classes = useStyles({ imageContainer });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const onLoad = useCallback(() => {
    setLoading(false);
  }, [setLoading]);

  const onError = useCallback(() => {
    setError(true);
    setLoading(false);
  }, [setError, setLoading]);

  const imageLoader = (
    <div className={classnames(classes.container, classname)}>
      <img
        alt={alt}
        style={{ display: 'none' }}
        src={src}
        onLoad={onLoad}
        onError={onError}
      />
      <Spinner
        size={28}
        styles={{
          ...unsetHeightAndWidth(),
          minWidth: '100%',
          minHeight: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      />
    </div>
  );

  const imageErrorPlaceholder = (
    <div className={classnames(classes.container, classname)}>
      <div className={classes.text} data-testid="image-placeholder">
        Image Not Found
      </div>
    </div>
  );

  const image = children || <img src={src} alt={alt} {...props} />;

  const imageInContainer = <div className={classes.container}>{image}</div>;

  if (loading && !!src) {
    return imageLoader;
  } else if (error || !src) {
    return imageErrorPlaceholder;
  } else {
    return imageContainer ? imageInContainer : image;
  }
};

export default Image;
