// Lib
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, ClickAwayListener } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

// Components
import Input from '../../Field/Input';

// Hooks
import useUser from '../../../hooks/useUser';
import useCountButton from '../../../hooks/useCountButton';

const useStyles = makeStyles((theme) => ({
  button: ({ isKiteUser, error, active, disabled }) => {
    let color;
    if (disabled) {
      color = 'rgba(0, 0, 0, 0.38)';
    } else if (!active) {
      color = theme.palette.grey1;
    } else {
      color = isKiteUser ? theme.palette.primary1 : theme.palette.primary2;
    }

    if (error) {
      color = '#C5203F';
    }

    return {
      alignItems: 'center',
      border: `1px solid ${color}`,
      borderRadius: 25,
      color,
      padding: '7px 12px',
      pointerEvents: disabled ? 'none' : 'initial',
      width: 130,
      [theme.breakpoints.down('lg')]: {
        fontSize: 14,
        padding: '7px 5px',
        width: 96,
      },
      [theme.breakpoints.downLandscape('lg')]: {
        ...(theme.isMobileDevice && {
          fontSize: 14,
          padding: '7px 5px',
          width: 96,
        }),
      },
    };
  },
  icon: ({ active }) => ({
    cursor: !active ? 'initial' : 'pointer',
    fontSize: 20,
    '&:hover': {
      backgroundColor: !active ? 'none' : theme.palette.lightGrey,
    },
    [theme.breakpoints.down('lg')]: {
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        '&:hover': {
          backgroundColor: 'inherit',
        },
      }),
    },
  }),
  input: ({ isKiteUser, error, active }) => {
    let color;
    if (!active) {
      color = theme.palette.grey1;
    } else {
      color = isKiteUser ? theme.palette.primary1 : theme.palette.primary2;
    }
    if (error) {
      color = '#C5203F';
    }

    return {
      display: 'flex',
      '& .MuiOutlinedInput-root': {
        padding: '0 !important',
      },
      '& fieldset': {
        border: 'none',
      },
      '& input': {
        color,
        fontSize: 14,
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
          fontSize: 12,
        },
        [theme.breakpoints.downLandscape('md')]: {
          ...(theme.isMobileDevice && {
            fontSize: 12,
          }),
        },
      },
    };
  },
}));

const CountButton = ({
  count = 0,
  onAdd,
  onRemove,
  onSetCount,
  product,
  active = true, // when false, buttons become grey
  error = false, // when true, button become red
  disabled = false, // when true, you can't change value
  limitValue = true, // doesn't let to enter qty > than available
  className,
  onClickAway,
}) => {
  const { isKiteUser } = useUser();
  const classes = useStyles({
    isKiteUser,
    active,
    error,
    disabled,
  });

  const { onChange, onFocus, onBlur, value } = useCountButton(
    product,
    count,
    onSetCount,
    limitValue,
  );

  return (
    <ClickAwayListener
      onClickAway={(e) => {
        onClickAway && onClickAway();
        e.stopPropagation();
      }}>
      <Box
        id="count-button"
        className={classnames(classes.button, className)}
        display="flex"
        justifyContent="space-between"
        sx={{ position: 'relative' }}>
        <RemoveIcon
          id="count-minus-button"
          className={classes.icon}
          onClick={onRemove}
        />
        <Input
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChange}
          disabled={disabled}
          id="count-input"
          className={classes.input}
          value={value}
        />
        <AddIcon
          id="count-plus-button"
          className={classes.icon}
          onClick={onAdd}
        />
      </Box>
    </ClickAwayListener>
  );
};

export default CountButton;
