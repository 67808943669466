// Lib
import React, { forwardRef } from 'react';
import { makeStyles } from '@mui/styles';

// Components
import { ReactComponent as Filters } from '../../assets/svg/filter.svg';
import { ReactComponent as OpenedEye } from '../../assets/svg/openedEye.svg';
import { ReactComponent as ClosedEye } from '../../assets/svg/closedEye.svg';
import { ReactComponent as ArrowDown } from '../../assets/svg/arrowDown.svg';
import { ReactComponent as FilledFlag } from '../../assets/svg/filledFlag.svg';
import { ReactComponent as Flag } from '../../assets/svg/flag.svg';
import { ReactComponent as Close } from '../../assets/svg/close.svg';
import { ReactComponent as Clear } from '../../assets/svg/clear.svg';
import { ReactComponent as ArrowUpSmall } from '../../assets/svg/arrowSmallUp.svg';
import { ReactComponent as ArrowDownSmall } from '../../assets/svg/arrowSmallDown.svg';
import { ReactComponent as User } from '../../assets/svg/user.svg';
import { ReactComponent as Delete } from '../../assets/svg/delete.svg';
import { ReactComponent as BackArrow } from '../../assets/svg/backArrow.svg';
import { ReactComponent as Success } from '../../assets/svg/success.svg';
import { ReactComponent as Edit } from '../../assets/svg/edit.svg';
import { ReactComponent as Plus } from '../../assets/svg/plus.svg';
import { ReactComponent as Error } from '../../assets/svg/error.svg';
import { ReactComponent as Download } from '../../assets/svg/download.svg';
import { ReactComponent as ArrowRight } from '../../assets/svg/arrowRight.svg';
import { ReactComponent as File } from '../../assets/svg/file.svg';
import { ReactComponent as ArrowSlideLeft } from '../../assets/svg/arrowSlideLeft.svg';
import { ReactComponent as ArrowSlideRight } from '../../assets/svg/arrowSlideRight.svg';
import { ReactComponent as BookGilead } from '../../assets/svg/bookGilead.svg';
import { ReactComponent as BookKite } from '../../assets/svg/bookKite.svg';
import { ReactComponent as EmptyBook } from '../../assets/svg/book.svg';
import { ReactComponent as Warning } from '../../assets/svg/warning.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { ReactComponent as ContainedRoundedMinus } from '../../assets/svg/containedRoundedMinus.svg';
import { ReactComponent as ContainedRoundedPlus } from '../../assets/svg/containedRoundedPlus.svg';

// Hooks
import useUser from '../../hooks/useUser';

const icons = (isKiteUser) => ({
  filters: Filters,
  openedEye: OpenedEye,
  closedEye: ClosedEye,
  arrowDown: ArrowDown,
  flag: Flag,
  filledFlag: FilledFlag,
  close: Close,
  clear: Clear,
  arrowDownSmall: ArrowDownSmall,
  arrowUpSmall: ArrowUpSmall,
  user: User,
  delete: Delete,
  backArrow: BackArrow,
  success: Success,
  edit: Edit,
  plus: Plus,
  error: Error,
  download: Download,
  arrowRight: ArrowRight,
  file: File,
  arrowSlideLeft: ArrowSlideLeft,
  arrowSlideRight: ArrowSlideRight,
  collapseOpened: ExpandMoreIcon,
  collapseClosed: ExpandLessIcon,
  book: isKiteUser ? BookKite : BookGilead,
  emptyBook: EmptyBook,
  warning: Warning,
  containedRoundedMinus: ContainedRoundedMinus,
  containedRoundedPlus: ContainedRoundedPlus,
});

const useStyles = makeStyles((theme) => ({
  icon: ({ printType, isKiteUser, nativeColor, colorType, strokeWidth }) => {
    let color;

    if (colorType) {
      color = theme.palette[colorType];
    }
    if (!nativeColor && !colorType) {
      color = isKiteUser ? theme.palette.primary1 : theme.palette.primary2;
    }

    return {
      cursor: 'pointer',
      [printType]: color,
      '& path': {
        [printType]: color,
        strokeWidth,
      },
    };
  },
}));

const Feather = forwardRef(
  (
    {
      type = 'filters',
      printType = 'fill',
      nativeColor = false,
      colorType = '',
      strokeWidth,
      ...props
    },
    ref,
  ) => {
    const { isKiteUser } = useUser();

    const classes = useStyles({
      printType,
      isKiteUser,
      nativeColor,
      colorType,
      strokeWidth,
    });

    const Component = icons(isKiteUser)[type];

    return <Component ref={ref} className={classes.icon} {...props} />;
  },
);

export default Feather;
