// Libs
import { put } from 'redux-saga/effects';

// Actions
import { SetApiError } from '../actions/apiError';

// Constants
import { COMMON_API_ERROR } from '../constants/errors';

function* handleApiError(e, apiMessage) {
  yield put(
    SetApiError({
      api: apiMessage,
      error: COMMON_API_ERROR,
    }),
  );
  console.log(e);
}

export default handleApiError;
