const productsObjSelector = (state) => state.products?.all?.obj;

const forOrderSelector = (state) => state.cart.forOrder || [];

const forOrderCachedSelector = (state) => state.cart.forOrderCached || [];

const kitsSelector = (state) => state.kits;

export {
  productsObjSelector,
  kitsSelector,
  forOrderSelector,
  forOrderCachedSelector,
};
