// Lib
import React, { Fragment } from 'react';
import Typography from '@mui/material/Typography';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { makeStyles } from '@mui/styles';

// Components
import ScheduleDetails from './ScheduleDetails';
import ShippingMethods from './ShippingMethods';
import Event from './Event';
import OrderProcessingInfo from '../OrderProcessingInfo';

// Hooks
import useDetailsForm from '../../../../pages/Cart/hooks/useDetailsForm';
import useUser from '../../../../hooks/useUser';
import useUI from '../../../../hooks/useUI';

// Constants
import { SHIPPING_SCHEDULES } from '../../../../constants/cart';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(1.5),
    letterSpacing: '-0.4px',
    [theme.breakpoints.down('lg')]: {
      fontSize: 18,
      marginBottom: theme.spacing(0.5),
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 16,
      marginBottom: theme.spacing(1),
    },
  },
  radioGroup: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1.5),
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        marginBottom: theme.spacing(3),
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        marginBottom: theme.spacing(1.5),
      }),
    },
  },
  radioLabel: {
    marginRight: 'auto',
    '& .MuiTypography-root': {
      fontSize: 18,
      [theme.breakpoints.down('md')]: {
        fontSize: 16,
      },
      [theme.breakpoints.downLandscape('md')]: {
        ...(theme.isMobileDevice && {
          fontSize: 16,
        }),
      },
    },
  },
  radioItem: {
    color: '#C6CAC6',
    padding: 8,
    '&$checked': { color: theme.palette.primary1 },
    [theme.breakpoints.down('md')]: {
      padding: 5,
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        padding: 5,
      }),
    },
  },
  notice: {
    marginBottom: theme.spacing(4),
    color: theme.palette.red,
  },
}));

const DetailsForm = ({ setMonthError }) => {
  const { isKiteUser } = useUser();
  const { isDesktopMode } = useUI();
  const classes = useStyles({ isKiteUser });
  const {
    onChangeDetails,
    shippingDetails,
    openRequestCard,
    loadingMethods,
    loadingNotice,
    onChangeEvent,
    onChangeFile,
    incorrectFormatMessage,
    inputFileRef,
    onRemoveFile,
    apiError,
  } = useDetailsForm();

  const { schedule, mustArriveOnDateDisclaimer } = shippingDetails;
  const notice = shippingDetails.notice?.trim();

  SHIPPING_SCHEDULES[2].disclaimer = mustArriveOnDateDisclaimer;

  return (
    <form>
      {!loadingNotice && notice && (
        <Typography className={classes.notice} variant="body1">
          {notice}
        </Typography>
      )}
      <Typography className={classes.title} variant="h2">
        Shipping Schedule
      </Typography>
      {isDesktopMode && <OrderProcessingInfo />}
      <RadioGroup
        className={classes.radioGroup}
        value={schedule}
        row={false}
        aria-labelledby="address-format-radio-buttons-group-label"
        name="shipping-schedule-radio-buttons-group">
        {SHIPPING_SCHEDULES.map(({ label, value, disclaimer }) => (
          <Fragment key={label}>
            <FormControlLabel
              className={classes.radioLabel}
              value={value}
              control={
                <Radio
                  classes={{
                    root: classes.radioItem,
                  }}
                  onChange={onChangeDetails('schedule')}
                />
              }
              label={label}
            />
            <ScheduleDetails
              disclaimer={disclaimer}
              setMonthError={setMonthError}
              value={value}
            />
          </Fragment>
        ))}
      </RadioGroup>
      <Typography className={classes.title} variant="h2">
        Shipping Method
      </Typography>
      <ShippingMethods
        apiError={apiError}
        shippingDetails={shippingDetails}
        loadingMethods={loadingMethods}
        openRequestCard={openRequestCard}
        onChangeDetails={onChangeDetails}
      />
      <Event
        shippingDetails={shippingDetails}
        onChangeEvent={onChangeEvent}
        onChangeFile={onChangeFile}
        incorrectFormatMessage={incorrectFormatMessage}
        inputFileRef={inputFileRef}
        onRemoveFile={onRemoveFile}
      />
    </form>
  );
};

export default DetailsForm;
