// Lib
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';

// Components
import Button from '../index';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.lightGrey,
    boxShadow: 'none',
    borderRadius: 10,
    color: `${theme.palette.darkGrey} !important`,
    fontSize: 14,
    fontWeight: '400 !important',
    maxHeight: 24,
    padding: 8,
    '&:hover': {
      backgroundColor: theme.palette.lightGrey,
    },
  },
  closeIcon: {
    marginLeft: 8,
    strokeWidth: 1,
    stroke: theme.palette.darkGrey,
    color: theme.palette.darkGrey,
    fontSize: 10,
  },
}));

const RemoveFilter = ({ title, onClick }) => {
  const classes = useStyles();

  return (
    <Button onClick={onClick} className={classes.button} variant="text">
      {title}
      <CloseIcon className={classes.closeIcon} />
    </Button>
  );
};

export default RemoveFilter;
