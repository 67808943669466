// Lib
import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

// Components
import Layout from '../../../components/Layout';
import Spinner from '../../../components/Spinner';
import Feather from '../../../components/Feather';

// Hooks
import useSkipFromEmail from '../hooks/useSkipFromEmail';

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    height: 'calc(100vh - 250px)',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0 auto',
    width: 600,
    [theme.breakpoints.down('lg')]: {
      width: 600,
    },
    [theme.breakpoints.down('md')]: {
      width: 230,
    },
  },
  icon: ({ isSuccessResponse }) => ({
    alignItems: 'center',
    background: '#F4F4F4',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    height: 120,
    width: 120,
    '& svg': {
      cursor: 'initial',
      ...(!isSuccessResponse && {
        height: 70,
        width: 70,
      }),
    },
  }),
  infoMessage: {
    fontSize: '18px',
    letterSpacing: '0.2px',
    lineHeight: '22px',
    paddingTop: theme.spacing(0.75),
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
      letterSpacing: '0.1px',
      lineHeight: '16px',
    },
  },
}));

const SkipFromEmail = () => {
  const { loading, message, nextShipping, responseType } = useSkipFromEmail();
  const classes = useStyles({ isSuccessResponse: responseType === 'success' });

  return (
    <Layout hideHeaderLinks={true}>
      <div className={classes.container}>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div className={classes.icon}>
              <Feather nativeColor printType="stroke" type={responseType} />
            </div>
            <Typography textAlign="center" mt={3} variant="h2">
              {message}
            </Typography>
            {responseType === 'success' && (
              <span
                className={classes.infoMessage}
                style={{ textAlign: 'center' }}>
                {`Recurring order is now updated. Next shipping: ${nextShipping}.`}
              </span>
            )}
          </>
        )}
      </div>
    </Layout>
  );
};

export default SkipFromEmail;
