// Lib
import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { format } from 'date-fns';
import { makeStyles } from '@mui/styles';

// Components
import ApprovalShipmentMethodLabel from '../../../Labels/ApprovalShipmentMethodLabel';

// Hooks
import useUI from '../../../../hooks/useUI';
import useUser from '../../../../hooks/useUser';

const useStyles = makeStyles((theme) => ({
  orderInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: ({ isKiteUser }) => (isKiteUser ? 'Helvetica' : 'Proxima Nova'),
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    fontStyle: 'normal',
    boxShadow: '0px 5px 15px rgba(44, 112, 172, 0.2)',
    borderRadius: '10px',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(1.5),
  },
  orderInfoHeader: {
    fontWeight: ({ isKiteUser }) => (isKiteUser ? 700 : 600),
    fontSize: '14px',
    marginBottom: theme.spacing(0.5),
  },
  orderInfoText: {
    fontSize: '14px',
  },
  shippingMethod: {
    width: '220px',
    [theme.breakpoints.down('lg')]: {
      width: '224px',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        width: '224px',
      }),
    },
  },
}));

const InfoCard = ({ orderDetails }) => {
  const { isMobileMode } = useUI();
  const { isKiteUser, userWithoutAllLimits } = useUser();
  const classes = useStyles({ isKiteUser });

  return (
    <Box className={isMobileMode ? {} : classes.orderInfo}>
      <Box
        display={'flex'}
        flexDirection={isMobileMode ? 'row' : 'column'}
        gap={isMobileMode ? '0.5rem' : 0}>
        <Typography variant="h3" mb={0.5}>
          Order ID{isMobileMode && ':'}
        </Typography>
        <Typography variant="h3">{orderDetails.order_sn}</Typography>
      </Box>
      <Box
        display={'flex'}
        flexDirection={isMobileMode ? 'row' : 'column'}
        gap={isMobileMode ? '0.5rem' : 0}>
        <Typography className={classes.orderInfoHeader}>
          Order Date{isMobileMode && ':'}
        </Typography>
        {orderDetails.created && (
          <Typography className={classes.orderInfoText}>
            {format(new Date(orderDetails.created), 'MM/dd/yyyy')}
          </Typography>
        )}
      </Box>
      <Box
        display={'flex'}
        flexDirection={isMobileMode ? 'row' : 'column'}
        gap={isMobileMode ? '0.5rem' : 0}>
        <Typography className={classes.orderInfoHeader}>
          Ship Date{isMobileMode && ':'}
        </Typography>
        <Typography className={classes.orderInfoText}>
          {orderDetails.ship_date
            ? format(new Date(orderDetails.ship_date), 'MM/dd/yyyy')
            : '-'}
        </Typography>
      </Box>
      <Box
        className={classes.shippingMethod}
        display={'flex'}
        flexDirection={'column'}>
        <Typography className={classes.orderInfoHeader}>
          Shipping Method{isMobileMode && ':'}
        </Typography>
        <Typography className={classes.orderInfoText}>
          {orderDetails.shipment_method?.name || '-'}
        </Typography>
        {/* escalation shows is approve needed or not */}
        {orderDetails.shipment_method.escalation && !userWithoutAllLimits && (
          <ApprovalShipmentMethodLabel
            approval={orderDetails.approval}
            requestedMethod={orderDetails.requested_shipment_method_name}
            approvedMethod={orderDetails.shipment_method.name}
            data-testid="approval-shipment-method-label"
          />
        )}
      </Box>
    </Box>
  );
};

export default InfoCard;
