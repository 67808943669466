// Lib
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

// Api
import http from '../../../api/http';

// Hooks
import useRequest from './useRequest';

// Utils
import { isObjEmpty } from '../../../utils/object';

// Actions
import {
  ClearCart,
  PlaceOrder,
  SetShippingDetails,
} from '../../../actions/cart';
import {
  SHIPPING_SCHEDULES,
  SHIPPING_SCHEDULE_WITH_DATES,
  DAY_NUMBERS,
} from '../../../constants/cart';
import { ClearApiError } from '../../../actions/apiError';

// Constants
import { CART_URLS } from '../../../constants/urls';

const useReviewOrder = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [disabledCountBtn, setDisabledCountButton] = useState(false);
  const [isAvailableMethod, setIsAvailableMethod] = useState(true);
  // const [loading, setLoading] = useState(false);
  const { openRequestCard, isRequestRequired } = useRequest(null, true);
  const {
    shippingDetails,
    shippingAddresses,
    cart,
    apiError,
    productsObj,
    // use global loading to rely on it resolving redirection from another place
    loading,
  } = useSelector((state) => ({
    shippingDetails: state.cart.shippingDetails,
    shippingAddresses: state.cart.shippingAddresses,
    cart: state.cart,
    apiError: state?.apiError?.placeOrder,
    productsObj: state?.products?.all?.obj,
    loading: state.isLoading,
  }));

  useEffect(() => {
    // "&& !cart?.placedOrder?.id" is to stay on the same page
    // after placing order and clearing forOrder

    if (!shippingDetails.method?.id && !cart?.placedOrder?.id) {
      navigate('/cart/shipping-details');
    }

    return () => {
      if (apiError) {
        dispatch(ClearApiError());
      }
      if (cart?.placedOrder?.id) {
        dispatch(ClearCart());
      }
    };
  }, [
    dispatch,
    shippingDetails.method,
    navigate,
    cart?.placedOrder?.id,
    apiError,
  ]);

  // move navigate to external hook to exclude cleanup between rerender
  // and wrong clear cart behavior. Which is triggered by adding in cart
  // placed order product from details modal
  useEffect(() => {
    // loading is to prevent redirection after placing order before set response
    if (!cart?.forOrder?.length && !loading && !cart?.placedOrder?.id) {
      navigate('/cart');
    }
  }, [cart.forOrder, cart?.placedOrder?.id, loading, navigate]);

  const goToSippingDetails = useCallback(
    (pathname = '') => {
      window.scroll(0, 0);
      navigate('/cart/shipping-details', {
        state: {
          navigateFrom: pathname,
        },
      });
    },
    [navigate],
  );

  const getShippingMethods = useCallback(async () => {
    const scheduleWithDate = SHIPPING_SCHEDULE_WITH_DATES.includes(
      shippingDetails.schedule,
    );
    const schedule_date = scheduleWithDate ? shippingDetails?.date : null;
    const data = {
      schedule_date_type: shippingDetails.schedule,
      schedule_date: format(new Date(schedule_date), 'yyyy-MM-dd'),
      country: shippingAddresses?.[0]?.country,
    };
    try {
      return (await http.post(CART_URLS.getShippingMethods, data))?.data?.map(
        (item) => item.name,
      );
    } catch (e) {
      console.log(e);
    }
  }, [shippingAddresses, shippingDetails?.date, shippingDetails.schedule]);

  const checkShippingMethodAvailability = useCallback(async () => {
    const methods = await getShippingMethods();
    setIsAvailableMethod(methods?.includes(shippingDetails?.method?.name));
    return methods?.includes(shippingDetails?.method?.name);
  }, [getShippingMethods, shippingDetails]);

  // Check if selected shipping method is available.
  useEffect(() => {
    checkShippingMethodAvailability();
  }, [checkShippingMethodAvailability]);

  const placeOrder = useCallback(async () => {
    const isShippingMethodAvailable = await checkShippingMethodAvailability();
    if (!isShippingMethodAvailable) {
      return;
    }
    if (apiError) {
      dispatch(ClearApiError());
    }
    if (
      cart.productsOverLimit.length &&
      !cart.overLimitRequest &&
      isRequestRequired
    ) {
      return openRequestCard(null, true);
    }
    if (!cart.placedOrder.id) {
      let isRecurring = false;
      const commonData = {
        shipping_comments: cart.shippingDetails.comment,
        order_items: cart.forOrder?.map(({ product, nums }) => ({
          product: product,
          qty_ordered: nums,
        })),
        shipment_method: shippingDetails.method.id,
        additional_info_shipment: shippingDetails.method.escalation
          ? shippingDetails.shippingRequest
          : '',
        additional_info_limit: !!cart.productsOverLimit.length
          ? cart.overLimitRequest
          : '',
        addresses: shippingAddresses.map((address) => {
          const modifiedAddress = { ...address };
          delete modifiedAddress.full_address;
          return modifiedAddress;
        }),
        event_name: shippingDetails.event.name,
        event_booth: shippingDetails.event.boothNumber,
      };
      let data;
      if (cart.shippingDetails.schedule === SHIPPING_SCHEDULES[4].value) {
        isRecurring = true;
        const days = [];
        cart.shippingDetails.weekly.days.forEach(({ title, checked }) => {
          if (checked) {
            days.push(title);
          }
        });
        data = {
          ...commonData,
          schedule: {
            type: 'WEEKLY',
            frequency: cart.shippingDetails.weekly.monthNumber,
            days_week: days,
            skip_next_run: false,
          },
        };
      } else if (
        cart.shippingDetails.schedule === SHIPPING_SCHEDULES[5].value
      ) {
        isRecurring = true;
        const isWeekdayChecked = cart.shippingDetails.monthly.weekDay.checked;
        data = {
          ...commonData,
          schedule: {
            type: 'MONTHLY',
            frequency: isWeekdayChecked
              ? cart.shippingDetails.monthly.weekDay.monthNumber
              : cart.shippingDetails.monthly.monthDay.monthNumber,
            day_month: !isWeekdayChecked
              ? cart.shippingDetails.monthly.monthDay?.dayNumber
              : null,

            days_week: isWeekdayChecked
              ? [cart.shippingDetails.monthly.weekDay?.weekDay]
              : [],
            day_number_in_month: isWeekdayChecked
              ? DAY_NUMBERS.find(
                  ({ title }) =>
                    title === cart.shippingDetails.monthly.weekDay?.dayNumber,
                )?.digit
              : null,
            skip_next_run: false,
          },
        };
      } else {
        data = {
          ...commonData,
          schedule_date_type: cart.shippingDetails.schedule,
          schedule_date: SHIPPING_SCHEDULE_WITH_DATES.includes(
            shippingDetails.schedule,
          )
            ? format(new Date(shippingDetails.date), 'yyyy-MM-dd')
            : null,
        };
      }
      dispatch(
        PlaceOrder({
          data,
          file: shippingDetails?.event?.file?.data,
          isRecurring,
          setDisabledCountButton,
          // setLoading,
        }),
      );
    } else {
      navigate('/materials');
    }
  }, [
    // setLoading,
    dispatch,
    cart,
    shippingDetails,
    shippingAddresses,
    navigate,
    apiError,
    openRequestCard,
    isRequestRequired,
    checkShippingMethodAvailability,
  ]);

  useEffect(() => {
    if (
      typeof apiError === 'object' &&
      apiError?.shipment_method?.[0] ===
        `The shipment method (${shippingDetails?.method?.name}) is disabled`
    ) {
      setIsAvailableMethod(false);
      dispatch(ClearApiError());
    }
  }, [apiError, shippingDetails, dispatch]);

  const onChangeInstructions = useCallback(
    (title) => () => {
      dispatch(
        SetShippingDetails({
          type: 'comment',
          value: title === 'None' ? '' : title,
        }),
      );
    },
    [dispatch],
  );

  const moreThanInStock = useMemo(() => {
    if (productsObj && !isObjEmpty(productsObj) && cart.forOrder) {
      return !!cart.forOrder?.find(
        ({ nums, product }) =>
          nums * shippingAddresses.length >
          productsObj[product]?.product_available_qty,
      );
    } else {
      return false;
    }
  }, [cart?.forOrder, shippingAddresses.length, productsObj]);

  return {
    cart: { ...cart, productsObj },
    shippingDetails,
    loading,
    placedOrder: cart.placedOrder,
    moreThanInStock,
    withOverLimitRequest: !!cart.productsOverLimit.length,
    onChangeInstructions,
    goToSippingDetails,
    placeOrder,
    apiError,
    disabledCountBtn,
    isAvailableMethod,
  };
};

export default useReviewOrder;
