// Lib
import { useLocation } from 'react-router-dom';

const useQueryParams = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  if (!search.length) return null;

  const queryParams = {};
  for (let [key, value] of query.entries()) {
    queryParams[key] = value;
  }
  return queryParams;
};

export default useQueryParams;
