// Libs
import { createReducer } from '@reduxjs/toolkit';

// Actions
import {
  SetApprovalOrder,
  SetApprovalOrderId,
  SetApprovalProducts,
  EditRequestedQty,
  EditShippingMethod,
  AddAction,
  SetOrderQtyError,
  SetApproveResponse,
  SetApprovalShippingMethods,
} from '../actions/approvals';
import { UserLogOut } from '../actions/user';

export const initialState = {
  orderId: null,
  order: null,
  approvals: null,
  orderQtyError: '',
  approveResponse: '',
  shippingMethods: [],
};

export default createReducer(initialState, {
  [SetApprovalOrderId]: (state, { payload }) => {
    return {
      ...state,
      orderId: payload,
    };
  },
  [SetApprovalOrder]: (state, { payload }) => {
    return {
      ...state,
      order: payload,
    };
  },
  [EditShippingMethod]: (state, { payload }) => {
    return {
      ...state,
      approvals: {
        ...state.approvals,
        shipment_method: {
          ...state?.approvals?.shipment_method,
          id_approved: payload.id,
        },
      },
      order: {
        ...state.order,
        shipment_method: payload.name,
      },
    };
  },
  [SetApprovalShippingMethods]: (state, { payload }) => {
    return {
      ...state,
      shippingMethods: payload,
    };
  },
  [SetApprovalProducts]: (state, { payload }) => {
    return {
      ...state,
      approvals: payload,
    };
  },
  [SetOrderQtyError]: (state, { payload }) => {
    return {
      ...state,
      orderQtyError: payload,
    };
  },
  [SetApproveResponse]: (state, { payload }) => {
    return {
      ...state,
      approveResponse: payload,
    };
  },
  [EditRequestedQty]: (state, { payload: { id, value } }) => {
    return {
      ...state,
      approvals: {
        ...state.approvals,
        products_limits: {
          ...state?.approvals?.products_limits,
          [id]: {
            ...state?.approvals?.products_limits?.[id],
            qty_approved: value,
          },
        },
      },
    };
  },
  [AddAction]: (state, { payload: { id, action } }) => {
    return {
      ...state,
      approvals: {
        ...state.approvals,
        products_limits: {
          ...state.approvals.products_limits,
          [id]: {
            ...state.approvals.products_limits[id],
            action,
          },
        },
      },
    };
  },
  [UserLogOut]: () => initialState,
});
