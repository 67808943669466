// Lib
import React, { useMemo, useEffect } from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

// Components
import Routes from '../routes/Routes';
import Modal from '../components/Modal';
import Snackbar from './Snackbar';

// Theme
import createTheme from '../styles/theme';

// hooks
import useUser from '../hooks/useUser';
import useModal from '../hooks/useModal';
import use2Modal from '../hooks/use2Modal';
import useAuth from '../pages/Auth/hooks/useAuth';
import useApiError from '../hooks/useApiError';

// Actions
import { GetCartProducts } from '../actions/cart';
import { ClearSearchValue } from '../actions/search';
import { SetPrevPath } from '../actions/location';
import { ClearPrevPath } from '../actions/location';

// URLS
import { BASE_URL } from '../constants/urls';
import { GetHolidays } from '../actions/holidays';

// Socket client
import { openSocket } from '../api/socket';

const App = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const {
    open,
    Component,
    onClose,
    props,
    closeIcon,
    children,
    styles,
    className,
  } = useModal();
  const { ssoAuth } = useAuth();
  const { apiError, clearApiError } = useApiError();
  const {
    open: open2,
    Component: Component2,
    styles: styles2,
    onClose: onClose2,
    onClosed: onClosed2,
    closeIcon: closeIcon2,
    props: props2,
  } = use2Modal();
  const { userType, isLoggedIn } = useUser();
  const { holidays, prevPath } = useSelector((state) => ({
    holidays: state.holidays,
    prevPath: state?.location?.prevPath,
  }));

  const theme = useMemo(() => createTheme(userType), [userType]);

  useEffect(() => {
    if (pathname === prevPath && isLoggedIn) {
      dispatch(ClearPrevPath());
    }
    dispatch(ClearSearchValue());
  }, [dispatch, pathname, prevPath, isLoggedIn]);

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }
    const client = openSocket();

    return () => {
      if (client.readyState === 1) {
        client.closeWithoutReconnect = true;
        client.close();
      }
    };
  }, [isLoggedIn]);

  useEffect(() => {
    if (
      isLoggedIn &&
      !(
        pathname.includes('order-history/recurring') &&
        (pathname.includes('/materials') || pathname.includes('/address-book'))
      )
    ) {
      dispatch(GetCartProducts(pathname));
    }
  }, [isLoggedIn, dispatch, pathname]);

  useEffect(() => {
    if (isLoggedIn && holidays === null) {
      dispatch(GetHolidays());
    }
  }, [dispatch, holidays, isLoggedIn]);

  const isLoginPage = useMemo(
    () =>
      [
        '/auth',
        '/auth/',
        '/auth/sso-response',
        '/auth/sso-response/',
        '/auth/sso/logout',
        '/auth/sso/logout/',
        '/fe-version',
      ].includes(pathname),
    [pathname],
  );

  useEffect(() => {
    if (pathname.includes('/approvals')) {
      return;
    }
    if (
      !isLoggedIn &&
      !isLoginPage &&
      !['/auth/sso/signin', '/auth/sso/signin/', '/'].includes(pathname)
    ) {
      dispatch(SetPrevPath(pathname));
    }
    if (!isLoggedIn && !isLoginPage && ssoAuth === null) {
      window.location.href = `${BASE_URL}/auth/sso/signin`;
    }
  }, [isLoggedIn, isLoginPage, ssoAuth, pathname, dispatch]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes isLoggedIn={isLoggedIn} userType={userType} />
        <Modal
          className={className}
          styles={styles}
          closeIcon={closeIcon}
          open={open}
          onClose={onClose}
          modalProps={props}>
          {Component && <Component {...props} />}
          {children && children}
        </Modal>
        <Modal
          closeIcon={closeIcon2}
          onClose={onClose2}
          onClosed={onClosed2}
          open={open2}
          styles={styles2}
          modalProps={props2}>
          {Component2 && <Component2 {...props2} />}
        </Modal>
        <Snackbar message={apiError} onCloseCallback={clearApiError} />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
