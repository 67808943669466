export const fullAddressString = (address) => {
  if (!address) {
    return;
  }

  const { address1, address2, city, country, state, zip } = address;

  let addressString = address1;
  if (address2) {
    addressString = `${addressString}, ${address2}`;
  }
  addressString = `${addressString}, ${city}`;

  if (state) {
    addressString = `${addressString}, ${state}`;
  }
  addressString = `${addressString}, ${country}, ${zip}`;

  return addressString;
};

export const getFirstName = (address) => {
  const firstName =
    address?.address_fields?.first_name ||
    address?.firstName ||
    address?.first_name ||
    address?.address?.first_name;

  return firstName;
};

export const getLastName = (address) => {
  const lastName =
    address?.address_fields?.last_name ||
    address?.lastName ||
    address?.last_name ||
    address?.address?.last_name;

  return lastName;
};

export const getCompany = (address) => {
  const company =
    address?.address_fields?.company ||
    address?.company ||
    address?.address?.company;

  if (company) {
    return company;
  }
};

export const getAddressType = (address) => {
  const addressType =
    address?.address_fields?.address_type ||
    address?.address_type ||
    address?.address?.address_type;

  if (addressType) {
    return addressType;
  }
};

export const addressCardTitle = (address) => {
  const firstName = getFirstName(address);
  const lastName = getLastName(address);
  const company = getCompany(address);

  let title = '';
  if (firstName) {
    title += firstName;
  }
  if (lastName) {
    title = `${title} ${lastName}`;
  }
  if (!firstName && !lastName) {
    title = 'No name';
  }
  if (company) {
    title = `${title}, "${company}"`;
  }

  return title;
};

export const getFullName = (address) => {
  const firstName = getFirstName(address);
  const lastName = getLastName(address);

  let title = '';
  if (firstName) {
    title += firstName;
  }
  if (lastName) {
    title = `${title} ${lastName}`;
  }
  if (!firstName && !lastName) {
    title = 'No name';
  }

  return title;
};

export const formatAddress = (addressObject) => {
  const {
    first_name,
    last_name,
    company,
    address1,
    address2,
    city,
    state,
    zip,
  } = addressObject.address_fields || addressObject.address || {};
  const addressData = [
    first_name,
    last_name,
    company,
    address1,
    address2,
    city,
    state,
    zip,
  ];

  return addressData.reduce(
    (addressString, currentAddressItem) =>
      addressString +
      (currentAddressItem
        ? `${!addressString ? '' : ', '}${currentAddressItem}`
        : ''),
    '',
  );
};

export const getAddressText = (order) => {
  if (order?.address) {
    return formatAddress(order?.address);
  }
  if (order?.addresses) {
    return formatAddress(order?.addresses?.[0]);
  }
};

export const getAddressCell = (order) => {
  const addressText = getAddressText(order);
  const additionalAddressQty =
    order?.addresses && order?.addresses.length > 1
      ? order.addresses.length - 1
      : 0;

  return `${addressText}${
    additionalAddressQty ? `+${additionalAddressQty}` : ''
  }`;
};

export const changeAddress1AndAddress2 = (address) => {
  const cloneAddress = { ...address };
  const { validated_fields, original_fields, validated_address } = cloneAddress;

  // back-end return 'None' instead of empty string when we try
  // to import .xlsx file, fix it to don't break conditions
  [original_fields, validated_fields].forEach((fields) => {
    if (!fields) {
      return;
    }
    const { address1, address2 } = fields;
    if (address1 === 'None') {
      fields.address1 = '';
    }

    if (address2 === 'None') {
      fields.address2 = '';
    }
  });

  if (!validated_address) {
    return cloneAddress;
  }

  if (
    validated_fields &&
    // There is only address1 and USPS changes it with address2
    ((original_fields.address1 &&
      !original_fields.address2 &&
      validated_fields.address2 &&
      !validated_fields.address1) ||
      // There are address1 and address2 and USPS changes them
      original_fields.address1.toLowerCase() ===
        validated_fields.address2.toLowerCase() ||
      original_fields.address2.toLowerCase() ===
        validated_fields.address1.toLowerCase())
  ) {
    const validAddress2 = cloneAddress?.validated_fields?.address1;
    cloneAddress.validated_fields.address1 =
      cloneAddress?.validated_fields?.address2;
    cloneAddress.validated_fields.address2 = validAddress2;
    cloneAddress.validated_address = fullAddressString(
      cloneAddress.validated_fields,
    );
  }

  return cloneAddress;
};
