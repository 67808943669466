// Pages
import Version from '../pages/Version';
import Materials from '../pages/Materials';
import OrderHistory from '../pages/OrderHistory';
import AddressBook from '../pages/AddressBook';
import Support from '../pages/Support';
import Cart from '../pages/Cart';
import Approvals from '../pages/Approvals';

import Auth from '../pages/Auth';
// constants
import BASE_URL from '../constants/routes';
import Kits from '../pages/Kits';
import Validate from '../pages/Validate';

export const authRoutes = [
  {
    path: '/auth/*',
    title: 'Auth',
    page: Auth,
  },
];

export const commonRoutes = [
  { path: BASE_URL.feVersion, title: 'FE-version', page: Version },
  { path: '/approvals/*', title: 'Approve order', page: Approvals },
];

export const securedRoutes = [
  {
    path: '/materials',
    title: 'Materials',
    page: Materials,
  },
  {
    path: '/kits/*',
    title: 'Kits',
    page: Kits,
  },
  {
    path: '/order-history/*',
    title: 'Order history',
    page: OrderHistory,
  },
  {
    path: '/address-book',
    title: 'Address book',
    page: AddressBook,
  },
  {
    path: '/support/*',
    title: 'Support',
    page: Support,
  },
  {
    path: '/cart/*',
    title: 'Cart',
    page: Cart,
  },
  {
    path: '/validate/*',
    title: 'Validate',
    page: Validate,
  },
];
