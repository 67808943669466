// Lib
import { createReducer } from '@reduxjs/toolkit';

// Actions
import { ClearUser, SetUser, UserLogOut } from '../actions/user';

export const initialState = {
  id: null,
  firstName: null,
  lastName: null,
  company: null,
  email: null,
  accessGroup: null,
  catalogView: 'list',
  userView: '',
};

export default createReducer(initialState, {
  [SetUser]: (state, { payload }) => {
    return {
      ...state,
      ...payload,
    };
  },
  [UserLogOut]: (state) => ({
    ...initialState,
    company: state.company,
  }),
  [ClearUser]: () => initialState,
});
