// Libs
import React from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';

// Components
import Search from '../../../components/Search';
import Feather from '../../../components/Feather';
import OrdersHistoryFilters from '../OrderHistoryFilters/OrdersHistoryFilters';
import MobileFilters from '../MobileFilters/MobileFilters';
import SideMenu from '../../SideMenu';

// Hooks
import useUI from '../../../hooks/useUI';
import useSearch from '../../../hooks/useSearch';
import useSideMenu from '../../../hooks/useSideMenu';

// Constants
import { ordersTabs } from '../../../constants/orderHistory';

const useStyles = makeStyles((theme) => ({
  bar: {
    position: 'sticky',
    top: '80px',
    boxSizing: 'border-box',
    zIndex: 100,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: '20px',
    paddingBottom: theme.spacing(1),
    margin: '0 auto',
    backgroundColor: 'white',
    maxWidth: 1180,
    [theme.breakpoints.down('xl')]: {
      top: '70px',
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: 0,
      top: '53px',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        top: '40px',
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        paddingBottom: 0,
      }),
    },
  },
  desktopSearch: {
    alignSelf: 'flex-start',
    marginTop: theme.spacing(2.5),
  },
  pageHeader: {
    fontSize: '28px',
    lineHeight: '38px',
    letterSpacing: '-0.4px',
    fontWeight: 700,
    marginBlock: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      fontSize: '22px',
      marginBlock: 0,
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: '22px',
        marginBlock: 0,
      }),
    },
  },
  panel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginInline: 'auto',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        justifyContent: 'center',
      }),
    },
  },
  tabs: ({ isRecurring }) => ({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: isRecurring ? theme.spacing(1) : theme.spacing(3),
  }),
  tab: {
    textTransform: 'none',
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '0.1px',
    paddingInline: theme.spacing(4),
    paddingBottom: 0,
    borderBottom: `2px solid ${theme.palette.grey1}`,
    color: theme.palette.grey1,
    '&.Mui-selected': {
      color: ({ userType }) =>
        userType === 'kite' ? theme.palette.primary2 : theme.palette.primary1,
    },
  },
  results: {
    color: '#54565B',
    fontSize: 16,
    fontWeight: 600,
  },
}));

const OrderHistoryBar = ({
  userType,
  activeTab = 0,
  filters,
  isRecurring = false,
}) => {
  const theme = useTheme();
  const { isTabletMode, isMobileMode } = useUI();
  const { active, toggleSideMenu, onCloseSideMenu } = useSideMenu();
  const classes = useStyles({ userType, isRecurring });
  const { onChange, onClearSearch } = useSearch();

  return (
    <>
      <Box className={classes.bar}>
        {isTabletMode && (
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}>
            <Typography className={classes.pageHeader}>
              Order History
            </Typography>
            {isTabletMode && !isMobileMode && (
              <Search
                onChange={onChange}
                onClearSearch={onClearSearch}></Search>
            )}
            {isMobileMode && (
              <Feather
                colorType="primary1"
                type="filters"
                onClick={toggleSideMenu(true)}
              />
            )}
          </Box>
        )}
        <Box className={classes.panel}>
          <Tabs
            value={activeTab}
            className={classes.tabs}
            TabIndicatorProps={{
              style: {
                backgroundColor:
                  userType === 'kite'
                    ? theme.palette.primary2
                    : theme.palette.primary1,
              },
            }}
            aria-label="secondary tabs example">
            {ordersTabs.map((tab) => (
              <Tab
                key={tab.path}
                className={classes.tab}
                label={tab.label}
                component={NavLink}
                to={tab.path}
              />
            ))}
          </Tabs>
          {!isTabletMode && (
            <Search
              className={classes.desktopSearch}
              onChange={onChange}
              onClearSearch={onClearSearch}></Search>
          )}
        </Box>
        {filters && !isMobileMode && (
          <OrdersHistoryFilters
            isRecurring={isRecurring}
            filters={filters}></OrdersHistoryFilters>
        )}
      </Box>
      <SideMenu
        title="Filters"
        styles={{
          width: '100%',
          maxWidth: 'initial',
          '& > div:nth-child(2)': {
            padding: '20px 12px',
          },
        }}
        active={active}
        toggleSideMenu={toggleSideMenu}>
        <MobileFilters
          isRecurring={isRecurring}
          filters={filters}
          onCloseSideMenu={onCloseSideMenu}
        />
      </SideMenu>
    </>
  );
};

export default OrderHistoryBar;
