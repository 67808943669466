// Libs
import React, { useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Card as MuiCard,
  CardActionArea,
  CardMedia,
  CardContent,
  Box,
  Typography,
  Stack,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

// Components
import FlagButton from '../../Button/FlagButton';
import Rating from '../../Rating';
import CountDescription, { StockItem } from './CountDescription';
import NewLabel from './NewLabel';
import ReportableIcon from './ReportableIcon';
import Image from '../../Image';
import Details from '../Details';
import MaterialsProductQtyButtons from './MaterialsProductQtyButtons';
import FdmButton from './FdmButton';
import KitsProductQtyButtons from '../../Kits/KitsProductQtyButtons/KitsProductQtyButtons';

// Actions
import { OpenModal } from '../../../actions/modal';

// Hooks
import useCart from '../../../pages/Cart/hooks/useCart';
import useUser from '../../../hooks/useUser';
import useFavorites from '../../../hooks/useFavorites';
import useUnavailableForOrderProduct from '../../../hooks/useUnavailableForOrderProduct';
import useUI from '../../../hooks/useUI';

const useStyles = makeStyles((theme) => ({
  root: ({ customCardSize }) => ({
    position: 'relative',
    borderRadius: 20,
    boxShadow: '0px 5px 15px rgba(44, 112, 172, 0.2)',
    display: 'flex',
    height: 275,
    [theme.breakpoints.down('lg')]: {
      height: 243,
    },
    [theme.breakpoints.down('md')]: {
      borderRadius: 10,
      height: 136,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        height: 243,
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        borderRadius: 10,
        height: 136,
      }),
    },
    ...customCardSize,
  }),
  img: ({ isUnavailableForOrder }) => ({
    ...(isUnavailableForOrder && {
      opacity: 0.65,
    }),
  }),
  detailsBold: ({ isKiteUser }) => ({
    fontWeight: isKiteUser ? 700 : 600,
  }),
  detailsNormal: ({ isKiteUser }) => ({
    fontWeight: isKiteUser ? 400 : 600,
  }),
  actionArea: {
    position: 'relative',
    width: 'initial',
  },
  descAndSize: {
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.down('lg')]: {
      marginBottom: 0,
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(0.5),
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        marginBottom: 0,
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        marginBottom: theme.spacing(0.5),
      }),
    },
  },
  description: {
    cursor: 'pointer',
    display: '-webkit-box',
    fontSize: 18,
    fontWeight: 700,
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    [theme.breakpoints.down('lg')]: {
      fontSize: 14,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      '-webkit-line-clamp': 1,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        fontSize: 14,
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 12,
        '-webkit-line-clamp': 1,
      }),
    },
  },
  sizeVers: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
    [theme.breakpoints.downLandscape('sm')]: {
      ...(theme.isMobileDevice && {
        fontSize: 10,
      }),
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: '30px 16px 30px 30px !important',
    width: '100%',
    minWidth: 150,
    [theme.breakpoints.down('lg')]: {
      gap: theme.spacing(1),
      padding: '16px 16px 16px 20px !important',
    },
    [theme.breakpoints.down('md')]: {
      padding: '8px 10px !important',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        gap: theme.spacing(1),
        padding: '16px 16px 16px 20px !important',
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        padding: '8px 10px !important',
      }),
    },
  },
  details: {
    color: theme.palette.darkGrey,
    display: 'flex',
    gap: theme.spacing(8),
    fontSize: 14,
    fontWeight: 600,
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
      gap: 3,
      '& div:nth-child(2)': {
        order: 2,
      },
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        flexDirection: 'column',
        gap: 3,
        '& div:nth-child(2)': {
          order: 2,
        },
      }),
    },
  },
  detailsItem: {
    '& > span:first-child': {
      display: 'block',
      [theme.breakpoints.down('lg')]: {
        paddingRight: theme.spacing(0.5),
        display: 'initial',
      },
      [theme.breakpoints.downLandscape('lg')]: {
        ...(theme.isMobileDevice && {
          paddingRight: theme.spacing(0.5),
          display: 'initial',
        }),
      },
    },
  },
  flagButton: {
    marginLeft: theme.spacing(2),
  },
  buttonsContainer: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      width: 144,
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        width: 144,
      }),
    },
  },
  qtyButtons: {
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('lg')]: {
      marginRight: 0,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        marginRight: 0,
      }),
    },
  },
  fdmButton: {
    alignSelf: 'end',
    fontSize: 12,
    marginLeft: 'auto',
    padding: theme.spacing(1),
    width: 105,
    [theme.breakpoints.down('lg')]: {
      alignSelf: 'start',
      marginLeft: 'initial',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        alignSelf: 'start',
        marginLeft: 'initial',
      }),
    },
  },
  ratingContainer: {
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        marginBottom: theme.spacing(2),
      }),
    },
  },
  labels: {
    display: 'flex',
    columnGap: theme.spacing(1),
    position: 'absolute',
    left: theme.spacing(2),
    top: theme.spacing(2),
    zIndex: 10,
    [theme.breakpoints.down('md')]: {
      columnGap: theme.spacing(0.75),
      left: theme.spacing(1.25),
      top: theme.spacing(1.25),
    },
  },
}));

const ListCard = ({
  product,
  cardContent,
  cardModalComponent,
  imageContainer,
  customCardSize = {},
  modalProps = {},
  nums,
}) => {
  const dispatch = useDispatch();
  const { selectingKitProducts, currentKit: kit } = useSelector(
    (state) => state.kits,
  );
  const { setFavoriteValue } = useFavorites();
  const { isDesktopMode, isTabletMode, isMobileMode } = useUI();
  const {
    image_link: image,
    product_description: description,
    product_id: stockNumber,
    pi_required: piRequired,
    unit_measure: unitMeasure,
    product_available_qty: availableQuantity,
    prepack_quantity: perUnitQty,
    order_limit: maxAllowedQty,
    is_new: isNew,
    is_favourite: isFavorite,
    rating: isRateable,
    reportable: isReportable,
    size_vers: sizeVers,
  } = product || {};
  const { isKiteUser } = useUser();

  const {
    addInCart,
    addingProgress,
    count,
    addOne,
    removeOne,
    setCount,
    isProductInCart,
  } = useCart(stockNumber);
  const { isUnavailableForOrder, unavailableLabel, unavailableWatermark } =
    useUnavailableForOrderProduct(product);
  const classes = useStyles({
    isUnavailableForOrder,
    customCardSize,
    isKiteUser,
  });

  const onOpenModal = useCallback(() => {
    dispatch(
      OpenModal({
        Component: cardModalComponent || Details,
        id: kit ? 'kits-product-details' : 'materials-product-details',
        props: {
          productId: stockNumber,
          isFullScreen: isTabletMode,
          ...modalProps,
          paddingContent: cardModalComponent
            ? {
                xl: '50px 40px 50px',
                lg: '60px 28px 30px',
                md: '40px 12px 16px',
              }
            : {
                xl: '50px 40px 40px',
                lg: '60px 28px 38px',
                md: '40px 12px 16px',
              },
        },
        styles: {
          width: 'min(90vw, 1143px)',
        },
      }),
    );
  }, [
    dispatch,
    cardModalComponent,
    kit,
    stockNumber,
    isTabletMode,
    modalProps,
  ]);
  return (
    <MuiCard disabled={!image} className={classes.root}>
      <div onClick={(e) => e.stopPropagation()} className={classes.labels}>
        {isNew && <NewLabel positionStatic />}
      </div>
      <CardActionArea className={classes.actionArea} onClick={onOpenModal}>
        {unavailableWatermark}
        <Image
          alt="productImage"
          src={image}
          imageContainer={
            imageContainer || { desktop: [275], tablet: [243], mobile: [136] }
          }>
          <CardMedia
            id="image-button"
            className={classes.img}
            component="img"
            image={image}
            alt="productImage"
          />
        </Image>
      </CardActionArea>
      {!cardContent ? (
        <CardContent className={classes.content}>
          <div
            style={
              isMobileMode && availableQuantity <= 0
                ? {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }
                : {}
            }>
            {isMobileMode && isRateable && <Rating product={product} />}
            <Box
              alignItems="start"
              display="flex"
              justifyContent="space-between"
              width="100%"
              gap={1}>
              <Box className={classes.descAndSize}>
                <div
                  onClick={onOpenModal}
                  className={classes.description}
                  data-testid="product-description">
                  {description}
                </div>
                <Typography variant="textSm" className={classes.sizeVers}>
                  {sizeVers}
                </Typography>
              </Box>
              {!isTabletMode && (
                <Box alignItems="center" display="flex" flexWrap="no-wrap">
                  {isRateable && <Rating product={product} />}
                  <FlagButton
                    className={classes.flagButton}
                    active={isFavorite}
                    onClick={() =>
                      setFavoriteValue(product.product_id, !isFavorite)
                    }
                    id="flag-button"
                  />
                </Box>
              )}
              {isTabletMode && !isMobileMode && (
                <FlagButton
                  className={classes.flagButton}
                  active={isFavorite}
                  onClick={() =>
                    setFavoriteValue(product.product_id, !isFavorite)
                  }
                  id="flag-button"
                />
              )}
            </Box>
            {isTabletMode && !isMobileMode && (
              <Box
                alignItems="center"
                display="flex"
                flexWrap="no-wrap"
                className={classes.ratingContainer}>
                {isRateable && <Rating product={product} />}
              </Box>
            )}
            <Box display="flex" justifyContent="space-between">
              {!isMobileMode && (
                <div className={classes.details}>
                  <div
                    className={classes.detailsItem}
                    data-testid="product-card-stock-number">
                    <span className={classes.detailsBold}>Stock Number:</span>
                    <span className={classes.detailsNormal}>{stockNumber}</span>
                  </div>
                  <div
                    className={classes.detailsItem}
                    data-testid="product-card-pi-required">
                    <span className={classes.detailsBold}>PI Required:</span>
                    <span className={classes.detailsNormal}>
                      {piRequired ? 'Yes' : 'No'}
                    </span>
                  </div>
                  <div
                    className={classes.detailsItem}
                    data-testid="product-card-unit-measure">
                    <span className={classes.detailsBold}>Unit Measure:</span>
                    <span className={classes.detailsNormal}>{unitMeasure}</span>
                  </div>
                </div>
              )}
              {isDesktopMode && isReportable && <ReportableIcon />}
              {isTabletMode && !isMobileMode && (
                <CountDescription
                  perUnitQty={perUnitQty}
                  maxAllowedQty={maxAllowedQty}
                  availableQuantity={availableQuantity}
                  unavailableLabel={unavailableLabel}
                />
              )}
              {isTabletMode && !isMobileMode && (
                <Box flexBasis="5%" display="flex" justifyContent="end">
                  {isReportable && <ReportableIcon />}
                </Box>
              )}
            </Box>
            {isMobileMode && (
              <Stack direction="row" justifyContent="space-between">
                <StockItem
                  availableQuantity={availableQuantity}
                  unavailableLabel={unavailableLabel}
                />
                {isReportable && <ReportableIcon />}
              </Stack>
            )}
          </div>
          <Box display="flex" justifyContent="space-between">
            <div className={classes.buttonsContainer}>
              {availableQuantity > 0 &&
                (selectingKitProducts || kit ? (
                  <KitsProductQtyButtons
                    product={product}
                    listView
                    detailsMode={false}
                    isMobileMode={isMobileMode}
                    nums={nums}
                    className={classes.qtyButtons}
                  />
                ) : (
                  !isUnavailableForOrder && (
                    <MaterialsProductQtyButtons
                      loading={addingProgress}
                      product={product}
                      addInCart={addInCart}
                      count={count}
                      addOne={addOne}
                      removeOne={removeOne}
                      setCount={setCount}
                      isProductInCart={isProductInCart}
                      listView
                      className={classes.qtyButtons}
                    />
                  )
                ))}
              {!isTabletMode && !isMobileMode && (
                <CountDescription
                  perUnitQty={perUnitQty}
                  maxAllowedQty={maxAllowedQty}
                  availableQuantity={availableQuantity}
                  unavailableLabel={unavailableLabel}
                />
              )}
            </div>
            {!isMobileMode && (
              <div style={{ width: 195, alignSelf: 'flex-end' }}>
                <FdmButton product={product} className={classes.fdmButton} />
              </div>
            )}
          </Box>
        </CardContent>
      ) : (
        cardContent
      )}
    </MuiCard>
  );
};

export default ListCard;
