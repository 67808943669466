// Lib
import React from 'react';
import { startOfTomorrow } from 'date-fns';

// Components
import DatePicker from '../../../../DatePicker';
import Monthly from './Monthly';
import Weekly from './Weekly';
import Disclaimer from '../../OrderProcessingInfo/Disclaimer';

// Constants
import {
  CHECKBOXES_WITH_DATE,
  SHIPPING_SCHEDULES,
} from '../../../../../constants/cart';

// Hooks
import useScheduleDetails from '../../../../../pages/Cart/hooks/useScheduleDetails';
import useOrderProcessingInfo from '../../../../../pages/Cart/hooks/useOrderProcessingInfo';

const ScheduleDetails = ({ value, setMonthError, disclaimer }) => {
  const {
    onChangeDate,
    onChangeSelect,
    onChangeMultipleSelect,
    onChangeMonthlyOption,
    onChangeMonthCount,
    shippingDetails,
    holidays,
  } = useScheduleDetails();
  const { schedule, date, weekly, monthly, showDisclaimer } = shippingDetails;
  const { openOrderProcessingWindow } = useOrderProcessingInfo();

  const hideMustArriveOnDateDisclaimer =
    schedule === SHIPPING_SCHEDULES[2].value && !showDisclaimer;

  if (
    value === schedule &&
    CHECKBOXES_WITH_DATE.includes(value) &&
    schedule !== SHIPPING_SCHEDULES[0].value
  ) {
    const doNotShipUntilDate = schedule === SHIPPING_SCHEDULES[3].value;
    return (
      <div style={{ position: 'relative' }}>
        <DatePicker
          datesDisabled={holidays}
          minDate={doNotShipUntilDate ? startOfTomorrow() : new Date()}
          required
          onChange={onChangeDate}
          date={date}
          placeholder="Select date *"
          width={141}
        />
        {!hideMustArriveOnDateDisclaimer && disclaimer && (
          <Disclaimer
            onClick={openOrderProcessingWindow(schedule, disclaimer)}
            disclaimer={disclaimer}
          />
        )}
      </div>
    );
  }
  if (value === schedule && schedule === SHIPPING_SCHEDULES[4].value) {
    return (
      <Weekly
        weekly={weekly}
        setMonthError={setMonthError}
        onChangeMonthCount={onChangeMonthCount}
        onChangeMultipleSelect={onChangeMultipleSelect}
        disclaimer={disclaimer}
        openOrderProcessingWindow={openOrderProcessingWindow(
          'Ship Weekly',
          disclaimer,
        )}
      />
    );
  }
  if (value === schedule && schedule === SHIPPING_SCHEDULES[5].value) {
    return (
      <Monthly
        monthly={monthly}
        onChangeMonthlyOption={onChangeMonthlyOption}
        onChangeSelect={onChangeSelect}
        onChangeMonthCount={onChangeMonthCount}
        setMonthError={setMonthError}
        disclaimer={disclaimer}
        openOrderProcessingWindow={openOrderProcessingWindow(
          'Ship Monthly',
          disclaimer,
        )}
      />
    );
  }
};

export default ScheduleDetails;
