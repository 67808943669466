export const modifyOrderItems = (order) => {
  let productsObj = {};
  let productIds = [];

  const order_items =
    order?.order_items?.map((orderItem) => {
      const { product } = orderItem;
      if (!productsObj[product.product_id]) {
        productsObj[product.product_id] = product || {};
        productIds.push(product.product_id);
      }

      return {
        ...orderItem,
        product: product.product_id,
      };
    }) || [];

  return { productsObj, productIds, order_items };
};
