// Lib
import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

// Components
import RemovedProduct from './RemovedProduct';
import { Typography } from '@mui/material';
import Button from '../../Button';

// Hooks
import useModal from '../../../hooks/useModal';
import { useSelector } from 'react-redux';

// Selectors
import { productsObjSelector } from '../../../store/selectors';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      '& button': {
        alignSelf: 'end',
      },
    },
  },
  removedProductsContainer: {
    height: '100%',
  },
  title: {
    marginBottom: theme.spacing(3.5),
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      width: 'initial',
    },
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    width: 138,
    justifySelf: 'end',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3),
      width: '100%',
      height: 44,
    },
  },
}));

const RemovedProducts = ({ products }) => {
  const classes = useStyles();
  const { onClose } = useModal();
  const single = products?.length === 1;
  const productsObj = useSelector((state) => productsObjSelector(state));

  return (
    <div className={classes.removedProductsContainer}>
      <Typography
        variant="h2"
        className={classes.title}
        data-testid="removed-products-header">
        {single
          ? 'This product has been removed from the site.'
          : 'These products have been removed from the site.'}
      </Typography>
      <Box
        style={{ maxHeight: '500px', overflowY: 'auto', marginBottom: '1rem' }}>
        {products?.map(({ product }) => {
          return (
            <RemovedProduct key={product} product={productsObj?.[product]} />
          );
        })}
      </Box>
      <Box className={classes.modalFooter}>
        <Button className={classes.button} onClick={onClose}>
          Close
        </Button>
      </Box>
    </div>
  );
};

export default RemovedProducts;
