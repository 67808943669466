// Libs
import React from 'react';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  label: ({ align }) => ({
    textAlign: align,
  }),
}));

const ApprovalShipmentMethodLabel = ({
  approval,
  requestedMethod,
  approvedMethod,
  align = 'left',
  className,
  ...props
}) => {
  const classes = useStyles({ align });

  const getLabelText = () => {
    if (!approval) {
      return 'Pending Approval';
    } else if (approval.is_auto_approval) {
      return `Auto-Approved By ${approval.approver}`;
    } else if (requestedMethod === approvedMethod) {
      return `Approved By ${approval.approver}`;
    } else return `Modified And Approved By ${approval.approver}`;
  };

  return (
    <Typography
      variant="textXs"
      color={'grey2'}
      className={classnames(classes.label, className)}
      {...props}>
      {getLabelText()}
    </Typography>
  );
};

export default ApprovalShipmentMethodLabel;
