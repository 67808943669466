// Libs
import React from 'react';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

// Components
import CountButton from '../../Button/CountButton';
import Button from '../../Button';

// Hooks
import useSaveForLater from '../../../pages/Cart/hooks/useSaveForLater';

const useStyles = makeStyles((theme) => ({
  container: ({ detailsMode }) => ({
    position: 'relative',
    display: 'flex',
    gap: 10,
    padding: 0,
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
      justifyContent: !detailsMode ? 'center' : 'unset',
      width: '100%',
    },
  }),
  countButton: ({ detailsMode }) => ({
    width: ({ detailsMode }) => (detailsMode ? 130 : '50%'),
    maxWidth: 130,
    height: 36,
    [theme.breakpoints.down('lg')]: {
      minWidth: detailsMode ? 200 : 96,
      height: detailsMode ? 44 : 34,
    },
    [theme.breakpoints.down('md')]: {
      minWidth: detailsMode ? 140 : 96,
      height: detailsMode ? 44 : 34,
    },
  }),
  addToCartButton: ({ detailsMode }) => ({
    width: detailsMode ? 130 : '50%',
    maxWidth: detailsMode ? 'unset' : 130,
    height: 36,
    padding: theme.spacing(1),
    [theme.breakpoints.down('lg')]: {
      minWidth: detailsMode ? 200 : 96,
      height: detailsMode ? 44 : 34,
    },
    [theme.breakpoints.down('md')]: {
      width: detailsMode ? 140 : 124,
      minWidth: detailsMode ? 140 : 'unset',
      maxWidth: detailsMode ? 'unset' : 124,
      height: 32,
    },
  }),
}));

const LaterProductQtyButtons = ({ nums, ...props }) => {
  const { product, className, detailsMode, isMobileMode } = props;
  const { putFromLaterInCart } = useSaveForLater();
  const classes = useStyles({ detailsMode });

  return (
    <div className={classnames(classes.container, className)}>
      {(detailsMode || (!detailsMode && !isMobileMode)) && (
        <CountButton
          product={product}
          count={nums}
          active={false}
          disabled={true}
          className={classes.countButton}
        />
      )}
      {!detailsMode && (
        <Button
          id="add-to-cart"
          onClick={() => putFromLaterInCart(props.product, nums)}
          disabled={product?.product_available_qty < 1}
          className={classes.addToCartButton}>
          Add To Cart
        </Button>
      )}
    </div>
  );
};

export default LaterProductQtyButtons;
