export default (text, allWords = false) => {
  const str = text?.toLowerCase();

  if (allWords) {
    const words = text.split(' ');

    if (words.length > 1) {
      return words
        .map((word) => {
          const modifiedWord = word.toLowerCase();
          return modifiedWord[0]?.toUpperCase() + modifiedWord?.slice(1);
        })
        .join(' ');
    }
  }

  return str[0]?.toUpperCase() + str?.slice(1);
};
