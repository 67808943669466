// Lib
import { createAction } from '@reduxjs/toolkit';

export const GetMyKits = createAction('GET_MY_KITS');
export const SetMyKits = createAction('SET_MY_KITS');
export const AddToMyKits = createAction('ADD_TO_MY_KITS');

export const SetKitActionErrorMessage = createAction(
  'SET_KIT_ACTION_ERROR_MESSAGE',
);
export const CleanKitActionErrorMessage = createAction(
  'CLEAN_KIT_ACTION_ERROR_MESSAGE',
);

export const DeleteKit = createAction('DELETE_KIT');
export const DeleteKitInDetailsMode = createAction(
  'DELETE_KIT_IN_DETAILS_MODE',
);
export const DeleteAllKits = createAction('DELETE_ALL_KITS');

export const GetKitDetails = createAction('GET_KIT_DETAILS');
export const SetKitDetails = createAction('SET_KIT_DETAILS');
export const ClearKitDetails = createAction('CLEAR_KIT_DETAILS');

export const EditKitName = createAction('EDIT_KIT_NAME');
export const SetNewKitName = createAction('SET_NEW_KIT_NAME');

export const ReorderKit = createAction('REORDER_KIT');
export const SetReorderKitConfirmation = createAction(
  'SET_REORDER_KIT_CONFIRMATION',
);

export const UpdateKitProductQty = createAction('UPDATE_KIT_PRODUCT_QTY');
export const AddProductToKit = createAction('ADD_PRODUCT_TO_KIT');
export const RemoveProductFromKit = createAction('REMOVE_PRODUCT_FROM_KIT');

export const SetKitProductQty = createAction('SET_KIT_PRODUCT_QTY');

// add to temp array
export const AddNewKitProduct = createAction('ADD_NEW_KIT_PRODUCT');
// edit qty of new kit product inside temp array
export const UpdateNewKitProductQty = createAction(
  'UPDATE_NEW_KIT_PRODUCT_QTY',
);
// add to temp array product that already exists in kit
export const AddExistingKitProduct = createAction('ADD_EXISTING_KIT_PRODUCT');
// edit qty of product that already exists in kit inside temp array
export const UpdateExistingKitProductQty = createAction(
  'UPDATE_EXISTING_KIT_PRODUCT_QTY',
);

export const CleanNewKitProducts = createAction('CLEAN_NEW_KIT_PRODUCTS');
export const CleanExistingKitProducts = createAction(
  'CLEAN_UPDATED_KIT_PRODUCTS',
); // edit qty of product that already exists in kit
export const SubmitNewKitProducts = createAction('SUBMIT_NEW_KIT_PRODUCTS'); // make request with products from temp array

export const SetKitAfterRemoveProduct = createAction(
  'SET_KIT_AFTER_REMOVE_PRODUCT',
);

export const SetSelectingKitProductsProcess = createAction(
  'SET_SELECTING_KIT_PRODUCTS_PROCESS',
);
