// symbols that are part of regexp syntax
// need to be prefixed with backslashes
const regExpPrefix = (symbol) => {
  try {
    new RegExp(symbol);
  } catch (e) {
    return `\\${symbol}`;
  }

  return symbol;
};

const getRegExpStr = (str) => {
  return str
    .split('')
    .map((symbol) => regExpPrefix(symbol))
    .join('');
};

const multiSearch = (text, searchWords) => {
  if (!text) {
    return;
  }
  let searchText = text;
  let result = true;

  for (let i = 0; i < searchWords.length; i++) {
    let word = getRegExpStr(searchWords[i]);
    const searchExp = new RegExp(word === '*' ? `/${word}` : word, 'i');
    result = !!searchText.match(searchExp);
    if (!result) {
      break;
    } else {
      searchText = searchText.replace(searchExp, '');
    }
  }
  return result;
};

export default multiSearch;
