// Lib
import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

// Components
import Feather from '../../../../Feather';
import Button from '../../../../Button';

// Hooks
import useModal from '../../../../../hooks/useModal';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
  info: {
    alignItems: 'center',
    display: 'flex',
    color: '#54565B',
  },
  fade: {
    width: '100%',
    height: theme.spacing(3),
    background: 'linear-gradient(to bottom, transparent, white)',
  },
  buttonContainer: {
    position: '-webkit-sticky',
    position: 'sticky',
    bottom: theme.spacing(1.5),
  },
  buttonBackground: {
    background: 'white',
  },
  closeBtn: {
    marginLeft: 'auto',
    width: 116,
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      width: '100%',
    },
  },
}));

const OrderProcessingInfoCard = ({ children }) => {
  const classes = useStyles();
  const { onClose } = useModal();

  return (
    <div className={classes.container}>
      <div className={classes.info}>
        <Feather
          nativeColor
          type="warning"
          style={{ marginRight: 4, marginBottom: '10px !important' }}
        />
        Info
      </div>
      {children}
      <div className={classes.buttonContainer}>
        <div className={classes.fade}></div>
        <div className={classes.buttonBackground}>
          <Button onClick={onClose} className={classes.closeBtn}>
            Close
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OrderProcessingInfoCard;
