// Lib
import { createAction } from '@reduxjs/toolkit';

export const GetContacts = createAction('GET_CONTACTS');
export const SetContacts = createAction('SET_CONTACTS');
export const GetAlgoliaAppInfo = createAction('GET_ALGOLIA_APP_INFO');
export const SetAlgoliaAppInfo = createAction('SET_ALGOLIA_APP_INFO');
export const ToClearSearchLocalValue = createAction(
  'TO_CLEAR_SEARCH_LOCAL_VALUE',
);
