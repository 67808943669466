// Lib
import React, { useEffect } from 'react';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';

// Components
import Weekly from '../../../Cart/ShippingDetails/DetailsForm/ScheduleDetails/Weekly';
import Monthly from '../../../Cart/ShippingDetails/DetailsForm/ScheduleDetails/Monthly';
import FooterButtons from '../../../Modal/FooterButtons';

// Constants
import { SHIPPING_SCHEDULES } from '../../../../constants/cart';

// Hooks
import useScheduleDetails from '../../../../pages/Cart/hooks/useScheduleDetails';
import useChangingRecurrence from '../../../../pages/OrderHistory/hooks/useChangingRecurrence';
import useModal from '../../../../hooks/useModal';

// Styles
import { colors } from '../../../../styles/palette';

// Actions
import { ClearShippingDetails } from '../../../../actions/cart';

const useStyles = makeStyles((theme) => ({
  radioGroup: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1.5),
    },
  },
  radioLabel: {
    width: 'fit-content',
    '& .MuiTypography-root': {
      fontSize: 18,
      [theme.breakpoints.down('md')]: {
        fontSize: 16,
      },
    },
  },
  radioItem: {
    color: '#C6CAC6',
    padding: 8,
    '&$checked': { color: theme.palette.primary1 },
    [theme.breakpoints.down('md')]: {
      padding: 5,
    },
  },
  buttons: {
    paddingBottom: 0,
    '& #accept-button': {
      [theme.breakpoints.down('lg')]: {
        width: 110,
      },
      [theme.breakpoints.down('md')]: {
        width: 140,
        height: 44,
      },
    },
  },
}));

const EditRecurrenceCard = ({ order, orderDetailsPage }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    onChangeRadio,
    setMonthError,
    isSaveBtnDisabled,
    onSave,
    loading,
    apiError,
  } = useChangingRecurrence(order, orderDetailsPage);
  const { onClose } = useModal();
  const {
    onChangeSelect,
    onChangeMultipleSelect,
    onChangeMonthlyOption,
    onChangeMonthCount,
    shippingDetails,
  } = useScheduleDetails();
  const { schedule, weekly, monthly } = shippingDetails;

  useEffect(() => {
    return () => {
      dispatch(ClearShippingDetails());
    };
  }, [dispatch]);

  return (
    <>
      <Typography variant="h3" mb={{ xs: 4 }}>
        Edit Recurrence
      </Typography>
      <RadioGroup
        className={classes.radioGroup}
        value={schedule}
        row={false}
        aria-labelledby="address-format-radio-buttons-group-label"
        name="shipping-schedule-radio-buttons-group">
        <FormControlLabel
          className={classes.radioLabel}
          value={SHIPPING_SCHEDULES[4].value}
          control={
            <Radio
              classes={{
                root: classes.radioItem,
              }}
              onChange={onChangeRadio}
            />
          }
          label={SHIPPING_SCHEDULES[4].label}
        />
        {schedule === SHIPPING_SCHEDULES[4].value && (
          <Weekly
            weekly={weekly}
            setMonthError={setMonthError}
            onChangeMonthCount={onChangeMonthCount}
            onChangeMultipleSelect={onChangeMultipleSelect}
          />
        )}
        <FormControlLabel
          className={classes.radioLabel}
          value={SHIPPING_SCHEDULES[5].value}
          control={
            <Radio
              classes={{
                root: classes.radioItem,
              }}
              onChange={onChangeRadio}
            />
          }
          label={SHIPPING_SCHEDULES[5].label}
        />
        {schedule === SHIPPING_SCHEDULES[5].value && (
          <Monthly
            monthly={monthly}
            onChangeMonthlyOption={onChangeMonthlyOption}
            onChangeSelect={onChangeSelect}
            onChangeMonthCount={onChangeMonthCount}
            setMonthError={setMonthError}
          />
        )}
      </RadioGroup>
      {apiError && (
        <div style={{ color: colors.gilead.primary1 }}>{apiError}</div>
      )}
      <FooterButtons
        className={classes.buttons}
        onReject={onClose}
        onAccept={onSave}
        acceptTitle="Save"
        rejectTitle="Cancel"
        disabledAcceptBtn={isSaveBtnDisabled}
        isLoading={loading}
      />
    </>
  );
};

export default EditRecurrenceCard;
