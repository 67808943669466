// lib
import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, Box } from '@mui/material';
import { useSelector } from 'react-redux';

// Components
import Rating from '../../Rating';
import Button from '../../Button';
import Feather from '../../Feather';
import Image from '../../Image';
import ProductDetailsButtons from './ProductDetailsButtons';
import ProductInfo, { QuantityInfo } from './ProductInfo';
import MyRating from '../../Rating/MyRating';
import NewLabel from '../Card/NewLabel';
import ReportableIcon from '../Card/ReportableIcon';
import FdmButton from '../Card/FdmButton';

// Hooks
import useUser from '../../../hooks/useUser';
import useCart from '../../../pages/Cart/hooks/useCart';
import useRequest from '../../../pages/Cart/hooks/useRequest';
import useUI from '../../../hooks/useUI';
import useFavorites from '../../../hooks/useFavorites';
import useUnavailableForOrderProduct from '../../../hooks/useUnavailableForOrderProduct';
import useOverLimitProduct from '../../../pages/Cart/hooks/useOverLimitProduct';

// Selectors
import { productsObjSelector } from '../../../store/selectors';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  flexContainer: {
    display: 'flex',
    gap: 'min(10vw, 140px)',
    [theme.breakpoints.down('lg')]: {
      flex: 1,
      marginTop: theme.spacing(6),
      gap: 58,
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        flex: 1,
        marginTop: theme.spacing(6),
        gap: 58,
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        flexDirection: 'column',
      }),
    },
  },
  descAndSize: {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1.5),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1.5),
      }),
    },
  },
  description: {
    display: '-webkit-box',
    margin: 0,
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    [theme.breakpoints.down('lg')]: {
      width: '90%',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 22,
      width: '100%',
      overflow: 'visible',
      display: 'block',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        width: '90%',
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 22,
        width: '100%',
        overflow: 'visible',
        display: 'block',
      }),
    },
  },
  stockNumber: {
    color: theme.palette.darkGrey,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(5),
    [theme.breakpoints.down('lg')]: {
      marginTop: 0,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        marginTop: 0,
      }),
    },
  },
  detailTitle: {
    color: theme.palette.darkGrey,
    fontWeight: 600,
    marginBottom: 6,
    '& span': {
      fontWeight: 400,
    },
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '50%',
    [theme.breakpoints.down('lg')]: {
      flexBasis: '45%',
    },
    [theme.breakpoints.down('md')]: {
      flexBasis: '50%',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        flexBasis: '45%',
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        flexBasis: '50%',
      }),
    },
  },
  right: ({ isKiteUser }) => ({
    flexBasis: '50%',
    '& #inStock': {
      color: isKiteUser ? theme.palette.primary1 : theme.palette.primary2,
    },
    [theme.breakpoints.down('lg')]: {
      flexBasis: '55%',
    },
    [theme.breakpoints.down('md')]: {
      flexBasis: '50%',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        flexBasis: '55%',
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        flexBasis: '50%',
      }),
    },
  }),
  mobileQuantity: ({ isKiteUser }) => ({
    '& #inStock': {
      color: isKiteUser ? theme.palette.primary1 : theme.palette.primary2,
    },
  }),
  quantityContainer: {
    display: 'flex',
    gap: 90,
    [theme.breakpoints.down('lg')]: {
      gap: 50,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        gap: 50,
      }),
    },
  },
  buttons: {
    '& #count-button': {
      height: 36,
    },
    '& #add-to-cart': {
      height: 36,
      fontSize: 14,
    },
    [theme.breakpoints.down('lg')]: {
      '& #count-button': {
        height: 44,
        '& input': {
          fontSize: 16,
        },
      },
      '& #add-to-cart': {
        height: 44,
        fontSize: 16,
      },
      '& #additionalInfo': {
        marginTop: 4,
      },
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(3),
      position: 'relative',
      '& #count-button': {
        '& input': {
          fontSize: 14,
        },
      },
      '& #add-to-cart': {
        fontSize: 14,
      },
      '& #additionalInfo': {
        height: 44,
        maxWidth: 86,
        position: 'absolute',
        marginLeft: 0,
        left: 156,
        fontSize: 16,
        marginTop: 0,
      },
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        '& #count-button': {
          height: 44,
          '& input': {
            fontSize: 16,
          },
        },
        '& #add-to-cart': {
          height: 44,
          fontSize: 16,
        },
        '& #additionalInfo': {
          marginTop: 4,
        },
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        paddingBottom: theme.spacing(3),
        position: 'relative',
        '& #count-button': {
          '& input': {
            fontSize: 14,
          },
        },
        '& #add-to-cart': {
          fontSize: 14,
        },
        '& #additionalInfo': {
          height: 44,
          maxWidth: 86,
          position: 'absolute',
          marginLeft: 0,
          left: 156,
          fontSize: 16,
          marginTop: 0,
        },
      }),
    },
  },
  fdmButton: {
    [theme.breakpoints.down('md')]: {
      width: 126,
      height: 36,
      paddingRight: 15,
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        width: 126,
        height: 36,
        paddingRight: 15,
      }),
    },
  },
  addToFavorite: ({ isFavorite }) => ({
    color: `${theme.palette.primary1} !important`,
    fontWeight: 600,
    marginBottom: theme.spacing(2.25),
    marginTop: theme.spacing(3),
    padding: 0,
    fontSize: 14,
    '& svg': {
      marginRight: theme.spacing(1),
      '& path': {
        fill: isFavorite ? `${theme.palette.primary1} !important` : 'unset',
        stroke: theme.palette.primary1,
      },
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(1.875),
      marginBottom: theme.spacing(1.25),
    },
    [theme.breakpoints.down('md')]: {
      margin: '10px auto',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        marginTop: theme.spacing(1.875),
        marginBottom: theme.spacing(1.25),
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        margin: '10px auto',
      }),
    },
  }),
  imageContainer: ({ isUnavailableForOrder }) => ({
    // align image and rating on desktop and table
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    maxWidth: 290,
    '& img': {
      objectFit: 'contain',
      width: '100%',
      opacity: isUnavailableForOrder ? 0.65 : 1,
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: 363,
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '-12px -12px 0 -12px',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        maxWidth: 363,
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        maxWidth: '100%',
        margin: '-12px -12px 0 -12px',
      }),
    },
  }),
  myRating: {
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    color: '#929A92',
    maxWidth: 290,
    [theme.breakpoints.down('lg')]: {
      maxWidth: 363,
    },
    [theme.breakpoints.down('md')]: {
      marginInline: 'auto',
      maxWidth: 'unset',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        maxWidth: 363,
      }),
    },

    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        marginInline: 'auto',
        maxWidth: 'unset',
      }),
    },
  },
  rating: {
    fontSize: 18,
  },
}));

const MobileDetails = ({
  product,
  classes,
  isMobileMode,
  cartProps,
  withMyRating,
}) => {
  const {
    image_link: image,
    product_description: description,
    is_new: isNew,
    is_favourite: isFavorite,
    rating: isRatable,
    reportable: isReportable,
    size_vers: sizeVers,
  } = product || {};

  const { setFavoriteValue } = useFavorites();
  const { isUnavailableForOrder, unavailableLabel, unavailableWatermark } =
    useUnavailableForOrderProduct(product, false, true);

  return (
    <>
      <Box display="flex" justifyContent="center">
        <div className={classes.imageContainer}>
          {isNew && <NewLabel />}
          {unavailableWatermark}
          <Image alt="image" src={image} />
        </div>
      </Box>
      <Button
        data-testid="add-to-favorites"
        className={classes.addToFavorite}
        variant="text"
        onClick={() =>
          setFavoriteValue(product?.product_id, !isFavorite, true)
        }>
        <Feather type="flag" printType="stroke" />
        {isFavorite ? 'In Favorites' : 'Add To Favorites'}
      </Button>
      {isRatable && withMyRating && (
        <MyRating product={product} className={classes.myRating} />
      )}
      <div className={classes.descAndSize}>
        <Typography className={classes.description} variant="h2">
          {description}
        </Typography>
        <Typography variant="textSm">{sizeVers}</Typography>
      </div>
      {isReportable && (
        <div style={{ marginBottom: 10 }}>
          <ReportableIcon withText />
        </div>
      )}
      {isRatable && <Rating product={product} />}
      <FdmButton
        styles={{
          fontSize: 14,
          marginBottom: 20,
          marginTop: 28,
        }}
        className={classes.fdmButton}
        product={product}
      />
      <div className={classes.mobileQuantity}>
        <QuantityInfo product={product} unavailableLabel={unavailableLabel} />
      </div>
      <ProductInfo product={product} isMobileMode={isMobileMode} />
      {!isUnavailableForOrder && (
        <div className={classes.buttons}>
          <ProductDetailsButtons
            product={product}
            isMobileMode={isMobileMode}
            cartProps={cartProps}
            detailsMode
          />
        </div>
      )}
    </>
  );
};

const Details = ({ productId, withMyRating = true, showRequestModal }) => {
  const { isKiteUser } = useUser();
  const { isDesktopMode, isTabletMode, isMobileMode } = useUI();
  const product = useSelector(
    (state) => productsObjSelector(state)?.[productId],
  );
  const {
    addInCart,
    addingProgress,
    count,
    addOne,
    removeOne,
    setCount,
    isProductInCart,
  } = useCart(product?.product_id);
  const { isOverLimitProduct } = useOverLimitProduct(product?.product_id);
  const { openRequestCard, savedRequest } = useRequest(
    product?.product_id,
    true,
  );

  const { isUnavailableForOrder, unavailableLabel, unavailableWatermark } =
    useUnavailableForOrderProduct(product, false, true);

  useEffect(() => {
    if (isOverLimitProduct && !savedRequest && showRequestModal) {
      openRequestCard();
    }
  }, [openRequestCard, isOverLimitProduct, savedRequest, showRequestModal]);

  const {
    image_link: image,
    product_description: description,
    product_id: stockNumber,
    is_new: isNew,
    is_favourite: isFavorite,
    rating: isRatable,
    reportable: isReportable,
    size_vers: sizeVers,
  } = product || {};

  const { setFavoriteValue } = useFavorites();

  const classes = useStyles({
    isKiteUser,
    isUnavailableForOrder,
    isFavorite,
  });

  return (
    <>
      {!isMobileMode ? (
        <div className={classes.root}>
          {isTabletMode && (
            <div>
              <Typography className={classes.description} variant="h2">
                {description}
              </Typography>
              <Typography variant="textSm">{sizeVers}</Typography>
              {isRatable && <Rating withTooltip={false} product={product} />}
            </div>
          )}
          <div className={classes.flexContainer}>
            <div className={classes.left}>
              {isDesktopMode && (
                <>
                  <Typography className={classes.description} variant="h2">
                    {description}
                  </Typography>
                  <Typography variant="textSm">{sizeVers}</Typography>
                  {isRatable && (
                    <Rating withTooltip={false} product={product} />
                  )}
                </>
              )}
              <Typography className={classes.stockNumber} variant="h3">
                {stockNumber && `Stock Number: ${stockNumber}`}
              </Typography>
              <ProductInfo product={product} />
              {isDesktopMode && (
                <div className={classes.buttons}>
                  <ProductDetailsButtons
                    product={product}
                    isMobileMode={isMobileMode}
                    detailsMode
                    hideQtyButtons={isUnavailableForOrder}
                    cartProps={{
                      loading: addingProgress,
                      addInCart,
                      count,
                      addOne,
                      removeOne,
                      setCount,
                      isProductInCart,
                      isOverLimit: isOverLimitProduct,
                    }}
                  />
                </div>
              )}
            </div>
            <div className={classes.right}>
              <QuantityInfo
                product={product}
                unavailableLabel={unavailableLabel}
              />
              <Button
                className={classes.addToFavorite}
                variant="text"
                onClick={() =>
                  setFavoriteValue(product?.product_id, !isFavorite, true)
                }>
                <Feather type="flag" printType="stroke" />
                {isFavorite ? 'In Favorites' : 'Add To Favorites'}
              </Button>
              <div className={classes.imageContainer}>
                {isNew && <NewLabel />}
                {unavailableWatermark}
                <Image
                  alt="image"
                  src={image}
                  imageContainer={{
                    desktop: [290],
                    tablet: [363],
                    mobile: [320],
                  }}
                />
                {isReportable && <ReportableIcon withText />}
                {isRatable && withMyRating && (
                  <MyRating product={product} className={classes.myRating} />
                )}
              </div>
            </div>
          </div>
          {isTabletMode && (
            <div
              className={classes.buttons}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                flexGrow: 1,
                marginBottom: '1rem', // place for Over limit label
              }}>
              <ProductDetailsButtons
                product={product}
                isMobileMode={isMobileMode}
                detailsMode
                hideQtyButtons={isUnavailableForOrder}
                cartProps={{
                  loading: addingProgress,
                  addInCart,
                  count,
                  addOne,
                  removeOne,
                  setCount,
                  isProductInCart,
                  isOverLimit: isOverLimitProduct,
                }}
              />
            </div>
          )}
        </div>
      ) : (
        <MobileDetails
          withMyRating={withMyRating}
          product={product}
          classes={classes}
          isMobileMode={isMobileMode}
          cartProps={{
            addInCart,
            count,
            addOne,
            removeOne,
            setCount,
            isProductInCart,
            isOverLimit: isOverLimitProduct,
          }}
        />
      )}
    </>
  );
};

export default Details;
