// Libs
import React, { useCallback } from 'react';
import classnames from 'classnames';
import { makeStyles } from '@mui/styles';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

// Components
import { Button, Typography, Box } from '@mui/material';
import { ReactComponent as PhoneIcon } from '../../../assets/svg/phone.svg';
import { ReactComponent as FileIcon } from '../../../assets/svg/file.svg';
import { ReactComponent as LetterIcon } from '../../../assets/svg/mail.svg';

// Hooks
import useUI from '../../../hooks/useUI';
import useUser from '../../../hooks/useUser';

// Styles
import { cardRoundedShadow } from '../../../styles/global';

const useStyles = makeStyles((theme) => ({
  supportCard: {
    ...cardRoundedShadow,
    width: '267px',
    minWidth: '224px',
    height: '230px',
    padding: '24px 20px',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    alignItems: 'start',
    [theme.breakpoints.down('lg')]: {
      height: '226px',
      padding: '20px 16px',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      minWidth: '100%',
      height: '200px',
      padding: '16px',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        height: '226px',
        padding: '20px 16px',
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        width: '100%',
        minWidth: '100%',
        height: '200px',
        padding: '16px',
      }),
    },
  },
  phoneButton: {
    color: ({ isKiteUser }) =>
      isKiteUser ? theme.palette.primary1 : theme.palette.pressed,
    textDecoration: 'none',
    fontStyle: 'normal',
    ...theme.typography.textSm,
    fontWeight: '400 !important',
    marginLeft: '8px',
  },
  mediaButton: {
    height: '36px',
    backgroundColor: theme.palette.lightGrey,
    '&:hover': {
      backgroundColor: `${theme.palette.lightGrey} !important`,
    },
    fontSize: '14px',
    fontWeight: ({ isKiteUser }) => (isKiteUser ? '400' : '600'),
    color: ({ isKiteUser }) =>
      isKiteUser ? theme.palette.primary1 : theme.palette.pressed,
    [theme.breakpoints.down('lg')]: {
      height: '34px',
    },
    [theme.breakpoints.down('md')]: {
      width: 264,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        height: '34px',
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        width: 264,
        marginLeft: 'auto',
        marginRight: 'auto',
      }),
    },
  },
  videoButton: ({ isKiteUser }) => ({
    marginBottom: theme.spacing(1),
    '& path': {
      fill: isKiteUser ? theme.palette.primary1 : theme.palette.pressed,
    },
  }),
  cardIcon: ({ isKiteUser }) => ({
    '& > path': {
      fill: isKiteUser ? theme.palette.primary1 : theme.palette.pressed,
    },
  }),
}));

const SupportCard = (props) => {
  const { header, text, phoneNumber, buttonInfo, videoButtonInfo } = props;
  const {
    type: buttonType,
    content: buttonContent,
    title: buttonTitle,
  } = buttonInfo || {};
  const { isKiteUser } = useUser();
  const classes = useStyles({ isKiteUser });
  const { isMobileMode, isDesktopMode } = useUI();

  const onButtonClick = useCallback(() => {
    if (buttonType === 'email') {
      window.location.href = `mailto:${buttonContent}`;
    } else {
      window.open(buttonContent, '_blank', 'noopener,noreferrer');
    }
  }, [buttonContent, buttonType]);

  const onVideoButtonClick = () => {
    window.open(videoButtonInfo.content, '_blank', 'noopener,noreferrer');
  };

  const resolveButtonIcon = () => {
    return buttonType === 'email' ? (
      <LetterIcon className={classes.cardIcon} />
    ) : (
      <FileIcon className={classes.cardIcon} />
    );
  };

  return (
    <div data-testid="support-card" className={classes.supportCard}>
      <Typography variant="textMdSemibold">{header}</Typography>
      <Typography
        variant={isMobileMode ? 'textMd' : 'textSm'}
        color={'#54565B'}>
        {text}
      </Typography>

      {phoneNumber && (
        <Button
          className={classes.phoneButton}
          style={{ padding: 0, wordWrap: 'none' }}
          startIcon={<PhoneIcon className={classes.cardIcon} />}
          onClick={() => {
            window.location.href = `tel:${phoneNumber}`;
          }}>
          {phoneNumber}
        </Button>
      )}

      <Box
        flexGrow={1}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'end'}
        width={isDesktopMode ? 'unset' : '100%'}>
        {videoButtonInfo && (
          <Button
            variant="contained"
            className={classnames(classes.mediaButton, classes.videoButton)}
            onClick={onVideoButtonClick}
            startIcon={<PlayCircleIcon />}>
            {videoButtonInfo.title}
          </Button>
        )}

        <Button
          variant="contained"
          className={classes.mediaButton}
          onClick={onButtonClick}
          startIcon={resolveButtonIcon()}>
          {buttonTitle}
        </Button>
      </Box>
    </div>
  );
};

export default SupportCard;
