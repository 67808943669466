// Lib
import React from 'react';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

// Components
import { ReactComponent as Grid } from '../../../assets/svg/grid.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    fill: theme.palette.primary1,
    cursor: 'pointer',

    '& rect': {
      fill: theme.palette.primary1,
    },

    '&:hover': {
      '& path': {
        fill: theme.palette.primary1,
      },
      '& rect': {
        fill: theme.palette.lightGrey,
      },
    },
  },
  active: {
    fill: theme.palette.lightGrey,
    cursor: 'pointer',
    '& rect': {
      fill: theme.palette.lightGrey,
    },

    '& path': {
      fill: theme.palette.primary1,
    },
  },
}));

const GridButton = ({ isActive, styles, ...props }) => {
  const classes = useStyles();

  return (
    <Grid
      id="grid-button"
      className={
        isActive ? classnames(classes.active, classes.root) : classes.root
      }
      style={styles}
      {...props}
    />
  );
};

export default GridButton;
