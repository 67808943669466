// Lib
import React from 'react';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

// Components
import Feather from '../../Feather';

const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: '#F4F4F4 !important',
    borderRadius: 4,
    height: 24,
    minWidth: 24,
    padding: 0,
    width: 24,
  },
}));

const PlusButton = ({ onClick = () => {}, style }) => {
  const classes = useStyles();

  return (
    <Button
      id="plus-button"
      style={style}
      onClick={onClick}
      className={classes.button}>
      <Feather type="plus" />
    </Button>
  );
};

export default PlusButton;
