import { ReactComponent as MaterialsIcon } from '../assets/svg/materials.svg';
import { ReactComponent as OrderHistoryIcon } from '../assets/svg/orderHistory.svg';
import { ReactComponent as AddressBookIcon } from '../assets/svg/addressBook.svg';
import { ReactComponent as SupportIcon } from '../assets/svg/support.svg';
import { ReactComponent as CartIcon } from '../assets/svg/cart.svg';
import { ReactComponent as KitsIcon } from '../assets/svg/kits.svg';
import { ReactComponent as ValidateIcon } from '../assets/svg/validate.svg';

const COMMON_HEADER_LINKS = [
  {
    title: 'Materials',
    path: '/materials',
    icon: MaterialsIcon,
    id: 'materials-link',
  },
  {
    title: 'My Kits',
    path: '/kits',
    icon: KitsIcon,
    id: 'kits-link',
  },
  {
    title: 'Order History',
    path: '/order-history',
    icon: OrderHistoryIcon,
    id: 'order-history-link',
  },
  {
    title: 'Address Book',
    path: '/address-book',
    icon: AddressBookIcon,
    id: 'address-book-link',
  },
  {
    title: 'Validate',
    path: '/validate',
    icon: ValidateIcon,
    id: 'validate-link',
  },
];

const DESKTOP_HEADER_LINKS = [
  ...COMMON_HEADER_LINKS,
  {
    title: 'Support',
    path: '/support',
    icon: SupportIcon,
    id: 'support-link',
  },
  {
    title: 'Cart',
    path: '/cart',
    icon: CartIcon,
    id: 'cart-link',
  },
];

const MOBILE_HEADER_LINKS = [
  ...COMMON_HEADER_LINKS,
  {
    title: 'Support',
    path: '/support',
    icon: SupportIcon,
    id: 'support-link',
  },
  {
    title: 'Cart',
    path: '/cart',
    icon: CartIcon,
    id: 'cart-link',
  },
];

export { DESKTOP_HEADER_LINKS, MOBILE_HEADER_LINKS };
