// Lib
import React from 'react';
import Box from '@mui/material/Box';

// Components
import Select from '../../Field/Select';
import MultipleSelect from '../../Field/MultipleSelect';
import SortButton from '../../Button/SortButton';
import DatePicker from '../../DatePicker';
import RemoveFilters from '../../FiltersBar/RemoveFilters';

const OrdersHistoryFilters = ({ isRecurring, filters }) => {
  const sortingFilter = (
    <Box display="flex" gap={1} alignItems="center">
      <Select
        onChange={filters.onChangeSorting}
        initialValue={filters.sorting.label}
        items={filters.sortingItems}
      />
      <SortButton onClick={filters.changeOrder} order={filters.order} />
    </Box>
  );

  return !isRecurring ? (
    <Box style={{ marginInline: 'auto', maxWidth: 1180 }}>
      <Box display="flex" gap={2} alignItems="center">
        {sortingFilter}
        <MultipleSelect
          name="statuses"
          label="All Statuses"
          width={130}
          items={filters.statuses}
          onChangeFilter={filters.onChangeStatuses}
        />
        <DatePicker
          width={140}
          date={filters.startDate}
          onChange={filters.onChangeStartDate}
          maxDate={new Date()}
          helperTextPosition="absolute"
        />{' '}
        To{' '}
        <DatePicker
          width={140}
          date={filters.endDate}
          onChange={filters.onChangeEndDate}
          helperTextPosition="absolute"
        />
      </Box>
      <Box
        mt={filters.statusesAreNotDefault ? 1 : 0}
        display="flex"
        gap={2}
        flexWrap={'wrap'}
        alignItems={'center'}>
        <RemoveFilters
          filters={filters}
          filterTitles={filters.filterTitles}
          onRemoveFilter={filters.onRemoveFilter}
          onClearAllFilters={filters.onClearAllFilters}></RemoveFilters>
      </Box>
    </Box>
  ) : (
    <Box mt={2} mx={'auto'}>
      {sortingFilter}
    </Box>
  );
};

export default OrdersHistoryFilters;
