// Libs
import React, { useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

// Components
import Rating from '../../../../Rating';
import OrderProductDetails from '../../../Modals/OrderProductDetails/OrderProductDetails';
import OutOfStockLabel from '../../../Labels/OutOfStockLabel';
import CountComponent from '../CountComponent';
import CloseComponent from '../CloseComponent';
import OrderProductQtyLabels from '../../../../Labels/OrderProductQtyLabels';
import ApprovalLabel from '../../../../Labels/ApprovalLabel';
import ReportableIcon from '../../../../Products/Card/ReportableIcon';

// Hooks
import useUser from '../../../../../hooks/useUser';
import useUI from '../../../../../hooks/useUI';
import useUnavailableForOrderProduct from '../../../../../hooks/useUnavailableForOrderProduct';

// Actions
import { OpenModal } from '../../../../../actions/modal';

// Utils
import getRemainingQuantity from '../../../../../utils/getRemainigQuantity';
import { getLimits } from '../../../../../utils/overLimit';
import { getProductTotalItemsOrdered } from '../../../../../pages/OrderHistory/utils/getQuantity';

const useStyles = makeStyles((theme) => ({
  audienceLabel: {
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#54565B',
    padding: '5px 10px',
    borderRadius: '5px',
    backgroundColor: '#F4F4F4',
  },
  infoContainer: {
    '& > div:nth-child(3)': {
      [theme.breakpoints.down('md')]: {
        width: 95,
        height: 24,
      },
      [theme.breakpoints.downLandscape('md')]: {
        ...(theme.isMobileDevice && {
          width: 95,
          height: 24,
        }),
      },
    },
  },
  qtyInfoText: ({ isKiteUser }) => ({
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    color: isKiteUser ? theme.palette.primary1 : theme.palette.pressed,
    '&:nth-child(2)': {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '10px',
      lineHeight: '12px',
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: '10px',
        lineHeight: '12px',
      }),
    },
  }),
  listCardQtyInfo: {
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    color: '#54565B',
  },
  productInfo: {
    '&  *': {
      fontSize: '14px',
    },
  },
  productInfoHeader: ({ isKiteUser }) => ({
    fontWeight: isKiteUser ? '700' : '600',
  }),
  productInfoText: ({ isKiteUser }) => ({
    fontWeight: isKiteUser ? '400' : '600',
  }),
  inStockQty: ({ order_limit }) => ({
    marginBottom: order_limit === null ? 36 : 0,
  }),
  productDescription: {
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    [theme.breakpoints.down('md')]: {
      '-webkit-line-clamp': 2,
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        '-webkit-line-clamp': 2,
      }),
    },
  },
  productMobileDescription: {
    fontSize: '14px',
    fontWeight: '700',
    [theme.breakpoints.down('md')]: {
      fontSize: '10px',
      fontWeight: '600',
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: '10px',
        fontWeight: '600',
      }),
    },
  },
  buttonContainer: {
    alignItems: 'end',
    display: 'flex',
    '& > div:nth-child(1)': {
      [theme.breakpoints.down('lg')]: {
        width: 130,
        height: 34,
      },
      [theme.breakpoints.downLandscape('lg')]: {
        ...(theme.isMobileDevice && {
          width: 130,
          height: 34,
        }),
      },
    },
  },
  closeIcon: {
    top: 3,
    right: -40,
    [theme.breakpoints.down('lg')]: {
      top: 0,
      right: 0,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        top: 0,
        right: 0,
      }),
    },
  },
  approvalLabel: ({ isDesktopMode }) => ({
    position: 'absolute',
    top: isDesktopMode ? '100%' : 'unset',
    right: isDesktopMode ? '0' : 'unset',
    bottom: !isDesktopMode ? '16px' : 'unset',
    left: !isDesktopMode ? '16px' : 'unset',
    maxWidth: isDesktopMode ? '145px' : '185px',
  }),
  totalItems: () => ({
    color: theme.palette.red,
  }),
}));

const ListContent = ({
  product,
  order,
  orderItem,
  isRecurring = false,
  addressesCount = 1,
}) => {
  const {
    product_id,
    brand_category,
    unit_measure,
    pi_required,
    product_available_qty,
    order_limit,
    product_description: description,
    prepack_quantity,
    owner,
    limits,
    rating: isRatable,
    reportable: isReportable,
    size_vers: sizeVers,
  } = product || {};
  const { isUnavailableForOrder, unavailableLabel } =
    useUnavailableForOrderProduct(product);

  const { monthLimit } = useMemo(() => getLimits(limits), [limits]);

  const remainingMonthQuantity = useMemo(
    () => getRemainingQuantity(monthLimit),
    [monthLimit],
  );

  const { isDesktopMode, isMobileMode, isTabletMode } = useUI();

  const dispatch = useDispatch();
  const { isKiteUser } = useUser();
  const classes = useStyles({
    isKiteUser,
    order_limit,
    isDesktopMode,
    isMobileMode,
    isTabletMode,
  });

  const onOpenModal = useCallback(() => {
    dispatch(
      OpenModal({
        Component: OrderProductDetails,
        props: {
          productId: product.product_id,
          isFullScreen: isTabletMode,
          order,
          orderItem,
          addressesCount,
          isRecurring,
          paddingContent: {
            xl: '50px 40px 50px',
            lg: '60px 28px 30px',
            md: '40px 12px 16px',
          },
        },
        styles: {
          width: 'min(90vw, 1143px)',
        },
      }),
    );
  }, [
    dispatch,
    product,
    order,
    isTabletMode,
    orderItem,
    addressesCount,
    isRecurring,
  ]);

  const totalOrdered = useMemo(
    () => getProductTotalItemsOrdered(product, orderItem, addressesCount),
    [product, orderItem, addressesCount],
  );

  return isMobileMode || isTabletMode ? (
    // list for devices
    <Box
      p={isMobileMode ? 1 : 2}
      width="100%"
      display="flex"
      flexDirection="column"
      position={'relative'}>
      <Box position="relative">
        {isRecurring && (
          <CloseComponent
            className={classes.closeIcon}
            orderItem={orderItem}
            order={order}
            product={product}
          />
        )}
        {!isMobileMode && (
          <span className={classes.audienceLabel}>{owner}</span>
        )}
      </Box>
      {!isMobileMode ? (
        <Box my={1}>
          <Typography
            style={{ cursor: 'pointer' }}
            onClick={onOpenModal}
            variant="h3"
            className={classes.productDescription}
            data-testid="order-product-name">
            {description}
          </Typography>
          <Typography variant="textSm">{sizeVers}</Typography>
        </Box>
      ) : (
        <Box position={'relative'} width={'100%'}>
          {isRatable && <Rating product={product} />}
          <Typography
            style={{ cursor: 'pointer' }}
            onClick={onOpenModal}
            className={classnames(
              classes.productDescription,
              classes.productMobileDescription,
            )}
            // save empty space for close button when order is recurring
            width={isRecurring ? 'calc(100% - 30px)' : '100%'}
            data-testid="order-product-name">
            {description}
          </Typography>
          <Typography variant="textXs">{sizeVers}</Typography>
        </Box>
      )}
      {!isMobileMode && isRatable && <Rating product={product} />}
      {!isMobileMode ? (
        <>
          <Box display={'flex'} gap={2} mt={1}>
            <Box display={'flex'} flexDirection={'column'} flexBasis="50%">
              <Box display={'flex'} className={classes.inStockQty}>
                <Typography variant="textSmSemibold" color="darkGrey">
                  Per Unit Qty: {prepack_quantity ? prepack_quantity : 'N/A'}
                </Typography>
              </Box>
              {order_limit !== null && (
                <Box display={'flex'}>
                  <Typography variant="textSmSemibold" color="darkGrey">
                    Max Allowed Qty:&nbsp;
                  </Typography>
                  <Typography variant="textSmSemibold" color="darkGrey">
                    {order_limit}
                  </Typography>
                </Box>
              )}
              <div className={classes.qtyInfoText}>
                <OrderProductQtyLabels
                  orderItem={orderItem}
                  addressesCount={addressesCount}
                  isRecurring={isRecurring}
                />
              </div>
              <Typography
                className={classnames(classes.qtyInfoText, classes.totalItems)}>
                {`Total Items Ordered: ${
                  prepack_quantity ? totalOrdered : 'N/A'
                }`}
              </Typography>
            </Box>
            <Box display={'flex'} flexDirection={'column'} flexBasis="40%">
              {isUnavailableForOrder ? (
                <OutOfStockLabel
                  label={unavailableLabel}
                  device={{ isMobileMode }}
                />
              ) : (
                <>
                  <Typography variant="textSmSemibold" color="darkGrey">
                    In Stock: {product_available_qty}
                  </Typography>
                </>
              )}
              {remainingMonthQuantity !== null &&
                remainingMonthQuantity >= 0 && (
                  <Typography>{`${remainingMonthQuantity} left in month`}</Typography>
                )}
            </Box>
            <Box flexBasis="10%" display="flex" justifyContent="end">
              {isReportable && <ReportableIcon />}
            </Box>
          </Box>
          {isRecurring && !isUnavailableForOrder && (
            <Box display="flex" flex={1} gap={2}>
              <Box flexBasis="50%"></Box>
              <Box flexBasis="50%" className={classes.buttonContainer}>
                <CountComponent
                  product={product}
                  orderItem={orderItem}
                  order={order}
                />
              </Box>
            </Box>
          )}
        </>
      ) : (
        <Box
          className={classes.infoContainer}
          display="flex"
          flexDirection="column"
          flex={1}>
          {isUnavailableForOrder ? (
            <OutOfStockLabel
              label={unavailableLabel}
              device={{ isMobileMode }}
            />
          ) : (
            <>
              <Typography className={classes.qtyInfoText}>
                In Stock: {product_available_qty}
              </Typography>
            </>
          )}
          <Typography
            className={classnames(classes.qtyInfoText, classes.totalItems)}>
            {`Total Items Ordered: ${prepack_quantity ? totalOrdered : 'N/A'}`}
          </Typography>
          {isRecurring && !isUnavailableForOrder && (
            <CountComponent
              product={product}
              orderItem={orderItem}
              order={order}
            />
          )}
        </Box>
      )}
      {!isRecurring && !isMobileMode && (
        <ApprovalLabel
          orderItemStatus={orderItem.status}
          approver={orderItem.approval?.approver}
          isModified={orderItem.qty_ordered !== orderItem.qty_approved}
          className={classes.approvalLabel}
        />
      )}
    </Box>
  ) : (
    // list for desktop
    <Box
      pt="10px"
      pl={4}
      pr={2}
      pb={2}
      width={'100%'}
      height={'100%'}
      position={'relative'}>
      {owner ? (
        <>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            mb={2}
            width={isRecurring ? 'calc(100% - 40px)' : '100%'}
            position="relative">
            <span className={classes.audienceLabel}>{owner}</span>
            {isRatable && <Rating product={product} />}
            {isRecurring && (
              <CloseComponent
                className={classes.closeIcon}
                orderItem={orderItem}
                order={order}
                product={product}
              />
            )}
            {!isRecurring && isDesktopMode && (
              <ApprovalLabel
                orderItemStatus={orderItem.status}
                approver={orderItem.approval?.approver}
                isModified={orderItem.qty_ordered !== orderItem.qty_approved}
                align={'right'}
                className={classes.approvalLabel}
              />
            )}
          </Box>
          <Box mb={2}>
            <Typography
              variant="h3"
              style={{ cursor: 'pointer' }}
              onClick={onOpenModal}
              className={classes.productDescription}
              data-testid="order-product-name">
              {description}
            </Typography>
            <Typography variant="textSm">{sizeVers}</Typography>
          </Box>
        </>
      ) : (
        <Box
          display={'flex'}
          alignItems="start"
          justifyContent={'space-between'}
          my={2}>
          <div>
            <Typography
              width={isRecurring ? 'calc(100% - 40px)' : '100%'}
              variant="h3"
              className={classes.productDescription}
              data-testid="order-product-name">
              {description}
            </Typography>
            <Typography variant="textSm">{sizeVers}</Typography>
          </div>
          {isRatable && <Rating product={product} />}
        </Box>
      )}
      <Box className={classes.productInfo} display={'flex'} gap={2}>
        <Box width="16%" className={classes.listCardQtyInfo}>
          <Typography className={classes.productInfoHeader}>
            Stock Number:
          </Typography>
          <Typography className={classes.productInfoText} mb={2}>
            {product_id}
          </Typography>
          <Typography className={classes.productInfoHeader}>
            Brand Category:
          </Typography>
          <Typography className={classes.productInfoText}>
            {prepack_quantity ? brand_category : 'N/A'}
          </Typography>
        </Box>
        <Box width="14%" className={classes.listCardQtyInfo}>
          <Typography className={classes.productInfoHeader}>
            Unit Measure:
          </Typography>
          <Typography className={classes.productInfoText} mb={2}>
            {prepack_quantity ? unit_measure : 'N/A'}
          </Typography>
          <Typography className={classes.productInfoHeader}>
            PI Required:
          </Typography>
          <Typography className={classes.productInfoText}>
            {prepack_quantity ? (pi_required ? 'Yes' : 'No') : 'N/A'}
          </Typography>
        </Box>
        <Box width="19%" className={classes.listCardQtyInfo}>
          <Box display={'flex'} className={classes.inStockQty}>
            <Typography className={classes.productInfoHeader}>
              Per Unit Qty:&nbsp;
            </Typography>
            <Typography className={classes.productInfoText}>
              {prepack_quantity ? prepack_quantity : 'N/A'}
            </Typography>
          </Box>
          {order_limit !== null && (
            <Box display={'flex'}>
              <Typography className={classes.productInfoHeader} mb={2}>
                Max Allowed Qty:&nbsp;
              </Typography>
              <Typography className={classes.productInfoText} mb={2}>
                {order_limit}
              </Typography>
            </Box>
          )}
          {!isUnavailableForOrder ? (
            <Box display={'flex'}>
              <Typography className={classes.productInfoHeader}>
                In Stock:&nbsp;
              </Typography>
              <Typography className={classes.productInfoText}>
                {product_available_qty}
              </Typography>
            </Box>
          ) : (
            <OutOfStockLabel
              label={unavailableLabel}
              device={{ isMobileMode }}
            />
          )}
          {remainingMonthQuantity !== null && remainingMonthQuantity >= 0 && (
            <Typography className={classes.productInfoHeader}>
              {`${remainingMonthQuantity} Left In Month`}
            </Typography>
          )}
        </Box>
        <Box width="24%" className={classes.qtyInfo}>
          <div className={classes.qtyInfoText}>
            <OrderProductQtyLabels
              orderItem={orderItem}
              addressesCount={addressesCount}
              isRecurring={isRecurring}
            />
          </div>
          <Typography
            className={classnames(classes.qtyInfoText, classes.totalItems)}>
            {`Total Items Ordered: ${prepack_quantity ? totalOrdered : 'N/A'}`}
          </Typography>
        </Box>
        {isRecurring && !isUnavailableForOrder && (
          <Box className={classes.buttonContainer}>
            <CountComponent
              product={product}
              orderItem={orderItem}
              order={order}
            />
          </Box>
        )}
        {isReportable && (
          <Box ml="auto">
            <ReportableIcon />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ListContent;
