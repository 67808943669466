// Lib
import { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

// Api
import http from '../../../api/http';

// Constants
import { BASE_URL, SSO_LOGIN_PAGE } from '../../../constants/urls';
import { COMMON_API_ERROR } from '../../../constants/errors';

const useUnauthorizedApprove = () => {
  const pathname = useLocation();
  const { search } = pathname;
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');
  const [approvePath, setApprovePath] = useState('');
  const [isLaunched, setIsLaunched] = useState(false);
  const navigate = useNavigate();
  const [responseType, setResponseType] = useState('');

  useEffect(() => {
    if (search) {
      setApprovePath(search.substring(1));
      navigate('/approvals/approve-all');
    }
  }, [search, navigate]);

  useEffect(() => {
    if (!search && !approvePath) {
      navigate(SSO_LOGIN_PAGE);
    }
  }, [search, approvePath, navigate]);

  const approveAll = useCallback(async () => {
    if (!approvePath) {
      return;
    }
    try {
      const result = await http.get(
        `${BASE_URL}/api/v1/orders_approvals/${approvePath}`,
      );

      const {
        approval_date,
        approver_email,
        approver_first_name,
        approver_last_name,
        status,
      } = result?.data || {};

      let approvalDate, approvalTime;

      if (['already_approved', 'approved'].includes(status)) {
        setStatus(status);
        const date = new Date(approval_date);
        approvalDate = format(date, 'yyyy-MM-dd');
        approvalTime = format(date, 'HH:mm:ss');

        setResponseType('success');
      } else {
        setResponseType('error');
        setMessage(COMMON_API_ERROR);
      }

      if (result?.data?.failed || result?.data?.detail) {
        setMessage(result?.data?.failed?.[0] || result?.data?.detail);
      }
      if (status === 'approved') {
        setMessage(
          `Approved by ${approver_first_name} ${approver_last_name} ${approver_email} on ${approvalDate} at ${approvalTime}`,
        );
      }
      if (status === 'already_approved') {
        setMessage(
          `The item(s) requiring review in this order have already been addressed by ${approver_first_name} ${approver_last_name} on ${approvalDate} at ${approvalTime}.`,
        );
      }
    } catch (e) {
      setMessage(e.data.failed[0] || COMMON_API_ERROR);
    } finally {
      setLoading(false);
    }
  }, [setMessage, approvePath]);

  useEffect(() => {
    if (approvePath && !search && !isLaunched) {
      approveAll();
      setIsLaunched(true);
    }
  }, [approvePath, search, isLaunched]);

  return { loading, message, responseType, status };
};

export default useUnauthorizedApprove;
