// Libs
import React from 'react';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import { makeStyles } from '@mui/styles';

// Utils
import { ORDER_APPROVAL_STATUSES } from '../../constants/approvals';

const useStyles = makeStyles(() => ({
  label: ({ align }) => ({
    textAlign: align,
  }),
}));

const ApprovalLabel = ({
  orderItemStatus,
  approver,
  isModified,
  align = 'left',
  className,
  ...props
}) => {
  const { WAITING_FOR_APPROVAL, APPROVED, REJECTED } = ORDER_APPROVAL_STATUSES;
  const classes = useStyles({ align });

  const getLabelText = (orderItemStatus, approver, isModified) => {
    switch (orderItemStatus) {
      case WAITING_FOR_APPROVAL:
        return 'Pending Approval';
      case REJECTED:
        return `Rejected By ${approver}`;
      case APPROVED:
        return isModified
          ? `Modified And Approved By ${approver}`
          : `Approved By ${approver}`;
    }
  };

  return (
    <Typography
      variant="textSm"
      color={'grey2'}
      className={classnames(classes.label, className)}
      {...props}>
      {getLabelText(orderItemStatus, approver, isModified)}
    </Typography>
  );
};

export default ApprovalLabel;
