// Lib
import React from 'react';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

// Components
import { ReactComponent as GileadLogo } from '../../../assets/svg/gileadLogo.svg';

// Hooks
import useUI from '../../../hooks/useUI';

// Utils
import { getCurrentYear } from '../../../utils/date';

// Assets
import kiteLogo from '../../../assets/img/kiteLogo.png';

const useStyles = makeStyles((theme) => ({
  root: {
    border: 'solid 1px #F4F4F4',
    borderBottom: 'none',
    backgroundColor: 'white',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    height: 80,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    zIndex: 1100,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 80,
    width: 1130,
  },
  rights: {
    color: '#C6CAC6',
    fontSize: 14,
    letterSpacing: 0.3,
  },
}));

const Footer = ({ isKiteUser, className }) => {
  const classes = useStyles();
  const { isDesktopMode } = useUI();

  return (
    <>
      {isDesktopMode ? (
        <footer className={classnames(classes.root, className)}>
          <div className={classes.container}>
            {isKiteUser ? (
              <img
                alt="logo"
                width={'121'}
                height={'36px'}
                src={kiteLogo}
                data-testid="kite-logo"
              />
            ) : (
              <GileadLogo />
            )}
            <div className={classes.rights}>
              {`${getCurrentYear()} Gilead Sciences, Inc. All Rights Reserved.`}
            </div>
          </div>
        </footer>
      ) : null}
    </>
  );
};

export default Footer;
