// Lib
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// Actions
import { SetCartProducts } from '../../../actions/cart';
import { UpdateRecurringOrder } from '../../../actions/orders';

// Selectors
import { forOrderSelector } from '../../../store/selectors';

const useRecurringMaterials = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const [loading, setLoading] = useState(false);
  const { forOrder, order, products } = useSelector((state) => ({
    forOrder: forOrderSelector(state),
    order: state.orders.currentOrder,
    products: state.orders.currentOrderProducts,
  }));

  useEffect(() => {
    if (!order) {
      return navigate(-1);
    }
    let orderIdQtyObj = {};

    order?.order_items.forEach((order) => {
      orderIdQtyObj[order.product] = order.qty_ordered;
    });

    const orderedProducts = products.map((product) => ({
      product,
      nums: orderIdQtyObj[product],
    }));

    dispatch(SetCartProducts(orderedProducts));
  }, [navigate, dispatch, products, order]);

  const onSave = useCallback(() => {
    const newOrder = { ...order };
    newOrder.order_items = forOrder.map(({ product, nums }) => ({
      product,
      qty_ordered: nums,
    }));
    newOrder.shipment_method = newOrder.shipment_method.id;
    newOrder.addresses = newOrder.addresses.map(({ address }) => address);
    delete newOrder.user;

    dispatch(
      UpdateRecurringOrder({
        newOrder,
        setLoading,
        backPath: `/order-history/recurring/${order.id}`,
        state: {
          updateOrderDetails: true,
        },
      }),
    );
  }, [dispatch, forOrder, order]);

  const onCancel = useCallback(() => {
    navigate(`/order-history/recurring/${orderId}`, {
      state: {
        updateOrderDetails: true,
      },
    });
  }, [navigate, orderId]);

  return { onCancel, onSave, loading };
};

export default useRecurringMaterials;
