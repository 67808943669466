// Libs
import { put, takeEvery, call, select } from 'redux-saga/effects';

// Api
import http from '../../../api/http';

// Actions
import {
  GetOrders,
  SetOrders,
  GetOrderDetails,
  SetOrderDetails,
  Reorder,
  GetRecurringOrders,
  SetRecurringOrders,
  CancelRecurringOrder,
  SkipRecurringOrder,
  UpdateRecurringOrder,
  UpdateLocalRecurringOrder,
  DeleteLocalRecurringOrder,
  UpdateCurrentOrder,
  RemoveProductFromCurrentProducts,
  UpdateNextShippingDate,
} from '../../../actions/orders';
import { SetLoading } from '../../../actions/loading';
import { ClearApiError, SetApiError } from '../../../actions/apiError';
import { CloseModal } from '../../../actions/modal';
import { SetProducts } from '../../../actions/products';

// Constants
import { ORDERS_URLS, PRODUCTS_URLS } from '../../../constants/urls';
import { COMMON_API_ERROR } from '../../../constants/errors';

// Sagas
import reorderSaga from './reorderSaga';

// Browser history
import browserHistory from '../../../browserHistory';

// Store
import { productsObjSelector } from '../../../store/selectors';

// Utils
import { modifyOrderItems } from '../utils/modifyOrderItems';
import { modifyOrders } from '../utils/modifyOrders';

export const requestProductDetails = async (productId) => {
  try {
    return (await http.get(PRODUCTS_URLS.getProductDetails(productId))).data;
  } catch (error) {
    return null;
  }
};

export const getProductInfo = (itemId) => requestProductDetails(itemId);

function* getOrdersSaga() {
  try {
    yield put(SetLoading(true));
    const response = yield call(http.get, ORDERS_URLS.getOrders);

    const { modifiedOrders, productsObj } = modifyOrders(response?.data);

    yield put(SetProducts({ obj: productsObj, ids: Object.keys(productsObj) }));
    yield put(SetOrders(modifiedOrders));
    yield put(SetLoading(false));
  } catch (e) {
    yield put(
      SetApiError({
        api: 'getOrders',
        error: COMMON_API_ERROR,
      }),
    );
  } finally {
    yield put(SetLoading(false));
  }
}

export function* getRecurringOrdersSaga() {
  try {
    yield put(SetLoading(true));
    const response = yield call(http.get, ORDERS_URLS.getRecurringOrders);
    const { modifiedOrders, productsObj } = modifyOrders(response.data);

    yield put(SetProducts({ obj: productsObj }));
    yield put(SetRecurringOrders(modifiedOrders));
  } catch (e) {
    yield put(
      SetApiError({
        api: 'getRecurringOrders',
        error: COMMON_API_ERROR,
      }),
    );
  } finally {
    yield put(SetLoading(false));
  }
}

export function* cancelRecurringOrderSaga({
  payload: { id, redirectPath, api },
}) {
  try {
    yield put(ClearApiError());
    yield call(http.delete, ORDERS_URLS.updateRecurringOrder(id));
    yield put(CloseModal());
    redirectPath && browserHistory.push(redirectPath);
    if (!redirectPath) {
      yield put(DeleteLocalRecurringOrder(id));
    }
  } catch (e) {
    yield put(SetApiError({ api, error: COMMON_API_ERROR }));
    console.log(e);
  }
}

export function* skipRecurringOrderSaga({
  payload: { id, setLoading, orderDetailsPage },
}) {
  try {
    setLoading(true);
    const data = yield call(http.post, ORDERS_URLS.skipRecurringOrder(id), {
      skip: true,
    });
    if (orderDetailsPage) {
      yield put(
        UpdateCurrentOrder({
          next_run: data.data.next_run,
          id,
          onlyNextRun: true,
        }),
      );
    } else {
      yield put(UpdateNextShippingDate({ next_run: data.data.next_run, id }));
    }
  } catch (e) {
    yield put(
      SetApiError({
        api: 'skipRecurring',
        error: COMMON_API_ERROR,
        id,
      }),
    );
    console.log(e);
  } finally {
    setLoading(false);
  }
}

export function* updateRecurringOrderSaga({
  payload: {
    newOrder,
    setLoading,
    backPath,
    updateCurrentOrder,
    updateRecurringOrders,
    api,
    productId,
    state,
  },
}) {
  try {
    yield put(ClearApiError());
    setLoading && setLoading(true);
    const order = yield call(
      http.put,
      ORDERS_URLS.updateRecurringOrder(newOrder.id),
      newOrder,
    );

    if (updateRecurringOrders) {
      yield put(UpdateLocalRecurringOrder(order.data));
    }
    yield put(CloseModal());
    if (updateCurrentOrder) {
      yield put(UpdateCurrentOrder(order.data));
    }
    if (productId) {
      yield put(RemoveProductFromCurrentProducts(productId));
    }
    backPath && browserHistory.push(backPath, state);
  } catch (e) {
    yield put(SetApiError({ api, error: COMMON_API_ERROR }));
    console.log(e);
  } finally {
    setLoading && setLoading(false);
  }
}

function* getOrderDetailsSaga({ payload }) {
  const { orderId, isRecurring } = payload;
  try {
    yield put(SetLoading(true));
    const orderDetailsResponse = isRecurring
      ? yield call(http.get, ORDERS_URLS.getRecurringOrderDetails(orderId))
      : yield call(http.get, ORDERS_URLS.getOrderDetails(orderId));

    const currentOrder = orderDetailsResponse?.data;

    const { productsObj, productIds, order_items } =
      modifyOrderItems(currentOrder);

    const allProducts = yield select(productsObjSelector);
    const newProductsObj = {
      ...allProducts,
      ...productsObj,
    };

    yield put(
      SetProducts({ obj: newProductsObj, ids: Object.keys(newProductsObj) }),
    );
    yield put(
      SetOrderDetails({
        currentOrder: { ...currentOrder, order_items },
        currentOrderProducts: productIds,
      }),
    );
  } catch (e) {
    yield put(
      SetApiError({
        api: 'getOrderDetails',
        error:
          e.response.status === 404 ? 'This order not found' : COMMON_API_ERROR,
      }),
    );
  } finally {
    yield put(SetLoading(false));
  }
}

function* ordersSaga() {
  yield takeEvery(GetOrders, getOrdersSaga);
  yield takeEvery(GetRecurringOrders, getRecurringOrdersSaga);
  yield takeEvery(GetOrderDetails, getOrderDetailsSaga);
  yield takeEvery(Reorder, reorderSaga);
  yield takeEvery(CancelRecurringOrder, cancelRecurringOrderSaga);
  yield takeEvery(SkipRecurringOrder, skipRecurringOrderSaga);
  yield takeEvery(UpdateRecurringOrder, updateRecurringOrderSaga);
}

export default ordersSaga;

export { getOrdersSaga, getOrderDetailsSaga };
