// Libs
import { call, put, takeEvery, fork, select } from 'redux-saga/effects';
import { detect } from 'detect-browser';

// Api
import http from '../../../api/http';

// Actions
import { SetLoading } from '../../../actions/loading';
import {
  GetProducts,
  SetProducts,
  GetFDMDocument,
  SetFrequentlyOrderedProducts,
  SetProductsSortedByOrder,
} from '../../../actions/products';
import { SetFilters, SaveFilters } from '../../../actions/filters';
import { SaveCatalogView } from '../../../actions/user';
import { SetApiError } from '../../../actions/apiError';

// Constants
import { PRODUCTS_URLS, USER_URLS } from '../../../constants/urls';
import { COMMON_API_ERROR } from '../../../constants/errors';
import { OTHER_FILTER_TYPE } from '../../../constants/products';

// Utils
import isProductAvailable from '../../../utils/isProductAvailable';

// Store
import { store } from '../../../store';

// Selectors
import { productsObjSelector } from '../../../store/selectors';

export const staticFilters = {
  favorites: false,
  frequentlyOrdered: false,
  available: [
    { title: 'All Orderable Materials', checked: true },
    { title: 'All Active Materials', checked: false },
    { title: 'Out Of Stock', checked: false },
    { title: 'Expired In The Last 30 Days', checked: false },
    { title: 'Withdrawn In The Last 30 Days', checked: false },
  ],
  sorting: {
    order: 'desc',
    orderBy: 'date',
    items: [
      { title: 'Date', label: 'Sort By Date' },
      { title: 'Name', label: 'Sort By Name' },
      { title: 'Orders', label: 'Sort By Orders' },
      { title: 'Rating', label: 'Sort By Rating' },
      { title: 'Stock Number', label: 'Sort By Stock Number' },
    ],
  },
};

export function* createFiltersSaga(filters) {
  try {
    yield http.post(USER_URLS.saveSettings, {
      view: 'List',
      products_filter: filters,
    });
  } catch (e) {
    console.log(e?.response?.data?.detail || e.message);
  }
}

export function* getFiltersSaga(products) {
  try {
    const userData = yield http.get(USER_URLS.getUser);

    const settings = userData?.data?.profile_settings;
    const filtersDB = settings?.products_filter;

    // Get filter options from products
    const filtersFromProducts = {
      brand_category: [],
      owner: [],
      audience: [],
      indication: [],
    };

    let brandCategoryWithOtherFilter = false;
    let audienceWithOtherFilter = false;
    let ownerWithOtherFilter = false;
    let indicationWithOtherFilter = false;

    products?.forEach((product) => {
      const { brand_category, audience, indication, owner } = product || {};

      // Brand category
      if (
        brand_category &&
        brand_category !== 'Other' &&
        !filtersFromProducts.brand_category.includes(brand_category)
      ) {
        filtersFromProducts.brand_category.push(brand_category);
      } else if (!brandCategoryWithOtherFilter) {
        brandCategoryWithOtherFilter = true;
      }

      // Owner
      if (
        owner &&
        owner !== 'Other' &&
        !filtersFromProducts.owner.includes(owner)
      ) {
        filtersFromProducts.owner.push(owner);
      } else if (!ownerWithOtherFilter) {
        ownerWithOtherFilter = true;
      }

      // Audience
      if (
        audience &&
        audience !== 'Other' &&
        !filtersFromProducts.audience.includes(audience)
      ) {
        filtersFromProducts.audience.push(audience);
      } else if (!audienceWithOtherFilter) {
        audienceWithOtherFilter = true;
      }

      // Indication
      if (
        indication &&
        indication !== 'Other' &&
        !filtersFromProducts.indication.includes(indication)
      ) {
        filtersFromProducts.indication.push(indication);
      } else if (!indicationWithOtherFilter) {
        indicationWithOtherFilter = true;
      }
    });

    if (brandCategoryWithOtherFilter) {
      filtersFromProducts.brand_category.push(OTHER_FILTER_TYPE);
    }
    if (audienceWithOtherFilter) {
      filtersFromProducts.audience.push(OTHER_FILTER_TYPE);
    }
    if (ownerWithOtherFilter) {
      filtersFromProducts.owner.push(OTHER_FILTER_TYPE);
    }
    if (indicationWithOtherFilter) {
      filtersFromProducts.indication.push(OTHER_FILTER_TYPE);
    }

    let filters;

    filters = {
      ...staticFilters,
      brand_category: filtersFromProducts?.brand_category?.map((item) => ({
        title: item,
        checked: false,
      })),
      audience: filtersFromProducts?.audience?.map((item) => ({
        title: item,
        checked: false,
      })),
      indication: filtersFromProducts?.indication?.map((item) => ({
        title: item,
        checked: false,
      })),
      owner: filtersFromProducts?.owner?.map((item) => ({
        title: item,
        checked: false,
      })),
    };

    if (!filtersDB && !settings?.address_book_filter) {
      yield createFiltersSaga(filters);
    } else {
      let mappedFilters = {};

      for (let key in filtersFromProducts) {
        mappedFilters[key] = filtersFromProducts?.[key]?.map((itemTitle) => {
          const option = filtersDB[key]?.find(
            (item) => item.title === itemTitle,
          );
          if (option) {
            return option;
          } else {
            return { title: itemTitle, checked: false };
          }
        });
      }

      filters = { ...filtersDB, ...mappedFilters };
    }

    yield put(SetFilters({ ...filters, loaded: true }));
  } catch (e) {
    throw new Error(e);
  }
}

export function* getProductsSaga() {
  try {
    yield put(SetLoading(true));
    // GET PRODUCTS
    yield fork(getAvailableProductsSortedByOrderSaga);
    const products = yield call(http.get, PRODUCTS_URLS.getProducts);
    // GET FILTERS
    const productsStateObj = yield select(productsObjSelector);
    const productsObj = { ...productsStateObj };
    const ids = [];

    products?.data.forEach((product) => {
      productsObj[product.product_id] = product || {};
      ids.push(product.product_id);
    });

    yield call(getFrequentlyOrderedProductsSaga, productsObj);
    yield getFiltersSaga(products?.data);

    yield put(SetProducts({ obj: productsObj, ids }));
  } catch (e) {
    yield put(SetApiError({ api: 'getProducts', error: COMMON_API_ERROR }));
  } finally {
    yield put(SetLoading(false));
  }
}

export function* getFrequentlyOrderedProductsSaga(productsObj) {
  try {
    const frequentlyOrderedResponse = yield call(
      http.get,
      PRODUCTS_URLS.getFrequentlyOrderedProducts,
    );
    const { data } = frequentlyOrderedResponse;
    const frequentlyOrderedProducts = data.filter((item) => {
      const product = productsObj[item.product];
      // product.product_available_qty > 0 consider case when
      // inactive value can be 1 inside isProductAvailable func.
      return isProductAvailable(product) && product.product_available_qty > 0;
    });
    yield put(
      SetFrequentlyOrderedProducts(frequentlyOrderedProducts.slice(0, 5)),
    );
  } catch (e) {
    yield put(
      SetApiError({
        api: 'getFrequentlyOrderedProducts',
        error: COMMON_API_ERROR,
      }),
    );
  }
}

export function* getAvailableProductsSortedByOrderSaga() {
  try {
    const sortedByOrderResponse = yield call(
      http.get,
      PRODUCTS_URLS.getAvailableProductsSortedByOrder,
    );
    const sortedArray = sortedByOrderResponse.data;

    const sortedObject = sortedArray.reduce((sortedObject, item) => {
      return { ...sortedObject, [item.product]: item.ordered };
    }, {});

    yield put(SetProductsSortedByOrder(sortedObject));
  } catch (e) {
    yield put(
      SetApiError({
        api: 'getAvailableProductsSortedByOrder',
        error: COMMON_API_ERROR,
      }),
    );
  }
}

export function* getFDMDocumentSaga({ payload: { id, setLoading } }) {
  let browser = detect();
  let windowRef;

  const isSafari = browser?.name?.match(/safari|ios/i);
  if (isSafari) {
    windowRef = window.open();
  }
  try {
    setLoading(true);
    const document = yield http.get(PRODUCTS_URLS.getFDMDoc(id), {
      responseType: 'arraybuffer',
    });
    const blob = new Blob([document?.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(blob);
    if (isSafari) {
      windowRef.location = fileURL;
    } else {
      window.open(fileURL);
    }
  } catch (e) {
    yield put(
      SetApiError({
        api: 'getFDMDocument',
        error: COMMON_API_ERROR,
      }),
    );
  } finally {
    setLoading(false);
  }
}

export function* saveFiltersSaga({ payload }) {
  const view = store.getState().user.catalogView;

  try {
    yield http.put(USER_URLS.saveSettings, {
      view: view === 'list' ? 'List' : 'Grid',
      products_filter: payload,
    });
  } catch (e) {
    console.log(e);
  }
}

export function* saveCatalogViewSaga({ payload }) {
  const filters = store.getState().filters;

  try {
    yield http.put(USER_URLS.saveSettings, {
      view: payload === 'list' ? 'List' : 'Grid',
      products_filter: filters,
    });
  } catch (e) {
    console.log(e);
  }
}

function* productsSaga() {
  yield takeEvery(GetProducts, getProductsSaga);
  yield takeEvery(GetFDMDocument, getFDMDocumentSaga);
  yield takeEvery(SaveFilters, saveFiltersSaga);
  yield takeEvery(SaveCatalogView, saveCatalogViewSaga);
}

export default productsSaga;
