const getRemainingQuantity = (restrictionQuantity) => {
  let quantity = null;

  if (restrictionQuantity?.restriction) {
    quantity = restrictionQuantity?.restriction - restrictionQuantity?.balance;
    quantity = quantity > 0 ? quantity : 0;
  }

  return quantity;
};

export default getRemainingQuantity;
