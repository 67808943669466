// Libs
import React, { useCallback, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Card as MuiCard,
  CardActionArea,
  CardMedia,
  CardContent,
  Box,
  Typography,
  Stack,
} from '@mui/material';
import { useDispatch } from 'react-redux';

// Components
import NewLabel from '../../../Products/Card/NewLabel';
import ReportableIcon from '../../../Products/Card/ReportableIcon';
import Image from '../../../Image';
import DetailsInfo from '../../DetailsInfo';
import CloseButton from '../../../Button/CloseButton';
import Button from '../../../Button';
import CartProductQtyButtons from '../../CartProductQtyButtons/CartProductQtyButtons';
import KitsProductQtyButtons from '../../../Kits/KitsProductQtyButtons/KitsProductQtyButtons';
import Details from '../../../Products/Details';
import Rating from '../../../Rating';

// Actions
import { OpenModal } from '../../../../actions/modal';

// Hooks
import useCart from '../../../../pages/Cart/hooks/useCart';
import useUI from '../../../../hooks/useUI';
import useUser from '../../../../hooks/useUser';
import useOverLimitProduct from '../../../../pages/Cart/hooks/useOverLimitProduct';
import useKitsProducts from '../../../../pages/Kits/hooks/useKitsProducts';
import useKitActions from '../../../../pages/Kits/hooks/useKitActions';
import useSaveForLater from '../../../../pages/Cart/hooks/useSaveForLater';
import useUnavailableForOrderProduct from '../../../../hooks/useUnavailableForOrderProduct';

// Styles
import { colors } from '../../../../styles/palette';

// Constants
import { ORDER_APPROVAL_STATUSES } from '../../../../constants/approvals';
import IconButton from '../../../Button/IconButton/IconButton';
import Feather from '../../../Feather';
import { isOverLimit } from '../../../../utils/overLimit';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
  root: ({ placedOrder }) => ({
    position: 'relative',
    borderRadius: 20,
    boxShadow: '0px 5px 15px rgba(44, 112, 172, 0.2)',
    display: 'flex',
    height: placedOrder ? 231 : 275,
    marginBottom: theme.spacing(2.5),
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      height: 298,
    },
    [theme.breakpoints.down('md')]: {
      borderRadius: 10,
      height: 136,
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        height: 298,
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        borderRadius: 10,
        height: 136,
        marginBottom: theme.spacing(2),
      }),
    },
  }),
  img: ({ isUnavailableForOrder }) => ({
    ...(isUnavailableForOrder && {
      opacity: 0.65,
    }),
  }),
  actionArea: {
    position: 'relative',
    width: 'initial',
  },
  descriptionContainer: {
    paddingRight: '23px',
    flex: 1,
    [theme.breakpoints.down('md')]: {
      flex: 0,
      paddingRight: 'unset',
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        flex: 0,
        paddingRight: 'unset',
      }),
    },
  },
  descAndSize: ({ reviewOrderPage }) => ({
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.down('lg')]: {
      marginBottom: 4,
      marginTop: 4,
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(0.5),
      ...(reviewOrderPage && {
        marginBottom: 0,
        marginTop: 0,
      }),
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
      marginTop: 0,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        marginBottom: 4,
        marginTop: 4,
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        marginBottom: theme.spacing(0.5),
        ...(reviewOrderPage && {
          marginBottom: 0,
          marginTop: 0,
        }),
      }),
    },
  }),
  description: {
    cursor: 'pointer',
    fontSize: 18,
    fontWeight: 700,
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 12,
      }),
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: '16px 16px 30px 30px !important',
    width: '100%',
    minWidth: 150,
    [theme.breakpoints.down('lg')]: {
      gap: theme.spacing(1),
      padding: '16px 16px 40px 20px !important',
    },
    [theme.breakpoints.down('md')]: {
      gap: theme.spacing(0.5),
      padding: '8px !important',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        gap: theme.spacing(1),
        padding: '16px 16px 40px 20px !important',
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        gap: theme.spacing(0.5),
        padding: '8px !important',
      }),
    },
  },
  iconsContainer: {
    paddingRight: '23px',
    [theme.breakpoints.down('md')]: {
      paddingRight: 0,
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        paddingRight: 0,
      }),
    },
  },
  label: {
    backgroundColor: '#F4F4F4',
    borderRadius: 4,
    color: '#54565B',
    fontSize: 14,
    fontWeight: 600,
    padding: 4,
  },
  removeButton: {
    fontSize: 22,
    position: 'initial',
    zIndex: 'initial',
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      marginLeft: 'auto',
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 14,
        marginLeft: 'auto',
      }),
    },
  },
  saveLater: {
    backgroundColor: '#F4F4F4',
    fontSize: 12,
    padding: '5px 10px',
    position: 'absolute',
    width: '100px',
    height: '30px',
    right: '75px',
    top: '16px',
    '&:hover': {
      backgroundColor: '#c6cac6 !important',
    },
    [theme.breakpoints.down('lg')]: {
      padding: '2px 10px',
      height: '24px',
      left: 'calc(298px + 20px + 0.6 * (100% - (298px + 20px + 23px + 16px)))',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 10,
      position: 'relative',
      width: '74px',
      height: '20px',
      left: 'auto',
      right: 'unset',
      top: 'unset',
      padding: '0',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        padding: '2px 10px',
        height: '24px',
        left: 'calc(298px + 20px + 0.6 * (100% - (298px + 20px + 23px + 16px)))',
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: '10px !important',
        position: 'relative',
        width: '74px',
        height: '20px',
        left: 'auto',
        right: 'unset',
        top: 'unset',
        padding: '0',
      }),
    },
  },
  totalItemsContainer: ({ reviewOrderPage }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '23px',
    [theme.breakpoints.down('lg')]: {
      alignItems: 'center',
      justifyContent: 'flex-start',
      paddingRight: 'unset',
      columnGap: 'calc(60% - 130px - 13px)',
      '& #count-button': {
        width: 130,
        paddingTop: '6px',
        paddingBottom: '6px',
      },
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      alignItems: 'initial',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
      columnGap: 0,
      '& #count-button': {
        padding: '1px 6px',
        width: 95,
      },
      '& > div:nth-child(2)': {
        fontSize: 12,
        order: 0,
      },
      '& > div:nth-child(1)': {
        order: 1,
      },
      ...(reviewOrderPage && {
        alignItems: 'initial',
        flexDirection: 'column',
        '& #count-button': {
          padding: '1px 6px',
          width: 95,
        },
        flex: 1,
        '& > div:nth-child(2)': {
          order: 0,
        },
        '& > div:nth-child(1)': {
          order: 1,
        },
      }),
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingRight: 'unset',
        columnGap: 'calc(60% - 130px - 13px)',
        '& #count-button': {
          width: 130,
          paddingTop: '6px',
          paddingBottom: '6px',
        },
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 12,
        alignItems: 'initial',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
        columnGap: 0,
        '& #count-button': {
          padding: '1px 6px',
          width: 95,
        },
        '& > div:nth-child(2)': {
          fontSize: 12,
          order: 0,
        },
        '& > div:nth-child(1)': {
          order: 1,
        },
        ...(reviewOrderPage && {
          alignItems: 'initial',
          flexDirection: 'column',
          '& #count-button': {
            padding: '1px 6px',
            width: 95,
          },
          flex: 1,
          '& > div:nth-child(2)': {
            order: 0,
          },
          '& > div:nth-child(1)': {
            order: 1,
          },
        }),
      }),
    },
  }),
  qtyLabels: ({ isKiteUser, reviewOrderPage }) => ({
    color: isKiteUser ? theme.palette.primary1 : theme.palette.primary2,
    fontSize: 14,
    fontWeight: 600,
    [theme.breakpoints.down('lg')]: {
      flexBasis: '40%',
    },
    [theme.breakpoints.down('md')]: {
      flexBasis: 'initial',
      fontSize: reviewOrderPage ? 10 : 12,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        flexBasis: '40%',
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        flexBasis: 'initial',
        fontSize: reviewOrderPage ? 10 : 12,
      }),
    },
  }),
  totalItems: () => ({
    color: theme.palette.red,
  }),
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& #rating': {
      marginRight: '20px',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        width: '100%',
        justifyContent: 'space-between',
      }),
    },
  },
  approvalInfo: {
    color: theme.palette.grey2,
    fontSize: 14,
    position: 'absolute',
    right: 0,
    top: theme.spacing(3.5),
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      bottom: 8,
      right: 'auto',
      top: 'auto',
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 12,
        bottom: 8,
        right: 'auto',
        top: 'auto',
      }),
    },
  },
  labels: {
    display: 'flex',
    columnGap: theme.spacing(1),
    position: 'absolute',
    left: theme.spacing(2),
    top: theme.spacing(2),
    zIndex: 10,
    [theme.breakpoints.down('md')]: {
      columnGap: theme.spacing(0.75),
      left: theme.spacing(1.25),
      top: theme.spacing(1.25),
    },
  },
}));

const CartCard = ({
  product,
  reviewOrderPage,
  kitDetailsPage,
  placedOrder,
  addressesCount = 1,
  kit,
  nums,
  disabledCountBtn = false,
  onClearOverLimitInDB = () => {},
  onSaveOverLimitInDB = () => {},
}) => {
  const { isKiteUser } = useUser();
  const dispatch = useDispatch();
  const { isDesktopMode, isTabletMode, isMobileMode } = useUI();
  const {
    count,
    isProductMoreThanInStock,
    addOne,
    removeOne,
    setCount,
    onDeleteProduct,
  } = useCart(product?.product_id);

  const removeOneProduct = useCallback(
    (product) => () => {
      removeOne(product)();
      if (!isOverLimit(product, count - 1, 1)) {
        onClearOverLimitInDB(product);
      }
    },
    [removeOne, count, onClearOverLimitInDB],
  );

  const addOneProduct = useCallback(
    (product) => () => {
      if (isOverLimit(product, count + 1, 1)) {
        onSaveOverLimitInDB(product);
      }
      addOne(product)();
    },
    [addOne, count, onSaveOverLimitInDB],
  );

  const { saveForLater } = useSaveForLater();
  const { isOverLimitProduct } = useOverLimitProduct(product?.product_id);
  const { removeProductFromKit } = useKitsProducts();
  const { onDeleteClick } = useKitActions(kit);
  const { isUnavailableForOrder, unavailableLabel, unavailableWatermark } =
    useUnavailableForOrderProduct(product);

  const {
    owner,
    image_link: image,
    product_description: description,
    product_id: stockNumber,
    product_available_qty: availableQuantity,
    prepack_quantity: perUnitQty,
    is_new: isNew,
    rating: isRatable,
    reportable: isReportable,
    size_vers: sizeVers,
  } = product || {};

  const classes = useStyles({
    isUnavailableForOrder,
    isKiteUser,
    reviewOrderPage,
    placedOrder: !!placedOrder?.id,
  });

  const isRecurring = placedOrder && placedOrder.schedule;

  const pendingApproval = useMemo(() => {
    if (isRecurring) {
      return false;
    } else {
      const item = placedOrder?.order_items?.find(
        // item.product.product_id can be just item.product
        // after backend unifying recurring and ordinary order
        // properties
        (item) => item.product.product_id === stockNumber,
      );

      if (
        item &&
        item.status === ORDER_APPROVAL_STATUSES.WAITING_FOR_APPROVAL
      ) {
        return true;
      } else {
        return false;
      }
    }
  }, [isRecurring, placedOrder, stockNumber]);

  const orderedProduct = useMemo(
    () =>
      placedOrder?.order_items?.find((item) => {
        // TODO: unify for ordinary and recurring on the back.
        if (isRecurring) {
          return item.product === stockNumber;
        } else {
          return item.product.product_id === stockNumber;
        }
      }),
    [isRecurring, placedOrder?.order_items, stockNumber],
  );

  const onOpenModal = useCallback(() => {
    dispatch(
      OpenModal({
        Component: () => (
          <Details
            productId={product.product_id}
            addOneProduct={addOneProduct}
            removeOneProduct={removeOneProduct}
          />
        ),
        id: kit
          ? 'kits-product-details'
          : !!placedOrder?.id
          ? 'placed-order-details'
          : 'cart-product-details',
        props: {
          showRequestModal:
            !isProductMoreThanInStock &&
            window?.location?.pathname?.includes('cart'),
          withMyRating: reviewOrderPage ? !!placedOrder.id : true,
          isOverLimit: isOverLimitProduct,
          productId: stockNumber,
          isFullScreen: isTabletMode,
          paddingContent: {
            xl: '50px 40px 40px',
            lg: '60px 28px 38px',
            md: '40px 12px 16px',
          },
        },
        styles: {
          width: 'min(90vw, 1143px)',
        },
      }),
    );
  }, [
    dispatch,
    kit,
    placedOrder?.id,
    isProductMoreThanInStock,
    reviewOrderPage,
    isOverLimitProduct,
    stockNumber,
    isTabletMode,
    removeOneProduct,
    addOneProduct,
  ]);

  const saveForLaterButton = useMemo(
    () => (
      <Button
        data-testid="save-for-later-btn"
        className={classes.saveLater}
        variant="text"
        onClick={() => saveForLater(product, count)}>
        Save For Later
      </Button>
    ),
    [classes.saveLater, count, product, saveForLater],
  );

  const renderRatingOrSaveForLater = useCallback(() => {
    if (kitDetailsPage && !isRatable) {
      return;
    }
    if (
      kit ||
      (reviewOrderPage && (isDesktopMode || isMobileMode) && isRatable)
    ) {
      return (
        <Rating
          withTooltip={reviewOrderPage ? !!placedOrder?.id : true}
          product={product}
        />
      );
    }
    if (!reviewOrderPage && isMobileMode) {
      return saveForLaterButton;
    }
  }, [
    reviewOrderPage,
    kitDetailsPage,
    isDesktopMode,
    isMobileMode,
    kit,
    saveForLaterButton,
    product,
    isRatable,
    placedOrder,
  ]);

  const renderDeleteButton = useCallback(() => {
    const onDeleteKitProduct = () => {
      if (kit?.products.length === 1) {
        // Remove this kit from kits.
        onDeleteClick(kit?.id);
      } else {
        removeProductFromKit(kit?.id, product?.product_id);
      }
    };

    if (!placedOrder?.id) {
      if (kit) {
        return (
          <CloseButton
            onClose={onDeleteKitProduct}
            className={classes.removeButton}
            data-testid="kit-product-delete-button"
          />
        );
      } else if (window.location.pathname?.includes('review-order')) {
        return (
          <CloseButton
            onClose={onDeleteProduct}
            className={classes.removeButton}
            data-testid="order-product-delete-button"
          />
        );
      } else {
        return (
          <IconButton
            onClick={onDeleteProduct}
            startIcon={<Feather type="delete" />}
            styles={{ marginTop: '3px' }}
            data-testid="cart-product-delete-button"
          />
        );
      }
    }
  }, [
    classes.removeButton,
    kit,
    onDeleteClick,
    onDeleteProduct,
    placedOrder?.id,
    product?.product_id,
    removeProductFromKit,
  ]);

  const totalItemsHeader = useMemo(() => {
    if (reviewOrderPage && isDesktopMode) {
      return 'Total Items Per Order';
    }
    if (reviewOrderPage) {
      return 'Items Per Order';
    }
    return 'Total Items';
  }, [reviewOrderPage, isDesktopMode]);

  return (
    <MuiCard disabled={!image} className={classes.root}>
      <div onClick={(e) => e.stopPropagation()} className={classes.labels}>
        {isNew && <NewLabel positionStatic />}
      </div>
      <CardActionArea
        data-testid="card-action-area"
        className={classes.actionArea}
        onClick={onOpenModal}>
        {unavailableWatermark}
        <Image
          alt="productImage"
          imageContainer={{
            desktop: placedOrder?.id ? [230] : [275],
            tablet: [298],
            mobile: [113],
          }}
          src={image}>
          <CardMedia
            id="image-button"
            className={classes.img}
            component="img"
            image={image}
            alt="productImage"
          />
        </Image>
      </CardActionArea>
      <CardContent className={classes.content}>
        <Box
          position="relative"
          display="flex"
          alignItems="start"
          justifyContent="space-between"
          className={classes.iconsContainer}>
          {!isMobileMode && <div className={classes.label}>{owner}</div>}
          <div className={classes.buttons}>
            {renderRatingOrSaveForLater()}
            {renderDeleteButton()}
          </div>
          {placedOrder?.id && isDesktopMode && pendingApproval && (
            <div className={classes.approvalInfo}>Pending Approval</div>
          )}
        </Box>
        <div className={classes.descriptionContainer}>
          <Stack
            className={classes.descAndSize}
            direction="row"
            justifyContent="space-between">
            <div>
              <div onClick={onOpenModal} className={classes.description}>
                {description}
              </div>
              <Typography variant="textSm">{sizeVers}</Typography>
            </div>
            {isReportable && (
              <Box alignContent="end" ml={1}>
                <ReportableIcon />
              </Box>
            )}
          </Stack>
          {reviewOrderPage && isTabletMode && !isMobileMode && isRatable && (
            <Rating
              withTooltip={reviewOrderPage ? placedOrder?.id : true}
              product={product}
            />
          )}
          <DetailsInfo
            pendingApproval={pendingApproval}
            orderedProduct={orderedProduct}
            isUnavailableForOrder={isUnavailableForOrder}
            product={product}
            cartProps={{
              count,
              addOne: addOneProduct,
              removeOne: removeOneProduct,
              setCount,
            }}
            isOverLimit={isOverLimitProduct}
            kit={kit}
            nums={nums}
            placedOrder={placedOrder}
            unavailableLabel={unavailableLabel}
            shippingAddresses={addressesCount}
          />
        </div>

        <div className={classes.totalItemsContainer}>
          {isTabletMode &&
            !placedOrder?.id &&
            kit &&
            !isUnavailableForOrder && (
              <KitsProductQtyButtons
                product={product}
                isMobileMode={isMobileMode}
                nums={nums}
                detailsMode={false}
                listView={true}
                className={classes.qtyButtons}
              />
            )}
          {isTabletMode && !placedOrder?.id && !kit && (
            <CartProductQtyButtons
              isOverLimit={isOverLimitProduct}
              product={product}
              onAdd={addOneProduct(product)}
              onRemove={removeOneProduct(product)}
              onSetCount={setCount}
              count={count}
              isMobileMode={isMobileMode}
              detailsMode={false}
              className={classes.qtyButtons}
              disabledCountBtn={disabledCountBtn}
              shippingAddresses={addressesCount}
            />
          )}
          {placedOrder?.id ? (
            isMobileMode && (
              <div>
                <div className={classes.qtyLabels}>{`Order Qty${
                  pendingApproval ? '(Requested)' : ''
                }: ${orderedProduct?.qty_ordered * addressesCount}`}</div>
                <div
                  className={classnames(
                    classes.qtyLabels,
                    classes.totalItems,
                  )}>{`Total Ordered: ${
                  orderedProduct?.qty_ordered * perUnitQty * addressesCount
                }`}</div>
                {pendingApproval && (
                  <div className={classes.approvalInfo}>Pending Approval</div>
                )}
              </div>
            )
          ) : (
            <div>
              {isMobileMode &&
                (reviewOrderPage || kit) &&
                (!isUnavailableForOrder ? (
                  <div
                    className={
                      classes.qtyLabels
                    }>{`In stock: ${availableQuantity}`}</div>
                ) : (
                  <span
                    style={{ color: colors.gilead.primary1, fontWeight: 700 }}>
                    {unavailableLabel}
                  </span>
                ))}
              {!kit && (
                <div
                  className={classnames(classes.qtyLabels, classes.totalItems)}
                  data-testid="product-card-total-items">{`${totalItemsHeader}: ${
                  perUnitQty * count * addressesCount
                }`}</div>
              )}
              {reviewOrderPage && addressesCount > 1 && (
                <div className={classes.qtyLabels}>{`${
                  isDesktopMode
                    ? 'Total Units In Distribution'
                    : 'Units In Distro'
                }: ${count * addressesCount}`}</div>
              )}
            </div>
          )}
        </div>
      </CardContent>
      {!reviewOrderPage && !kit && !isMobileMode && saveForLaterButton}
    </MuiCard>
  );
};

export default CartCard;
