// Lib
import React, { useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';

// Components
import Header from './Header';
import Footer from './Footer';

// Constants
import USER_TYPE from '../../constants/user';

// Hooks
import useUser from '../../hooks/useUser';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    minHeight: 'fit-content',
    maxWidth: 1180,
    margin: '0 auto',
    padding: `${theme.spacing(5)} 26px ${theme.spacing(14)}`,
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      minHeight: 'auto',
      paddingBottom: theme.spacing(5),
    },
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(2.5)} ${theme.spacing(1.5)}`,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        minHeight: 'auto',
        paddingBottom: theme.spacing(5),
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        padding: `${theme.spacing(2.5)} ${theme.spacing(1.5)}`,
      }),
    },
  },
}));

const Layout = ({
  children,
  className,
  renderComponent,
  hideHeaderLinks,
  searchResults = [],
  onChangeSearch,
  onlyContent = false,
  title = '',
}) => {
  const { userType, isLoggedIn } = useUser();
  const { pathname } = useLocation();
  const classes = useStyles();
  const isKiteUser = userType === USER_TYPE.kite;

  const isAuthPage = useMemo(
    () => pathname === '/auth' || pathname === '/auth/',
    [pathname],
  );

  return (
    <>
      <Header
        title={title}
        onlyContent={onlyContent}
        isKiteUser={isKiteUser}
        isAuthPage={isAuthPage}
        isLoggedIn={isLoggedIn}
        onlyLogoLink={hideHeaderLinks}
        searchResults={searchResults}
        onChangeSearch={onChangeSearch}
      />
      {renderComponent && renderComponent()}
      <div className={classnames(classes.root, className?.layout)}>
        {children}
      </div>
      {!isAuthPage && !onlyContent && (
        <Footer isKiteUser={isKiteUser} className={className?.footer} />
      )}
    </>
  );
};

export default Layout;
