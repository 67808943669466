// Lib
import React from 'react';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

// Components
import { ReactComponent as List } from '../../../assets/svg/list.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    fill: theme.palette.primary1,
    cursor: 'pointer',

    '& rect': {
      fill: theme.palette.primary1,
    },

    '& rect:first-child': {
      fill: theme.palette.lightGrey,
    },

    '&:hover': {
      '& rect': {
        fill: theme.palette.lightGrey,
      },
      '& rect:first-child': {
        fill: theme.palette.primary1,
      },
    },
  },
  active: {
    '& rect': {
      fill: theme.palette.lightGrey,
    },
    '& rect:first-child': {
      fill: theme.palette.primary1,
    },
  },
}));

const ListButton = ({ isActive, styles, ...props }) => {
  const classes = useStyles();

  return (
    <List
      id="list-button"
      className={
        isActive ? classnames(classes.active, classes.root) : classes.root
      }
      style={styles}
      {...props}
    />
  );
};

export default ListButton;
