// Lib
import React from 'react';
import { makeStyles } from '@mui/styles';

// Hooks
import useUser from '../../../hooks/useUser';

const useStyles = makeStyles((theme) => ({
  label: ({ isKiteUser, positionStatic }) => ({
    height: 24,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.lightGrey,
    borderRadius: 4,
    color: isKiteUser ? theme.palette.primary2 : theme.palette.primary1,
    fontSize: 14,
    fontWeight: isKiteUser ? 400 : 600,
    padding: `${theme.spacing(0.5)} ${theme.spacing(0.8)}`,
    position: positionStatic ? 'static' : 'absolute',
    left: theme.spacing(2),
    top: theme.spacing(2),
    zIndex: 10,
    [theme.breakpoints.down('md')]: {
      height: 20,
      fontSize: 12,
      lineHeight: '11px',
      left: theme.spacing(1),
      top: theme.spacing(1),
    },
  }),
}));

const NewLabel = ({ positionStatic = false }) => {
  const { isKiteUser } = useUser();

  const classes = useStyles({ isKiteUser, positionStatic });

  return (
    <div data-testid="new-label" className={classes.label}>
      New
    </div>
  );
};

export default NewLabel;
