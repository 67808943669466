// Lib
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { SetShippingDetails } from '../../../actions/cart';
import { UpdateRecurringOrder } from '../../../actions/orders';
import { ClearApiError } from '../../../actions/apiError';

// Initial state
import { initialState } from '../../../reducers/cart';

// Constants
import { DAY_NUMBERS, SHIPPING_SCHEDULES } from '../../../constants/cart';

// Utils
import isDisabledSavingRecurringSchedule from '../../../utils/isDisabledSavingRecurringSchedule';

const useChangingRecurrence = (order, orderDetailsPage) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [monthError, setMonthError] = useState();
  const { shippingDetails, apiError } = useSelector((state) => ({
    shippingDetails: state.cart.shippingDetails,
    apiError: state.apiError['update-recurrence'],
  }));

  useEffect(() => {
    dispatch(ClearApiError());
  }, [dispatch, shippingDetails]);

  const isSaveBtnDisabled = useMemo(() => {
    return isDisabledSavingRecurringSchedule(shippingDetails, monthError);
  }, [shippingDetails, monthError]);

  useEffect(() => {
    if (!order) {
      return;
    }

    const type = order.schedule.type.toLowerCase();

    dispatch(
      SetShippingDetails({
        type: 'schedule',
        value: SHIPPING_SCHEDULES.find(({ label }) =>
          label.toLowerCase().includes(type),
        ).value,
      }),
    );

    if (type === 'weekly') {
      dispatch(
        SetShippingDetails({
          type,
          value: {
            days: shippingDetails.weekly.days.map((item) => {
              if (order.schedule.days_week.includes(item.title)) {
                return { ...item, checked: true };
              }
              return item;
            }),
            monthNumber: order.schedule.frequency,
          },
        }),
      );
      dispatch(
        SetShippingDetails({
          type: 'monthly',
          value: initialState.shippingDetails.monthly,
        }),
      );
    }
    if (type === 'monthly') {
      dispatch(
        SetShippingDetails({
          type: 'weekly',
          value: initialState.shippingDetails.weekly,
        }),
      );
      if (order?.schedule?.days_week?.length) {
        dispatch(
          SetShippingDetails({
            type,
            value: {
              ...shippingDetails.monthly,
              monthDay: {
                ...initialState.shippingDetails.monthly.monthDay,
                checked: false,
              },
              weekDay: {
                ...shippingDetails.monthly.weekDay,
                checked: true,
                monthNumber: order.schedule.frequency,
                dayNumber: DAY_NUMBERS.find(
                  ({ digit }) => digit === order?.schedule?.day_number_in_month,
                )?.title,
                weekDay: order.schedule.days_week[0],
              },
            },
          }),
        );
      } else {
        dispatch(
          SetShippingDetails({
            type,
            value: {
              ...initialState.shippingDetails.monthly,
              monthDay: {
                ...shippingDetails.monthly.monthDay,
                checked: true,
                monthNumber: order.schedule.frequency,
                dayNumber: order.schedule.day_month,
              },
            },
          }),
        );
      }
    }
  }, [dispatch, order]);

  const onSave = useCallback(() => {
    const type = shippingDetails.schedule.toLowerCase().includes('weekly')
      ? 'weekly'
      : 'monthly';
    const newSchedule = shippingDetails[type];
    let newOrder = { ...order };
    newOrder.shipment_method = newOrder.shipment_method.id;
    newOrder.addresses = newOrder.addresses.map(({ address }) => address);
    delete newOrder.user;

    if (type === 'weekly') {
      newOrder.schedule = {
        ...order.schedule,
        day_month: null,
        days_week: newSchedule.days
          .filter(({ checked }) => checked)
          .map(({ title }) => title),
        frequency: newSchedule.monthNumber,
        type: type.toUpperCase(),
        day_number_in_month: null,
      };
    } else {
      const isWeekdayChecked = shippingDetails.monthly.weekDay.checked;
      newOrder.schedule = {
        ...order.schedule,

        frequency: isWeekdayChecked
          ? shippingDetails.monthly.weekDay.monthNumber
          : shippingDetails.monthly.monthDay.monthNumber,
        day_month: !isWeekdayChecked
          ? shippingDetails.monthly.monthDay?.dayNumber
          : null,
        days_week: isWeekdayChecked
          ? [shippingDetails.monthly.weekDay?.weekDay]
          : [],
        day_number_in_month: isWeekdayChecked
          ? DAY_NUMBERS.find(
              ({ title }) =>
                title === shippingDetails.monthly.weekDay?.dayNumber,
            )?.digit
          : null,
        type: type.toUpperCase(),
      };
    }
    dispatch(
      UpdateRecurringOrder({
        newOrder,
        setLoading,
        updateCurrentOrder: orderDetailsPage,
        updateRecurringOrders: !orderDetailsPage,
        api: 'update-recurrence',
      }),
    );
  }, [dispatch, shippingDetails, order, orderDetailsPage]);

  const onChangeRadio = useCallback(
    ({ target }) => {
      dispatch(SetShippingDetails({ type: 'schedule', value: target.value }));
    },
    [dispatch],
  );

  return {
    onChangeRadio,
    setMonthError,
    isSaveBtnDisabled,
    onSave,
    loading,
    apiError,
    monthError,
  };
};

export default useChangingRecurrence;
