const PRODUCTS_COLUMNS_PART_1 = [
  { id: 'productDescription', label: 'Product description' },
  { id: 'stockNumber', label: 'Stock number' },
  { id: 'manager', label: 'Product Manager' },
  { id: 'unitOfMeasure', label: 'Unit of measure' },
  { id: 'perPackQty', label: 'Per pack Qty' },
  { id: 'orderQty', label: 'Order Qty' },
];

const PRODUCTS_COLUMNS_PART_2 = [
  { id: 'maxOrderQty', label: 'Max order Qty' },
  { id: 'monthlyLimit', label: 'Monthly limit' },
  { id: 'annualLimit', label: 'Annual limit' },
  { id: 'action', label: 'PM action' },
];

export const ORDER_APPROVAL_STATUSES = {
  APPROVAL_IS_NOT_REQUIRED: 'APPROVAL IS NOT REQUIRED',
  WAITING_FOR_APPROVAL: 'WAITING FOR APPROVAL',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  CANCELED_FROM_VERACORE: 'CANCELED FROM VERACORE',
};

export const PRODUCTS_COLUMNS = (isDistro, isMethodPage) => {
  let columns = [...PRODUCTS_COLUMNS_PART_1, ...PRODUCTS_COLUMNS_PART_2];
  if (isDistro) {
    columns = [
      ...PRODUCTS_COLUMNS_PART_1,
      { id: 'totalQty', label: 'Total Qty' },
      ...PRODUCTS_COLUMNS_PART_2,
    ];
  }
  if (isMethodPage) {
    columns.pop();
    return columns;
  }
  return columns;
};
