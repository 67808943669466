// Lib
import { createAction } from '@reduxjs/toolkit';

export const GetOrders = createAction('GET_ORDERS');
export const GetRecurringOrders = createAction('GET_RECURRING_ORDERS');
export const CancelRecurringOrder = createAction('CANCEL_RECURRING_ORDER');
export const SkipRecurringOrder = createAction('SKIP_RECURRING_ORDER');
export const UpdateRecurringOrder = createAction('UPDATE_RECURRING_ORDER');
export const UpdateLocalRecurringOrder = createAction(
  'UPDATE_LOCAL_RECURRING_ORDER',
);
export const DeleteLocalRecurringOrder = createAction(
  'DELETE_LOCAL_RECURRING_ORDER',
);
export const UpdateCurrentOrder = createAction('UPDATE_CURRENT_ORDER');
export const UpdateNextShippingDate = createAction('UPDATE_NEXT_SHIPPING_DATE');
export const RemoveProductFromCurrentProducts = createAction(
  'REMOVE_PRODUCT_FROM_CURRENT_PRODUCTS',
);
export const SetOrders = createAction('SET_ORDERS');
export const SetRecurringOrders = createAction('SET_RECURRING_ORDERS');
export const ClearOrders = createAction('CLEAR_ORDERS');
export const GetOrderDetails = createAction('GET_ORDER_DETAILS');
export const SetOrderDetails = createAction('SET_ORDER_DETAILS');
export const ClearOrderDetails = createAction('CLEAR_ORDER_DETAILS');
export const Reorder = createAction('REORDER');
export const SetReorderProcessing = createAction('SET_REORDER_PROCESSING');
export const SetOrderConfirmation = createAction('SET_ORDER_CONFIRMATION');
export const UpdateOrderViaSocket = createAction('UPDATE_ORDER_VIA_SOCKET');
export const ClearReorder = createAction('CLEAR_REORDER');
export const SetRecurringOrderProductQty = createAction(
  'SET_RECURRING_ORDER_PRODUCT_QTY',
);
