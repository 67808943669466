// Lib
import { createAction } from '@reduxjs/toolkit';

export const GetAddressBook = createAction('GET_ADDRESS_BOOK');
export const SetAddressBook = createAction('SET_ADDRESS_BOOK');
export const SaveAddress = createAction('SAVE_ADDRESS');
export const SaveImportedAddresses = createAction('SAVE_IMPORTED_ADDRESSES');
export const AddAddress = createAction('ADD_ADDRESS');
export const EditAddress = createAction('EDIT_ADDRESS');
export const ChangeBookView = createAction('CHANGE_BOOK_VIEW');
export const DeleteAddress = createAction('DELETE_ADDRESS');
export const DeleteAddressInState = createAction('DELETE_ADDRESS_IN_STATE');
export const MakeMainAddress = createAction('MAKE_MAIN_ADDRESS');
export const ValidateAddress = createAction('VALIDATE_ADDRESS');
export const SetValidatedAddress = createAction('SET_VALIDATED_ADDRESS');
export const ApplyAddressFilters = createAction('APPLY_ADDRESS_FILTERS');
export const UpdateAddressesPage = createAction('UPDATE_ADDRESSES_PAGE');
export const ChangAddressBookFilter = createAction(
  'CHANGE_ADDRESS_BOOK_FILTER',
);
export const SetSearchResults = createAction('SET_SEARCH_RESULTS');
export const SetEnteredAddress = createAction('SET_ENTERED_ADDRESS');
export const SetAllCountries = createAction('SET_ALL_COUNTRIES');
export const SetUSAStates = createAction('SET_USA_STATES');
export const SaveAddressBookFilters = createAction('SAVE_ADDRESS_BOOK_FILTERS');
export const SetImportedAddressFile = createAction('SET_IMPORTED_ADDRESS_FILE');
export const UploadImportedAddressFile = createAction(
  'UPLOAD_IMPORTED_ADDRESS_FILE',
);
export const SetImportedAddresses = createAction('SET_IMPORTED_ADDRESSES');
export const SetImportedAddress = createAction('SET_IMPORTED_ADDRESS');
export const RemoveImportedAddress = createAction('REMOVE_IMPORTED_ADDRESS');
export const EditImportedAddress = createAction('EDIT_IMPORTED_ADDRESS');
export const SetUploadingProgressValue = createAction(
  'SET_UPLOADING_PROCESS_VALUE',
);
