import React from 'react';
import { Box, Typography } from '@mui/material';
export default function EmptyResultPage() {
  return (
    <Box
      height={'70vh'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}>
      <Typography variant={'h1'}>The product cannot be found</Typography>
      <Typography variant={'textLgSemibold'}>
        Please check that the Stock Number is correct
      </Typography>
    </Box>
  );
}
