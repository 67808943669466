// Lib
import { createReducer } from '@reduxjs/toolkit';

// Actions
import {
  AddToStateCart,
  AddOne,
  RemoveOne,
  SetCount,
  SetCartProducts,
  DeleteProductInState,
  DeleteAllProductsInState,
  SetOverLimitRequest,
  SetMinLengthOverLimitRequest,
  SetShippingDetails,
  SetPlacedOrder,
  DeleteUnavailableProducts,
  ClearCart,
  ClearOrderInfo,
  AddShippingAddress,
  SetShippingAddresses,
  ClearShippingAddresses,
  ClearShippingDetails,
  ClearShippingDetailsComment,
  DeleteShippingAddress,
  SetSaveForLaterProducts,
  SetUnavailableLaterProducts,
  UpdateSaveForLaterProducts,
  SetProductsForRate,
  UpdateProductRate,
  SetOverLimitProducts,
  SetCachedCountInCart,
  ClearForOrder,
  ClearEvent,
  DeleteAllCachedCountInCart,
} from '../actions/cart';
import { UserLogOut } from '../actions/user';

export const initialState = {
  placedOrder: {},
  productsOverLimit: [],
  minLengthOverLimitRequest: 2,
  overLimitRequest: '',
  forOrder: null,
  forOrderCached: {},
  forLater: [],
  unavailableLaterProducts: [],
  forRate: [],
  shippingAddresses: [],
  shippingDetails: {
    comment: '',
    methods: [],
    schedule: 'Ship Right Now',
    method: {
      name: '',
      enabled: true,
      id: '',
      escalation: false,
    },
    shippingRequest: '',
    date: '',
    event: {
      name: '',
      boothNumber: '',
      file: {
        data: null,
        name: '',
      },
    },
    weekly: {
      days: [
        { title: 'Monday', checked: false },
        { title: 'Tuesday', checked: false },
        { title: 'Wednesday', checked: false },
        { title: 'Thursday', checked: false },
        { title: 'Friday', checked: false },
      ],
      monthNumber: 1,
    },
    monthly: {
      monthDay: {
        checked: true,
        dayNumber: '',
        monthNumber: 1,
      },
      weekDay: {
        checked: false,
        dayNumber: '',
        weekDay: '',
        monthNumber: 1,
      },
    },
  },
};

export default createReducer(initialState, {
  [SetCartProducts]: (state, { payload }) => {
    return {
      ...state,
      forOrder: [...payload],
    };
  },
  [SetProductsForRate]: (state, { payload }) => ({
    ...state,
    forRate: payload,
  }),
  [SetOverLimitProducts]: (state, { payload }) => ({
    ...state,
    productsOverLimit: payload,
  }),
  [SetCachedCountInCart]: (state, { payload }) => ({
    ...state,
    forOrderCached: {
      ...state.forOrderCached,
      [payload.id]: payload.count,
    },
  }),
  [DeleteAllCachedCountInCart]: (state) => ({
    ...state,
    forOrderCached: {},
  }),
  [UpdateProductRate]: (state, { payload: { productId, newRate } }) => ({
    ...state,
    forRate: state.forRate.map((product) => {
      if (product.product_id === productId) {
        return { ...product, user_rate: newRate };
      }
      return product;
    }),
  }),
  [AddToStateCart]: (state, { payload: { product, nums } }) => {
    const prevStateForOrder = state.forOrder || [];
    const forOrder = [...prevStateForOrder, { product, nums }];

    return {
      ...state,
      forOrder,
    };
  },
  [AddOne]: (state, { payload }) => {
    const { product: productId } = payload;
    const forOrder = state.forOrder.map(({ product, nums }) => {
      if (product === productId) {
        return payload;
      }
      return { product, nums };
    });

    return {
      ...state,
      forOrder,
    };
  },
  [RemoveOne]: (state, { payload }) => {
    const { product: productId } = payload;
    const forOrder = [];
    state.forOrder.forEach(({ product, nums }) => {
      if (product === productId) {
        payload.nums && forOrder.push(payload);
      } else {
        forOrder.push({ product, nums });
      }
    });

    return {
      ...state,
      forOrder: forOrder,
    };
  },
  [DeleteProductInState]: (state, { payload }) => {
    const forOrder = state.forOrder?.filter(
      ({ product }) => product !== payload,
    );
    return {
      ...state,
      forOrder,
    };
  },
  [DeleteAllProductsInState]: (state) => {
    return {
      ...state,
      forOrder: [],
      productsOverLimit: [],
    };
  },
  [SetCount]: (state, { payload }) => {
    const { product: productId } = payload;
    const forOrder = [];
    state.forOrder.forEach(({ product, nums }) => {
      if (product === productId) {
        payload.nums && forOrder.push(payload);
      } else {
        forOrder.push({ product, nums });
      }
    });

    return {
      ...state,
      forOrder,
    };
  },
  [SetOverLimitRequest]: (state, { payload }) => {
    return {
      ...state,
      overLimitRequest: payload,
    };
  },
  [SetMinLengthOverLimitRequest]: (state, { payload }) => {
    return {
      ...state,
      minLengthOverLimitRequest: payload,
    };
  },
  [SetShippingDetails]: (state, { payload }) => {
    return {
      ...state,
      shippingDetails: {
        ...state.shippingDetails,
        [payload.type]: payload.value,
      },
    };
  },
  [SetPlacedOrder]: (state, { payload }) => {
    return {
      ...state,
      placedOrder: payload,
    };
  },
  [DeleteUnavailableProducts]: (state, { payload }) => {
    return {
      ...state,
      forOrder: state.forOrder.filter(
        ({ product }) => !payload.find((item) => item.product === product),
      ),
      unavailableLaterProducts: initialState.unavailableLaterProducts,
    };
  },
  [SetShippingAddresses]: (state, { payload }) => ({
    ...state,
    shippingAddresses: payload,
  }),
  [AddShippingAddress]: (state, { payload }) => ({
    ...state,
    shippingAddresses: [...state.shippingAddresses, payload],
  }),
  [DeleteShippingAddress]: (state, { payload }) => {
    const shippingAddresses = state.shippingAddresses.filter(
      ({ id }) => id !== payload.id,
    );
    return {
      ...state,
      shippingAddresses,
    };
  },
  [ClearOrderInfo]: (state) => ({
    ...initialState,
    forOrder: state.forOrder,
    minLengthOverLimitRequest: state.minLengthOverLimitRequest,
    overLimitRequest: state.overLimitRequest,
    forLater: state.forLater,
  }),
  [ClearShippingDetails]: (state) => ({
    ...state,
    shippingDetails: {
      ...initialState.shippingDetails,
    },
  }),
  [ClearShippingDetailsComment]: (state) => ({
    ...state,
    shippingDetails: {
      ...state.shippingDetails,
      comment: initialState.shippingDetails.comment,
    },
  }),
  [ClearShippingAddresses]: (state) => ({
    ...state,
    shippingAddresses: [],
  }),
  [ClearForOrder]: (state) => ({
    ...state,
    forOrder: initialState.forOrder,
  }),
  [ClearCart]: () => initialState,
  [UserLogOut]: () => initialState,
  [SetSaveForLaterProducts]: (state, { payload }) => ({
    ...state,
    forLater: payload,
  }),
  [SetUnavailableLaterProducts]: (state, { payload }) => ({
    ...state,
    unavailableLaterProducts: payload,
  }),
  [UpdateSaveForLaterProducts]: (state, { payload }) => {
    const { type, product, nums, productId } = payload;

    switch (type) {
      case 'add':
        const alreadyExistedProduct = state.forLater.find(
          (item) => item.product === product.product_id,
        );
        const newForOrder = state.forOrder.filter(
          (item) => item.product !== product.product_id,
        );

        if (!alreadyExistedProduct) {
          return {
            ...state,
            forOrder: newForOrder,
            forLater: [
              ...state.forLater,
              { product: product.product_id, nums },
            ],
          };
        } else {
          return {
            ...state,
            forOrder: newForOrder,
            forLater: state.forLater.map((item) =>
              item.product === product.product_id
                ? {
                    product: product.product_id,
                    nums: nums + alreadyExistedProduct.nums,
                  }
                : item,
            ),
          };
        }
      case 'delete':
        return {
          ...state,
          forLater: state.forLater.filter((item) => item.product !== productId),
        };
      case 'deleteAll':
        return { ...state, forLater: [] };
      case 'putInCart':
        const alreadyExistedCartProduct = state.forOrder.find(
          (item) => item.product === product.product_id,
        );
        const newForLater = state.forLater.filter(
          (item) => item.product !== product.product_id,
        );
        if (!alreadyExistedCartProduct) {
          return {
            ...state,
            forLater: newForLater,
            forOrder: [
              ...state.forOrder,
              { product: product.product_id, nums },
            ],
          };
        } else {
          return {
            ...state,
            forLater: newForLater,
            forOrder: state.forOrder.map((item) =>
              item.product === product.product_id
                ? {
                    ...item,
                    nums: Math.min(
                      product.product_available_qty,
                      nums + alreadyExistedCartProduct.nums,
                    ),
                  }
                : item,
            ),
          };
        }
    }
  },
  [ClearEvent]: (state) => ({
    ...state,
    shippingDetails: {
      ...state.shippingDetails,
      event: initialState.shippingDetails.event,
    },
  }),
});
