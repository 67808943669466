import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import Spinner from '../../../components/Spinner';
import OrderHistory from '../../../components/OrderHistory';
import Error from '../../../components/Error';

// Hooks
import useSearch from '../../../hooks/useSearch';

// Actions
import { GetOrders } from '../../../actions/orders';

const Orders = ({ orders }) => {
  const dispatch = useDispatch();
  const { onClearSearch } = useSearch();
  const { isLoading, apiError } = useSelector((state) => ({
    isLoading: state.isLoading,
    apiError: state.apiError?.['getOrders'],
  }));

  useEffect(() => {
    dispatch(GetOrders());

    return () => {
      onClearSearch();
    };
  }, [dispatch, onClearSearch]);

  if (isLoading) {
    return <Spinner />;
  }

  if (apiError) {
    return <Error message={apiError} />;
  }

  return <OrderHistory orders={orders} />;
};

export default Orders;
