// Lib
import { createReducer } from '@reduxjs/toolkit';

// Actions
import { SetLoading } from '../actions/loading';

const initialState = false;

export default createReducer(initialState, {
  [SetLoading]: (state, { payload }) => payload,
});
