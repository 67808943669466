// Lib
import React from 'react';
import { Route, Routes as Switch } from 'react-router-dom';

// Components
import SsoResponse from './SsoResponse';
import Login from './Login';
import Logout from './Logout';

const Auth = () => (
  <Switch>
    <Route path="" element={<Login />} />
    <Route path="sso/logout" element={<Logout />} />
    <Route path="sso-response" element={<SsoResponse />} />
  </Switch>
);

export default Auth;
