export const camelToSnake = (string) => {
  return string.replace(/[A-Z]/g, (c) => {
    return '_' + c.toLowerCase();
  });
};

export const objectKeysToSnake = (data) => {
  if (!data) {
    return;
  }
  const object = {};

  Object.keys(data).forEach((key) => {
    object[camelToSnake(key)] = data[key];
  });

  return object;
};
