// Libs
import React from 'react';
import { put, takeEvery, all, call, select } from 'redux-saga/effects';

// Components
import SavedInfoCard from '../../../components/Cards/SavedInfoCard';

// Api
import http from '../../../api/http';

// Actions
import {
  AddToCart,
  AddAllToCart,
  SetCartProducts,
  GetCartProducts,
  RemoveOne,
  AddOne,
  DeleteProduct,
  DeleteUnavailableProducts,
  SetCount,
  DeleteAllProducts,
  SetOverLimitRequest,
  SaveOverLimitRequest,
  SetMinLengthOverLimitRequest,
  SaveShippingRequest,
  SetShippingDetails,
  GetShippingMethods,
  PlaceOrder,
  SetPlacedOrder,
  GetSaveForLaterProducts,
  AddSaveForLaterProduct,
  DeleteSaveForLaterProduct,
  DeleteAllSaveForLaterProducts,
  PutSaveForLaterProductInCart,
  ClearForOrder,
  DeleteProductInState,
  DeleteAllProductsInState,
  AddToStateCart,
  DeleteUnavailableLaterProducts,
  SetCachedCountInCart,
  GetShippingNotice,
} from '../../../actions/cart';
import { ClearApiError, SetApiError } from '../../../actions/apiError';
import { GetHolidays, SetHolidays } from '../../../actions/holidays';
import { SetProducts } from '../../../actions/products';

import {
  getSaveForLaterProductsSaga,
  addSaveForLaterProductSaga,
  deleteSaveForLaterProductSaga,
  putSaveForLaterProductInCartSaga,
  deleteAllSaveForLaterProductsSaga,
  deleteUnavaialableLaterProductsSaga,
} from './saveForLater';

// Constants
import { CART_URLS, SUPPORT_URLS } from '../../../constants/urls';
import {
  SHIPPING_SCHEDULE_DATES_NEED_CHECK,
  INVALID_PLACE_ORDER_DATE,
} from '../../../constants/cart';
import { SetLoading } from '../../../actions/loading';
import { Open2Modal } from '../../../actions/modal2';

// Store
import { store } from '../../../store';
import { forOrderCachedSelector } from '../../../store/selectors';

// Constants
import { COMMON_API_ERROR } from '../../../constants/errors';

export function* deleteProductSaga({ payload }) {
  try {
    yield http.delete(CART_URLS.deleteProduct(payload), {
      data: { type: 'cart' },
    });
    yield put(DeleteProductInState(payload));
  } catch (e) {
    yield put(
      SetApiError({
        api: 'deleteProduct',
        error: COMMON_API_ERROR,
      }),
    );
  }
}

export function* deleteUnavailableProductsSaga({ payload }) {
  try {
    yield all(
      payload.map(({ product }) =>
        call(http.delete, CART_URLS.deleteProduct(product), {
          data: { type: 'cart' },
        }),
      ),
    );
  } catch (e) {
    console.log(e);
  }
}

export function* deleteAllProductsSaga() {
  try {
    yield http.delete(CART_URLS.deleteAllProducts, {
      data: { type: 'cart' },
    });
    yield put(DeleteAllProductsInState());
  } catch (e) {
    yield put(
      SetApiError({
        api: 'deleteAllProducts',
        error: COMMON_API_ERROR,
      }),
    );
  }
}

export function* addToCartSaga({ payload }) {
  if (payload.withoutApi) {
    yield put(AddToStateCart(payload));
    return;
  }
  const setAddingProgress = payload.setAddingProgress
    ? payload.setAddingProgress
    : () => {};
  try {
    setAddingProgress(true);
    const item = { type: 'cart', nums: payload.nums };
    yield call(http.patch, CART_URLS.addInCart(payload.product), item);
    setAddingProgress(false);
    payload?.openModalWindow && payload?.openModalWindow();
    yield put(AddToStateCart(payload));
    yield put(SetCachedCountInCart({ id: payload.product, count: 0 }));
  } catch (e) {
    console.log(e);
    setAddingProgress(false);
    yield put(
      SetApiError({
        api: 'addToCart',
        error: COMMON_API_ERROR,
      }),
    );
  }
}

export function* addAllToCartSaga({ payload }) {
  try {
    const { openModalWindow } = payload;
    const cachedProducts = yield select(forOrderCachedSelector);

    const sagas = [];

    Object.entries(cachedProducts).forEach(([productId, qty]) => {
      if (qty > 0) {
        sagas.push(
          call(addToCartSaga, {
            payload: {
              product: productId,
              nums: qty,
              withoutApi: false,
              openModalWindow,
              setAddingProgress: null,
            },
          }),
        );
      }
    });

    yield all(sagas);
  } catch (error) {
    console.log(error);
  }
}

export function* updateProductSaga({ payload }) {
  if (payload.withoutApi) {
    return;
  }
  const id = payload.product;
  try {
    const item = {
      nums: payload.nums,
      type: 'cart',
    };
    if (payload.nums === 0) {
      yield deleteProductSaga({ payload: id });
    } else {
      yield http.patch(CART_URLS.updateProduct(id), item);
    }
    payload.openModalWindow && payload.openModalWindow();
  } catch (e) {
    yield put(
      SetApiError({
        api: 'updateProduct',
        error: COMMON_API_ERROR,
      }),
    );
  }
}

export function* getCartProductsSaga({ payload: pathname }) {
  const isCartPage = pathname.includes('/cart');

  try {
    if (isCartPage) {
      yield put(SetLoading(true));
    }
    const cartData = yield http.post(CART_URLS.getProducts, { type: 'cart' });

    if (cartData?.data[0]?.products) {
      const productsObj = { ...store.getState().products.all.obj };
      const localOverLimitRequest = store.getState().cart.overLimitRequest;
      const products = cartData?.data[0]?.products.map((product) => {
        if (isCartPage) {
          productsObj[product.product.product_id] = product.product;
        }
        return {
          product: product.product.product_id,
          nums: product.nums,
        };
      });

      if (isCartPage) {
        yield put(SetProducts({ obj: productsObj }));
      }

      yield put(SetCartProducts(products));
      if (!localOverLimitRequest) {
        yield put(
          SetOverLimitRequest(cartData?.data[0]?.additional_info_limit),
        );
      }
      yield put(
        SetMinLengthOverLimitRequest(
          cartData?.data[0]?.min_length_additional_info,
        ),
      );
    }
  } catch (e) {
    yield put(
      SetApiError({
        api: 'getCartProducts',
        error: COMMON_API_ERROR,
      }),
    );
  } finally {
    if (isCartPage) {
      yield put(SetLoading(false));
    }
  }
}

export function* saveOverLimitRequestSaga({
  payload: {
    request,
    setLoading = () => {},
    setIsRequestSaved = () => {},
    onlyDB = false,
  },
}) {
  try {
    yield put(ClearApiError());
    setLoading(true);
    yield http.patch(CART_URLS.saveOverLimitRequest, {
      additional_info: request,
    });
    if (!onlyDB) {
      yield put(SetOverLimitRequest(request));
      setIsRequestSaved(true);
    }
  } catch (e) {
    if (!onlyDB) {
      yield put(
        SetApiError({
          api: 'saveOverLimitRequest',
          error: COMMON_API_ERROR,
        }),
      );
    }
  } finally {
    setLoading(false);
  }
}

export function* saveShippingRequestSaga({
  payload: { request, setLoading, setIsRequestSaved = () => {} },
}) {
  try {
    setLoading(true);
    yield put(SetShippingDetails({ type: 'shippingRequest', value: request }));
    setIsRequestSaved(true);
  } catch (e) {
    console.log(e);
  } finally {
    setLoading(false);
  }
}

export function* getShippingMethodsSaga({
  payload: { data, setLoadingMethods },
}) {
  try {
    yield put(ClearApiError());
    setLoadingMethods(true);
    const methods = yield call(http.post, CART_URLS.getShippingMethods, data);
    const { cart } = store.getState();
    const method = cart?.shippingDetails?.method;

    yield put(
      SetShippingDetails({
        type: 'methods',
        value: methods.data.map((method) => ({
          ...method,
          label: method.name,
          value: method.name,
        })),
      }),
    );
    if (
      !method?.id ||
      !methods?.data?.find((item) => item.name === method?.name)
    ) {
      yield put(
        SetShippingDetails({
          type: 'method',
          value: methods.data[0],
        }),
      );
    }
  } catch (e) {
    yield put(
      SetApiError({
        api: 'getShippingMethods',
        error: COMMON_API_ERROR,
      }),
    );
  } finally {
    setLoadingMethods(false);
  }
}

export function* getShippingNoticeSaga({ payload: { setLoadingNotice } }) {
  try {
    yield put(ClearApiError());
    setLoadingNotice(true);
    const res = yield call(http.get, SUPPORT_URLS.getShippingNotice);

    yield put(
      SetShippingDetails({
        type: 'notice',
        value: res.data.shipping_detail_notice,
      }),
    );
    yield put(
      SetShippingDetails({
        type: 'showDisclaimer',
        value: res.data.show_must_arrive_on_date_disclaimer,
      }),
    );
    yield put(
      SetShippingDetails({
        type: 'mustArriveOnDateDisclaimer',
        value: res.data.mast_arrive_on_date_massage,
      }),
    );
  } catch (e) {
    yield put(
      SetApiError({
        api: 'getShippingNotice',
        error: COMMON_API_ERROR,
      }),
    );
  } finally {
    setLoadingNotice(false);
  }
}

export function* checkShipmentDate(payload, shipmentMethod) {
  const response = yield call(http.post, CART_URLS.getShippingMethods, payload);
  if (response.data.some((method) => method.id === shipmentMethod)) {
    return true;
  } else {
    return false;
  }
}

export function* placeOrderSaga({
  payload: { data, file, isRecurring, setDisabledCountButton },
}) {
  try {
    setDisabledCountButton(true);
    yield put(SetLoading(true));

    if (SHIPPING_SCHEDULE_DATES_NEED_CHECK.includes(data.schedule_date_type)) {
      const isDateCorrect = yield call(
        checkShipmentDate,
        {
          country: data.addresses[0].country,
          schedule_date: data.schedule_date,
          schedule_date_type: data.schedule_date_type,
        },
        data.shipment_method,
      );

      if (!isDateCorrect) {
        throw new Error(INVALID_PLACE_ORDER_DATE);
      }
    }

    const createOrderPayload = file ? { ...data, shipping_label: true } : data;

    const order = yield http.post(
      isRecurring ? CART_URLS.createRecurringOrder : CART_URLS.createOrder,
      createOrderPayload,
    );
    if (file) {
      yield http.post(
        CART_URLS.uploadEventFile,
        {
          order: order.data.id,
          file: file,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
    }
    yield put(SetPlacedOrder(order?.data));
    yield put(
      Open2Modal({
        id: 'over-limit',
        Component: () => (
          <SavedInfoCard
            message="Your order has been successfully placed"
            btnTitle="Close"
          />
        ),
        closeIcon: true,
        styles: {
          minHeight: 'initial',
          width: 'auto',
        },
        props: {
          paddingContent: {
            xl: '40px',
            lg: '40px',
            md: '40px 16px 30px',
          },
        },
      }),
    );
    yield put(ClearForOrder());
  } catch (e) {
    setDisabledCountButton(false);
    // wrap e.message in array because of getErrorString implementation
    yield put(
      SetApiError({ api: 'placeOrder', error: [e.message] || e.response.data }),
    );
  } finally {
    // setLoading(false);
    yield put(SetLoading(false));
  }
}

export function* getHolidaysSaga() {
  try {
    const holidays = yield call(http.get, SUPPORT_URLS.getHolidays);
    const dates = {};

    holidays.data.forEach(({ holiday }) => {
      dates[holiday] = holiday;
    });
    yield put(SetHolidays(dates));
  } catch (e) {
    console.log(e);
  }
}

function* cartSaga() {
  yield takeEvery(AddToCart, addToCartSaga);
  yield takeEvery(AddAllToCart, addAllToCartSaga);
  yield takeEvery(GetCartProducts, getCartProductsSaga);
  yield takeEvery(RemoveOne, updateProductSaga);
  yield takeEvery(AddOne, updateProductSaga);
  yield takeEvery(SetCount, updateProductSaga);
  yield takeEvery(DeleteProduct, deleteProductSaga);
  yield takeEvery(DeleteUnavailableProducts, deleteUnavailableProductsSaga);
  yield takeEvery(DeleteAllProducts, deleteAllProductsSaga);
  yield takeEvery(SaveOverLimitRequest, saveOverLimitRequestSaga);
  yield takeEvery(SaveShippingRequest, saveShippingRequestSaga);
  yield takeEvery(GetShippingMethods, getShippingMethodsSaga);
  yield takeEvery(GetShippingNotice, getShippingNoticeSaga);
  yield takeEvery(PlaceOrder, placeOrderSaga);
  yield takeEvery(GetHolidays, getHolidaysSaga);
  yield takeEvery(GetSaveForLaterProducts, getSaveForLaterProductsSaga);
  yield takeEvery(AddSaveForLaterProduct, addSaveForLaterProductSaga);
  yield takeEvery(DeleteSaveForLaterProduct, deleteSaveForLaterProductSaga);
  yield takeEvery(
    DeleteAllSaveForLaterProducts,
    deleteAllSaveForLaterProductsSaga,
  );
  yield takeEvery(
    PutSaveForLaterProductInCart,
    putSaveForLaterProductInCartSaga,
  );
  yield takeEvery(
    DeleteUnavailableLaterProducts,
    deleteUnavaialableLaterProductsSaga,
  );
}

export default cartSaga;
