import React from 'react';
import useValidateProducts from './useValidateProducts';
import { Box, Button, Divider, Typography } from '@mui/material';
import Layout from '../../components/Layout';
import TextField from '@mui/material/TextField';
import QrReaderWindow from './QrReaderWindow';
import useQrReader from './useQrReader';
import { ReactComponent as QrCode } from '../../assets/svg/qr.svg';

export default function ValidateMobile() {
  const { value, onChangeInput, handleCheck } = useValidateProducts();
  const { isQrOpen, qrError, handleScan, handleError, toggleQrOpen } =
    useQrReader();

  return (
    <Layout title={'Validate'}>
      <Box display={'flex'} width={'100%'} height={'70vh'}>
        <Box
          display={'flex'}
          height={'70vh'}
          width={'100%'}
          rowGap={5}
          flexDirection={'column'}
          alignContent={'center'}>
          <Typography variant={'h1'}>Enter Stock Number</Typography>
          <Button
            sx={{
              color: '#30556B',
              backgroundColor: '#F4F4F4',
            }}
            variant={'contained'}
            onClick={toggleQrOpen}
            startIcon={<QrCode />}>
            {isQrOpen ? 'Close QR Reader' : ' Scan QR Code '}
          </Button>
          <Divider
            sx={{
              color: '#C6CAC6',
            }}
            textAlign={'center'}
            variant={'fullWidth'}
            orientation={'horizontal'}>
            <Typography variant={'textMdSemibold'}>
              or enter manually
            </Typography>
          </Divider>
          <Box
            display={'flex'}
            flexDirection={'column'}
            width={'100%'}
            rowGap={2}
            justifyContent={'center'}>
            <Typography htmlFor="input" variant={'textMdSemibold'}>
              Stock Number
            </Typography>
            <TextField
              id="input"
              fullWidth
              value={value}
              onChange={(e) => onChangeInput(e.target.value)}
            />
            <Button
              variant={'contained'}
              disabled={!value}
              fullWidth
              onClick={handleCheck}>
              Check
            </Button>
            <QrReaderWindow
              toggleQrOpen={toggleQrOpen}
              isQrOpen={isQrOpen}
              handleError={handleError}
              handleScan={handleScan}
              qrError={qrError}
            />
          </Box>
        </Box>
      </Box>
    </Layout>
  );
}
