// Lib
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import { makeStyles } from '@mui/styles';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const useStyles = makeStyles((theme) => ({
  crumbs: {
    '& .MuiBreadcrumbs-separator': {
      color: theme.palette.darkGrey,
      fontSize: 18,
      fontWeight: 700,
      '&:nth-last-child(2)': {
        color: theme.palette.grey2,
      },
    },
  },
  link: {
    color: theme.palette.darkGrey,
    fontSize: 18,
    fontWeight: 700,
    textDecoration: 'none',
  },
  activeLink: {
    color: theme.palette.grey2,
  },
}));

const Breadcrumbs = ({ items }) => {
  const classes = useStyles();
  const { pathname } = useLocation();

  return (
    <MuiBreadcrumbs
      className={classes.crumbs}
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb">
      {items.map(({ to, title }) => (
        <NavLink
          key={title}
          end
          to={to}
          state={{
            navigateFrom: pathname,
          }}
          className={({ isActive }) =>
            isActive
              ? classnames(classes.activeLink, classes.link)
              : classes.link
          }>
          {title}
        </NavLink>
      ))}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
