// Lib
import axios from 'axios';

// store
import { store } from '../store';

// Actions
import { SetPrevPath } from '../actions/location';
import { UserLogOut } from '../actions/user';
import { SetAuthData } from '../actions/auth';
import { SetLoading } from '../actions/loading';

// history
import browserHistory from '../browserHistory';

// URLS
import { AUTH_URLS, BASE_URL } from '../constants/urls';

const urlsWithoutRefreshingToken = [
  AUTH_URLS.obtainToken,
  AUTH_URLS.refreshToken,
];

let isRefreshing = false;
let refreshSubscribers = [];

export const refreshToken = async (oldAuth) => {
  isRefreshing = true;
  try {
    const newAuthData = await axios.post(
      `${BASE_URL}${AUTH_URLS.refreshToken}`,
      {
        refresh_token: oldAuth?.refreshToken,
        access_token: oldAuth?.accessToken,
      },
    );
    store.dispatch(
      SetAuthData({ ...newAuthData?.data, sso: store.getState().auth.sso }),
    );
    onRrefreshed(newAuthData?.data.access_token);
  } catch (error) {
    const pathname = browserHistory.location.pathname;

    if (!pathname.includes('auth')) {
      store.dispatch(SetPrevPath(pathname));
    }
    store.dispatch(UserLogOut());
  } finally {
    isRefreshing = false;
    refreshSubscribers = [];
    store.dispatch(SetLoading(false));
  }
};

const subscribeTokenRefresh = (callback) => {
  refreshSubscribers.push(callback);
};

const onRrefreshed = (token) => {
  refreshSubscribers.map((callback) => callback(token));
};

const interceptors = {
  requestFulfilled: (config = { headers: {} }) => {
    const { auth } = store.getState();
    const token = auth?.accessToken;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },

  responseFulfilled: (res = { data: {} }) => {
    // res.ok = res.status >= 200 && res.status <= 299;
    return res;
  },

  responseRejected: async (
    error = {
      config: {
        headers: {
          Authorization: 'accessToken',
        },
      },
      response: {
        status: 401,
      },
    },
  ) => {
    const originalConfig = error?.config;
    if (
      error?.response?.status === 401 &&
      !originalConfig.retry &&
      !urlsWithoutRefreshingToken.includes(originalConfig.url)
    ) {
      originalConfig.retry = true;
      const { auth } = store.getState();
      const authHeader = originalConfig?.headers?.Authorization;
      const tokenIsOutdated = authHeader?.includes(auth?.accessToken);

      if (!isRefreshing && tokenIsOutdated) {
        refreshToken(auth);
      }

      return new Promise((resolve) => {
        subscribeTokenRefresh((token) => {
          originalConfig.headers['Authorization'] = 'Bearer ' + token;

          // To mock axios in test
          resolve(
            process.env.NODE_ENV === 'test'
              ? Promise.resolve({})
              : axios(originalConfig),
          );
        });
      });
    } else {
      return Promise.reject(error);
    }
  },
};

export default interceptors;
