// Lib
import React, { useCallback, useEffect, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';

// Components
import Layout from '../../components/Layout';
import Products from '../../components/Products';
import FiltersBar from '../../components/FiltersBar';
import Spinner from '../../components/Spinner';
import TotalQtyLabel from '../../components/Labels/TotalQtyLabel';
import Error from '../../components/Error';

// Hooks
import useFilters from './hooks/useFilters';
import useCatalogView from './hooks/useCatalogView';

// Actions
import { GetProducts } from '../../actions/products';

const useStyles = makeStyles((theme) => ({
  layout: {
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      paddingBottom: ({ onlyContent }) =>
        onlyContent ? theme.spacing(7) : 'initial',
    },
  },
  emptyMaterials: {
    marginTop: '20vh',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    width: 540,
    [theme.breakpoints.down('md')]: {
      width: 290,
    },
  },
  modalContainer: {
    paddingInline: '116px',
    paddingTop: '10px',
    [theme.breakpoints.down('lg')]: {
      paddingInline: 0,
    },
    [theme.breakpoints.down('md')]: {
      paddingInline: '12px',
    },
  },
  productsQtyLabel: {
    marginBottom: theme.spacing(2),
  },
}));

const Materials = ({ children, onlyContent = false }) => {
  const dispatch = useDispatch();
  const apiError = useSelector((state) => state.apiError?.['getProducts']);
  const classes = useStyles({ onlyContent });
  const {
    products,
    updateShownProducts,
    onChangeFilter,
    onMultipleSelectChange,
    applyMobileFilters,
    onRemoveFilter,
    filters,
    onClearAllFilters,
    isLoading,
    onChangeSorting,
  } = useFilters();
  const { catalogView } = useCatalogView();

  useEffect(() => {
    dispatch(GetProducts());
  }, [dispatch]);

  const renderComponent = useCallback(
    () => (
      <FiltersBar
        onlyContent={onlyContent}
        onChangeFilter={onChangeFilter}
        onMultipleSelectChange={onMultipleSelectChange}
        applyMobileFilters={applyMobileFilters}
        onRemoveFilter={onRemoveFilter}
        filters={filters}
        onClearAllFilters={onClearAllFilters}
        onChangeSorting={onChangeSorting}
      />
    ),
    [
      onlyContent,
      onChangeFilter,
      onMultipleSelectChange,
      applyMobileFilters,
      onRemoveFilter,
      filters,
      onClearAllFilters,
      onChangeSorting,
    ],
  );

  const content = useMemo(() => {
    if (isLoading) {
      return <Spinner />;
    } else if (apiError) {
      return <Error message={apiError} />;
    } else if (products?.all?.ids && products?.all?.ids?.length === 0) {
      return (
        <Typography className={classes.emptyMaterials} variant="h1">
          There are currently no products in your product catalog
        </Typography>
      );
    } else {
      return (
        <>
          {products?.all?.ids && (
            <TotalQtyLabel
              qty={products?.afterFilters?.length}
              type="product"
              className={classes.productsQtyLabel}
            />
          )}
          <Products
            updateShownProducts={updateShownProducts}
            products={products}
            view={catalogView}
          />
          {children}
        </>
      );
    }
  }, [
    apiError,
    children,
    products,
    isLoading,
    catalogView,
    classes,
    updateShownProducts,
  ]);

  return (
    <Layout
      title="Materials"
      onlyContent={onlyContent}
      className={{ layout: classes.layout }}
      renderComponent={renderComponent}>
      {content}
    </Layout>
  );
};

export default Materials;
