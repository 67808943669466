// Lib
import { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Actions
import { DeleteShippingAddress } from '../../../actions/cart';

const useShippingAddresses = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const shippingAddresses = useSelector(
    (state) => state.cart.shippingAddresses,
  );

  const isDistroOrder = useMemo(
    () => shippingAddresses.length > 1,
    [shippingAddresses],
  );

  const goToAddresses = useCallback(() => {
    navigate('/cart/shipping-address');
  }, [navigate]);

  const onDeleteAddress = useCallback(
    (id) => () => {
      dispatch(DeleteShippingAddress({ id }));
    },
    [dispatch],
  );

  return { shippingAddresses, goToAddresses, isDistroOrder, onDeleteAddress };
};

export default useShippingAddresses;
