// Lib
import { createReducer } from '@reduxjs/toolkit';

// Actions
import {
  SetContacts,
  SetAlgoliaAppInfo,
  ToClearSearchLocalValue,
} from '../actions/support';

export const initialState = {
  contacts: null,
  algoliaAppInfo: null,
  toClearFAQSearch: false,
};

export default createReducer(initialState, {
  [SetContacts]: (state, { payload }) => ({
    ...state,
    contacts: { ...payload },
  }),
  [SetAlgoliaAppInfo]: (state, { payload }) => ({
    ...state,
    algoliaAppInfo: { ...payload },
  }),
  [ToClearSearchLocalValue]: (state, { payload }) => ({
    ...state,
    toClearFAQSearch: payload,
  }),
});
