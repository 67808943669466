// Reducers
import isLoading from './loading';
import user from './user';
import products from './products';
import apiError from './apiError';
import auth from './auth';
import location from './location';
import modal from './modal';
import modal2 from './modal2';
import filters from './filters';
import search from './search';
import isSearchActive from './isSearchActive';
import addressBook from './addressBook';
import orders from './orders';
import support from './support';
import approvals from './approvals';
import holidays from './holidays';
import kits from './kits';
import validate from './validate';

export default {
  auth,
  apiError,
  isLoading,
  user,
  products,
  location,
  filters,
  search,
  isSearchActive,
  modal,
  modal2,
  addressBook,
  orders,
  support,
  approvals,
  holidays,
  kits,
  validate,
};
