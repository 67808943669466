// Lib
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';

// Components
import Form from './index';
import FooterButtons from '../../Modal/FooterButtons';

// Hooks
import useAddressForm from '../../../pages/AddressBook/hooks/useAddressForm';
import ErrorMessage from '../../Error/ErrorMessage';

// Actions
import { ClearApiError } from '../../../actions/apiError';

const useStyles = makeStyles((theme) => ({
  buttons: {
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      paddingTop: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      '& #accept-button': {
        height: 44,
        width: 204,
      },
    },
  },
}));

const AddressForm = ({ address = {}, editMode = false }) => {
  const dispatch = useDispatch();
  const {
    onClose,
    onSubmit,
    onChangeSelect,
    onChangeInput,
    states,
    countries,
    isLoading,
    apiErrors,
    uspsError,
    apiError,
  } = useAddressForm(editMode, address.id);

  const [visible, setVisible] = useState(true);
  const hideElement = () => setVisible(false);
  const showElement = () => setVisible(true);

  const classes = useStyles();

  useEffect(() => {
    return () => {
      dispatch(ClearApiError());
    };
  }, [dispatch]);

  return (
    <Form
      onChangeSelect={onChangeSelect}
      onChangeInput={onChangeInput}
      states={states}
      countries={countries}
      apiErrors={apiErrors}
      uspsError={uspsError}
      onSubmit={onSubmit}
      address={address}
      hideElement={hideElement}
      showElement={showElement}>
      {apiError && <ErrorMessage message={apiError} />}
      {visible && (
        <FooterButtons
          className={classes.buttons}
          isLoading={isLoading}
          onReject={onClose}
          rejectTitle="Cancel"
          acceptTitle="Save"
          acceptBtnType="submit"
        />
      )}
    </Form>
  );
};

export default AddressForm;
