// Lib
import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Selectors
import {
  productsObjSelector,
  forOrderSelector,
} from '../../../store/selectors';

// Actions
import { SetProductsForRate, UpdateProductRate } from '../../../actions/cart';
import { UpdateProductsRating } from '../../../actions/rating';

// Utils
import { isObjEmpty } from '../../../utils/object';

const useRatingBeforeNextStep = () => {
  const dispatch = useDispatch();
  const { forOrder, productsForRate, productsObj } = useSelector((state) => ({
    forOrder: forOrderSelector(state),
    productsForRate: state.cart.forRate,
    productsObj: productsObjSelector(state),
  }));

  useEffect(() => {
    if (isObjEmpty(productsObj) || !forOrder.length) {
      return;
    }

    const productIds = [];
    forOrder?.forEach(({ product: productId }) => {
      const product = productsObj[productId];
      const { user_rate, is_ordered, rating } = product || {};

      if (!user_rate && is_ordered && rating) {
        productIds.push({ product_id: productId, user_rate });
      }
    });

    dispatch(SetProductsForRate(productIds));
  }, [dispatch, forOrder, productsObj]);

  const updateProductRating = useCallback(
    (productId) => (newRate) => {
      dispatch(UpdateProductRate({ productId, newRate }));
    },
    [dispatch],
  );

  const onSaveRating = useCallback(
    (productsForRate, setLoading) => {
      dispatch(
        UpdateProductsRating({
          products: productsForRate.map((product) => ({
            id: product.product_id,
            rate: product.user_rate,
          })),
          setLoading,
        }),
      );
    },
    [dispatch],
  );

  return { productsForRate, onSaveRating, updateProductRating };
};

export default useRatingBeforeNextStep;
