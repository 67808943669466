// Lib
import React, { Fragment } from 'react';
import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';

// Components
import { makeStyles } from '@mui/styles';
import Spinner from '../../../../Spinner';
import EditRequestButton from '../../../../Button/EditRequestButton';

// Constants
import {
  NO_METHODS,
  SHIPPING_SCHEDULE_WITH_DATES,
} from '../../../../../constants/cart';
import ErrorMessage from '../../../../Error/ErrorMessage';

const useStyles = makeStyles((theme) => ({
  radioGroup: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1.5),
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        marginBottom: theme.spacing(3),
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        marginBottom: theme.spacing(1.5),
      }),
    },
  },
  radioLabel: {
    marginRight: 'auto',
    '& .MuiTypography-root': {
      fontSize: 18,
      [theme.breakpoints.down('md')]: {
        fontSize: 16,
      },
      [theme.breakpoints.downLandscape('md')]: {
        ...(theme.isMobileDevice && {
          fontSize: 16,
        }),
      },
    },
  },
  radioItem: {
    color: '#C6CAC6',
    padding: 8,
    '&$checked': { color: theme.palette.primary1 },
    [theme.breakpoints.down('md')]: {
      padding: 5,
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        padding: 5,
      }),
    },
  },
  editRequestButton: {
    marginTop: -10,
    padding: 4,
    width: 'fit-content',
  },
  noMethods: {
    color: '#c5203f',
    marginBottom: theme.spacing(2),
    width: 530,
    [theme.breakpoints.down('md')]: {
      width: 400,
    },
    [theme.breakpoints.down('sm')]: {
      width: 'initial',
    },
  },
}));

const ShippingMethods = ({
  loadingMethods,
  shippingDetails,
  openRequestCard,
  onChangeDetails,
  apiError,
}) => {
  const classes = useStyles();
  const { schedule, method, shippingRequest, date, methods } = shippingDetails;

  if (loadingMethods) {
    return <Spinner height="120px" />;
  }

  if (apiError) {
    return <ErrorMessage message={apiError} />;
  }

  if (SHIPPING_SCHEDULE_WITH_DATES.includes(schedule) && !date) {
    return <Box mb={2}>Please, Choose Date</Box>;
  }

  if (methods.length) {
    return (
      <RadioGroup
        className={classes.radioGroup}
        value={method?.name}
        row={false}
        aria-labelledby="address-format-radio-buttons-group-label"
        name="shipping-method-radio-buttons-group">
        {methods.map(({ label, value, escalation, id }) => (
          <Fragment key={label}>
            <FormControlLabel
              className={classes.radioLabel}
              value={value}
              control={
                <Radio
                  id={String(id)}
                  name={escalation ? 'requiredRequest' : ''}
                  classes={{
                    root: classes.radioItem,
                  }}
                  onChange={onChangeDetails('method')}
                />
              }
              label={label}
            />
            {value === method?.name && shippingRequest && escalation && (
              <EditRequestButton
                className={classes.editRequestButton}
                onClick={openRequestCard}
                title="Edit Shipping Request"
              />
            )}
          </Fragment>
        ))}
      </RadioGroup>
    );
  }

  return (
    <div data-testid="no-methods" className={classes.noMethods}>
      {NO_METHODS}
    </div>
  );
};

export default ShippingMethods;
