// Libs
import React, { useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns';
import { Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

// Components
import Rating from '../../../Rating';
import MyRating from '../../../Rating/MyRating';
import Image from '../../../Image';
import OrderProductQtyLabels from '../../../Labels/OrderProductQtyLabels';
import ApprovalLabel from '../../../Labels/ApprovalLabel';
import ReportableIcon from '../../../Products/Card/ReportableIcon';

// Hooks
import useUser from '../../../../hooks/useUser';
import useUI from '../../../../hooks/useUI';
import useUnavailableForOrderProduct from '../../../../hooks/useUnavailableForOrderProduct';

// Utils
import { getProductTotalItemsOrdered } from '../../../../pages/OrderHistory/utils/getQuantity';

// Selectors
import { productsObjSelector } from '../../../../store/selectors';

const useStyles = makeStyles((theme) => ({
  header: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    width: 'fit-content',
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        fontSize: 24,
      }),
    },
  },
  blockHeader: {
    color: 'black',
  },
  stockNumber: {
    color: theme.palette.darkGrey,
  },
  productInfoHeader: ({ isKiteUser }) => ({
    fontWeight: isKiteUser ? '700' : '600',
    marginBottom: theme.spacing(1),
    color: theme.palette.darkGrey,
  }),
  productInfoText: {
    fontWeight: '400',
    color: theme.palette.darkGrey,
  },
  qtyInfoText: ({ isKiteUser }) => ({
    color: isKiteUser ? theme.palette.primary1 : theme.palette.primary2,
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '24px',
  }),
  outOfStockText: ({ isKiteUser }) => ({
    fontWeight: isKiteUser ? '700' : '600',
    color: '#C5203F',
  }),
  myRating: ({ isKiteUser }) => ({
    fontWeight: isKiteUser ? '700' : '600',
    color: '#929A92',
  }),
  leftPart: {
    height: 'fit-content',
  },
  qtyBlock: ({ isTabletMode, isMobileMode }) =>
    !isMobileMode && isTabletMode
      ? {
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }
      : {},
  rightPart: {
    [theme.breakpoints.down('lg')]: {
      paddingTop: 83.5,
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
    },
  },
  imageContainer: ({ isUnavailableForOrder }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    '& img': {
      objectFit: 'contain',
      width: '100%',
      opacity: isUnavailableForOrder ? 0.65 : 1,
    },
  }),
}));

const OrderProductDetails = ({
  productId,
  order,
  orderItem,
  addressesCount,
  isRecurring,
}) => {
  const product = useSelector(
    (state) => productsObjSelector(state)?.[productId],
  );
  const {
    product_id: stockNumber,
    product_description: description,
    brand_category: brandCategory,
    unit_measure: unitMeasure,
    pi_required: piRequired,
    prepack_quantity: perUnitQty,
    order_limit: maxAllowedQty,
    product_available_qty: inStock,
    owner,
    rating: isRatable,
    reportable: isReportable,
    size_vers: sizeVers,
  } = product || {};
  const { isUnavailableForOrder, unavailableLabel, unavailableWatermark } =
    useUnavailableForOrderProduct(product, false, true);

  const {
    order_sn: orderId,
    created: orderDate,
    ship_date: shipDate,
    address,
  } = order;

  const theme = useTheme();
  const { isKiteUser } = useUser();
  const { isMobileMode, isTabletMode, isDesktopMode } = useUI();
  const classes = useStyles({
    isKiteUser,
    isUnavailableForOrder,
    isTabletMode,
    isMobileMode,
    isDesktopMode,
  });

  const totalOrdered = useMemo(
    () => getProductTotalItemsOrdered(product, orderItem, addressesCount),
    [product, orderItem, addressesCount],
  );

  return (
    <Box
      display="flex"
      gap={theme.spacing(3.75)}
      flexDirection={isMobileMode ? 'column-reverse' : 'row'}
      height="fit-content"
      pb={{ md: 2, xs: 2 }}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        className={classes.leftPart}>
        <Typography variant="h2" className={classes.header}>
          {description}
        </Typography>
        <Typography variant="textSm" mb={0.5}>
          {sizeVers}
        </Typography>
        {isRatable && <Rating withTooltip={false} product={product} />}
        {isMobileMode && (
          <Box mt={2} className={classes.qtyBlock}>
            <div className={classes.qtyInfoText}>
              <OrderProductQtyLabels
                orderItem={orderItem}
                addressesCount={addressesCount}
                isRecurring={isRecurring}
              />
            </div>
            <Typography className={classes.qtyInfoText}>
              {`Total Items Ordered: ${perUnitQty ? totalOrdered : 'N/A'}`}
            </Typography>
            {!isRecurring && (
              <ApprovalLabel
                orderItemStatus={orderItem.status}
                approver={orderItem.approval?.approver}
                isModified={orderItem.qty_ordered !== orderItem.qty_approved}
                variant={'textMd'}
                className={classes.approvalLabel}
              />
            )}
          </Box>
        )}
        <Typography
          variant="h3"
          my={2}
          className={classnames(classes.blockHeader, classes.stockNumber)}>
          Stock Number: {stockNumber}
        </Typography>
        <Box display={'flex'}>
          <Typography className={classes.productInfoHeader}>
            Therapeutic Area:&nbsp;
          </Typography>
          <Typography className={classes.productInfoText}>
            {perUnitQty ? owner : 'N/A'}
          </Typography>
        </Box>
        <Box display={'flex'}>
          <Typography className={classes.productInfoHeader}>
            Brand Category:&nbsp;
          </Typography>
          <Typography className={classes.productInfoText}>
            {perUnitQty ? brandCategory : 'N/A'}
          </Typography>
        </Box>
        <Box display={'flex'}>
          <Typography className={classes.productInfoHeader}>
            Unit Measure:&nbsp;
          </Typography>
          <Typography className={classes.productInfoText}>
            {perUnitQty ? unitMeasure : 'N/A'}
          </Typography>
        </Box>
        <Box display={'flex'} mb={1}>
          <Typography className={classes.productInfoHeader}>
            PI Required:&nbsp;
          </Typography>
          <Typography className={classes.productInfoText}>
            {perUnitQty ? (piRequired ? 'Yes' : 'No') : 'N/A'}
          </Typography>
        </Box>
        <Box display={'flex'}>
          <Typography className={classes.productInfoHeader}>
            Per Unit Qty:&nbsp;
          </Typography>
          <Typography className={classes.productInfoText}>
            {perUnitQty ? perUnitQty : 'N/A'}
          </Typography>
        </Box>
        {maxAllowedQty !== null && (
          <Box display={'flex'}>
            <Typography className={classes.productInfoHeader}>
              Max Allowed Qty:&nbsp;
            </Typography>
            <Typography className={classes.productInfoText}>
              {maxAllowedQty}
            </Typography>
          </Box>
        )}
        <Box display={'flex'}>
          {!isUnavailableForOrder ? (
            <Typography className={classes.qtyInfoText}>
              In Stock: {inStock}
            </Typography>
          ) : (
            <Typography className={classes.outOfStockText}>
              {unavailableLabel}
            </Typography>
          )}
        </Box>
        <Typography variant="h3" mt={2} mb={1} className={classes.blockHeader}>
          Order Details
        </Typography>
        <Box display={'flex'}>
          <Typography className={classes.productInfoHeader}>
            Order ID:&nbsp;
          </Typography>
          <Typography className={classes.productInfoText}>{orderId}</Typography>
        </Box>
        <Box display={'flex'}>
          <Typography className={classes.productInfoHeader}>
            Order Date:&nbsp;
          </Typography>
          <Typography className={classes.productInfoText}>
            {orderDate ? format(new Date(orderDate), 'MM/dd/yyyy') : '-'}
          </Typography>
        </Box>
        <Box display={'flex'}>
          <Typography className={classes.productInfoHeader}>
            Ship Date:&nbsp;
          </Typography>
          <Typography className={classes.productInfoText}>
            {shipDate ? format(new Date(shipDate), 'MM/dd/yyyy') : '-'}
          </Typography>
        </Box>
        {address && (
          <Box display="flex">
            <Typography className={classes.productInfoText}>
              <b>Shipped To:</b>{' '}
              {`${address.customer ? address.customer + ',' : ''} ${
                address.full_address || ''
              }`}
            </Typography>
          </Box>
        )}
        {!isMobileMode && (
          <Box mt={2} className={classes.qtyBlock}>
            <div className={classes.qtyInfoText}>
              <OrderProductQtyLabels
                orderItem={orderItem}
                addressesCount={addressesCount}
                isRecurring={isRecurring}
              />
            </div>
            <Typography className={classes.qtyInfoText}>
              {`Total Items Ordered: ${perUnitQty ? totalOrdered : 'N/A'}`}
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        flexGrow={1}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        gap={1}
        className={classes.rightPart}>
        <div className={classes.imageContainer}>
          <Image
            src={product.image_link}
            imageContainer={{
              desktop: [457],
              tablet: [363],
              mobile: ['100%', 320],
            }}
          />
          {unavailableWatermark}
        </div>
        {isReportable && <ReportableIcon withText />}
        {isRatable && (
          <MyRating product={product} className={classes.myRating} />
        )}
      </Box>
    </Box>
  );
};

export default OrderProductDetails;
