const ordersHistoryColumns = [
  { id: 'orderId', label: 'Order ID' },
  { id: 'tracking', label: 'Tracking' },
  { id: 'created', label: 'Order Time' },
  { id: 'orderStatus', label: 'Status' },
  { id: 'shippingAddress', label: 'Shipping Address(es)' },
  { id: 'orderQty', label: 'Order Qty' },
  { id: 'totalOrdered', label: 'Total Ordered' },
  { id: 'addToMyKits', label: '' },
  { id: 'reorder', label: '' },
];

const recurringOrdersColumns = [
  { id: 'nextShipping', label: 'Next Shipping' },
  { id: 'shippingAddress', label: 'Shipping Address(es)' },
  { id: 'orderQty', label: 'Order Qty' },
  { id: 'totalOrdered', label: 'Total Ordered' },
  { id: 'editRecurrence', label: '' },
  { id: 'cancel', label: '' },
  { id: 'skip', label: '' },
  { id: 'details', label: '' },
];

const sortingItems = [
  { title: 'created', label: 'Sort By Date', name: 'date' },
  { title: 'status', label: 'Sort By Status', name: 'status' },
];

const sortingRecurringItems = [
  { title: 'next shipping', label: 'Sort By Date', name: 'date' },
];

const filterItems = [
  { title: 'In Review', checked: false },
  { title: 'Ordered', checked: false },
  { title: 'Shipped', checked: false },
  { title: 'Cancelled', checked: false },
];

const filterTitles = ['statuses'];

const ordersTabs = [
  { label: 'Order History', path: '/order-history' },
  { label: 'Recurring Orders', path: '/order-history/recurring' },
];

export {
  ordersHistoryColumns,
  recurringOrdersColumns,
  sortingItems,
  sortingRecurringItems,
  filterItems,
  filterTitles,
  ordersTabs,
};
