// Lib
import React, { useCallback, useState } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

// Components
import QtyInput from '../QtyInput';
import FooterButtons from '../../../Modal/FooterButtons';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  buttons: {
    '& #reject-button': {
      width: 87,
    },
    '& #accept-button': {
      width: 107,
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingBottom: 0,
      '& #reject-button': {
        fontSize: 16,
        width: '50%',
      },
      '& #accept-button': {
        fontSize: 16,
        width: '50%',
      },
    },
  },
}));

const EditQtyCard = ({
  onSave,
  onClose,
  qtyApproved,
  orderLimit,
  setOrderQtyError = () => {},
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(qtyApproved);
  const onChange = useCallback((value) => setValue(value), [setValue]);
  const orderQtyError = useSelector((state) => state.approvals.orderQtyError);

  return (
    <>
      <Typography mb={2.5} variant="h3">
        Editing
      </Typography>
      <QtyInput
        setOrderQtyError={setOrderQtyError}
        orderLimit={orderLimit}
        value={value}
        label="Order Qty"
        onChange={onChange}
      />
      <FooterButtons
        onAccept={() => onSave(value)}
        className={classes.buttons}
        onReject={onClose}
        acceptTitle="Save"
        rejectTitle="Cancel"
        disabledAcceptBtn={!!orderQtyError}
      />
    </>
  );
};

export default EditQtyCard;
