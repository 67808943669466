// Libs
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';

// Actions
import { AddToMyKits, CleanKitActionErrorMessage } from '../../../actions/kits';
import { OpenModal } from '../../../actions/modal';
import { Open2Modal, Close2Modal } from '../../../actions/modal2';

// Components
import KitNameModal from '../../../components/Kits/Modal/KitNameModal/KitNameModal';
import OrderConfirmation from '../../../components/OrderHistory/Modals/OrderConfirmation/OrderConfirmation';

// Hooks
import useModal from '../../../hooks/useModal';
import useUI from '../../../hooks/useUI';

// Selectors
import { productsObjSelector } from '../../../store/selectors';

// Styles
import { colors } from '../../../styles/palette';

const useAddToKits = (orderId) => {
  const dispatch = useDispatch();
  const timerRef = useRef(null);
  const { orders, currentOrder, productsObj } = useSelector((state) => ({
    orders: state.orders.general,
    currentOrder: state.orders.currentOrder,
    productsObj: productsObjSelector(state),
  }));
  const { onClose } = useModal();
  const { pathname } = useLocation();
  const params = useParams();
  const { isTabletMode, isMobileMode } = useUI();

  const unavailableProducts = useMemo(() => {
    const isOrderHistory = pathname.includes('order-history');
    if (params.orderId && isOrderHistory) {
      return currentOrder.order_items.filter(
        ({ product }) => !productsObj?.[product]?.is_available_for_user,
      );
    }
    if (!params.orderId && isOrderHistory) {
      const order = orders.find((order) => order.id === orderId);
      return order?.order_items.filter(
        ({ product }) => !productsObj?.[product]?.is_available_for_user,
      );
    }
  }, [pathname, params, productsObj, orderId, currentOrder, orders]);

  const showNotificationModal = useCallback(() => {
    dispatch(
      Open2Modal({
        id: 'added-to-kits',
        Component: () => <Typography variant="h3">Added To My Kits</Typography>,
        closeIcon: false,
        styles: {
          width: 'max-content',
          minHeight: 'initial',
          color: colors.darkGrey,
        },
        props: {
          transitionTimeout: { enter: 0, exit: 500 },
          paddingContent: {
            xl: '20px',
            lg: '20px',
            md: '20px',
          },
        },
      }),
    );
    timerRef.current = setTimeout(() => dispatch(Close2Modal()), 2000);
  }, [dispatch]);

  useEffect(() => {
    return () => clearTimeout(timerRef?.current);
  }, [dispatch]);

  const onClickSave = useCallback(
    (orderId, name) => {
      dispatch(
        AddToMyKits({
          orderId,
          name,
          callback: showNotificationModal,
        }),
      );
    },
    [dispatch, showNotificationModal],
  );

  const cleanUp = useCallback(() => {
    dispatch(CleanKitActionErrorMessage());
  }, [dispatch]);

  const showWindowWithKitName = useCallback(() => {
    dispatch(
      OpenModal({
        Component: KitNameModal,
        props: {
          entityId: orderId,
          onClickSave,
          onClickCancel: onClose,
          cleanUp,
          paddingContent: {
            xl: '40px 40px 30px',
            lg: '40px 40px 30px',
            md: '24px 16px 24px',
          },
        },
        styles: {
          width: isMobileMode ? 268 : isTabletMode ? 660 : 700,
          height: isMobileMode ? 263 : 272,
        },
      }),
    );
  }, [
    dispatch,
    orderId,
    onClose,
    onClickSave,
    cleanUp,
    isMobileMode,
    isTabletMode,
  ]);

  const showWindowWithUnavailableProducts = useCallback(() => {
    dispatch(
      OpenModal({
        Component: OrderConfirmation,
        props: {
          unavailableProducts: unavailableProducts.map(
            ({ product }) => productsObj[product],
          ),
          header:
            unavailableProducts.length === 1
              ? 'This Product Is No Longer Available.'
              : 'These Products Are No Longer Available.',
          description:
            unavailableProducts.length === 1
              ? 'Do You Want To Create Kit Without This Product? '
              : 'Do You Want To Create Kit Without These Products? ',
          cancelAction: onClose,
          confirmAction: showWindowWithKitName,
        },
        styles: {
          width: 'min(90vw, 651px)',
        },
      }),
    );
  }, [
    dispatch,
    unavailableProducts,
    showWindowWithKitName,
    productsObj,
    onClose,
  ]);

  const onAddToMyKits = useCallback(() => {
    if (unavailableProducts?.length) {
      showWindowWithUnavailableProducts();
    } else {
      showWindowWithKitName();
    }
  }, [
    showWindowWithUnavailableProducts,
    showWindowWithKitName,
    unavailableProducts,
  ]);

  return { onAddToMyKits };
};

export default useAddToKits;
