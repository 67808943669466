// Lib
import { useDispatch } from 'react-redux';

// Actions
import {
  AddSaveForLaterProduct,
  DeleteSaveForLaterProduct,
  DeleteAllSaveForLaterProducts,
  PutSaveForLaterProductInCart,
} from '../../../actions/cart';

const useSaveForLater = () => {
  const dispatch = useDispatch();

  const saveForLater = (product, nums) => {
    dispatch(AddSaveForLaterProduct({ product, nums }));
  };

  const deleteFromLater = (productId) => {
    dispatch(DeleteSaveForLaterProduct(productId));
  };

  const deleteAllFromLater = () => {
    dispatch(DeleteAllSaveForLaterProducts());
  };

  const putFromLaterInCart = (product, nums) => {
    dispatch(PutSaveForLaterProductInCart({ product, nums }));
  };

  return {
    saveForLater,
    deleteFromLater,
    deleteAllFromLater,
    putFromLaterInCart,
  };
};

export default useSaveForLater;
