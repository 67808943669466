// Lib
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

// Actions
import { SetOverLimitProducts } from '../../../actions/cart';

// Utils
import { getProductsOverLimit } from '../../../utils/overLimit';
import { isObjEmpty } from '../../../utils/object';

// Hooks
import useUser from '../../../hooks/useUser';

// Selectors
import { productsObjSelector } from '../../../store/selectors';

const useOverLimitProducts = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { userWithoutQuantityLimit, userWithoutAllLimits, userView } =
    useUser();

  const { cart, productsObj } = useSelector((state) => ({
    cart: state.cart,
    productsObj: productsObjSelector(state),
  }));

  useEffect(() => {
    if (
      !cart.forOrder ||
      userWithoutQuantityLimit ||
      userWithoutAllLimits ||
      isObjEmpty(productsObj)
    ) {
      return;
    }

    dispatch(
      SetOverLimitProducts(
        getProductsOverLimit(
          cart.forOrder,
          pathname === '/cart' ? 1 : cart.shippingAddresses.length,
          productsObj,
          userView,
        ),
      ),
    );
  }, [
    dispatch,
    cart.forOrder,
    cart.shippingAddresses,
    productsObj,
    pathname,
    userWithoutQuantityLimit,
    userWithoutAllLimits,
    userView,
  ]);
};

export default useOverLimitProducts;
