// Lib
import { createTheme } from '@mui/material';
import { isMobile } from 'react-device-detect';

// Constants
import USER_TYPE from '../constants/user';

const theme = createTheme();

const bold = {
  fontWeight: 700,
};

const semibold = {
  fontWeight: 600,
};

const textLg = {
  fontSize: 18,
  fontWeight: 400,
  lineHeight: '22px',
  letterSpacing: '0.2px',
  [theme.breakpoints.down(768)]: {
    fontSize: 16,
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
  [`@media (max-height: 767.85px) and (orientation: landscape)`]: {
    ...(isMobile && {
      fontSize: 16,
      lineHeight: '20px',
      letterSpacing: '0.1px',
    }),
  },
};

const textMd = {
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: '0.1px',
  [theme.breakpoints.down(768)]: {
    fontSize: 14,
    lineHeight: '18px',
  },
  [`@media (max-height: 767.85px) and (orientation: landscape)`]: {
    ...(isMobile && {
      fontSize: 14,
      lineHeight: '18px',
    }),
  },
};

const textSm = (userType) => ({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: userType === USER_TYPE.gilead ? '16px' : '20px',
  [theme.breakpoints.down(768)]: {
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: '0.1px',
  },
  [`@media (max-height: 767.85px) and (orientation: landscape)`]: {
    ...(isMobile && {
      fontSize: 12,
      lineHeight: '16px',
      letterSpacing: '0.1px',
    }),
  },
});

const textXs = {
  fontSize: 12,
  fontWeight: 400,
  lineHeight: '20px',
  [theme.breakpoints.down(768)]: {
    fontSize: 10,
    lineHeight: '12px',
    letterSpacing: '0.1px',
  },
  [`@media (max-height: 767.85px) and (orientation: landscape)`]: {
    ...(isMobile && {
      fontSize: 10,
      lineHeight: '12px',
      letterSpacing: '0.1px',
    }),
  },
};

const createTypography = (userType) => ({
  useNextVariants: true,
  fontFamily:
    userType === USER_TYPE.gilead
      ? 'Proxima Nova, sans-serif'
      : 'Helvetica, sans-serif',
  fontSize: 16,
  body1: {
    fontSize: 16,
    [theme.breakpoints.down(768)]: {
      fontSize: 14,
    },
    [`@media (max-height: 767.85px) and (orientation: landscape)`]: {
      ...(isMobile && {
        fontSize: 14,
      }),
    },
  },
  button: {
    fontSize: 16,
    [theme.breakpoints.down(768)]: {
      fontSize: 14,
    },
    [`@media (max-height: 767.85px) and (orientation: landscape)`]: {
      ...(isMobile && {
        fontSize: 14,
      }),
    },
  },
  h1: {
    fontSize: 36,
    fontWeight: 700,
    lineHeight: '44px',
    [theme.breakpoints.down(768)]: {
      fontSize: 22,
      lineHeight: '28px',
    },
    [`@media (max-height: 767.85px) and (orientation: landscape)`]: {
      ...(isMobile && {
        fontSize: 22,
        lineHeight: '28px',
      }),
    },
  },
  h2: {
    fontSize: 28,
    fontWeight: 700,
    lineHeight: '38px',
    [theme.breakpoints.down(768)]: {
      fontSize: 16,
      lineHeight: '24px',
    },
    [`@media (max-height: 767.85px) and (orientation: landscape)`]: {
      ...(isMobile && {
        fontSize: 16,
        lineHeight: '24px',
      }),
    },
  },
  h3: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '24px',
    [theme.breakpoints.down(768)]: {
      fontSize: 16,
      lineHeight: '20px',
    },
    [`@media (max-height: 767.85px) and (orientation: landscape)`]: {
      ...(isMobile && {
        fontSize: 16,
        lineHeight: '20px',
      }),
    },
  },
  h4: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: '0.1px',
    [theme.breakpoints.down(768)]: {
      fontSize: 14,
      lineHeight: '20px',
    },
    [`@media (max-height: 767.85px) and (orientation: landscape)`]: {
      ...(isMobile && {
        fontSize: 14,
        lineHeight: '20px',
      }),
    },
  },
  textLg,
  textLgSemibold: {
    ...textLg,
    ...semibold,
  },
  textLgBold: {
    ...textLg,
    ...bold,
  },
  textMd,
  textMdSemibold: {
    ...textMd,
    ...semibold,
  },
  textMdBold: {
    ...textMd,
    ...bold,
  },
  textSm: textSm(userType),
  textSmSemibold: {
    ...textSm(userType),
    ...semibold,
  },
  textSmBold: {
    ...textSm(userType),
    ...bold,
  },
  textXs,
  textXsSemibold: {
    ...textXs,
    ...semibold,
  },
  textXsBold: {
    ...textXs,
    ...bold,
  },
});

export default createTypography;
