// Styles
import createFonts from './fonts';
import { colors } from './palette';
import { isMobile } from 'react-device-detect';

// Constants
import USER_TYPE from '../constants/user';

const createComponents = (userType) => {
  const isKiteUser = userType === USER_TYPE.kite;
  const user = [USER_TYPE.kite, USER_TYPE.gilead].includes(userType)
    ? userType
    : USER_TYPE.gilead;

  return {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          overflowX: 'hidden',
          fontFamily: createFonts(userType),
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: 10,
            fontSize: 16,
            padding: '8px 12px !important',
          },
          '&:hover fieldset': {
            borderColor: '#929A92 !important',
          },
          '& .Mui-focused fieldset': {
            borderColor: '#929A92 !important',
            borderWidth: '1px !important',
          },
          '& input': {
            height: 20,
            padding: 0,
            '&:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 1000px white inset',
            },
          },
          '& .MuiAutocomplete-input': {
            padding: '0 !important',
          },
          '& .MuiSelect-select': {
            fontSize: 14,
            minHeight: 20,
            padding: 0,
            zIndex: 1,
          },
          '& .MuiSelect-icon': {
            right: 12,
            top: 'calc(50% - 3px) !important',
            zIndex: 1,
            stroke: isKiteUser ? colors.kite.primary1 : colors.gilead.primary2,
          },
          '&[id*="classic-select"] .MuiSelect-icon': {
            right: 5,
            top: 'calc(50% - 14px) !important',
            stroke: 'none',
          },
          '&[id*="classic-select"] .Mui-disabled': {
            backgroundColor: '#F4F4F4',
            fieldset: {
              border: 'none !important',
            },
            '& #simple-select > span': {
              display: 'none',
            },
          },
          '&[id*="select"] .Mui-disabled svg': {
            stroke: '#929A92',
          },
          '&[id*="select-error"] fieldset': {
            border: `1px solid ${colors.gilead.primary1} !important`,
          },
          '&#select > div': {
            borderRadius: '20px !important',
            color: isKiteUser ? colors.kite.primary1 : colors.gilead.primary2,
            fontWeight: isKiteUser ? 400 : 600,
            padding: '6.5px 16px !important',
            '& .MuiSelect-select': {
              paddingRight: '16px !important',
            },
            '& fieldset': {
              border: 'none',
              backgroundColor: colors.lightGrey,
            },
          },
          '& .Mui-error fieldset': {
            borderColor: colors.gilead.primary1,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: isKiteUser ? colors.kite.primary1 : colors.gilead.primary2,
          fontSize: 14,
        },
        outlined: {
          transform: 'translate(14px, 9px) scale(1)',
        },
        shrink: {
          transform: 'translate(14px, -8px) scale(0.75) !important',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          height: 36,
          paddingLeft: 8,
          paddingRight: 8,
          '& .MuiCheckbox-root': {
            padding: 0,
            paddingRight: 8,
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: '5px',
          '& .MuiSwitch-track': {
            backgroundColor: colors.grey1,
            borderRadius: 30,
            opacity: '1 !important',
          },
          '& .Mui-checked': {
            '&+.MuiSwitch-track': {
              borderRadius: 30,
            },
          },
          '& .MuiSwitch-thumb': {
            backgroundColor: '#FFF !important',
          },
          '&+.MuiTypography-root': {
            fontWeight: isKiteUser ? 400 : 600,
          },
        },
      },
    },
    MuiRating: {
      styleOverrides: {
        root: {
          fontSize: 18,
          '&.Mui-disabled': {
            opacity: 1,
          },
          '& .MuiRating-iconFilled svg': {
            color: isKiteUser
              ? `${colors.kite.primary2} !important`
              : `${colors.gilead.primary1} !important`,
          },
          '& .MuiRating-icon svg': {
            color: colors.grey,
          },
          '& label': {
            marginRight: 3,
          },
          '& .MuiRating-decimal': {
            lineHeight: 0.1,
            marginRight: 3,
          },
          '&#rateable': {
            marginLeft: 8,
            '@media (max-width: 1023.85px)': {
              fontSize: 26,
              '& label': {
                marginRight: 6,
              },
            },
          },
          '@media (max-width: 767.85px)': {
            fontSize: 12,
          },
          '@media (max-height: 767.85px) and (orientation: landscape)': {
            ...(isMobile && { fontSize: 12 }),
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          boxShadow: 'none !important',
          maxHeight: 44,
          padding: '8px 16px',
          textTransform: 'none',
          '&.MuiButton-containedPrimary': {
            fontWeight: isKiteUser ? 400 : 600,
            '&:hover': {
              backgroundColor: isKiteUser
                ? colors.kite.hovered
                : colors.gilead.hovered,
            },
          },
          '&.MuiButton-containedSecondary': {
            color: isKiteUser ? colors.kite.primary1 : colors.gilead.primary2,
            fontWeight: isKiteUser ? 700 : 600,
            '&:active': {
              backgroundColor: 'rgb(170, 170, 170)',
            },
          },
          '&.MuiButton-textPrimary': {
            color: isKiteUser ? colors.kite.primary1 : colors.gilead.primary2,
            fontWeight: isKiteUser ? 400 : 600,
            '&:active': {
              backgroundColor: 'white',
            },
            '&:hover': {
              backgroundColor: 'white',
            },
          },
          '&.Mui-disabled': {
            color: colors.grey2,
            background: colors.disabled,
            '&.MuiButton-containedPrimary:hover': {
              backgroundColor: colors.disabled,
            },
          },
          '&:active': {
            background: isKiteUser
              ? colors.kite.pressed
              : colors.gilead.pressed,
          },
          '@media (max-height: 767.85px) and (orientation: landscape)': {
            ...(isMobile && { fontSize: '12px !important' }),
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
          color: isKiteUser ? colors.kite.primary1 : colors.gilead.primary2,
          '&:hover': {
            color: isKiteUser ? colors.kite.hovered : '#3C7BA3',
          },
          '&.Mui-checked': {
            color: isKiteUser ? '#23639B' : colors.gilead.primary2,
          },
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          '& > div:last-child': {
            '& > div:first-of-type': {
              '& .MuiTypography-root': {
                textTransform: 'lowercase',
                display: 'inline-block',
                '&:first-letter': {
                  textTransform: 'upperCase',
                },
              },
            },
          },
          '& .Mui-selected': {
            backgroundColor: `${colors[user].primary1} !important`,
            borderRadius: 4,
          },
          '& .PrivatePickersMonth-root': {
            '&:hover': {
              borderRadius: 4,
            },
          },
          '& .PrivatePickersYear-yearButton': {
            '&:hover': {
              borderRadius: 4,
            },
          },
          '& .MuiPickersDay-dayWithMargin': {
            '&:hover': {
              borderRadius: 4,
            },
            '&.MuiPickersDay-today': {
              borderRadius: 4,
            },
          },
          '& .PrivatePickersFadeTransitionGroup-root': {
            fontSize: 14,
            fontWeight: isKiteUser ? 700 : 600,
          },
          '& [class*="MuiCalendarPicker-viewTransitionContainer"]': {
            '& > div:first-of-type': {
              '& > div:first-of-type .MuiTypography-root': {
                textTransform: 'lowercase',
                display: 'inline-block',
                '&:first-letter': {
                  textTransform: 'upperCase',
                },
              },
            },
          },
          '& .MuiTypography-root': {
            fontWeight: 700,
          },
          '& .PrivatePickersSlideTransition-root': {
            minHeight: 240,
          },
          "& [role='presentation']:nth-child(1)": {
            fontWeight: isKiteUser ? 700 : 600,
            '& .MuiButtonBase-root': {
              display: 'none',
            },
          },
          '& .MuiPickersArrowSwitcher-root': {
            '& .MuiPickersArrowSwitcher-spacer': {
              width: 0,
            },
          },
          '& .MuiPickersDay-dayOutsideMonth': {
            color: colors.grey2,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'white',
          borderRadius: 10,
          boxShadow: '0px 5px 17px rgba(44, 112, 172, 0.2)',
          color: colors.grey2,
          fontSize: 14,
          marginTop: '6px !important',
          padding: 12,
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        circle: {
          color: colors[userType].primary1,
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          '& .MuiMenuItem-root.Mui-selected': {
            backgroundColor: isKiteUser
              ? 'rgba(44, 112, 172, 0.2)'
              : 'rgba(48, 85, 107, 0.2)',
            '&:hover': {
              backgroundColor: isKiteUser
                ? 'rgba(44, 112, 172, 0.2)'
                : 'rgba(48, 85, 107, 0.2)',
            },
          },
        },
      },
    },
  };
};

export default createComponents;
