// Lib
import React, { useMemo } from 'react';
import { Typography, TextareaAutosize } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Components
import Button from '../../Button';

// Hooks
import useUser from '../../../hooks/useUser';
import useUI from '../../../hooks/useUI';

// Validation schema
import { overLimitValidationSchema } from '../../../validationSchema';

// Styles
import { colors } from '../../../styles/palette';
import use2Modal from '../../../hooks/use2Modal';
import ErrorMessage from '../../Error/ErrorMessage';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 620,
    [theme.breakpoints.down('lg')]: {
      width: 580,
    },
    [theme.breakpoints.down('md')]: {
      width: 236,
    },
  },
  title: {
    width: '85%',
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      width: 'initial',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3.5),

    '& > button:nth-child(1)': {
      marginRight: theme.spacing(3.5),
      color: theme.palette.primary1,
    },
    '& > button:nth-child(2)': {
      width: 91,
    },
    [theme.breakpoints.down('lg')]: {
      '& > button:nth-child(2)': {
        width: 200,
      },
    },
    [theme.breakpoints.down('md')]: {
      '& > button:nth-child(2)': {
        width: 116,
        height: 44,
      },
    },
  },
  message: {
    color: '#54565B',
    fontSize: 18,
    marginTop: 10,
    marginBottom: theme.spacing(2.5),
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      marginTop: 6,
      marginBottom: theme.spacing(2),
    },
  },
  textArea: ({ isKiteUser }) => ({
    border: '1px solid #C6CAC6',
    borderRadius: 10,
    fontFamily: isKiteUser
      ? 'Helvetica, sans-serif'
      : 'Proxima Nova, sans-serif',
    fontSize: 16,
    padding: theme.spacing(1),
    width: '100% !important',
    outline: 0,
    resize: 'none',
    '&::placeholder': {
      color: '#C6CAC6',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  }),
  fieldName: {
    fontWeight: 600,
    marginBottom: 6,
  },
  error: {
    color: colors.gilead.primary1,
    fontSize: 14,
    [theme.breakpoints.down('md')]: {
      fontSize: 10,
    },
  },
}));

const RequestCard = ({
  onSubmit = () => {},
  onChange = () => {},
  loading,
  minLengthOverLimitRequest,
  savedRequest,
  isOverLimitRequest,
  apiError,
}) => {
  const { isSmallMobileMode } = useUI();
  const { isKiteUser } = useUser();
  const classes = useStyles({ isKiteUser });
  const { onClose } = use2Modal();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(overLimitValidationSchema(minLengthOverLimitRequest)),
    defaultValues: {
      request: savedRequest || '',
    },
  });

  const error = useMemo(() => errors?.request?.message, [errors?.request]);

  return (
    <div className={classes.root} data-testid="request-modal">
      <Typography variant="h3" className={classes.title}>
        {isOverLimitRequest
          ? 'Your order total is more than your remaining predetermined allocation.'
          : 'You have chosen a shipping method that requires approval.'}
      </Typography>
      <div className={classes.message}>{`Please submit ${
        isOverLimitRequest ? 'an extension' : 'a shipping'
      }  request.`}</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="request"
          control={control}
          render={({ field }) => (
            <>
              <div className={classes.fieldName}>
                Request <span style={{ color: colors.gilead.primary1 }}>*</span>
              </div>
              <TextareaAutosize
                placeholder="Type a request"
                className={classes.textArea}
                minRows={isSmallMobileMode ? 5 : 8}
                aria-label="empty textarea"
                style={{ width: 200 }}
                maxLength={256}
                {...field}
                onChange={onChange(field.onChange)}
              />
            </>
          )}
        />
        {(error || apiError) && <ErrorMessage message={error || apiError} />}
        <div className={classes.buttons}>
          <Button
            variant="text"
            onClick={onClose}
            data-testid="request-modal-cancel-button">
            Cancel
          </Button>
          <Button
            loadingIndicator="Loading…"
            loading={loading}
            type="submit"
            data-testid="request-modal-submit-button">
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default RequestCard;
