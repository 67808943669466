// Lib
import { useCallback, useEffect, useMemo, useState } from 'react';
import { sub } from 'date-fns';
import { isSameDay } from 'date-fns';
import { useSelector } from 'react-redux';

// Utils
import {
  sortingItems,
  filterItems,
  filterTitles,
} from '../../../constants/orderHistory';
import { includeSearchValue } from '../utils/filterFunctions';
import multiSearch from '../../../utils/multiSearch';

// Hooks
import useUI from '../../../hooks/useUI';

// Selectors
import { productsObjSelector } from '../../../store/selectors';

const useOrdersHistoryFilters = () => {
  const { searchValue, orders, productsObj } = useSelector((state) => ({
    searchValue: state.search,
    orders: state.orders.general,
    productsObj: productsObjSelector(state),
  }));

  const [filteredOrders, setFilteredOrders] = useState(null);
  const initialStartDate = sub(new Date(), { months: 6 });
  const initialEndDate = useMemo(() => new Date(), []);
  const { isMobileMode } = useUI();
  const [sorting, setSorting] = useState(sortingItems[0]);
  const [order, setOrder] = useState('desc');
  const [statuses, setStatuses] = useState(filterItems);
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  const applyFilters = useCallback(() => {
    if (!orders?.length) {
      return;
    }
    let filteredOrders;

    filteredOrders = [...orders].sort((a, b) => {
      if (sorting.title === 'created') {
        return order === 'asc'
          ? new Date(a[sorting.title]) - new Date(b[sorting.title])
          : new Date(b[sorting.title]) - new Date(a[sorting.title]);
      } else {
        if (a[sorting.title] < b[sorting.title]) {
          return order === 'asc' ? -1 : 1;
        }
        if (a[sorting.title] > b[sorting.title]) {
          return order === 'asc' ? 1 : -1;
        }
        return 0;
      }
    });

    filteredOrders = filteredOrders.filter((item) => {
      // TODO logic for tracking number
      if (
        item.order_items.some((orderItem) => {
          const product = productsObj[orderItem.product];
          if (!product) {
            return;
          }
          const { product_id, product_description } = product;
          const match = [product_id, product_description].find((field) => {
            return multiSearch(field, searchValue.split(' '));
          });

          if (match) {
            return true;
          }
        })
      ) {
        return true;
      }

      if (!item.children || item.children.length === 0) {
        return includeSearchValue(item, searchValue);
      } else {
        return item.children.some((itemChild) =>
          includeSearchValue(itemChild, searchValue),
        );
      }
    });

    const checkedStatuses = statuses.filter(
      (status) => status.checked === true,
    );

    if (checkedStatuses.length > 0) {
      filteredOrders = filteredOrders.filter((item) =>
        checkedStatuses.some(
          (checkedStatus) =>
            checkedStatus.title.toLowerCase() === item.status?.toLowerCase(),
          // TODO logic to check children statuses,
        ),
      );
    }

    filteredOrders = filteredOrders.filter((item) => {
      const orderDate = new Date(item.created);

      return (
        (!startDate ||
          isSameDay(orderDate, startDate) ||
          orderDate > startDate) &&
        (!endDate || isSameDay(orderDate, endDate) || orderDate < endDate)
      );
    });

    return filteredOrders;
  }, [
    orders,
    endDate,
    order,
    searchValue,
    startDate,
    sorting.title,
    statuses,
    productsObj,
  ]);

  const onRemoveFilter = useCallback(
    (filterTitle, title) => () => {
      setStatuses((prevStatuses) =>
        prevStatuses.map((status) =>
          status.title === title ? { ...status, checked: false } : status,
        ),
      );
    },
    [setStatuses],
  );

  const onClearAllFilters = useCallback(() => {
    setStatuses((prevStatuses) =>
      prevStatuses.map((status) => ({ ...status, checked: false })),
    );
  }, [setStatuses]);

  const changeOrder = () =>
    setOrder((prevOrder) => (prevOrder === 'desc' ? 'asc' : 'desc'));

  const onChangeStatus = (title, checked) => {
    setStatuses((prevStatuses) =>
      prevStatuses.map((status) =>
        status.title === title ? { ...status, checked } : status,
      ),
    );
  };

  const onChangeStatuses = useCallback(
    () => (event) => {
      const checkedValues = event.target.value;
      setStatuses((prevStatuses) =>
        prevStatuses.map((status) => ({
          ...status,
          checked: !!checkedValues.includes(status.title),
        })),
      );
    },
    [],
  );

  const onChangeSorting = useCallback(
    (title) => () => {
      setSorting(
        sortingItems.find((item) => item.title === title) || sortingItems[0],
      );
    },
    [],
  );

  const onChangeMobileSorting = (title) => {
    setSorting(
      sortingItems.find((item) => item.title === title) || sortingItems[0],
    );
  };

  const onChangeStartDate = (date) => {
    setStartDate(date);
  };

  const onChangeEndDate = (date) => {
    setEndDate(date);
  };

  const statusesAreNotDefault = useMemo(
    () => statuses.some((status) => status.checked),
    [statuses],
  );

  const sortingIsNotDefault = useMemo(
    () => sorting.name !== 'date' || order !== 'desc',
    [sorting, order],
  );

  const datesAreNotDefault = useMemo(
    () =>
      !isSameDay(startDate, initialStartDate) ||
      !isSameDay(endDate, initialEndDate),
    [initialStartDate, initialEndDate, startDate, endDate],
  );

  const filtersNotDefault = () => {
    return (
      sortingIsNotDefault ||
      statusesAreNotDefault ||
      datesAreNotDefault ||
      searchValue
    );
  };

  const applyMobileFilters = () => {
    setFilteredOrders(applyFilters());
  };

  useEffect(() => {
    if (isMobileMode) {
      return;
    }
    if (!orders) {
      return;
    }

    setFilteredOrders(applyFilters());
  }, [
    sorting,
    order,
    statuses,
    startDate,
    endDate,
    searchValue,
    setFilteredOrders,
    isMobileMode,
    orders,
  ]);

  useEffect(() => {
    if (isMobileMode) {
      applyMobileFilters();
    }
  }, [isMobileMode, searchValue, orders]);

  const toInitialState = () => {
    setSorting(sortingItems[0]);
    setOrder('desc');
    setStatuses(filterItems);
    setStartDate(sub(new Date(), { months: 6 }));
    setEndDate(new Date());
  };

  return {
    sorting,
    order,
    statuses,
    startDate,
    endDate,
    onRemoveFilter,
    onClearAllFilters,
    onChangeStatus,
    onChangeStatuses,
    onChangeSorting,
    onChangeStartDate,
    onChangeEndDate,
    changeOrder,
    sortingItems,
    filterTitles,
    filteredOrders,
    toInitialState,
    statusesAreNotDefault,
    sortingIsNotDefault,
    filtersNotDefault,
    onChangeMobileSorting,
    applyMobileFilters,
    applyFilters,
  };
};

export default useOrdersHistoryFilters;
