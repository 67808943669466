// Lib
import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { CloseModal, OpenModal } from '../actions/modal';

const useModal = () => {
  const dispatch = useDispatch();
  const { open, props, Component, closeIcon, children, styles, className } =
    useSelector((state) => state?.modal);

  const onOpen = useCallback(
    (data) => () => dispatch(OpenModal(data)),
    [dispatch],
  );
  const onClose = useCallback(() => dispatch(CloseModal()), [dispatch]);

  return {
    open,
    onClose,
    onOpen,
    closeIcon,
    children,
    Component,
    styles,
    props,
    className,
  };
};

export default useModal;
