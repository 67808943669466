// Lib
import React from 'react';
import { Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

// Components
import IconButton from '../../../Button/IconButton/IconButton';
import EditRequestButton from '../../../Button/EditRequestButton';
import Feather from '../../../Feather';

// Hooks
import useUI from '../../../../hooks/useUI';
import useCart from '../../../../pages/Cart/hooks/useCart';
import useRequest from '../../../../pages/Cart/hooks/useRequest';

// Styles
import { colors } from '../../../../styles/palette';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    margin: '0 auto',
    maxWidth: 1180,
    position: 'sticky',
    padding: `40px 26px 0`,
    top: 50,
    zIndex: 100,
    [theme.breakpoints.down('lg')]: {
      top: 40,
    },
    [theme.breakpoints.down('md')]: {
      top: 50,
      padding: `${theme.spacing(2.5)} ${theme.spacing(1.5)} 0`,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        top: 40,
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        top: 50,
        padding: `${theme.spacing(2.5)} ${theme.spacing(1.5)} 0`,
      }),
    },
  },
  title: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      fontSize: 22,
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 22,
        marginBottom: theme.spacing(2),
      }),
    },
  },
  buttonContainer: {
    alignItems: 'center',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(0.5),
      '& h3': {
        fontSize: 14,
      },
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        marginBottom: theme.spacing(0.5),
        '& h3': {
          fontSize: 14,
        },
      }),
    },
  },
  editQty: {
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      padding: '2px',
    },
    [theme.breakpoints.downLandscape('sm')]: {
      ...(theme.isMobileDevice && {
        padding: '2px',
      }),
    },
  },
}));

const CartBar = ({ countInCart }) => {
  const { isTabletMode, isSmallMobileMode } = useUI();
  const { onDeleteAllProducts } = useCart();
  const classes = useStyles();
  const { openRequestCard, savedRequest, isRequestRequired } = useRequest(
    null,
    true,
  );

  return (
    <div className={classes.root}>
      {isTabletMode && (
        <Typography alignSelf="start" variant="h2" className={classes.title}>
          Cart<span style={{ color: colors.grey1 }}>({countInCart})</span>
        </Typography>
      )}
      {!!countInCart && (
        <>
          <div className={classes.buttonContainer}>
            <Typography variant="h3">{`Total Items: ${countInCart}`}</Typography>
            <Box display="flex" gap={4}>
              {!isSmallMobileMode && savedRequest && isRequestRequired && (
                <EditRequestButton
                  onClick={openRequestCard}
                  title="Edit Qty Request"
                />
              )}
              <IconButton
                onClick={onDeleteAllProducts}
                startIcon={<Feather type="delete" />}
                data-testid="cart-remove-all-button">
                Remove All
              </IconButton>
            </Box>
          </div>
          {isSmallMobileMode && savedRequest && isRequestRequired && (
            <EditRequestButton
              onClick={openRequestCard}
              title="Edit Qty Request"
            />
          )}
        </>
      )}
    </div>
  );
};

export default CartBar;
