// Lib
import React from 'react';
import SliderSlick from 'react-slick';
import classnames from 'classnames';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

// Components
import Button from '../Button';
import Feather from '../Feather';

// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: `-${theme.spacing(2)}`,
    marginRight: `-${theme.spacing(2)}`,
    padding: '4px 16px 20px 20px',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      padding: '4px 12px 20px 16px',
    },
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  slider: {
    '& .slick-disabled svg path': {
      stroke: '#929A92',
    },
    '& .slick-slide > div': {
      marginRight: 20,
    },
    '& .slick-list': {
      overflow: 'initial',
      marginRight: -20,
    },
    '& .slick-arrow': {
      height: 24,
      left: 'initial',
      top: '-26px',
      width: 24,
    },
    '& .slick-prev': {
      right: 34,
      '&:before': {
        content: 'none !important',
      },
    },
    '& .slick-track': {
      marginLeft: 0,
    },
    '& .slick-next': {
      right: 0,
      '&:before': {
        content: 'none !important',
      },
    },
    [theme.breakpoints.down('md')]: {
      '& .slick-slide > div': {
        marginRight: 16,
      },
      '& .slick-list': {
        overflow: 'initial',
        marginRight: -16,
      },
    },
  },
  buttonArrow: {
    background: theme.palette.grey1,
    padding: 0,
    width: 24,
    minWidth: 24,
  },
}));

const PrevArrow = ({ className, style, onClick }) => {
  const classes = useStyles();

  return (
    <Button
      className={classnames(className, classes.buttonArrow)}
      style={{ ...style, display: 'block', background: '#F4F4F4' }}
      onClick={onClick}>
      <Feather printType="stroke" type="arrowSlideLeft" />
    </Button>
  );
};

const NextArrow = ({ className, style, onClick }) => {
  const classes = useStyles();

  return (
    <Button
      className={classnames(className, classes.buttonArrow)}
      style={{ ...style, display: 'block', background: '#F4F4F4' }}
      onClick={onClick}>
      <Feather printType="stroke" type="arrowSlideRight" />
    </Button>
  );
};

const settings = {
  arrows: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  centerPadding: '30px',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
  ],
};

const Slider = ({ title, children, ...props }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {title && (
        <Typography className={classes.title} variant="h3">
          {title}
        </Typography>
      )}
      <SliderSlick className={classes.slider} {...settings} {...props}>
        {children}
      </SliderSlick>
    </div>
  );
};

export default Slider;
