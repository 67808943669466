// Lib
import React, { useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Components
import Button from '../Button';

// Constants
import { EMPTY_CART } from '../../constants/cart';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    minHeight: 'calc(50vh - 150px)',
  },
  message: {
    marginBottom: theme.spacing(5),
    width: 350,
    [theme.breakpoints.down('md')]: {
      width: 210,
    },
  },
  button: {
    width: 146,
    minHeight: 46,
    [theme.breakpoints.down('lg')]: {
      width: 170,
      minHeight: 44,
    },
  },
}));

const CartLayout = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const onClick = useCallback(() => navigate('/materials'), [navigate]);

  return (
    <div className={classes.root}>
      <Typography className={classes.message} variant="h1" textAlign="center">
        {EMPTY_CART}
      </Typography>
      <Button onClick={onClick} className={classes.button}>
        Go To Materials
      </Button>
    </div>
  );
};

export default CartLayout;
