//Libs
import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

// Hooks
import useUser from '../../../hooks/useUser';
import useUI from '../../../hooks/useUI';

const useStyles = makeStyles((theme) => ({
  linkText: ({ isKiteUser, isTabletMode, isMobileMode }) => ({
    color: isKiteUser ? theme.palette.primary1 : theme.palette.pressed,
    display: 'block',
    fontWeight: isKiteUser
      ? '400'
      : isTabletMode && !isMobileMode
      ? '700'
      : '600',
    textDecoration: 'none',
    fontStyle: 'normal',
    fontSize: isTabletMode && !isMobileMode ? '18px' : '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
}));

const OrderIdLink = ({ order }) => {
  const { isKiteUser } = useUser();
  const { isDesktopMode, isTabletMode, isMobileMode } = useUI();
  const classes = useStyles({ isKiteUser, isTabletMode, isMobileMode });

  const isDistro = useMemo(
    () => !!order?.addresses?.length && !order.parent,
    [order?.addresses?.length, order.parent],
  );

  return (
    <div
      style={{
        position: 'relative',
      }}
      data-testid={'order-id-link'}>
      <Typography
        component={Link}
        title={order.order_sn}
        to={`${order.id}`}
        className={classes.linkText}>
        {!isDesktopMode && 'Order ID: '}#{order.order_sn}
      </Typography>
      {isDistro && isDesktopMode && (
        <span
          style={{
            color: '#929A92',
            position: 'absolute',
            fontSize: 12,
            top: 15,
          }}>
          Distro
        </span>
      )}
    </div>
  );
};

export default OrderIdLink;
