// Lib
import storage from 'redux-persist/lib/storage';
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';

// Root Saga
import mainSaga from '../sagas';

// Root reducer
import reducers from '../reducers';
import cart from '../reducers/cart';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'auth', 'location'],
};

const cartPersistConfig = {
  key: 'cart',
  storage,
  blacklist: ['placedOrder', 'forLater'],
};

const rootReducer = combineReducers({
  cart: persistReducer(cartPersistConfig, cart),
  ...reducers,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middleware),
  devTools: process.env.NODE_ENV !== 'production',
});

sagaMiddleware.run(mainSaga);

const persistor = persistStore(store);
export { store, persistor };
