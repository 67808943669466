// Lib
import React from 'react';
import { makeStyles } from '@mui/styles';

// Styles
import { colors } from '../../styles/palette';
import Feather from '../Feather';

const useStyles = makeStyles((theme) => ({
  error: {
    height: '40vh',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    color: colors.gilead.primary1,
    fontSize: 22,
    '& svg': {
      height: 22,
      width: 22,
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 18,
      '& svg': {
        height: 18,
        width: 18,
      },
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      '& svg': {
        height: 14,
        width: 14,
      },
    },
  },
}));

const Error = ({ message }) => {
  const classes = useStyles();

  return (
    <div className={classes.error}>
      <Feather style={{ marginRight: 8 }} type="error" nativeColor />
      {message}
    </div>
  );
};

export default Error;
