import breakpoints from '../styles/breakpoints';

const getDeltaViewportScreen = () => {
  const screenHeight = window?.screen?.height || 0;
  const screenWidth = window?.screen?.width || 0;
  const layoutWidth = window.innerWidth;
  const layoutHeight = window.innerHeight;
  const isLandscapeOrientation = layoutWidth >= layoutHeight;

  const landscapeHeight =
    screenHeight > screenWidth ? screenWidth : screenHeight;

  const isTablet =
    landscapeHeight >= breakpoints.values.md &&
    landscapeHeight < breakpoints.values.lg;

  if (
    !isTablet ||
    // skip logic for emulation mode
    (isLandscapeOrientation &&
      screenHeight === layoutHeight &&
      screenWidth === layoutWidth)
  ) {
    return 0;
  }

  let deltaViewportScreen = screenHeight - layoutHeight;

  // Landscape orientation
  if (isLandscapeOrientation) {
    deltaViewportScreen = screenWidth - layoutHeight;
  }

  localStorage.setItem('deltaViewportScreen', String(deltaViewportScreen));
};

export default getDeltaViewportScreen;
