// Lib
import React, { useCallback, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

// Components
import Input from '../../../Field/Input';

// Validation Schema
import { editApproveQtySchema } from '../../../../validationSchema';

const useStyles = makeStyles(() => ({
  input: {
    '& input': {
      textAlign: 'right',
    },
    '& .MuiInputBase-formControl': {
      borderRadius: 6,
      padding: '10px 4px!important',
    },
    '& #input-error': {
      fontSize: 10,
    },
  },
}));

const QtyInput = ({
  value,
  label,
  onChange = () => {},
  orderLimit,
  setOrderQtyError = () => {},
}) => {
  const classes = useStyles();

  const {
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(editApproveQtySchema),
    defaultValues: {
      orderQty: value,
    },
  });

  useEffect(() => {
    setOrderQtyError(errors?.orderQty?.message);
  }, [errors?.orderQty, setOrderQtyError]);

  const onChangeInput = useCallback(
    (onChangeField) =>
      ({ value }) => {
        if (value && value == 0) return;
        if (value > orderLimit) {
          onChangeField(orderLimit);
          onChange(orderLimit);
        } else {
          onChangeField(value);
          onChange(value);
        }
      },
    [onChange, orderLimit],
  );

  return (
    <Controller
      name="orderQty"
      control={control}
      render={({ field }) => (
        <Input
          {...field}
          autoFocus={true}
          value={value}
          className={classes.input}
          label={label}
          onChange={onChangeInput(field.onChange)}
          error={errors?.orderQty?.message}
        />
      )}
    />
  );
};

export default QtyInput;
