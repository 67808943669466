import React from 'react';

import { Box, Typography } from '@mui/material';
import useUI from '../../hooks/useUI';
import ValidateCard from '../../components/Products/Card/ValidateCard';

export default function ResultList({ validate, type, message }) {
  const { isDesktopMode, isTabletMode } = useUI();

  const maxWidth = isDesktopMode ? 1128 : isTabletMode ? 600 : 300;
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      pt={8}
      pl={2}
      pr={2}>
      <Box
        display={'flex'}
        rowGap={2}
        maxWidth={maxWidth}
        width={'100%'}
        flexDirection={'column'}>
        <Typography variant={'h1'}>{titleMapper[type]}</Typography>
        <Subheader type={type} validate={validate} />
        <ValidateCard key={`${validate?.product_id}`} product={validate} />
        {message && (
          <Typography variant={'textMdSemibold'} color={'#C5203F'}>
            {message}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

const titleMapper = {
  expired: 'This product has been expired',
  withdraw: 'This product has been withdrawn',
  available: 'Approved for Use',
  outOfStock: 'Out of stock',
};

function Subheader({ type, validate }) {
  console.log(new Date(validate?.expiration_date));
  return (
    <>
      {type === 'available' && (
        <Box display={'flex'} columnGap={3}>
          <Typography variant={'textMdSemibold'}>
            PI or IF Required: {validate?.pi_required ? 'Yes' : 'No'}
          </Typography>
          {validate?.pi_required && (
            <Typography variant={'textMdSemibold'}>
              PI or IF Associated:{' '}
              {validate?.pi_associated.map((pi) => pi).join('| ')}
            </Typography>
          )}
        </Box>
      )}
      {type === 'expired' && (
        <Typography variant={'textMdSemibold'}>
          Expiration Date: {convertDate(validate?.expiration_date)}
        </Typography>
      )}
      {type === 'withdraw' && (
        <Typography variant={'textMdSemibold'}>
          Withdrawal Effective Date:{' '}
          {convertDate(validate?.withdrawal_effective_date)}
        </Typography>
      )}
    </>
  );
}

function convertDate(date) {
  const [year, month, day] = date.split('-');
  return `${month}/${day}/${year}`;
}
