// Libs
import { useNavigate } from 'react-router-dom';

// Hooks
import useDeleteKits from './useDeleteKits';
import useEditKit from './useEditKit';
import useReorderKit from './useReorderKit';

const useKitActions = (kit) => {
  const navigate = useNavigate();

  const { onDeleteMyKit: onDeleteClick, onDeleteAllKits: onDeleteAllClick } =
    useDeleteKits();
  const { onEditKitName } = useEditKit();
  const { onReorderKit, canReorder } = useReorderKit(kit);

  const onViewDetailsClick = (kitId) => {
    navigate(`${kitId}`);
  };

  return {
    onReorderClick: onReorderKit,
    onDeleteClick,
    onDeleteAllClick,
    onViewDetailsClick,
    onEditKitName,
    canReorder,
  };
};

export default useKitActions;
