// Libs
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { UserLogIn, UserLogOut } from '../../../actions/user';
import { ClearApiError } from '../../../actions/apiError';
import { SetPrevPath } from '../../../actions/location';
import { useLocation } from 'react-router-dom';

const useAuth = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [type, setType] = useState('password');

  const { apiError, sso, accessToken, isLoading } = useSelector((state) => ({
    apiError: state.apiError['logIn'],
    sso: state.auth.sso,
    accessToken: state.auth.accessToken,
    isLoading: state.isLoading,
  }));

  const changeType = useCallback(
    () => setType((prevType) => (prevType === 'text' ? 'password' : 'text')),
    [setType],
  );

  const onSubmit = useCallback((data) => dispatch(UserLogIn(data)), [dispatch]);

  const onChange = useCallback(
    (onChangeField) =>
      ({ value }) => {
        if (apiError) {
          dispatch(ClearApiError());
        }
        onChangeField(value);
      },
    [dispatch, apiError],
  );

  const onLogout = useCallback(() => {
    dispatch(SetPrevPath(pathname));
    dispatch(UserLogOut());
  }, [dispatch, pathname]);

  return {
    type,
    changeType,
    onSubmit,
    onChange,
    apiError,
    onLogout,
    isLoading,
    ssoAuth: sso,
    accessToken,
  };
};

export default useAuth;
