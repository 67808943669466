// Libs
import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { Box, Typography, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import classnames from 'classnames';
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux';

// Components
import OrderIdLink from '../../Buttons/OrderIdLink';
import IconButton from '../../../Button/IconButton/IconButton';
import Track from '../../../OrderHistory/Buttons/Track';
import ToggleButton from '../../../Button/ToggleButton';
import Reorder from '../../../Button/ReorderButton/ReorderButton';
import AddressesTruncatedLabel from '../../Labels/AddressesTruncatedLabel';

// Hooks
import useUser from '../../../../hooks/useUser';
import useUI from '../../../../hooks/useUI';
import useAddToKits from '../../../../pages/Kits/hooks/useAddToKits';

// Utils
import capitalize from '../../../../utils/capitalize';
import {
  getOrderQty,
  getTotalOrderedQty,
} from '../../../../pages/OrderHistory/utils/getQuantity';
import isProductAvailable from '../../../../utils/isProductAvailable';
import getGridPropValues from '../../../../utils/getGridPropValues';

// Selectors
import { productsObjSelector } from '../../../../store/selectors';

const useStyles = makeStyles((theme) => ({
  card: ({ withChildren }) => ({
    backgroundColor: withChildren ? '#F4F4F4' : 'white',
    boxShadow: '0px 5px 15px rgba(44, 112, 172, 0.2)',
    borderRadius: withChildren ? '20px 20px 0px 0px' : '20px',
    paddingBottom: theme.spacing(2.75),
    height: 168,
    marginBottom: withChildren ? 0 : theme.spacing(2.5),
    '&:first-child': {
      marginTop: theme.spacing(2),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      height: 180,
      borderRadius: ({ withChildren }) =>
        withChildren ? '10px 10px 0px 0px' : 10,
      marginBottom: ({ withChildren }) =>
        withChildren ? 0 : theme.spacing(1.5),
      paddingBottom: theme.spacing(1.25),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        height: 180,
        borderRadius: ({ withChildren }) =>
          withChildren ? '10px 10px 0px 0px' : 10,
        marginBottom: ({ withChildren }) =>
          withChildren ? 0 : theme.spacing(1.5),
        paddingBottom: theme.spacing(1.25),
      }),
    },
  }),
  headerText: ({ isKiteUser }) => ({
    color: isKiteUser ? theme.palette.darkGrey : theme.palette.black,
    fontWeight: isKiteUser ? 700 : 600,
    fontSize: '14px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: '12px',
      }),
    },
  }),
  text: ({ isKiteUser }) => ({
    color: theme.palette.darkGrey,
    fontWeight: isKiteUser ? 400 : 600,
    fontSize: '14px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: '12px',
      }),
    },
  }),
  reorderContainer: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: '5px !important',
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        paddingLeft: '5px !important',
      }),
    },
    '& #reorder': {
      [theme.breakpoints.down('lg')]: {
        height: 36,
        width: 119,
      },
      [theme.breakpoints.down('md')]: {
        width: 130,
      },
      [theme.breakpoints.downLandscape('lg')]: {
        ...(theme.isMobileDevice && {
          height: 36,
          width: 119,
        }),
      },
      [theme.breakpoints.downLandscape('md')]: {
        ...(theme.isMobileDevice && {
          width: 130,
        }),
      },
    },
  },
  totalItems: {
    color: () => theme.palette.red,
  },
}));

const OrderCard = ({
  order,
  onReorder,
  isDistro = false,
  withChildren = false,
  onToggleChildren = () => {},
  className,
}) => {
  const productsObj = useSelector((state) => productsObjSelector(state));
  const { isKiteUser } = useUser();
  const { onAddToMyKits } = useAddToKits(order.id);
  const classes = useStyles({
    isKiteUser,
    withChildren,
  });
  const theme = useTheme();

  const { isMobileMode, isTabletMode } = useUI();
  const gridPropValues = getGridPropValues(isTabletMode, isMobileMode);

  const canReorder = useMemo(
    () =>
      !!order?.order_items.find(({ product }) =>
        isProductAvailable(productsObj[product]),
      ),
    [order, productsObj],
  );

  const buttons = (
    <>
      <Grid
        container
        alignContent={isMobileMode ? 'center' : 'initial'}
        item
        flexBasis={gridPropValues({
          lgSize: 12,
          mdSize: 8,
          unit: '%',
        })}
        maxWidth={gridPropValues({
          lgSize: 12,
          mdSize: 8,
          unit: '%',
        })}
        order={1}>
        <OrderIdLink order={order} />
      </Grid>
      <Grid
        container
        item
        maxWidth={gridPropValues({
          lgSize: 12,
          mdSize: 6,
          unit: '%',
        })}
        flexBasis={gridPropValues({
          lgSize: 12,
          mdSize: 6,
          unit: '%',
        })}
        order={gridPropValues({ lgSize: 2, mdSize: 6 })}
        alignContent={isMobileMode ? 'center' : 'initial'}>
        {!isDistro && (
          <IconButton
            data-testid={'add-to-kits-button'}
            disabled={!order.can_be_added_to_kit}
            onClick={onAddToMyKits}
            startIcon={<AddIcon />}>
            Add To My Kits
          </IconButton>
        )}
      </Grid>
    </>
  );

  return (
    <Grid
      container
      spacing={gridPropValues({
        lgSize: 2.5,
        mdSize: '10px',
      })}
      className={classnames(className, classes.card)}
      data-testid="order-card">
      {!isMobileMode ? (
        <Grid
          item
          flexBasis={gridPropValues({
            lgSize: 5,
            mdSize: 12,
            unit: '%',
          })}
          maxWidth={gridPropValues({
            lgSize: 5,
            mdSize: 12,
            unit: '%',
          })}>
          {buttons}
        </Grid>
      ) : (
        buttons
      )}
      {!isMobileMode && (
        <>
          <Grid
            item
            flexBasis={gridPropValues({
              lgSize: 2,
              unit: '%',
            })}
            order={gridPropValues({ lgSize: 2 })}>
            <Box display="flex" flexDirection="column" data-testid="order-qty">
              <Typography className={classes.headerText}>Order Qty:</Typography>
              <Typography className={classes.text}>
                {getOrderQty(order)}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            flexBasis={gridPropValues({
              lgSize: 2,
              unit: '%',
            })}
            order={gridPropValues({
              lgSize: 3,
            })}>
            <Box
              display="flex"
              flexDirection="column"
              data-testid="total-ordered">
              <Typography
                className={classnames(classes.headerText, classes.totalItems)}>
                Total Ordered:
              </Typography>
              <Typography
                className={classnames(classes.text, classes.totalItems)}>
                {getTotalOrderedQty(order, productsObj)}
              </Typography>
            </Box>
          </Grid>
        </>
      )}
      <Grid
        item
        flexBasis={gridPropValues({
          lgSize: 3,
          mdSize: 4,
          unit: '%',
        })}
        container
        pr={gridPropValues({
          lgSize: 2.5,
          mdSize: '10px',
        })}
        justifyContent="right"
        order={gridPropValues({
          lgSize: 4,
          mdSize: 2,
        })}>
        {order?.tracking_link && (
          <Track
            trackingLink={order.tracking_link}
            styles={{ paddingBlock: 0, paddingInline: '10px' }}
          />
        )}
        {isDistro && (
          <>
            {isMobileMode && (
              <span
                style={{
                  color: '#929A92',
                  fontSize: 12,
                  marginRight: 10,
                  marginTop: 5,
                }}
                data-testid="distro-label">
                Distro
              </span>
            )}
            {!!order?.children?.length && (
              <ToggleButton
                onToggle={onToggleChildren}
                open={withChildren}
                data-testid="distro-toggle-button"
              />
            )}
          </>
        )}
      </Grid>
      <Grid
        item
        flexBasis={gridPropValues({
          lgSize: 5,
          mdSize: 12,
          unit: '%',
        })}
        maxWidth={gridPropValues({
          lgSize: 5,
          mdSize: 12,
          unit: '%',
        })}
        order={gridPropValues({
          lgSize: 5,
          mdSize: 4,
        })}
        paddingRight={isMobileMode ? '10px' : 0}>
        <Box
          display="flex"
          flexDirection="column"
          data-testid="shipping-address">
          <Typography className={classes.headerText}>
            Shipping Address:
          </Typography>
          <AddressesTruncatedLabel
            stringsQty={isTabletMode && isMobileMode ? 1 : 2}
            order={order}
            className={classes.text}
          />
        </Box>
      </Grid>
      <Grid
        item
        flexBasis={gridPropValues({
          lgSize: 2,
          unit: '%',
        })}
        order={gridPropValues({
          lgSize: 6,
          mdSize: 2,
        })}>
        <Box display="flex" flexDirection="column" data-testid="order-time">
          <Typography className={classes.headerText}>Order Time:</Typography>
          {order.created && (
            <div
              className={classes.text}
              style={{ display: isMobileMode ? 'flex' : 'initial' }}>
              <div>{format(new Date(order.created), 'M/d/yyy')}</div>
              {isMobileMode && <>,&nbsp;</>}
              <div>{format(new Date(order.created), 'hh:mm a')}</div>
            </div>
          )}
        </Box>
      </Grid>
      <Grid
        item
        flexBasis={gridPropValues({
          lgSize: 2,
          unit: '%',
        })}
        order={gridPropValues({
          lgSize: 7,
          mdSize: 3,
        })}>
        <Box
          display="flex"
          flexDirection="column"
          data-testid="order-status"
          paddingLeft={isMobileMode ? '20px' : 0}>
          <Typography className={classes.headerText}>Status:</Typography>
          <Typography className={classes.text}>
            {capitalize(
              order?.status === 'VERACORE FAILED' ? 'ORDERED' : order?.status,
              true,
            )}
          </Typography>
        </Box>
      </Grid>
      <Grid
        className={classes.reorderContainer}
        item
        maxWidth={gridPropValues({
          lgSize: 12,
          mdSize: 6,
          unit: '%',
        })}
        flexBasis={gridPropValues({
          lgSize: 3,
          mdSize: 6,
          unit: '%',
        })}
        container
        order={8}
        pr={isMobileMode ? 1.25 : 2.5}
        alignContent="end"
        justifyContent="right">
        {order?.address && (
          <Reorder
            disabled={!canReorder}
            order={order}
            onClick={onReorder}
            styles={{ paddingInline: theme.spacing(4) }}
            inTable={true}
          />
        )}
        {isDistro && isTabletMode && !isMobileMode && (
          <span
            style={{
              color: '#929A92',
              fontSize: 12,
            }}
            data-testid="distro-label">
            Distro
          </span>
        )}
      </Grid>
    </Grid>
  );
};

export default OrderCard;
