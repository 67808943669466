// Lib
import { createReducer } from '@reduxjs/toolkit';

// Actions
import {
  SetMyKits,
  SetKitActionErrorMessage,
  CleanKitActionErrorMessage,
  SetKitDetails,
  ClearKitDetails,
  SetNewKitName,
  SetKitProductQty,
  AddNewKitProduct,
  UpdateNewKitProductQty,
  AddExistingKitProduct,
  UpdateExistingKitProductQty,
  CleanNewKitProducts,
  CleanExistingKitProducts,
  SetKitAfterRemoveProduct,
  SetSelectingKitProductsProcess,
} from '../actions/kits';

export const initialState = {
  data: null,
  currentKit: null,
  kitActionErrorMessage: '',
  selectingKitProducts: false,
  newKitProducts: [],
  existingKitProducts: [],
};

export default createReducer(initialState, {
  [SetMyKits]: (state, { payload }) => ({ ...state, data: payload }),
  [SetNewKitName]: (state, { payload }) => ({
    ...state,
    currentKit: { ...state.currentKit, name: payload },
  }),
  [SetKitActionErrorMessage]: (state, { payload }) => ({
    ...state,
    kitActionErrorMessage: payload,
  }),
  [CleanKitActionErrorMessage]: (state) => ({
    ...state,
    kitActionErrorMessage: initialState.kitActionErrorMessage,
  }),
  [SetKitDetails]: (state, { payload }) => ({ ...state, currentKit: payload }),
  [ClearKitDetails]: (state) => ({
    ...state,
    currentKit: initialState.currentKit,
  }),
  [SetKitProductQty]: (state, { payload }) => {
    const { productId, newQty } = payload;
    return {
      ...state,
      currentKit: {
        ...state.currentKit,
        products: state.currentKit.products.map((item) =>
          item.product === productId ? { ...item, nums: newQty } : item,
        ),
      },
    };
  },
  [SetKitAfterRemoveProduct]: (state, { payload }) => {
    const { productId } = payload;
    return {
      ...state,
      currentKit: {
        ...state.currentKit,
        products: state.currentKit.products.filter(
          (item) => item.product !== productId,
        ),
      },
    };
  },
  [SetSelectingKitProductsProcess]: (state, { payload }) => ({
    ...state,
    selectingKitProducts: payload,
  }),
  [AddNewKitProduct]: (state, { payload }) => {
    const { productId, qty } = payload;
    return {
      ...state,
      newKitProducts: [...state.newKitProducts, { productId, nums: qty }],
    };
  },
  [UpdateNewKitProductQty]: (state, { payload }) => {
    const { productId, newQty } = payload;
    return {
      ...state,
      newKitProducts: state.newKitProducts.map((item) =>
        item.productId === productId ? { ...item, nums: newQty } : item,
      ),
    };
  },
  [AddExistingKitProduct]: (state, { payload }) => {
    const { productId, qty } = payload;
    return {
      ...state,
      existingKitProducts: [
        ...state.existingKitProducts,
        { productId, nums: qty },
      ],
    };
  },
  [UpdateExistingKitProductQty]: (state, { payload }) => {
    const { productId, newQty } = payload;
    return {
      ...state,
      existingKitProducts: state.existingKitProducts.map((item) =>
        item.productId === productId ? { ...item, nums: newQty } : item,
      ),
    };
  },
  [CleanNewKitProducts]: (state) => ({
    ...state,
    newKitProducts: initialState.newKitProducts,
  }),
  [CleanExistingKitProducts]: (state) => ({
    ...state,
    existingKitProducts: initialState.existingKitProducts,
  }),
});
