// Lib
import React, { useCallback, useState } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';

// Components
import ProductRatingCard from './ProductRatingCard';
import FooterButtons from '../../Modal/FooterButtons';

// Hooks
import use2Modal from '../../../hooks/use2Modal';

// Utils
import { isObjEmpty } from '../../../utils/object';

// Selectors
import { productsObjSelector } from '../../../store/selectors';

const useStyles = makeStyles((theme) => ({
  title: {
    letterSpacing: '-0.4px',
    marginBottom: theme.spacing(4),
    width: '80%',
    [theme.breakpoints.down('md')]: {
      letterSpacing: 'initial',
      marginBottom: theme.spacing(2.5),
      width: '95%',
    },
  },
  buttons: {
    [theme.breakpoints.down('lg')]: {
      '& #accept-button': {
        width: 110,
      },
    },
    [theme.breakpoints.down('md')]: {
      gap: theme.spacing(4),
    },
  },
}));

const RatingCard = ({ onSave = () => {}, updateProductRating = () => {} }) => {
  const classes = useStyles();
  const { onClose } = use2Modal();
  const { productsForRate, productsObj } = useSelector((state) => ({
    productsForRate: state.cart.forRate,
    productsObj: productsObjSelector(state),
  }));
  const [notRatedProducts, setNotRatedProducts] = useState({});
  const [loading, setLoading] = useState(false);

  const onSaveRating = useCallback(() => {
    const errors = {};
    productsForRate.forEach((product) => {
      if (!product.user_rate) {
        errors[product.product_id] = 'Product is not rated';
      }
    });
    if (isObjEmpty(errors)) {
      onSave(productsForRate, setLoading);
    } else {
      setNotRatedProducts(errors);
    }
  }, [onSave, productsForRate, setNotRatedProducts, setLoading]);

  return (
    <>
      <Typography variant="h2" className={classes.title}>
        Please rate these products before going to the next step.
      </Typography>
      {productsForRate.map((product) => (
        <ProductRatingCard
          notRatedProducts={notRatedProducts}
          key={product.product_id}
          product={{
            ...productsObj[product.product_id],
            user_rate: product.user_rate,
          }}
          updateProductRating={updateProductRating}
        />
      ))}
      <FooterButtons
        className={classes.buttons}
        acceptTitle="Save"
        rejectTitle="Cancel"
        onReject={onClose}
        onAccept={onSaveRating}
        isLoading={loading}
      />
    </>
  );
};

export default RatingCard;
