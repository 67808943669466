// Libs
import React, { useCallback, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import {
  Card as MuiCard,
  CardActionArea,
  CardMedia,
  CardContent,
  Box,
  Typography,
  Stack,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

// Components
import FlagButton from '../../Button/FlagButton';
import Rating from '../../Rating';
import CountDescription, { StockItem } from './CountDescription';
import NewLabel from './NewLabel';
import ReportableIcon from './ReportableIcon';
import Image from '../../Image';
import Details from '../Details';
import MaterialsProductQtyButtons from './MaterialsProductQtyButtons';
import FdmButton from './FdmButton';
import CloseButton from '../../Button/CloseButton';
import LaterProductQtyButtons from '../../Cart/LaterProductQtyButtons/LaterProductQtyButtons';
import KitsProductQtyButtons from '../../Kits/KitsProductQtyButtons/KitsProductQtyButtons';

// Actions
import { OpenModal } from '../../../actions/modal';

// Hooks
import useCart from '../../../pages/Cart/hooks/useCart';
import useSaveForLater from '../../../pages/Cart/hooks/useSaveForLater';
import useKitsProducts from '../../../pages/Kits/hooks/useKitsProducts';
import useKitActions from '../../../pages/Kits/hooks/useKitActions';
import useFavorites from '../../../hooks/useFavorites';
import useUnavailableForOrderProduct from '../../../hooks/useUnavailableForOrderProduct';
import useUI from '../../../hooks/useUI';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    borderRadius: 20,
    boxShadow: '0px 5px 15px rgba(44, 112, 172, 0.2)',
    display: 'flex',
    flexDirection: 'column',
    height: 495,
    [theme.breakpoints.down('lg')]: {
      height: 380,
    },
    [theme.breakpoints.down('md')]: {
      borderRadius: 10,
      height: 275,
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        height: 380,
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        borderRadius: 10,
        height: 275,
      }),
    },
  },
  img: ({ isUnavailableForOrder }) => ({
    ...(isUnavailableForOrder && {
      opacity: 0.65,
    }),
  }),
  actionArea: {
    position: 'relative',
    width: 'initial',
  },
  description: {
    cursor: 'pointer',
    display: '-webkit-box',
    fontSize: 18,
    lineHeight: '20px',
    fontWeight: 700,
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    [theme.breakpoints.down('lg')]: {
      fontSize: 14,
      lineHeight: '14px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      marginBottom: 0,
      lineHeight: '12px',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        fontSize: 14,
        lineHeight: '14px',
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 12,
        marginBottom: 0,
        lineHeight: '12px',
      }),
    },
  },
  content: ({ cardContent }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: '14px 20px !important',
    height: '100%',
    minWidth: 50,
    flex: 1,
    [theme.breakpoints.down('lg')]: {
      padding: '10px !important',
    },
    [theme.breakpoints.down('md')]: {
      padding: '8px !important',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        padding: '10px !important',
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        padding: '8px !important',
      }),
    },
  }),
  sizeVers: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
    [theme.breakpoints.downLandscape('sm')]: {
      ...(theme.isMobileDevice && {
        fontSize: 10,
      }),
    },
  },
  flagButton: {
    marginLeft: 'auto',
  },
  stockItem: {
    [theme.breakpoints.down('md')]: {
      fontSize: 10,
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 10,
      }),
    },
  },
  fdmButton: {
    alignSelf: 'start',
    fontSize: 12,
    padding: theme.spacing(1),
    width: 105,
    [theme.breakpoints.down('lg')]: {
      alignSelf: 'start',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        alignSelf: 'start',
      }),
    },
  },
  removeButton: {
    fontSize: 22,
    position: 'absolute',
    right: '10px',
    top: '10px',
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: 14,
      }),
    },
  },
  materialsQtyButtons: {
    width: '100%',
  },
  kitsQtyButtons: {
    width: '100%',
  },
  laterQtyButtons: {
    width: '100%',
  },
  labels: {
    display: 'flex',
    columnGap: theme.spacing(1),
    position: 'absolute',
    left: theme.spacing(2),
    top: theme.spacing(2),
    zIndex: 10,
    [theme.breakpoints.down('md')]: {
      columnGap: theme.spacing(0.75),
      left: theme.spacing(1.25),
      top: theme.spacing(1.25),
    },
  },
}));

const GridCard = ({
  product,
  cardContent,
  cardModalComponent,
  imageContainer,
  modalProps = {},
  saveForLater,
  kit,
  nums,
  className,
  CloseComponent,
}) => {
  const dispatch = useDispatch();
  const { selectingKitProducts } = useSelector((state) => state.kits);

  const { isTabletMode, isMobileMode } = useUI();

  const {
    image_link: image,
    product_description: description,
    product_available_qty: availableQuantity,
    prepack_quantity: perUnitQty,
    order_limit: maxAllowedQty,
    is_new: isNew,
    is_favourite: isFavorite,
    rating: isRateable,
    product_id: id,
    reportable: isReportable,
    size_vers: sizeVers,
  } = product || {};

  const {
    addInCart,
    addingProgress,
    count,
    addOne,
    removeOne,
    setCount,
    isProductInCart,
  } = useCart(id);
  const { isUnavailableForOrder, unavailableWatermark, unavailableLabel } =
    useUnavailableForOrderProduct(product, true);

  const { deleteFromLater } = useSaveForLater();

  const classes = useStyles({ isUnavailableForOrder, cardContent });

  const { removeProductFromKit } = useKitsProducts();

  const { onDeleteClick } = useKitActions(kit);

  const { setFavoriteValue } = useFavorites();

  const onOpenModal = useCallback(
    () =>
      dispatch(
        OpenModal({
          id:
            kit || selectingKitProducts
              ? 'kits-product-details'
              : saveForLater
              ? 'later-product-details'
              : 'materials-product-details',
          Component: cardModalComponent || Details,
          props: {
            productId: id,
            isFullScreen: isTabletMode,
            ...modalProps,
            nums,
            paddingContent: cardModalComponent
              ? {
                  xl: '50px 40px 50px',
                  lg: '60px 28px 30px',
                  md: '40px 12px 16px',
                }
              : {
                  xl: '50px 40px 40px',
                  lg: '60px 28px 38px',
                  md: '40px 12px 16px',
                },
          },
          styles: {
            width: 'min(90vw, 1143px)',
          },
        }),
      ),
    [
      dispatch,
      kit,
      selectingKitProducts,
      saveForLater,
      cardModalComponent,
      id,
      isTabletMode,
      modalProps,
      nums,
    ],
  );

  const CloseIcon = useMemo(() => {
    if (saveForLater) {
      return (
        <CloseButton
          onClose={(e) => {
            e.stopPropagation();
            deleteFromLater(product?.product_id);
          }}
          className={classes.removeButton}
        />
      );
    }
    if (kit) {
      return (
        <CloseButton
          onClose={(e) => {
            e.stopPropagation();
            if (kit.products.length === 1) {
              // Remove this kit from kits.
              onDeleteClick(kit.id);
            } else {
              removeProductFromKit(kit.id, product?.product_id);
            }
          }}
          className={classes.removeButton}
        />
      );
    }
    return <></>;
  }, [
    saveForLater,
    kit,
    classes.removeButton,
    deleteFromLater,
    product?.product_id,
    removeProductFromKit,
    onDeleteClick,
  ]);
  return (
    <MuiCard className={classnames(className, classes.root)}>
      <div onClick={(e) => e.stopPropagation()} className={classes.labels}>
        {isNew && <NewLabel positionStatic />}
      </div>
      <CardActionArea
        disabled={!image}
        className={classes.actionArea}
        onClick={onOpenModal}>
        {unavailableWatermark}
        {CloseIcon}
        {CloseComponent && <CloseComponent />}
        <Image
          alt="productImage"
          imageContainer={
            imageContainer || {
              desktop: ['100%', 250],
              tablet: ['100%', 224],
              mobile: ['100%', 138],
            }
          }
          src={image}>
          <CardMedia
            id="image-button"
            className={classes.img}
            component="img"
            image={image}
            alt="productImage"
          />
        </Image>
      </CardActionArea>
      <CardContent className={classes.content}>
        {!cardContent ? (
          <>
            <div>
              {isMobileMode && isRateable && <Rating product={product} />}
              <div
                onClick={onOpenModal}
                className={classes.description}
                data-testid="product-description">
                {description}
              </div>
              <Typography variant="textXs" className={classes.sizeVers}>
                {sizeVers}
              </Typography>
              {!isMobileMode && isRateable && <Rating product={product} />}
              {isTabletMode && (
                <Stack direction="row" justifyContent="space-between">
                  <StockItem
                    availableQuantity={availableQuantity}
                    unavailableLabel={unavailableLabel}
                    className={classes.stockItem}
                  />
                  {isReportable && <ReportableIcon />}
                </Stack>
              )}
              {!isTabletMode && (
                <Box display="flex" justifyContent="space-between" mt={1}>
                  <CountDescription
                    perUnitQty={perUnitQty}
                    maxAllowedQty={maxAllowedQty}
                    availableQuantity={availableQuantity}
                    view={'grid'}
                    unavailableLabel={unavailableLabel}
                  />
                  <Stack>
                    {!saveForLater && !kit && (
                      <FdmButton
                        className={classes.fdmButton}
                        product={product}
                      />
                    )}
                    {isReportable && (
                      <Box marginTop="auto" alignSelf="end">
                        <ReportableIcon />
                      </Box>
                    )}
                  </Stack>
                </Box>
              )}
            </div>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width={'100%'}
              gap={2}>
              {/* We always display SFL qty buttons, just disable them (inside LaterProductQtyButtons) */}
              {saveForLater ? (
                <LaterProductQtyButtons
                  product={product}
                  isMobileMode={isMobileMode}
                  isProductInCart={false}
                  nums={nums}
                  className={classes.laterQtyButtons}
                />
              ) : /* Another buttons should be displayed only when available qty > 0 */
              availableQuantity > 0 ? (
                kit || selectingKitProducts ? (
                  !isUnavailableForOrder && (
                    <KitsProductQtyButtons
                      product={product}
                      isMobileMode={isMobileMode}
                      detailsMode={false}
                      listView={false}
                      nums={nums}
                      className={classes.kitsQtyButtons}
                    />
                  )
                ) : (
                  !isUnavailableForOrder && (
                    <MaterialsProductQtyButtons
                      loading={addingProgress}
                      listView={false}
                      product={product}
                      isMobileMode={isMobileMode}
                      addInCart={addInCart}
                      count={count}
                      addOne={addOne}
                      removeOne={removeOne}
                      setCount={setCount}
                      isProductInCart={isProductInCart}
                      className={classes.materialsQtyButtons}
                    />
                  )
                )
              ) : (
                <></>
              )}
              {!kit && !isTabletMode && (
                <FlagButton
                  className={classes.flagButton}
                  active={isFavorite}
                  onClick={() =>
                    setFavoriteValue(product.product_id, !isFavorite)
                  }
                  id="flag-button"
                />
              )}
            </Box>
          </>
        ) : (
          <>{cardContent}</>
        )}
      </CardContent>
    </MuiCard>
  );
};

export default GridCard;
