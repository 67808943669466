// Lib
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
  root: ({ isDragActive }) => ({
    backgroundColor: isDragActive ? theme.palette.lightGrey : 'initial',
    border: '2px dashed #C6CAC6',
    borderRadius: 6,
    padding: 20,
    marginBottom: 10,
    marginTop: theme.spacing(2),
    textAlign: 'center',
    width: '100%',
    outline: 'unset',
    [theme.breakpoints.down('md')]: {
      border: 'none',
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        border: 'none',
        padding: theme.spacing(0.5),
        marginTop: 0,
      }),
    },
  }),
}));

const Dropzone = ({ onDrop, accept, children }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    onDrop,
  });

  const classes = useStyles({ isDragActive });

  return (
    <div {...getRootProps({ className: classnames('dropzone', classes.root) })}>
      <input className="input-zone" {...getInputProps()} />
      {children}
    </div>
  );
};
export default Dropzone;
