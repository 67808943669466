// Constants
import { BASE_URL } from '../constants/urls';

// Store
import { store } from '../store';

// Actions
import {
  UpdateProductViaSocket,
  UpdateArrayProductsViaSocket,
} from '../actions/products';

const RECONNECT_TIMEOUT = 2000;
const DELTA_BETWEEN_ACTIONS = 3000;
const UPDATE_ARRAY_PRODUCTS_TIMEOUT = 3000;

let updatedProducts = [];
let firstTimeUpdate = '';
let nextTimeUpdate = '';
let timerIdForProducts;

const openSocket = (
  path = 'products/',
  ReduxAction = UpdateProductViaSocket,
) => {
  const token = store.getState()?.auth?.accessToken;
  const client = new WebSocket(
    `wss:${BASE_URL.slice(8)}/ws/${path}?token=${token}`,
  );
  let timerIdForOpenSocket;

  client.onopen = () => {
    if (timerIdForOpenSocket) {
      clearInterval(timerIdForOpenSocket);
    }
  };

  client.onmessage = ({ data }) => {
    const item = JSON.parse(data);
    if (ReduxAction === UpdateProductViaSocket) {
      if (firstTimeUpdate) {
        nextTimeUpdate = new Date().getTime();
      }
      if (!nextTimeUpdate) {
        firstTimeUpdate = new Date().getTime();
      }

      if (firstTimeUpdate && nextTimeUpdate) {
        const delta = nextTimeUpdate - firstTimeUpdate;
        firstTimeUpdate = '';
        if (delta < DELTA_BETWEEN_ACTIONS) {
          updatedProducts.push(item);

          if (timerIdForProducts) {
            clearTimeout(timerIdForProducts);
          }

          timerIdForProducts = setTimeout(() => {
            firstTimeUpdate = '';
            nextTimeUpdate = '';
            store.dispatch(UpdateArrayProductsViaSocket(updatedProducts));
            updatedProducts = [];
          }, UPDATE_ARRAY_PRODUCTS_TIMEOUT);
          firstTimeUpdate = new Date().getTime();
        } else {
          store.dispatch(ReduxAction(item));
          firstTimeUpdate = '';
          nextTimeUpdate = '';
        }
      }

      if (!nextTimeUpdate) {
        store.dispatch(ReduxAction(item));
      }
    } else {
      store.dispatch(ReduxAction(item));
    }
  };

  client.onclose = () => {
    if (client.closeWithoutReconnect) {
      return;
    }
    timerIdForOpenSocket = setInterval(() => {
      clearInterval(timerIdForOpenSocket);
      openSocket(path, ReduxAction);
    }, RECONNECT_TIMEOUT);
  };

  client.onerror = () => {
    client.close();
  };

  window.addEventListener('offline', () => {
    client.close();
  });

  return client;
};

export { openSocket };
