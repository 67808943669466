//Libs
import React from 'react';
import { Button } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { makeStyles } from '@mui/styles';

// Hooks
import useUser from '../../../hooks/useUser';

const useStyles = makeStyles((theme) => ({
  button: {
    color: ({ isKiteUser }) =>
      isKiteUser ? theme.palette.primary1 : theme.palette.pressed,
    fontWeight: ({ isKiteUser }) => (isKiteUser ? '400' : '600'),
    textDecoration: 'none',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
}));

const Track = ({ trackingLink, styles }) => {
  const { isKiteUser } = useUser;
  const classes = useStyles({ isKiteUser });
  return (
    <Button
      className={classes.button}
      href={trackingLink || ''}
      target="_blank"
      style={styles}
      endIcon={<ChevronRightIcon style={{ marginLeft: '-5px' }} />}
      data-testid={'tracking-link-button'}>
      Track
    </Button>
  );
};

export default Track;
