export const SEARCHABLE_FIELDS = [
  'product_description',
  'product_id',
  'brand_category',
  'owner', // department
  'audience',
  'indication',
];

export const RATING_DISABLED =
  'You Can Rate This Product After Ordering At Least Once.';

export const MORE_THAN_IN_STOCK_MESSAGE = 'More Than In Stock';
export const OVER_LIMIT_MESSAGE = 'Over Limit';

export const IGNORED_PRESCRIBING_INFO_VALUES = [
  'Combined Important Facts + ISI',
  'Full Package Insert',
  'Important Facts',
  'Medication Guide',
  'Prescribing Information',
];

export const OTHER_FILTER_TYPE = 'Other';

export const FILTERS_KEY_MAP = {
  brand_category: 'All brands',
  owner: 'All therapeutic areas',
  audience: 'All audience',
  indication: 'All indications',
};

export const ADD_ALL_TO_CART_LABEL = 'Add All To Cart';
