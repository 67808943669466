// Libs
import React, { useCallback, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

// Components
import DatePicker from '../../DatePicker';
import NestedList from '../../NestedList';
import Button from '../../Button';
import Feather from '../../Feather';

// Utils
import capitalize from '../../../utils/capitalize';

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    [theme.breakpoints.downLandscape('sm')]: {
      ...(theme.isMobileDevice && {
        paddingBottom: theme.spacing(1.5),
        paddingTop: theme.spacing(1.5),
      }),
    },
  },
}));

const MobileFilters = ({ filters, onCloseSideMenu, isRecurring = false }) => {
  const classes = useStyles();
  const onChangeSorting = useCallback(
    (title) => {
      let sorting, order;
      let keys = title.split(' ').map((item) => {
        return item.toLowerCase() === 'date' ? 'created' : item.toLowerCase();
      });
      if (isRecurring) {
        sorting = `${keys[0]} ${keys[1]}`;
        order = keys[1];
      } else {
        [sorting, order] = keys;
      }

      order = order.slice(1, -1); // remove braces
      filters.onChangeMobileSorting(sorting);
      if (filters.order !== order) {
        filters.changeOrder();
      }
    },
    [filters, isRecurring],
  );

  const onChangeStatus = (name, title, checked) => {
    filters.onChangeStatus(title, !checked);
  };

  const sortingItems = useMemo(() => {
    const items = [];
    filters?.sortingItems.forEach((item) => {
      items.push({
        title: `${
          item.title === 'created' ? 'Date' : capitalize(item.title, true)
        } (Asc)`,
        checked:
          filters.sorting.title === item.title && filters.order === 'asc',
      });
      items.push({
        title: `${
          item.title === 'created' ? 'Date' : capitalize(item.title, true)
        } (Desc)`,
        checked:
          filters.sorting.title === item.title && filters.order === 'desc',
      });
    });
    return items;
  }, [filters]);

  const onApplyFilters = () => {
    filters.applyMobileFilters();
    onCloseSideMenu();
  };

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      height={'calc(100% - 52px)'}>
      <Box>
        {!isRecurring && (
          <Box mb={4} display={'flex'} alignItems={'center'} gap={2}>
            <Box display={'flex'} flexDirection={'column'}>
              <Typography variant={'textMdSemibold'} mb={1}>
                From
              </Typography>
              <DatePicker
                width={140}
                date={filters.startDate}
                onChange={filters.onChangeStartDate}
                maxDate={new Date()}
                inSidebarOnMobile={true}
                helperTextPosition="absolute"
              />
            </Box>
            <Box display={'flex'} flexDirection={'column'}>
              <Typography variant={'textMdSemibold'} mb={1}>
                To
              </Typography>
              <DatePicker
                width={140}
                date={filters.endDate}
                onChange={filters.onChangeEndDate}
                inSidebarOnMobile={true}
                helperTextPosition="absolute"
              />
            </Box>
          </Box>
        )}
        <NestedList
          withChanges={filters.sorting}
          onChange={(_, title) => () => onChangeSorting(title)}
          title="Sort By"
          items={sortingItems}
          orderBy={`${filters?.sorting.title} (${filters?.order})`}
          order={filters?.order}
        />
        {!isRecurring && (
          <NestedList
            withChanges={filters.statusesAreNotDefault}
            name="status"
            title="Status"
            items={filters.statuses}
            onChange={(name, title, checked) => () =>
              onChangeStatus(name, title, checked)}
          />
        )}
      </Box>
      <Box className={classes.buttonsContainer}>
        <Box flexGrow={1} flexBasis={'50%'}>
          {!isRecurring && (
            <Button
              id="clear-all"
              onClick={() => {
                filters.toInitialState();
                filters.applyMobileFilters();
              }}
              styles={{ padding: '4px' }}
              variant="text">
              <Feather
                style={{ marginRight: 4, paddingBottom: 2 }}
                type="close"
              />
              <span>Clear All</span>
            </Button>
          )}
        </Box>
        <Box flexGrow={1} display={'flex'} justifyContent={'end'}>
          <Button
            styles={{ width: 150, height: 44, maxHeight: 44 }}
            onClick={onApplyFilters}>
            Apply Filters
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default MobileFilters;
