// Lib
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

// Components
import Switch from '../../Button/Switch';
import NestedList from '../../NestedList';
import Button from '../../Button';
import Feather from '../../Feather';

const useStyles = makeStyles((theme) => ({
  switcher: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

const MobileFilterBar = ({
  filters: storeFilters,
  onClearAllFilters,
  applyMobileFilters,
  onCloseSideMenu,
}) => {
  const [filters, setFilters] = useState(storeFilters);
  const classes = useStyles();

  const filtersWithChanges = useMemo(
    () =>
      Object.keys(filters).filter((filterTitle) => {
        if (Array.isArray(filters[filterTitle])) {
          return filters[filterTitle].find((option) => option.checked);
        }
      }),
    [filters],
  );

  const onChange = useCallback(
    (name, title, checked) => () => {
      if (name === 'available') {
        return setFilters((prevFilters) => ({
          ...prevFilters,
          [name]: prevFilters[name].map((item) =>
            item.title === title
              ? { ...item, checked: !item.checked }
              : { ...item, checked: false },
          ),
        }));
      } else {
        return setFilters((prevFilters) => ({
          ...prevFilters,
          [name]: prevFilters[name].map((item) =>
            item.title === title ? { ...item, checked: !checked } : item,
          ),
        }));
      }
    },
    [],
  );

  const onChangeSwitch = useCallback(() => {
    setFilters((prevState) => ({
      ...prevState,
      frequentlyOrdered: !prevState.frequentlyOrdered,
    }));
  }, [setFilters]);

  const items = useMemo(() => {
    const options = [];
    filters?.sorting?.items.forEach((item) => {
      options.push({ title: `${item.title} (Asc)` });
      options.push({ title: `${item.title} (Desc)` });
    });
    return options;
  }, [filters]);

  const onChangeSorting = useCallback(
    (_, orderBy) => () => {
      const newFilter = { ...filters.sorting };
      const isDescOrder = orderBy?.toLowerCase()?.includes('desc');
      newFilter.orderBy = isDescOrder
        ? orderBy.slice(0, orderBy.length - 7)
        : orderBy.slice(0, orderBy.length - 6);
      newFilter.order = isDescOrder ? 'desc' : 'asc';

      setFilters((prevFilters) => ({ ...prevFilters, sorting: newFilter }));
    },
    [filters],
  );

  const onClear = useCallback(() => {
    onClearAllFilters();
  }, [onClearAllFilters]);

  useEffect(() => setFilters(storeFilters), [storeFilters]);

  const onApplyFilters = useCallback(() => {
    onCloseSideMenu();
    applyMobileFilters(filters);
  }, [filters, applyMobileFilters, onCloseSideMenu]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="calc(100% - 32px)"
      justifyContent="space-between">
      <div>
        <Switch
          name="frequentlyOrdered"
          className={classes.switcher}
          label="Frequently Ordered"
          onChange={onChangeSwitch}
          checked={filters.frequentlyOrdered}
        />
        <NestedList
          withChanges={filtersWithChanges.includes('brand_category')}
          name="brand_category"
          title="Brand Category"
          items={filters.brand_category}
          onChange={onChange}
        />
        <NestedList
          withChanges={filtersWithChanges.includes('owner')}
          name="owner"
          title="Therapeutic Area"
          items={filters.owner}
          onChange={onChange}
        />
        <NestedList
          withChanges={filtersWithChanges.includes('audience')}
          title="Audience"
          name="audience"
          items={filters.audience}
          onChange={onChange}
        />
        <NestedList
          withChanges={filters?.sorting}
          onChange={onChangeSorting}
          title="Sort By"
          items={items}
          orderBy={`${filters?.sorting?.orderBy} (${filters?.sorting?.order})`}
        />
        <NestedList
          withChanges={filtersWithChanges.includes('available')}
          title="Product Availability"
          name="available"
          items={filters.available}
          onChange={onChange}
        />
        {!!filters.indication.length && (
          <NestedList
            withChanges={filtersWithChanges.includes('indication')}
            title="Indication"
            name="indication"
            items={filters.indication}
            onChange={onChange}
          />
        )}
      </div>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        pt={6}
        pb={2}>
        <Button
          id="clear-all"
          onClick={onClear}
          styles={{ padding: '4px' }}
          variant="text">
          <Feather style={{ marginRight: 4, paddingBottom: 2 }} type="close" />
          <span>Clear All</span>
        </Button>
        <Button
          id="apply-filters"
          onClick={onApplyFilters}
          styles={{ width: 150, height: 44, maxHeight: 44 }}>
          Apply Filters
        </Button>
      </Box>
    </Box>
  );
};

export default MobileFilterBar;
