// Lib
import React, { useEffect, useMemo } from 'react';
import classnames from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@mui/styles';
import MaskedInput from 'react-input-mask';

// Components
import Input from '../../Field/Input';
import Select from '../../Field/Select';

// Validation schema
import { newAddressSchema } from '../../../validationSchema';

// Hooks
import useUI from '../../../hooks/useUI';

// Styles
import { colors } from '../../../styles/palette';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(3),
  },
  input: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.down('md')]: {
      '& input': {
        fontSize: 14,
      },
    },
    '& .css-19idom': {
      height: 20,
      marginBottom: theme.spacing(0.5),
      [theme.breakpoints.down('lg')]: {
        height: 'auto',
        marginBottom: theme.spacing(1),
      },
    },
  },
  addressType: {
    marginTop: theme.spacing(0.125),
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(1.75),
    },
  },
  inputContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    '& > div': {
      flexBasis: 'calc(50% - 10px)',
      maxWidth: 'calc(50% - 10px)',
      '&:nth-child(1)': {
        marginRight: 20,
      },
      '&:nth-child(3)': {
        marginRight: 20,
      },
    },
    [theme.breakpoints.down('md')]: {
      flexWrap: 'nowrap',
      flexDirection: 'column',
      '& > div': {
        maxWidth: 'none',
        flexBasis: '100%',
        marginRight: '0 !important',
      },
    },
  },
  select: {
    marginTop: theme.spacing(1),
    '& fieldset': {
      backgroundColor: 'transparent !important',
      border: '1px solid rgba(0, 0, 0, 0.23) !important',
      borderRadius: '10px',
    },
    '& .MuiSelect-select': {
      color: '#1E1E1E',
      fontSize: 16,
      fontWeight: 400,
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(1.5),
    },
    [theme.breakpoints.down('md')]: {
      '& .MuiSelect-select': {
        fontSize: 14,
      },
    },
    '& .css-19idom': {
      height: 20,
      marginBottom: theme.spacing(0.5),
      [theme.breakpoints.down('lg')]: {
        height: 'auto',
        marginBottom: theme.spacing(1),
      },
    },
  },
  zip: {
    '& .MuiTextField-root': {
      width: '45%',
      [theme.breakpoints.down('md')]: {
        width: 148,
      },
    },
  },
  uspsError: {
    color: colors.gilead.primary1,
    marginBottom: theme.spacing(1),
  },
}));

const Form = ({
  address = {},
  onChangeSelect,
  onChangeInput,
  states,
  countries,
  apiErrors,
  uspsError,
  onSubmit,
  children,
  hideElement = () => {},
  showElement = () => {},
}) => {
  const classes = useStyles();
  const { isMobileMode } = useUI();

  const {
    address1,
    address2,
    address_type: addressType,
    city,
    company,
    country,
    first_name: firstName,
    last_name: lastName,
    phone,
    state,
    zip,
  } = address;

  const fullState = useMemo(() => {
    if (!state) {
      return;
    }
    const { name } = states.find((item) => item.isoCode === state) || {};
    return name ? name : state;
  }, [states, state]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(newAddressSchema),
    defaultValues: {
      addressType: addressType || '',
      company: company || '',
      firstName: firstName || '',
      lastName: lastName || '',
      phone: phone || '',
      address1: address1 || '',
      address2: address2 || '',
      country: country || 'United States',
      state: fullState || '',
      city: city || '',
      zip: zip || '',
    },
  });

  const currentCountry = watch('country');

  useEffect(() => {
    if (isMobileMode) {
      const inputs = document.querySelectorAll('input');
      inputs.forEach((item) => {
        item.addEventListener('focus', hideElement);
        item.addEventListener('blur', showElement);
      });
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {uspsError && <div className={classes.uspsError}>{uspsError}</div>}
      <Controller
        name="addressType"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            maxLength={100}
            onChange={onChangeInput(field.onChange)}
            error={errors?.addressType?.message || apiErrors?.address_type?.[0]}
            className={classnames(classes.input, classes.addressType)}
            label="Address Type"
            required
            data-testid="address-type-input"
          />
        )}
      />
      <Controller
        name="company"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            maxLength={35}
            onChange={onChangeInput(field.onChange)}
            className={classes.input}
            label="Company"
            data-testid="address-company-input"
          />
        )}
      />
      <div className={classes.inputContainer}>
        <Controller
          name="firstName"
          control={control}
          render={({ field }) => {
            return (
              <Input
                {...field}
                maxLength={35}
                onChange={onChangeInput(field.onChange)}
                error={errors?.firstName?.message}
                className={classes.input}
                label="First Name"
                data-testid="address-first-name-input"
              />
            );
          }}
        />
        <Controller
          name="lastName"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              maxLength={35}
              onChange={onChangeInput(field.onChange)}
              error={errors?.lastName?.message || apiErrors?.last_name?.[0]}
              className={classes.input}
              required
              label="Last Name"
              data-testid="address-last-name-input"
            />
          )}
        />
        <Controller
          name="phone"
          control={control}
          render={({ field }) => (
            <MaskedInput
              mask="999-999-9999"
              maskChar=""
              onChange={onChangeInput(field.onChange, true)}
              value={field.value}>
              {(inputProps) => {
                return (
                  <Input
                    name="phone"
                    placeholder="555-555-5555"
                    {...inputProps}
                    withMask
                    maxLength={15}
                    error={errors?.phone?.message || apiErrors?.phone?.[0]}
                    className={classes.input}
                    required
                    label="Phone"
                    data-testid="address-phone-input"
                  />
                );
              }}
            </MaskedInput>
          )}
        />
      </div>
      <Controller
        name="address1"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            maxLength={35}
            onChange={onChangeInput(field.onChange)}
            error={errors?.address1?.message || apiErrors?.address1?.[0]}
            className={classes.input}
            required
            label="Address 1"
            placeholder="No P.O. Boxes Allowed"
            data-testid="address-one-input"
          />
        )}
      />
      <Controller
        name="address2"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            placeholder="No P.O. Boxes Allowed"
            maxLength={35}
            onChange={onChangeInput(field.onChange)}
            error={errors?.address2?.message}
            className={classes.input}
            label="Address 2"
            data-testid="address-two-input"
          />
        )}
      />
      <div className={classes.inputContainer}>
        <Controller
          name="country"
          control={control}
          render={({ field }) => (
            <Select
              initialValue={address.country || 'United States'}
              {...field}
              error={errors?.country?.message || apiErrors?.country?.[0]}
              onChange={onChangeSelect(field.onChange, setValue, field)}
              className={classes.select}
              classic
              label="Country"
              required
              items={countries}
              data-testid="address-country-select"
            />
          )}
        />
        <Controller
          name="state"
          control={control}
          render={({ field }) => (
            <Select
              withRemoveIcon={apiErrors?.state?.[0]?.includes('not required')}
              {...field}
              initialValue={field.value}
              disabled={currentCountry !== 'United States'}
              error={
                (currentCountry === 'United States' &&
                  errors?.state?.message) ||
                apiErrors?.state?.[0]
              }
              onChange={onChangeSelect(field.onChange, undefined, field)}
              placeholder="Select"
              items={states}
              className={classes.select}
              classic
              label="State"
              required={currentCountry === 'United States'}
              data-testid="address-state-select"
            />
          )}
        />
        <Controller
          name="city"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              maxLength={30}
              onChange={onChangeInput(field.onChange)}
              error={errors?.city?.message || apiErrors?.city?.[0]}
              className={classes.input}
              required
              label="City"
              data-testid="address-city-input"
            />
          )}
        />
        <Controller
          name="zip"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              maxLength={10}
              onChange={onChangeInput(field.onChange)}
              error={errors?.zip?.message || apiErrors?.zip?.[0]}
              className={classnames(classes.input, classes.zip)}
              required
              label="Zip"
              data-testid="address-zip-input"
            />
          )}
        />
      </div>
      {children}
    </form>
  );
};

export default Form;
