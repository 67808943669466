// Libs
import React, { useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

// Components
import Rating from '../../../../Rating';
import OrderProductDetails from '../../../Modals/OrderProductDetails/OrderProductDetails';
import OutOfStockLabel from '../../../Labels/OutOfStockLabel';
import CountComponent from '../CountComponent';
import OrderProductQtyLabels from '../../../../Labels/OrderProductQtyLabels';
import ApprovalLabel from '../../../../Labels/ApprovalLabel';

// Hooks
import useUser from '../../../../../hooks/useUser';
import useUI from '../../../../../hooks/useUI';
import useUnavailableForOrderProduct from '../../../../../hooks/useUnavailableForOrderProduct';

// Actions
import { OpenModal } from '../../../../../actions/modal';

// Utils
import { getProductTotalItemsOrdered } from '../../../../../pages/OrderHistory/utils/getQuantity';
import ReportableIcon from '../../../../Products/Card/ReportableIcon';

const useStyles = makeStyles((theme) => ({
  ratingContainer: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(0.5),
      marginBottom: 0,
    },
  },
  qtyInfo: {
    marginTop: theme.spacing(1),
    marginBottom: 'auto',
    [theme.breakpoints.down('lg')]: {
      marginBottom: 0,
    },
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(0.5),
      marginBottom: 'auto',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        marginBottom: 0,
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        marginTop: theme.spacing(0.5),
        marginBottom: 'auto',
      }),
    },
  },
  qtyInfoText: ({ isKiteUser }) => ({
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    color: isKiteUser ? theme.palette.primary1 : theme.palette.pressed,
    '&:nth-child(2)': {
      marginBottom: theme.spacing(0.5),
    },
    [theme.breakpoints.down('lg')]: {
      '&:nth-child(2)': {
        marginBottom: 0,
      },
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '10px',
      lineHeight: '12px',
    },
    [theme.breakpoints.downLandscape('lg')]: {
      ...(theme.isMobileDevice && {
        '&:nth-child(2)': {
          marginBottom: 0,
        },
      }),
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: '10px',
        lineHeight: '12px',
      }),
    },
  }),
  outOfStockText: {
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    color: '#C5203F',
  },
  productDescription: {
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '700',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    [theme.breakpoints.down('md')]: {
      fontSize: '10px',
      fontWeight: '600',
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: '10px',
        fontWeight: '600',
      }),
    },
  },
  sizeVers: {
    [theme.breakpoints.down('md')]: {
      fontSize: '10px',
    },
    [theme.breakpoints.downLandscape('md')]: {
      ...(theme.isMobileDevice && {
        fontSize: '10px',
      }),
    },
  },
  buttonContainer: {
    alignItems: 'end',
    display: 'flex',
    '& > div:nth-child(1)': {
      [theme.breakpoints.down('lg')]: {
        width: 130,
        height: 34,
      },
      [theme.breakpoints.down('md')]: {
        width: 124,
        height: 24,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      [theme.breakpoints.downLandscape('lg')]: {
        ...(theme.isMobileDevice && {
          width: 130,
          height: 34,
        }),
      },
      [theme.breakpoints.downLandscape('md')]: {
        ...(theme.isMobileDevice && {
          width: 124,
          height: 24,
          marginLeft: 'auto',
          marginRight: 'auto',
        }),
      },
    },
  },
  approvalLabel: ({ isDesktopMode, isOutOfStock }) => ({
    position: isDesktopMode ? 'absolute' : 'static',
    right: isDesktopMode && !isOutOfStock ? '20px' : 'unset',
    left: isDesktopMode && isOutOfStock ? '20px' : 'unset',
    bottom: isDesktopMode ? '14px' : 'unset',
    maxWidth: isDesktopMode ? '145px' : 'unset',
    marginBottom: isDesktopMode ? 0 : 'auto',
  }),
  totalItems: {
    color: () => theme.palette.red,
  },
}));

const GridContent = ({
  product,
  order,
  orderItem,
  isRecurring = false,
  addressesCount = 1,
}) => {
  const {
    product_available_qty,
    order_limit,
    product_description: description,
    rating: isRatable,
    prepack_quantity,
    reportable: isReportable,
    size_vers: sizeVers,
  } = product || {};
  const { isUnavailableForOrder, unavailableLabel } =
    useUnavailableForOrderProduct(product, true);

  const { isMobileMode, isTabletMode, isDesktopMode } = useUI();

  const isOutOfStock = product_available_qty === 0;

  const dispatch = useDispatch();
  const { isKiteUser } = useUser();
  const classes = useStyles({
    isKiteUser,
    order_limit,
    isMobileMode,
    isTabletMode,
    isDesktopMode,
    isOutOfStock,
  });

  const onOpenModal = useCallback(() => {
    dispatch(
      OpenModal({
        Component: OrderProductDetails,
        props: {
          productId: product.product_id,
          isFullScreen: isTabletMode,
          order,
          orderItem,
          addressesCount,
          isRecurring,
          paddingContent: {
            xl: '50px 40px 50px',
            lg: '60px 28px 30px',
            md: '40px 12px 16px',
          },
        },
        styles: {
          width: 'min(90vw, 1143px)',
        },
      }),
    );
  }, [
    dispatch,
    product,
    order,
    isTabletMode,
    orderItem,
    addressesCount,
    isRecurring,
  ]);

  const totalOrdered = useMemo(
    () => getProductTotalItemsOrdered(product, orderItem, addressesCount),
    [product, orderItem, addressesCount],
  );

  return isMobileMode || isTabletMode ? (
    // grid for devices
    <>
      {isMobileMode && isRatable && <Rating product={product} />}
      <Typography
        onClick={onOpenModal}
        className={classes.productDescription}
        width={isRecurring ? 'calc(100% - 40px)' : '100%'}
        data-testid="order-product-name">
        {description}
      </Typography>
      <Typography variant="textSm" className={classes.sizeVers}>
        {sizeVers}
      </Typography>
      {!isMobileMode && isRatable && (
        <div className={classes.ratingContainer}>
          <Rating product={product} />
        </div>
      )}
      <Box className={classes.qtyInfo}>
        {isUnavailableForOrder ? (
          <OutOfStockLabel label={unavailableLabel} device={{ isMobileMode }} />
        ) : (
          <>
            <Typography className={classes.qtyInfoText}>
              In Stock: {product_available_qty}
            </Typography>
          </>
        )}
        {!isMobileMode && (
          <div className={classes.qtyInfoText}>
            <OrderProductQtyLabels
              orderItem={orderItem}
              addressesCount={addressesCount}
              isRecurring={isRecurring}
            />
          </div>
        )}
        <Box
          width={'100%'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          pb={1}>
          <Typography
            className={classnames(classes.qtyInfoText, classes.totalItems)}>
            {`Total Items Ordered: ${prepack_quantity ? totalOrdered : 'N/A'}`}
          </Typography>
          {isReportable && <ReportableIcon />}
        </Box>
      </Box>
      {isRecurring && !isUnavailableForOrder && (
        <Box className={classes.buttonContainer} marginTop="auto">
          <CountComponent
            isGridView
            product={product}
            orderItem={orderItem}
            order={order}
          />
        </Box>
      )}
      {!isRecurring && !isMobileMode && (
        <ApprovalLabel
          orderItemStatus={orderItem.status}
          approver={orderItem.approval?.approver}
          isModified={orderItem.qty_ordered !== orderItem.qty_approved}
          className={classes.approvalLabel}
        />
      )}
    </>
  ) : (
    // grid for desktop
    <>
      <Box mb={1}>
        <Typography
          variant="h3"
          className={classes.productDescription}
          onClick={onOpenModal}
          width={isRecurring ? 'calc(100% - 40px)' : '100%'}>
          {description}
        </Typography>
        <Typography variant="textSm">{sizeVers}</Typography>
      </Box>
      {isRatable && (
        <div className={classes.ratingContainer}>
          <Rating product={product} />
        </div>
      )}
      <Box className={classes.qtyInfo}>
        <div className={classes.qtyInfoText}>
          <OrderProductQtyLabels
            orderItem={orderItem}
            addressesCount={addressesCount}
            isRecurring={isRecurring}
          />
        </div>
        <Box
          width={'100%'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}>
          <Typography
            className={classnames(classes.qtyInfoText, classes.totalItems)}>
            {`Total Items Ordered: ${prepack_quantity ? totalOrdered : 'N/A'}`}
          </Typography>
          {isReportable && <ReportableIcon />}
        </Box>
        {isUnavailableForOrder && (
          <Typography className={classes.outOfStockText}>
            {unavailableLabel}
          </Typography>
        )}
      </Box>
      {isRecurring && !isUnavailableForOrder && (
        <Box display="flex" gap={2} marginTop="auto">
          <Box flexBasis="57%"></Box>
          <Box flexBasis="43%" className={classes.buttonContainer}>
            <CountComponent
              product={product}
              orderItem={orderItem}
              order={order}
            />
          </Box>
        </Box>
      )}
      {!isRecurring && isDesktopMode && (
        <ApprovalLabel
          orderItemStatus={orderItem.status}
          approver={orderItem.approval?.approver}
          isModified={orderItem.qty_ordered !== orderItem.qty_approved}
          align={isOutOfStock ? 'left' : 'right'}
          className={classes.approvalLabel}
        />
      )}
    </>
  );
};

export default GridContent;
