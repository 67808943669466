// Libs
import axios from 'axios';

// Interceptors
import interceptors from './requestInterceptor';

// URLS
import { BASE_URL } from '../constants/urls';

const http = axios.create({ baseURL: `${BASE_URL}/api/v1` });

http.interceptors.request.use(interceptors.requestFulfilled);

http.interceptors.response.use(
  interceptors.responseFulfilled,
  interceptors.responseRejected,
);

export default http;
