// Lib
import { useCallback, useEffect, useState } from 'react';

// Hooks
import useUI from './useUI';

const useSideMenu = () => {
  const [active, setActive] = useState(false);
  const { isDesktopMode } = useUI();

  useEffect(() => {
    if (isDesktopMode && active) {
      setActive(false);
    }
  }, [isDesktopMode, active, setActive]);

  const onCloseSideMenu = useCallback(() => setActive(false), [setActive]);

  const toggleSideMenu = useCallback(
    (open) => (event) => {
      if (
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
      ) {
        return;
      }

      setActive(open);
    },
    [setActive],
  );

  return { active, toggleSideMenu, onCloseSideMenu };
};

export default useSideMenu;
